import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';


export const _getContractorIsLoading = (state: IAppState) => state.admin.accounts.contractors.isLoading;

export const getContractorIsLoading = createSelector(
  [_getContractorIsLoading],
  isLoading => isLoading,
);

export const _getErrorMessages = (state: IAppState) => state.admin.accounts.contractors.errorMessages;

export const getErrorMessages = createSelector(
  [_getErrorMessages],
  errMessage => errMessage,
);

export const _getAccountssContractors = (state: IAppState) => state.admin.accounts.contractors.items;

export const getAccountsContractors = createSelector(
  [_getAccountssContractors],
  programContractor => {
    const getOrderer = getList(programContractor).sort(function(a: any, b: any) {
      return parseInt(a.key) - parseInt(b.key);
    });
    return getOrderer;
  },
);


export const _getContractorCachedCounter = (state: IAppState) =>
  state.admin.accounts.contractors.contractorCachedCounter;


export const getContractorCachedCounter = createSelector(
  [_getContractorCachedCounter],
  contractor => contractor,
);
