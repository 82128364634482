import React, { memo, useEffect, useState } from 'react';
import CorrectiveActionsComponent from '../view/corrective-actions.component';
import { connect } from 'react-redux';
import {
  fetchUnderUserCA,
  fetchCATriggers,
  fetchCASeveritys,
  fetchCAStatus,
  fetchCATypes,
  UpdateUnderUserCA,
  fetchUserManagers,
} from './corrective-actions.actions';
import { IAppState } from '@rdx/root.reducer';
import { fetchContractorsAction } from '@app/admin/accounts/contractors/state/contractors.actions';

import { getUserCorrectiveActions } from './corrective-actions.selector';
import { CorrectiveActionsType, ProjectType, UserType } from '@shared/types/backend.types';
import { getSessionUser, getUserAccountId } from '@app/session/session.selector';
import { fetchProjectsAction } from '@app/back-office/projects/state/projects.actions';
import { getProgramsProjects } from '@app/back-office/professional.selector';
import { getTeam, getTeamAccessUserID } from '@app/back-office/team/state/team.selector';
import { getCAIsLoading } from './corrective-actions.selector';
import { getProgramPlans } from '@app/back-office/safety-programs/state/safety-programs.selector';
import { fetchPlansInstanceAction } from '@app/back-office/safety-programs/state/safety-programs.actions';
import { fetchFormQuestions } from '@app/admin/safety-programs/state/safety-programs.actions';

interface ICorrectiveActionsContainer {
  user?: UserType;
  projects: ProjectType[];
  team: UserType[];
  correctiveActions: CorrectiveActionsType[];
  isLoading: boolean;
  teamAccessUserID?: string;
  fetchUserCorrectiveActions: (userID: string) => void;
  fetchUserManagerAction: (userID: string) => void;
  fetchProjectsAction: (values: { accountID: number; userID: string }) => void;
  fetchContractorsAction: (accountID: number) => void;
  fetchCAServeritysAction: () => void;
  fetchCAStatusAction: () => void;
  fetchCATriggersAction: () => void;
  fetchCATypesAction: () => void;
  fetchPlansAction: (accountID: number) => void;
  fetchFormQuestionsAction: (payload: any) => void;
}

const CorrectiveActions = (props: ICorrectiveActionsContainer) => {
  const {
    user,
    team,
    isLoading,
    projects,
    teamAccessUserID,
    correctiveActions,
    fetchUserManagerAction,
    fetchUserCorrectiveActions,
    fetchProjectsAction,
    fetchContractorsAction,
    fetchCAServeritysAction,
    fetchCAStatusAction,
    fetchCATriggersAction,
    fetchCATypesAction,
    fetchPlansAction,
    fetchFormQuestionsAction,
  } = props;

  const [openCreateCAModal, toggleCreateCAModal] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      fetchUserManagerAction(user.user_id);
      fetchProjectsAction({ accountID: user.user_metadata.accountID, userID: user.user_id });
      fetchContractorsAction(user.user_metadata.accountID);
      fetchPlansAction(user.user_metadata.accountID);
      fetchCAServeritysAction();
      fetchCAStatusAction();
      fetchCATriggersAction();
      fetchCATypesAction();
    }
  }, [user]);

  useEffect(() => {
    if (teamAccessUserID) fetchUserCorrectiveActions(teamAccessUserID);
  }, [teamAccessUserID]);
  
  function handleFetchFormQuestions(formID: string) {
    fetchFormQuestionsAction({ params: { formID } });
  }

  return (
    <>
      <CorrectiveActionsComponent
        team={team}
        correctiveActions={correctiveActions}
        projects={projects}
        isLoading={isLoading}
        openCreateCAModal={openCreateCAModal}
        toggleCreateCAModal={toggleCreateCAModal}
        handleFetchFormQuestions={handleFetchFormQuestions}
      />
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  team: getTeam(state),
  correctiveActions: getUserCorrectiveActions(state),
  teamAccessUserID: getTeamAccessUserID(state),
  projects: getProgramsProjects(state),
  isLoading: getCAIsLoading(state),
});

const mapDispatchToProps = {
  fetchUserCorrectiveActions: fetchUnderUserCA.request,
  fetchProjectsAction: fetchProjectsAction.request,
  fetchCAServeritysAction: fetchCATriggers.request,
  fetchCAStatusAction: fetchCASeveritys.request,
  fetchCATriggersAction: fetchCAStatus.request,
  fetchCATypesAction: fetchCATypes.request,
  fetchPlansAction: fetchPlansInstanceAction.request,
  fetchUserManagerAction: fetchUserManagers.request,
  updateUserCorrectiveAction: UpdateUnderUserCA.request,
  fetchContractorsAction: fetchContractorsAction.request,
  fetchFormQuestionsAction: fetchFormQuestions.request,
};

export const CorrectiveActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(CorrectiveActions));
