import { Modal } from 'antd';
import React, { useState } from 'react'


export const useRenderPDF = (ref: any) => {

    const RenderPdf = () => {
        return (
            <a ref={ref} target="_blank" style={{ display: 'hidden' }}></a>
        )
    }

    return { RenderPdf }
}
