import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';
import { FETCH_FORM_QUESTIONS_API } from '@constants/endpoints';

const CHANGE_PLAN_FORMS = 'CHANGE_PLAN_FORMS';
const FETCH_FORM_QUESTIONS = 'FETCH_FORM_QUESTIONS';

export const changePlanFormsAction = createActionRoutine(CHANGE_PLAN_FORMS);
export const fetchFormQuestionsAction = createActionRoutine(
  FETCH_FORM_QUESTIONS,
  FETCH_FORM_QUESTIONS_API,
);
