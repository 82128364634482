import React from 'react';

import { SafetyPlanType } from '@shared/types/backend.types';

import { PlansListContainer } from '../list/state/list.container';
import { PlanFormsContainer } from '../forms/state/forms.container';

import '@components/back-office/safety-programs/plans.styles.scss';
import '@utils/styles/flex.css';
import '@utils/styles/width.css';

export interface IPlansComponentProps {
  plans: SafetyPlanType[];
  currentSubstep?: number;
  stepTracker?: any;
  handleChangeCurrentPlan: (nextStep: number, isFinished?: boolean) => void;
  handleValidateStep: (step: number, alternativeStatus: string) => void;
}

export const ProfessionalPlansComponent = (props: IPlansComponentProps) => {
  const { plans, currentSubstep, stepTracker } = props;
  const { handleChangeCurrentPlan, handleValidateStep } = props;

  return (
    <div className="plans__container" style={{ marginTop: 0, marginBottom: 0 }}>
      <div className="d-flex sm-w-100 justify-between">
        <div className="sm-w-30 md-w-30">
          <PlansListContainer
            plans={plans}
            currentSubstep={currentSubstep}
            stepTracker={stepTracker}
            handleChangeCurrentPlan={handleChangeCurrentPlan}
          />
        </div>
        <div className="sm-w-65 md-w-65">
          <PlanFormsContainer
            handleChangeCurrentPlan={handleChangeCurrentPlan}
            handleValidateStep={handleValidateStep}
          />
        </div>
      </div>
    </div>
  );
};
