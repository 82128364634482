import { SearchFilter } from '@app/back-office/corrective-actions/view/search-filter/search.filter';
import { HeadingComponent } from '@components/layout/header/heading.component';
import { GET_PTP_SUBMISSION_API } from '@constants/endpoints';
import {
  PTP,
  PTPSubmission,
  Submission,
} from '@shared/types/backend.types';
import { toTitleCase } from '@shared/utils/utils';
import { Badge, Icon, Table, Tag, Tooltip, message } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useRef } from 'react';
import { useRenderPDF } from '@shared/hooks/usepdf';

type props = {
  ptpSubmissions: PTPSubmission[];
  isPtpSubmissionLoading: boolean;
  submissionPtps: any;
  submissionUsers: any;
  submissionProjects: any;
  signaturesNames: any
};

const PtpSubmissionsComponent = (props: props) => {
  const {
    ptpSubmissions,
    isPtpSubmissionLoading,
    submissionUsers,
    submissionProjects,
    signaturesNames
  } = props;

  const ref = useRef<HTMLAnchorElement | undefined>(undefined);

  const { RenderPdf } = useRenderPDF(ref);

  const fetchSubmissionPdf = async (submissionId: number) => {
    try {
      message.loading({ key: "ptpSubmissionPdf", content: "Loading..." });
      const token = Cookies.get('sessionKey') || "";
      const endpoint = GET_PTP_SUBMISSION_API.replace(':ptpSubmissionId', `${submissionId}`);
      const res = await axios.get(endpoint, { headers: { Authorization: `Bearer ${token}` } })
      if (res && res.data && res.data.blobPath && ref && ref.current) {
        ref.current.href = res.data.blobPath
        ref.current.click();
        message.success({ key: "ptpSubmissionPdf", content: "Success" });
      }

    } catch (error) {
      message.error({ key: "ptpSubmissionPdf", content: "Error while fetching submission!" })
    }
  }


  const columns = [
    {
      title: `Total Submissions - ${ptpSubmissions.length}`,
      children: [
        {
          title: 'Project Number',
          dataIndex: 'ptp',
          key: 'projectNumber',
          render: (ptp: PTP) => ptp.project.number,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <SearchFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="pjtp.project.projectID"
            />
          ),
          filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          onFilter: (value: string, record: PTPSubmission) =>
          (console.log(record),
            record.ptp.project.projectID
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())),
        },
        {
          title: 'Project Name',
          dataIndex: 'ptp',
          key: 'projectName',
          filters: submissionProjects,
          render: (ptp: PTP) => ptp.project.name,
          onFilter: (value: any, record: PTPSubmission) =>
            record.ptp.project.projectID.toString().includes(value),
        },
        {
          title: 'Project Address',
          dataIndex: 'ptp',
          key: 'projectAddress',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <SearchFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="id"
            />
          ),
          onFilter: (value: string, record: PTPSubmission) => record.ptp.project.address.toString().toLowerCase().includes(value.toLowerCase()),
          render: (ptp: PTP) => ptp.project.address,
          filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
        },
        {
          title: 'PTP#',
          dataIndex: 'id',
          defaultSortOrder: 'ascend',
          width: '10%',
          sorter: (a: PTPSubmission, b: PTPSubmission) => a.id - b.id,
          render: (_: string, record: PTPSubmission) => <b className="sa-table-clickable">{`#${record.ptp.ptpHash}`}</b>,

          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <SearchFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="id"
            />
          ),
          filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          onFilter: (value: string, record: PTPSubmission) =>
            `#${record.id}`
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: 'Status',
          dataIndex: 'submissionStatus',
          render: (status: string) => (
            <Tag color={status === 'submitted' ? 'green' : 'red'}>{toTitleCase(status)}</Tag>
          ),
          filters: [
            { text: 'Submitted', value: 'submitted' },
            { text: 'Ready', value: 'ready' },
            { text: 'Pending', value: 'pending' },
          ],
          onFilter: (value: any, record: any) => record.submissionStatus.includes(value),
        },

        {
          title: 'Inspection Facilitator',
          dataIndex: 'submittedByUserName',
          render: (name: string, record: PTPSubmission) =>
            record.submittedByUserName ? record.submittedByUserName : <b>N/A</b>,
          filters: submissionUsers,
          onFilter: (value: string, record: PTPSubmission) =>
            record.submittedByUserId.includes(value),
        },

        {
          title: 'Signatures',
          dataIndex: 'signatures',
          render: (signatures: any[]) => (
            signatures && signatures.length ?
              signatures.map(({ signatureName }: any) =>
                <div style={{ marginBottom: 10 }}>
                  <Tag color='green'>{signatureName}</Tag>
                </div>
              )
              : (<b>N/A</b>)
          ),
          filters: signaturesNames,
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <SearchFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              signaturesNames={signaturesNames}
              dataIndex="id"
            />
          ),
          onFilter: (value: string, record: PTPSubmission) =>
            !!record.signatures.find(signature => signature.signatureName.toString().toLowerCase().includes(value.toLowerCase())),
        },

        {
          title: 'Submitted On',
          dataIndex: 'submissionDateTime',
          width: '10%',
          render: (date: string) => moment(date).format('MM-DD-YYYY'),
        },

        {
          title: '',
          width: '4%',
          key: 12,
          render: (_: any, rowData: PTPSubmission) => (
            <Tooltip title="download pdf">
              <Icon onClick={() => fetchSubmissionPdf(rowData.id)} className="table-sa__actionIcon" type="file-pdf" />
            </Tooltip>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <div className="sa__container">
        <div className="sa__box">
          <HeadingComponent title="Pre Task Plan Submissions" />
          <div className="sa__box-content">
            <Table
              className="table-sa"
              columns={columns as any}
              rowKey={submission => submission.id.toString()}
              bordered={true}
              loading={isPtpSubmissionLoading}
              dataSource={ptpSubmissions}
              pagination={false}
            />
          </div>
        </div>
      </div>

      <RenderPdf />
    </>
  );
};

export default PtpSubmissionsComponent;
