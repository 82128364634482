import {
  fetchFormsAction,
  changeCurrentPlanStepAction,
  createPlanStepsAction,
  validatePlansStepAction,
} from './plans.actions';

import { IProfessionalState } from '@back-office/professional.reducer';
import { AnyAction } from 'redux';

import update from 'immutability-helper';

import { programPlanFormsReducer } from '../forms/state/forms.reducer';

export const setupPlansReducer = {
  [fetchFormsAction.REQUEST]: (state: IProfessionalState) => ({
    ...state,
    isLoading: true,
  }),
  [fetchFormsAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    errorMessages: {
      ...state.errorMessages,
    },
  }),
  [fetchFormsAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    forms: payload.forms,
    isLoading: false,
  }),
  [createPlanStepsAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        stepTracker: {
          $set: { ...payload },
        },
      },
    }),
  [changeCurrentPlanStepAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        stepTracker: {
          [payload.planID]: {
            status: { $set: payload.status },
          },
        },
      },
    }),
  [validatePlansStepAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        meta: {
          validSteps: {
            [payload.step]: { $set: payload.isValid },
          },
        },
      },
    }),
  ...programPlanFormsReducer,
};
