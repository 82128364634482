import { combineActions } from 'redux-actions';
import { IAdminState } from '@app/admin/admin.reducer';
import { AnyAction } from 'redux';

import update from 'immutability-helper';
import {
  fetchSafetyPrograms,
  addSafetyForm,
  fetchSafetyForms,
  fetchFormQuestions,
  addSafetyProgram,
  addFormQuestion,
  removeFormQuestion,
  updateFormQuestion,
  fetchMultiQuestions,
  fetchSafetySubforms,
  addSafetySubforms,
  updateSafetyForm,
  removeSafetyForm,
  removeSafetyProgram,
} from './safety-programs.actions';

import { FormType, QuestionType, SafetyPlanType } from '@shared/types/backend.types';

export interface ISafetyProgramsState {
  programs: SafetyPlanType[];
  forms: FormType[];
  subForms: FormType[];
  questions: QuestionType[];
  multiQuestions: any[];
  reqState: { isLoading: boolean | undefined, isError: boolean | undefined, type: string | undefined, msg: string | undefined }
}

export const SAFETY_PROGRAMS_INITIAL_STATE: ISafetyProgramsState = {
  programs: [],
  forms: [],
  subForms: [],
  questions: [],
  multiQuestions: [],
  reqState: { isLoading: undefined, isError: undefined, type: undefined, msg: undefined },
};

export const adminSafetyProgramsReducer = {

  //* SAFETY PROGRAMS REDUCERS *// 

  [fetchSafetyPrograms.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        programs: { $set: payload },
        reqState: { isLoading: { $set: false } }
      },
    }),

  [addSafetyProgram.REQUEST]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: true }, type: { $set: addSafetyProgram.REQUEST } }
      },
    }),

  [addSafetyProgram.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        programs: { $set: [...state.safetyPrograms.programs, payload] },
        reqState: { isLoading: { $set: false }, type: { $set: addSafetyProgram.SUCCESS } }
      },
    }),

  [addSafetyProgram.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: addSafetyProgram.FAILURE }
        }
      },
    }),

  [removeSafetyProgram.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: removeSafetyProgram.REQUEST }
        }
      },
    }),

  [removeSafetyProgram.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {

    const removedPlans = state.safetyPrograms.programs.filter((plan: SafetyPlanType) => plan.safetyPlanID !== payload.safetyPlanID)

    return update(state, {
      safetyPrograms: {
        programs: { $set: removedPlans },
        reqState: { isLoading: { $set: false }, type: { $set: removeSafetyProgram.SUCCESS } }
      },
    })
  },

  [removeSafetyProgram.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: removeSafetyProgram.FAILURE }
        }
      },
    }),

  //* SAFETY FORMS REDUCERS *// 

  [fetchSafetyForms.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: fetchSafetyForms.REQUEST }
        }
      },
    }),

  [fetchSafetyForms.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      safetyPrograms: {
        forms: { $set: payload },
        reqState: {
          isLoading: { $set: false },
          type: { $set: fetchSafetyForms.SUCCESS }
        }
      },
    })
  },

  [fetchSafetyForms.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: fetchSafetyForms.FAILURE }
        }
      },
    }),

  [addSafetyForm.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: addSafetyForm.REQUEST }
        }
      },
    }),

  [addSafetyForm.SUCCESS]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: false }, type: { $set: addSafetyForm.SUCCESS } }
      },
    }),


  [addSafetyForm.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: addSafetyForm.FAILURE }
        }
      },
    }),

  [updateSafetyForm.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: updateSafetyForm.REQUEST }
        }
      },
    }),

  [updateSafetyForm.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {

    const updatedForms = payload.parentID ?
      ([...state.safetyPrograms.subForms
        .filter((form: FormType) => form.id !== payload.id)
        , payload
      ].sort((a: FormType, b: FormType) => a.sortOrder - b.sortOrder))
      : ([
        ...state.safetyPrograms.forms
          .filter((form: FormType) => form.id !== payload.id)
        , payload
      ].sort((a: FormType, b: FormType) => a.sortOrder - b.sortOrder))

    if (payload.parentID) {
      return update(state, {
        safetyPrograms: {
          subForms: { $set: updatedForms },
          reqState: { isLoading: { $set: false }, type: { $set: updateSafetyForm.SUCCESS } }
        },
      })
    } else {
      return update(state, {
        safetyPrograms: {
          forms: { $set: updatedForms },
          reqState: { isLoading: { $set: false }, type: { $set: updateSafetyForm.SUCCESS } }
        },
      })
    }

  },

  [updateSafetyForm.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: updateSafetyForm.FAILURE }
        }
      },
    }),


  [removeSafetyForm.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: removeSafetyForm.REQUEST }
        }
      },
    }),

  [removeSafetyForm.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: removeSafetyForm.FAILURE }
        }
      },
    }),

  [fetchSafetySubforms.REQUEST]: (state: IAdminState) => {
    return update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: fetchSafetySubforms.REQUEST }
        }
      },
    });
  },

  [fetchSafetySubforms.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      safetyPrograms: {
        subForms: { $set: payload },
        reqState: {
          isLoading: { $set: false },
          type: { $set: fetchSafetySubforms.SUCCESS }
        }
      },
    });
  },

  [fetchSafetySubforms.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: fetchSafetyForms.FAILURE }
        }
      },
    });
  },

  [addSafetySubforms.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: true },
          type: { $set: addSafetySubforms.REQUEST }
        }
      },
    }),

  [addSafetySubforms.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        subForms: { $set: [...state.safetyPrograms.forms, payload] },
        reqState: { isLoading: { $set: false }, type: { $set: addSafetySubforms.SUCCESS } }
      },
    }),

  [addSafetySubforms.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: addSafetySubforms.FAILURE }
        }
      },
    }),

  //* QUESTION REDUCERS *// 

  [fetchFormQuestions.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: true }, type: { $set: fetchFormQuestions.REQUEST } }
      },
    }),

  [fetchFormQuestions.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        questions: { $set: payload },
        reqState: { isLoading: { $set: false }, type: { $set: fetchFormQuestions.SUCCESS } }
      },
    }),

  [fetchFormQuestions.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: fetchFormQuestions.FAILURE }
        }
      },
    }),

  [fetchMultiQuestions.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: true }, type: { $set: fetchMultiQuestions.REQUEST } }
      },
    }),

  [fetchMultiQuestions.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        multiQuestions: { $set: payload },
        reqState: { isLoading: { $set: false }, type: { $set: fetchMultiQuestions.SUCCESS } }
      },
    }),

  [fetchMultiQuestions.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: fetchMultiQuestions.FAILURE }
        }
      },
    }),

  [addFormQuestion.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: true }, type: { $set: addFormQuestion.REQUEST } }
      },
    }),

  [addFormQuestion.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        questions: { $set: [...state.safetyPrograms.questions, payload] },
        reqState: { isLoading: { $set: false }, type: { $set: addFormQuestion.SUCCESS } }
      },
    }),

  [addFormQuestion.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: addFormQuestion.FAILURE }
        }
      },
    }),


  [updateFormQuestion.REQUEST]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: true }, type: { $set: updateFormQuestion.REQUEST } }
      },
    }),

  [updateFormQuestion.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const updatequestions =
      [
        ...state.safetyPrograms.questions
          .filter((question: QuestionType) => question.id !== payload.id)
        , payload
      ]
        .sort((a: QuestionType, b: QuestionType) => a.orderSequence - b.orderSequence)

    return update(state, {
      safetyPrograms: {
        questions: { $set: updatequestions },
        reqState: { isLoading: { $set: false }, type: { $set: updateFormQuestion.SUCCESS } }
      },
    })
  },

  [updateFormQuestion.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: updateFormQuestion.FAILURE }
        }
      },
    }),

  [removeFormQuestion.REQUEST]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: false }, type: { $set: removeFormQuestion.REQUEST } }
      },
    }),

  [removeFormQuestion.SUCCESS]: (state: IAdminState) =>
    update(state, {
      safetyPrograms: {
        reqState: { isLoading: { $set: false }, type: { $set: removeFormQuestion.SUCCESS } }
      },
    }),

  [removeFormQuestion.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        reqState: {
          isLoading: { $set: false },
          isError: { $set: true },
          msg: { $set: payload },
          type: { $set: removeFormQuestion.FAILURE }
        }
      },
    }),

  //* COMBINE REDUCERS *//

  [combineActions(
    fetchSafetyPrograms.REQUEST,

  ) as any]: (state: IAdminState) =>
      update(state, {
        safetyPrograms: {
          reqState: {
            isLoading: { $set: true },
          }
        },
      }),

  [combineActions(
    fetchSafetyPrograms.FAILURE,

  ) as any]: (state: IAdminState, { payload }: AnyAction) =>
      update(state, {
        safetyPrograms: {
          reqState: {
            isLoading: { $set: false },
            isError: { $set: true },
            msg: { $set: payload }
          }
        },
      }),

};
