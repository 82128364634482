import React, { useEffect, useState } from 'react';

// 
import { connect } from 'react-redux';

// ACTIONS
import { fecthUsersConfigAction } from '@app/admin/accounts/state/accounts.actions';
import { fetchTeamAction } from '@app/back-office/team/state/team.actions';

// SELECTORS
import { getUsersConfig } from '@app/admin/accounts/state/accounts.selector';
import { getTeam } from '@app/back-office/team/state/team.selector';
import { getUserCompany } from '@app/session/session.selector';
import { getSessionUser } from '@app/session/session.selector';

//TYPES
import { UserType, UsersConfigType, AccountType } from '@shared/types/backend.types';

// Helper Functions
import { getUnderUsers } from '@shared/utils/filterSubUsers';
import _ from 'lodash';
import { IAppState } from '@rdx/root.reducer';

import CAReportsComponent from '../view/ca-reports.component';

interface ICAReportsContainerProps {
    reports: any;
    company?: AccountType;
    user?: UserType;
    team: UserType[]
    usersConfig: UsersConfigType[];
    fecthUsersConfig: (loggedUserID: number) => void;
    fetchTeamAction: (accountID: number) => void;
}

export const CAReports = (props: ICAReportsContainerProps) => {

    const { team, user, company, usersConfig, fecthUsersConfig, fetchTeamAction } = props;
    const [userConfig, setUserConfig] = useState<UsersConfigType | undefined>(undefined)
    const [underUsers, setUnderUsers] = useState([] as any);
    const [selectedUser, setSelectedUser] = useState<UserType | undefined>(undefined)
    const [loggedUser, setLoggedUser] = useState<undefined | UserType>(undefined)

    useEffect(() => {
        if (user) {
            setLoggedUser(user);
        }
    }, [user])

    useEffect(() => {
        if (loggedUser) {
            fecthUsersConfig(loggedUser.user_metadata.accountID)
            fetchTeamAction(loggedUser.user_metadata.accountID)
        }
    }, [loggedUser])

    useEffect(() => {
        if (loggedUser)
            setUserConfig(usersConfig.filter((userConfig: UsersConfigType) => userConfig.user_id === loggedUser.user_id)[0])
    }, [usersConfig])

    useEffect(() => {
        if (loggedUser) {
            setUnderUsers(getUnderUsers(team, loggedUser.user_id));
        }
    }, [team]);

    useEffect(() => {
        if (loggedUser) {
            setSelectedUser(team.filter((user: UserType) => user.user_id === loggedUser.user_id)[0])
        }
    }, [team]);

    const sortedTeam = _.orderBy(underUsers, ['tier'], ['asc']);

    return (
        <CAReportsComponent
            loggedUserConfig={userConfig}
            user={loggedUser}
            company={company}
            underUsers={sortedTeam}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
        />
    )
}

const mapStateToProps = (state: IAppState) => ({
    company: getUserCompany(state),
    user: getSessionUser(state),
    usersConfig: getUsersConfig(state),
    team: getTeam(state),
});

const mapDispatchToProps = {
    fecthUsersConfig: fecthUsersConfigAction.request,
    fetchTeamAction: fetchTeamAction.request,
};

const CAReportsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CAReports);

export default CAReportsContainer;
