import React from 'react';

import { ThemeType } from '@shared-types/theme.type';
import { Classes } from 'jss';
import { StepType } from '@shared-types/professional.types';

import { Steps } from 'antd';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import './steps.styles.scss';

export interface IStepsComponentProps {
  currentTitle: string;
  steps: StepType[];
  classes: Classes;
  currentStep?: number;
}

const { Step } = Steps;

const ProgramSteps = (props: IStepsComponentProps) => {
  const { steps, currentStep, classes } = props;
  const { stepContainerStyles, numberedStepStyles } = classes;

  //TODO: add type
  const renderSteps = () =>
    steps.map((step: StepType) => (
      <Step key={step.id} status={step.status as any} className={numberedStepStyles} />
    ));

  return (
    <div className="step__header">
      <h1 className="step__title" style={{ fontWeight: 'bold', paddingLeft: '8%' }}>
        {currentStep ? steps[currentStep].title : "Let's add your plans"}
      </h1>
      <div>
        <Steps
          className={classnames(stepContainerStyles, 'step__container')}
          size="default"
          current={currentStep}
        >
          {renderSteps()}
        </Steps>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  stepContainerStyles: {
    '& .ant-steps-item-icon': {
      border: '3px solid #fff',
      background: theme.green.main,
    },
    '& .ant-steps-item-finish .ant-steps-item-icon': {
      border: `4px solid ${theme.green.main}`,
    },
    '& .ant-steps-item-wait .ant-steps-item-icon': {
      background: 'none !important',
    },
    '& .ant-steps-item-wait .ant-steps-item-icon, & .ant-steps-item-process .ant-steps-item-icon': {
      border: `4px solid ${theme.grey.steps}`,
      backgroundClip: 'content-box',
      padding: '2px',
    },
    '& .ant-steps-item-active span': {
      display: 'none',
    },
    '& .ant-steps-item .ant-steps-icon': {
      color: '#fff',
      '& .ant-steps-icon-dot': {
        backgroundColor: theme.green.main,
      },
    },
    '& .ant-steps-item-title:after, & .ant-steps-item-title:after': {
      backgroundColor: `${theme.grey.steps} !important`,
    },
    '& .ant-steps-item-finish .ant-steps-item-title:after': {
      backgroundColor: `${theme.green.main} !important`,
    },
  },
  numberedStepStyles: {
    '& .anticon': {
      color: '#fff',
    },
  },
});

export const ProgramStepsComponent = injectSheet(styles)(ProgramSteps);
