export const IMAGE_COMPANY_RESTRICTIONS = {
  minHeight: 400,
  minWidth: 400,
  maxHeight: 720,
  maxWidth: 1280,
  maxSize: 2097152,
  maxSizeFormatted: '2 MB',
};

export const FILE_TYPES = {
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
};

export const FILE_EXTENSION = {
  csv: '.csv',
  xlsx: '.xlsx'
};
