import React, { useState } from 'react'
import { Modal, Carousel, Icon } from 'antd'
import { CertificateImageType, UserCertificateType } from '@shared/types/backend.types';


interface ICertificatesImgProps {
    certificate: UserCertificateType;
}

export const UserCertificateImgModal = (props: ICertificatesImgProps) => {
    const { certificate } = props;

    const [openCertificateImgModal, setCertificateImgModal] = useState(false);



    return (
        <>
            <div className='certificates-modal__img-backdrop' onClick={() => { setCertificateImgModal(true) }} ></div>
            <Modal
                title={`Licenses / Certificates - ${certificate.certificateTitle}`}
                className="image-modal"
                visible={openCertificateImgModal}
                destroyOnClose={true}
                onCancel={() => { setCertificateImgModal(false) }}
                footer={false}
            >
                <table className='sa-table-textLeft'>
                    <div className='image-modal__InfoContainer'>
                        <div className='title'>
                            <h3><b>Title:</b>&nbsp;{certificate.certificateTitle}</h3>
                        </div>
                        <div className='dates'>
                            <h3><b>Issue Date:</b>&nbsp;{certificate.certificateIssueDate !== "" ? certificate.certificateIssueDate : <b>N/A</b>}</h3>
                            <h3><b>Expiration Date:</b>&nbsp;{certificate.certificateExpirationDate !== "" ? certificate.certificateExpirationDate : <b>N/A</b>}</h3>
                        </div>
                    </div>
                </table>
                <div className='image-modal__ImgContainer'>
                    {
                        certificate.certificateImageUri.length > 0 ? (
                            <Carousel arrows={true} dots={true} prevArrow={<Icon type="left" />} nextArrow={<Icon type="right" />} infinite={false} draggable={true}>
                                {certificate.certificateImageUri.map((image: CertificateImageType) => (
                                    <img src={image.certificateImageURL} />
                                ))}
                            </Carousel>
                        )
                            : null
                    }
                </div>
            </Modal>
        </>
    )
}

