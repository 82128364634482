// REACT ESSENTIALS
import React, { FormEvent, useEffect, useState } from 'react'

// HOOKS
import { useDispatch, useSelector } from 'react-redux';

// ANTD COMPONENTS
import { Form, Input, Button, Modal, Select, Checkbox, DatePicker, message } from 'antd';

// STYLES
import './create-ca.styles.scss'

// TYPES
import { ProjectType, QuestionType, UserType } from '@shared/types/backend.types';
import { IAppState } from '@rdx/root.reducer';
import { WrappedFormUtils } from 'antd/es/form/Form';

// HELPERS
import { getList } from '@shared/helpers/state-caster';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { createCorrectiveAction } from '@app/back-office/corrective-actions/state/corrective-actions.actions';

interface ICreateCAProps {
    openModal: boolean;
    form: WrappedFormUtils
    toggleModal: (value: React.SetStateAction<boolean>) => void;
    handleFetchFormQuestions(formID: string): void;
}

export const CreateCA = (props: ICreateCAProps) => {
    // HOOKS INSTANCE
    const dispacth = useDispatch();

    // STORE DATA
    const { admin: { safetyPrograms: { questions } }, professional, session: { user } } = useSelector((state: IAppState) => state);
    const { team, correctiveActions, programs: { projects }, safetyPrograms } = professional;
    const { severitys, triggers } = correctiveActions;

    const safetyPlans = React.useMemo(() => getList(safetyPrograms.items), [safetyPrograms.items]);

    // PROPS
    const { openModal, form, toggleModal,handleFetchFormQuestions } = props;
    const { getFieldDecorator } = form

    // LOCAL STATES
    const [showField, setShowField] = useState<boolean>(false);
    const [safetyPlanID, setSafetyPlanID] = useState<number | undefined>(undefined);
    const [safetyForms, setSafetyForms] = useState<any>([]);
    const [subForms, setSubForms] = useState<any>([]);


    const getSubForms = (value: string) => {
        const safetyForm = safetyForms.find((safetyform: any) => safetyform.id === value);
        if (!safetyForm)
            return setSubForms([]);

        const subforms = getList(safetyForm.subForms);
        if (!subforms || !(subforms && subforms.length))
            return setSubForms([]);

        return setSubForms(subforms);
    }

    useEffect(() => {
        const selectedSafetyPlan = safetyPlans.find((sefetyPlan: any) => sefetyPlan.safetyPlanID === safetyPlanID);
        if (!selectedSafetyPlan)
            return setSafetyForms([]);

        const safetyForms = getList(selectedSafetyPlan.forms);
        if (!safetyForms.length)
            return setSafetyForms([]);

        return setSafetyForms(safetyForms);


    }, [safetyPlanID])


    return (
        <Modal
            title="New Corrective Action"
            className='create-ca-modal'
            visible={openModal}
            onCancel={() => toggleModal(false)}
            footer={null}
        >
            <Form
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    form.validateFields((err, values) => {
                        if (err) {
                            return;
                        }

                        if (user) {
                            dispacth(createCorrectiveAction.request(
                                {
                                    ...values,
                                    ...{ status: 'Opened', openedBy: user.user_id, accountID: user.user_metadata.accountID },
                                    safetyFormID: values.subSafetyFormID ? values.subSafetyFormID : values.safetyFormID
                                }
                            ))
                            toggleModal(false);
                        }
                    })
                }} >

                <Form.Item
                    label={<b>Situation Statement</b>}
                    style={{ marginBottom: 15 }}
                >
                    {getFieldDecorator('title', {
                        rules: [
                            {
                                required: true,
                                message: 'Please, input situation statement',
                            },
                        ],
                    })(
                        <Input placeholder='Situation Statement' className='sa-input' />
                    )}
                </Form.Item>

                <Form.Item
                    label={<b>Narrative</b>}
                    style={{ marginBottom: 15 }}
                >
                    {getFieldDecorator('description', {
                        rules:
                            [
                                {
                                    type: 'string',
                                    required: true,
                                    message: "Please input situation narative",
                                },
                            ],
                    })(
                        <Input.TextArea placeholder='Narative' className='sa-input' />
                    )}
                </Form.Item>

                <Form.Item
                    label={<b>Project</b>}
                    style={{ marginBottom: 15 }}
                >
                    {getFieldDecorator('projectID', {
                        rules:
                            [
                                {
                                    type: 'number',
                                    required: true,
                                    message: "Please select project",
                                },
                            ],
                    })(
                        <Select placeholder="Project" className='sa-select'>
                            <Select.OptGroup label="Select Project">
                                {
                                    getList(projects).map((project: ProjectType, dataIndex: number) => (
                                        <Select.Option key={dataIndex} value={project.projectID}>{project.name}</Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>



                <Form.Item
                    label={<b>SafetyPlan</b>}
                    style={{ marginBottom: 15 }}
                >
                    {getFieldDecorator('safetyPlanID', {
                        rules:
                            [
                                {
                                    type: 'number',
                                    required: true,
                                    message: "Please select Safety Plan",
                                },
                            ],
                    })(
                        <Select onChange={(value: number) => setSafetyPlanID(value)} placeholder="Select SafetyPlan" className='sa-select'>
                            <Select.OptGroup label="Select SafetyPlan">
                                {
                                    safetyPlans.map((safetyPlan: any, dataIndex: number) => (
                                        safetyPlan.isEnable &&
                                        <Select.Option key={dataIndex} value={safetyPlan.safetyPlanID}>{safetyPlan.name}</Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    label={<b>Safety Form</b>}
                    style={{ marginBottom: 15 }}
                >
                    {getFieldDecorator('safetyFormID', {
                        rules:
                            [
                                {
                                    type: 'string',
                                    required: true,
                                    message: "Please select Safety Form",
                                },
                            ],
                    })(
                        <Select
                            className='sa-select'
                            placeholder="Select Safety Form"
                            onChange={(value: string) => {
                                getSubForms(value)
                                handleFetchFormQuestions(value);
                            }}
                        >
                            <Select.OptGroup label="Select Safety Form">
                                {
                                    safetyForms && safetyForms.length && safetyForms.map((safetyForm: any, dataIndex: number) => (
                                        safetyForm.isSelected &&
                                        <Select.Option key={dataIndex} value={safetyForm.id}>{safetyForm.name}</Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>


                {
                    subForms && subForms.length ?
                        <Form.Item
                            label={<b>Sub Form</b>}
                            style={{ marginBottom: 15 }}
                        >
                            {getFieldDecorator('subSafetyFormID', {
                                rules:
                                    [
                                        {
                                            type: 'string',
                                            required: true,
                                            message: "Please select Safety Form",
                                        },
                                    ],
                            })(
                                <Select
                                    className='sa-select'
                                    placeholder="Select Safety Form"
                                    onChange={(value: string) => {
                                        handleFetchFormQuestions(value);
                                    }}
                                >
                                    <Select.OptGroup label="Select Safety Form">
                                        {
                                            subForms.map((subForm: any, dataIndex: number) => (
                                                <Select.Option key={dataIndex} value={subForm.id}>{subForm.name}</Select.Option>
                                            ))
                                        }
                                    </Select.OptGroup>
                                </Select>
                            )}
                        </Form.Item>

                        : null
                }

                <Form.Item
                    label={<b>Form Question</b>}
                    style={{ marginBottom: 15 }}
                >
                    {getFieldDecorator('safetyQuestionID', {
                        rules:
                            [
                                {
                                    type: 'string',
                                    required: true,
                                    message: "Please select form question",
                                },
                            ],
                    })(
                        <Select placeholder="Form Question" className='sa-select'>
                            <Select.OptGroup label="Select Form Question">
                                {
                                    questions && questions.length && questions.map((question: QuestionType, dataIndex: number) => (
                                        <Select.Option key={dataIndex} className='ca-question-option' value={question.id}>{question.description}</Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>


                <div className='container'>
                    <Form.Item
                        label={<b>Incident Type</b>}
                        style={{ marginBottom: 15 }}
                    >
                        {getFieldDecorator('trigger', {
                            rules:
                                [
                                    {
                                        type: 'string',
                                        required: true,
                                        message: "Please select the trigger cause!",
                                    },
                                ],
                        })(
                            <Select placeholder="Trigger" className='sa-select'>
                                <Select.OptGroup label="Select Action Trigger">
                                    {
                                        triggers.map((trigger: string, dataIndex: number) => (
                                            <Select.Option key={dataIndex} value={trigger}>{trigger}</Select.Option>
                                        ))
                                    }
                                </Select.OptGroup>
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item
                        label={<b>Risk Level</b>}
                        style={{ marginBottom: 15 }}
                    >
                        {getFieldDecorator('severity', {
                            rules:
                                [
                                    {
                                        type: 'string',
                                        required: true,
                                        message: "Please select the serverity of action!",
                                    },
                                ],
                        })(
                            <Select placeholder="Severity" className='sa-select'>
                                <Select.OptGroup label="Select Action Severity">
                                    {
                                        severitys.map((severity: string, dataIndex: number) => (
                                            <Select.Option key={dataIndex} value={severity}>{severity}</Select.Option>
                                        ))
                                    }
                                </Select.OptGroup>
                            </Select>
                        )}
                    </Form.Item>
                </div>

                <div className='container'>
                    <Form.Item
                        label={<b>Incident Location</b>}
                        style={{ marginBottom: 15 }}
                    >
                        {getFieldDecorator('incidentLocation', {
                            rules:
                                [
                                    {
                                        required: true,
                                        type: 'string'
                                    },
                                ],
                        })(
                            <Input className='sa-input' />
                        )}
                    </Form.Item>

                    <Form.Item label={<b>Incident Date & Time</b>}>
                        {getFieldDecorator('incidentTime', {
                            rules:
                                [
                                    {
                                        required: true,
                                        message: 'Incident date and time is required'
                                    },
                                ],
                        })(
                            <DatePicker showTime format="MM/DD/YYYY HH:mm" className='sa-input-time' />
                        )}
                    </Form.Item>
                </div>

                <Form.Item
                    label={<b>Assigned To</b>}
                    style={{ marginBottom: 15 }}
                >

                    {getFieldDecorator('assignedTo', {
                        rules:
                            [
                                {
                                    type: 'string',
                                    required: true,
                                    message: "Please assign the action!",
                                },
                            ],
                    })(
                        <Select placeholder="Assigned To" className='sa-select'>
                            <Select.OptGroup label="Select User">
                                {
                                    getList(team.items).map((associate: UserType) => (
                                        <Select.Option key={associate.user_id} value={associate.user_id}>{associate.name}</Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    label={
                        <>
                            <b>Action taken on spot</b>
                            <Checkbox style={{ marginLeft: 10 }} value={showField} onChange={(e: CheckboxChangeEvent) => { setShowField(!showField) }} />
                        </>
                    }
                >
                    {getFieldDecorator('actionDesc', {
                        rules:
                            [
                                {
                                    type: 'string',
                                    message: "Please input action description!",
                                },
                            ],
                    })(
                        <Input.TextArea className='sa-textarea' disabled={!showField} />
                    )}
                </Form.Item>


                <div className='ant-modal-body__form-footer' style={{ marginBottom: 10 }}>
                    <Button
                        htmlType="submit"
                        type='primary'
                    >
                        Create CA
                    </Button>

                    <Button type='default' onClick={() => toggleModal(false)}> Cancel </Button>
                </div>


            </Form>
        </Modal>
    )
}

const CreateCACompoent: any = Form.create({ name: 'CreateCA' })(CreateCA);

export default CreateCACompoent;