import React, { useEffect, useState, SetStateAction, FormEvent } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { AccountType, PackageSeatDetail, UserType, ContractorType } from '@shared/types/backend.types';
import { IFormInvite } from '@shared/types/forms.types';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  getHasSentInvite,
  getInviteErrors,
  getIsLoadingInvite,
} from '@admin/invite/state/invite.selector';

import { fetchContractorsAction, addContractorAction, addCachedContractorAction } from '@app/admin/accounts/contractors/state/contractors.actions';
import { getAccountsContractors } from '@app/admin/accounts/contractors/state/contractors.selector';

import { getAccountCompanyInformation } from '@app/admin/accounts/state/accounts.selector';


import { getAccountID } from '@app/admin/invite/state/invite.selector';

import { getSessionUser } from '@app/session/session.selector';
import { submitInviteAction } from '@admin/admin.action';

import Swal from 'sweetalert2';
import { AdminInviteComponent } from '../view/invite.component';

import cityBackground from '@assets/city-background-x2.png';
import { baseTheme } from '@shared/themes/presets/base.theme';
import { ROLE } from '@constants/roles';
import { getSeatsDetails, getAccounts } from '@app/admin/accounts/state/accounts.selector';
import { fetchSeatsInfoAction, fetchAccountsAction } from '@app/admin/accounts/state/accounts.actions';

import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';
import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { isEmail } from '@app/back-office/programs/programs.validations';

export interface IAdminInviteContainerProps {
  accounts: AccountType[];
  selectedCompanyInformation: any;
  contractorsCompany: ContractorType[]
  selectedAccountID?: number;
  selectedCompanyInAccounts?: number;
  defaultCompanyID?: number;
  isSubmittingInvite: boolean;
  hasSentInvite?: boolean;
  user?: UserType;
  //TODO: add correct type
  seatsInformation: any;
  accountsTeamInformation: any;
  errors?: any;
  fetchAccounts: () => void;
  fetchContractorsAction: (AccountID?: number) => void;
  fetchSeatsInfo: (payload: any) => void;
  fetchAccountUsersInfo: (payload: any) => void;
  submitInviteAction: (form: IFormInvite) => void;
  resetInviteAction: () => void;
}

const AdminInvite = (props: IAdminInviteContainerProps) => {

  const { accounts, contractorsCompany, selectedAccountID, selectedCompanyInformation, hasSentInvite, isSubmittingInvite, errors, seatsInformation, accountsTeamInformation } = props;
  const { submitInviteAction, fetchContractorsAction, resetInviteAction, fetchSeatsInfo, fetchAccountUsersInfo, fetchAccounts } = props;

  const intlProvider = useIntl();

  const [inviteEmail, setInviteEmail] = useState<SetStateAction<any>>('');
  const [seatsInfo, setSeatInfo] = useState<SetStateAction<any>>();
  const [reportsToUsersInfo, setReportsToUsersInfo] = useState<SetStateAction<any>>();
  const [selectedLicense, setSelectedLicense] = useState<SetStateAction<string>>('');
  const [selectedReportTo, setSelectedReportsTo] = useState<SetStateAction<string>>('');
  const [selectedCompnay, setSelectedCompany] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [inviteType, setInviteType] = useState('send-invite');

  useEffect(() => {
    if (accounts.length === 0) {
      fetchAccounts();
    }
  }, [accounts]);

  useEffect(() => {
    if (selectedAccountID)
      setSelectedCompany(selectedCompanyInformation)
  }, [selectedAccountID])

  useEffect(() => {
    if (selectedAccountID) {
      fetchSeatsInfo(selectedAccountID);
      fetchAccountUsersInfo(selectedAccountID);
      fetchContractorsAction(selectedAccountID);
    }
  }, [selectedAccountID])

  useEffect(() => {
    setSeatInfo(seatsInformation);
  }, [seatsInformation])

  useEffect(() => {
    setReportsToUsersInfo(accountsTeamInformation);
  }, [accountsTeamInformation])


  useEffect(() => {
    if (hasSentInvite !== undefined) {
      if (errors) {
        Swal.fire({
          title: intlProvider.messages['modal.error.ups'].toString(),
          text: `${errors}`,
          type: 'error',
          confirmButtonColor: baseTheme.red.error,
        });
      } else {
        Swal.fire({
          title: intlProvider.messages['modal.invite.title'],
          text: `${intlProvider.messages['modal.invite.message']} ${inviteEmail}!`,
          type: 'success',
          confirmButtonColor: baseTheme.green.aside,
        });
      }
    }

    return () => {
      resetInviteAction();
    };
  }, [hasSentInvite, inviteEmail, errors, intlProvider.messages, resetInviteAction]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>, form: any): void => {
    event.preventDefault();
    form.validateFieldsAndScroll((err: string, formValues: IFormInvite) => {
      if (!err) {

        const selectedLicenceType = seatsInfo.find((p: PackageSeatDetail) => p.packageID === formValues.package);
        let localSelectedCompany = accounts.find(c => c.accountID === selectedAccountID);

        if (!selectedLicenceType || !localSelectedCompany)
          return false;

        var availableSeats = selectedLicenceType.availableSeats; //selectedCompany.noLicenses - selectedCompany.usedSeats;

        var pass = availableSeats >= 1;

        if (!pass) {
          Swal.close();
          Swal.fire({
            title: '',
            type: 'error',
            html: `<div>You are attempting to INVITE more users to your account than available licenses.</div> <br /> <div><strong>Available Licenses</strong> : ` + availableSeats + `.</div> <br />Change the number of Inviting users or contact us at (<a href="mailto:sales@velocityvue.com">sales@velocityvue.com</a>) to add additional Licenses.`,
            confirmButtonColor: baseTheme.red.error,
          });
          return false;
        }

        const name = `${formValues.givenName} ${formValues.familyName}`;
        const accountID = selectedAccountID;

        delete formValues.inviteType;
        
        //is needed to send roleID and name to create administrator role user
        submitInviteAction({
          ...formValues,
          name,
          accountID,
          app_metadata: { role: { name: ROLE[2], roleID: 2 } },
          form: form
        });
        setInviteEmail(formValues.email);
      }
    });
  };

  return (
    <div>
      <div className="invite__cityImage">
        <img className="city-image" src={cityBackground} width="80%" alt="Safety Assist" />
      </div>
      <AdminInviteComponent
        selectedCompnay={selectedCompnay}
        showModal={showModal}
        inviteType={inviteType}
        setInviteType={setInviteType}
        setShowModal={setShowModal}
        contractorsCompany={contractorsCompany}
        selectedAccountID={selectedAccountID}
        seatsInfo={seatsInfo}
        reportsToUsersInfo={reportsToUsersInfo}
        setSelectedLicense={setSelectedLicense}
        setSelectedReportsTo={setSelectedReportsTo}
        isSubmittingInvite={isSubmittingInvite}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  accounts: getAccounts(state),
  selectedAccountID: getAccountID(state),
  selectedCompanyInformation: getAccountCompanyInformation(state),
  contractorsCompany: getAccountsContractors(state),
  hasSentInvite: getHasSentInvite(state),
  seatsInformation: getSeatsDetails(state),
  accountsTeamInformation: getAccountsTeam(state),
  isSubmittingInvite: getIsLoadingInvite(state),
  errors: getInviteErrors(state),
});

const mapDispatchToProps = {
  fetchAccounts: fetchAccountsAction.request,
  addContractorAction: addContractorAction.request,
  fetchContractorsAction: fetchContractorsAction.request,
  submitInviteAction: submitInviteAction.request,
  addCachedContractorAction: addCachedContractorAction.trigger,
  fetchSeatsInfo: fetchSeatsInfoAction.request,
  fetchAccountUsersInfo: fetchAccountUsersAction.request,
  resetInviteAction: submitInviteAction.fulfill,
};

export const AdminInviteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminInvite);
