import React, { useState, useEffect, SetStateAction } from 'react';

import { History } from 'history';
import { IAppState } from '@rdx/root.reducer';

import { withRouter } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { SendRestoreEmailFormComponent } from '../view/send-restore.component';
import { submitPasswordRestoreAction } from '@session/session.action';

import { getIsLoadingSession } from '@session/session.selector';

import Swal from 'sweetalert2';

import { baseTheme } from '@themes/presets/base.theme';
import { STATUS } from '@constants/status';

export interface ISendRestoreEmailFormProps {
  isLoading: boolean;
  history: History;
  submitPasswordRestoreAction: (username: string) => void;
}

const RestorePassword = (props: ISendRestoreEmailFormProps) => {
  const { submitPasswordRestoreAction, isLoading, history } = props;
  const [hasSentEmail, setHasSentEmail] = useState<SetStateAction<boolean | null>>(null);
  const intlProvider = useIntl();

  useEffect(() => {
    if (hasSentEmail && !isLoading) {
      Swal.fire({
        title: intlProvider.messages['modal.restorePassword.title'].toString(),
        text: intlProvider.messages['modal.restorePassword.message'].toString(),
        type: STATUS.success,
        confirmButtonColor: baseTheme.green.aside,
      }).then(() => {
        history.push('/login');
      });
    } else if (hasSentEmail === false) {
      Swal.fire({
        title: intlProvider.messages['modal.ups'].toString(),
        text: intlProvider.messages['modal.restorePassword.error'].toString(),
        type: STATUS.error,
        confirmButtonColor: baseTheme.red.error,
      }).then(() => setHasSentEmail(false));
    }
  }, [isLoading, hasSentEmail, history, intlProvider.messages]);

  const handleSubmit = (form: any) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err) {
        submitPasswordRestoreAction(formValues.username);
        setHasSentEmail(true);
      }
    });
  };

  return <SendRestoreEmailFormComponent isLoading={isLoading} handleSubmit={handleSubmit} />;
};

const mapStateToProps = (state: IAppState) => ({
  isLoading: getIsLoadingSession(state),
});

const mapDispatchToProps = {
  submitPasswordRestoreAction: submitPasswordRestoreAction.request,
};

export const SendRestoreEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RestorePassword as any));
