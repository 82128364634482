import { handleActions } from 'redux-actions';

import {
  accountsReducer,
  IAccountState,
  ACCOUNTS_INITIAL_STATE,
} from './accounts/state/accounts.reducer';

import {
  IAdminInviteState,
  INVITE_INITIAL_STATE,
  adminInviteReducer,
} from './invite/state/invite.reducer';

import {
  SAFETY_PROGRAMS_INITIAL_STATE,
  ISafetyProgramsState,
  adminSafetyProgramsReducer,
} from './safety-programs/state/safety-programs.reducer';

import {
  ACCOUNTS_SUBMISSIONS_INITIAL_STATE,
  IAccountSubmissionsState,
  accountsSubmissionsReducer,
} from './submissions/state/submissions.reducer';
import { ICorrectiveActionsState, CORRECTIVE_ACTIONS_INITIAL_STATE, correctiveActionsReducer } from './corrective-actions/state/corrective-actions.reducer';
import { HAZARD_INITIAL_STATE, IHazardState, hazardsReducer } from './hazard/state/hazard.reducer';

export interface IAdminState {
  accounts: IAccountState;
  invite: IAdminInviteState;
  safetyPrograms: ISafetyProgramsState;
  correctiveActions: ICorrectiveActionsState;
  hazards: IHazardState;
  isLoading: boolean;
  errorMessages?: {};
  projectSubmissions: IAccountSubmissionsState;
  accountID?: number;

}

export const ADMIN_INITIAL_STATE: IAdminState = {
  accounts: ACCOUNTS_INITIAL_STATE,
  invite: INVITE_INITIAL_STATE,
  safetyPrograms: SAFETY_PROGRAMS_INITIAL_STATE,
  correctiveActions: CORRECTIVE_ACTIONS_INITIAL_STATE,
  hazards: HAZARD_INITIAL_STATE,
  isLoading: false,
  errorMessages: undefined,
  accountID: undefined,
  projectSubmissions: ACCOUNTS_SUBMISSIONS_INITIAL_STATE,
};

export const adminReducer = handleActions(
  {
    ...adminInviteReducer,
    ...accountsReducer,
    ...adminSafetyProgramsReducer,
    ...accountsSubmissionsReducer,
    ...correctiveActionsReducer,
    ...hazardsReducer
  },
  ADMIN_INITIAL_STATE,
);
