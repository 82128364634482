import React, { useState, useReducer, useEffect, SetStateAction, FormEvent } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { connect } from 'react-redux';

import { inviteTeamPayload } from '../invite-team';

import Swal from 'sweetalert2';

import { InviteTeamComponent } from '../view/invite-team.component';
import { getSessionUser } from '@app/session/session.selector';

import { getList, arrayToObject } from '@shared/helpers/state-caster';
import { UserType, AccountType, PackageSeatDetail, ContractorType } from '@shared/types/backend.types';
import { getEmployeesValidated, validateTeam } from '@app/back-office/programs/programs.validations';
import { baseTheme } from '@shared/themes/presets/base.theme';

import { ROLE } from '@constants/roles';
import { STATUS } from '@constants/status';

import { IFormInvite } from '@shared/types/forms.types';

import { getAccountID } from '@app/admin/invite/state/invite.selector';
import { getAccountCompanyInformation } from '@app/admin/accounts/state/accounts.selector';


import { getSeatsDetails, getAccounts } from '@app/admin/accounts/state/accounts.selector';
import { fetchSeatsInfoAction, fetchAccountsAction } from '@app/admin/accounts/state/accounts.actions';

import { fetchContractorsAction } from '@app/admin/accounts/contractors/state/contractors.actions';
import { getAccountsContractors } from '@app/admin/accounts/contractors/state/contractors.selector';

import { getAccountsTeam, getTeamOwner } from '@app/admin/accounts/team/state/team.selector';
import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { submitInviteTeamAction } from './invite-team.saga';


export interface InviteTeamContainer {
  user: any;
  tier1User: UserType | undefined;
  isLoading: boolean;
  accounts: AccountType[];
  selectedCompanyInformation: any;
  companyContractors: ContractorType[];
  selectedAccountID?: number;
  seatsInformation: any;
  accountsTeamInformation: any;
  hasSubmittedTeam: boolean;
  errorMessages: any;
  fetchAccounts: () => void;
  fetchContractorsAction: (accountID: number) => void;
  fetchPackagesAction: (selectedAccountID: number) => void;
  submitInviteTeamAction: (payload: inviteTeamPayload) => void;
  fetchSeatsInfo: (payload: any) => void;
  fetchAccountUsersInfo: (payload: any) => void;
}

const InviteTeam = (props: InviteTeamContainer) => {

  const { user, tier1User, accounts, companyContractors, selectedAccountID, selectedCompanyInformation, seatsInformation, isLoading, hasSubmittedTeam, errorMessages, accountsTeamInformation } = props;
  const { fetchContractorsAction, submitInviteTeamAction, fetchAccounts, fetchSeatsInfo, fetchAccountUsersInfo } = props;

  const [isRemoving, setIsRemoving] = useState(false);
  const [seatsDetails, setSeatsInformation] = useState<SetStateAction<any>>({});
  const [reportsToUsers, setReportsToUsers] = useState<SetStateAction<any>>();
  const [isValidSeat, setIsValidSeat] = useState(false);
  const [userspassword, setUserspassword] = useState<SetStateAction<any>>({})
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    setIsSubmited(false);

    setIsRemoving(false);
    if (accounts.length === 0) {
      fetchAccounts();
    }
  }, [accounts]);

  useEffect(() => {
    if (selectedAccountID !== undefined) {
      handleSelectCompany(selectedAccountID);
    }
  }, [selectedAccountID])

  useEffect(() => {
    setSeatsInformation(seatsInformation);
  }, [seatsInformation])

  useEffect(() => {
    setReportsToUsers(accountsTeamInformation);
  }, [accountsTeamInformation])


  const initialState = {
    accountTeam: {},
    teamCachedCounter: 0,
    selectedCompany: undefined,
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case 'ADD_USER':
        return {
          ...state,
          teamCachedCounter: state.teamCachedCounter + 1,
          accountTeam: {
            ...state.accountTeam,
            [action.user.key]: { ...action.user },
          },
        };

      case 'REMOVE_USER':
        const updatedTeam = { ...state.accountTeam };
        delete updatedTeam[action.key];
        return {
          ...state,
          teamCachedCounter: state.teamCachedCounter - 1,
          accountTeam: updatedTeam,
        };

      case 'ADD_FILE_USERS':
        return {
          ...state,
          teamCachedCounter: action.usersCounter,
          accountTeam: { ...action.users },
        };

      case 'UPDATE_USER':
        return {
          ...state,
          accountTeam: {
            ...state.accountTeam,
            [action.key]: action.user,
          },
        };

      case 'SELECT_COMPANY':
        return {
          ...state,
          selectedCompany: action.accountID,
        };

      case 'CLEAN_USERS':
        return {
          ...state,
          accountTeam: {},
        };
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {

    if (isValidSubmitTeam && !isLoading) {
      if (hasSubmittedTeam) {
        Swal.close();
        Swal.fire({
          title: 'Done!',
          text: `Team has been invited.`,
          type: STATUS.success,
          html: undefined,
          confirmButtonColor: baseTheme.green.aside,
        });
        handleSelectCompany(state.selectedCompany);

      } else {
        if (!hasSubmittedTeam && errorMessages !== undefined && errorMessages.code === 'submitInviteTeam') {
          Swal.close();
          Swal.fire({
            title: 'Invite Error',
            text: errorMessages.message,
            type: 'error',
            html: undefined,
            confirmButtonColor: baseTheme.red.error,
          });
        }
      }
    }
  }, [isLoading, errorMessages, hasSubmittedTeam])

  const handleFileImport = (users: UserType[]) => {

    let updatedDataSource: any = { ...state.accountTeam };

    const updatedCount = getList(updatedDataSource).length;


    for (let i = 0; i < users.length; i++) {
      updatedDataSource = {
        ...updatedDataSource,
        [updatedCount + i]: {
          key: updatedCount + i,
          ...users[i],
        },
      };
    }

    let checkedTeam: any = getEmployeesValidated(getList(updatedDataSource));

    const accountTeam = arrayToObject(checkedTeam, 'key');

    setIsRemoving(false);
    dispatch({ type: 'ADD_FILE_USERS', users: accountTeam, usersCounter: checkedTeam.length });

    checkedTeam.map((item: any) => (
      validateSeats(getList(state.accountTeam), item.package)
    ))

  };

  const handleAddTableItem = () => {
    const newItemIndex = state.teamCachedCounter;

    const newUser = {
      key: newItemIndex,
      givenName: '',
      familyName: '',
      email: '',
      jobTitle: '',
      name: '',
      reportsToUserID: undefined, //TODO: a quien se le va a asignar en reportsto si es gregado dsd admin
      canCreateProject: true,
      app_metadata: {
        role: {
          roleID: 1,
          name: ROLE[1],
        },
      },
      package: undefined,
    };
    setIsRemoving(false);
    dispatch({ type: 'ADD_USER', user: newUser });
  };

  const handleRemoveTableItem = (key: number) => {
    setIsRemoving(true);
    dispatch({ type: 'REMOVE_USER', key });
  };

  const handleSelectCompany = (accountID: number) => {
    dispatch({ type: 'CLEAN_USERS' })
    dispatch({ type: 'SELECT_COMPANY', accountID });
    fetchSeatsInfo(accountID);
    fetchAccountUsersInfo(accountID);
    fetchContractorsAction(accountID)
  };

  const savePasswordConfigration = (event: FormEvent<HTMLFormElement>, form: any): void => {
    event.preventDefault();
    form.validateFieldsAndScroll((err: string, formValues: IFormInvite) => {
      delete formValues.inviteType
      setUserspassword(formValues)
    })
  }


  const handleSubmit = () => {
    setSeatsInformation([]);
    submitInviteTeamAction({
      team: state.accountTeam,
      accountID: state.selectedCompany,
    });
  };

  const handleUpdateForm = (targetKey: string, value: any, key: string | number) => {
    const name = `${state.accountTeam[key].givenName} ${state.accountTeam[key].familyName}`;
    const selectedPackage = targetKey == 'package' ? value : undefined;

    let updatedUser = {
      ...state.accountTeam[key],
      [targetKey]: value,
      name,
      ...userspassword
    };

    dispatch({ type: 'UPDATE_USER', user: updatedUser, key });

    validateSeats(getList(state.accountTeam), selectedPackage);
  };


  const validateSeats = (users: UserType[], selectedPackage: string | undefined) => {

    const selectedLicenceType = seatsInformation.find((p: PackageSeatDetail) => p.packageID == selectedPackage);

    //var selectedCompany = companies.find(c=> c.accountID == state.selectedCompany);

    if (!selectedLicenceType || state.selectedCompany == null)
      return false;

    var availableSeats = selectedLicenceType.availableSeats; //selectedCompany.noLicenses - selectedCompany.usedSeats;

    var registredUsers = users.filter(u => u.package == selectedLicenceType.packageID).length + 1;

    var pass = availableSeats > 0 && (availableSeats <= 0 || registredUsers <= availableSeats);

    if (!pass && !isRemoving) {
      Swal.close();
      Swal.fire({
        title: '',
        type: 'error',
        html: `<div>You are attempting to INVITE more users to your account than available licenses.</div> <br /> <div>Users Added : ` + registredUsers + ` , <strong>` + selectedPackage + ` Licenses</strong> : ` + availableSeats + `.</div> <br />Change the number of Inviting users or contact us at (<a href="mailto:sales@velocityvue.com">sales@velocityvue.com</a>) to add additional Licenses.`,
        confirmButtonColor: baseTheme.red.error,
      });
    }
    setIsValidSeat(pass);
    return pass;
  }

  const isValidSubmitTeam =
    validateTeam(getList(state.accountTeam)) &&
    state.selectedCompany !== undefined &&
    !Number.isNaN(state.selectedCompany)
    && isValidSeat;

  return (
    <InviteTeamComponent
      user={user}
      tier1User={tier1User}
      savePasswordConfigration={savePasswordConfigration}
      companyContractors={companyContractors}
      selectedCompanyInformation={selectedCompanyInformation}
      selectedAccountID={selectedAccountID}
      seatsDetails={seatsDetails}
      reportsToUsers={reportsToUsers}
      isSubmittingTeam={isLoading}
      accounts={accounts}
      dataSource={getList(state.accountTeam)}
      handleRemoveTableItem={handleRemoveTableItem}
      handleAddTableItem={handleAddTableItem}
      handleFileImport={handleFileImport}
      handleSubmit={handleSubmit}
      handleSelectCompany={handleSelectCompany}
      handleUpdateForm={handleUpdateForm}
      validateTeam={isValidSubmitTeam}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  accounts: getAccounts(state),
  companyContractors: getAccountsContractors(state),
  selectedAccountID: getAccountID(state),
  seatsInformation: getSeatsDetails(state),
  selectedCompanyInformation: getAccountCompanyInformation(state),
  accountsTeamInformation: getAccountsTeam(state),
  tier1User: getTeamOwner(state),
  isLoading: state.admin.invite.inviteTeam.isLoading,
  hasSubmittedTeam: state.admin.invite.inviteTeam.hasSubmitedTeam,
  errorMessages: state.admin.invite.inviteTeam.errorMessages,
});

const mapDispatchToProps = {
  fetchAccounts: fetchAccountsAction.request,
  fetchContractorsAction: fetchContractorsAction.request,
  fetchSeatsInfo: fetchSeatsInfoAction.request,
  fetchAccountUsersInfo: fetchAccountUsersAction.request,
  submitInviteTeamAction: submitInviteTeamAction.request,
};

export const AdminInviteTeamContainer = connect(mapStateToProps, mapDispatchToProps)(InviteTeam);
