import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  ADD_PROJECT_API,
  UPDATE_PROJECT_API,
  DELETE_PROJECT_API,
  FETCH_USER_PROJECTS_API,
  FETCH_ACCOUNTS_PROJECTS_API,
} from '@constants/endpoints';

export const FETCH_ACCOUNTS_PROJECTS = 'FETCH_ACCOUNTS_PROJECTS';
export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_CACHED_PROJECT = 'ADD_CACHED_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const REMOVE_PROJECT_ROW = 'REMOVE_PROJECT_ROW';

export const fetchAccountsProjectAction = createActionRoutine(
  FETCH_ACCOUNTS_PROJECTS,
  FETCH_ACCOUNTS_PROJECTS_API,
);
export const fetchProjectsAction = createActionRoutine(FETCH_PROJECTS, FETCH_USER_PROJECTS_API);
export const addProjectAction = createActionRoutine(ADD_PROJECT, ADD_PROJECT_API);
export const addCachedProjectAction = createActionRoutine(ADD_CACHED_PROJECT);
export const updateProjectAction = createActionRoutine(UPDATE_PROJECT, UPDATE_PROJECT_API);
export const removeProjectAction = createActionRoutine(REMOVE_PROJECT, DELETE_PROJECT_API);
export const removeProjectRowAction = createActionRoutine(REMOVE_PROJECT_ROW);
