import { handleActions, combineActions } from 'redux-actions';

import { AnyAction } from 'redux';
import {
  StateType,
  IndustryType,
  SafetyPlanType,
  FormType,
  UserType,
  AccountType,
} from '@shared/types/backend.types';
import { Sorter, ErrorMessage } from '@shared/types/utils.types';
import {
  IProjectFormsState,
  PROJECT_FORMS_INITIAL_STATE,
  projectFormsReducer,
} from './submissions/state/submissions.reducer';

import {
  fetchStatesAction,
  fetchPlansAction,
  changeFormSubstepAction,
  changeFormValuesAction,
  fetchQuestionsAction,
  changeModalQuestionAction,
  fetchJobTitlesAction,
  fetchWizardPermitAction,
  fetchAccountJobTitlesAction,
  removeBackofficeErrorAction,
} from './professional.action';
import { fetchProjectsAction } from './projects/state/projects.actions';
import { submitSafetyTeamAction } from './programs/state/programs.actions';

import {
  programsReducer,
  PROGRAMS_INITIAL_STATE,
  IProfessionalProgramsState,
} from './programs/state/programs.reducer';
import { projectsReducer } from './projects/state/projects.reducer';
import {
  safetyProgramsReducer,
  IBackOfficeProgramsState,
  BACKOFFICE_PROGRAMS_INITIAL_STATE,
} from './safety-programs/state/safety-programs.reducer';
import { teamReducer } from './team/state/team.reducer';

import update from 'immutability-helper';
import {
  CORRECTIVE_ACTIONS_INITIAL_STATE,
  ICorrectiveActionsState,
  correctiveActionsReducer,
} from './corrective-actions/state/corrective-actions.reducer';


import { ptpReducer, IPtpState, PTP_INITIAL_STATE } from '@shared/pre-task-plan/state/pre-task-plan.reducer';

export interface IProfessionalState {
  industries: IndustryType[];
  states: StateType[];
  plans: SafetyPlanType[];
  userCompany?: AccountType;
  forms: {
    [key: string]: FormType;
  };
  question?: {};
  questions: undefined | {};
  jobTitles: [];
  projects: [];
  team: {
    items: {
      [key: string]: UserType;
    };

    userTeam: UserType[];
    isLoading: boolean;
    isSendingInvite: boolean;
    hasSentInvite?: boolean;
    cachedSupervisorID?: string;
  };
  teamSorter: Sorter;
  safetyPrograms: IBackOfficeProgramsState;
  managers: UserType[];
  reportsToUsers: UserType[];
  projectSubmissions: IProjectFormsState;
  preTaskPlans: IPtpState;
  correctiveActions: ICorrectiveActionsState;
  programs: IProfessionalProgramsState;
  isLoading: boolean;
  isLoadingQuestion: boolean;
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
  hasWizard?: boolean;
}

export const PROFESSIONAL_INITIAL_STATE: IProfessionalState = {
  industries: [],
  states: [],
  plans: [],
  forms: {},
  question: {},
  userCompany: undefined,
  questions: undefined,
  jobTitles: [],
  projects: [],
  managers: [],
  reportsToUsers: [],
  team: {
    items: {},
    userTeam: [],
    isLoading: true,
    isSendingInvite: false,
    cachedSupervisorID: undefined,
    hasSentInvite: undefined,
  },
  teamSorter: {
    key: '',
    order: '',
  },
  safetyPrograms: BACKOFFICE_PROGRAMS_INITIAL_STATE,
  projectSubmissions: PROJECT_FORMS_INITIAL_STATE,
  correctiveActions: CORRECTIVE_ACTIONS_INITIAL_STATE,
  programs: PROGRAMS_INITIAL_STATE,
  preTaskPlans: PTP_INITIAL_STATE,
  hasWizard: undefined,
  isLoading: false,
  isLoadingQuestion: false,
  errorMessages: {},
};

export const professionalReducer = handleActions(
  {
    [combineActions(
      fetchStatesAction.REQUEST,
      fetchPlansAction.REQUEST,
      fetchQuestionsAction.REQUEST,
      fetchJobTitlesAction.REQUEST,
      fetchProjectsAction.REQUEST,
      submitSafetyTeamAction.REQUEST,
    ) as any]: (state: IProfessionalState) => ({
      ...state,
      isLoading: true,
    }),
    [combineActions(
      fetchStatesAction.FAILURE,
      fetchPlansAction.FAILURE,
      fetchQuestionsAction.FAILURE,
      fetchJobTitlesAction.FAILURE,
      fetchAccountJobTitlesAction.FAILURE,
      fetchProjectsAction.FAILURE,
      fetchWizardPermitAction.FAILURE,
    ) as any]: (state: IProfessionalState) =>
        update(state, {
          isLoading: { $set: false },
        }),
    [fetchWizardPermitAction.REQUEST]: (state: IProfessionalState) =>
      update(state, {
        isLoading: { $set: true },
      }),
    [submitSafetyTeamAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        isLoading: { $set: false },
        programs: {
          meta: {
            isSubmitSuccess: { $set: false },
          },
        },
        errorMessages: {
          [payload.code]: {
            title: { $set: payload.title },
            message: { $set: payload.message },
          },
        },
      }),
    [fetchStatesAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        states: { $set: payload.states },
        isLoading: { $set: false },
      }),
    [fetchPlansAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        plans: { $set: payload.plans },
        isLoading: { $set: false },
        programs: {
          meta: {
            currentSubstep: { $set: payload.currentSubstep },
          },
        },
      }),
    [fetchQuestionsAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        questions: { $set: payload.questions },
        isLoading: { $set: false },
      }),
    [combineActions(fetchJobTitlesAction.SUCCESS, fetchAccountJobTitlesAction.SUCCESS) as any]: (
      state: IProfessionalState,
      { payload }: AnyAction,
    ) =>
      update(state, {
        jobTitles: { $set: payload.jobTitles },
      }),
    [combineActions(fetchJobTitlesAction.FULFILL, fetchAccountJobTitlesAction.FULFILL) as any]: (
      state: IProfessionalState,
    ) =>
      update(state, {
        jobTitles: { $set: [] },
      }),
    [fetchWizardPermitAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        hasWizard: { $set: payload.account.isConfigurate },
        userCompany: { $set: payload.account },
        isLoading: { $set: false },
      }),
    [changeFormSubstepAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        programs: {
          meta: {
            currentSubstep: { $set: payload.currentSubstep },
          },
        },
      }),
    [changeFormValuesAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        programs: { $set: payload },
      }),
    [changeModalQuestionAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        programs: {
          meta: {
            currentQuestion: { $set: payload.currentQuestion },
          },
        },
      }),
    [removeBackofficeErrorAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
      update(state, {
        errorMessages: {
          [payload.code]: { $set: undefined },
        },
      }),
    ...projectsReducer,
    ...programsReducer,
    ...safetyProgramsReducer,
    ...teamReducer,
    ...projectFormsReducer,
    ...correctiveActionsReducer,
    ...ptpReducer
  },
  PROFESSIONAL_INITIAL_STATE,
);
