import React, { Fragment, ReactNode } from 'react';

import { SessionHeaderContainer } from '../header/session/session-header.container';

import cityBackground from '@assets/city-background-x2.png';

import '@session/session.styles.scss';
import './session-layout.styles.css';

interface ISessionLayoutProps {
  children?: ReactNode;
}

export const SessionLayoutComponent = (props: ISessionLayoutProps) => {
  const { children } = props;

  return (
    <Fragment>
      <SessionHeaderContainer />
      <div className="session__header__container session__body__container">
        <img className="background__logo" src={cityBackground} width="80%" alt="Safety Assist" />
        {children}
      </div>
    </Fragment>
  );
};
