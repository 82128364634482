import React, { useEffect } from 'react';

import { IAppState } from '@rdx/root.reducer';

import { StepType } from '@shared/types/professional.types';

import { connect } from 'react-redux';
import { getCurrentStep, getStepItems, getStepTitle } from '@layout/layout.selector';

import { ProgramStepsComponent } from '../view/steps.component';
import { resetLayoutStepsAction } from '@layout/layout.action';

export interface IStepsContainerProps {
  currentTitle: string;
  currentStep: number;
  items: StepType[];
  resetLayoutStepsAction: () => void;
}

const ProgramSteps = (props: IStepsContainerProps) => {
  const { currentTitle, currentStep, items } = props;
  const { resetLayoutStepsAction } = props;

  useEffect(() => {
    return () => {
      resetLayoutStepsAction();
    };
  }, [resetLayoutStepsAction]);

  return (
    <ProgramStepsComponent currentTitle={currentTitle} currentStep={currentStep} steps={items} />
  );
};

const mapStateToProps = (state: IAppState) => ({
  currentTitle: getStepTitle(state),
  currentStep: getCurrentStep(state),
  items: getStepItems(state),
});

const mapDispatchToProps = {
  resetLayoutStepsAction: resetLayoutStepsAction.trigger,
};

export const ProgramStepsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgramSteps);
