import React, { Fragment } from 'react';

import { ThemeType } from '@shared/types/theme.type';
import { Classes } from 'jss';

import { Col, Checkbox, Icon } from 'antd';

import injectSheet from 'react-jss';
import '@components/back-office/safety-programs/forms.styles.scss';

export interface IFormItemProps {
  id: string;
  name: string;
  classes: Classes;
  hasCheckbox?: boolean;
  onClick: (...args: any[]) => any;
}

const FormItem = (props: IFormItemProps) => {
  const { id, name, hasCheckbox } = props;
  const { onClick } = props;
  const { checkboxStyles } = props.classes;
 
  return (
    <Col span={8} className="gutter-row form__item">
      {hasCheckbox ? (
        <Checkbox className={`${checkboxStyles} form__item-title`} value={id}>
          {name}
          &nbsp;{' '}
        </Checkbox>
      ) : (
        <Fragment>
          <span className="form__item-title">{name}</span>
          &nbsp;{' '}
        </Fragment>
      )}
      <Icon
        onClick={() => {
          onClick(name, id);
        }}
        type="question-circle"
        theme="filled"
        className="icon__regulations"
      />
    </Col>
  );
};

const styles = (theme: ThemeType) => ({
  checkboxStyles: {
    color: theme.grey.fourth,
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: theme.green.fourth,
    },
  },
});

export const PlanFormComponent = injectSheet(styles)(FormItem);
