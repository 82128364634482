import React, { ChangeEvent } from 'react'
import { Form, Input, Button, Modal } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { useIntl } from 'react-intl';

import './add-contractor.styles.scss';



export interface ModalComponentProps {
    form: WrappedFormUtils;
    showModal: boolean;
    setShowModal: any;
    handleOnChangeContractor: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSaveContractor: (form: WrappedFormUtils) => void;
}

const ModalComponent = (props: ModalComponentProps) => {
    const { form, showModal, setShowModal, handleSaveContractor, handleOnChangeContractor } = props;
    const { getFieldDecorator } = form
    const intlProvider = useIntl();



    return (
        <Modal
            title="Add Contractor Company"
            className='add-company-modal'
            visible={showModal}
            onCancel={() => setShowModal(false)}
            footer={
                <>
                    <Button
                        onClick={() => {
                            form.validateFields((err, values) => {
                                if (err) {
                                    return;
                                }
                                handleSaveContractor(form)
                                setShowModal(false)
                            })
                        }}
                        type='primary'
                    >
                        SAVE
                    </Button>
                </>
            }
        >
            <Form>
                <Form.Item
                    label={intlProvider.messages['inputs.companyName']}

                >
                    {getFieldDecorator('companyName', {
                        rules: [
                            {
                                required: true,
                                message: intlProvider.messages['inputs.requiredCompanyName'],
                            },
                        ],
                    })(
                        <Input
                            name="companyName"
                            className="input--medium-height modal-input"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChangeContractor(event)}
                            placeholder="Company Name"
                            required
                        />,
                    )}
                </Form.Item>

                <Form.Item
                    label={intlProvider.messages['inputs.companyAddress']}

                >
                    {getFieldDecorator('companyAddress', {
                        rules: [
                            {
                                message: intlProvider.messages['inputs.requiredCompanyAddress'],
                            },
                        ],
                    })(
                        <Input
                            name="companyAddress"
                            className="input--medium-height modal-input"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChangeContractor(event)}
                            placeholder="Company Address"
                        />,
                    )}
                </Form.Item>
            </Form>

        </Modal>
    )
}

const AddContractorModalComponents: any = Form.create({ name: 'ModalComponent' })(ModalComponent);

export default AddContractorModalComponents
