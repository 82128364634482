import React, { Fragment, SetStateAction } from 'react';

import { ThemeType } from '@shared-types/theme.type';
import { Classes } from 'jss';

import { FormattedMessage } from 'react-intl';
import { Upload } from 'antd';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import uploadIcon from '@assets/icon/file-upload-solid.svg';
import './upload.styles.scss';

const { Dragger } = Upload;

export interface IUploadFileProps {
  hasFile: SetStateAction<boolean>;
  defaultUploadConfig: any;
  classes: Classes;
  csvIndications: string;
}

const UploadFile = (props: IUploadFileProps) => {
  const { hasFile, defaultUploadConfig, csvIndications } = props;
  const {
    iconStyles,
    textStyles,
    dragStyles,
    downloadMessageStyles,
    noBorderStyles,
  } = props.classes;

  return (
    <div className="d-flex justify-between" style={{ padding: '2%' }}>
      <div className="d-flex align-items-center md-w-70">
        <span className={downloadMessageStyles}>
          <FormattedMessage id="wizard.team.messageClick" /> &nbsp;
          <a href="/safety-assist-team-template.csv" download target="_blank">
            <FormattedMessage id="buttons.here" />
          </a>
          &nbsp;
          <FormattedMessage id="wizard.team.messageCSV" />
          {csvIndications}
        </span>
      </div>
      <div>
        <Dragger
          {...defaultUploadConfig}
          className={!hasFile ? dragStyles : classnames(dragStyles, noBorderStyles)}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          showUploadList={{
            showRemoveIcon: false,
          }}
        >
          <Fragment>
              <p className="ant-upload-drag-icon">
                <img src={uploadIcon} width="18" alt="Upload" className={iconStyles} />
              </p>
              <p className={classnames('ant-upload-text', textStyles)}>
                <FormattedMessage id="wizard.team.upload" />
              </p>
            </Fragment>
        </Dragger>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  iconStyles: {
    color: `${theme.grey.steps} !important`,
  },
  textStyles: {
    fontWeight: 'bold',
    color: `${theme.grey.steps} !important`,
  },
  dragStyles: {
    display: 'flex',
    width: '205px',
    alignItems: 'center',
    border: `3px dashed ${theme.grey.steps}`,
    borderRadius: 5,
    padding: '3px 0px 3px 1px',
    '& .ant-upload-drag': {
      border: 'none',
      borderBottom: `2px solid ${theme.grey.main}`,
    },
  },
  noBorderStyles: {
    position: 'relative',
    '& .ant-upload.ant-upload-drag': {
      border: 'none !important',
    },
  },
  downloadMessageStyles: {
    fontSize: 16,
    paddingLeft: '2%',
    '& a': {
      fontWeight: 'bold',
      color: theme.green.main,
      textDecoration: 'underline',
    },
  },
});

export const UploadFileComponent = injectSheet(styles)(UploadFile);
