import React, { FormEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Button, Card, DatePicker, Divider, Form, Input, Modal, Select, Tag, TimePicker } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { CorrectiveActionsType, UserType } from '@shared/types/backend.types';
import { getList } from '@shared/helpers/state-caster';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateUnderUserCA, fetchCATickets } from '../../state/corrective-actions.actions';
import { IAppState } from '@rdx/root.reducer';
import { History } from 'history';
import { ViewHistoryComponent } from '../view-history/view-history.component';
import { ROLES } from '@constants/roles';
import moment from 'moment';
import { getFormattedDate, toTitleCase } from '@shared/utils/utils';


export interface IViewDetailsComponentProps {
    form: WrappedFormUtils;
    history: History;
}

const ViewDetails = (props: IViewDetailsComponentProps) => {
    const { form, history } = props;
    const { caID }: { caID: string } = useParams();
    const { getFieldDecorator } = form
    const dispacth = useDispatch();


    const { session, admin, professional } = useSelector((state: IAppState) => state);
    const { userRole, user } = session;
    const { severitys, triggers, status, types } = professional.correctiveActions;
    const [openViewHistoryModal, toggleViewHistoryModal] = useState(false);

    const isAdmin = userRole === ROLES.systemAdmin;

    const selectedCA =
        isAdmin
            ? admin.correctiveActions.items.filter((correctiveAction: CorrectiveActionsType) => correctiveAction.correctiveActionID === +caID)[0]
            : professional.correctiveActions.items.filter((correctiveAction: CorrectiveActionsType) => correctiveAction.correctiveActionID === +caID)[0];
    const team = isAdmin ? admin.accounts.team.items : professional.team.items;

    const isCAEditable = user && selectedCA ? selectedCA.assignedTo === user.user_id || selectedCA.openedBy === user.user_id : false;

    useEffect(() => {
        if (!selectedCA)
            history.push('/')
    }, [])

    return (
        <>
            <div className='sa__container'>

                <div className="sa__box">

                    <div className="sa__box-heading">
                        <h4 className="sa__box-heading-title">{`Corrective Action: ${selectedCA ? selectedCA.title : 'N/A'}`}</h4>

                        <div className='flex' style={{ gap: '1rem' }}>
                            <Button onClick={() => history.goBack()}>Back</Button>
                            <Button type='primary' className='ca-table-actions' onClick={(e) => {
                                e.preventDefault();
                                dispacth(fetchCATickets.request({ accountID: selectedCA.accountID, caID }));
                                toggleViewHistoryModal(true);
                            }}>View History</Button>

                        </div>
                    </div>

                    <div className='sa__box-content' style={{ padding: 0 }}>
                        <Form className='d-flex justify-center align-items-center'

                            onSubmit={(e: any) => {
                                e.preventDefault();
                                form.validateFields((err, values) => {
                                    if (!err && selectedCA && user) {
                                        const updatedCA = { ...selectedCA, ...values };
                                        dispacth(UpdateUnderUserCA.request({ formValues: updatedCA, userID: user.user_id, correctiveActionID: selectedCA.correctiveActionID }));
                                    }
                                });
                            }}

                            style={{ width: '100%', flexDirection: 'column' }}
                            hideRequiredMark
                        >
                            <div className='main-container'>
                                <div className='form'>
                                    <div className='content-container' style={{ borderTop: 'none' }}>

                                        <Form.Item label={<b>Situation Statement</b>} >

                                            {getFieldDecorator('title', {
                                                initialValue: selectedCA && selectedCA.title,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            message: "Please enter the situation statement!",
                                                        },
                                                    ],
                                            })(
                                                <Input placeholder='Narative' className='sa-input' disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Narrative</b>} >

                                            {getFieldDecorator('description', {
                                                initialValue: selectedCA && selectedCA.description,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            message: "Please select the action description!",
                                                        },
                                                    ],
                                            })(
                                                <Input placeholder='Narative' className='sa-input' disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className='content-container'>
                                        <Form.Item label={<b>Incident Type</b>}>
                                            {getFieldDecorator('trigger', {
                                                initialValue: selectedCA && selectedCA.trigger,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            message: "Please select the trigger cause!",
                                                        },
                                                    ],
                                            })(
                                                <Select placeholder="Trigger" disabled={!isCAEditable} className='sa-select'>
                                                    <Select.OptGroup label="Select Action Trigger">
                                                        {
                                                            triggers.map((trigger: string, dataIndex: number) => (
                                                                <Select.Option key={dataIndex} value={trigger}>{trigger}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Incident Location</b>}>
                                            {getFieldDecorator('incidentLocation', {
                                                initialValue: selectedCA && selectedCA.incidentLocation,
                                                rules:
                                                    [
                                                        {
                                                            required: true,
                                                            type: 'string'
                                                        },
                                                    ],
                                            })(
                                                <Input.TextArea className='sa-textarea' style={{ height: 45 }} disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className='content-container' style={{ borderBottom: 'none' }}>

                                        <Form.Item label={<b>Incident Date & Time</b>}>
                                            {getFieldDecorator('incidentTime', {
                                                initialValue: selectedCA && moment(selectedCA.incidentTime),
                                                rules:
                                                    [
                                                        {
                                                            required: true
                                                        },
                                                    ],
                                            })(
                                                <DatePicker showTime className='sa-input-time' format="MM/DD/YYYY HH:mm" disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Risk Level</b>}>
                                            {getFieldDecorator('severity', {
                                                initialValue: selectedCA && selectedCA.severity,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            message: "Please select the serverity of action!",
                                                        },
                                                    ],
                                            })(
                                                <Select placeholder="Severity" className='sa-select' disabled={!isCAEditable}>
                                                    <Select.OptGroup label="Select Action Severity">
                                                        {
                                                            severitys.map((severity: string, dataIndex: number) => (
                                                                <Select.Option key={dataIndex} value={severity}>{severity}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                </Select>
                                            )}
                                        </Form.Item>

                                    </div>

                                    <div className='content-container'>

                                        <Form.Item label={<b>Root Cause Analysys Finding</b>}>
                                            {getFieldDecorator('rootCause', {
                                                initialValue: selectedCA && selectedCA.rootCause,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                        },
                                                    ],
                                            })(
                                                <Input.TextArea className='sa-textarea' style={{ height: 45 }} disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Status</b>}>
                                            {getFieldDecorator('status', {
                                                initialValue: selectedCA && toTitleCase(selectedCA.status),
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            message: "Please select the ticket stage!",
                                                        },
                                                    ],
                                            })(
                                                <Select placeholder="Ticket Stage" className='sa-select' disabled={!isCAEditable}>
                                                    <Select.OptGroup label="Select Action Status">
                                                        {
                                                            status.map((status: string, dataIndex: number) => (
                                                                <Select.Option key={dataIndex} value={status}>{status}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                </Select>
                                            )}
                                        </Form.Item>

                                    </div>

                                    <div className='content-container'>

                                        <Form.Item label={<b>Action Type</b>}>
                                            {getFieldDecorator('actionType', {
                                                initialValue: selectedCA && selectedCA.actionType !== null ? selectedCA.actionType : undefined,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            message: "Please select the action type!",
                                                        },
                                                    ],
                                            })(
                                                <Select placeholder="Action Type" className='sa-select' disabled={!isCAEditable}>
                                                    <Select.OptGroup label="Select Action Status">
                                                        {
                                                            types.map((type: string, dataIndex: number) => (
                                                                <Select.Option key={dataIndex} value={type}>{type}</Select.Option>
                                                            ))
                                                        }
                                                    </Select.OptGroup>
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Assigned To</b>}>
                                            {getFieldDecorator('assignedTo', {
                                                initialValue: selectedCA && selectedCA.assignedTo,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                            required: true,
                                                            message: "Please assign the action!",
                                                        },
                                                    ],
                                            })(
                                                <Select placeholder="Assigned To" className='sa-select' disabled={!isCAEditable}>
                                                    {
                                                        user &&
                                                        <Select.OptGroup label="Select User">
                                                            {
                                                                getList(team).map((associate: UserType) => (
                                                                    user.user_id !== associate.user_id &&
                                                                    <Select.Option key={associate.user_id} value={associate.user_id}>{associate.name}</Select.Option>
                                                                ))
                                                            }
                                                        </Select.OptGroup>
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className='content-container'>

                                        <Form.Item label={<b>Action to be Taken</b>}>
                                            {getFieldDecorator('actionDesc', {
                                                initialValue: selectedCA && selectedCA.actionDesc,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                        },
                                                    ],
                                            })(
                                                <Input.TextArea className='sa-textarea' style={{ height: 45 }} disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Training Updates</b>}>
                                            {getFieldDecorator('trainingUpdates', {
                                                initialValue: selectedCA && selectedCA.trainingUpdates,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                        },
                                                    ],
                                            })(
                                                <Input.TextArea className='sa-textarea' style={{ height: 45 }} disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>
                                    </div>


                                    <div className='content-container'>

                                        <Form.Item label={<b>Monitor Plan for Occurrence</b>}>
                                            {getFieldDecorator('monitorPlan', {
                                                initialValue: selectedCA && selectedCA.monitorPlan,
                                                rules:
                                                    [
                                                        {
                                                            type: 'string',
                                                        },
                                                    ],
                                            })(
                                                <Input.TextArea className='sa-textarea' style={{ height: 45 }} disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>

                                        <Form.Item label={<b>Notes</b>}>
                                            {getFieldDecorator('notes', {
                                                initialValue: null,
                                                rules:
                                                    [
                                                        {
                                                            required: true,
                                                            type: 'string',
                                                            message: 'Notes are required',
                                                        },
                                                    ],
                                            })(
                                                <Input.TextArea className='sa-textarea' style={{ height: 45 }} disabled={!isCAEditable} />
                                            )}
                                        </Form.Item>

                                    </div>

                                    {
                                        isCAEditable &&
                                        <div className='d-flex justify-end md-w-100' style={{ padding: '10px' }}>
                                            <Button type='primary' htmlType='submit' >Save</Button>
                                        </div>
                                    }
                                </div>

                                <Card className='card'>
                                    <div className='d-flex' style={{ gap: '1rem' }} >
                                        <span style={{ fontWeight: 'bold' }}>Current Status:</span>
                                        <Tag style={{ fontSize: 14 }} color={selectedCA && toTitleCase(selectedCA.status) === 'Closed' ? 'red' : 'green'}>
                                            {selectedCA ? toTitleCase(selectedCA.status) : <b>N/A</b>}
                                        </Tag>
                                    </div>

                                    <div className='d-flex' style={{ gap: '1rem' }} >
                                        <span style={{ fontWeight: 'bold' }}>Opened By:</span>
                                        {
                                            selectedCA && getList(team) && getList(team).find((associate: UserType) => (associate.user_id === selectedCA.openedBy)) ?
                                                <Tag style={{ fontSize: 14 }} color='geekblue'>{selectedCA.openedByUser}</Tag>
                                                : <Tag style={{ fontSize: 14 }} color='red'>N/A</Tag>
                                        }

                                    </div>

                                    <div className='d-flex' style={{ gap: '1rem' }} >
                                        <span style={{ fontWeight: 'bold' }}>Opened Date:</span>
                                        <span>{selectedCA ? getFormattedDate(selectedCA.creationDateTime) : <b>N/A</b>}</span>
                                    </div>
                                    {
                                        selectedCA && selectedCA.closedBy && selectedCA.closedBy !== '' && (
                                            <>
                                                <div className='d-flex' style={{ gap: '1rem' }} >
                                                    <span style={{ fontWeight: 'bold' }}>Closed By:</span>
                                                    <Tag style={{ fontSize: 14 }} color='red'>
                                                        {selectedCA ? selectedCA.closedByUser : <b>N/A</b>}
                                                    </Tag>
                                                </div>

                                                <div className='d-flex' style={{ gap: '1rem' }} >
                                                    <span style={{ fontWeight: 'bold' }}>Closed Date:</span>
                                                    <span>{selectedCA ? getFormattedDate(selectedCA.closedDateTime) : <b>N/A</b>}</span>
                                                </div>
                                            </>
                                        )
                                    }
                                </Card>


                            </div>
                        </Form>
                    </div>
                </div>

            </div>

            <ViewHistoryComponent openModal={openViewHistoryModal} toggleModal={toggleViewHistoryModal} team={team} />
        </>

    )
}

const ViewDetailsForm: any = Form.create({ name: 'ViewDetails' })(ViewDetails);
export const ViewDetailsComponent: any = withRouter(ViewDetailsForm as any);



