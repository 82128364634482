import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import SADialog from '@components/ui/accordian/dialog';
import { Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import React, { FormEvent } from 'react';
import Swal from 'sweetalert2';
import { addSafetyProgram } from '../state/safety-programs.actions';
import { SafetyPlanType } from '@shared/types/backend.types';

type Props = {
  open: boolean;
  form: WrappedFormUtils;
  plan?: SafetyPlanType;
  reqState: { isLoading: boolean | undefined; type: string };
  setShow: (show: boolean) => void;
  onSubmitCreateSafetyPlan: (values: any) => void;
};

const AddPlan = (props: Props) => {
  const {
    open,
    plan,
    reqState: { isLoading, type },
    form: { resetFields, getFieldDecorator, validateFields },
    setShow,
    onSubmitCreateSafetyPlan,
  } = props;

  React.useEffect(() => {
    if (!isLoading && type === addSafetyProgram.SUCCESS) {
      Swal.fire({
        title: 'Done!',
        text: 'New safety plan has been added',
        type: 'success',
        confirmButtonColor: '#0B734F',
      }).then(() => (resetFields(), setShow(false)));
    }
  }, [type]);

  return (
    <SADialog title="Create New Safety Program" open={open} setShow={setShow}>
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        hideRequiredMark
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          validateFields((err, values) => {
            if (!err) onSubmitCreateSafetyPlan(values);
          });
        }}
      >
        <Form.Item>
          {getFieldDecorator('name', { initialValue: plan ? plan.name : undefined })(
            <Input autoFocus className="input--medium-height" required />,
          )}
        </Form.Item>

        <div className="dialog__form-footer">
          <ButtonControlComponent
            text="Cancel"
            size="Large"
            color="gray"
            // loading={isFormSubmitting}
            onClick={() => setShow(false)}
          />
          <ButtonControlComponent
            text="Create"
            size="Large"
            color="grey"
            // loading={isFormSubmitting}
            htmlType="submit"
          />
        </div>
      </Form>
    </SADialog>
  );
};

const AddPlanDialog: any = Form.create({ name: 'addPlanForm' })(AddPlan);

export default AddPlanDialog;
