import React, { FormEvent, useState, SetStateAction, useEffect } from 'react';

import { ErrorMessage } from '@shared/types/utils.types';

import { SalesFormComponent } from '../view/sales-form.component';
import Swal from 'sweetalert2';

import { baseTheme } from '@shared/themes/presets/base.theme';
import { IAppState } from '@rdx/root.reducer';

import { connect } from 'react-redux';
import { submitSalesFormAction } from './sales-form.action';
export interface ISalesFormContainerProps {
    showSalesModal: boolean;
    isFormSubmitting: boolean;
    errorMessages: {
        [key: string]: ErrorMessage | undefined;
    }
    item?: any;
    setShowSalesModal: (show: boolean) => void;
    submitSalesFormAction: (item: any) => void;
    handleCloseSalesModal: () => void;
}

export const SalesForm = (props: ISalesFormContainerProps) => {
    const { showSalesModal, isFormSubmitting, item, errorMessages } = props;
    const { setShowSalesModal, submitSalesFormAction, handleCloseSalesModal } = props;

    const [hasSentForm, setHasSentForm] = useState<SetStateAction<Boolean | null>>(null);

    useEffect(() => {
        if (hasSentForm && !isFormSubmitting) {
            if(errorMessages.salesFormSubmit) {
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error has ocurred to contact sales department, please try again.',
                    confirmButtonColor: baseTheme.red.error,
                }).then(() => {
                    setHasSentForm(null);
                    setShowSalesModal(false);
                });
             } else {
                 Swal.fire({
                     title: 'Thanks for your interest!',
                     text: 'Someone from our Sales Department will be in touch shortly.',
                     confirmButtonColor: baseTheme.green.aside,
                 }).then(() => {
                     setHasSentForm(null);
                     setShowSalesModal(false);
                 });
             }
        }
    }, [hasSentForm, isFormSubmitting, setShowSalesModal]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>, item: any): void => {
        event.preventDefault();

        submitSalesFormAction({ item });

        setHasSentForm(true);
    }

    return (
        <SalesFormComponent
            isSalesFormSubmitting={isFormSubmitting}
            showSalesModal={showSalesModal}
            handleCloseSalesModal={handleCloseSalesModal}
            item={item}
            handleSubmit={handleSubmit}
        />
    )
};

const mapStateToProps = (state: IAppState) => ({
    isFormSubmitting: state.professional.safetyPrograms.isSalesFormSubmiting,
    errorMessages: state.professional.safetyPrograms.errorMessages
});

const mapDispatchToProps = {
    submitSalesFormAction: submitSalesFormAction.request,
}

export const SalesFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SalesForm);
