import React from 'react'
import { withRouter } from 'react-router-dom';
import { Card, Modal, Tag, Button, Spin, Empty } from 'antd';
import { CATicketType, UserType } from '@shared/types/backend.types';
import { getList } from '@shared/helpers/state-caster';
import { useSelector } from 'react-redux';
import { IAppState } from '@rdx/root.reducer';
import { History } from 'history';
import { getFormattedDate, teamNameDecoder } from '@shared/utils/utils';


export interface IViewHistoryProps {
    openModal: boolean;
    toggleModal: (value: React.SetStateAction<boolean>) => void;
    team: { [key: string]: UserType }
}

export const ViewHistoryComponent = (props: IViewHistoryProps) => {
    const { openModal, team, toggleModal } = props;
    const { tickets, isLoading } = useSelector((state: IAppState) => state.professional.correctiveActions);

    return (

        <Modal
            visible={openModal}
            title="History"
            onCancel={() => toggleModal(false)}
            footer={<Button className='ant-modal-btn-close' onClick={() => toggleModal(false)}>Close</Button>}
        >
            <div className='history-container'>
                {
                    isLoading ? <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                        : tickets.length ? (
                            (
                                <div className='history-container__content'>
                                    {tickets.map((ticket: CATicketType) => (
                                        <Card key={ticket.ticketID} className='history-card'>

                                            <div className='d-flex' style={{ gap: '1rem' }} >
                                                <span style={{ fontWeight: 'bold' }}>Assigned To:</span>
                                                <Tag color='green'>
                                                    <b>{teamNameDecoder(getList(team), ticket.assignedTo)}</b>
                                                </Tag>
                                            </div>

                                            <div className='d-flex' style={{ gap: '1rem' }} >
                                                <span style={{ fontWeight: 'bold' }}>Status:</span>
                                                <Tag color='red'>{ticket.toStatus}</Tag>
                                            </div>

                                            <div className='d-flex' style={{ gap: '1rem' }} >
                                                <span style={{ fontWeight: 'bold' }}>Status Date:</span>
                                                <span >{getFormattedDate(ticket.creationDateTime)}</span>
                                            </div>

                                            <div className='d-flex' style={{ gap: '1rem' }}>
                                                <span style={{ fontWeight: 'bold' }}>Notes:</span>
                                                <span>{ticket.notes ? ticket.notes : <b>N/A</b>}</span>
                                            </div>
                                        </Card>
                                    ))}
                                </div>
                            )
                        ) : (
                            <Empty description={"No History Found"} />
                        )
                }

            </div>
        </Modal>

    )
}
