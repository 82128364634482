import React from 'react';
import { API_URL } from '@constants/endpoints';
import { getList } from '@shared/helpers/state-caster';
import { ContractorType, CorrectiveActionsType, UserType } from '@shared/types/backend.types';
import { Tag } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';

export function teamNameDecoder(team: UserType[], id: string | null) {
  if (!id) return <Tag color="red">N/A</Tag>;

  const user = team.find((user: UserType) => user.user_id === id);

  if (user) return user.name;

  return <Tag color="red">N/A</Tag>;
}
export function getFormattedDate(date: string) {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export const caQuestion = async (
  safetyPlanID: number,
  safetyFormID: string,
  questionTypeID: string,
) => {
  const endpoint = `${API_URL}/safetyplans/${safetyPlanID}/forms/${safetyFormID}`;
  const response = await axios.get(endpoint, {
    headers: { Authorization: `Bearer ${Cookies.get('sessionKey')}` },
  });
  return response.data.questions.find((question: any) => question.id === questionTypeID);
};

export function companyNameDecoder(companies: ContractorType[], id: number) {
  if (!id) return <Tag color="red">N/A</Tag>;

  const company = getList(companies).find(
    (company: ContractorType) => company.contractorCompanyID === id,
  );

  if (company) return company.companyName;

  return <Tag color="red">N/A</Tag>;
}

export const toTitleCase = (str: string) => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function(match) {
    return match.toUpperCase();
  });
};

export const getUniqueFilters = (data: any, textToFilter: string, valueToFilter: string) => {
  const uniqueValues = new Set();

  return Array.from(data).reduce((result: any, _: any) => {
    let nestedValue = textToFilter.split('.').reduce((obj, key) => obj[key], _);
    if (!uniqueValues.has(nestedValue)) {
      uniqueValues.add(nestedValue);
      let nestedValueToFilter = valueToFilter.split('.').reduce((obj, key) => obj[key], _);
      result.push({ text: nestedValue, value: nestedValueToFilter });
    }
    return result;
  }, []);
};
