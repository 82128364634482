import { Switch } from "react-router-dom"

import { PrivateRouteHOC } from '@routes/private-route.hoc';


import React from 'react'
import { CorrectiveActionsContainer } from "@admin/corrective-actions/state/corrective-actions.container";
import { ROLE } from "@constants/roles";
import { ViewDetailsComponent } from "@back-office/corrective-actions/view/view-details/view-details.component";

const CorrectiveActionsRoutes = () => {
    return (
        <Switch>
            <PrivateRouteHOC
                path="/admin/corrective-actions/:caID"
                component={ViewDetailsComponent}
                whitelistedRoles={[ROLE[3]]}
            />

            <PrivateRouteHOC
                path="/admin/corrective-actions"
                component={CorrectiveActionsContainer}
                whitelistedRoles={[ROLE[3]]}
            />
        </Switch>
    )
}

export default CorrectiveActionsRoutes
