import { Table } from 'antd';
import React from 'react'


interface EditableCellProps {
    editing: boolean;
    dataIndex: string;
    title: string;
    record: any;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, record, children, getEditableFeilds }: any) => {

    return (
        <td>
            {
                editing
                    ? getEditableFeilds(dataIndex, title, record)
                    : children
            }
        </td>
    )
};


const SATable = (props: any) => {

    const { dataSource, columns, isLoading, isEditing, getEditableFeilds, } = props;

    const components = {
        body: {
            cell: EditableCell
        }
    };

    const columnsWithComponents = columns.map((col: any) => {

        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                getEditableFeilds: getEditableFeilds
            }),
        };
    });

    return (
        <Table
            className='table-sa'
            components={components}
            bordered
            dataSource={dataSource}
            loading={isLoading}
            columns={columnsWithComponents}
            pagination={false}
        />
    )
}


export default SATable
