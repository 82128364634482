import { addProjectAction } from '@app/back-office/projects/state/projects.actions';
import { Button, Form, Input, InputNumber, Modal } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import React, { FormEvent, memo } from 'react';
import { useDispatch } from 'react-redux';

interface IAddProject {
  open: boolean;
  form: WrappedFormUtils;
  toggleModal: (value: React.SetStateAction<boolean>) => void;
  handleAddProjet: (values: any) => void;
}

const AddProject = (props: IAddProject) => {
  const { open, form, toggleModal, handleAddProjet } = props;
  const { getFieldDecorator } = form;

  return (
    <Modal
      title="Add Project"
      visible={open}
      destroyOnClose={true}
      onCancel={() => {
        toggleModal(false);
      }}
      footer={null}
    >
      <Form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          form.validateFields((err, values) => {
            if (err) {
              return;
            }
            handleAddProjet(values);
          });
        }}
      >
        <div className="grid grid-cols-2" style={{ columnGap: '2rem' }}>
          <Form.Item label={<b>Project Number</b>}>
            {getFieldDecorator('number', {
              rules: [
                {
                  message: 'Please, select a invite type',
                },
              ],
            })(<Input placeholder="Project Number" required className="sa-input" />)}
          </Form.Item>

          <Form.Item label={<b>Project Name</b>}>
            {getFieldDecorator('name', {
              rules: [
                {
                  message: 'Please, select a invite type',
                },
              ],
            })(<Input placeholder="Project Name" required className="sa-input" />)}
          </Form.Item>
        </div>

        <Form.Item label={<b>Project Address</b>}>
          {getFieldDecorator('address', {
            rules: [
              {
                message: 'Please, select a invite type',
              },
            ],
          })(<Input placeholder="Project Address" required className="sa-input" />)}
        </Form.Item>

        <div className="password-configration-form__btnContainer">
          <Button htmlType="submit" type="primary">
            Save
          </Button>

          <Button type="default" onClick={() => toggleModal(false)}>
            {' '}
            Cancel{' '}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const AddProjectModal: any = Form.create({ name: 'SavePasswordConfigration' })(AddProject);

export default memo(AddProjectModal);
