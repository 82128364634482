export const baseTheme = {
  yellow: {
    main: '#f7ca4f',
    secondary: '#fadd7f',
  },
  black: {
    main: '#1c1c1c',
  },
  green: {
    main: '#006435',
    secondary: '#306f41',
    third: '#009D73',
    fourth: '#0B734F',
    aside: '#0B734F',
    hover: '#26563e',
  },
  red: {
    error: '#c0392b',
  },
  grey: {
    main: '#43425D',
    secondary: '#F8F8F8',
    third: '#A5A6AD',
    fourth: '#707070EF',
    steps: '#C2C2C2',
    border: '#E4E4E4',
    questionMark: '#797979',
    tabs: '#E4E4E47E',
  },
  white: {
    main: '#fff',
  },
  placeholder: {
    main: '#f2f2f4',
    secondary: '#c7c7c7',
  },
  widget: {
    purple: '#9e9feb',
    pink: '#ee7a87',
    green: '#7dd89e',
    yellow: '#eec58a',
    blue: '#79d7fa',
  },
  unselectable: {
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-o-user-select': 'none',
    'user-select': 'none',
  },
  container: {
    width: '90%',
    maxWidth: 1278,
    margin: 'auto',
  },
  inputs: {
    label: {
      fontSize: 16,
      fontWeight: 'normal',
    },
  },
};
