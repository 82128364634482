import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import {
  uploadImageCompanyAction,
  changeSetupImageStatusAction,
  validateCreationAction,
  changeSetupIndustryAction,
  deleteAccountLogoAction,
} from './creation.actions';

import { uploadImageServiceUtil } from '@shared/utils/imageConverterBase64';
import { deleteService } from '@rdx/services.saga';

function* uploadImageRequest(action: AnyAction) {
  const { accountID, image } = action.payload;
  const endpoint = uploadImageCompanyAction.getEndpoint({ accountID });

  try {
    yield call(uploadImageServiceUtil, endpoint, image);
    yield put(uploadImageCompanyAction.success());
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        uploadImageCompanyAction.failure({
          error: {
            code: 'setupCompanyImage',
            title: 'Upload failed.',
            message: 'There has been an error while uploading your image.',
          },
        }),
      );
    }
  }
}

function* changeSetupImageStatusTrigger(action: AnyAction) {
  yield put(changeSetupImageStatusAction.fulfill(action.payload));
}

function* validateCreationStepTrigger(action: AnyAction) {
  yield put(
    validateCreationAction.fulfill({
      step: action.payload.currentStep,
      isValid: action.payload.isValid,
    }),
  );
}

function* changeSetupIndustryTrigger(action: AnyAction) {
  yield put(changeSetupIndustryAction.fulfill(action.payload));
}

export function* deleteAccountLogoRequest(action: AnyAction) {
  const { accountID }  = action.payload;
  try {
    const endpoint = deleteAccountLogoAction.getEndpoint({ accountID });
    yield call(deleteService, endpoint);
    yield put(deleteAccountLogoAction.success({}));
  } catch (error) {
    yield put(deleteAccountLogoAction.failure({ code: 'failure', title: 'Error Deleting', message: 'Error deleting Company logo.'}));
  }
}

export function* setupCreationSaga() {
  yield takeLatest(uploadImageCompanyAction.REQUEST, uploadImageRequest);
  yield takeLatest(changeSetupImageStatusAction.TRIGGER, changeSetupImageStatusTrigger);
  yield takeLatest(changeSetupIndustryAction.TRIGGER, changeSetupIndustryTrigger);
  yield takeLatest(validateCreationAction.TRIGGER, validateCreationStepTrigger);
  yield takeLatest(deleteAccountLogoAction.REQUEST, deleteAccountLogoRequest);
}
