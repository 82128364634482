import React from 'react';

import { ProjectsTableComponent } from '../table/table.component';

import '@back-office/programs/view/programs.styles.scss';
import '@utils/styles/width.css';

export interface IProjectsComponentProps {
  //TODO: add type
  dataSource: any;
  handleSort: (key: any) => void;
  handleUpdateForm: (targetKey: string, e: string, key: string) => void;
  handleAddTableItem: (item: any) => void;
  handleRemoveTableItem: (item: any) => void;
}

export const ProfessionalProjectsComponent = (props: IProjectsComponentProps) => {
  const { dataSource } = props;
  const { handleUpdateForm, handleSort, handleAddTableItem, handleRemoveTableItem } = props;

  return (
    <div
      style={{ width: '100%', maxHeight: 350, overflowY: 'auto', transform: 'translateY(-40px)' }}
    >
      <ProjectsTableComponent
        projects={dataSource}
        handleSort={handleSort}
        handleUpdateForm={handleUpdateForm}
        handleAddTableItem={handleAddTableItem}
        handleRemoveTableItem={handleRemoveTableItem}
      />
    </div>
  );
};
