import React, { useState } from 'react';

import { UserType, ContractorType } from '@shared/types/backend.types';

import { Popconfirm, Menu, Dropdown, Icon, Switch, Modal } from 'antd';

import { TeamChangePasswordContainer } from '@components/admin/change-password/change-password.container';

import './item.styles.scss';
import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';
import { Link } from 'react-router-dom';

interface ITeamItemProps {
  user: UserType;
  companyContractors: [];
  team: UserType[];
  selectedAccountID?: number;
  selectedRolID?: number;
  modifiedUser: any;
  handleSaveUser: (user_id: string) => void;
  handleUpdateUser: (event: any, user: any) => void;
  resendUserInvite: (selectedUserId: string) => void;
  setselectedRolID: (rolID?: number) => void;
  handleJobTitleChange: (value: any, user_id: string) => void;
  handleDeleteUser: (id: string, status: boolean) => void;
}

export const TeamItemComponent = (props: ITeamItemProps) => {
  const { user, modifiedUser, team, companyContractors } = props;
  const { name, email, blocked, user_metadata, user_id, app_metadata } = user;
  const { jobTitle } = user_metadata;
  const { role } = app_metadata;
  const { handleDeleteUser, handleUpdateUser, handleSaveUser, resendUserInvite } = props;
  const [reportsToUserInfo] = useState(
    team.filter((memeber: UserType) => memeber.user_id === user.user_metadata.reportsToUserID)[0],
  );

  return (
    <tr className={user.blocked ? 'inactive-user' : ''}>
      <td>
        <input
          name="name"
          className={`ant-select-selection ant-select-selection--single ${user.blocked &&
            'ant-input-disabled'}`}
          defaultValue={`${name}` || ''}
          placeholder={`${name}` || 'Name'}
          disabled={blocked ? true : false}
          onChange={(event: any) => handleUpdateUser(event, user)}
        />
      </td>
      <td>{`${email}`}</td>
      <td>
        <input
          name="jobTitle"
          className={`ant-select-selection ant-select-selection--single ${user.blocked &&
            'ant-input-disabled'}`}
          defaultValue={`${jobTitle}` || ''}
          disabled={blocked ? true : false}
          placeholder={`${jobTitle}` || 'Job title'}
          onChange={(event: any) => handleUpdateUser(event, user)}
        />
      </td>
      <td>
        {user.user_metadata.package !== null ? (
          <div>{user.user_metadata.package}</div>
        ) : (
          <div>N/A</div>
        )}
      </td>

      <td>
        {blocked ? (
          <b style={{ color: 'red' }}>
            {`Tier ${user.tier}`}
            <br />
            <b style={{ fontSize: 12 }}>(Inactive)</b>
          </b>
        ) : user.tier ? (
          `Tier ${user.tier}`
        ) : (
          <b> undefined</b>
        )}
      </td>

      <td>
        <div>{role.name}</div>
      </td>

      <td>
        <select
          name="contractorCompanyID"
          placeholder="Company Contractors"
          onChange={(event: any) => handleUpdateUser(event, user)}
          disabled={blocked ? true : false}
          className="ant-select-selection ant-select-selection--single team__select--centered"
        >
          <option value="0">{user.user_metadata.companyName}</option>

          {companyContractors.map((company: ContractorType) =>
            user.user_metadata.contractorCompanyID === company.contractorCompanyID ? (
              <option
                key={company.contractorCompanyID}
                selected
                value={company.contractorCompanyID}
              >
                {' '}
                {company.companyName}
              </option>
            ) : (
              <option key={company.contractorCompanyID} value={company.contractorCompanyID}>
                {' '}
                {company.companyName}
              </option>
            ),
          )}
        </select>
      </td>
      <td>
        <select
          name="reportsToUserID"
          placeholder="Reports To"
          onChange={(event: any) => handleUpdateUser(event, user)}
          disabled={blocked ? true : false}
          className="ant-select-selection ant-select-selection--single team__select--centered"
        >
          {user.reportsTo !== null ? (
            reportsToUserInfo ? (
              <option value={user.reportsTo.user_id} selected>{`${user.reportsTo.name} ${
                reportsToUserInfo.contractorCompany
                  ? ` (${reportsToUserInfo.contractorCompany.companyName})`
                  : `(${reportsToUserInfo.user_metadata.companyName})`
              }`}</option>
            ) : (
              <option
                value={user.reportsTo.user_id}
                selected
              >{`${user.reportsTo.name} (Inactive)`}</option>
            )
          ) : (
            <option value="" selected>
              Owner (Tier 1)
            </option>
          )}

          {team.map((reportsToUser: UserType, index: number) =>
            user.user_metadata.reportsToUserID !== reportsToUser.user_id &&
            user.user_id !== reportsToUser.user_id &&
            reportsToUser.app_metadata.role.roleID === 2 &&
            user.blocked === false ? (
              <option key={index} value={reportsToUser.user_id}>
                {reportsToUser.name}
              </option>
            ) : null,
          )}

          {user.app_metadata.role.roleID !== 1 && user.reportsTo !== null ? (
            <option value="">Owner (Tier 1)</option>
          ) : null}
        </select>
      </td>

      <td className="container">
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item>
                <UserCertificateContainer
                  team={team}
                  targetUserID={user.user_id}
                  usage="team"
                  site="admin"
                  defaultTabOpen="1"
                />
              </Menu.Item>

              {!blocked ? (
                <Menu.Item>
                  <a>
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure you want to resend invite?"
                      onConfirm={() => {
                        resendUserInvite(user_id);
                      }}
                      okText="Yes"
                    >
                      <div className="team-actions-dropdown-content">
                        <Icon type="mail" />
                        <b>Resend Invite &nbsp;</b>
                      </div>
                    </Popconfirm>
                  </a>
                </Menu.Item>
              ) : null}

              {!!user && user.app_metadata.role.roleID === 2 && (
                <Menu.Item>
                  <Link to={`/admin/user/${user.user_id}/proxy-users`}>
                    <div className="team-actions-dropdown-content">
                      <Icon type="user-add" />
                      <b>Proxy Users</b>
                    </div>
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item>
                <TeamChangePasswordContainer user={user} />
              </Menu.Item>

              <Menu.Item>
                <a
                  onClick={e => {
                    e.preventDefault();
                    handleDeleteUser(user_id, user.blocked);
                  }}
                >
                  <div className="team-actions-dropdown-content">
                    <Icon style={{ color: 'red' }} type="user-delete" />
                    <b>Associate Status</b>
                    <Switch defaultChecked={!user.blocked} checked={!user.blocked} size="small" />
                  </div>
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <Icon type="more" />
          </a>
        </Dropdown>
        {modifiedUser[user_id] && (
          <Icon
            style={{ marginTop: '5px' }}
            type="save"
            onClick={() => handleSaveUser(user_id)}
            theme="filled"
          />
        )}
      </td>
    </tr>
  );
};
