import React from 'react';

import { ThemeType } from '@shared-types/theme.type';
import { Classes } from 'jss';

import { useIntl } from 'react-intl';

import { SessionLayoutComponent } from '@layout/session-layout/session-layout.component';
import { ButtonControlComponent } from '@controls/button/button-control.component';

import injectSheet from 'react-jss';

export interface INotFoundComponentProps {
  classes: Classes;
}

const NotFound = (props: INotFoundComponentProps) => {
  const { titleStyles, controlContainer } = props.classes;
  const intlProvider = useIntl();

  return (
    <div className="not-found">
      <SessionLayoutComponent>
        <div className={controlContainer}>
          <h1 className={titleStyles}>{intlProvider.messages['help.notFoundTitle']}</h1>
          <h1 className={titleStyles}>{intlProvider.messages['help.notFoundSubtitle']}</h1>
          <ButtonControlComponent
            text={intlProvider.messages['help.contactSupport']}
            size="large"
            color="green"
            style={{ margin: 'auto', marginTop: 40 }}
          />
        </div>
      </SessionLayoutComponent>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  titleStyles: {
    color: theme.grey.main,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 0,
  },
  controlContainer: {
    width: '100%',
    textAlign: 'center',
  },
});

export const NotFoundComponent = injectSheet(styles)(NotFound);
