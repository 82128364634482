import { Spin } from 'antd'
import React from 'react'

type Props = {
    rootClassName?: string
}

const SASpin = (props: Props) => {

    const { rootClassName } = props;

    return (
        <div className={`d-flex justify-center align-items-center ${rootClassName}`} style={{ padding: "30px 0px", height: '100%' }}>
            <Spin />
        </div>
    )
}

export default SASpin;