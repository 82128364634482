import _ from 'lodash';

//TODO: add types
// Native
export function sortBy(key: string | number) {
  return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
}

export function throttle(func: any, timeFrame: number) {
  var lastTime = 0;

  return function() {
    var now: any = new Date();

    if (now - lastTime >= timeFrame) {
      func();
      lastTime = now;
    }
  };
}

export function difference(object: any, base: any) {
  function changes(object: any, base: any) {
    return _.transform(object, function(result: any, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function findObjectInCollection(collection: any, property: any) {
  if (collection.length > 0) {
    let objectInCollection = _.find(collection, function(o) { return o.isSelected === true });
    if (objectInCollection !== undefined) {
      return true;
    }
  }
  return false;
}
