import { handleActions } from 'redux-actions';

import {
  changeSidemenuAction,
  changePathRedirectionAction,
  changeCurrentStepAction,
  resetLayoutStepsAction,
  validateCurrentStepAction,
} from '@layout/layout.action';

import { STATUS } from '@constants/status';
import { ROLES } from '@constants/roles';
import { AnyAction } from 'redux';
import { ErrorMessage } from '@shared/types/utils.types';
import { helpFormReducer } from './navigation-menu/help-form/state/help-form.reducer';

export interface ILayoutState {
  //TODO: add types
  steps: {
    title: string;
    items: any[];
    currentStep: number;
    totalSteps: number;
  };
  isHelpFormSubmitting: boolean;
  activeSidemenu: string;
  isLoadingSidemenu: boolean;
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
  pathRedirection?: string;
}

export const LAYOUT_INITIAL_STATE: ILayoutState = {
  steps: {
    title: "Let's set up your company profile!",
    items: [
      {
        id: 0,
        status: STATUS.process,
        name: 'plans',
        title: "Let's add Safety Programs!",
      },
      {
        id: 1,
        status: STATUS.wait,
        name: 'projects',
        title: "Let's create some projects!",
      },
    ],
    currentStep: 0,
    totalSteps: 2,
  },
  isHelpFormSubmitting: false,
  activeSidemenu: ROLES.safetyProfessional,
  isLoadingSidemenu: false,
  errorMessages: {},
  pathRedirection: undefined,
};

//TODO: implement immutability helper
export const layoutReducer = handleActions(
  {
    [changeSidemenuAction.TRIGGER]: (state: ILayoutState) => ({
      ...state,
      isLoadingSidemenu: true,
    }),
    [changeSidemenuAction.FULFILL]: (state: ILayoutState, { payload }: AnyAction) => ({
      ...state,
      activeSidemenu: payload.theme,
      isLoadingSidemenu: false,
    }),
    [changePathRedirectionAction.FULFILL]: (state: ILayoutState, { payload }: AnyAction) => ({
      ...state,
      pathRedirection: payload.path,
    }),
    [changeCurrentStepAction.FULFILL]: (state: ILayoutState, { payload }: AnyAction) => ({
      ...state,
      steps: {
        ...state.steps,
        currentStep: payload.currentStep,
        title: payload.currentTitle,
        items: payload.updatedSteps,
      },
    }),
    [validateCurrentStepAction.SUCCESS]: (state: ILayoutState, { payload }: AnyAction) => {
      const updateItems = [...state.steps.items];

      if (payload.step === 1) {
        updateItems[0] = { ...updateItems[0], status: STATUS.finish };
        updateItems[1] = { ...updateItems[1], status: STATUS.process };
      }

      return {
        ...state,
        steps: {
          ...state.steps,
          currentStep: payload.step,
          items: updateItems,
        },
      };
    },
    [resetLayoutStepsAction.FULFILL]: (state: ILayoutState) => ({
      ...state,
      steps: {
        ...state.steps,
        items: [
          { id: 0, status: STATUS.process },
          { id: 1, status: STATUS.wait },
        ],
      },
    }),
    [validateCurrentStepAction.FAILURE]: (state: ILayoutState, { payload }: AnyAction) => ({
      ...state,
      errorMessages: {
        ...state.errorMessages,
        [payload.error.code]: payload.error,
      },
    }),
    ...helpFormReducer,
  },
  LAYOUT_INITIAL_STATE,
);
