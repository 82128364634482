import React, { FormEvent } from 'react';

import { WrappedFormUtils } from 'antd/es/form/Form';
import { ThemeType } from '@shared/types/theme.type';

import Form from 'antd/es/form';
import { Input } from 'antd';

import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import injectSheet from 'react-jss';

import './help-form.styles.scss';

export interface IHelpFormComponentProps {
  show: boolean;
  form: WrappedFormUtils;
  isFormSubmitting: boolean;
  handleCloseHelpForm: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>, form: any) => void;
}

export const HelpForm = (props: IHelpFormComponentProps) => {
  const { show, form, isFormSubmitting } = props;
  const { handleCloseHelpForm, handleSubmit } = props;

  const { getFieldDecorator } = form;

  return (
    <div className={show ? 'helpform-display' : 'hide'}>
      <section className="helpform-header">
        <span className="helpform-close" onClick={handleCloseHelpForm}>
          &times;
        </span>
      </section>
      <div className="helpform__container">
        <div className="helpform-body">
          <Form
            layout="vertical"
            hideRequiredMark
            onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event, form)}
            style={{
              width: '-webkit-fill-available',
              margin: 50,
            }}
          >
            <div className="helpform__subject-container">
              <span className='helpformclose' onClick={handleCloseHelpForm}> &times; </span>
              <Form.Item className="sm-w-100 md-w-100" label="Subject:">
                {getFieldDecorator('subject', {
                  rules: [
                    {
                      required: true,
                      message: 'Please insert a subject',
                    },
                  ],
                })(<Input className="" placeholder="subject" required />)}
              </Form.Item>
            </div>
            <div className="helpform__description-container">
              <Form.Item className="" label="Description:">
                {getFieldDecorator('content', {
                  rules: [
                    {
                      required: true,
                      message: 'Please insert a problem description',
                    },
                  ],
                })(
                  <Input.TextArea
                    style={{ height: 200 }}
                    className=""
                    placeholder="Add description"
                    required
                  />,
                )}
              </Form.Item>
            </div>
            <div className="helpform__submit-container">
              <ButtonControlComponent
                text="Send"
                size="extraLarge"
                color="green"
                htmlType="submit"
                loading={isFormSubmitting}
              />
            </div>
          </Form>
        </div>
        <section className="helpform-footer"></section>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  formLabelStyles: {
    color: theme.grey.main,
    fontSize: 18,
  },
});

const HelpFormStyled = injectSheet(styles)(HelpForm);
export const HelpFormComponent: any = Form.create({ name: 'needHelp' })(HelpFormStyled);
