import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  ADD_CONTRACTOR_API,
  UPDATE_CONTRACTOR_API,
  DELETE_CONTRACTOR_API,
  FETCH_ACCOUNTS_CONTRACTORS_API,
} from '@constants/endpoints';

export const FETCH_CONTRACTORS = 'FETCH_CONTRACTORS';
export const ADD_CONTRACTOR = 'ADD_CONTRACTOR';
export const ADD_CACHED_CONTRACTOR = 'ADD_CACHED_CONTRACTOR';
export const UPDATE_CONTRACTOR = 'UPDATE_CONTRACTOR';
export const REMOVE_CONTRACTOR = 'REMOVE_CONTRACTOR';
export const REMOVE_CONTRACTOR_ROW = 'REMOVE_PROJECT_CONTRACTOR';

export const fetchContractorsAction = createActionRoutine(FETCH_CONTRACTORS, FETCH_ACCOUNTS_CONTRACTORS_API);
export const addContractorAction = createActionRoutine(ADD_CONTRACTOR, ADD_CONTRACTOR_API);
export const addCachedContractorAction = createActionRoutine(ADD_CACHED_CONTRACTOR);
export const updateContractorAction = createActionRoutine(UPDATE_CONTRACTOR, UPDATE_CONTRACTOR_API);
export const removeContractorAction = createActionRoutine(REMOVE_CONTRACTOR, DELETE_CONTRACTOR_API);
export const removeContractorRowAction = createActionRoutine(REMOVE_CONTRACTOR_ROW);
