import React, { useEffect, useState, SetStateAction, FormEvent } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { IFormRegister, SubmitFormRegister } from '@shared/types/forms.types';
import { UserType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { ErrorMessage } from '@shared/types/utils.types';
import { History } from 'history';

import { withRouter, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { connect } from 'react-redux';
import { getIsLoadingSession, getSessionErrors, getSessionUser } from '@session/session.selector';
import { submitRegisterAction, fetchUserRegisterAction } from '@session/session.action';

import Swal from 'sweetalert2';
import { RegistrationFormComponent } from '../view/registration-form.component';

import { daysInMonth } from '@utils/dates';

import queryString from 'query-string';
import { REGEX } from '@constants/regex';
import { STATUS } from '@constants/status';
import { baseTheme } from '@themes/presets/base.theme';
import { LoadingSpinnerComponent } from '@components/controls/loading/spinner.component';
import { getUserSession } from '@services/storage/session.service';

export interface IRegistrationFormContainerProps {
  user: UserType;
  userInfo: UserType;
  errorMessages: {
    [key: string]: ErrorMessage;
  };
  isFormSubmitting: boolean;
  history: History;
  location: Location;
  //TODO: add types
  fetchUserRegisterAction: (params: any) => void;
  submitFormAction: (payload: SubmitFormRegister) => void;
}

const RegistrationForm = (props: IRegistrationFormContainerProps) => {
  const {
    user,
    userInfo,
    errorMessages,
    isFormSubmitting,
    fetchUserRegisterAction,
    submitFormAction,
    history,
    location,
  } = props;
  const [doPasswordsMatch, setDoPasswordMatch] = useState<SetStateAction<Boolean>>(false);
  const [hasSentForm, setHasSentForm] = useState<SetStateAction<Boolean | null>>(null);
  const [selectedMonth, setSelectedMonth] = useState<SetStateAction<any>>(0);
  const [numberOfDays, setNumberOfDays] = useState<SetStateAction<any>>(0);
  const [selectedYear, setSelectedYear] = useState<SetStateAction<any>>(0);
  const [selectedDay, setSelectedDay] = useState<SetStateAction<any>>(0);
  const [inviteID, setInviteID] = useState<SetStateAction<any>>(null);
  const intlProvider = useIntl();
  const hasSession = getUserSession();

  useEffect(() => {
    fetchUserRegisterAction(queryString.parse(location.search));
    if (queryString.parse(location.search)) {
      setInviteID(queryString.parse(location.search).inviteid);
    }
  }, [fetchUserRegisterAction, location.search]);

  useEffect(() => {
    if (!user && errorMessages && !isFormSubmitting) {
      Swal.fire({
        title: 'Get register error',
        text: `${errorMessages ? errorMessages.title : ''}`,
        type: STATUS.error,
        confirmButtonColor: baseTheme.red.error,
      }).then(() => history.push('/login'));
    }
    if (hasSentForm && !isFormSubmitting) {
      if (errorMessages) {
        Swal.fire({
          title: intlProvider.messages['modal.error.ups'].toString(),
          text: intlProvider.messages['modal.register.error'].toString(),
          type: STATUS.error,
          confirmButtonColor: baseTheme.red.error,
        }).then(() => setHasSentForm(null));
      } else if (user && user.app_metadata.role.roleID === 2) {
        Swal.fire({
          title: intlProvider.messages['modal.register.title'].toString(),
          text: intlProvider.messages['modal.register.message'].toString(),
          type: STATUS.success,
          confirmButtonColor: baseTheme.green.aside,
        }).then(() => history.push('/login'));
      } else {
        Swal.fire({
          title: intlProvider.messages['modal.register.title'].toString(),
          text: 'Your Password has been created.',
          type: STATUS.success,
          confirmButtonColor: baseTheme.green.aside,
        }).then(() => history.push('/login'));
      }
    }
  }, [hasSentForm, errorMessages, history, isFormSubmitting, intlProvider.messages, user]);

  //this function prevents to display undesireable autocompletes on chrome
  const fixAutocomplete = () => {
    document
      .querySelectorAll(
        '#register_contactNumber, #register_address, #register_emergencyContactName, #register_emergencyContactNumber',
      )
      .forEach(e => {
        if (e.id === 'register_contactNumber') {
          e.setAttribute('autocomplete', 'stopAutocomplete');
          e.setAttribute('readonly', '');
        }
        e.setAttribute('autocomplete', 'stopAutocomplete');
        //you can put any value but NOT "off" or "false" because they DO NOT works
      });
  };

  useEffect(() => {
    fixAutocomplete();
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils): void => {
    event.preventDefault();

    form.validateFieldsAndScroll((err: string, formValues: IFormRegister) => {
      const {
        given_name,
        family_name,

        password,
      } = formValues;

      let birthDate;
      const name = `${formValues.given_name} ${formValues.family_name}`;
      if (!formValues.birthdayYear || !formValues.birthdayMonth || !formValues.birthdayDay) {
        birthDate = undefined;
      } else {
        birthDate = `${formValues.birthdayYear}-${formValues.birthdayMonth}-${formValues.birthdayDay}`;
      }
      const user = {
        given_name,
        family_name,
        name,
        user_metadata: {

        },
        password,
      };

      if (!err) {
        submitFormAction({
          user,
          inviteID,
        });
        setHasSentForm(true);
      }
    });
  };

  const handleCompareToFirstPassword = (
    form: any,
    rule: any,
    value: string,
    callback: (message?: string) => {},
  ) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(intlProvider.messages['inputs.unmatchingPassword'].toString());
    } else {
      callback();
    }
  };

  const handleValidateToNextPassword = (
    form: any,
    rule: any,
    value: string,
    callback: (message?: string) => {},
  ) => {
    const passwordStrength = new RegExp(REGEX.passwordStrength);
    const isStrong = passwordStrength.test(value);

    if (isStrong && value.length >= 6) {
      if (value && doPasswordsMatch) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    } else if (!isStrong || value !== '') {
      callback(intlProvider.messages['inputs.invalidPassword'].toString());
    } else {
      callback();
    }
  };

  const validateKeys = (e: any) => {
    if (!REGEX.validateNumber.test(e.key) || e.target.value.length >= 10) {
      e.preventDefault();
    }
  };

  const handleConfirmBlur = (e: FormEvent<HTMLInputElement>) => {
    const { value }: any = e.target;

    setDoPasswordMatch(doPasswordsMatch || value!!);
  };

  if (hasSession) {
    return <Redirect to="/" />;
  }

  const showDays = (numberDays: number) => {
    if (numberDays > 0) {
      return Array.from(new Array(numberDays), (val, index) => index);
    } else {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      const numberOfDays = daysInMonth(year, month);
      return Array.from(new Array(numberOfDays), (val, index) => index);
    }
  };

  const handleDaySelected = (day: number) => {
    setSelectedDay(day);
  };

  const handleMonthSelected = (month: number, form: WrappedFormUtils) => {
    setSelectedMonth(month);
    const today = new Date();

    const monthDays = selectedYear
      ? daysInMonth(selectedYear, month)
      : daysInMonth(today.getFullYear(), month);

    setNumberOfDays(monthDays);

    if (selectedDay > monthDays) {
      form.resetFields(['birthdayDay']);
    }
  };

  const handleYearSelected = (year: number, form: WrappedFormUtils) => {
    setSelectedYear(year);
    const month = selectedMonth ? selectedMonth : new Date().getMonth();
    const monthDays = daysInMonth(year, month);
    setNumberOfDays(monthDays);

    if (selectedDay > monthDays) {
      form.resetFields(['birthdayDay']);
    }
  };

  if (!userInfo) {
    return <LoadingSpinnerComponent />;
  }

  return (
    <RegistrationFormComponent
      user={userInfo}
      numberOfDays={numberOfDays}
      selectedMonth={selectedMonth}
      isFormSubmitting={isFormSubmitting}
      showDays={showDays}
      handleDaySelected={handleDaySelected}
      handleMonthSelected={handleMonthSelected}
      handleYearSelected={handleYearSelected}
      handleSubmit={handleSubmit}
      handleConfirmBlur={handleConfirmBlur}
      handleValidateToNextPassword={handleValidateToNextPassword}
      handleCompareToFirstPassword={handleCompareToFirstPassword}
      validateKeys={validateKeys}
      daysInMonth={daysInMonth}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  isFormSubmitting: getIsLoadingSession(state),
  errorMessages: getSessionErrors(state),
  user: getSessionUser(state),
  userInfo: state.session.userInfo,
});

const mapDispatchToProps = {
  fetchUserRegisterAction: fetchUserRegisterAction.request,
  submitFormAction: submitRegisterAction.request,
};

export const RegistrationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RegistrationForm as any));
