import React, { useEffect } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { ChangeFormStatusPayload, SafetyFormsList, FormInstanceSelected } from '../safety-plans.d';

import { connect } from 'react-redux';
import {
  changeFormActiveStatusAction,
  changeSubFormActiveStatusAction,
  fetchAccountsPlansAction,
  changeAccountSafetyPlanActiveStatusAction,
} from './safety-plans.actions';
import { getUserAccountId } from '@app/session/session.selector';
import {
  getSafetyPlansItems,
  getSafetyPlansIsLoading,
  getSafetyPlansIsUpdating,
} from '../../state/accounts.selector';

import { getAccountID } from '@app/admin/invite/state/invite.selector';

import { message } from 'antd';

import { fetchFormQuestions } from '@app/admin/safety-programs/state/safety-programs.actions';

import { AccountsSafetyPlansComponent } from '../view/safety-plans.component';

interface IAccountSafetyPlansProps {
  isLoading: boolean;
  isUpdating: number;
  questionsIsLoading?: boolean;
  safetyPlans: any[];
  questions?: any;
  selectedAccountID?: number;
  changeFormActiveStatusAction: (payload: ChangeFormStatusPayload) => void;
  changeSubFormActiveStatusAction: (payload: any) => void;
  fetchAccountsPlansAction: (payload: number) => void;
  resetPlansAction: () => void;
  // TODO: add proper type to this change action
  changeAccountSafetyPlanActiveStatusAction: (payload: any) => void;
  fetchSafetyPlansFormQuestionsAction: (payload: any) => void;
}

const AccountsSafetyPlans = (props: IAccountSafetyPlansProps) => {
  const {
    safetyPlans,
    questions,
    isLoading,
    questionsIsLoading,
    selectedAccountID,
    isUpdating,
  } = props;
  const {
    changeFormActiveStatusAction,
    changeSubFormActiveStatusAction,
    fetchAccountsPlansAction,
    resetPlansAction,
    changeAccountSafetyPlanActiveStatusAction,
    fetchSafetyPlansFormQuestionsAction,
  } = props;

  useEffect(() => {
    if (selectedAccountID) {
      fetchAccountsPlansAction(selectedAccountID);
    }
  }, []);

  useEffect(() => {
    openMessage();
  }, [isUpdating]);

  // useEffect(() => {
  //   if (safetyPlans.length > 0) {
  //     fecthSelectedFormQuestion((Object.values(safetyPlans[0].forms) as any)[0].id)
  //   }
  // }, [safetyPlans])

  useEffect(() => {
    if (selectedAccountID) {
      fetchAccountsPlansAction(selectedAccountID);
    }
    return () => {
      resetPlansAction();
    };
  }, [selectedAccountID, fetchAccountsPlansAction, resetPlansAction]);

  const openMessage = () => {
    const key = 'updatable';

    if (isUpdating === 1) message.loading({ content: 'Loading', key });
    else if (isUpdating === 2) {
      message.success({ content: 'Success', key, duration: 2 });
    }
  };

  const handleSwitchChecked = (
    isValid: boolean,
    form: FormInstanceSelected,
    safetyPlan: SafetyFormsList,
  ) => {
    if (selectedAccountID !== undefined) {
      changeFormActiveStatusAction({
        safetyPlanID: safetyPlan.safetyPlanID,
        accountID: selectedAccountID,
        form,
        safetyPlan,
        isValid,
      });
    }
  };

  const handleSubFormSwitchChecked = (
    isValid: boolean,
    form: FormInstanceSelected,
    safetyPlan: SafetyFormsList,
    formID: string,
  ) => {
    if (selectedAccountID !== undefined) {
      changeSubFormActiveStatusAction({
        safetyPlanID: safetyPlan.safetyPlanID,
        accountID: selectedAccountID,
        formID,
        form,
        safetyPlan,
        isValid,
      });
    }
  };

  const fecthSelectedFormQuestion = (formID: string) => {
    fetchSafetyPlansFormQuestionsAction({ params: { formID } });
  };

  const handleChangeStatusPlan = (isEnable: boolean, safetyPlan: any) => {
    if (selectedAccountID !== undefined) {
      changeAccountSafetyPlanActiveStatusAction({
        accountID: selectedAccountID,
        safetyPlanID: safetyPlan.safetyPlanID,
        isEnable,
        safetyPlans,
      });
    }
  };

  return (
    <AccountsSafetyPlansComponent
      title={'Safety Plans'}
      questions={questions}
      questionsIsLoading={questionsIsLoading}
      safetyPlans={safetyPlans}
      isLoading={isLoading}
      handleSwitchChecked={handleSwitchChecked}
      handleSubFormSwitchChecked={handleSubFormSwitchChecked}
      handleChangeStatusPlan={handleChangeStatusPlan}
      fecthSelectedFormQuestion={fecthSelectedFormQuestion}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  safetyPlans: getSafetyPlansItems(state),
  questions: state.admin.safetyPrograms.questions,
  questionsIsLoading: state.admin.safetyPrograms.reqState.isLoading,
  isUpdating: getSafetyPlansIsUpdating(state),
  userAccountId: getUserAccountId(state),
  selectedAccountID: getAccountID(state),
  isLoading: getSafetyPlansIsLoading(state),
});

const mapDispatchToProps = {
  fetchSafetyPlansFormQuestionsAction: fetchFormQuestions.request,
  changeFormActiveStatusAction: changeFormActiveStatusAction.request,
  changeSubFormActiveStatusAction: changeSubFormActiveStatusAction.request,
  fetchAccountsPlansAction: fetchAccountsPlansAction.request,
  resetPlansAction: fetchAccountsPlansAction.fulfill,
  changeAccountSafetyPlanActiveStatusAction: changeAccountSafetyPlanActiveStatusAction.request,
};

export const AccountsSafetyPlansContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsSafetyPlans);
