import { handleActions } from 'redux-actions';

import { AnyAction } from 'redux';

import { changeThemeAction } from '@themes/theme.action';

export interface IThemeState {
  activeTheme: string;
  isLoadingTheme: boolean;
}

export const THEME_INITIAL_STATE: IThemeState = {
  activeTheme: 'darkTheme',
  isLoadingTheme: false,
};

export const themeReducer = handleActions(
  {
    [changeThemeAction.TRIGGER]: (state: IThemeState) => ({
      ...state,
      isLoadingTheme: true,
    }),
    [changeThemeAction.FULFILL]: (state: IThemeState, { payload }: AnyAction) => ({
      activeTheme: payload.theme,
      isLoadingTheme: false,
    }),
  },
  THEME_INITIAL_STATE,
);
