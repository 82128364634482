import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import SADialog from '@components/ui/accordian/dialog';
import { Button, Checkbox, Form, Icon, Input, Popconfirm, Select } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import React, { FormEvent, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { addFormQuestion, updateFormQuestion } from '../../state/safety-programs.actions';
import { QuestionType } from '@shared/types/backend.types';
import { QUESTION_TYPE } from '@constants/question';
import SATable from '@components/table/sa.table';
import { Upload, Modal } from 'antd';

import './question.style.scss'

type Props = {
    open: boolean;
    form: WrappedFormUtils;
    question?: QuestionType;
    reqState: { isLoading: boolean | undefined, type: string }
    setShow: (show: boolean) => void;
    onSubmitAddQuestionForm: (values: any) => void;
}



const AddQuestionForm = (props: Props) => {

    const { open, question, reqState: { isLoading, type }, form: { resetFields, getFieldDecorator, validateFields, getFieldValue }, setShow, onSubmitAddQuestionForm } = props;

    const [isOptionsTableVisible, setOptionsTable] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([]);
    const [regulations, setRegulations] = useState<any>([]);

    React.useEffect(() => {
        if (!isLoading && type === addFormQuestion.SUCCESS) {
            Swal.fire({
                title: 'Done!',
                text: 'New question has been add to the form',
                type: 'success',
                confirmButtonColor: '#0B734F',
            }).then(() => (resetFields(), setShow(false), setOptions([]), setRegulations([])));
        } else if (!isLoading && type === updateFormQuestion.SUCCESS) {
            Swal.fire({
                title: 'Done!',
                text: 'The form question has been updated',
                type: 'success',
                confirmButtonColor: '#0B734F',
            }).then(() => (resetFields(), setShow(false)));
            setOptions([]);
            setRegulations([]);
        }
    }, [type]);


    React.useEffect(() => {
        if (!open) resetFields();
    }, [open]);

    React.useEffect(() => {
        if (question) {
            setOptionsTable(question.questionTypeID === 10 ? true : false)
            if (question.options) setOptions(question.options.map((option, index) => { return { key: index + 1, option } }))
            if (question.regulations) setRegulations(question.regulations.map((regulation, index) => { return { key: index + 1, regulationImages: undefined, ...regulation } }))
        }
    }, [question]);

    const optionsColumns = React.useMemo(() => [
        {
            title: "Option",
            Key: "option",
            dataIndex: 'option',
            render: (_: any, record: any) => (
                <Input
                    defaultValue={options[record.key - 1] ? options[record.key - 1].option : undefined}
                    onChange={(e) => setOptions((prev: any) => {
                        prev[record.key - 1].option = e.currentTarget.value;
                        return prev;
                    })} placeholder='Enter Option' />
            )
        },
        {
            title: "",
            Key: "actions",
            width: "10%",
            render: (record: any) => (
                <>
                    <Popconfirm
                        title="Do you sure?"
                        okText="Delete"
                        onConfirm={() => handleDeleteOptions(record.key)}
                    >
                        <Icon
                            className='table-sa__deleteIcon'
                            type="delete"
                        />
                    </Popconfirm>
                </>
            )
        },
    ], [options]);

    const regulationColumns = [
        {
            title: "Title",
            Key: "number",
            dataIndex: 'number',
            render: (_: any, record: any) => (
                <Input
                    defaultValue={regulations[record.key - 1] ? regulations[record.key - 1].number : undefined}
                    onChange={(e) => setRegulations((prev: any) => {
                        prev[record.key - 1].number = e.currentTarget.value;
                        return prev;

                    })}
                    placeholder='Regulation Title'
                />)
        },
        {
            title: "Description",
            Key: "description",
            dataIndex: 'description',
            render: (_: any, record: any) => (
                <Input.TextArea
                    defaultValue={regulations[record.key - 1] ? regulations[record.key - 1].description : undefined}
                    onChange={(e) => {
                        setRegulations((prev: any) => {
                            prev[record.key - 1].description = e.currentTarget.value;
                            return prev;
                        })
                    }}
                    placeholder='Regulation Description'
                />)
        },
        {
            title: "Image",
            Key: "image",
            render: (record: any) => (
                <>
                    <div className='regulations-image'>
                        <input className='regulations-upload-input' type='file' multiple={false} onChange={({ target: { files } }) => {
                            if (files) {
                                const reader = new FileReader();
                                reader.readAsDataURL(files[0]);

                                reader.onload = (e) => {
                                    setRegulations((prev: any) => {
                                        const prevData = prev;
                                        prevData[record.key - 1].regulationImages = [{ base64Data: reader.result, fileName: files[0].name }]
                                        return [...prev];
                                    })
                                }
                            }
                        }} />

                        {
                            regulations[record.key - 1] && (
                                !!regulations[record.key - 1].regulationImages ? (
                                    <div className='regulation-img-container'>
                                        <div className='hover-drop'></div>
                                        <img src={regulations[record.key - 1].regulationImages[0].base64Data} />
                                    </div>
                                )
                                    : regulations[record.key - 1].hasOwnProperty("imagesUri")
                                        ? (
                                            <div className='regulation-img-container'>
                                                <div className='hover-drop'></div>
                                                <img src={regulations[record.key - 1].imagesUri} />
                                            </div>
                                        )
                                        : (
                                            <div className='regulation-img-upload'>
                                                <div className='hover-drop'></div>
                                                <Icon type="plus" />
                                                <div className="upload-text">Upload</div>
                                            </div>
                                        )
                            )
                        }

                    </div>
                </>
            )
        },
        {
            title: "",
            Key: "actions",
            width: "10%",
            render: (record: any) => (
                <>
                    <Popconfirm
                        title="Do you sure?"
                        okText="Delete"
                        onConfirm={() => handleDeleteRegulations(record.key)}
                    >
                        <Icon
                            className='table-sa__deleteIcon'
                            type="delete"
                        />
                    </Popconfirm>
                </>
            )
        },
    ]

    const handleAddOptions = () => {
        setOptions((prev: any[]) => [...prev, { key: options.length + 1, option: '' }]);
    }

    const handleAddRegulations = () => {
        setRegulations((prev: any[]) => [...prev, { key: regulations.length + 1, number: '', description: '', regulationImages: undefined }]);
    }

    const handleDeleteOptions = (key: number) => {
        setOptions(options.filter((option: any) => option.key !== key));
    }

    const handleDeleteRegulations = (key: number) => {
        setRegulations(regulations.filter((option: any) => option.key !== key));
    }



    return (
        <SADialog title="Create New Question" open={open} setShow={(value: boolean) => (setShow(value), setRegulations([]), setOptions([]))} >
            <Form
                key={'question-form'}
                className='questions-form'
                layout="vertical"
                hideRequiredMark
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    validateFields((err, values) => {
                        if (!err) {
                            onSubmitAddQuestionForm(
                                {
                                    ...values,
                                    options: options.map((option: any) => option.option),
                                    orderSequence: question ? question.orderSequence : undefined,
                                    regulations: [...regulations]
                                })
                        }
                    });
                }}
                style={{ width: '100%' }}
            >
                <div style={{ maxHeight: 600, overflow: 'auto', paddingRight: 20 }}>

                    <Form.Item label="Question Description">
                        {getFieldDecorator('description', {
                            initialValue: question ? question.description : '',
                            rules: [
                                {
                                    required: true,
                                    message: 'please enter question description',
                                },
                            ],
                        })(
                            <Input.TextArea
                                className={`sm-w-100`}
                                placeholder="Add description"
                                required
                            />,
                        )}
                    </Form.Item>

                    <Form.Item label="Question Type">
                        {getFieldDecorator('questionTypeID', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select question type',
                                },
                            ],
                            initialValue: question ? question.questionTypeID.toString() : 'Select question type',
                        })(
                            <Select
                                className="sm-w-100"
                                placeholder={question ? QUESTION_TYPE[question.questionTypeID] : 'Select question type'}
                                onChange={(value: string) => setOptionsTable(value === '10' ? true : false)}
                            >
                                {Object.keys(QUESTION_TYPE).map(type => (
                                    <Select.Option key={type} value={type}>
                                        {QUESTION_TYPE[type]}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>

                    {isOptionsTableVisible && (
                        <>
                            <Form.Item >
                                {getFieldDecorator('multiSelect', {
                                    valuePropName: 'checked',
                                    initialValue: question ? question.multiSelect : false,
                                })(
                                    <div className='d-flex justify-between align-items-center'>
                                        <Button type='default' size='small' onClick={handleAddOptions}>Create New Option</Button>
                                        <div>
                                            <span>User can choose only one option</span>
                                            <Checkbox style={{ marginLeft: '10px' }} />
                                        </div>
                                    </div>
                                )}
                            </Form.Item>

                            <div className='questions-form__options-table'>
                                <SATable dataSource={options} columns={optionsColumns} />
                            </div>
                        </>

                    )}

                    <div className='d-flex justify-end'>
                        <Button size='default' type='primary' onClick={handleAddRegulations} >Add Regulations</Button>
                    </div>

                    <div className='questions-form__options-table'>
                        <SATable dataSource={regulations} columns={regulationColumns} />
                    </div>

                    <div className='dialog__form-footer'>
                        <ButtonControlComponent
                            text="Save"
                            size="Large"
                            color="green"
                            htmlType="submit"
                        />
                    </div>
                </div>
            </Form>
        </SADialog>
    )
}

const AddQuestionDialog: any = Form.create({ name: 'addQuestionForm' })(AddQuestionForm);

export default AddQuestionDialog;