import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

export const _getProgramPlans = (state: IAppState) => state.professional.safetyPrograms.items;

export const getProgramPlans = createSelector(
  [_getProgramPlans],
  plans => plans,
);

export const _getIsLoadingSafetyPrograms = (state: IAppState) =>
  state.professional.safetyPrograms.isLoading;

export const getIsLoadingSafetyPrograms = createSelector(
  [_getIsLoadingSafetyPrograms],
  isLoading => isLoading,
);
