
import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { fetchAccountCA } from "./corrective-actions.actions";
import { fetchService } from '@rdx/services.saga';

function* fetchAccountsCARequest(action: AnyAction) {

    try {
        const endpoint = fetchAccountCA.getEndpoint({ accountID: action.payload });
        const response = yield call(fetchService, endpoint);
        yield put(fetchAccountCA.success(response));

    } catch (error) {
        yield put(fetchAccountCA.failure(error));
    }
}

export function* adminCASaga() {
    yield takeLatest(fetchAccountCA.REQUEST, fetchAccountsCARequest);
}
