import update from 'immutability-helper';

import { Submission } from '@shared/types/backend.types';
import { IProfessionalState } from '@app/back-office/professional.reducer';
import { AnyAction } from 'redux';

import {
  fetchProjectFormsAction,
  fetchSPProjectAction,
  changeSelectedProjectAction,
  fetchFormPDFAction,
  getSelectedFormPDFAction,
} from './submissions.actions';
import { combineActions } from 'redux-actions';

export interface IProjectFormsState {
  //TODO: add type
  items: {
    [key: string]: Submission;
  };
  isLoading: boolean;
  isLoadingPDF: boolean;
  selectedProjectID?: number;
  selectedFormID?: number;
  formPDF: string | undefined;
}

export const PROJECT_FORMS_INITIAL_STATE = {
  items: {},
  isLoading: true,
  isLoadingPDF: true,
  selectedProjectID: undefined,
  selectedFormID: undefined,
  formPDF: undefined,
};

export const projectFormsReducer = {
  [combineActions(fetchSPProjectAction.REQUEST, fetchProjectFormsAction.REQUEST) as any]: (
    state: IProfessionalState,
  ) =>
    update(state, {
      projectSubmissions: {
        isLoading: { $set: true },
      },
    }),
  [fetchSPProjectAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projects: { $set: payload.projects },
      projectSubmissions: {
        isLoading: { $set: false },
      },
    }),
  [fetchSPProjectAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        selectedProjectID: { $set: undefined },
      },
    }),
  [fetchProjectFormsAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        items: { $set: payload.submissions },
        isLoading: { $set: false },
      },
    }),
  [fetchProjectFormsAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        items: { $set: {} },
        isLoading: { $set: false },
      },
    }),
  [getSelectedFormPDFAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        selectedFormID: { $set: payload },
      },
    }),
  [fetchFormPDFAction.REQUEST]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        formPDF: { $set: undefined },
        isLoadingPDF: { $set: true },
      },
    }),
  [fetchFormPDFAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        formPDF: { $set: payload },
        isLoadingPDF: { $set: false },
      },
    }),
  [changeSelectedProjectAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    return update(state, {
      projectSubmissions: {
        selectedProjectID: { $set: payload },
      },
    })
  },
};
