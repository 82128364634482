import React from 'react';

import { ThemeType } from '@shared/types/theme.type';
import { Classes } from 'jss';

import { Icon } from 'antd';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import { STATUS } from '@constants/status';

import './tabs.style.scss';

export interface ITabsComponentProps {
  planName: string;
  stepID: number;
  classes: Classes;
  status?: string;
  //TODO: add types
  activeClass?: any;
  steps?: any;
  backoffice?: string;
  handleChangeCurrentPlan: (id: any) => void;
}

const Tabs = (props: ITabsComponentProps) => {
  const {
    classes,
    handleChangeCurrentPlan,
    stepID,
    planName,
    steps,
    status,
    activeClass,
    backoffice,
  } = props;
  const { itemStyles, checkedIconStyles } = classes;

  const __renderIcon = (status: string): JSX.Element | null => {
    switch (status) {
      case STATUS.wait:
        return <Icon type="right" />;
      case STATUS.completed:
        return <Icon type="check-circle" theme="filled" className={checkedIconStyles} />;
      default:
        return null;
    }
  };

  return (
    <div
      key={stepID}
      className={
        steps
          ? steps[stepID].status === STATUS.completed
            ? classnames(itemStyles, 'plans__item completed', activeClass)
            : status === STATUS.process
            ? classnames(itemStyles, 'plans__item active')
            : classnames(itemStyles, 'plans__item')
          : classnames(itemStyles, 'plans__item')
      }
      onClick={() => handleChangeCurrentPlan(stepID)}
    >
      <p>{planName}</p>
      {__renderIcon(steps ? steps[stepID].status : backoffice)}
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  itemStyles: {
    color: theme.grey.fourth,
    border: `1px solid ${theme.grey.border}`,
    borderLeft: 'none',
    '&.completed': {
      color: theme.grey.third,
      backgroundColor: theme.grey.tabs,
    },
  },
  checkedIconStyles: {
    color: theme.green.main,
  },
});

export const TabsComponent = injectSheet(styles)(Tabs);
