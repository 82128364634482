import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  FETCH_ACCOUNT_TEAM_API,
  DELETE_USER_API,
  UPDATE_USER_API,
  RESEND_USER_INVITE_API,
  USER_CERTIFICATES_API,
  MAIL_CERTIFICTAE_API,
  BLOCK_USERS_API,
  UPDATE_SEAT_API,
  GET_USER_API,
  FETCH_PROXY_USERS_API,
  FETCH_PROXY_USERS_API as BLOCK_PROXY_USER_API,
  CREATE_PROXY_USER_API,
} from '@constants/endpoints';
import { UPDATE_USER_INFO } from '@app/back-office/team/state/team.actions';

export const UPDATE_ACCOUNT_USER = 'UPDATE_ACCOUNT_USER';
export const FETCH_ACCOUNT_USERS = 'FETCH_ACCOUNT_USERS';

export const CREATE_PROXY_USER = 'CREATE_PROXY_USER';
export const UPDATE_PROXY_USER = 'UPDATE_PROXY_USER';
export const BLOCK_PROXY_USER = 'BLOCK_PROXY_USERS';
export const FETCH_PROXY_USERS = 'FETCH_PROXY_USERS';
export const FETCH_USER = 'FETCH_USER';

export const REMOVE_ACCOUNT_USER = 'REMOVE_ACCOUNT_USER';
export const TOGGLE_ACCOUNTS_TEAM_SORTER = 'TOGGLE_ACCOUNTS_TEAM_SORTER';
export const RESEND_USER_INVITE = 'RESEND_USER_INVITE';
const BLOCK_USERS = 'BLOCK_USERS';
export const USER_CERTIFICATES = 'USER_CERTIFICATES';
export const MAIL_CERTIFICATES = 'MAIL_CERTIFICTAES';
export const UPDATE_USER_SEAT = 'UPDATE_USER_SEAT';

export const fetchAccountUsersAction = createActionRoutine(
  FETCH_ACCOUNT_USERS,
  FETCH_ACCOUNT_TEAM_API,
);

export const fetchUserAction = createActionRoutine(FETCH_USER, GET_USER_API);

export const fetchProxyUsersAction = createActionRoutine(FETCH_PROXY_USERS, FETCH_PROXY_USERS_API);
export const createProxyUserAction = createActionRoutine(CREATE_PROXY_USER, CREATE_PROXY_USER_API);
export const updateProxyUserAction = createActionRoutine('UPDATE_PROXY_USER', UPDATE_USER_API);
export const blockProxyUserAction = createActionRoutine('BLOCK_PROXY_USER', BLOCK_PROXY_USER_API);

export const updateUserSeatAction = createActionRoutine(UPDATE_USER_SEAT, UPDATE_SEAT_API);
export const fetchUserCertificatesAction = createActionRoutine(
  USER_CERTIFICATES,
  USER_CERTIFICATES_API,
);
export const updateAccountUserAction = createActionRoutine(UPDATE_ACCOUNT_USER, UPDATE_USER_API);
export const resendUserInviteAction = createActionRoutine(
  RESEND_USER_INVITE,
  RESEND_USER_INVITE_API,
);
export const removeAccountUserAction = createActionRoutine(REMOVE_ACCOUNT_USER, DELETE_USER_API);
export const mailCertificateUserAction = createActionRoutine(
  MAIL_CERTIFICATES,
  MAIL_CERTIFICTAE_API,
);
export const blockUsersAction = createActionRoutine(BLOCK_USERS, BLOCK_USERS_API);
export const toggleAccountsTeamSorterAction = createActionRoutine(TOGGLE_ACCOUNTS_TEAM_SORTER);
