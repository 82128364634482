import React, { FormEvent, useEffect } from 'react';

import { WrappedFormUtils } from 'antd/es/form/Form';
import { ThemeType } from '@shared-types/theme.type';
import { FormValidatorType } from '@shared-types/forms.types';
import { UserType } from '@shared/types/backend.types';
import { Classes } from 'jss';

import { withRouter } from 'react-router-dom';

import { Form, Input, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonControlComponent } from '@controls/button/button-control.component';

import { getYears, getMonths } from '@utils/dates';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import '@controls/input.styles.css';
import '@utils/styles/flex.css';
import '@utils/styles/width.css';
import './registration.styles.scss';

export interface IRegistrationFormComponentProps {
  user: UserType;
  isFormSubmitting: boolean;
  classes: Classes;
  form: WrappedFormUtils;
  selectedMonth: number;
  numberOfDays: number;
  showDays: (numberDays: number) => number[];
  //TODO: add type
  validateKeys: (event: any) => void;
  handleDaySelected: (e: number) => void;
  handleMonthSelected: (e: number, form: WrappedFormUtils) => void;
  handleYearSelected: (e: number, form: WrappedFormUtils) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
  handleConfirmBlur: (e: FormEvent<HTMLInputElement>) => void;
  handleValidateToNextPassword: FormValidatorType;
  handleCompareToFirstPassword: FormValidatorType;
}

const RegistrationForm = (props: IRegistrationFormComponentProps) => {
  const { user, isFormSubmitting, selectedMonth, numberOfDays, form, classes } = props;
  const {
    showDays,
    validateKeys,
    handleSubmit,
    handleConfirmBlur,
    handleValidateToNextPassword,
    handleCompareToFirstPassword,
    handleDaySelected,
    handleMonthSelected,
    handleYearSelected,
  } = props;
  const {
    containerStyles,
    formHeadingStyles,
    formTitleStyles,
    formLabelStyles,
    formSubmitStyles,
  } = classes;
  const { Option } = Select;
  const { getFieldDecorator } = form;
  const intl = useIntl();

  return (
    <div className={classnames(containerStyles, 'registration__container')}>
      <div className="registration__container">
        <h3 className={classnames(formHeadingStyles, 'registration__heading')}>
          <FormattedMessage id="registration.createYourAccount" />
        </h3>
        <Form
          className={classnames('form__layout d-flex flex-wrap')}
          layout="vertical"
          onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event, form)}
          hideRequiredMark
        >
          <div className="d-flex flex-wrap justify-between sm-w-100">
            <Form.Item
              className={classnames(formLabelStyles, 'registration__form-label sm-w-45 lg-w-45')}
              label={intl.messages['inputs.name']}
            >
              {getFieldDecorator('given_name', {
                initialValue: user ? user.given_name : '',
                rules: [
                  {
                    required: true,
                    message: intl.messages['inputs.requiredName'],
                  },
                ],
              })(<Input className="sm-w-100 input--medium-height" placeholder="John" required />)}
            </Form.Item>
            <Form.Item
              className={classnames(formLabelStyles, 'registration__form-label sm-w-45 lg-w-45')}
              label={intl.messages['inputs.lastName']}
            >
              {getFieldDecorator('family_name', {
                initialValue: user ? user.family_name : '',
                rules: [
                  {
                    required: true,
                    message: intl.messages['inputs.requiredLastName'],
                  },
                ],
              })(<Input className="sm-w-100 input--medium-height" placeholder="Doe" required />)}
            </Form.Item>
          </div>
          <div className="d-flex flex-wrap justify-between sm-w-100 input--no-rm">
            <Form.Item
              className={classnames(
                formLabelStyles,
                'registration__form-label sm-w-100 md-w-100 lg-w-60',
              )}
              label={intl.messages['inputs.emailAddress']}
            >
              {getFieldDecorator('email', {
                initialValue: user ? user.email : '',
                rules: [
                  {
                    //required: true,
                    message: intl.messages['inputs.requiredEmailAddress'],
                  },
                ],
              })(
                <Input
                  className="sm-w-100 md-w-100 input--medium-height"
                  placeholder="johndoe@me.com"
                  disabled
                />,
              )}
            </Form.Item>
            <Form.Item
              className={classnames(formLabelStyles, 'registration__form-label sm-w-100 lg-w-35')}
              label=
              {
                <>
                  {intl.messages['inputs.contactNumber']}
                  <small>&nbsp;(Optional)</small>
                </>
              }
            >
              {getFieldDecorator('contactNumber', {})(
                <Input
                  className="sm-w-100 input--medium-height"
                  placeholder="00 0000 0000"
                  onKeyPress={(e: any) => {
                    validateKeys(e);
                  }}
                  onFocus={() => {
                    //@ts-ignore
                    const el = document.querySelector('#register_contactNumber');
                    //@ts-ignore
                    el.removeAttribute('readonly');
                  }}
                  required
                />,
              )}
            </Form.Item>
          </div>
          <div className="d-flex flex-wrap justify-between sm-w-100">
            <Form.Item
              className={classnames(formLabelStyles, 'registration__form-label sm-w-100 lg-w-45')}
              label={intl.messages['inputs.password']}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: intl.messages['inputs.requiredPassword'],
                  },
                  {
                    validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                      handleValidateToNextPassword(form, rule, value, callback),
                  },
                ],
              })(
                <Input.Password
                  className="input--medium-height sm-w-100"
                  placeholder="Password"
                  required
                />,
              )}
            </Form.Item>
            <Form.Item
              className={classnames(formLabelStyles, 'registration__form-label sm-w-100 lg-w-45')}
              label={intl.messages['inputs.confirmPassword']}
            >
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: intl.messages['inputs.requiredPassword'],
                  },
                  {
                    validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                      handleCompareToFirstPassword(form, rule, value, callback),
                  },
                ],
              })(
                <Input.Password
                  className="input--medium-height sm-w-100"
                  placeholder="Confirm password"
                  onBlur={handleConfirmBlur}
                  required
                />,
              )}
            </Form.Item>
          </div>

          <div className="button-container">
            <Form.Item className={formSubmitStyles}>
              <ButtonControlComponent
                text={intl.messages['buttons.register'].toString()}
                size="medium"
                color="green"
                htmlType="submit"
                loading={isFormSubmitting}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  containerStyles: {
    ...theme.container,
    height: '100vh',
  },
  formHeadingStyles: {
    color: theme.black.main,
  },
  formTitleStyles: {
    color: theme.green.main,
  },
  formLabelStyles: {
    ...theme.inputs.label,
    '& label span': {
      display: 'inherit',
      opacity: '0',
      backgroundColor: 'transparent',
      cursor: 'default',
    },
    '& label': {
      color: theme.grey.fourth,
      fontSize: '18px',
      opacity: 1,
    },
  },
  formSubmitStyles: {
    marginTop: 24,
    '& button': {
      fontSize: '20px',
    },
  },
});

const RegistrationFormStyled: any = injectSheet(styles)(withRouter(RegistrationForm as any));
export const RegistrationFormComponent: any = Form.create({ name: 'register' })(
  RegistrationFormStyled,
);
