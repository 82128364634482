import {
  uploadImageCompanyAction,
  changeSetupImageStatusAction,
  validateCreationAction,
  changeSetupIndustryAction,
} from './creation.actions';

import { deleteAccountLogoAction } from '../state/creation.actions';

import { IProfessionalState } from '@app/back-office/professional.reducer';
import { AnyAction } from 'redux';

import { combineActions } from 'redux-actions';

import update from 'immutability-helper';

export const setupCreationReducer = {
  [changeSetupImageStatusAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        hasImage: { $set: payload },
      },
    }),
  [changeSetupIndustryAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        industryID: { $set: payload },
      },
    }),
  [combineActions(uploadImageCompanyAction.REQUEST, uploadImageCompanyAction.SUCCESS) as any]: (
    state: IProfessionalState,
  ) =>
    update(state, {
      programs: {
        hasImage: { $set: true },
      },
    }),
  [uploadImageCompanyAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        hasImage: { $set: false },
      },
      errorMessages: {
        [payload.error.code]: { $set: payload.error },
      },
    }),
  [validateCreationAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    let updatedSteps: any = [...state.programs.meta.validSteps];
    //TODO: add type
    updatedSteps[payload.step] = payload.isValid;

    return update(state, {
      programs: {
        meta: {
          validSteps: { $set: updatedSteps },
        },
      },
    });
  },
  [deleteAccountLogoAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) => {
    return update(state, {
      errorMessages: {
        [payload.code]: { $set: payload }
      }
    })
  }
};
