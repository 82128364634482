import React, { useState, SetStateAction } from 'react';

import { UserType } from '@shared/types/backend.types';
import { ROLE } from '@constants/roles';

//@ts-ignore
import * as XLSX from 'xlsx';
import { message } from 'antd';
import { UploadFileComponent } from '../view/upload.component';

import { STATUS } from '@constants/status';
import { FILE_EXTENSION } from '@constants/upload';

const csvHeaderRemoval = 'NAME,LAST NAME,E-MAIL,JOB TITLE';

export interface IUploadFileProps {
  jobTitles?: [];
  csvIndications: string;
  user: UserType | undefined
  handleFileImport: (users: UserType[]) => void;
}


//TODO: add types
export const UploadFileContainer = (props: any) => {
  const { handleFileImport, csvIndications, user } = props;
  const [hasFile, setHasFile] = useState<SetStateAction<boolean>>(false);



  const defaultUploadConfig = {
    name: 'file',
    multiple: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      'Access-Control-Allow-Origin': '*'
    },
    onChange(info: any) {
      const { status, name } = info.file;

      const fileExtension = name.substring(name.indexOf('.'));

      switch (status) {
        case STATUS.uploading:
          setHasFile(true);
          break;
        case STATUS.done:
          if (fileExtension === FILE_EXTENSION.csv) {
            __handleCSVMapping(info);
          } else {
            __handleExcelImport(info);
          }

          setHasFile(true);
          message.success(`${name} file uploaded successfully.`);

          break;
        case STATUS.removed:
          message.warning(`${name} file has been removed.`);

          if (info.fileList.length === 0) {
            setHasFile(false);
          }

          break;
        case STATUS.error:
          message.error(`${name} file upload failed.`);
          break;
        default:
          setHasFile(false);
      }
    },
  };

  const __getUsersMapped = (users: any) => {
    return users.map((item: string) => {
      const sanitizedItem = item.split(',');

      return {
        givenName: sanitizedItem[0].trim() !== '' ? sanitizedItem[0] : undefined,
        familyName: sanitizedItem[1].trim() !== '' ? sanitizedItem[1] : undefined,
        email: sanitizedItem[2].trim() !== '' ? sanitizedItem[2] : undefined,
        jobTitle: sanitizedItem[3].trim() !== '' ? sanitizedItem[3] : undefined,
        canCreateProject: true,
        reportsToUserID: user ? user.user_id : undefined,
        name: sanitizedItem[0].trim() !== '' ? sanitizedItem[0] : undefined,
        contractorCompanyID: user.user_metadata.contractorCompanyID,
        app_metadata: { role: { roleID: 1, name: ROLE[1], }, },
      };
    });
  };

  const getUsersArray = (unformattedData: string): string[] => {
    const sanitizedString = unformattedData.substring(
      unformattedData.indexOf(csvHeaderRemoval) + csvHeaderRemoval.length + 1,
    );
    return sanitizedString.split('\n');
  };

  const __handleCSVMapping = (info: any) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      //@ts-ignore
      const csvString = e.target.result.toString();
      
      let usersArray: string[] = getUsersArray(csvString);
      // Remove first element of array because of being an empty value
      usersArray.shift();
      // Remove last element of array if it is an empty value
      const usersArrayLastIndex = usersArray.length - 1;
      const usersArrayLastElement: string = usersArray[usersArrayLastIndex];
      if (usersArrayLastElement === "") {
        usersArray.pop();
      }
      const mappedUsers = __getUsersMapped(usersArray);

      handleFileImport(mappedUsers);
    };

    reader.readAsText(info.file.originFileObj);
  };

  const __handleExcelImport = (info: any) => {
    const reader: FileReader = new FileReader();

    reader.onload = (evt: any): void => {
      /* Parse data */
      const bstr = evt.target && evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //@ts-ignore
      const excelDataString = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      let usersArray: string[] = getUsersArray(excelDataString);
      usersArray.pop();

      const mappedUsers = __getUsersMapped(usersArray);

      handleFileImport(mappedUsers);
    };

    reader.readAsBinaryString(info.file.originFileObj);
  };

  return (
    <UploadFileComponent
      hasFile={hasFile}
      defaultUploadConfig={defaultUploadConfig}
      csvIndications={csvIndications}
    />
  );
};
