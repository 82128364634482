import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  RESTORE_PASSWORD_API,
  REGISTRATION_API,
  FETCH_PROXY_USERS_API,
} from '@constants/endpoints';

export const GET_USER = 'GET_USER';
export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';
export const SUBMIT_PASSWORD_RESTORE = 'SUBMIT_PASSWORD_RESTORE';
export const SUBMIT_LOGIN = 'SUBMIT_LOGIN';
export const SUBMIT_REGISTER = 'SUBMIT_REGISTER';
export const FETCH_USER_REGISTER = 'FETCH_USER_REGISTER';

export const fetchUserAction = createActionRoutine(GET_USER);

export const fetchUserProfileAction = createActionRoutine(FETCH_USER_PROFILE);

export const fetchUserRegisterAction = createActionRoutine(FETCH_USER_REGISTER, REGISTRATION_API);

export const submitRegisterAction = createActionRoutine(SUBMIT_REGISTER, REGISTRATION_API);

export const submitLoginAction = createActionRoutine(SUBMIT_LOGIN);

export const setAccountIdAction = createActionRoutine("SET_ACCOUNT_ID");

export const submitPasswordRestoreAction = createActionRoutine(
  SUBMIT_PASSWORD_RESTORE,
  RESTORE_PASSWORD_API,
);

export const setTeamAccessUserIdAction = createActionRoutine('SET_TEAM_ACCESS_USER_ID');
