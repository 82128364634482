import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import {
  changePathRedirectionAction,
  changeCurrentStepAction,
  resetLayoutStepsAction,
  validateCurrentStepAction,
} from './layout.action';
import { fetchProjectsAction } from '@app/back-office/projects/state/projects.actions';
import { fetchAccountsPlansAction } from '@app/admin/accounts/safety-plans/state/safety-plans.actions';
import { fetchService } from '@rdx/services.saga';
import { associatedForms } from '@shared/utils/validations';
import { AnyAction } from 'redux';
import { minValidLength } from '@app/back-office/programs/programs.validations';

//TODO: add types
function* changePathRedirectionTrigger(params: any) {
  yield put(
    changePathRedirectionAction.fulfill({
      path: params.payload,
    }),
  );
}

function* changeCurrentStepTrigger(params: any) {
  yield put(
    changeCurrentStepAction.fulfill({
      currentStep: params.payload.currentStep,
      currentTitle: params.payload.title,
      updatedSteps: params.payload.updatedSteps,
    }),
  );
}

function* resetLayoutStepsTrigger(params: any) {
  yield put(resetLayoutStepsAction.fulfill({}));
}

function* validateCurrentStepRequest(action: AnyAction) {
  const projectsEndpoint = fetchProjectsAction.getEndpoint({
    accountID: action.payload.accountID,
  });
  const accountPlansEndpoint = fetchAccountsPlansAction.getEndpoint({
    accountID: action.payload.accountID,
  });

  try {
    const response = yield call(fetchService, accountPlansEndpoint);

    //check if account associated plans are equal to the total plans step 1
    if (Object.keys(associatedForms(response)).length >= minValidLength) {
        yield put(validateCurrentStepAction.success({ step: 1 }));
    }
  } catch (error) {
    yield put(
      validateCurrentStepAction.failure({
        error: {
          code: 'validatePrevConfigBackOffice',
          title: 'Failed validate previous configuration',
          message: 'theres been an error while validating if account has previous configuration',
        },
      }),
    );
    if (!error.wasCancelled) {
      yield put(
        fetchAccountsPlansAction.failure({
          error: {
            code: 'validatePrevConfigBackOffice',
            title: 'Failed validate previous configuration',
            message: 'theres been an error while validating if account has previous configuration',
          },
        }),
      );
    }
  }
}

export function* layoutSaga() {
  yield takeLatest(changePathRedirectionAction.TRIGGER, changePathRedirectionTrigger);
  yield takeLatest(changeCurrentStepAction.TRIGGER, changeCurrentStepTrigger);
  yield takeLatest(resetLayoutStepsAction.TRIGGER, resetLayoutStepsTrigger);
  yield takeEvery(validateCurrentStepAction.REQUEST, validateCurrentStepRequest);
}
