import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  FETCH_INDUSTRIES_API,
  ADD_ACCOUNT_FORM_API,
  ADD_PROJECT_API,
  SUBMIT_INVITE_API,
  FETCH_ACCOUNT_IS_CONFIGURATE,
} from '@constants/endpoints';

export const RESET_SAFETY_SETUP = 'RESET_SAFETY_SETUP';
export const SUBMIT_TEAM_SETUP = 'SUBMIT_TEAM_SETUP';
export const FETCH_INDUSTRIES = 'FETCH_INDUSTRIES';

export const CONFIG_ADD_ACCOUNT_FORMS = 'CONFIG_ADD_ACCOUNT_FORMS';
export const CONFIG_ADD_ACCOUNT_PROJECTS = 'CONFIG_ADD_ACCOUNT_PROJECTS';
export const CONFIG_ADD_ACCOUNT_TEAM = 'CONFIG_ADD_ACCOUNT_TEAM';

export const UPDATE_ACCOUNT_CONFIGURATION = 'UPDATE_ACCOUNT_CONFIGURATION';

export const resetSafetySetupAction = createActionRoutine(RESET_SAFETY_SETUP);

export const submitSafetyTeamAction = createActionRoutine(
  SUBMIT_TEAM_SETUP,
  //SUBMIT_SAFETY_SETUP_API,
);

export const fetchIndustriesAction = createActionRoutine(FETCH_INDUSTRIES, FETCH_INDUSTRIES_API);

export const configAddFormsAction = createActionRoutine(
  CONFIG_ADD_ACCOUNT_FORMS,
  ADD_ACCOUNT_FORM_API,
);

export const configAddProjectsAction = createActionRoutine(
  CONFIG_ADD_ACCOUNT_PROJECTS,
  ADD_PROJECT_API,
);


export const updateAccountConfigurationAction = createActionRoutine(
  UPDATE_ACCOUNT_CONFIGURATION,
  FETCH_ACCOUNT_IS_CONFIGURATE,
);
