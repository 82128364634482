import React, { useState } from 'react'



import { Button, Modal } from 'antd'
import { PackageSeatDetail } from '@shared/types/backend.types';

import './view-package.styles.scss'

export interface IViewPackageModalProps {
    seatsDetails: any;
}

export const ViewPackageModal = (props: IViewPackageModalProps) => {

    const { seatsDetails } = props
    const [packageModal, setPackageModal] = useState(false)


    return (
        <div className='view-package'>
            <Button onClick={() => setPackageModal(true)} type="primary" >Package Details</Button>

            <Modal
                title="Package Details"
                visible={packageModal}
                onCancel={() => setPackageModal(false)}
                footer={<Button className='ant-modal-btn-close' type='primary' onClick={() => setPackageModal(false)}>Close</Button>}
            >
                <div className='ant-modal-body-content'>
                    <div className='view-package__table-container'>
                        <table className="sa-table">
                            <thead>
                                <tr>
                                    <th>Package Name</th>
                                    <th>Assigned Seats</th>
                                    <th>Purchased Seats</th>
                                </tr >
                            </thead >

                            <tbody>

                                {
                                    seatsDetails !== undefined && seatsDetails.length > 0 ? (
                                        seatsDetails.map((seat: PackageSeatDetail, index: number) => (
                                            <tr key={index} >
                                                <td>{seat.packageName}</td>
                                                <td>{seat.assignedSeats}</td>
                                                <td>{seat.seats}</td>
                                            </tr>
                                        ))
                                    )
                                        :
                                        <tr>
                                            <td colSpan={3}><strong>No Package Available</strong></td>
                                        </tr>
                                }
                            </tbody>
                        </table >

                    </div>
                </div>
            </Modal>
        </div>
    )
}


