import { takeLatest, call, put } from 'redux-saga/effects';

import { ProjectType } from '@shared/types/backend.types';
import { AnyAction } from 'redux';

import { fetchService, addService, updateService, deleteService } from '@rdx/services.saga';

import {
  removeProjectAction,
  updateProjectAction,
  addProjectAction,
  fetchProjectsAction,
  addCachedProjectAction,
  removeProjectRowAction,
  fetchAccountsProjectAction,
} from './projects.actions';

import { arrayToObject } from '@shared/helpers/state-caster';
import { message } from 'antd';

function* fetchProjectsRequest(action: AnyAction) {
  try {
    const endPoint = fetchProjectsAction.getEndpoint({
      accountID: action.payload.accountID,
      userID: action.payload.userID,
    });
    const data = yield call(fetchService, endPoint);

    const mappedProjects = data.map(({ projectID, ...rest }: ProjectType) => ({
      ...rest,
      key: projectID,
      projectID,
    }));

    const projectsObject = arrayToObject(mappedProjects, 'projectID');

    yield put(fetchProjectsAction.success({ projects: projectsObject }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchProjectsAction.failure({
          error: {
            code: 'backOfficeFetchProjects',
            title: 'Failed to retrieve projects',
            messages: 'There has been an error while retrieving projects.',
          },
        }),
      );
    }
  }
}

function* fetchAccountsProjectsRequest({ payload }: AnyAction) {
  try {
    const endPoint = fetchAccountsProjectAction.getEndpoint({ accountID: payload });
    const data: ProjectType[] = yield call(fetchService, endPoint);

    const mappedProjects = data.map(({ projectID, ...rest }: ProjectType) => ({
      ...rest,
      key: projectID,
      projectID,
    }));

    const projectsObject = arrayToObject(mappedProjects, 'projectID');

    yield put(fetchAccountsProjectAction.success({ projects: projectsObject }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchAccountsProjectAction.failure({
          error: {
            code: 'backOfficeFetchProjects',
            title: 'Failed to retrieve projects',
            messages: 'There has been an error while retrieving projects.',
          },
        }),
      );
    }
  }
}

function* addProjectRequest(action: AnyAction) {
  const { accountID, project } = action.payload;
  const endpoint = addProjectAction.getEndpoint({ accountID });

  let payloadreq = { ...project };
  delete payloadreq.key;

  try {
    message.loading({ content: 'Adding Project', key: 'addProject' }, 0);
    const response = yield call(addService, endpoint, [payloadreq]);

    if (!response[0].succeeded) {
      message.error({ content: response[0].result, key: 'addProject' }, 2);
    } else {
      yield put(
        addProjectAction.success({
          project: { ...response[0].project, key: response[0].project.projectID },
          key: project.key,
        }),
      );
      message.success({ content: 'Project Created', key: 'addProject' }, 2);
    }
  } catch (error) {
    if (!error.wasCancelled) {
      message.error({ content: 'Error while adding the project', key: 'addProject' }, 2);
    }
  }
}

function* addCachedProjectTrigger(action: AnyAction) {
  yield put(addCachedProjectAction.fulfill({ project: action.payload.project }));
}

function* updateProjectRequest(action: AnyAction) {
  try {
    const endpoint = updateProjectAction.getEndpoint({ accountID: action.payload.accountID });

    yield call(updateService, endpoint, action.payload.project);

    yield put(updateProjectAction.success({ project: action.payload.project }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        updateProjectAction.failure({
          error: {
            code: 'backOfficeUpdateProject',
            title: 'Failed to update project',
            message: 'There has been an error while saving project.',
          },
        }),
      );
    }
  }
}

function* removeProjectRequest(action: AnyAction) {
  try {
    const { accountID, projectID } = action.payload;
    const endpoint = removeProjectAction.getEndpoint({ accountID, projectID });
    const fetchProjectsEndPoint = fetchAccountsProjectAction.getEndpoint({ accountID });

    yield call(deleteService, endpoint);

    const projects: ProjectType[] = yield call(fetchService, fetchProjectsEndPoint);

    const mappedProjects = projects.map(({ projectID, ...rest }: ProjectType) => ({
      ...rest,
      key: projectID,
      projectID,
    }));

    const projectsObject = arrayToObject(mappedProjects, 'projectID');

    yield put(fetchAccountsProjectAction.success({ projects: projectsObject }));


    yield put(removeProjectAction.success({ projectID }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        removeProjectAction.failure({
          error: {
            code: 'backOfficeRemoveProject',
            title: 'Failed to remove project',
            message: 'There has been an error while removing project.',
          },
        }),
      );
    }
  }
}

function* removeProjectRowTrigger(action: AnyAction) {
  yield put(removeProjectRowAction.fulfill({ key: action.payload.key }));
}

export function* projectsSaga() {
  yield takeLatest(fetchProjectsAction.REQUEST, fetchProjectsRequest);
  yield takeLatest(fetchAccountsProjectAction.REQUEST, fetchAccountsProjectsRequest);
  yield takeLatest(addProjectAction.REQUEST, addProjectRequest);
  yield takeLatest(addCachedProjectAction.TRIGGER, addCachedProjectTrigger);
  yield takeLatest(updateProjectAction.REQUEST, updateProjectRequest);
  yield takeLatest(removeProjectAction.REQUEST, removeProjectRequest);
  yield takeLatest(removeProjectRowAction.TRIGGER, removeProjectRowTrigger);
}
