import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import { FETCH_FORMS_API } from '@constants/endpoints';

export const FETCH_FORMS = 'FETCH_FORMS';
export const CREATE_PLAN_STEPS = 'CREATE_PLAN_STEPS';
export const CHANGE_CURRENT_PLAN_STEP = 'CHANGE_CURRENT_PLAN_STEP';
export const VALIDATE_PLANS_STEP = 'VALIDATE_PLANS_STEP';

export const fetchFormsAction = createActionRoutine(FETCH_FORMS, FETCH_FORMS_API);

export const createPlanStepsAction = createActionRoutine(CREATE_PLAN_STEPS);
export const changeCurrentPlanStepAction = createActionRoutine(CHANGE_CURRENT_PLAN_STEP);

export const validatePlansStepAction = createActionRoutine(VALIDATE_PLANS_STEP);
