//TODO: add types
export const getById = (dictionary: any, id: string | number) => {
  return dictionary[id];
};

export const getList = (dictionary: any): any => {
  
  if (!dictionary) {
    return [];
  }
  const data = Object.values(dictionary).map((item: any) => item);
 
  return data;
};

export const getKeys = (dictionary: any) => {
  return Object.keys(dictionary);
};

export const arrayToObject = (array: any[], keyField: string) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
