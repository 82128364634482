import React, { memo, useState } from 'react'
import { HeadingComponent } from '@components/layout/header/heading.component';
import { CorrectiveActionsType, ProjectType, UserType } from '@shared/types/backend.types';
import { Button, Dropdown, Icon, Input, Menu, Table, Tag } from 'antd';
import { useSelector } from 'react-redux';
import './corrective-actions.styles.scss'
import { Link } from 'react-router-dom';
import { IAppState } from '@rdx/root.reducer';
import CreateCACompoent from './modal/create-ca/create-ca.component';
import { companyNameDecoder, getUniqueFilters, toTitleCase } from '@shared/utils/utils';
import { ROLES } from '@constants/roles';
import { SearchFilter } from './search-filter/search.filter';


interface ICorrectiveActionsComponent {
    projects: ProjectType[];
    team: UserType[];
    isLoading: boolean;
    correctiveActions: CorrectiveActionsType[];
    openCreateCAModal?: boolean;
    toggleCreateCAModal?: (value: React.SetStateAction<boolean>) => void;
    handleFetchFormQuestions?: (formID: string) => void;
}

const projectNameDecoder = (projectID: number, projects: any[], index: string) => {
    const project = projects.find((project: ProjectType) => project.projectID === projectID);

    if (!project)
        return <b>N/A</b>

    return project[index]
}


const CorrectiveActionsComponent = (props: ICorrectiveActionsComponent) => {

    const {
        projects,
        isLoading,
        correctiveActions,
        openCreateCAModal,
        toggleCreateCAModal,
        handleFetchFormQuestions
    } = props;

    const { professional, session } = useSelector((state: IAppState) => state);
    const { severitys, status, triggers } = professional.correctiveActions;
    const { userRole } = session;
    const isAdmin = userRole === ROLES.systemAdmin;


    const columns = [
        {
            title: `Total Corrective Actions - ${correctiveActions.length}`,
            children: [
                {
                    title: 'CA Number',
                    dataIndex: 'caTicketHash',
                    defaultSortOrder: 'ascend',
                    sorter: (a: CorrectiveActionsType, b: CorrectiveActionsType) => Number(a.caTicketHash.split("-")[1]) - Number(b.caTicketHash.split("-")[1]),
                    render: (ticketHashID: string, ca: CorrectiveActionsType) => (
                        <Link className='ca-table-clickable' style={{ color: 'grey' }} to={`/${userRole === 'systemAdmin' ? 'admin' : 'back-office'}/corrective-actions/${ca.correctiveActionID}`}><b>{`#${ticketHashID}`}</b></Link>
                    ),
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                        <SearchFilter setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} dataIndex="caTicketHash" />
                    ),
                    filterIcon: (filtered: any) => (
                        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                    ),
                    onFilter: (value: string, record: CorrectiveActionsType) => (
                        `#${record.caTicketHash}`.toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    ),
                },
                {
                    title: 'Situation Statement',
                    dataIndex: 'title',
                    render: (text: string, ca: CorrectiveActionsType) => (
                        <Link className='ca-table-clickable' to={`/${userRole === 'systemAdmin' ? 'admin' : 'back-office'}/corrective-actions/${ca.correctiveActionID}`}>{text}</Link>
                    ),
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                        <SearchFilter setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} dataIndex="title" />
                    ),
                    onFilter: (value: string, record: CorrectiveActionsType) => (
                        record.title
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    ),
                    filterIcon: (filtered: any) => (
                        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                    ),
                },
                {
                    title: 'Narrative',
                    dataIndex: 'description',
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                        <SearchFilter setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm} clearFilters={clearFilters} dataIndex="description" />
                    ),
                    filterIcon: (filtered: any) => (
                        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                    ),
                    onFilter: (value: string, record: CorrectiveActionsType) => (
                        record.description
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    ),
                },

                {
                    title: 'Company Name',
                    dataIndex: 'companyName',
                    filters: getUniqueFilters(correctiveActions, 'companyName', 'companyID'),
                    onFilter: (value: any, record: CorrectiveActionsType) => record.companyID.toString().includes(value)
                },
                {
                    title: 'Project Number',
                    dataIndex: 'projectID',
                    key: 'projectNumber',
                    render: (id: number) => projectNameDecoder(id, projects, 'number'),
                },
                {
                    title: 'Project Name',
                    dataIndex: 'projectName',
                    key: 'projectName',
                    filters: Array.from(projects).map((project: ProjectType) => ({ text: project.name, value: project.projectID })),
                    onFilter: (value: any, record: any) => record.projectID.toString().includes(value),
                },
                {
                    title: 'Incident Type',
                    dataIndex: 'trigger',
                    filters: Array.from(triggers).map((type: string) => ({ text: type, value: type })),
                    onFilter: (value: any, record: any) => record.trigger.includes(value),
                },
                {
                    title: 'Risk Level',
                    dataIndex: 'severity',
                    render: (text: string | null) => !text ? <Tag color='red'>N/A</Tag> : text,
                    filters: Array.from(severitys).map((type: string) => ({ text: type, value: type })),
                    onFilter: (value: any, record: any) => record.severity.includes(value),

                },
                {
                    title: 'Assigned to',
                    dataIndex: 'assignedTo',
                    render: (id: string, record: CorrectiveActionsType) => record.assignedToUser,
                    filters: getUniqueFilters(correctiveActions, 'assignedToUser', 'assignedTo'),
                    onFilter: (value: any, record: any) => record.assignedTo.includes(value)
                },
                {
                    title: 'Opened by',
                    dataIndex: 'openedBy',
                    filters: getUniqueFilters(correctiveActions, 'openedByUser', 'openedBy'),
                    render: (id: string, record: CorrectiveActionsType) => record.openedByUser,
                    onFilter: (value: any, record: any) => record.openedBy.includes(value)
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (value: string) => <Tag color='red'>{toTitleCase(value)} </Tag>,
                    filters: Array.from(status).map((type: string) => ({ text: type, value: type })),
                    onFilter: (value: any, record: any) => toTitleCase(record.status).includes(value)
                },
                {
                    title: '',
                    width: '4%',
                    key: 12,
                    render: (ca: CorrectiveActionsType) => (
                        <Dropdown overlay={
                            <Menu >
                                <Menu.Item className='ca-table-actions'>
                                    <Icon type="eye" />
                                    <Link style={{ fontWeight: 'bold' }} to={`/${userRole === 'systemAdmin' ? 'admin' : 'back-office'}/corrective-actions/${ca.correctiveActionID}`}>View Details</Link>
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <Icon type="more" />
                        </Dropdown>
                    ),
                },
            ]
        }
    ];

    return (
        <>
            <div className='sa__container'>
                <div className='sa__box'>
                    {
                        isAdmin ?
                            < HeadingComponent title='Corrective Actions' />
                            :
                            < HeadingComponent title='Corrective Actions' btnTitle='Create New CA' onClkBtnFunction={() => toggleCreateCAModal && toggleCreateCAModal(true)} />
                    }
                    <div className='sa__box-content'>
                        <Table className='ca-table' columns={columns as any} loading={isLoading} rowKey={(correctiveAction) => String(correctiveAction.correctiveActionID)} bordered={true} dataSource={correctiveActions} pagination={false} />
                    </div>
                </div>
            </div>
            <CreateCACompoent openModal={openCreateCAModal} toggleModal={toggleCreateCAModal} handleFetchFormQuestions={handleFetchFormQuestions} />
        </>
    )
}

export default memo(CorrectiveActionsComponent)
