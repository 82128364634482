import React, { ChangeEvent } from 'react';

import { Sorter } from '@shared/types/utils.types';
import { UserType, ContractorType } from '@shared/types/backend.types';
import { AntdSelectTarget } from '../team';

import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';
import { BackOfficeTeamItemComponent } from '@app/back-office/team/item/item.component';
import { Select, Tooltip } from 'antd';
import { SorterComponent } from '@components/controls/table/sorter.component';
import { EmptyRowComponent } from '@components/controls/table/empty-row.component';
import { useIntl } from 'react-intl';

import '@shared/components/back-office/team/supervisors.styles.scss';
import '@shared/components/back-office/team/switch.styles.scss';

import '@admin/accounts/team/view/team.styles.scss';
import '@utils/styles/cursor.scss';

//TODO: add types
export interface ITeamTableLockedProps {
  loggedUser?: UserType;
  team: UserType[];
  AccountTeam: UserType[];
  companyContractors: ContractorType[];
  modifiedUsers: UserType[];
  jobTitles: [];
  sorter: Sorter;
  isLoading: boolean;
  handleSort: (key: any) => void;
  handleUpdateUser: (
    event: ChangeEvent<HTMLSelectElement> | AntdSelectTarget,
    user: UserType,
  ) => void;
  onFilterChange: (value: string) => void;
  handleSaveUser: (userID: any) => void;
  handleDeleteUser: (id: string, status: boolean) => void;
  resendUserInvite: (selectedUserId: string) => void;
}

export const TeamTableLockedComponent = (props: ITeamTableLockedProps) => {
  const {
    team,
    modifiedUsers,
    companyContractors,
    isLoading,
    sorter,
    loggedUser,
    AccountTeam,
  } = props;
  const {
    handleSort,
    handleSaveUser,
    handleUpdateUser,
    resendUserInvite,
    onFilterChange,
    handleDeleteUser,
  } = props;
  const intl = useIntl();
  const renderReadOnlyRow = () => {
    if (isLoading) {
      return (
        <td colSpan={10}>
          <div style={{ padding: 50 }}>
            <TableLoadingSpinnerComponent />
            <h4 className="table__accounts-team__loading-title">Loading Team</h4>
          </div>
        </td>
      );
    }
    if (!team.length) {
      return <EmptyRowComponent colspan={10} text="Your team is empty." />;
    }

    return team.map((user: any, index: number) =>
      user.user_id !== (loggedUser ? loggedUser.user_id : '') ? (
        <BackOfficeTeamItemComponent
          index={index}
          user={user}
          team={team}
          AccountTeam={AccountTeam}
          companyContractors={companyContractors}
          modifiedUsers={modifiedUsers}
          handleSaveUser={handleSaveUser}
          handleUpdateUser={handleUpdateUser}
          resendUserInvite={resendUserInvite}
          handleDeleteUser={handleDeleteUser}
        />
      ) : null,
    );
  };

  return (
    <div className="sa__box-content">
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Select
          style={{ width: 200 }}
          defaultValue="active"
          onChange={(value: string) => onFilterChange(value)}
        >
          <Select.Option value="all"> All</Select.Option>
          <Select.Option value="active"> Active</Select.Option>
          <Select.Option value="inactive"> Inactive</Select.Option>
        </Select>
      </div>

      <table className="sa-table">
        <thead>
          <tr>
            <th className="unselectable" onClick={() => handleSort('name')}>
              <span>{intl.messages['inputs.name']}</span>
              <SorterComponent status={sorter.key === 'name' ? sorter.order : 'desc'} />
            </th>
            <th className="unselectable" onClick={() => handleSort('email')}>
              {intl.messages['inputs.email']}
              <SorterComponent status={sorter.key === 'email' ? sorter.order : 'desc'} />
            </th>
            <th className="unselectable" onClick={() => handleSort('user_metadata.jobTitle')}>
              {intl.messages['inputs.jobTitle']}
              <SorterComponent
                status={sorter.key === 'user_metadata.jobTitle' ? sorter.order : 'desc'}
              />
            </th>
            <th className="unselectable" onClick={() => handleSort('user_metadata.package')}>
              {intl.messages['inputs.package']}
              <SorterComponent
                status={sorter.key === 'user_metadata.package' ? sorter.order : 'desc'}
              />
            </th>
            <th className="unselectable" onClick={() => handleSort('tier')}>
              {intl.messages['inputs.tier']}
              <SorterComponent status={sorter.key === 'tier' ? sorter.order : 'desc'} />
            </th>
            <th className="unselectable">{intl.messages['inputs.role']}</th>
            <th className="unselectable">{intl.messages['inputs.contractorCompany']}</th>
            <th className="unselectable">{intl.messages['inputs.reportsTo']}</th>
            <th className="unselectable">
              {intl.messages['inputs.createProject']}
              <Tooltip title="The user will be able to create projects." placement="topRight">
                <i className="fas fa-question-circle sorter" />
              </Tooltip>
            </th>
            <th className="unselectable"></th>
          </tr>
        </thead>
        <tbody>{renderReadOnlyRow()}</tbody>
      </table>
    </div>
  );
};
