import { UserType } from '@shared/types/backend.types';


export function createTeamTearing(data: UserType[], user: UserType | null, tier: number): any {

    let children: UserType[] = [];

    if (user === null) {
        children = data.filter((owner: UserType) => owner.reportsTo === null);
    }

    else {
        if (user !== undefined) {
            children = data.filter((item: UserType) => item.user_metadata.reportsToUserID === user.user_id);
        }
    }

    if (children.length === 0) {
        return null;
    }

    children.map((child: any) => {
        child.tier = tier;
        createTeamTearing(data, child, tier + 1);
    })
}