import React, { Fragment } from 'react';

import { ThemeType } from '@shared-types/theme.type';
import { Classes } from 'jss';

import { Link } from 'react-router-dom';

import classnames from 'classnames';
import injectSheet from 'react-jss';

import './session-header.styles.css';

export interface ISessionHeaderComponentProps {
  classes: Classes;
}

const SessionHeader = (props: ISessionHeaderComponentProps) => {
  const { containerStyles } = props.classes;

  return (
    <Fragment>
      <div className={classnames(containerStyles, 'session-header__container')}>
        <Link to="/">
          <img
            src="https://cdn.velocityvue.com/safety-assist-logo/safety-assist-logo.png"
            alt="Safety Assist Logo"
            className="session__logo"
          />
        </Link>
      </div>
    </Fragment>
  );
};

const styles = (theme: ThemeType) => ({
  containerStyles: {
    ...theme.container,
  },
});

export const SessionHeaderComponent = injectSheet(styles)(SessionHeader);
