import { baseTheme } from './base.theme';

export const lightTheme = {
  ...baseTheme,
  menuBackground: baseTheme.yellow.main,
  menuText: baseTheme.black.main,
  menuItemHover: baseTheme.yellow.secondary,
  menuItemActive: baseTheme.green.main,
  dashboardBackground: baseTheme.grey.secondary,
};
