import React, { useState } from 'react';

import { Popconfirm, Menu, Dropdown, Icon, Switch } from 'antd';

import { TeamChangePasswordContainer } from '@components/admin/change-password/change-password.container';
import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';

import { UserType, ContractorType } from '@shared/types/backend.types';
import { Link } from 'react-router-dom';

interface IBackOfficeItemProps {
  user: any;
  team: UserType[];
  AccountTeam: UserType[];
  companyContractors: ContractorType[];
  index: number;
  modifiedUsers: UserType[];
  handleSaveUser: (user_id: string) => void;
  handleUpdateUser: (event: any, user: any) => void;
  resendUserInvite: (selectedUserId: string) => void;
  handleDeleteUser: (id: string, status: boolean) => void;
}

export const BackOfficeTeamItemComponent = (props: IBackOfficeItemProps) => {
  const { user, companyContractors, team, AccountTeam, index, modifiedUsers } = props;
  const { handleSaveUser, handleUpdateUser, resendUserInvite, handleDeleteUser } = props;
  const [reportsToUserInfo, setreportsToUserInfo] = useState(
    AccountTeam.filter(
      (memeber: UserType) => memeber.user_id === user.user_metadata.reportsToUserID,
    )[0],
  );

  return (
    <tr key={index}>
      <td>
        <input
          name="name"
          className={`ant-select-selection ant-select-selection--single ${
            user.blocked ? 'ant-input-disabled' : ''
          }`}
          placeholder={user.name ? user.name : 'Name'}
          disabled={user.blocked ? true : false}
          onChange={(e: any) => handleUpdateUser(e, user)}
        />
      </td>
      <td className="container">
        <div>{user.email}</div>
      </td>
      <td className="container">
        <input
          name="jobTitle"
          disabled={user.blocked ? true : false}
          placeholder={user.user_metadata.jobTitle}
          className={`ant-select-selection ant-select-selection--single ${
            user.blocked ? 'ant-input-disabled' : ''
          }`}
          onChange={(e: any) => handleUpdateUser(e, user)}
        />
      </td>
      <td className="container">
        {user.user_metadata.package !== null ? (
          <div>{user.user_metadata.package}</div>
        ) : (
          <div> N/A </div>
        )}
      </td>
      <td>
        {user.blocked ? (
          <b style={{ color: 'red' }}>
            {`Tier ${user.tier}`}
            <br />
            <b style={{ fontSize: 12 }}>(Inactive)</b>
          </b>
        ) : user.tier ? (
          `Tier ${user.tier}`
        ) : (
          <b> undefined</b>
        )}
      </td>
      {user.app_metadata.role && (
        <td className="container">
          <div>{user.app_metadata.role.name}</div>
        </td>
      )}
      <td className="container">
        <select
          name="contractorCompanyID"
          className="ant-select-selection ant-select-selection--single"
          placeholder="Company Name"
          disabled={user.blocked ? true : false}
          style={{ width: 130, textAlign: 'center' }}
          onChange={(e: any) => handleUpdateUser(e, user)}
        >
          <option value="0"> {user.user_metadata.companyName}</option>
          {companyContractors.map((company: ContractorType) =>
            user.user_metadata.contractorCompanyID === company.contractorCompanyID ? (
              <option
                key={company.contractorCompanyID}
                selected
                value={company.contractorCompanyID}
              >
                {' '}
                {company.companyName}
              </option>
            ) : (
              <option key={company.contractorCompanyID} value={company.contractorCompanyID}>
                {' '}
                {company.companyName}
              </option>
            ),
          )}
        </select>
      </td>

      <td className="container">
        <select
          name="reportsToUserID"
          className="ant-select-selection ant-select-selection--single"
          placeholder="Supervisor"
          disabled={user.blocked ? true : false}
          style={{ width: 130, textAlign: 'center' }}
          onChange={(e: any) => handleUpdateUser(e, user)}
        >
          {user.reportsTo !== null && reportsToUserInfo !== undefined ? (
            <option value={user.reportsTo.user_id} selected>{`${user.reportsTo.name} ${
              reportsToUserInfo.contractorCompany
                ? ` (${reportsToUserInfo.contractorCompany.companyName})`
                : `(${reportsToUserInfo.user_metadata.companyName})`
            } ${reportsToUserInfo.blocked ? '(Inactive)' : ''}`}</option>
          ) : (
            <option value="0">Owner (Tier 1)</option>
          )}
          {team.map((reportsToUser: UserType, index: number) =>
            user.user_metadata.reportsToUserID !== reportsToUser.user_id &&
            user.user_id !== reportsToUser.user_id &&
            reportsToUser.app_metadata.role.roleID === 2 &&
            !user.blocked ? (
              <option key={index} value={reportsToUser.user_id}>
                {reportsToUser.name}
              </option>
            ) : null,
          )}

          {user.app_metadata.role.roleID !== 1 && user.reportsTo !== null ? (
            <option value="">Owner (Tier 1)</option>
          ) : null}
        </select>
      </td>
      <td className="container">
        <Switch
          disabled={user.blocked ? true : false}
          defaultChecked={user.user_metadata.canCreateProject}
          onChange={(e: any) =>
            handleUpdateUser(
              {
                target: {
                  name: 'canCreateProject',
                  value: e,
                },
              },
              user,
            )
          }
        />
      </td>

      <td className="container">
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item>
                <UserCertificateContainer
                  team={team}
                  targetUserID={user.user_id}
                  usage="team"
                  site="backoffice"
                  defaultTabOpen="1"
                />
              </Menu.Item>

              {!user.blocked ? (
                <Menu.Item>
                  <a>
                    <Popconfirm
                      placement="topRight"
                      title="Are you sure you want to resend invite?"
                      onConfirm={() => {
                        resendUserInvite(user.user_id);
                      }}
                      okText="Yes"
                    >
                      <div className="team-actions-dropdown-content">
                        <Icon type="mail" />
                        <b>Resend Invite &nbsp;</b>
                      </div>
                    </Popconfirm>
                  </a>
                </Menu.Item>
              ) : null}

              {!!user && user.app_metadata.role.roleID === 2 && (
                <Menu.Item>
                  <Link to={`/back-office/user/${user.user_id}/proxy-users`}>
                    <div className="team-actions-dropdown-content">
                      <Icon type="user-add" />
                      <b>Proxy Users</b>
                    </div>
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item>
                <TeamChangePasswordContainer user={user} />
              </Menu.Item>

              <Menu.Item>
                <a
                  onClick={e => {
                    e.preventDefault();
                    handleDeleteUser(user.user_id, user.blocked);
                  }}
                >
                  <div className="team-actions-dropdown-content">
                    <Icon style={{ color: 'red' }} type="user-delete" />
                    <b>Associate Status</b>
                    <Switch defaultChecked={!user.blocked} checked={!user.blocked} size="small" />
                  </div>
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <Icon type="more" />
          </a>
        </Dropdown>

        {modifiedUsers[user.user_id] ? (
          <Icon
            style={{ marginTop: '5px' }}
            onClick={() => handleSaveUser(user.user_id)}
            type="save"
            theme="filled"
          />
        ) : (
          <Icon style={{ display: 'none' }} type="save" theme="filled" />
        )}
      </td>
    </tr>
  );
};
