import { Button, Checkbox, Input } from 'antd'
import React from 'react'

import styles from "./styles.module.scss"
export const SearchFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, dataIndex, signaturesNames }: any) => {

    return (
        <div style={{ padding: 8 }}>
            <Input
                placeholder="Search"
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            {signaturesNames &&
                <ul className={styles.search_filter} style={{ listStyleType: "none", }}>
                    {
                        signaturesNames.map((signature: any) => (
                            <li>
                                <Checkbox
                                    checked={selectedKeys.find((key: string) => key === signature.value)}
                                    value={signature.value}
                                    onChange={(e) => {


                                        if (e.target.checked) setSelectedKeys([...selectedKeys, e.target.value])
                                        else {
                                            setSelectedKeys(selectedKeys.filter((key: string) => key !== signature.value) || [])
                                        }
                                    }}
                                >
                                    {signature.text}
                                </Checkbox>
                            </li>
                        ))
                    }
                </ul>
            }

            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                Reset
            </Button>

            <Button
                type="primary"
                onClick={() => confirm()}
                icon="search"
                size="small"
                style={{ width: 90, marginLeft: 8 }}
            >
                Search
            </Button>
        </div>
    )
}
