import React, { createRef, ChangeEvent, MouseEvent, Fragment } from 'react';

import { Sorter } from '@shared/types/utils.types';
import { ContractorType } from '@shared/types/backend.types';

import { Popconfirm } from 'antd';
import { SorterComponent } from '@components/controls/table/sorter.component';
import { EmptyRowComponent } from '@components/controls/table/empty-row.component';
import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';

import '@utils/styles/cursor.scss';
import './contractors.style.scss';

export interface IContractorsTableProps {
  companyContractors: [];
  //TODO: add types
  sorter: Sorter;
  modifiedContractors: any;
  isLoading: boolean;
  selectedAccountID?: number;
  contractorCachedCounter: number;
  handleSort: (key: any) => void;
  handleUpdateContractor: (event: ChangeEvent<HTMLInputElement>, contractor: ContractorType) => void;
  handleAddTableItem: (item: any) => void;
  handleSaveContractor: (contractorCompanyID: number | string) => void;
  handleRemoveTableItem: (key: string) => void;
  handleRemoveContractor: (contractor: ContractorType) => void;
}

export const ContractorsLockedComponent = (props: IContractorsTableProps) => {
  const { companyContractors, contractorCachedCounter, modifiedContractors, sorter, isLoading } = props;
  const {
    handleUpdateContractor,
    handleSaveContractor,
    handleRemoveTableItem,
    handleRemoveContractor,
  } = props;
  const tableRef: any = createRef();

  const isContractorValid = (contractor: ContractorType) => {
    if (!contractor) {
      return false;
    }
    return (
      Object.keys(contractor).length >= 1 &&
      contractor.companyName !== ''
    );
  };

  const renderEditableRow = () => {
    if (isLoading) {
      return (
        <td colSpan={6}>
          <div style={{ padding: 50 }}>
            <TableLoadingSpinnerComponent />
            <h4 className="table__accounts-team__loading-title">Loading Contractor Companies</h4>
          </div>
        </td>
      );
    }

    if (companyContractors.length === 0) {
      return <EmptyRowComponent colspan={6} text="No Companies Available" />;
    }

    return companyContractors.map((item: ContractorType) => {
      return (
        <tr key={item.key}>
          <td >
            <input
              name="companyName"
              defaultValue={item.companyName || ''}
              autoFocus={contractorCachedCounter > 0 ? true : false}
              placeholder={item.companyName || 'Company Name'}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleUpdateContractor(event, item)}
            />
          </td>
          <td >
            <input
              name="companyAddress"
              defaultValue={item.companyAddress || ''}
              placeholder={item.companyAddress || 'Company Address'}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleUpdateContractor(event, item)}
            />
          </td>

          <td >
            {modifiedContractors[item.contractorCompanyID] && (
              <div className="team-item__controls">
                <i
                  className="fas fa-save active-icon"
                  onClick={(event: MouseEvent) => {
                    handleSaveContractor(item.contractorCompanyID);
                  }}
                ></i>
              </div>
            )}
            {modifiedContractors[`${item.contractorCompanyID ? undefined : item.key}`] &&
              isContractorValid(modifiedContractors[`${item.key}`]) && (
                <div className="team-item__controls">
                  <i
                    className="fas fa-save active-icon "
                    onClick={(event: MouseEvent) => {
                      handleSaveContractor(item.key);
                    }}
                  ></i>
                </div>
              )}
            {item.key !== undefined &&
              !item.contractorCompanyID &&
              !isContractorValid(modifiedContractors[item.key]) && (
                <div className="team-item__controls">
                  <i
                    className="fas fa-save"
                    style={{
                      color: 'lightgrey',
                    }}
                  ></i>
                </div>
              )}
            {item.contractorCompanyID && (
              <div className="team-item__controls">
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => handleRemoveContractor(item)}
                  okText="Yes"
                >
                  <i
                    className="far fa-trash-alt active-icon"
                  ></i>
                </Popconfirm>
              </div>
            )}

            {item.key !== undefined && !item.contractorCompanyID && (
              <div className="team-item__controls">
                <Popconfirm
                  title="Are you sure you want to delete?"
                  onConfirm={() => handleRemoveTableItem(`${item.key}`)}
                  okText="Yes"
                >
                  <i
                    className="far fa-trash-alt active-icon"
                  ></i>
                </Popconfirm>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <Fragment>
      <table
        ref={tableRef}
        className="table sa-table"
      >
        <thead>
          <tr>
            <th>
              Company Name{' '}
              <SorterComponent status={sorter.key === 'companyName' ? sorter.order : 'desc'} />
            </th>
            <th>
              Company Address{' '}
              <SorterComponent status={sorter.key === 'companyAddress' ? sorter.order : 'desc'} />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderEditableRow()}</tbody>
      </table>
    </Fragment>
  );
};
