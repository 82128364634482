import React from 'react';

import { SafetyPlanType } from '@shared/types/backend.types';
import { FormInstanceSelected } from '../safety-plans';

import { QUESTION_TYPE } from '@constants/question';

import { Switch, Popconfirm, Collapse } from 'antd';
import { EmptyRowComponent } from '@components/controls/table/empty-row.component';
import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';
import { getList } from '@shared/helpers/state-caster';

import './safety-plans.styles.scss';
import './safety-plans-collapse-styles.scss';

const { Panel } = Collapse;


interface IAccountsSafetyPlansProps {
  safetyPlans: SafetyPlanType[];
  isUpdating?: number;
  questions?: any;
  questionsIsLoading?: boolean;
  isLoading: boolean;
  title: string;
  role?: string;
  //TODO: add new model to safetyPlan
  handleSwitchChecked: (isValid: boolean, form: FormInstanceSelected, safetyPlan: any) => void;
  handleSubFormSwitchChecked?: (isValid: boolean, subform: FormInstanceSelected, safetyPlan: any, formID: string) => void;
  fecthSelectedFormQuestion?: (formID: string) => void;
  handleChangeStatusPlan?: (isSelected: boolean, safetyPlan: any) => void;
  handleShowModal?: (item: any) => void;
}

const safetyPlansMocked = [
  'INSPECT - OSHA',
  'INSPECT - EM385 1-1',
  'JSA-AHA',
  'FORMS',
  'PERMITS',
  'SAFETY LESSONS',
  'OBSERVATIONS',
  'ADMINISTRATOR',
  'COMMANDER',
  'CRANES SAFETY',
  'XYZ'
];

export const AccountsSafetyPlansComponent = (props: IAccountsSafetyPlansProps) => {
  const { safetyPlans, isLoading, isUpdating, questions, questionsIsLoading, title, role } = props;
  const {
    handleSwitchChecked,
    handleSubFormSwitchChecked,
    handleChangeStatusPlan,
    fecthSelectedFormQuestion,
    handleShowModal,
  } = props;

  const renderLoadingPlans = () => (
    <Collapse bordered={false}>
      {safetyPlansMocked.map((item: any, index: number) => (
        <Panel
          header={<div className="skeleton-box skeleton-plans"></div>}
          extra={<Switch className={role === 'admin' ? 'opacity-0' : ''} size='small' disabled />}
          disabled
          key={index}
        >
        </Panel>
      ))}
    </Collapse>
  );

  const renderQuestions = () => {

    if (questionsIsLoading) {
      return (
        <td colSpan={2}>
          <div style={{ padding: 50 }}>
            <TableLoadingSpinnerComponent />
            <h4>Loading Questions</h4>
          </div>
        </td>
      )
    }
    else {

      if (questions.length !== 0) {
        return questions.map((question: any, index: number) => {
          return (
            <tr key={index}>
              <td>{question.description}</td>
              <td>{QUESTION_TYPE[question.questionTypeID]}</td>
            </tr>
          )
        })
      }
      return (
        <EmptyRowComponent colspan={2} text={'No Questions Assigned to Selected Form'} />
      )
    }
  }

  return (
    <>
      <div className='sa__box-heading'>
        <h4 className="sa__box-heading-title">{title}</h4>
      </div>

      <div className="accounts-plans__container sa__box-content">
        <div className='sa-ant-collapse' style={{ width: '35%' }}>
          {
            isLoading ? (
              renderLoadingPlans()
            ) : (
              <Collapse bordered={false}>
                {safetyPlans && safetyPlans.length > 0 && getList(safetyPlans).map((item: any, index: number) => (
                  role === 'admin' ? (
                    !item.isEnable && handleShowModal !== undefined ? (
                      <Panel
                        header={<div className='w-100' onClick={() => handleShowModal(item)}>{item.name}</div>}
                        disabled
                        key={index}>
                      </Panel>
                    )
                      : (
                        <Panel
                          header={item.name}
                          key={index}>
                          {item.forms && getList(item.forms).map((subForm: any, index: number) => (
                            subForm.subForms !== null ? (
                              <Collapse>
                                <Panel
                                  header={<div onClick={() => fecthSelectedFormQuestion !== undefined &&
                                    fecthSelectedFormQuestion(subForm.id)}>{subForm.name}</div>}
                                  extra=
                                  {
                                    <Popconfirm
                                      placement="topRight"
                                      title=
                                      {
                                        subForm.isEnable
                                          ? 'Are you sure you want to deactivate this program from this account?'
                                          : 'Are you sure you want to activate this program from this account?'
                                      }
                                      onConfirm={() => {
                                        handleSwitchChecked !== undefined && handleSwitchChecked(!subForm.isSelected, subForm, item);
                                      }}
                                      okText="Yes"
                                    >
                                      {handleSwitchChecked !== undefined && (<div><Switch checked={subForm.isSelected} size="small" /></div>)}
                                    </Popconfirm>
                                  }
                                  key={index}>

                                  {getList(subForm.subForms).map((childSubForm: any, index: number) => (
                                    <div key={index} className='subForm-container'>
                                      <p className='subForm-name'
                                        onClick={() => fecthSelectedFormQuestion !== undefined &&
                                          fecthSelectedFormQuestion(childSubForm.id)}>
                                        {childSubForm.name}
                                      </p>
                                    </div>
                                  ))}

                                </Panel>
                              </Collapse>
                            )
                              : (
                                <div key={index} className='subForm-container'>
                                  <p className='subForm-name'
                                    onClick={() => fecthSelectedFormQuestion !== undefined &&
                                      fecthSelectedFormQuestion(subForm.id)}>
                                    {subForm.name}
                                  </p>

                                  <Popconfirm
                                    placement="topRight"
                                    title=
                                    {
                                      subForm.isEnable
                                        ? 'Are you sure you want to deactivate this program from this account?'
                                        : 'Are you sure you want to activate this program from this account?'
                                    }
                                    onConfirm={() => {
                                      handleSwitchChecked !== undefined && handleSwitchChecked(!subForm.isSelected, subForm, item);
                                    }}
                                    okText="Yes"
                                  >
                                    {handleSwitchChecked !== undefined && (<div><Switch checked={subForm.isSelected} size="small" /></div>)}
                                  </Popconfirm>
                                </div>
                              )
                          ))}
                        </Panel>
                      )
                  )
                    : (
                      <Panel
                        header={item.name}
                        extra=
                        {
                          handleChangeStatusPlan !== undefined && (
                            <Popconfirm
                              disabled={!item.isEnable}
                              placement="topRight"
                              title={
                                item.isEnable
                                  ? 'Are you sure you want to deactivate this program from this account?'
                                  : 'Are you sure you want to activate this program from this account?'
                              }
                              onConfirm={() => {
                                handleChangeStatusPlan(!item.isEnable, item);
                              }}
                              okText="Yes">
                              <Switch
                                checked={item.isEnable}
                                size="small"
                                disabled={!item.isEnable}
                              />
                            </Popconfirm>
                          )
                        }
                        key={index}>
                        {item.forms && getList(item.forms).map((subForm: any, index: number) => (
                          subForm.subForms !== null ? (
                            <Collapse key={index}>
                              <Panel
                                header={<div onClick={() => fecthSelectedFormQuestion !== undefined &&
                                  fecthSelectedFormQuestion(subForm.id)}>{subForm.name}</div>}
                                extra=
                                {
                                  <Popconfirm
                                    placement="topRight"
                                    title=
                                    {
                                      subForm.isEnable
                                        ? 'Are you sure you want to deactivate this program from this account?'
                                        : 'Are you sure you want to activate this program from this account?'
                                    }
                                    onConfirm={() => {
                                      handleSwitchChecked !== undefined && handleSwitchChecked(!subForm.isSelected, subForm, item);
                                    }}
                                    okText="Yes"
                                  >
                                    {handleSwitchChecked !== undefined && (<div><Switch checked={subForm.isSelected} size="small" /></div>)}
                                  </Popconfirm>
                                }
                                key={index}>

                                {getList(subForm.subForms).map((childSubForm: any, index: number) => (
                                  <div key={index} className='subForm-container'>
                                    <p className='subForm-name'
                                      onClick={() => fecthSelectedFormQuestion !== undefined &&
                                        fecthSelectedFormQuestion(childSubForm.id)}>
                                      {childSubForm.name}
                                    </p>

                                    <Popconfirm
                                      placement="topRight"
                                      title=
                                      {
                                        childSubForm.isEnable
                                          ? 'Are you sure you want to deactivate this program from this account?'
                                          : 'Are you sure you want to activate this program from this account?'
                                      }
                                      onConfirm={() => {
                                        handleSubFormSwitchChecked !== undefined && handleSubFormSwitchChecked(!childSubForm.isSelected, childSubForm, item, subForm.id);
                                      }}
                                      okText="Yes"
                                    >
                                      {handleSubFormSwitchChecked !== undefined && (<div><Switch checked={childSubForm.isSelected} size="small" /></div>)}
                                    </Popconfirm>
                                  </div>
                                ))}

                              </Panel>
                            </Collapse>
                          )
                            : (
                              <div key={index} className='subForm-container'>
                                <p className='subForm-name'
                                  onClick={() => fecthSelectedFormQuestion !== undefined &&
                                    fecthSelectedFormQuestion(subForm.id)}>
                                  {subForm.name}
                                </p>

                                <Popconfirm
                                  placement="topRight"
                                  title=
                                  {
                                    subForm.isEnable
                                      ? 'Are you sure you want to deactivate this program from this account?'
                                      : 'Are you sure you want to activate this program from this account?'
                                  }
                                  onConfirm={() => {
                                    handleSwitchChecked !== undefined && handleSwitchChecked(!subForm.isSelected, subForm, item);
                                  }}
                                  okText="Yes"
                                >
                                  {handleSwitchChecked !== undefined && (<div><Switch checked={subForm.isSelected} size="small" /></div>)}
                                </Popconfirm>

                              </div>
                            )
                        ))}
                      </Panel>)
                ))}
              </Collapse>
            )}

        </div>
        <div className='accounts-plans__questions'>
          <div className='sa-table-scroll'>
            <table className="sa-table sa-table-textLeft m-0">
              <thead>
                <tr >
                  <th>Question</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody >{renderQuestions()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
