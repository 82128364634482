import React, { ReactNode } from 'react';

import './dialog.styles.scss';
import { Icon } from 'antd';

export interface IModalComponentProps {
    title: React.Component | string;
    open: boolean;
    children: ReactNode;
    loading?: boolean;
    setShow: (show: boolean) => void;
}

const SADialog = (props: IModalComponentProps) => {
    const { title, open, children } = props;
    const { setShow } = props;

    return (
        <div className={open ? 'dialog-display' : 'dialog-hide'}>
            <div className="dialog__container">
                <div className="dialog-header" onClick={() => setShow(false)}>
                    <div className='dialog-header__title'>{title}</div>
                    <Icon className='dialog-header__close-icon' type='close' />
                </div>
                <div className="dialog-body">{children}</div>
                <section className="dialog-footer"></section>
            </div>
        </div >
    );
};

export default SADialog;