import React from 'react';
import { CURRENT_ENV } from '@services/axios/axios.config';
import './env-indicator.scss';
import { Icon } from 'antd';

export interface IEnvIndicatorProps {}

export const EnvIndicator = (props: IEnvIndicatorProps) => (
  <div className="envindicator-container" style={{}}>
    <div className="env-item">
      <Icon
        type="thunderbolt"
        style={{
          fontSize: 15,
          padding: 10,
        }}
      />
      <span>{CURRENT_ENV}</span>
    </div>
  </div>
);
