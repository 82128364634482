import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

/**
 * @description Generate a HTTP GET request
 * @param {string} endpoint string of endpoint url
 * @param {any} queryParams object of query params
 * @returns data from api
 */

export async function fetchService(endpoint: string, queryParams?: any) {
  const authKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  const { data }: AxiosResponse = await axios.get(endpoint, {
    headers: {
      Authorization: authKey,
    },
  });

  return data;
}

/**
 * @description - Generate HTTP POST Request
 * @param endpoint string of endpoint url
 * @param body object of body
 * @returns data from api
 */

export async function addService(endpoint: string, body?: any) {
  const authKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  const { data }: AxiosResponse = await axios.post(endpoint, body, {
    headers: {
      Authorization: authKey,
    },
  });
  return data;
}

/**
 * @description - Generate HTTP PUT Request
 * @param endpoint string of endpoint url
 * @param body object of body
 * @returns data from api
 */

export async function updateService(endpoint: string, body: any) {
  const authKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  const { data }: AxiosResponse = await axios.put(endpoint, {
    headers: {
      Authorization: authKey,
    },
    body,
  });

  return data;
}

/**
 * @description - Generate HTTP PATCH Request
 * @param endpoint string of endpoint url
 * @param body object of body
 * @returns data from api
 */

export async function patchService(endpoint: string, body: any) {
  const authKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  const { data }: AxiosResponse = await axios.patch(endpoint, body, {
    headers: {
      Authorization: authKey,
    },
  });

  return data;
}

/**
 * @description - Generate HTTP DELETE Request
 * @param endpoint string of endpoint url
 * @returns data from api
 */

export async function deleteService(endpoint: string) {
  const authKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  const { data }: AxiosResponse = await axios.delete(endpoint, {
    headers: {
      Authorization: authKey,
    },
  });

  return data;
}
