import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { Submission } from '@shared/types/backend.types';

import {
  fetchProjectFormsAction,
  fetchSPProjectAction,
  changeSelectedProjectAction,
  fetchFormPDFAction,
  getSelectedFormPDFAction,
} from './submissions.actions';

import { arrayToObject } from '@shared/helpers/state-caster';
import { fetchService } from '@rdx/services.saga';

import axios, { AxiosResponse } from 'axios';

import { fetchAccountSubmissionsAction } from '@app/admin/submissions/state/submissions.actions';

function* fetchProjectFormsRequest(action: AnyAction) {
  const { accountID, projectID } = action.payload;
  try {
    const endpoint = projectID ? fetchProjectFormsAction.getEndpoint({ accountID, projectID }) : fetchAccountSubmissionsAction.getEndpoint({ accountID });
    const response = yield call(fetchService, endpoint);

    if (response && response.length > 0) {
      // filtered by project

      const submissions = arrayToObject(response, 'id');
      yield put(fetchProjectFormsAction.success({ submissions }));
    } else {
      yield put(fetchProjectFormsAction.success({ submissions: {} }));
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchProjectFormsAction.failure({
          code: 'backOfficeProjectForms',
          title: 'Failed to retrieve forms',
          message: 'There has been an error while retrieving forms.',
        }),
      );
    }
  }
}

function* fetchSPProjectsRequest(action: AnyAction) {
  try {
    const endpoint = fetchSPProjectAction.getEndpoint({ accountID: action.payload });
    const response = yield call(fetchService, endpoint);

    if (response.length > 0) {
      yield put(fetchSPProjectAction.success({ projects: response, id: response[0].projectID }));
    } else {
      yield put(fetchSPProjectAction.success({ projects: [], id: undefined }));
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchSPProjectAction.failure({
          code: 'backOfficeProject',
          title: 'Failed to retrieve projects',
          message: 'There has been an error while retrieving projects.',
        }),
      );
    }
  }
}

function* getPdfService(endpoint: string) {
  const { data }: AxiosResponse = yield axios(endpoint, {
    method: 'GET',
    responseType: 'blob', //Force to receive data in a Blob Format
  });
  const file = new Blob([data], { type: 'application/pdf' });
  return URL.createObjectURL(file);
}

function* fetchFormPDFRequest(action: AnyAction) {
  try {
    const endpoint = fetchFormPDFAction.getEndpoint({
      submissionID: action.payload.submissionID,
    });
    const response = yield call(fetchService, endpoint);
    const pdfFileResponse = yield call(getPdfService, response.pdfLocation);

    if (pdfFileResponse.length > 0) {
      yield put(fetchFormPDFAction.success(pdfFileResponse));
    } else {
      yield put(fetchFormPDFAction.success(''));
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchSPProjectAction.failure({
          code: 'backOfficePDF',
          title: 'Failed to retrieve PDF report',
          message: 'There has been an error while generating pdf.',
        }),
      );
    }
  }
}

function* getSelectedFormPDFActionRequest(action: AnyAction) {
  yield put(getSelectedFormPDFAction.fulfill(action.payload));
}

function* changeSelectedProjectTrigger(action: AnyAction) {
  yield put(changeSelectedProjectAction.fulfill(+action.payload));
}

export function* projectFormsSaga() {
  yield takeLatest(fetchProjectFormsAction.REQUEST, fetchProjectFormsRequest);
  yield takeLatest(fetchFormPDFAction.REQUEST, fetchFormPDFRequest);
  yield takeLatest(fetchSPProjectAction.REQUEST, fetchSPProjectsRequest);
  yield takeLatest(changeSelectedProjectAction.TRIGGER, changeSelectedProjectTrigger);
  yield takeLatest(getSelectedFormPDFAction.TRIGGER, getSelectedFormPDFActionRequest);
}
