import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';

import _ from 'lodash';

export const _getTeamSorter = (state: IAppState) => state.professional.teamSorter;

export const getTeamSorter = createSelector([_getTeamSorter], sorter => sorter);

export const _getTeam = (state: IAppState) => state.professional.team.items;

export const _getUserTeam = (state: IAppState) => state.professional.team.userTeam;

export const _getManagers = (state: IAppState) => state.professional.managers;

export const _getTeamAccessUserID = (state: IAppState) => state.session.teamAccessUserID;

//TODO: add types
export const getTeam = createSelector(_getTeam, team => getList(team));

export const getUserTeam = createSelector(_getUserTeam, team => team);

export const getManagers = createSelector(_getManagers, managers => managers);

export const getTeamAccessUserID = createSelector(_getTeamAccessUserID, id => id);

export const _getTeamDictionary = (state: IAppState) => state.professional.team.items;

export const getTeamDictionary = createSelector([_getTeamDictionary], team => team);

export const _getTeamIsLoading = (state: IAppState) => state.professional.team.isLoading;

export const getTeamIsLoading = createSelector([_getTeamIsLoading], isLoading => isLoading);

export const _getTeamIsSendingInvite = (state: IAppState) =>
  state.professional.team.isSendingInvite;

export const getTeamIsSendingInvite = createSelector(
  [_getTeamIsSendingInvite],
  isSendingInvite => isSendingInvite,
);
