import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as ReduxProvider } from 'react-redux';
import { store } from '@rdx/store.config';

import { AppContainer } from '@app/app.container';

import 'antd/dist/antd.css';
import 'global.styles.css';
import '@shared/utils/styles/global.style.scss'

//Utility Classes
import '@utils/styles/width.css';
import '@utils/styles/utility.scss';
import '@utils/styles/sa.tables.scss';
import '@utils/styles/ant.modal.styles.scss'
import '@utils/styles/sa.tree.styles.scss'
import '@utils/styles/sa.variables.scss';




ReactDOM.render(
  <ReduxProvider store={store}>
    <AppContainer />
  </ReduxProvider>,
  document.getElementById('root'),
);
