import { CATicketType, CorrectiveActionsType } from "@shared/types/backend.types";
import update from 'immutability-helper';
import { fetchAccountCA } from "./corrective-actions.actions";
import { IProfessionalState } from "@app/back-office/professional.reducer";
import { combineActions } from 'redux-actions';

import { AnyAction } from "redux";
import { IAdminState } from "@app/admin/admin.reducer";


export interface ICorrectiveActionsState {
    items: CorrectiveActionsType[];
    isLoading: boolean;
    errMsg?: string;
}

export const CORRECTIVE_ACTIONS_INITIAL_STATE = {
    items: [],
    isLoading: true,
    errMsg: undefined
};

export const correctiveActionsReducer = {

    // COMBINE REQUEST REDUCERS
    [combineActions(fetchAccountCA.REQUEST) as any]:
        (state: IAdminState) => {
            return update(state, {
                correctiveActions: {
                    isLoading: { $set: true },
                }
            })
        },

    // SUCCESS REDUCERS
    [fetchAccountCA.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                items: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    // FULLFILL REDUCERS
    [fetchAccountCA.FULFILL]: (state: IAdminState) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    // FAILURE REDUCERS
    [fetchAccountCA.FAILURE]: (state: IAdminState) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Actions' },
            }
        })
    },
};