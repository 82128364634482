import { takeLatest, put, call, all, fork } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { addService, cancelService, fetchService, patchService } from '@rdx/services.saga';

import {
  submitSafetyTeamAction,
  fetchIndustriesAction,
  configAddFormsAction,
  configAddProjectsAction,
  updateAccountConfigurationAction,
} from './programs.actions';

import { setupCreationSaga } from '../creation/state/creation.saga';
import { setupProjectsSaga } from '../projects/state/project.saga';
import { setupPlansSaga } from '../plans/state/plans.saga';

function* fetchIndustriesRequest() {
  const endpoint = fetchIndustriesAction.getEndpoint({});
  try {
    const data = yield call(fetchService, endpoint);

    yield put(fetchIndustriesAction.success({ industries: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchIndustriesAction.failure({
          error: {
            code: 'fetchProgramIndustry',
            title: 'Failed to retrieve industries.',
            message: 'There has been an error while retrieving industries.',
          },
        }),
      );
    }
  }
}

function* configAddFormsRequest(action: AnyAction) {
  const { forms, accountID } = action.payload;

  const selectedForms: { safetyPlanID: number; formID: string }[] = [];

  Object.keys(forms).forEach(form => {
    forms[form].forEach((id: string) => {
      selectedForms.push({
        safetyPlanID: Number(form),
        formID: id,
      });
    });
  });

  try {
    const endpoint = configAddFormsAction.getEndpoint({ accountID });

    const response = yield call(addService, endpoint, selectedForms);

    if (!response[0].succeeded) {
      yield put(
        configAddFormsAction.failure({
          error: {
            code: 'configBackOfficeAddPlansForms',
            title: 'Failed to add plans forms',
            message: response[0].result,
          },
        }),
      );
    } else {
      yield put(configAddFormsAction.success({}));
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        configAddFormsAction.failure({
          error: {
            code: 'configBackOfficeAddPlansForms',
            title: 'Failed to add plans forms',
            message: 'theres been an error with add back-office plan forms',
          },
        }),
      );
    }
  }
}

function* configAddProjectsRequest(action: AnyAction) {
  const { projects, accountID } = action.payload;
  const updateAccount = updateAccountConfigurationAction.getEndpoint({ accountID });

  try {
    const endpoint = configAddProjectsAction.getEndpoint({ accountID });
    const response = yield call(addService, endpoint, projects);

    if (response[0].succeeded) {
      const responsePatch = yield call(patchService, updateAccount, { isConfigurate: true });
      if (responsePatch.isConfigurate) {
        yield put(updateAccountConfigurationAction.success({}));
      }
    }

  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        configAddProjectsAction.failure({
          error: {
            code: 'configBackOfficeAddTeam',
            title: 'Failed add projects',
            message: 'There has been an error while adding projects.',
          },
        }),
      );
    }
  }
}

function* submitSafetyTeamRequest(action: AnyAction) {
  const { team, accountID } = action.payload;

  let endpoint = submitSafetyTeamAction.getEndpoint({ accountID });

  try {
    yield call(addService, endpoint, team);
    yield put(submitSafetyTeamAction.success({}));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        submitSafetyTeamAction.failure({
          error: {
            code: 'submitProgramsSetup',
            title: 'Failed to configurate your company.',
            message: 'There has been an error while setting up the company.',
          },
        }),
      );
    } else {
      yield put(submitSafetyTeamAction.failure(undefined));
    }
  }
}

export function* programsSaga() {
  yield takeLatest(submitSafetyTeamAction.REQUEST, submitSafetyTeamRequest);
  yield takeLatest(fetchIndustriesAction.REQUEST, fetchIndustriesRequest);
  yield takeLatest(fetchIndustriesAction.FAILURE, cancelService);
  yield takeLatest(configAddFormsAction.REQUEST, configAddFormsRequest);
  yield takeLatest(configAddProjectsAction.REQUEST, configAddProjectsRequest);
  yield all([
    fork(setupCreationSaga),
    fork(setupProjectsSaga),
    fork(setupPlansSaga),
  ]);
}
