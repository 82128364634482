import { notification } from 'antd';

//@ts-ignore
export const openNotificationWithIcon = (type, message, description, onClose) => {
  //@ts-ignore
  notification[type]({
    message,
    description,
    onClose,
  });
};
