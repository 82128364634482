import React from 'react';

export const AddQuestionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 32 32"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M 16 4 C 11.671875 4 8 7.054688 8 11 L 8 14 L 14 14 L 14 11 C 14 10.851563 14.089844 10.636719 14.4375 10.40625 C 14.785156 10.175781 15.351563 10 16 10 C 16.652344 10 17.21875 10.175781 17.5625 10.40625 C 17.90625 10.636719 18 10.835938 18 11 C 18 11.578125 17.8125 11.980469 17.4375 12.4375 C 17.0625 12.894531 16.480469 13.367188 15.84375 13.90625 C 14.566406 14.988281 13 16.496094 13 19 L 13 20 L 19 20 L 19 19 C 19 18.660156 19.125 18.421875 19.5 18.03125 C 19.875 17.640625 20.496094 17.179688 21.15625 16.625 C 22.476563 15.515625 24 13.816406 24 11 C 24 7.089844 20.335938 4 16 4 Z M 16 6 C 19.394531 6 22 8.367188 22 11 C 22 13.144531 21.023438 14.101563 19.84375 15.09375 C 19.253906 15.589844 18.625 16.074219 18.0625 16.65625 C 17.714844 17.015625 17.445313 17.484375 17.25 18 L 15.3125 18 C 15.625 16.988281 16.234375 16.21875 17.15625 15.4375 C 17.769531 14.921875 18.4375 14.382813 19 13.6875 C 19.5625 12.992188 20 12.082031 20 11 C 20 10.039063 19.429688 9.242188 18.6875 8.75 C 17.945313 8.257813 17.003906 8 16 8 C 14.992188 8 14.050781 8.257813 13.3125 8.75 C 12.574219 9.242188 12 10.042969 12 11 L 12 12 L 10 12 L 10 11 C 10 8.316406 12.597656 6 16 6 Z M 13 22 L 13 28 L 19 28 L 19 22 Z M 15 24 L 17 24 L 17 26 L 15 26 Z"></path>
    </svg>
  );
};

export const PowerBiIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 32 32"
      version="1.1"
      {...props}
    >
      <path d="M13.501 16h-7.498c0 0-0 0-0 0-0.69 0-1.25 0.559-1.25 1.25 0 0 0 0 0 0v0 12.496c0 0 0 0 0 0 0 0.69 0.559 1.25 1.25 1.25 0 0 0 0 0 0h8.747v-13.746c0-0.69-0.559-1.25-1.25-1.25v0zM19.749 8.502h-7.498c0 0-0 0-0 0-0.69 0-1.25 0.559-1.25 1.25 0 0 0 0 0 0v0 5.623h2.499c1.035 0.001 1.873 0.84 1.874 1.874v13.746h5.623v-21.244c0-0.69-0.559-1.25-1.25-1.25v0zM27.247 2.254v27.492c0 0 0 0 0 0 0 0.69-0.559 1.25-1.25 1.25 0 0-0 0-0 0h-4.374v-21.244c-0.001-1.035-0.84-1.873-1.874-1.874h-2.499v-5.623c0-0 0-0 0-0 0-0.69 0.559-1.25 1.25-1.25h7.498c0.69 0 1.25 0.559 1.25 1.25 0 0 0 0 0 0v0z" />
    </svg>
  );
};

export const ReportsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M3 3v18h18" />
      <rect width="4" height="7" x="7" y="10" rx="1" />
      <rect width="4" height="12" x="15" y="5" rx="1" />
    </svg>
  );
};
