import { UserType } from "@shared/types/backend.types";

export function getUnderUsers(data: UserType[], loggedUserID: string): any {
    let underUsers: UserType[] = []

    if (data.length === 0)
        return underUsers

    const traverseChildren = (data: UserType[], loggedUserID: string) => {

        let children: UserType[] = [];

        if (loggedUserID !== null) {
            children = data.filter((item: UserType) => item.user_metadata.reportsToUserID === loggedUserID);
        }

        if (children.length === 0) {
            return null;
        }

        children.map((item) => {
            underUsers.push(item);
            return traverseChildren(data, item.user_id);
        })
    }

    underUsers.push(data.filter((user) => user.user_id === loggedUserID)[0]);
    traverseChildren(data, loggedUserID);
    return underUsers;
}









