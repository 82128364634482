import update from 'immutability-helper';

import { Submission } from '@shared/types/backend.types';
import { IAdminState } from '@app/admin/admin.reducer';
import { AnyAction } from 'redux';

import { fetchAccountSubmissionsAction } from './submissions.actions';

export interface IAccountSubmissionsState {
  items: {
    [key: string]: Submission;
  };
  isLoading: boolean;
  isLoadingPDF: boolean;
  selectedProjectID?: number;
  selectedFormID?: number;
  formPDF: string | undefined;
}

export const ACCOUNTS_SUBMISSIONS_INITIAL_STATE = {
  items: {},
  isLoading: true,
  isLoadingPDF: true,
  selectedProjectID: undefined,
  selectedFormID: undefined,
  formPDF: undefined,
};

export const accountsSubmissionsReducer = {
  [fetchAccountSubmissionsAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      projectSubmissions: {
        isLoading: { $set: true },
      },
    }),
  [fetchAccountSubmissionsAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        items: { $set: payload.submissions },
        isLoading: { $set: false },
      },
    }),
  [fetchAccountSubmissionsAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      projectSubmissions: {
        items: { $set: {} },
        isLoading: { $set: false },
      },
    }),
};
