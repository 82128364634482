
import { takeLatest, put, call, all, fork } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { createPtpAction, createPtpTaskAction, deletePtpAction, deletePtpTaskAction, fetchPtpAction, fetchPtpTaskAction, updatePtpAction, updatePtpTaskAction } from "./pre-task-plan.actions";
import { addService, deleteService, fetchService, updateService } from '@rdx/services.saga';
import { BackendResponse, PTP, PTPTask } from '@shared/types/backend.types';
import { ptpSubmissionSaga } from '../reports/state/ptp-submissions.saga';

function* fetchPreTaskPlanRequest({ payload }: AnyAction) {

    try {

        const endpoint = fetchPtpAction.getEndpoint();

        const response: BackendResponse<PTP[]> = yield call(fetchService, endpoint, payload.query);

        const { data } = response;

        if (data) yield put(fetchPtpAction.success(data));

    } catch (error) {
        yield put(fetchPtpAction.failure({}));
    }
}

function* createPreTaskPlanRequest({ payload }: AnyAction) {
    try {

        const { body, clearForm } = payload;

        const endpoint = createPtpAction.getEndpoint();

        const response: BackendResponse<PTP> = yield call(addService, endpoint, body);

        const { data } = response;

        if (data) {
            yield put(createPtpAction.success(data));
            clearForm && clearForm()
        }


    } catch (error) {
        yield put(createPtpAction.failure({}));
    }
}
function* updatePreTaskPlanRequest({ payload }: AnyAction) {

    const { clearForm } = payload;

    try {
        const endpoint = updatePtpAction.getEndpoint({ ptpId: payload.params.ptpId });

        const response: BackendResponse<PTP> = yield call(updateService, endpoint, payload.body);

        const { data } = response;

        if (data) {
            yield put(updatePtpAction.success(data));
            clearForm && clearForm(false)
        }

    } catch (error) {
        yield put(updatePtpAction.failure({}));
    }
}
function* deletePreTaskPlanRequest({ payload }: AnyAction) {

    const { ptpId } = payload.params;

    try {
        const endpoint = deletePtpAction.getEndpoint({ ptpId });

        const response: BackendResponse<PTP> = yield call(deleteService, endpoint, payload.body);

        const { data } = response;

        if (data) yield put(deletePtpAction.success(ptpId));

    } catch (error) {
        yield put(deletePtpAction.failure({}));
    }
}

function* fetchPtpTaskRequest({ payload }: AnyAction) {

    try {
        const endpoint = fetchPtpTaskAction.getEndpoint({ ptpTaskId: payload.params.ptpId });
        const response: BackendResponse<PTPTask[]> = yield call(fetchService, endpoint);

        const { data } = response;

        if (data) yield put(fetchPtpTaskAction.success(data));

    } catch (error) {
        yield put(fetchPtpAction.failure({}));
    }
}

function* createPtpTaskRequest({ payload }: AnyAction) {

    try {

        const { body, clearForm } = payload;

        const endpoint = createPtpTaskAction.getEndpoint();
        const response: BackendResponse<PTPTask> = yield call(addService, endpoint, body);

        const { data } = response;

        if (data) {
            yield put(createPtpTaskAction.success(data));
            clearForm && clearForm()

        }

    } catch (error) {
        yield put(createPtpTaskAction.failure({}));
    }
}

function* updatePtpTaskRequest({ payload }: AnyAction) {

    try {

        const { body, clearForm } = payload;

        const endpoint = updatePtpTaskAction.getEndpoint({ ptpTaskId: payload.params.ptpTaskId });
        const response: BackendResponse<PTPTask> = yield call(updateService, endpoint, body);

        const { data } = response;

        if (data) {
            yield put(updatePtpTaskAction.success(data));
            clearForm && clearForm()
        }


    } catch (error) {
        yield put(updatePtpTaskAction.failure({}));
    }
}

function* deletePtpTaskRequest({ payload }: AnyAction) {

    try {

        const { ptpTaskId } = payload.params;

        const endpoint = deletePtpTaskAction.getEndpoint({ ptpTaskId });
        const response: BackendResponse<PTPTask> = yield call(deleteService, endpoint);

        const { data } = response;

        if (data) yield put(deletePtpTaskAction.success(ptpTaskId));

    } catch (error) {
        yield put(deletePtpTaskAction.failure({}));
    }
}


export function* preTaskPlanSaga() {
    yield takeLatest(fetchPtpAction.REQUEST, fetchPreTaskPlanRequest);
    yield takeLatest(createPtpAction.REQUEST, createPreTaskPlanRequest);
    yield takeLatest(updatePtpAction.REQUEST, updatePreTaskPlanRequest);
    yield takeLatest(deletePtpAction.REQUEST, deletePreTaskPlanRequest);
    yield takeLatest(fetchPtpTaskAction.REQUEST, fetchPtpTaskRequest);
    yield takeLatest(createPtpTaskAction.REQUEST, createPtpTaskRequest);
    yield takeLatest(updatePtpTaskAction.REQUEST, updatePtpTaskRequest);
    yield takeLatest(deletePtpTaskAction.REQUEST, deletePtpTaskRequest);
    yield all([fork(ptpSubmissionSaga)]);
}
