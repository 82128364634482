import React, { useEffect } from 'react';

import { SafetyPlanType } from '@shared/types/backend.types';
import { IAppState } from '@rdx/root.reducer';
import { ChangeCurrentStepPayload } from '../plans';
import { ValidateStepPayload } from '../../programs';

import { connect } from 'react-redux';
import { fetchPlansAction, changeFormSubstepAction } from '@app/back-office/professional.action';
import {
  fetchFormsAction,
  changeCurrentPlanStepAction,
  validatePlansStepAction,
} from './plans.actions';

import { ProfessionalPlansComponent } from '../view/plans.component';

import {
  getProgramsForms,
  getPlans,
  getCurrentSubstep,
} from '@app/back-office/professional.selector';
import { validatePlans, minValidLength } from '../../programs.validations';
import { getCurrentStep } from '@components/layout/layout.selector';

export interface IPlansContainerProps {
  //TODO: add types
  plans: SafetyPlanType[];
  plansForm: any;
  currentStep: number;
  currentSubstep: number;
  stepTracker: {
    [key: string]: any;
  };
  fetchFormsAction: (accountID: number) => void;
  fetchPlansAction: (params: any) => void;
  changeFormSubstepsAction: () => void;
  changeCurrentPlanStepAction: (payload: ChangeCurrentStepPayload) => void;
  validatePlansStepAction: (payload: ValidateStepPayload) => void;
}

//TODO: add types
const Plans = (props: any) => {
  const { plans, plansForm, currentStep, currentSubstep, stepTracker } = props;
  const {
    fetchPlansAction,
    fetchFormsAction,
    changeFormSubstepsAction,
    changeCurrentPlanStepAction,
    validatePlansStepAction,
  } = props;

  useEffect(() => {
    fetchPlansAction(1);
  }, [fetchPlansAction]);

  useEffect(() => {
    fetchFormsAction(currentSubstep);
  }, [currentSubstep, fetchFormsAction]);

  // Handles validation and plan changes.
  useEffect(() => {
    //step number depends on added screens on stepper process
    if (currentStep === 0 && plansForm) {
      const isValid = validatePlans(plansForm, minValidLength);
      validatePlansStepAction({ isValid, currentStep });
    }
  }, [plansForm, currentStep, validatePlansStepAction]);

  const handleValidateStep = (step: number, alternativeStatus: string, values?: number[]) => {
    var nextStatus = alternativeStatus;

    if (
      (values && values.length > 0) ||
      (!values && plansForm[step] && plansForm[step].length > 0)
    ) {
      nextStatus = 'completed';
    }

    changeCurrentPlanStepAction({ planID: step, status: nextStatus });
  };

  const handleChangeCurrentPlan = (nextStep: number): void => {
    //@ts-ignore
    if (currentSubstep !== nextStep) {
      handleValidateStep(currentSubstep, 'wait');
    }

    handleValidateStep(nextStep, 'process');
    changeFormSubstepsAction(nextStep);
  };

  return (
    <ProfessionalPlansComponent
      plans={plans}
      stepTracker={stepTracker}
      currentSubstep={currentSubstep}
      handleChangeCurrentPlan={handleChangeCurrentPlan}
      handleValidateStep={handleValidateStep}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  plans: getPlans(state),
  plansForm: getProgramsForms(state),
  stepTracker: state.professional.programs.stepTracker,
  currentStep: getCurrentStep(state),
  currentSubstep: getCurrentSubstep(state),
});

const mapDispatchToProps = {
  fetchPlansAction: fetchPlansAction.request,
  fetchFormsAction: fetchFormsAction.request,
  changeFormSubstepsAction: changeFormSubstepAction.trigger,
  changeCurrentPlanStepAction: changeCurrentPlanStepAction.trigger,
  validatePlansStepAction: validatePlansStepAction.trigger,
};

export const ProfessionalPlansContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Plans);
