import React, { FormEvent, useState, SetStateAction, useEffect } from 'react';

import { IAppState } from '@rdx/root.reducer';

import { connect } from 'react-redux';
import { submitHelpFormAction } from './help-form.action';

import { WrappedFormUtils } from 'antd/es/form/Form';
import { ErrorMessage } from '@shared/types/utils.types';

import { HelpFormComponent } from '../view/help-form.component';

import Swal from 'sweetalert2';

import { baseTheme } from '@shared/themes/presets/base.theme';

export interface IHelpFormContainerProps {
  show: boolean;
  isFormSubmitting: boolean;
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
  setShowHelpModal: (show: boolean) => void;
  submitHelpFormAction: (form: any) => void;
  handleCloseHelpForm: () => void;
}

export const HelpForm = (props: IHelpFormContainerProps) => {
  const { show, isFormSubmitting, errorMessages } = props;
  const { submitHelpFormAction, handleCloseHelpForm, setShowHelpModal } = props;

  const [hasSentForm, setHasSentForm] = useState<SetStateAction<Boolean | null>>(null);

  useEffect(() => {
    if (hasSentForm && !isFormSubmitting) {
      if (errorMessages.helpFormSubmit) {
        Swal.fire({
          title: 'Ups', // intlProvider.messages['modal.error.ups'].toString(),
          text: errorMessages.helpFormSubmit.message,
          confirmButtonColor: baseTheme.red.error,
        }).then(() => {
          setHasSentForm(null);
          setShowHelpModal(false);
        });
      } else {
        Swal.fire({
          title: 'Message Sent',
          text:
            'Thank you for submitting your inquiry. Someone from our team will reach out to you soon.',
          confirmButtonColor: baseTheme.green.aside,
        }).then(() => {
          setHasSentForm(null);
          setShowHelpModal(false);
        });
      }
    }
  }, [hasSentForm, errorMessages, isFormSubmitting, setShowHelpModal]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils): void => {
    event.preventDefault();

    form.validateFieldsAndScroll((
      err: string,
      formValues: any /*TODO: implement INeedHelpForm */,
    ) => {
      if (!err) {
        submitHelpFormAction({ formValues });

        setHasSentForm(true);
      }
    });
  };

  return (
    <HelpFormComponent
      isFormSubmitting={isFormSubmitting}
      show={show}
      handleCloseHelpForm={handleCloseHelpForm}
      handleSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  isFormSubmitting: state.layout.isHelpFormSubmitting,
  errorMessages: state.layout.errorMessages,
});

const mapDispatchToProps = {
  submitHelpFormAction: submitHelpFormAction.request,
};

export const HelpFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpForm);
