// REACT ESSENTIALS
import React, { FormEvent, useState } from 'react';

// HOOKS
import { useDispatch } from 'react-redux';

// ANTD COMPONENTS
import { Form, Input, Button, Modal, Icon, message, Select, Radio } from 'antd';

// TYPES
import { UserType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';

// HELPERS
import { createProxyUserAction, fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { addService } from '@shared/utils/http.utils';

import './proxy-user.modal.styles.scss';
import Swal from 'sweetalert2';
import lockIcon from '@assets/icon/lock-icon.svg';
import { handleValidateToNextPassword } from '@shared/utils/password-validation';

interface ICreateCAProps {
  user: UserType;
  accountsTeam: UserType[];
  openModal: boolean;
  isLoading: boolean;
  form: WrappedFormUtils;
  toggleModal: (value: React.SetStateAction<boolean>) => void;
}

export const CreateProxyUser = (props: ICreateCAProps) => {
  // HOOKS INSTANCE
  const dispatch = useDispatch();

  // PROPS
  const { accountsTeam, user, openModal, isLoading, form, toggleModal } = props;
  const { getFieldDecorator } = form;

  // LOCAL STATES
  const [inviteType, setInviteType] = useState('send-invite');
  const [screenView, setScreenView] = useState<boolean>(false);

  const onSubmit = async (userID: string, values: any) => {
    try {
      const endpoint = createProxyUserAction.getEndpoint({ userID });
      dispatch(createProxyUserAction.request());
      const { data } = await addService(endpoint, {
        ...values,
        name: `${values.firstName} ${values.lastName}`,
      });
      dispatch(createProxyUserAction.success(data));
      dispatch(fetchAccountUsersAction.request(user.user_metadata.accountID))


      form.resetFields();
      Swal.fire({
        title: 'SUCCESS',
        text: 'proxy user has been created successfully',
        type: 'success',
        confirmButtonColor: '#0B734F',
      }).then(() => (form.resetFields(), toggleModal(false)));
    } catch (error) {
      const { response }: any = error;
      dispatch(createProxyUserAction.failure());
      Swal.fire({
        title: 'ERROR!',
        text: response.data.msg,
        type: 'error',
        confirmButtonColor: '#c0392b',
      });
    }
  };

  return (
    <Modal
      title="Add Proxy User"
      className="add-proxy-user form-modal"
      visible={openModal}
      onCancel={() => toggleModal(false)}
      footer={null}
    >
      <Form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          form.validateFields((err, values) => {
            if (!user || err) return;
            onSubmit(user.user_id, values);
          });
        }}
      >
        <Radio.Group style={{ margin: '10px 0px' }} name="screen-view" defaultValue={screenView}>
          <Radio value={false} onChange={({ target: { value } }) => setScreenView(value)}>
            Add existing user
          </Radio>
          <Radio value={true} onChange={({ target: { value } }) => setScreenView(value)}>
            Add new user
          </Radio>
        </Radio.Group>

        {!screenView ? (
          <Form.Item label={<b>Select User</b>} style={{ marginBottom: 15 }}>
            {getFieldDecorator('existingUserAuth0ID', {
              rules: [
                {
                  required: true,
                  message: 'Please, select user',
                },
              ],
            })(
              <Select className="sa-select" placeholder="Select User">
                <Select.OptGroup label="Select User">
                  {accountsTeam.map(
                    (associate, index) =>
                      associate.app_metadata.role.roleID === 2 &&
                      user.user_id !== associate.user_id && (
                        <Select.Option key={index} value={associate.user_id}>
                          {associate.name}
                        </Select.Option>
                      ),
                  )}
                </Select.OptGroup>
              </Select>,
            )}
          </Form.Item>
        ) : (
          <>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                gap: '0.5rem',
              }}
            >
              <Form.Item label={<b>First Name</b>} style={{ marginBottom: 15 }}>
                {getFieldDecorator('firstName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please, enter first name',
                    },
                  ],
                })(<Input placeholder="First Name" className="sa-input" />)}
              </Form.Item>

              <Form.Item label={<b>Last Name</b>} style={{ marginBottom: 15 }}>
                {getFieldDecorator('lastName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please, enter last name',
                    },
                  ],
                })(<Input placeholder="Last Name" className="sa-input" />)}
              </Form.Item>
            </div>

            <Form.Item label={<b>Email</b>} style={{ marginBottom: 15 }}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    required: true,
                    message: 'Please, enter user email address',
                  },
                ],
              })(<Input placeholder="Email" className="sa-input" />)}
            </Form.Item>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                gap: '0.5rem',
              }}
            >
              <Form.Item label={<b>Job Title</b>} style={{ marginBottom: 15 }}>
                {getFieldDecorator('jobTitle', {
                  initialValue: user && user.user_metadata.jobTitle,
                  rules: [
                    {
                      type: 'string',
                      required: false,
                    },
                  ],
                })(<Input placeholder="Job Title" disabled={true} className="sa-input" />)}
              </Form.Item>

              <Form.Item label={<b>Licence Type</b>} style={{ marginBottom: 15 }}>
                {getFieldDecorator('package', {
                  initialValue:
                    user && user.user_metadata.package ? user.user_metadata.package : 'N/A',
                  rules: [
                    {
                      type: 'string',
                      required: false,
                    },
                  ],
                })(<Input placeholder="License Type" disabled={true} className="sa-input" />)}
              </Form.Item>
            </div>

            {user && (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  gap: '0.5rem',
                }}
              >
                <Form.Item label={<b>Company</b>} style={{ marginBottom: 15 }}>
                  {getFieldDecorator('contractorCompanyID', {
                    initialValue: user.user_metadata.contractorCompanyID
                      ? user.user_metadata.contractorCompanyID
                      : user.user_metadata.accountID,
                    rules: [
                      {
                        type: 'number',
                        required: false,
                      },
                    ],
                  })(
                    <Select className="sa-select" disabled placeholder="">
                      <Select.Option
                        value={
                          user.user_metadata.contractorCompanyID
                            ? user.user_metadata.contractorCompanyID
                            : user.user_metadata.accountID
                        }
                      >
                        {user.user_metadata.contractorCompanyID
                          ? user.contractorCompany.companyName
                          : user.user_metadata.companyName}
                      </Select.Option>
                    </Select>,
                  )}
                </Form.Item>

                <Form.Item label={<b>Reports To</b>} style={{ marginBottom: 15 }}>
                  {getFieldDecorator('reportsToUserID', {
                    initialValue: user.user_metadata.reportsToUserID,
                    rules: [
                      {
                        type: 'string',
                        required: false,
                      },
                    ],
                  })(
                    <Select className="sa-select" disabled placeholder="Select Report To">
                      <Select.Option value={user.user_metadata.reportsToUserID}>
                        {user.name}
                      </Select.Option>
                    </Select>,
                  )}
                </Form.Item>
              </div>
            )}

            <Form.Item style={{ marginBottom: 15 }} label={<b>Invite Type</b>}>
              {getFieldDecorator('inviteType', {
                initialValue: inviteType,
                rules: [
                  {
                    required: true,
                    message: 'Please, select a invite type',
                  },
                ],
              })(
                <Select
                  className="sa-select"
                  placeholder="Invite Type"
                  onSelect={(value: any) => {
                    setInviteType(value);
                  }}
                >
                  <Select.OptGroup key={0} label="Select a Invite Type">
                    <Select.Option value={'send-invite'}>Send Invite</Select.Option>
                    <Select.Option value={'set-password'}>Set Password</Select.Option>
                  </Select.OptGroup>
                </Select>,
              )}
            </Form.Item>

            {inviteType === 'set-password' ? (
              <Form.Item label={<b>Password</b>}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: inviteType === 'set-password' ? true : false,
                      validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                        handleValidateToNextPassword(
                          form,
                          rule,
                          value,
                          callback,
                          'Password must contain at least 6 characters 1 uppercase, 1 number, and 1 special character',
                        ),
                    },
                  ],
                })(
                  <Input.Password
                    className="sa-input"
                    placeholder="Input Password"
                    required
                    prefix={<img src={lockIcon} width="15" alt="Password" />}
                  />,
                )}
              </Form.Item>
            ) : null}
          </>
        )}

        <div className="ant-modal-body__form-footer">
          <Button loading={isLoading} disabled={isLoading} htmlType="submit" type="primary">
            Create
          </Button>

          <Button
            htmlType="submit"
            type="default"
            onClick={() => (form.resetFields(), toggleModal(false))}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const CreateProxyUserModal: any = Form.create({ name: 'CreateProxyUser' })(CreateProxyUser);

export default CreateProxyUserModal;
