import React, { ChangeEvent, Fragment, useState } from 'react';

import { ProjectType, Submission } from '@shared/types/backend.types';

import { ProjectFormsTableComponent } from './table.component';
import { HeadingComponent } from '@components/layout/header/heading.component';

import { ViewerContainer } from '../viewer/state/viewer.container';
import { DatePicker, Form } from 'antd';

import '@admin/submissions/view/submissions.styles.scss';

const { RangePicker } = DatePicker;

interface IProjectFormsProps {
  projects: ProjectType[];
  submissions: Submission[];
  isLoading: boolean;
  isLoadingPDF: boolean;
  fileBlob: string;
  distinctNames: any;
  distinctPrograms: any;
  sortSetup: any;
  submittedReport: any;
  //TODO: add type
  selectedProjectID?: number;
  selectedSubmission?: Submission;
  RenderPdf: () => JSX.Element;
  fetchSubmissionPdf: (submissionId: String) => Promise<void>;
  handleSort: (key: any) => void;
  handleChangeSelectedProject: (projectName: string) => void;
  handleChangeSelectedUser: (username: string) => void;
  handleChangeSelectedProgram: (program: string) => void;
  handleChangesubmittedReport: (report: string) => void;
  handleChangeReportStatus: (isCompleted: number) => void;
  handleChangeDateRange: (dateRange: any) => void;
}

export const ProjectFormComponent = (props: IProjectFormsProps) => {
  const {
    projects,
    submissions,
    selectedProjectID,
    isLoading,
    sortSetup,
    distinctNames,
    distinctPrograms,
    submittedReport,
  } = props;

  const {
    handleSort,
    fetchSubmissionPdf,
    RenderPdf,
    handleChangeSelectedProject,
    handleChangeSelectedUser,
    handleChangeSelectedProgram,
    handleChangeReportStatus,
    handleChangesubmittedReport,
    handleChangeDateRange,
  } = props;

  const [selectedName, setSelectedName] = useState('');
  const [selectedProgram, setSelectedProgram] = useState('');
  const [selectedReport, setSubmittedReport] = useState('');
  const [selectedDateRange, setChangeDate] = useState([]);

  const dateFormat = 'MM/DD/YYYY';


  const handleLocalChangeSelectedUser = (userName: string) => {
    setSelectedName(userName);
    handleChangeSelectedUser(userName);
  };

  const handleLocalChangeSelectedProgram = (program: string) => {
    setSelectedProgram(program);
    handleChangeSelectedProgram(program);
  };

  const handleLocalChangesubmittedReport = (report: string) => {
    setSubmittedReport(report);
    handleChangesubmittedReport(report);
  };

  const handleLocalChangeDateRange = (date: any, dateString: any) => {
    setChangeDate(dateString);
    handleChangeDateRange(dateString);
  };

  return (
    <div className="project-forms sa__container">
      <div className="sa__box md-w-100">
        <HeadingComponent title="Submissions" />

        <div className="sa__box-content">
          <div className="project-forms__filters">
            <Form.Item className="project-forms__filters-form" label="Select a project">
              <select
                style={{ marginLeft: 0 }}
                key="finteringproject"
                name="accountID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Select Account"
                value={selectedProjectID || 'All'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChangeSelectedProject(e.target.value)
                }
                required
              >
                <option value="All">All</option>
                {projects.map((project: ProjectType) => (
                  <option key={project.projectID} value={project.projectID}>
                    {project.name}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Select a User">
              <select
                style={{ marginLeft: 0 }}
                key="filteringName"
                name="accountID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Select Account"
                value={selectedName}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleLocalChangeSelectedUser(e.target.value)
                }
              >
                <option key="All" value="All">
                  All
                </option>
                {distinctNames.length > 0
                  ? distinctNames.map((distinctName: any) => (
                    <option key={distinctName} value={distinctName}>
                      {distinctName}
                    </option>
                  ))
                  : null}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Select Program">
              <select
                style={{ marginLeft: 0 }}
                key="filterinProgram"
                name="programID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Select P"
                value={selectedProgram}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleLocalChangeSelectedProgram(e.target.value)
                }
              >
                <option key="All" value="All">
                  All
                </option>
                {distinctPrograms.map((distinctProgram: any) => (
                  <option key={distinctProgram} value={distinctProgram}>
                    {distinctProgram}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Submitted Report">
              <select
                style={{ marginLeft: 0 }}
                key="submittedReportFilter"
                name="programID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Submitted Report"
                value={selectedReport}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleLocalChangesubmittedReport(e.target.value)
                }
              >
                <option key="All" value="All">
                  All
                </option>
                {submittedReport.map((report: any) => (
                  <option key={report} value={report}>
                    {report}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Date Range">
              <RangePicker
                onChange={handleLocalChangeDateRange}
                format={dateFormat}
                style={{ width: 250 }}
              />
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Submission Status">
              <select
                style={{ marginLeft: 0 }}
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Submission Status"
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChangeReportStatus(parseInt(e.target.value))
                }
              >
                <option key={2} value={2}>
                  All
                </option>

                <option key={1} value={1}>
                  Completed
                </option>

                <option key={0} value={0}>
                  Not Completed
                </option>
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Total Submissions">
              <h3 className="misc">{submissions.length}</h3>
            </Form.Item>
          </div>

          <RenderPdf />

          <ProjectFormsTableComponent
            submissions={submissions}
            isLoading={isLoading}
            sortSetup={sortSetup}
            handleSort={handleSort}
            handleShowViewer={fetchSubmissionPdf}
          />
        </div>
      </div>
    </div >
  );
};
