import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { IThemeState, themeReducer, THEME_INITIAL_STATE } from '@themes/theme.reducer';
import { ILangState, langReducer, LANG_INITIAL_STATE } from '@languages/lang.reducer';
import { ILayoutState, layoutReducer, LAYOUT_INITIAL_STATE } from '@layout/layout.reducer';
import { ISessionState, sessionReducer, SESSION_INITIAL_STATE } from '@session/session.reducer';
import { IAdminState, adminReducer, ADMIN_INITIAL_STATE } from '@admin/admin.reducer';
import { logoutAction } from './root.action';
import { IProfessionalState, professionalReducer } from '@app/back-office/professional.reducer';

export interface IAppState {
  theme: IThemeState;
  lang: ILangState;
  layout: ILayoutState;
  session: ISessionState;
  admin: IAdminState;
  professional: IProfessionalState;
}

const APP_INITIAL_STATE = {
  theme: THEME_INITIAL_STATE,
  lang: LANG_INITIAL_STATE,
  layout: LAYOUT_INITIAL_STATE,
  session: SESSION_INITIAL_STATE,
  admin: ADMIN_INITIAL_STATE,
};

const AppReducer = combineReducers<IAppState>({
  theme: themeReducer,
  lang: langReducer,
  layout: layoutReducer,
  //TODO: Remove messages and Form
  session: sessionReducer,
  admin: adminReducer,
  professional: professionalReducer,
});

//TODO: add type
export const RootReducer = handleActions(
  {
    [logoutAction.FULFILL]: (state: any) => ({
      state: APP_INITIAL_STATE,
    }),
  },
  AppReducer as any,
);
