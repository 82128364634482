import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

export const _getCompanies = (state: IAppState) => state.admin.invite.companies;
export const _getAccountID = (state: IAppState) => state.admin.accountID;
export const _getPackages = (state: IAppState) => state.admin.invite.packages;
export const _getIsLoadingInvite = (state: IAppState) => state.admin.invite.isLoading;
export const _getHasSentInvite = (state: IAppState) => state.admin.invite.hasSentInvite;
export const _getInviteErrors = (state: IAppState) => state.admin.invite.errorMessages;

export const getCompanies = createSelector(
  [_getCompanies],
  companies => companies,
);

export const getAccountID = createSelector(
  [_getAccountID],
  accountID => accountID,
);

export const getPackages = createSelector(
  [_getPackages],
  packages => packages,
);

export const getIsLoadingInvite = createSelector(
  [_getIsLoadingInvite],
  isLoading => isLoading,
);

export const getHasSentInvite = createSelector(
  [_getHasSentInvite],
  hasSentInvite => hasSentInvite,
);

export const getInviteErrors = createSelector(
  [_getInviteErrors],
  errors => errors,
);
