import React, { useState, FormEvent } from 'react'

import { Form, Input, Button, Modal, Select } from 'antd';

import { WrappedFormUtils } from 'antd/es/form/Form';

import { handleValidateToNextPassword } from '@shared/utils/password-validation';

import { useIntl } from 'react-intl';
import lockIcon from '@assets/icon/lock-icon.svg';

import './set-password.styles.scss';


const { Option, OptGroup } = Select;



export interface ISetPasswordModalProps {
    form: WrappedFormUtils;
    inviteType: string;
    setInviteType: any;
    savePasswordConfigration: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;

}

export const InviteTeamPasswordModal = (props: ISetPasswordModalProps) => {

    const { form, savePasswordConfigration } = props;
    const { getFieldDecorator } = form
    const intlProvider = useIntl();
    const [passwordModal, viewPasswordModal] = useState(false)
    const [inviteType, setInviteType] = useState('send-invite');


    return (
        <div className='set-password-modal'>
            <Button onClick={() => viewPasswordModal(true)} type="primary" >Invite Type</Button>

            <Modal
                title="Set Invite Type"
                visible={passwordModal}
                onCancel={() => viewPasswordModal(false)}
                footer={null}
            >
                <div className='password-configration-form'>
                    <Form
                        onSubmit={(event: FormEvent<HTMLFormElement>) => {
                            form.validateFields((err, values) => {
                                if (err) {
                                    return;
                                }
                            }
                            )
                            savePasswordConfigration(event, form)
                            viewPasswordModal(false)
                        }} >
                        <Form.Item
                            label={intlProvider.messages['inputs.inviteType']}
                        >
                            {getFieldDecorator('inviteType', {
                                initialValue: inviteType,
                                rules: [
                                    {
                                        message: 'Please, select a invite type',
                                    },
                                ],
                            })(
                                <Select
                                    className={'input--medium-height'}
                                    placeholder="Invite Type"
                                    onSelect={(value: any) => { setInviteType(value) }}
                                >
                                    <OptGroup key={0} label="Select a Invite Type">
                                        <Option value={"send-invite"}>Send Invite</Option>
                                        <Option value={"set-password"}>Set Password</Option>
                                    </OptGroup>
                                </Select>
                            )}
                        </Form.Item>

                        {
                            inviteType === 'set-password' ?
                                (
                                    <Form.Item
                                        label={intlProvider.messages['inputs.invitePassword']}

                                    >
                                        {getFieldDecorator('password', {
                                            rules:
                                                [
                                                    {
                                                        validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                                                            handleValidateToNextPassword(form, rule, value, callback, intlProvider.messages['inputs.invalidPassword'].toString()),
                                                    },
                                                ],
                                        })(
                                            <Input.Password
                                                className="input--medium-height"
                                                placeholder="Input Password"
                                                required
                                                prefix={<img src={lockIcon} width="15" alt="Password" />}
                                            />,
                                        )}
                                    </Form.Item>

                                )
                                : null
                        }

                        <div className='password-configration-form__btnContainer'>
                            <Button
                                htmlType="submit"
                                type='primary'
                            >
                                Save
                            </Button>

                            <Button type='default' onClick={() => viewPasswordModal(false)}> Cancel </Button>
                        </div>


                    </Form>
                </div>

            </Modal>
        </div>
    )
}


export const InviteTeamPasswordComponent: any = Form.create({ name: 'SavePasswordConfigration' })(InviteTeamPasswordModal);
