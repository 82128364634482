import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  CHANGE_SIDEMENU,
  CHANGE_PATH_REDIRECTION,
  CHANGE_CURRENT_STEP,
  RESET_LAYOUT_STEPS,
} from './layout.action-types';

export const VALIDATE_CURRENT_STEP = 'VALIDATE_CURRENT_STEP';

export const validateCurrentStepAction = createActionRoutine(VALIDATE_CURRENT_STEP);

export const resetLayoutStepsAction = createActionRoutine(RESET_LAYOUT_STEPS);

export const changeSidemenuAction = createActionRoutine(CHANGE_SIDEMENU);
export const changePathRedirectionAction = createActionRoutine(CHANGE_PATH_REDIRECTION);
export const changeCurrentStepAction = createActionRoutine(CHANGE_CURRENT_STEP);
