import { IAdminState } from '@app/admin/admin.reducer';
import { AnyAction } from 'redux';

import {
  changeFormActiveStatusAction,
  fetchAccountsPlansAction,
  changeAccountSafetyPlanActiveStatusAction,
  changeSubFormActiveStatusAction,
} from './safety-plans.actions';

import update from 'immutability-helper';

export const accountsSafetyPlansReducer = {

  [changeFormActiveStatusAction.REQUEST]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        safetyPlans: {
          isUpdating: { $set: 1 },
        },
      },
    });
  },
  [changeSubFormActiveStatusAction.REQUEST]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        safetyPlans: {
          isUpdating: { $set: 1 },
        },
      },
    });
  },

  [changeSubFormActiveStatusAction.FULFILL]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        safetyPlans: {
          isUpdating: { $set: 0 },
        },
      },
    });
  },


  [changeSubFormActiveStatusAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {

    const { isValid, id, safetyPlanID, formID } = payload;

    const safetyPlansUpdated = [...state.accounts.safetyPlans.items].map((safetyPlan: any) => {

      if (safetyPlan.safetyPlanID === safetyPlanID) {
        if (isValid) {
          return update(safetyPlan, {
            forms: {
              [formID]: {
                subForms: {
                  [id]: {
                    isSelected: { $set: isValid }
                  }
                }
              },
            },
          });
        } else {
          return update(safetyPlan, {
            forms: {
              [formID]: {
                subForms: {
                  [id]: {
                    isSelected: { $set: isValid }
                  }
                }
              },
            },
          });
        }
      }

      return safetyPlan;
    });

    return update(state, {
      accounts: {
        safetyPlans: {
          items: { $set: safetyPlansUpdated },
          isUpdating: { $set: 2 },
        },
      },
    });
  },

  [changeFormActiveStatusAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const { isValid, id, safetyPlanID } = payload;

    const safetyPlansUpdated = [...state.accounts.safetyPlans.items].map((safetyPlan: any) => {
      if (safetyPlan.safetyPlanID === safetyPlanID) {
        if (isValid) {
          return update(safetyPlan, {
            isEnable: { $set: isValid },
            forms: {
              [id]: { isSelected: { $set: isValid } },
            },
          });
        } else {
          return update(safetyPlan, {
            forms: {
              [id]: { isSelected: { $set: isValid } },
            },
          });
        }
      }

      return safetyPlan;
    });

    return update(state, {
      accounts: {
        safetyPlans: {
          items: { $set: safetyPlansUpdated },
          isUpdating: { $set: 2 },
        },
      },
    });
  },

  [fetchAccountsPlansAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        safetyPlans: {
          //items: { $set: [] },
          isLoading: { $set: true },
        },
      },
    }),
  [fetchAccountsPlansAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        safetyPlans: {
          items: { $set: payload.safetyPlans },
          isLoading: { $set: false },
        },
      },
    }),
  [fetchAccountsPlansAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        safetyPlans: {
          items: { $set: {} },
          isLoading: { $set: false },
          errorMessages: { $set: undefined },
        },
      },
    }),
  [changeAccountSafetyPlanActiveStatusAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const { formsObject, isEnable, safetyPlanID } = payload;
    const safetyPlansUpdated = [...state.accounts.safetyPlans.items].map((safetyPlan: any) => {
      if (safetyPlan.safetyPlanID === safetyPlanID) {
        return update(safetyPlan, {
          isEnable: { $set: isEnable },
          forms: { $set: formsObject },
        });
      }

      return safetyPlan;
    });
    return update(state, {
      accounts: {
        safetyPlans: {
          items: { $set: safetyPlansUpdated }
        }
      }
    });
  }
};
