import React, { useEffect, useState, SetStateAction, ChangeEvent } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { ErrorMessage } from '@shared/types/utils.types';
import { ProjectType, UserType, AccountType } from '@shared/types/backend.types';
import {
  ProjectRemovalPayload,
  ProjectUpdatePayload,
  ProjectSubmitPayload,
  ProjectFetchPayload,
} from '../projects';
import { AddProjectPayload, MappedProject } from '@app/back-office/programs/projects/project';

import { connect, useDispatch } from 'react-redux';
import { removeBackofficeErrorAction } from '@app/back-office/professional.action';
import {
  fetchProjectsAction,
  removeProjectAction,
  updateProjectAction,
  addProjectAction,
} from '@app/back-office/projects/state/projects.actions';
import { getSessionUser } from '@app/session/session.selector';
import {
  getProfessionalIsLoading,
  getProgramsProjects,
  getProjectCachedCounter,
  getErrorMessages,
} from '@app/back-office/professional.selector';

import ProjectTableComponent from '../view/projects.component';

import { openNotificationWithIcon } from '@components/controls/notification/notification.component';
import '../view/projects.style.scss';
import { fetchUserManagers } from '@app/back-office/corrective-actions/state/corrective-actions.actions';
import {
  getManagers,
  getTeam,
  getTeamAccessUserID,
} from '@app/back-office/team/state/team.selector';
import _ from 'lodash';
import { fetchTeamAction } from '@app/back-office/team/state/team.actions';
import { filterReportsToUsers, getUserManagers } from '@shared/utils/user.utils';
import { Tree } from 'antd';

const { TreeNode } = Tree;

interface IProjectsLockedProps {
  projectsForm: [];
  isLoading: boolean;
  teamAccessUserID?: string;
  projectCachedCounter: number;
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
  user?: UserType;
  team: UserType[];
  managers: UserType[];
  fetchProjectsAction: (payload: ProjectFetchPayload) => void;
  removeErrorAction: (code: any) => void;
  fetchUserManagerAction: (userID: string) => void;
  fetchAccountTeamAction: (accountID: number) => void;
  resetFetchProjectsAction: () => void;
}

const ProjectsLocked = (props: IProjectsLockedProps) => {
  const {
    user,
    teamAccessUserID,
    projectsForm,
    isLoading,
    team,
    managers,
    projectCachedCounter,
    errorMessages,
  } = props;
  const [tree, setTree] = useState<any>([]);
  const [orgTree, setOrgTree] = useState<any>([]);
  const [teamFilter, setTeamFilter] = useState<string>('active');
  const [filteredTeam, setFilteredTeam] = useState<UserType[]>([]);

  const {
    fetchProjectsAction,
    fetchUserManagerAction,
    removeErrorAction,
    resetFetchProjectsAction,
    fetchAccountTeamAction,
  } = props;

  const [openAddProjectModal, toggleAddProjectModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [editingKey, setEditingKey] = React.useState('');
  const [selectedUserId, setUserId] = useState<undefined | string>(undefined);

  useEffect(() => {
    const user = team.find((user: UserType) => user.user_id === selectedUserId);

    if (user && team.length > 0) {
      const sortbyUser: any = _.orderBy(
        getUserManagers(team, user).concat(filterReportsToUsers(team, user)),
        ['app_metadata.role.name'],
        ['desc'],
      );
      setTree(buildTree(sortbyUser, null));
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (team.length > 0) {
      const sortbyUser: any = _.orderBy(
        team.filter((team: UserType) => !team.blocked),
        ['app_metadata.role.name'],
        ['desc'],
      );
      setOrgTree(buildTree(sortbyUser, null));
    }
  }, [team]);

  useEffect(() => {
    if (user) {
      fetchUserManagerAction(user.user_id);
      fetchAccountTeamAction(user.user_metadata.accountID);
    }

    return () => {
      resetFetchProjectsAction();
    };
  }, [fetchProjectsAction, user, resetFetchProjectsAction]);

  useEffect(() => {
    if (user && teamAccessUserID) {
      fetchProjectsAction({ accountID: user.user_metadata.accountID, userID: teamAccessUserID });
    }
  }, [user, teamAccessUserID]);

  useEffect(() => {
    if (projectsForm.length) {
      onFilterChange(teamFilter);
    }
  }, [projectsForm]);

  // Error handler
  useEffect(() => {
    const errors = Object.values(errorMessages);

    errors.forEach((key: any) => {
      key &&
        openNotificationWithIcon(
          'error',
          key.title,
          key.message,
          removeErrorAction({ code: key.code }),
        );
    });
  }, [errorMessages, removeErrorAction]);

  function buildTree(data: UserType[], user: UserType | null): any {
    let children: UserType[] = [];

    if (user === null) {
      children = data.filter((item: UserType) => item.reportsTo === null);
    } else {
      if (user !== undefined) {
        children = data.filter(
          (item: UserType) => item.user_metadata.reportsToUserID === user.user_id,
        );
      }
    }

    if (children.length === 0) {
      return null;
    }

    return children.map((child: UserType) => {
      return (
        <TreeNode
          title={
            <span>
              {child.name}{' '}
              {child.contractorCompany !== null
                ? `(${child.contractorCompany.companyName})`
                : `(${child.user_metadata.companyName})`}{' '}
              - {child.app_metadata.role.name} (Tier {child.tier}){' '}
              {child.blocked ? 'Inactive' : null}
            </span>
          }
          key={child.user_id}
        >
          {buildTree(data, child)}
        </TreeNode>
      );
    });
  }

  const handleUpdateProject = (form: any, record: any) => {
    form.validateFields((error: any, row: any) => {
      if (error) return;

      if (user) {
        dispatch(
          updateProjectAction.request({
            project: { ...record, ...row },
            accountID: user.user_metadata.accountID,
          }),
        );
        setEditingKey('');
      }
    });
  };

  const handleRemoveProject = (projectID: number) => {
    if (user)
      dispatch(removeProjectAction.request({ projectID, accountID: user.user_metadata.accountID }));
  };

  const handleAddProject = (values: any) => {
    if (user) {
      dispatch(
        addProjectAction.request({
          project: { ...values, assignedToUserID: user && user.user_id },
          accountID: user.user_metadata.accountID,
        }),
      );

      toggleAddProjectModal(false);
    }
  };
  const onFilterChange = (value: string) => {
    setTeamFilter(value);

    if (value === 'active' && user){
      setFilteredTeam(
        projectsForm.filter((project: ProjectType) => project.isActive),
      );
    }
    else if (value === 'inactive' && user) {
      setFilteredTeam(projectsForm.filter((project: ProjectType) => !project.isActive));
    } else if (user) {
      setFilteredTeam(projectsForm);
    }
  };


  return (
    <ProjectTableComponent
      editingKey={editingKey}
      projects={filteredTeam}
      team={managers}
      tree={tree}
      orgTree={orgTree}
      isLoading={isLoading}
      openAddProjectModal={openAddProjectModal}
      onFilterChange={onFilterChange}
      setUserId={setUserId}
      toggleAddProjectModal={toggleAddProjectModal}
      setEditingKey={setEditingKey}
      handleAddProject={handleAddProject}
      handleUpdateProject={handleUpdateProject}
      handleRemoveProject={handleRemoveProject}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  projectCachedCounter: getProjectCachedCounter(state),
  isLoading: getProfessionalIsLoading(state),
  projectsForm: getProgramsProjects(state),
  errorMessages: getErrorMessages(state),
  team: getTeam(state),
  teamAccessUserID: getTeamAccessUserID(state),
  managers: getManagers(state),
});

const mapDispatchToProps = {
  fetchProjectsAction: fetchProjectsAction.request,
  fetchUserManagerAction: fetchUserManagers.request,
  removeErrorAction: removeBackofficeErrorAction.trigger,
  resetFetchProjectsAction: fetchProjectsAction.fulfill,
  fetchAccountTeamAction: fetchTeamAction.request,
};

export const ProjectsLockedContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectsLocked);
