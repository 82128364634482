import React from 'react';
import { Modal } from 'antd';
import './admin-guide-styles.scss';

interface IAdminGuideComponentProps {
  showModal: boolean;
  toggleModal: (value: boolean) => void;
}

const AdminGuideComponent = (props: IAdminGuideComponentProps) => {
  const { showModal, toggleModal } = props;

  return (
    <Modal
      className="admin-guide-modal"
      visible={showModal}
      title={null}
      footer={null}
      onCancel={() => toggleModal(false)}
    >
      <iframe
        style={{ width: '100%', height: '100%' }}
        src="https://vvstouat01.blob.core.windows.net/user-guides/sa-administrator-user-guide.pdf"
      ></iframe>
    </Modal>
  );
};

export default AdminGuideComponent;
