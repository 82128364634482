import React, { FormEvent, useState } from 'react';
import {
  Modal,
  Icon,
  Button,
  Empty,
  Tabs,
  Carousel,
  Tooltip,
  message,
  Form,
  Input,
  Checkbox,
  Switch,
  Popconfirm,
} from 'antd';

import './user-certificates.styles.scss';

import {
  AccountType,
  CertificateImageType,
  PackageSeatDetail,
  UserCertificateType,
  UserType,
} from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { BASE_URL } from '@services/axios/axios.config';

import { UserCertificateImgModal } from './img-modal/user-certificate-img.modal';
import { CertificateMailComponent } from './certificate-mailing-modal/certificate-mail-component';
import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';
import { RoleChangeComponent } from './role-change-modal/role-change.component';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

interface IUserCertificatesProps {
  user?: UserType;
  usage: string;
  companyInformation: AccountType;
  site: 'admin' | 'backoffice';
  userCertificates?: UserCertificateType[];
  toMailCertificateIds: [];
  isLoading: boolean;
  seatsInfo: PackageSeatDetail[];
  form: WrappedFormUtils;
  editPowerBiKey: boolean;
  openCertificatesModal: boolean;
  openRoleChangeModal: boolean;
  defaultTabOpen: string;
  currentTab: string;
  toggleRoleChangeModal: (value: React.SetStateAction<boolean>) => void;
  setcurrentTab: (value: string) => void;
  setCertificatesModal: (value: boolean) => void;
  setToMailCertificateIds: any;
  setEditPowerBiKey: (value: boolean) => void;
  handleRoleChange: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
  updatePowerBIKey: (
    event: FormEvent<HTMLFormElement>,
    form: WrappedFormUtils,
    userConfigID: number,
  ) => void;
  handleFecthCertificate: (selectedUserId: string | undefined) => void;
  handleOnCheckBoxChange: (value: boolean, certificateID: number) => void;
  handleMailSend: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
}

export const UserProfile = (props: IUserCertificatesProps) => {
  const {
    user,
    usage,
    userCertificates,
    toMailCertificateIds,
    form,
    openRoleChangeModal,
    seatsInfo,
    openCertificatesModal,
    defaultTabOpen,
    currentTab,
    editPowerBiKey,
    isLoading,
    site,
    companyInformation,
  } = props;
  const {
    setCertificatesModal,
    setEditPowerBiKey,
    handleFecthCertificate,
    toggleRoleChangeModal,
    updatePowerBIKey,
    setcurrentTab,
    handleMailSend,
    handleOnCheckBoxChange,
    handleRoleChange,
    setToMailCertificateIds,
  } = props;
  const [onclickMailBtn, setOnClickMailBtn] = useState(false);
  const { getFieldDecorator } = form;
  const [openMailModal, setMailModal] = useState(false);

  return (
    <>
      {usage === 'team' ? (
        <a
          className="team-actions-dropdown-content"
          onClick={() => handleFecthCertificate(user && user.user_id)}
        >
          <Icon type="eye" />
          <b>View Profile&nbsp;</b>
        </a>
      ) : null}

      <Modal
        title={`Associate - ${user && user.name}`}
        className="certificates-modal"
        visible={openCertificatesModal}
        destroyOnClose={true}
        onCancel={() => {
          setCertificatesModal(false);
        }}
        footer={
          !editPowerBiKey && user ? (
            <>
              {currentTab === '3' ? (
                <>
                  <Tooltip title="Copy user projects URL">
                    <Button
                      className="links-btn"
                      type="default"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          (BASE_URL + `/reports/projects/${user.userConfig.apiKey}`) as string,
                        );
                        message.success('Projects URL Copied');
                      }}
                      icon="project"
                      size={'default'}
                    />
                  </Tooltip>

                  <Tooltip title="Copy user submissions URL">
                    <Button
                      className="links-btn"
                      type="default"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          (BASE_URL + `/reports/submissions/${user.userConfig.apiKey}`) as string,
                        );
                        message.success('Submissions URL Copied');
                      }}
                      icon="container"
                      size={'default'}
                    />
                  </Tooltip>

                  <Tooltip title="Copy users URL">
                    <Button
                      className="links-btn"
                      type="default"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          (BASE_URL + `/reports/users/${user.userConfig.apiKey}`) as string,
                        );
                        message.success('Users URL Copied');
                      }}
                      icon="user"
                      size={'default'}
                    />
                  </Tooltip>

                  <Tooltip title="Copy user corrective actions URL">
                    <Button
                      className="links-btn"
                      type="default"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          (BASE_URL + `/reports/actions/${user.userConfig.apiKey}`) as string,
                        );
                        message.success('Corrective Actions URL Copied');
                      }}
                      icon="project"
                      size={'default'}
                    />
                  </Tooltip>
                </>
              ) : null}

              {currentTab === '2' && userCertificates && userCertificates.length > 0 ? (
                <>
                  {!onclickMailBtn && (
                    <Button
                      type="primary"
                      icon="mail"
                      onClick={() => {
                        setOnClickMailBtn(true);
                      }}
                      size={'default'}
                    >
                      E-Mail Certificates
                    </Button>
                  )}

                  {onclickMailBtn && (
                    <>
                      <Button
                        type="primary"
                        icon="check"
                        onClick={() => {
                          setMailModal(true);
                          setOnClickMailBtn(false);
                        }}
                        disabled={toMailCertificateIds.length > 0 ? false : true}
                        size={'default'}
                      />

                      <Button
                        className="ant-modal-btn-close"
                        type="default"
                        icon="close"
                        onClick={() => {
                          setOnClickMailBtn(false);
                        }}
                        size={'default'}
                      />
                    </>
                  )}

                  <CertificateMailComponent
                    visibleModal={openMailModal}
                    setToMailCertificateIds={setToMailCertificateIds}
                    setVisibleModal={setMailModal}
                    handleMailSend={handleMailSend}
                  />
                </>
              ) : null}

              <Button
                className="ant-modal-btn-close"
                type="primary"
                onClick={() => {
                  setCertificatesModal(false);
                }}
              >
                Close
              </Button>
            </>
          ) : null
        }
      >
        <Tabs
          className="tabs"
          defaultActiveKey={defaultTabOpen}
          onChange={value => {
            setEditPowerBiKey(false);
            setcurrentTab(value);
          }}
        >
          <TabPane tab="Profile" key="1">
            <table className="sa-info-table">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>
                    <span>
                      {user && user.name
                        ? `${user.name} ${user.tier > 0 ? `Tier ${user.tier}` : ''} ${user.blocked ? 'Inactive' : 'Active'
                        }`
                        : null}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Email</td>
                  <td>
                    <span>{user && user.email ? user.email : <b>N/A</b>}</span>
                  </td>
                </tr>

                <tr>
                  <td>Address</td>
                  <td>
                    <span>
                      {user && user.user_metadata.Address ? user.user_metadata.address : <b>N/A</b>}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Role</td>
                  <td>
                    <span>{user ? user.app_metadata.role.name : <b>N/A</b>}</span>
                    <Tooltip title="Change Role">
                      <i
                        style={{ marginLeft: '10px' }}
                        className="fa fa-edit"
                        onClick={() => {
                          setCertificatesModal(false);
                          toggleRoleChangeModal(true);
                        }}
                      />
                    </Tooltip>
                  </td>
                </tr>

                <tr>
                  <td>Job Title</td>
                  <td>
                    <span>
                      {user && user.user_metadata.jobTitle ? (
                        user.user_metadata.jobTitle
                      ) : (
                        <b>N/A</b>
                      )}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>License Type</td>
                  <td>
                    <span>
                      {user && user.user_metadata.package ? user.user_metadata.package : <b>N/A</b>}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Company Name</td>
                  <td>
                    <span>
                      {user && user.contractorCompany ? (
                        user.contractorCompany.companyName
                      ) : user && user.user_metadata.companyName ? (
                        user.user_metadata.companyName
                      ) : (
                        <b>N/A</b>
                      )}
                    </span>
                  </td>
                </tr>
                
                {!!(user && (!user.user_metadata.proxyUsers || !user.user_metadata.proxyUsers.length )) && (
                  <tr>
                    <td>Proxy Users</td>
                    <td>
                      {user && (
                        <Link
                          style={{
                            marginLeft: '0.5rem',
                            fontSize: 14,
                            color: 'rgba(0,0,0,0.65)',
                            textDecoration: 'underline',
                            textUnderlinePosition: '2px',
                          }}
                          className="ca-table-clickable"
                          to={
                            site === 'admin'
                              ? `/admin/user/${user.user_id}/proxy-users`
                              : `/back-office/user/${user.user_id}/proxy-users`
                          }
                          onClick={() => setCertificatesModal(false)}
                        >
                          View
                        </Link>
                      )}
                    </td>
                  </tr>
                )}

                <tr>
                  <td>Emergency Contact Name</td>
                  <td>
                    <span>
                      {user && user.emergencyContactName ? user.emergencyContactName : <b>N/A</b>}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>Emergency Contact Number</td>
                  <td>
                    <span>
                      {user && user.emergencyContactNumber ? (
                        user.emergencyContactNumber
                      ) : (
                        <b>N/A</b>
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPane>

          <TabPane tab="Licenses / Certificates" key="2">
            <div className="certificates-modal__container">
              {isLoading || userCertificates === undefined ? (
                <div className="certificates-modal__img-subcontainer certificates-modal__loading">
                  <TableLoadingSpinnerComponent />
                </div>
              ) : userCertificates.length > 0 ? (
                userCertificates.map((certificate: UserCertificateType) => (
                  <div className="certificates-modal__subcontainer">
                    <div className="certificates-modal__img-container">
                      {certificate.certificateImageUri.length > 0 ? (
                        <Carousel
                          draggable
                          arrows={false}
                          infinite={false}
                          dots={true}
                          autoplay={false}
                        >
                          {certificate.certificateImageUri.map((image: CertificateImageType) => (
                            <img src={image.certificateImageURL} />
                          ))}
                        </Carousel>
                      ) : null}
                      <UserCertificateImgModal certificate={certificate} />
                    </div>

                    <div className="certificates-modal__info-container">
                      <table>
                        <tbody>
                          <tr>
                            <td className="certificates-modal__card-heading">
                              <h3>{certificate.certificateTitle}</h3>
                              {onclickMailBtn && (
                                <Checkbox
                                  onChange={event => {
                                    handleOnCheckBoxChange(
                                      event.target.checked,
                                      certificate.userCertificateID,
                                    );
                                  }}
                                ></Checkbox>
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Issue Date:</td>
                            <td>
                              <span className="info">
                                {certificate.certificateIssueDate !== '' ? (
                                  certificate.certificateIssueDate
                                ) : (
                                  <b>N/A</b>
                                )}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td>Expiration Date:</td>
                            <td>
                              <span className="info">
                                {certificate.certificateExpirationDate === 'Y'
                                  ? certificate.certificateExpirationDate
                                  : 'Does not expire'}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
              ) : (
                <div className="certificates-modal__img-subcontainer certificates-modal__empty ">
                  <Empty className="submissions-empty" description={'No Certificates'} />
                </div>
              )}
            </div>
          </TabPane>

          {user && user.app_metadata.role.roleID !== 1 && site !== 'backoffice' ? (
            <TabPane tab="Associate API Key" key="3">
              <table className="sa-info-table">
                <tbody>
                  <tr>
                    <td>API Key</td>
                    <td>
                      <span>{user ? user.userConfig.apiKey : <b>N/A</b>}</span>
                      <Tooltip title="Copy API Key">
                        <i
                          className="fa fa-clipboard"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              user ? (user.userConfig.apiKey as string) : 'N/A',
                            );
                            message.success('Api Key Copied');
                          }}
                        />
                      </Tooltip>
                    </td>
                  </tr>

                  {!!(companyInformation && companyInformation.isPowerBIReportFree) && (
                    <>
                      <tr>
                        <td>Submissions Power BI Key</td>
                        <td>
                          {user && user.userConfig.powerBIKey !== '' ? (
                            <div className="d-flex">
                              <span
                                style={{ maxWidth: '300px', width: 'auto' }}
                                className="cuttext"
                              >
                                {user.userConfig.powerBIKey}
                              </span>
                              <div>
                                <Tooltip title="Update Submissions Power BI Key">
                                  <i
                                    className="fa fa-edit"
                                    onClick={() => setEditPowerBiKey(!editPowerBiKey)}
                                  />
                                </Tooltip>

                                <Tooltip title="Copy Submissions Power BI Key">
                                  <i
                                    style={{ marginLeft: '10px', fontSize: 'medium' }}
                                    className="fa fa-clipboard"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        user.userConfig.powerBIKey as string,
                                      );
                                      message.success('Power Submissions Power BI Key Copied');
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          ) : (
                            <>
                              <span>
                                <b>N/A</b>
                              </span>
                              <Tooltip title="Assign Submissions Power BI Key">
                                <i
                                  style={{ marginLeft: '10px' }}
                                  className="fa fa-edit"
                                  onClick={() => setEditPowerBiKey(!editPowerBiKey)}
                                />
                              </Tooltip>
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Corrective Actions Power BI Key</td>
                        <td>
                          {user &&
                            user.userConfig.caPowerBIkey !== '' &&
                            user.userConfig.caPowerBIkey !== null ? (
                            <div className="d-flex">
                              <span
                                style={{ maxWidth: '300px', width: 'auto' }}
                                className="cuttext"
                              >
                                {user.userConfig.caPowerBIkey}
                              </span>
                              <div>
                                <Tooltip title="Update Corrective Actions Power BI Key">
                                  <i
                                    className="fa fa-edit"
                                    onClick={() => setEditPowerBiKey(!editPowerBiKey)}
                                  />
                                </Tooltip>

                                <Tooltip title="Copy Corrective Actions Power BI Key">
                                  <i
                                    style={{ marginLeft: '10px', fontSize: 'medium' }}
                                    className="fa fa-clipboard"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        user.userConfig.caPowerBIkey as string,
                                      );
                                      message.success('Corrective Actions Power BI Key Copied');
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          ) : (
                            <>
                              <span>
                                <b>N/A</b>
                              </span>
                              <Tooltip title="Assign Corrective Actions Power BI Key">
                                <i
                                  style={{ marginLeft: '10px' }}
                                  className="fa fa-edit"
                                  onClick={() => setEditPowerBiKey(!editPowerBiKey)}
                                />
                              </Tooltip>
                            </>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>

              {editPowerBiKey === true && user ? (
                <Form
                  hideRequiredMark
                  onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    updatePowerBIKey(event, form, user.userConfig.userConfigID);
                    setEditPowerBiKey(false);
                  }}
                >
                  <Form.Item
                    label={
                      <b>
                        {user.userConfig.powerBIKey !== ''
                          ? 'Update Power BI Key'
                          : 'Assign Power BI Key'}
                      </b>
                    }
                  >
                    {getFieldDecorator('powerBIKey', { initialValue: user.userConfig.powerBIKey })(
                      <Input
                        className="input--medium-height"
                        autoFocus={true}
                        placeholder="Power BI Key"
                      />,
                    )}
                  </Form.Item>

                  <Form.Item
                    label={
                      <b>
                        {user.userConfig.caPowerBIkey !== '' &&
                          user.userConfig.caPowerBIkey !== null
                          ? 'Update Corrective Action Power BI Key'
                          : 'Assign Corrective Action Power BI Key'}
                      </b>
                    }
                  >
                    {getFieldDecorator('caPowerBIKey', {
                      initialValue: user.userConfig.caPowerBIkey,
                    })(
                      <Input
                        className="input--medium-height"
                        placeholder="Corrective Action Power BI Key"
                      />,
                    )}
                  </Form.Item>

                  <div className="ant-modal-body__form-footer">
                    <Button htmlType="submit" type="primary">
                      Save
                    </Button>

                    <Button
                      type="default"
                      onClick={() => {
                        setEditPowerBiKey(false);
                      }}
                    >
                      {' '}
                      Cancel{' '}
                    </Button>
                  </div>
                </Form>
              ) : null}
            </TabPane>
          ) : null}
        </Tabs>
      </Modal>
      <RoleChangeComponent
        openModal={openRoleChangeModal}
        toggleModal={toggleRoleChangeModal}
        seatsInfo={seatsInfo}
        handleRoleChange={handleRoleChange}
        user={user}
      />
    </>
  );
};

export const UserProfileModal: any = Form.create({ name: 'UserProfile' })(UserProfile);
