import React from 'react';

interface ISorterProps {
  status: string;
}

export const SorterComponent = (props: ISorterProps) => {
  const { status } = props;

  switch (status) {
    case 'asc':
      return <i className="fas fa-sort-alpha-down-alt sorter" />;
    case 'desc':
      return <i className="fas fa-sort-alpha-up-alt sorter" />;
    default:
      return null;
  }
};
