import React, { FormEvent } from 'react';

import { ThemeType } from '@shared-types/theme.type';
import { AccountType, PackageSeatDetail, UserType, ContractorType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { Classes } from 'jss';

import { handleValidateToNextPassword } from '@shared/utils/password-validation';

import { Form, Input, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonControlComponent } from '@controls/button/button-control.component';
import { ViewPackageModal } from '@components/admin/view-package/view-package.component';
import { ModalContractorInviteContainer } from '@components/admin/add-contractor/add-contractor.container';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import lockIcon from '@assets/icon/lock-icon.svg';

import '@controls/input.styles.css';
import './invite.styles.scss';

const { Option, OptGroup } = Select;

export interface IAdminInviteComponentProps {
  selectedCompnay: AccountType;
  contractorsCompany: ContractorType[];
  isSubmittingInvite: boolean;
  classes: Classes;
  showModal: boolean;
  form: WrappedFormUtils;
  seatsInfo: PackageSeatDetail[];
  reportsToUsersInfo: UserType[];
  inviteType: string;
  selectedAccountID: number
  setSelectedLicense: any;
  setSelectedReportsTo: any;
  setShowModal: any;
  setInviteType: any;
  handleSubmit: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;

}

const AdminInvite = (props: IAdminInviteComponentProps) => {
  const { contractorsCompany, classes, form, isSubmittingInvite, seatsInfo, showModal, reportsToUsersInfo, selectedAccountID, selectedCompnay, inviteType } = props;
  const { handleSubmit, setSelectedLicense, setSelectedReportsTo, setShowModal, setInviteType } = props;
  const { formTitleStyles, formLabelStyles, formMessageStyles, selectStyles } = classes;
  const { getFieldDecorator } = form;
  const intlProvider = useIntl();

  return (
    <div className="invite__container sa__container">

      <div className='sa__box'>

        <div className='invite-team__heading-container sa__box-heading'>

          <div>
            <h1 className={classnames(formTitleStyles, 'sa__box-heading-title')}>
              <FormattedMessage id="dashboard.admin.invite.formTitle" />
            </h1>
            <h3 className={classnames(formMessageStyles, 'invite__form-message')}>
              <FormattedMessage id="dashboard.admin.invite.formMessage" />
            </h3>
          </div>

          <ViewPackageModal seatsDetails={seatsInfo} />

        </div>

        <Form
          className="invite__form-layout sa__box-content"
          layout="vertical"
          onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event, form)}
          hideRequiredMark
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Form.Item
              className={classnames('invite__form-label sm-w-45 lg-w-50', formLabelStyles)}
              label={intlProvider.messages['inputs.firstName']}
            >
              {getFieldDecorator('givenName', {
                rules: [
                  {
                    required: true,
                    message: intlProvider.messages['inputs.requiredName'],
                  },
                ],
              })(<Input className="input--medium-height" placeholder="John" required />)}
            </Form.Item>
            <Form.Item
              className={classnames('invite__form-label sm-w-45 lg-w-50 ', formLabelStyles)}
              label={intlProvider.messages['inputs.lastName']}
            >
              {getFieldDecorator('familyName', {
                rules:
                  [
                    {
                      required: true,
                      message: intlProvider.messages['inputs.requiredLastName'],
                    },
                  ],
              })(<Input className="input--medium-height" placeholder="Doe" required />)}
            </Form.Item>
          </div>
          <div className="d-flex flex-wrap justify-between sm-w-100">
            <Form.Item
              className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}

              label={intlProvider.messages['inputs.emailAddress']}
            >
              {getFieldDecorator('email', {
                rules:
                  [
                    {
                      required: true,
                      type: 'email',
                      message: intlProvider.messages['inputs.requiredEmailAddress'],
                    },
                  ],
              })(
                <Input
                  className="input--medium-height"
                  placeholder="johndoe@me.com"
                />,
              )}
            </Form.Item>



            {(contractorsCompany.length > 0) || (reportsToUsersInfo !== undefined && reportsToUsersInfo.length > 0) ?


              (<Form.Item
                className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
                label={intlProvider.messages['inputs.contractorCompany']}
              >
                {getFieldDecorator('contractorCompanyID', {
                  rules: [
                    {
                      required: true,
                      message: 'Please, select a Company Name',
                    },
                  ],
                })(
                  <Select
                    className={classnames(selectStyles, 'lg-w-50 sm-w-100 input--medium-height')}
                    placeholder="Company"
                  >
                    <OptGroup label="Select Company Name">
                      <Option className='parent-company-option' value="0">
                        {selectedCompnay.name}
                        <i className='fa fa-star icon-highlight'></i>
                      </Option>
                      {
                        contractorsCompany.map((contractorCompany: any) => (
                          <Option key={contractorCompany.contractorCompanyID} value={contractorCompany.contractorCompanyID}>
                            {contractorCompany.companyName}
                          </Option>
                        ))
                      }
                      <option className='add-company-option' value="1" onClick={() => { setShowModal(true) }} >Add Contractor Company</option>
                    </OptGroup>
                  </Select>
                )}

                <ModalContractorInviteContainer
                  selectedAccountID={selectedAccountID}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />

              </Form.Item>
              )

              : (<Form.Item
                className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
                label={intlProvider.messages['inputs.contractorCompany']}
              >
                <Select
                  className={classnames(selectStyles, 'lg-w-50 sm-w-100 input--medium-height')}
                  defaultValue={selectedCompnay.name}
                  disabled
                  placeholder={selectedCompnay.name}
                >
                  <Option value={'null'}>{selectedCompnay.name}</Option>
                </Select>
              </Form.Item>
              )}

          </div>
          <div className="d-flex flex-wrap justify-between sm-w-100">
            <Form.Item
              className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
              label={intlProvider.messages['inputs.jobTitle']}
            >
              {getFieldDecorator('jobTitle', {
                rules: [
                  {
                    type: 'string',
                    required: true,
                    message: intlProvider.messages['inputs.requiredRole'],
                  },
                ],
              })(
                <Input className="input--medium-height" placeholder="Safety Professional" required />,
              )}
            </Form.Item>

            {reportsToUsersInfo !== undefined ?

              reportsToUsersInfo.length === 0 ? (
                <Form.Item
                  className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
                  label={intlProvider.messages['inputs.reportsTo']}
                >
                  <Select
                    className={classnames(selectStyles, 'lg-w-50 sm-w-100 input--medium-height')}
                    placeholder="Owner (Tier 1)"
                    disabled
                  >
                    <Option value={"null"}>Owner (Tier 1)</Option>
                  </Select>
                </Form.Item>
              )

                : (
                  <Form.Item
                    className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
                    label={intlProvider.messages['inputs.reportsTo']}
                  >
                    {getFieldDecorator('reportsToUserID', {
                      rules: [
                        {
                          required: true,
                          message: 'Please, select Report to',
                        },
                      ],
                    })(
                      <Select
                        className={classnames(selectStyles, 'lg-w-50 sm-w-100 input--medium-height')}
                        placeholder="Reports To"
                        onSelect={(value) => setSelectedReportsTo(value)}
                      >
                        <OptGroup key={0} label="Select Reports To">
                          {reportsToUsersInfo.map((reportsTo: UserType) => {
                            if (reportsTo.app_metadata.role.roleID === 2 && !reportsTo.blocked) {
                              if (reportsTo.contractorCompany !== null) {
                                return (
                                  <Option key={reportsTo.user_id} value={reportsTo.user_id}>
                                    {reportsTo.name} &ensp;({reportsTo.contractorCompany.companyName})
                                  </Option>
                                );
                              }
                              else {
                                return (
                                  <Option key={reportsTo.user_id} value={reportsTo.user_id}>
                                    {reportsTo.name}
                                  </Option>)
                              }
                            }
                          })}
                        </OptGroup>
                      </Select>,
                    )}
                  </Form.Item>
                )

              : null
            }
          </div>

          <div className="d-flex flex-wrap justify-between sm-w-100">
            {seatsInfo != undefined &&
              <Form.Item
                className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
                required
                label={intlProvider.messages['inputs.package']}
              >
                {getFieldDecorator('package', {
                  rules: [
                    {
                      required: true,
                      message: 'Please, select a Licence type',
                    },
                  ],
                })(
                  <Select
                    className={classnames(selectStyles, 'lg-w-50 sm-w-100 input--medium-height')}
                    placeholder="License Type"
                    onSelect={(value) => setSelectedLicense(value)
                    }
                  >
                    <OptGroup key={0} label="Select a License Type">
                      {seatsInfo.map((seat: any) => (
                        seat.packageID === 'SA-ADMINISTRATOR' ?
                          <Option key={seat.packageID} value={seat.packageID}>
                            {`${seat.packageName} (${seat.availableSeats})`}
                          </Option>
                          : null
                      ))}
                    </OptGroup>
                  </Select>,
                )}
              </Form.Item>
            }
          </div>

          <div className="d-flex flex-wrap justify-between sm-w-100">

            <Form.Item
              className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
              required
              label={intlProvider.messages['inputs.inviteType']}
            >
              {getFieldDecorator('inviteType', {
                initialValue: inviteType,
                rules: [
                  {
                    required: true,
                    message: 'Please, select a invite type',
                  },
                ],
              })(
                <Select
                  className={classnames(selectStyles, 'lg-w-50 sm-w-100 input--medium-height')}
                  placeholder="Invite Type"
                  onSelect={(value: any) => { setInviteType(value) }}
                >
                  <OptGroup key={0} label="Select a Invite Type">
                    <Option value={"send-invite"}>Send Invite</Option>
                    <Option value={"set-password"}>Set Password</Option>
                  </OptGroup>
                </Select>
              )}
            </Form.Item>

            {
              inviteType === 'set-password' ?
                (
                  <Form.Item
                    className={classnames('invite__form-label sm-w-100 lg-w-50', formLabelStyles)}
                    label={intlProvider.messages['inputs.invitePassword']}
                  >
                    {getFieldDecorator('password', {
                      rules:
                        [
                          {
                            validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                              handleValidateToNextPassword(form, rule, value, callback, intlProvider.messages['inputs.invalidPassword'].toString()),
                          },
                        ],
                    })(
                      <Input.Password
                        className="input--medium-height"
                        placeholder="Input Password"
                        required
                        prefix={<img src={lockIcon} width="15" alt="Password" />}
                      />
                    )}
                  </Form.Item>
                )
                : null
            }

          </div>


          <div className="invite__form-submit-container">
            <Form.Item className="invite__form-submit">
              <ButtonControlComponent
                text={intlProvider.messages['buttons.invite'].toString()}
                size="medium"
                color="green"
                htmlType="submit"
                loading={isSubmittingInvite}
                style={{ fontSize: '20px', marginTop: 50 }}
              />
            </Form.Item>
          </div>
        </Form>

      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  formTitleStyles: {
    color: theme.black,
  },
  formLabelStyles: {
    '& .ant-form-item-label label': {
      ...theme.inputs.label,
      color: theme.grey.fourth,
    },
  },
  formMessageStyles: {
    color: theme.grey.fourth,
  },
  selectStyles: {
    fontWeight: 'normal',
  },
});

const AdminInviteStyled = injectSheet(styles)(AdminInvite);

//TODO: add type
export const AdminInviteComponent: any = Form.create({ name: 'invite' })(AdminInviteStyled);
