import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { SA_MENU_PATH, SYS_ADMIN_MENU_PATH } from '@constants/paths';

//TODO: add types
export const ROLE_MENUS: any = {
  safetyProfessional: SA_MENU_PATH,
  systemAdmin: SYS_ADMIN_MENU_PATH,
};

export const _getActiveSidemenu = (state: IAppState) => {
  return ROLE_MENUS[state.session.userRole];
};

export const getActiveSidemenu = createSelector(
  [_getActiveSidemenu],
  activeSidemenu => activeSidemenu,
);

export const _getIsLoadingSidemenu = (state: IAppState) => state.layout.isLoadingSidemenu;

export const getIsLoadingSidemenu = createSelector(
  [_getIsLoadingSidemenu],
  isLoadingSidemenu => isLoadingSidemenu,
);

export const _getPathRedirection = (state: IAppState) => state.layout.pathRedirection;

export const getPathRedirection = createSelector(
  [_getPathRedirection],
  getPathRedirection => getPathRedirection,
);

export const _getCurrentStep = (state: IAppState) => state.layout.steps.currentStep;

export const getCurrentStep = createSelector(
  [_getCurrentStep],
  currentStep => currentStep,
);

export const _getStepItems = (state: IAppState) => state.layout.steps.items;

export const getStepItems = createSelector(
  [_getStepItems],
  items => items,
);

export const _getStepTitle = (state: IAppState) => state.layout.steps.title;

export const getStepTitle = createSelector(
  [_getStepTitle],
  title => title,
);
