import React, { FormEvent } from 'react';

import { connect } from 'react-redux';

import { IAppState } from '@rdx/root.reducer';

import { WrappedFormUtils } from 'antd/es/form/Form';
import { UserType } from '@shared/types/backend.types';

import { TeamChangePasswordModal } from "./change-password.component";
import { UpdateAccountUserPayload } from "@app/admin/accounts/accounts";
import { updateAccountUserAction } from "@app/admin/accounts/team/state/team.actions";

export interface ITeamChangePasswordContainerProps {
    user: UserType;
    updateAccountUserAction: (payload: UpdateAccountUserPayload) => void;
}


const TeamChangePassword = (props: ITeamChangePasswordContainerProps) => {

    const { user, updateAccountUserAction, } = props


    const changeUserPassword = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils, selectedUserId: string) => {
        event.preventDefault();

        form.validateFieldsAndScroll((err: string, formValues: UserType) => {

            formValues = { ...formValues, ...{ user_id: selectedUserId } }

            const user: UserType = formValues

            updateAccountUserAction({ user: user, toUpdateUserId: selectedUserId });
        })
    }


    return (
        <TeamChangePasswordModal selectedUserId={user.user_id} changeUserPassword={changeUserPassword} />
    )

}

const mapStateToProps = (state: IAppState) => ({});

const mapDispatchToProps = {
    updateAccountUserAction: updateAccountUserAction.request,
};

export const TeamChangePasswordContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TeamChangePassword);