import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';
import {
  FETCH_SAFETY_PLANS_API,
  ADD_SAFETY_PLAN_API,
  FETCH_FORMS_API,
  ADD_FORM_API,
  UPDATE_FORM_API,
  FETCH_FORM_QUESTIONS_API,
  ADD_FORM_QUESTION_API,
  UPDATE_FORM_QUESTION_API,
  REMOVE_FORM_QUESTION_API,
  REMOVE_REGULATION_IMAGE_API,
  FETCH_SAFETY_SUBFORMS,
  FETCH_MULTI_QUESTION_API,
  PLANS_SORT_ORDER_API,
  FORMS_SORT_ORDER_API,
  QUESTIONS_SORT_ORDER_API,
  SORT_MULTI_QUESTIONS_API,
  REMOVE_SAFETY_PROGRAM_API

} from '@constants/endpoints';

// SAFETY PLANS ACTIONS
export const fetchSafetyPrograms = createActionRoutine('FETCH_SAFETY_PROGRAMS', FETCH_SAFETY_PLANS_API);
export const addSafetyProgram = createActionRoutine('ADD_SAFETY_PROGRAMS', ADD_SAFETY_PLAN_API);
export const removeSafetyProgram = createActionRoutine('REMOVE_SAFETY_PROGRAMS', REMOVE_SAFETY_PROGRAM_API);
export const managePlansSortOrder = createActionRoutine('MANAGE_PLANS_SORT_ORDER', PLANS_SORT_ORDER_API);



// SAFETY FORMS ACTIONS
export const fetchSafetyForms = createActionRoutine('FETCH_SAFETY_FORMS', FETCH_FORMS_API);
export const addSafetyForm = createActionRoutine('ADD_SAFETY_FORMS', ADD_FORM_API);
export const updateSafetyForm = createActionRoutine('UPDATE_SAFETY_FORMS', UPDATE_FORM_API);
export const removeSafetyForm = createActionRoutine('REMOVE_SAFETY_FORMS', UPDATE_FORM_API);
export const manageFormsSortOrder = createActionRoutine('MANAGE_FORMS_SORT_ORDER', FORMS_SORT_ORDER_API);
export const setSelectedForm = createActionRoutine('SET_SELECTED_FORM');


// EXCEPTION NEEDED ACTION 
export const fetchSafetySubforms = createActionRoutine('FETCH_SAFETY_SUBFORMS', FETCH_SAFETY_SUBFORMS);
export const addSafetySubforms = createActionRoutine('ADD_SAFETY_SUBFORM', UPDATE_FORM_API);
export const updateSafetySubforms = createActionRoutine('UPDATE_SAFETY_SUBFORMS');


// QUESTIONS ACTIONS
export const fetchFormQuestions = createActionRoutine('FETCH_QUESTIONS', FETCH_FORM_QUESTIONS_API);
export const addFormQuestion = createActionRoutine('ADD_FORM_QUESTION', ADD_FORM_QUESTION_API);
export const updateFormQuestion = createActionRoutine('UPDATE_FORM_QUESTION', UPDATE_FORM_QUESTION_API);
export const removeFormQuestion = createActionRoutine('REMOVE_FORM_QUESTION', REMOVE_FORM_QUESTION_API);
export const fetchMultiQuestions = createActionRoutine('FETCH_MULTI_QUESTION_API', FETCH_MULTI_QUESTION_API);
export const manageQuestionsSortOrder = createActionRoutine('MANAGE_QUESTION_SORT_ORDER', QUESTIONS_SORT_ORDER_API);
export const manageMQQuestionsSortOrder = createActionRoutine('MANAGE_MQ_QUESTION_SORT_ORDER', QUESTIONS_SORT_ORDER_API);
export const manageMultiQuestionsSortOrder = createActionRoutine('FETCH_MULTI_QUESTION_SORT_ORDER_API', SORT_MULTI_QUESTIONS_API);


//QUESTIONS REGULATION ACTIONS
export const removeRegulationImage = createActionRoutine('REMOVE_REGULATION_IMAGE', REMOVE_REGULATION_IMAGE_API);
export const updateRegulations = createActionRoutine('UPDATE_REGULATION');
export const addRegulation = createActionRoutine('ADD_REGULATION');

// MULTIQUESTIONS ACTIONS
