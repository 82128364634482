import React, { useState, FormEvent } from 'react';
import { useIntl } from 'react-intl';
import { Menu, Icon, Dropdown, Drawer, Button, Tabs, Select } from 'antd';
import { UserType, AccountType } from '@shared/types/backend.types';
import { AddAccountComponent } from '@components/admin/add-account/add-account.component';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { TeamChangePasswordContainer } from '@components/admin/change-password/change-password.container';
import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';
import SafetyAssistLogo from '@shared/assets/logo/logo.png';
import HeaderAvatarLogo from '@shared/assets/icons/avatar.png';

import './main-header.component.scss';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

interface IMainHeaderProps {
  user: UserType;
  loggedUserAccesses: UserType[];
  accounts: AccountType[];
  selectedCompanyInformation?: AccountType;
  isCreatingCompany?: boolean;
  isModalVisible?: boolean;
  navigationMenu: boolean;
  toggleNavigationMenu: any;
  handleLogout: () => void;
  validateKeys?: (event: any) => void;
  setAccountIDAction: (accountID: number) => void;
  handleToggleModal: (isUpdating: boolean, form?: WrappedFormUtils) => void;
  handleSaveAccount: (form: WrappedFormUtils) => void;
  handleUpdateAccount: (form: WrappedFormUtils) => void;
  setTeamAccessUserIdAction: (userID: string) => void;
}

const MainHeaderComponent = (props: IMainHeaderProps) => {
  const {
    user,
    accounts,
    loggedUserAccesses,
    selectedCompanyInformation,
    isCreatingCompany,
    isModalVisible,
    navigationMenu,
    toggleNavigationMenu,
    handleLogout,
    setAccountIDAction,
    validateKeys,
    handleToggleModal,
    handleSaveAccount,
    handleUpdateAccount,
    setTeamAccessUserIdAction,
  } = props;

  const intlProvider = useIntl();
  const [openSelectCompanyDrawer, setSelectCompanyDrawer] = useState(false);


  const menu = (
    <Menu>
      {user.app_metadata.role.roleID !== 3 ? (
        <Menu.Item>
          <UserCertificateContainer
            team={[user]}
            targetUserID={user.user_id}
            usage="team"
            site="backoffice"
            defaultTabOpen="1"
          />
        </Menu.Item>
      ) : null}

      {!user.user_metadata.forProxyUserID && user.app_metadata.role.roleID !== 3 ? (
        <Menu.Item>
          <Link to={`/back-office/user/${user.user_id}/proxy-users`}>
            <div className="team-actions-dropdown-content">
              <Icon type="user-add" />
              <b>Proxy Users</b>
            </div>
          </Link>
        </Menu.Item>
      ) : null}

      {user.app_metadata.role.roleID !== 3 ? (
        <Menu.Item>
          <TeamChangePasswordContainer user={user} />
        </Menu.Item>
      ) : null}

      <Menu.Item onClick={() => handleLogout()}>
        <a className="main-header__container-dropdown_item">
          <Icon type="logout" />
          &nbsp;&nbsp;&nbsp;
          <b>Logout</b>
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="main-header__container">
        <div className="main-header__container-header">
          <Button
            type="primary"
            onClick={() => {
              toggleNavigationMenu(!navigationMenu);
            }}
          >
            <Icon type={navigationMenu ? 'menu-unfold' : 'menu-fold'} />
          </Button>

          <img src={SafetyAssistLogo} />
        </div>

        <div className="main-header__container-content">
          {selectedCompanyInformation === undefined && user.app_metadata.role.roleID === 3 ? (
            <span
              className="skeleton-box"
              style={{ width: '140px', height: '0.7rem', marginRight: '15px' }}
            ></span>
          ) : user.app_metadata.role.roleID === 3 ? (
            <Button>
              <p
                className="btn-text"
                onClick={() => {
                  setSelectCompanyDrawer(true);
                }}
              >
                {selectedCompanyInformation && selectedCompanyInformation.name.toUpperCase()}
                <span>
                  <Icon type="caret-down" />
                </span>
              </p>
            </Button>
          ) : (
            <>
              {user.user_metadata.proxyUsers &&
              user.user_metadata.proxyUsers.length &&
              loggedUserAccesses.length ? (
                <Select
                  placeholder="Select User"
                  defaultValue={loggedUserAccesses[0].user_id}
                  onChange={(id: string) => setTeamAccessUserIdAction(id)}
                  style={{ minWidth: 150 }}
                >
                  <Select.OptGroup label="Login as">
                    {loggedUserAccesses.map((user, index) => (
                      <Select.Option key={index} value={user.user_id}>
                        {`${user.name} (Tier - ${user.tier})`}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              ) : null}

              <p className="btn-text" style={{ margin: '0px 20px' }}>
                {user.contractorCompany !== null
                  ? user.contractorCompany.companyName.toUpperCase()
                  : user.user_metadata.companyName.toLocaleUpperCase()}
              </p>
            </>
          )}

          <div className="main-header__container-logout">
            <img src={HeaderAvatarLogo} />
            <Dropdown overlay={menu}>
              <a
                className="main-header__container-dropdown_title"
                onClick={e => e.preventDefault()}
              >
                {user ? `${intlProvider.messages['sidemenu.avatar.hello']}! ${user.name}` : ''}
                <Icon type="down" />
              </a>
            </Dropdown>
          </div>

          <Drawer
            title="SWICH ACCOUNT"
            placement="right"
            visible={openSelectCompanyDrawer}
            onClose={() => setSelectCompanyDrawer(false)}
          >
            <Tabs className="main-header__tabs" defaultActiveKey="1">
              <TabPane tab="Active" key="1">
                <div className="content-container">
                  <div className="content-container__list">
                    {accounts.length > 0
                      ? accounts.map((account, index) =>
                          account.isActive ? (
                            <p
                              key={index}
                              onClick={() => {
                                setAccountIDAction(account.accountID);
                                setSelectCompanyDrawer(false);
                              }}
                              className="main-header__drawer"
                            >
                              {account.name}
                            </p>
                          ) : null,
                        )
                      : null}
                  </div>
                </div>
              </TabPane>

              <TabPane tab="Inactive" key="2">
                <div className="content-container">
                  <div className="content-container__list">
                    {accounts.length > 0
                      ? accounts.map((account, index) =>
                          !account.isActive ? (
                            <p
                              key={index}
                              onClick={() => {
                                setAccountIDAction(account.accountID);
                                setSelectCompanyDrawer(false);
                              }}
                              className="main-header__drawer"
                            >
                              {account.name}
                            </p>
                          ) : null,
                        )
                      : null}
                  </div>
                </div>
              </TabPane>
            </Tabs>

            <Button type="primary" onClick={() => handleToggleModal(false)}>
              ADD COMPANY
            </Button>

            <AddAccountComponent
              title={'Create Account'}
              validateKeys={validateKeys}
              account={isCreatingCompany === false ? selectedCompanyInformation : null}
              isModalVisible={isModalVisible}
              handleCloseModal={handleToggleModal}
              handleSaveAccount={!!isCreatingCompany ? handleSaveAccount : handleUpdateAccount}
            />
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default MainHeaderComponent;
