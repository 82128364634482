import React, { memo, useEffect } from 'react';
import CorrectiveActionsComponent from '@back-office/corrective-actions/view/corrective-actions.component';
import { connect } from 'react-redux';
import {
  fetchCATriggers,
  fetchCASeveritys,
  fetchCAStatus,
  fetchCATypes,
  UpdateUnderUserCA,
} from '@back-office/corrective-actions/state/corrective-actions.actions';
import { IAppState } from '@rdx/root.reducer';
import { fetchContractorsAction } from '@app/admin/accounts/contractors/state/contractors.actions';
import { useSelector } from 'react-redux';
import { CorrectiveActionsType, ProjectType, UserType } from '@shared/types/backend.types';
import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { fetchAccountsProjectAction } from '@app/back-office/projects/state/projects.actions';
import { getProgramsProjects } from '@app/back-office/professional.selector';
import {
  getCAIsLoading,
  getUserCorrectiveActions,
} from '@back-office/corrective-actions/state/corrective-actions.selector';
import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';
import { getAccountID } from '@app/admin/invite/state/invite.selector';
import { fetchAccountCA } from '@app/admin/corrective-actions/state/corrective-actions.actions';

interface ICorrectiveActionsContainer {
  projects: ProjectType[];
  selectedAccountID?: number;
  team: UserType[];
  correctiveActions: CorrectiveActionsType[];
  isLoading: boolean;
  fetchCorrectiveActions: (accountID: number) => void;
  fetchTeamAction: (accountID: number) => void;
  fetchAccountsProjectAction: (accountID: number) => void;
  fetchContractorsAction: (accountID: number) => void;
  fetchCAServeritysAction: () => void;
  fetchCAStatusAction: () => void;
  fetchCATriggersAction: () => void;
  fetchCATypesAction: () => void;
}

const CorrectiveActions = (props: ICorrectiveActionsContainer) => {
  const {
    team,
    isLoading,
    selectedAccountID,
    projects,
    fetchTeamAction,
    fetchCorrectiveActions,
    fetchAccountsProjectAction,
    fetchContractorsAction,
    fetchCAServeritysAction,
    fetchCAStatusAction,
    fetchCATriggersAction,
    fetchCATypesAction,
  } = props;

  const { correctiveActions } = useSelector((state: IAppState) => state.admin);

  useEffect(() => {
    if (selectedAccountID) {
      fetchCorrectiveActions(selectedAccountID);
      fetchTeamAction(selectedAccountID);
      fetchAccountsProjectAction(selectedAccountID);
      fetchContractorsAction(selectedAccountID);
      fetchCAServeritysAction();
      fetchCAStatusAction();
      fetchCATriggersAction();
      fetchCATypesAction();
    }
  }, [selectedAccountID]);

  return (
    <>
      <CorrectiveActionsComponent
        team={team}
        correctiveActions={correctiveActions.items}
        projects={projects}
        isLoading={isLoading}
      />
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  selectedAccountID: getAccountID(state),
  team: getAccountsTeam(state),
  correctiveActions: getUserCorrectiveActions(state),
  projects: getProgramsProjects(state),
  isLoading: getCAIsLoading(state),
});

const mapDispatchToProps = {
  fetchCorrectiveActions: fetchAccountCA.request,
  fetchAccountsProjectAction: fetchAccountsProjectAction.request,
  fetchCAServeritysAction: fetchCATriggers.request,
  fetchCAStatusAction: fetchCASeveritys.request,
  fetchCATriggersAction: fetchCAStatus.request,
  fetchCATypesAction: fetchCATypes.request,
  fetchTeamAction: fetchAccountUsersAction.request,
  updateUserCorrectiveAction: UpdateUnderUserCA.request,
  fetchContractorsAction: fetchContractorsAction.request,
};

export const CorrectiveActionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(CorrectiveActions));
