import React, { useState, SetStateAction } from 'react';

import { AccountsSafetyPlansComponent } from '@app/admin/accounts/safety-plans/view/safety-plans.component';
import { FormInstanceSelected } from '@app/admin/accounts/safety-plans/safety-plans';
import { SalesFormContainer } from '../contact-sales/state/sales-form.container';

import './back-office.safety-programs.styles.scss';

export interface IPlansBOProps {
  plans: any;
  questionsIsLoading?: boolean;
  questions?: any;
  isLoading: boolean;
  //TODO: add new model to safetyPlan
  handleSwitchChecked: (isValid: boolean, form: FormInstanceSelected, safetyPlan: any) => void;
  fecthSelectedFormQuestion?: (formID: string) => void;

}

export const BackOfficePlansComponent = (props: IPlansBOProps) => {
  const { plans, questions, questionsIsLoading, isLoading } = props;
  const { handleSwitchChecked, fecthSelectedFormQuestion } = props;

  const [showModal, setShowModal] = useState<SetStateAction<any>>(false);
  const [safetyPlanSelected, setSafetyPlanSelected] = useState(undefined);

  const handleShowModal = (item: any) => {
    setShowModal(true);
    setSafetyPlanSelected(item);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="back-office_plans sa__container">
      <div style={{ width: '100%' }} className='sa__box'>
        {showModal &&
          <SalesFormContainer
            showSalesModal={showModal}
            handleCloseSalesModal={handleCloseModal}
            item={safetyPlanSelected}
            setShowSalesModal={setShowModal}
          />}
        <AccountsSafetyPlansComponent
          title={'Safety Programs'}
          safetyPlans={plans}
          questions={questions}
          questionsIsLoading={questionsIsLoading}
          isLoading={isLoading}
          role={'admin'}
          handleSwitchChecked={handleSwitchChecked}
          fecthSelectedFormQuestion={fecthSelectedFormQuestion}
          handleShowModal={handleShowModal}
        />
      </div>
    </div>
  );
};
