import React, { FormEvent } from 'react';

import { Classes } from 'jss';
import { ThemeType } from '@shared/types/theme.type';
import { AccountType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { FormComponentProps } from 'antd/es/form';

import { useIntl } from 'react-intl';

import { Modal, Button, Form, Input } from 'antd';
import { ImageUploaderComponent } from '../../../../shared/components/img-uploader/img-uploader.component';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import './add-account.styles.scss';

export interface IAddAccountProps {
  account: AccountType;
  //TODO: add type
  form: any;
  isModalVisible: boolean;
  classes: Classes;
  isLoading?: boolean;
  imageValidation?: any;
  title?: string;
  validateKeys: (event: any) => void;
  handleCloseModal: (isUpdating: boolean, form?: WrappedFormUtils) => void;
  handleSaveAccount: (form: WrappedFormUtils) => void;
  handleImageDrop?: (file: any, form: FormComponentProps) => void;
  handleImageStatusChange?: (status: string) => void;

  user: any;
}

const AddAccount = (props: IAddAccountProps) => {
  const { account, form, isModalVisible, isLoading, imageValidation, title, user } = props;
  const { handleCloseModal, handleSaveAccount, validateKeys } = props;
  const { handleImageDrop, handleImageStatusChange } = props;
  const { formLabelStyles } = props.classes;
  const { getFieldDecorator } = form;
  const intlProvider = useIntl();

  return (
    <Modal
      title={
        title ? <span>{title}</span> : <span>{intlProvider.messages['modal.add.company.title']}</span>
      }
      visible={isModalVisible}
      closeIcon={<i className="fas fa-times" onClick={() => handleCloseModal(false, form)} />}
      footer={
        <div className="d-flex justify-center">
          <Button
            className="btn__supervisors btn--cancel"
            onClick={() => handleCloseModal(false, form)}
          >
            {intlProvider.messages['buttons.cancel']}
          </Button>
          <Button
            className="btn__supervisors btn--white"
            onClick={() => {
              handleSaveAccount(form)
            }}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      }
    >
      <div >
        {handleImageDrop && handleImageStatusChange && (
          <div className="custome-dropzone">
            <ImageUploaderComponent form={form} handleOnChangeStatus={handleImageStatusChange} handleImageDrop={handleImageDrop} />
            {imageValidation.isValid === false && (
              <span className="custome-dropzone_error">
                {imageValidation.message}
              </span>
            )}
            {imageValidation.isValid === true && (
              <span className="custome-dropzone_success">
                {imageValidation.message}
              </span>
            )}
          </div>
        )}
      </div>
      <Form
        className="d-flex flex-wrap sm-w-100"
        layout="vertical"
        onSubmit={(event: FormEvent<HTMLFormElement>) => { }}
        hideRequiredMark
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('name', {
              initialValue: account ? account.name : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.requiredCompany'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.companyName']}`}
                disabled={!!account}
                required
              />,
            )}
          </Form.Item>
          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('address', {
              initialValue: account ? account.address : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.requiredCompanyAddress'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.companyAddress']}`}
                required
              />,
            )}
          </Form.Item>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('firstName', {
              initialValue: account ? account.firstName : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.firstName'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.firstName']}`}
                required
              />,
            )}
          </Form.Item>
          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('lastName', {
              initialValue: account ? account.lastName : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.lastName'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.lastName']}`}
                required
              />,
            )}
          </Form.Item>
        </div>
        <div style={{ display: 'flex', width: '100%' }}>

          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('city', {
              initialValue: account ? account.city : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.city'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.city']}`}
                required
              />,
            )}
          </Form.Item>

          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('contactState', {
              initialValue: account ? account.contactState : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.contactState'],
                },
              ],
            })(
              <Input
                className="input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.contactState']}`}
                style={{ width: '100%' }}
              />,
            )}
          </Form.Item>
          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('country', {
              initialValue: account ? account.country : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.country'],
                },
              ],
            })(
              <Input
                className="input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.country']}`}
                style={{ width: '100%' }}
              />,
            )}
          </Form.Item>
        </div>

        <div style={{ display: 'flex', width: '100%' }}>

          <Form.Item className={classnames('input--width-50 inputs-styles', formLabelStyles)}>
            {getFieldDecorator('contactPhone', {
              initialValue: account ? account.contactPhone : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.requiredContactPhone'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.contactPhone']}`}
                onKeyPress={(e: any) => {
                  validateKeys(e);
                }}
                required
              />,
            )}
          </Form.Item>
          <Form.Item className={classnames('input--large-width inputs-styles', formLabelStyles)}>
            {getFieldDecorator('contactEmail', {
              initialValue: account ? account.contactEmail : '',
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: intlProvider.messages['inputs.requiredEmailAddress'],
                },
              ],
            })(
              <Input
                className="input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.contactEmail']}`}
                style={{ width: '100%' }}
              />,
            )}
          </Form.Item>
        </div>

        {user != undefined && user.app_metadata.role.roleID != 3 ? '' :
          <Form.Item label={intlProvider.messages['inputs.noLicenses']} className={classnames('input--width-100 inputs-styles mb-0 pb-0', formLabelStyles)}>
            {getFieldDecorator('noLicenses', {
              initialValue: account ? account.noLicenses : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.noLicenses'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={`${intlProvider.messages['inputs.noLicenses']}`}
                onKeyPress={(e: any) => {
                  validateKeys(e);
                }}
                required
              />,
            )}
          </Form.Item>}
        {
          user != undefined && user.app_metadata.role.roleID != 3 ? '' :
            <div className='flex' style={{ width: "100%", margin: "10px 0px" }}>
              <Form.Item label="Submissions PowerBI Key" className={classnames('input--width-50 inputs-styles mb-0 pb-0', formLabelStyles)}>
                {getFieldDecorator('powerBIKey', {
                  initialValue: account ? account.powerBIKey : '',
                  rules: [
                    {
                      required: false,
                      message: intlProvider.messages['inputs.powerBIKey'],
                    },
                  ],
                })(
                  <Input
                    className="input--width-100 input--medium-height input--transparent"
                    placeholder={`${intlProvider.messages['inputs.powerBIKey']}`}
                    onKeyPress={(e: any) => {
                      validateKeys(e);
                    }}
                    required
                  />,
                )}
              </Form.Item>

              <Form.Item label={"Corrective Acitons PowerBI Key"} className={classnames('input--width-50 inputs-styles mb-0 pb-0', formLabelStyles)}>
                {getFieldDecorator('caPowerBIkey', {
                  initialValue: account ? account.caPowerBIkey : '',
                  rules: [
                    {
                      required: false,
                      message: "Corrective Acitons PowerBI Key is required",
                    },
                  ],
                })(
                  <Input
                    className="input--width-100 input--medium-height input--transparent"
                    placeholder='Corrective Action PowerBI Key'
                    onKeyPress={(e: any) => {
                      validateKeys(e);
                    }}
                    required
                  />,
                )}
              </Form.Item>
            </div>
        }

      </Form>
    </Modal>
  );
};

const styles = (theme: ThemeType) => ({
  modalTitleStyles: {
    color: theme.grey.fourth,

  },
  formLabelStyles: {
    '& .ant-form-item-label label': {
      ...theme.inputs.label,
      color: theme.grey.fourth,
    },
  },
});

export const AddAccountStyled: any = injectSheet(styles)(AddAccount);
export const AddAccountComponent: any = Form.create({ name: 'addSupervisor' })(AddAccountStyled);
