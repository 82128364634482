export const QUESTION_TYPE: { [index: string]: any } = {
  1: 'Open Question',
  3: 'Yes / No',
  4: 'Risk Question',
  6: 'Informative',
  7: 'Signature',
  8: 'Time',
  9: 'Date',
  10: 'Selection',
};
