import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';

import _ from 'lodash';
import { UserType } from '@shared/types/backend.types';

export const _getAccountsTeamSorter = (state: IAppState) => state.admin.accounts.team.sorter;
export const getAccountsTeamSorter = createSelector(
  [_getAccountsTeamSorter],
  sorter => sorter,
);

export const _getAccountsTeam = (state: IAppState) => state.admin.accounts.team.items;

export const getAccountsTeam = createSelector(
  _getAccountsTeam,
  _getAccountsTeamSorter,
  //TODO: add types
  (team, sorter): any => {
    return _.orderBy(getList(team), [sorter.key], [sorter.order as any]);
  },
);

export const _getTeamOwner = (state: IAppState) => {
  const { items } = state.admin.accounts.team;

  const team = getList(items);
  return team.filter((user: UserType) => user.user_metadata.reportsToUserID === '')[0];
};

export const getTeamOwner = createSelector(
  [_getTeamOwner],
  owner => owner,
);

export const _getUserCertificates = (state: IAppState) => state.admin.accounts.team.certificates.items;
export const getUserCertificates = createSelector(
  [_getUserCertificates],
  certificates => certificates,
);

export const _getCertificatesLoading = (state: IAppState) => state.admin.accounts.team.certificates.isLoading;
export const getCertificatesLoading = createSelector(
  [_getCertificatesLoading],
  isCertificatesLoading => isCertificatesLoading,
);

export const _getMailCertificateLoading = (state: IAppState) => state.admin.accounts.team.certificates.isCertificateMailing;
export const getMailCertificatesLoading = createSelector(
  [_getMailCertificateLoading],
  isCertificateMailing => isCertificateMailing,
);

export const _getAccountsTeamIsLoading = (state: IAppState) => state.admin.accounts.team.isLoading;
export const getAccountsTeamIsLoading = createSelector(
  [_getAccountsTeamIsLoading],
  isLoading => isLoading,
);
