import React, { ReactNode } from 'react';

import { ThemeType } from '@shared-types/theme.type';

import { Classes } from 'jss';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import greenLogoImage from '@assets/logo/logo.png';
import cityAside from '@assets/city-aside-2x.png';

import '@session/session.styles.scss';
import './register.styles.css';

export interface IAsideRegisterProps {
  classes: Classes;
  children: ReactNode;
  image?: string;
}

const AsideRegister = (props: IAsideRegisterProps) => {
  const { classes, children } = props;
  const { asideContainerStyles, asideStyles, childrenContainerStyles, headerStyles } = classes;

  return (
    <div className={classnames(asideContainerStyles, 'main-div')}>
      <header className={classnames(headerStyles, 'header')}>
        <img src={greenLogoImage} className={classnames('header-logo')} alt="Safety Assist" />
      </header>
      <aside className={classnames(asideStyles, 'aside')}>
        <img className="image--aside" src={cityAside} alt="Welcome to Safety Assist" />
      </aside>
      <div className={classnames(childrenContainerStyles, 'children')}>{children}</div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  asideContainerStyles: {
    display: 'flex',
  },
  asideStyles: {
    position: 'relative',
    width: '35%',
    height: '100vh',
    background: theme.green.aside,
    overflow: 'hidden',
  },
  logoStyles: {
    margin: 40,
  },
});

export const AsideRegisterComponent = injectSheet(styles)(AsideRegister);
