import { takeLatest, put, call, all } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import {
  changeFormActiveStatusAction,
  changeSubFormActiveStatusAction,
  fetchAccountsPlansAction,
  addAccountFormAction,
  deleteAccountFormAction,
  changeAccountSafetyPlanActiveStatusAction,
  deleteAccountSafetyPlanAction,
} from './safety-plans.actions';

import { fetchService, addService, deleteService } from '@rdx/services.saga';
import { arrayToObject } from '@shared/helpers/state-caster';

function* changeSubFormActiveStatusRequest(action: AnyAction) {
  try {
    const { isValid, form, safetyPlanID, accountID, formID } = action.payload;
    const { id } = form;

    const addAccountFormEndpoint = addAccountFormAction.getEndpoint({ accountID });
    const deleteAccountFormEndpoint = deleteAccountFormAction.getEndpoint({
      formID: id,
      accountID,
      safetyPlanID,
    });
    if (!isValid) {
      yield call(deleteService, deleteAccountFormEndpoint);
      yield put(changeSubFormActiveStatusAction.success({ id, safetyPlanID, isValid, formID }));
    } else {
      const bodyData = [
        {
          formID: id,
          safetyPlanID,
        },
      ];
      yield call(addService, addAccountFormEndpoint, bodyData);
      yield put(changeSubFormActiveStatusAction.success({ id, safetyPlanID, isValid, formID }));
    }
  } catch (error) { }
}
function* changeFormActiveStatusRequest(action: AnyAction) {
  try {
    const { isValid, form, safetyPlanID, accountID } = action.payload;
    const { id } = form;

    const addAccountFormEndpoint = addAccountFormAction.getEndpoint({ accountID });
    const deleteAccountFormEndpoint = deleteAccountFormAction.getEndpoint({
      formID: id,
      accountID,
      safetyPlanID,
    });
    if (!isValid) {
      yield call(deleteService, deleteAccountFormEndpoint);
      yield put(changeFormActiveStatusAction.success({ id, safetyPlanID, isValid }));
    } else {
      const bodyData = [
        {
          formID: id,
          safetyPlanID,
        },
      ];
      yield call(addService, addAccountFormEndpoint, bodyData);
      yield put(changeFormActiveStatusAction.success({ id, safetyPlanID, isValid }));
    }
  } catch (error) { }
}

function* fetchAccountsPlansRequest(action: AnyAction) {
  let accountID;
  if (action.payload) { accountID = action.payload } else { accountID = action };
  try {
    const endpoint = fetchAccountsPlansAction.getEndpoint({ accountID: accountID });
    let data = yield call(fetchService, endpoint);

    if (data.length > 0) {

      data = data.map((safetyPlan: any) => {

        safetyPlan.forms.map((form: any) => {
          if (form.subForms)
            form.subForms = arrayToObject(form.subForms, 'id');
        })

        return safetyPlan;
      });

      data = data.map((safetyPlan: any) => {

        if (safetyPlan.safetyPlanID === 97) {
          let orderedForms = safetyPlan.forms.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
          safetyPlan.forms = arrayToObject(orderedForms, 'id');
        } else {
          safetyPlan.forms = arrayToObject(safetyPlan.forms, 'id');
        }

        return safetyPlan;
      });

      const firstIndexID = data[0].safetyPlanID;

      yield put(
        fetchAccountsPlansAction.success({
          safetyPlans: data,
          firstIndexID,
        }),
      );
    } else {
      yield put(
        fetchAccountsPlansAction.success({
          safetyPlans: {},
          firstIndexID: undefined,
        }),
      );
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fetchAccountsPlansAction.failure({ title: 'Error while retrieving plans' }));
    }
  }
}

function* changeAccountSafetyPlanActiveStatusRequest(action: AnyAction) {
  const { accountID, safetyPlanID, isEnable, safetyPlans } = action.payload;
  try {
    const deleteAccountSafetyPlanEndpoint = deleteAccountSafetyPlanAction.getEndpoint({
      accountID,
      safetyPlanID,
    });
    yield call(deleteService, deleteAccountSafetyPlanEndpoint);
    // call fetch
    yield call(fetchAccountsPlansRequest, accountID);

    // Get forms by SafetyPlanID:
    let safetyPlanByID = safetyPlans.filter((safetyPlan: any) => safetyPlan.safetyPlanID === safetyPlanID);
    let formsUpdated = safetyPlanByID.forms.map((form: any) => {
      if (form.isSelected === true) {
        form.isSelected = isEnable;
      }
      return form;
    });
    let formsObject = arrayToObject(formsUpdated, 'id');
    // Update state with forms that where set to isSelected = false
    yield put(changeAccountSafetyPlanActiveStatusAction.success({
      isEnable,
      safetyPlanID,
      formsObject,
    }));
  } catch (error) {

  }
}

export function* accountsSafetyPlansSaga() {
  yield takeLatest(fetchAccountsPlansAction.REQUEST, fetchAccountsPlansRequest);
  yield takeLatest(changeSubFormActiveStatusAction.REQUEST, changeSubFormActiveStatusRequest);
  yield takeLatest(changeFormActiveStatusAction.REQUEST, changeFormActiveStatusRequest);
  yield takeLatest(changeAccountSafetyPlanActiveStatusAction.REQUEST, changeAccountSafetyPlanActiveStatusRequest);
}
