import { FETCH_ACCOUNT_CA_API } from '@constants/endpoints';
import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';



export const FETCH_ROLES = 'FETCH_ROLES';
export const SUBMIT_INVITE = 'SUBMIT_INVITE';

export const submitInviteAction = createActionRoutine(SUBMIT_INVITE);
export const fetchCompaniesAction = createActionRoutine(FETCH_COMPANIES);
export const fetchPackagesAction = createActionRoutine(FETCH_PACKAGES);
export const setAccountIDAction = createActionRoutine(SET_ACCOUNT_ID);
