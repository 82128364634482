import {
  addContractorAction,
  updateContractorAction,
  removeContractorAction,
  fetchContractorsAction,
  addCachedContractorAction,
  removeContractorRowAction,
} from './contractors.actions';

import { IAdminState } from '@app/admin/admin.reducer';
import { AnyAction } from 'redux';
import { combineActions } from 'redux-actions';
import update from 'immutability-helper';



export const contractorsReducer = {
  [fetchContractorsAction.REQUEST]: (state: IAdminState) => (
    update(state, {
      accounts: {
        contractors: {
          isLoading: { $set: true },
        },
      },
    })),
    
  [fetchContractorsAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => (
    update(state, {
      accounts: {
        contractors: {
          items: { $set: payload.contractors },
          isLoading: { $set: false },
        },
      },
    })),

  [fetchContractorsAction.FULFILL]: (state: IAdminState) => (
    update(state, {
      accounts: {
        contractors: {
          items: { $set: {} },
          isLoading: { $set: false },
        },
      },
    })),

  [removeContractorRowAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) => {
    const { key } = payload;
    let updatedContractors: any = {
      ...state.accounts.contractors.items
    };

    delete updatedContractors[key];

    return update(state, {
      accounts: {
        contractors: {
          items: { $set: updatedContractors },
          contractorCachedCounter: { $set: state.accounts.contractors.contractorCachedCounter - 1 },
        },
      }
    })
  },

  [addContractorAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const { key, contractor } = payload;

    let updatedContractors: any = {
      ...state.accounts.contractors.items,
      [contractor.contractorCompanyID]: contractor,
    };

    delete updatedContractors[key];

    return update(state, {
      accounts: {
        contractors: {
          items: { $set: updatedContractors },
        },
      }
    })
  },

  [addCachedContractorAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) => {
    const updatedContractor: any = {
      ...state.accounts.contractors.items,
      [payload.contractor.key]: payload.contractor,
    };
    return update(state, {
      accounts: {
        contractors: {
          items: { $set: updatedContractor },
          contractorCachedCounter: { $set: state.accounts.contractors.contractorCachedCounter + 1 },
        },
      }
    })
  },

  [updateContractorAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const updatedContractor: any = {
      ...state.accounts.contractors.items,
      [payload.contractor.key]: payload.contractor,
    };
    return update(state, {
      accounts: {
        contractors: {
          items: { $set: updatedContractor },
        },
      }
    })
  },

  [removeContractorAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    let updatedContractors: any = { ...state.accounts.contractors.items };
    delete updatedContractors[payload.contractorCompanyID];

    return update(state, {
      accounts: {
        contractors: {
          items: { $set: updatedContractors },
        },
      }
    })
  },
  [combineActions(
    fetchContractorsAction.FAILURE,
    addContractorAction.FAILURE,
    updateContractorAction.FAILURE,
    removeContractorAction.FAILURE,
  ) as any]: (state: IAdminState, { payload }: AnyAction) => (
    update(state, {
      accounts: {
        contractors: {
          isLoading: { $set: false },
          errorMessages: {
            ...state.errorMessages,
            [payload.error.code]: payload.error,
          },
        },
      },
    })
  ),
}

