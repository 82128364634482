// REACT ESSENTIALS
import React from 'react';

// ANTD COMPONENTS
import {
  Input,
  Popconfirm,
  Form,
  Tag,
  Select,
  Icon,
  Tooltip,
  Button,
  Modal,
  Tree,
  Empty,
  Badge,
  Menu,
  Dropdown,
  Switch,
} from 'antd';

// TYPESCRIPT TYPES
import { UserType } from '@shared/types/backend.types';

// APP TABLE COMPONENT
import SATable from '@components/table/sa.table';
import AddProject from '@components/modal/add-project/add.project';
import { reduce } from 'lodash';

interface IProjectsTableProps {
  projects: any;
  team: UserType[];
  tree: any;
  orgTree: any;
  form: any;
  editingKey: string;
  isLoading: boolean;
  openAddProjectModal: boolean;
  setUserId: any;
  handleAddProject: (values: any) => void;
  onFilterChange: (values: any) => void;
  toggleAddProjectModal: (value: React.SetStateAction<boolean>) => void;
  setEditingKey: (value: React.SetStateAction<string>) => void;
  handleUpdateProject: (form: any, record: any) => void;
  handleRemoveProject: (projectID: number) => void;
}

const Table = (props: IProjectsTableProps) => {
  const {
    projects,
    form,
    team,
    orgTree,
    tree,
    isLoading,
    editingKey,
    openAddProjectModal,
    setUserId,
    onFilterChange,
    handleAddProject,
    toggleAddProjectModal,
    handleUpdateProject,
    setEditingKey,
    handleRemoveProject,
  } = props;

  const { getFieldDecorator } = form;

  const [openProjectAccessModal, setProjectAccessModal] = React.useState(false);
  const [openOrganizationTreeModal, setOrganizationTreeModal] = React.useState(false);
  const isEditing = React.useCallback((record: any) => record.key === editingKey, [editingKey]);

  const columns = React.useMemo(
    () => [
      {
        title: <b>Project Number</b>,
        dataIndex: 'number',
        editable: true,
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => Number(a.number) - Number(b.number),
        render: (text: string) => <b>{text}</b>,
      },
      {
        title: <b>Project Name</b>,
        dataIndex: 'name',
        editable: true,
      },
      {
        title: <b>Project Address</b>,
        dataIndex: 'address',
        editable: true,
      },
      {
        title: <b>Project Status</b>,
        dataIndex: 'isActive',
        render: (status: boolean) =>
          status ? (
            <Tag style={{ fontWeight: 600 }} color="green">
              Active
            </Tag>
          ) : (
            <Tag style={{ fontWeight: 600 }} color="red">
              Inactive
            </Tag>
          ),
      },
      {
        title: <b>Project Owner (Created By) </b>,
        dataIndex: 'assignedToUserID',
        editable: true,
        render: (id: string) => {
          const user = team.find((user: UserType) => user.user_id === id);
          if (!user) return <b className="sa-clickable">N/A</b>;
          return <span className="sa-title">{user.name}</span>;
        },
      },
      {
        dataIndex: 'operation',
        render: (_: any, record: any) => {
          return isEditing(record) ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Icon
                className="table-sa__actionIcon"
                theme="filled"
                type="save"
                onClick={() => handleUpdateProject(form, record)}
                style={{ marginRight: 8 }}
              />

              <Popconfirm title="Sure to cancel?" onConfirm={() => setEditingKey('')}>
                <Icon className="table-sa__actionIcon" type="close" />
              </Popconfirm>
            </div>
          ) : (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item>
                    <div
                      className="team-actions-dropdown-content"
                      onClick={() => {
                        setProjectAccessModal(true);
                        setUserId(record.assignedToUserID);
                      }}
                    >
                      <Icon className="table-sa__actionIcon" type="eye" />
                      <b>View Project Tree</b>
                    </div>
                  </Menu.Item>

                  <Menu.Item>
                    <div
                      className="team-actions-dropdown-content"
                      onClick={() => {
                        setEditingKey(record.key);
                      }}
                    >
                      <Icon className="table-sa__actionIcon" type="edit" />
                      <b>Edit Project</b>
                    </div>
                  </Menu.Item>

                  <Menu.Item>
                    <Popconfirm
                      placement="topRight"
                      title={
                        record.isActive
                          ? 'Are you sure you want to remove this project?'
                          : 'Do you want to make this project active?'
                      }
                      onConfirm={() => handleRemoveProject(record.key)}
                      okText="Yes"
                    >
                      <div className="team-actions-dropdown-content">
                        <Icon className="table-sa__deleteIcon" type="delete" />
                        <b>Project Status &nbsp;</b>
                        <Switch
                          defaultChecked={record.isActive}
                          checked={record.isActive}
                          size="small"
                        />
                      </div>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Icon type="more" />
              </a>
            </Dropdown>
          );
        },
      },
    ],
    [editingKey, team],
  );

  const getEditableFeilds = React.useCallback(
    (dataIndex: string, title: string, record: any) => {
      if (dataIndex === 'assignedToUserID') {
        return (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please assign the project to user!`,
                },
              ],
              initialValue: record[dataIndex] ? record[dataIndex] : 'Unassigned',
            })(
              <Select style={{ width: '100%' }}>
                <Select.OptGroup label="Select User">
                  {team.map((user: UserType, index: number) => (
                    <Select.Option key={index} value={user.user_id}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              </Select>,
            )}
          </Form.Item>
        );
      }
      return (
        <Form.Item style={{ margin: 0 }}>
          {getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ],
            initialValue: record[dataIndex],
          })(<Input style={{ width: '100%' }} />)}
        </Form.Item>
      );
    },
    [projects, team],
  );

  return (
    <>
      <div className="sa__container">
        <div className="sa__box">
          <div className="sa__box-heading">
            <h4 className="sa__box-heading-title">Projects</h4>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button type="primary" onClick={() => toggleAddProjectModal(true)}>
                Create Project
              </Button>
              <Button type="primary" onClick={() => setOrganizationTreeModal(true)}>
                Organization Tree
              </Button>
            </div>
          </div>

          <div className="sa__box-content">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                style={{ width: 200, paddingBottom: '10px' }}
                defaultValue="active"
                onChange={(value: string) => onFilterChange(value)}
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="active">Active</Select.Option>
                <Select.Option value="inactive">Inactive</Select.Option>
              </Select>
            </div>
            <SATable
              dataSource={projects}
              columns={columns}
              isLoading={isLoading}
              isEditing={isEditing}
              getEditableFeilds={getEditableFeilds}
            />
          </div>
        </div>
      </div>

      <AddProject
        open={openAddProjectModal}
        toggleModal={toggleAddProjectModal}
        handleAddProjet={handleAddProject}
      />

      <Modal
        title="Project Access"
        visible={openProjectAccessModal}
        cancelText="Close"
        cancelButtonProps={{ type: 'danger' }}
        okButtonProps={{ hidden: true }}
        onCancel={() => setProjectAccessModal(false)}
      >
        {tree.length ? (
          <div style={{ maxHeight: 500, overflowY: 'auto', overflowX: 'hidden' }}>
            <Tree defaultExpandAll={true} showLine={true} selectable={false}>
              {tree}
            </Tree>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Empty" />
        )}
      </Modal>

      <Modal
        title="Organization Tree"
        visible={openOrganizationTreeModal}
        cancelText="Close"
        cancelButtonProps={{ type: 'danger' }}
        okButtonProps={{ hidden: true }}
        onCancel={() => setOrganizationTreeModal(false)}
      >
        {orgTree.length ? (
          <div style={{ maxHeight: 500, overflowY: 'auto', overflowX: 'hidden' }}>
            <Tree defaultExpandAll={true} showLine={true} selectable={false}>
              {orgTree}
            </Tree>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Empty" />
        )}
      </Modal>
    </>
  );
};

const ProjectTableComponent: any = Form.create()(Table);

export default ProjectTableComponent;
