import { PackageSeatDetail, UserType } from '@shared/types/backend.types';
import { Button, Form, Modal, Select } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import React, { FormEvent } from 'react'

interface IRoleChangeProps {
    user?: UserType;
    form: WrappedFormUtils;
    seatsInfo: PackageSeatDetail[];
    openModal: boolean;
    toggleModal: (value: React.SetStateAction<boolean>) => void;
    handleRoleChange: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
}

const RoleChange = (props: IRoleChangeProps) => {
    const { user, seatsInfo, form, openModal, toggleModal, handleRoleChange } = props;

    const { getFieldDecorator, } = form

    return (
        <Modal
            title="Role Change"
            visible={openModal}
            footer={null}
            onCancel={() => toggleModal(false)}
        >
            <Form
                hideRequiredMark
                onSubmit={(event: FormEvent<HTMLFormElement>) => handleRoleChange(event, form)} >
                <Form.Item
                    label={<b>Role</b>}
                >
                    {getFieldDecorator('roleID', {
                        rules:
                            [
                                {
                                    type: 'number',
                                    required: true,
                                    message: "Please select a role",
                                },
                            ],
                    })(
                        <Select
                            placeholder="Select Role"
                            className="input--medium-height"
                        >

                            <Select.OptGroup key="1" label="Select Role">
                                {
                                    user && user.app_metadata.role.roleID === 1
                                        ? <Select.Option value={2}>Administrator</Select.Option>
                                        : <Select.Option value={1}>User</Select.Option>
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    label={<b>License Type</b>}
                >
                    {getFieldDecorator('package', {
                        rules:
                            [
                                {
                                    type: 'string',
                                    required: true,
                                    message: "Please select License Type",
                                },
                            ],
                    })(
                        <Select
                            placeholder="Select License Type"
                            className="input--medium-height"
                        >
                            <Select.OptGroup key="1" label="Select License">
                                {
                                    seatsInfo.map((seat: PackageSeatDetail) => {
                                        return (
                                            user && user.app_metadata.role.roleID === 2 ? (
                                                seat.packageID !== "SA-ADMINISTRATOR" && (
                                                    <Select.Option key={seat.packageID} value={seat.packageID}>{`${seat.packageName} (${seat.availableSeats})`}</Select.Option>
                                                )
                                            ) : (
                                                seat.packageID === "SA-ADMINISTRATOR" && (
                                                    <Select.Option key={seat.packageID} value={seat.packageID}>{`${seat.packageName} (${seat.availableSeats})`}</Select.Option>
                                                )
                                            )
                                        );
                                    })
                                }
                            </Select.OptGroup>
                        </Select>
                    )}
                </Form.Item>

                <div className='ant-modal-body__form-footer'>
                    <Button
                        htmlType="submit"
                        type='primary'
                    >
                        Save
                    </Button>

                    <Button type='default' onClick={() => {
                        toggleModal(false)
                    }}> Cancel </Button>
                </div>
            </Form>
        </Modal>
    )
}

export const RoleChangeComponent: any = Form.create({ name: 'RoleChange' })(RoleChange);
