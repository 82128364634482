import Cookies from 'js-cookie';

export function setUserSession(obj: any) {
  localStorage.setItem('session', JSON.stringify(obj));
}

export function getUserSession() {
  const sessionStorage = localStorage.getItem('session');

  return JSON.parse(sessionStorage as any);
}

export function removeUserSession() {
  localStorage.removeItem('session');
}

export function getSessionCookie(key: string): string | null | undefined {
  const session = Cookies.get('sessionKey');

  return session;
}
