import React, { useEffect, useMemo } from 'react';
import PtpSubmissionsComponent from '../view/ptp-submissions.component';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '@rdx/root.reducer';
import { fetchPtpSubmissionAction } from './ptp-submissions.actions';
import { getList } from '@shared/helpers/state-caster';
import { getUniqueFilters } from '@shared/utils/utils';
import { Filter, PTPSubmission } from '@shared/types/backend.types';

const PtpSubmissionsContainer = () => {
  const { accountId } = useSelector((state: IAppState) => state.session);
  const {
    ptpSubmissions: { items, isLoading: isPtpSubmissionLoading },
  } = useSelector((state: IAppState) => state.professional.preTaskPlans);
  const dispatch = useDispatch();

  const ptpSubmissions: PTPSubmission[] = useMemo(() => getList(items), [items]);

  const submissionPtps = useMemo(() => getUniqueFilters(ptpSubmissions, 'ptp.title', 'ptp.id'), [
    ptpSubmissions,
  ]);

  const submissionUsers = useMemo(
    () => getUniqueFilters(ptpSubmissions, 'submittedByUserName', 'submittedByUserId'),
    [ptpSubmissions],
  );

  const submissionProjects = useMemo(
    () => getUniqueFilters(ptpSubmissions, 'ptp.project.name', 'ptp.project.projectID'),
    [ptpSubmissions],
  );

  const signaturesNames = useMemo(() => {
    // Use reduce to iterate over submissions and accumulate unique signature names
    const uniqueSignaturesSet = new Set();
    const uniqueSignatures = ptpSubmissions.reduce((accumulator: any[], submission) => {
      // Check if the submission has signatures
      if (submission.signatures.length) {
        // Iterate through each signature in the submission
        submission.signatures.forEach(signature => {
          // Add the signature name to the accumulator if it's not already present
          if (!uniqueSignaturesSet.has(signature.signatureName)) {
            uniqueSignaturesSet.add(signature.signatureName);
            accumulator.push({ text: signature.signatureName, value: signature.signatureName });
          }
        });
      }
      return accumulator;
    }, []);

    return uniqueSignatures;
  }, [ptpSubmissions]);

  useEffect(() => {
    if (accountId) dispatch(fetchPtpSubmissionAction.request({ query: { accountId } }));
  }, [accountId]);



  return (
    <PtpSubmissionsComponent
      ptpSubmissions={ptpSubmissions}
      isPtpSubmissionLoading={isPtpSubmissionLoading}
      submissionPtps={submissionPtps}
      submissionUsers={submissionUsers}
      submissionProjects={submissionProjects}
      signaturesNames={signaturesNames}
    />
  );
};

export default PtpSubmissionsContainer;
