import { IProfessionalProgramsState } from './state/programs.reducer';
import { UserType, ProjectType } from '@shared/types/backend.types';

import { getList } from '@shared/helpers/state-caster';
import { REGEX } from '@constants/regex';

export const validateCompany = (hasImage: boolean): boolean => {
  return true;
};

//indicate the minimal selected forms valid
export const minValidLength = 1;

export const validatePlans = (forms: IProfessionalProgramsState, totalPlans: number) => {
  const mappedForms = Object.values(forms);

  if (mappedForms.length < totalPlans) {
    return false;
  }

  //TODO: add types
  const areFormsValid = mappedForms.some((item: any) => item && item.length > 0);

  return areFormsValid;
};

export const validateProjects = (projects: any) => {
  const projectList = getList(projects);

  if (projectList.length === 0) {
    return false;
  }

  return projectList.every((project: ProjectType) => isProjectValid(project));
};

export const validateJobTitles = (programForms: IProfessionalProgramsState) => {
  const { jobTitles } = programForms;

  return jobTitles.length >= 2 && jobTitles.length <= 10;
};

export const validateTeam = (team: any) => {
  const teamList = getList(team);

  if (teamList.length < 1) {
    return false;
  }

  const isValid = teamList.every((item: UserType) => isEmployeeValid(item));
  
  return isValid;
};

export const getProjectsValidated = (projects: ProjectType[]): ProjectType[] => {
  return projects.map((project: ProjectType) => ({ ...project, isValid: isProjectValid(project) }));
};

export const getEmployeesValidated = (team: UserType[]) =>
  team.map((user: UserType) => ({ ...user, isValid: isEmployeeValid(user) }));

export const isEmployeeValid = (user: any) => {
  
  return (
    (user.given_name || user.givenName) !== '' &&
    (user.family_name || user.familyName) !== '' &&
    user.email !== '' &&
    (user.jobTitle !== undefined && user.jobTitle !== null && user.jobTitle !== '') &&
    isEmail(user.email)&&
    user.package !== undefined
  );
};

export const isEmail = (email: string) => {
  const emailRegex = REGEX.email;

  return emailRegex.test(String(email).toLowerCase());
};

export const isProjectValid = ({ address, name, number }: ProjectType) => {
  return address !== '' && name !== '' && number !== '';
};
