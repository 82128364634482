import React, { useState, useEffect, SetStateAction } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { AccountType, UserType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { AddAccountPayload, UpdateAccountPayload, AddPackagePayload, UpdatePackagePayload } from '../accounts';

import { connect } from 'react-redux';
import {
  getAccountCompanyInformation,
  getErrorMessages,
  getSeatsDetails,
} from './accounts.selector';

import { getAccountID } from '@app/admin/invite/state/invite.selector';
import { getAccountsTeam } from '../team/state/team.selector';
import { fetchAccountUsersAction } from '../team/state/team.actions';
import { filterAdminUsers } from '@shared/utils/filter-admin-users';


import {
  fetchAccountsAction,
  fetchSeatsInfoAction,
  createCompanyAction,
  updateCompanyAccountAction,
  patchAccountIsActivatedAction,
  createPackageAction,
  updatePackageAction,
} from './accounts.actions';

import { fetchContractorsAction } from '../contractors/state/contractors.actions';
import { getAccountsContractors } from '../contractors/state/contractors.selector';

import { AccountsComponent } from '../view/accounts.component';
import { REGEX } from '@constants/regex';
import Swal from 'sweetalert2';
import { baseTheme } from '@shared/themes/presets/base.theme';
import { Tree } from 'antd';

const { TreeNode } = Tree;


//TODO: add types
interface IAccountsProps {
  companyInformation: any;
  team: UserType[]
  companyContractors: []
  seatsInformation: any;
  accountID?: number;
  roles: any;
  errorMessages?: any;
  fetchAccounts: () => void;
  fetchContractorsAction: (AccountID: any) => void;
  fetchTeamAction: (selectedAccountID: number) => void;
  createCompanyAction: (payload: AddAccountPayload) => void;
  updateCompanyAction: (payload: UpdateAccountPayload) => void;
  patchAccountActivateAction: (payload: any) => void;
  fetchSeatsInfo: (payload: any) => void;
  createPackageAction: (payload: AddPackagePayload) => void;
  updatePackageAction: (payload: UpdatePackagePayload) => void;
}

const Accounts = (props: IAccountsProps) => {
  const { accountID, team, companyInformation, seatsInformation, roles, errorMessages, companyContractors } = props;
  const {
    fetchAccounts,
    fetchSeatsInfo,
    createCompanyAction,
    updateCompanyAction,
    patchAccountActivateAction,
    createPackageAction,
    fetchContractorsAction,
    updatePackageAction,
    fetchTeamAction
  } = props;
  const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>(undefined);
  const [isCreatingCompany, setIsCreatingCompany] = useState<boolean | undefined>(undefined);
  const [companyInfo, setCompanyInfo] = useState<SetStateAction<any>>({});
  const [seatsDetails, setSeatsInformation] = useState<SetStateAction<any>>({});
  const [isPModalVisible, setIsPModalVisible] = useState<boolean | undefined>(undefined);
  const [isCreatingPackage, setIsCreatingPackage] = useState<boolean | undefined>(undefined);
  const [PackageID, setPackageID] = useState<string | undefined>(undefined);
  const [Seats, setSeats] = useState<any | undefined>(undefined);
  const [adminsTree, setAdminsTree] = useState<any>();
  const [isTreeLoading, setTreeLoading] = useState(true);

  useEffect(() => {
    if (accountID) {
      fetchSeatsInfo(accountID);
      fetchContractorsAction(accountID);
      fetchTeamAction(accountID);
    }
  }, [accountID])


  useEffect(() => {
    setCompanyInfo(companyInformation);
    setSeatsInformation(seatsInformation);
  }, [companyInformation, seatsInformation])

  useEffect(() => {
    if (team.length > 0) {
      setAdminsTree(buildTree(filterAdminUsers(team), null, true));
      setTreeLoading(false);
    } else {
      setAdminsTree([]);
      setTreeLoading(false);
    }
  }, [team]);

  const toggleCheckBoxChange = (value: boolean) => {
    setAdminsTree(buildTree(team, null, !value));
  }

  const buildTree = (data: UserType[], user: UserType | null, incActive: boolean): any => {

    let children: UserType[] = [];

    if (user === null) {
      children = data.filter((item: UserType) => item.reportsTo === null);
    } else {
      if (user !== undefined) {
        children = data.filter((item: UserType) => item.user_metadata.reportsToUserID === user.user_id);
      }
    }

    if (children.length === 0) {
      return null;
    }

    return children.map((child: UserType) => {

      if (incActive && child.blocked === true) return null

      return (
        <TreeNode
          title=
          {
            <span className={child.blocked ? 'inactive-user' : undefined}>
              {child.name} {child.contractorCompany !== null ? `(${child.contractorCompany.companyName})` : `(${child.user_metadata.companyName})`} - {child.app_metadata.role.name} (Tier {child.tier}) {child.blocked ? 'Inactive' : null}
            </span>
          }
          key={child.user_id}
        >
          {buildTree(data, child, incActive)}
        </TreeNode>
      );
    });
  };

  const handleToggleModal = (isUpdating: boolean, form?: WrappedFormUtils) => {
    if (isModalVisible && form) {
      setIsCreatingCompany(undefined);
      setIsModalVisible(undefined);
      form.resetFields();
    } else {
      setIsCreatingCompany(!isUpdating);
      setIsModalVisible(!isModalVisible);
    }
  };

  const handleSaveAccount = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err) {
        createCompanyAction({ formValues });
        setIsModalVisible(undefined);
        fetchAccounts();
        form.resetFields();
      }
    });
  };

  const handleUpdateAccount = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err && accountID) {

        const mergedCompanyInfo = { ...companyInfo, ...formValues };

        updateCompanyAction({ formValues: mergedCompanyInfo, accountID: accountID });
        setIsModalVisible(undefined);
        setCompanyInfo(mergedCompanyInfo);
        form.resetFields();
      }
    });
  };

  const handleTogglePackageModal = (isUpdating: boolean, pId?: string, seats?: any, aSeats?: any, form?: WrappedFormUtils) => {

    if (isPModalVisible && form) {
      setIsCreatingPackage(undefined);
      setIsPModalVisible(undefined);
      form.resetFields();
    } else {
      setIsCreatingPackage(!isUpdating);
      setIsPModalVisible(!isPModalVisible);
      setPackageID(pId);
      setSeats(seats);
    }
  };

  const handleSavePackage = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err && accountID) {
        if (formValues.Seats > 0) {
          formValues['AccountID'] = accountID;
          createPackageAction({ formValues });
          setIsPModalVisible(undefined);
          form.resetFields();
          setTimeout(function () {
            fetchSeatsInfo(accountID);
          }, 1e3);
        }
      }
    });
  };

  const handleUpdatePackage = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err && accountID) {
        if (formValues.Seats >= 0) {
          formValues['AccountID'] = accountID;
          updatePackageAction({ formValues });
          setIsPModalVisible(undefined);
          form.resetFields();
          setTimeout(function () {
            fetchSeatsInfo(accountID);
          }, 1e3);
        }
      }
    });
  };

  const validateKeys = (e: any) => {
    if (!REGEX.validateNumber.test(e.key) || e.target.value.length >= 10) {
      e.preventDefault();
    }
  };

  const handleToogleAccountActivate = (isActivated: boolean, company: AccountType) => {
    patchAccountActivateAction({ isActive: isActivated, accountID: company.accountID });
    if (errorMessages['failure']) {
      Swal.close();
      Swal.fire({
        title: 'Upps!',
        text: `There has been an error when deleting company logo.`,
        type: 'error',
        html: undefined,
        confirmButtonColor: baseTheme.red.error,
      });
    }
  }

  return (
    <AccountsComponent
      roles={roles}
      team={team}
      adminsTree={adminsTree}
      isTreeLoading={isTreeLoading}
      companyContractors={companyContractors}
      companyInformation={companyInfo}
      seatsDetails={seatsDetails}
      isModalVisible={isModalVisible}
      selectedAccountID={accountID}
      isCreatingCompany={isCreatingCompany}
      handleToggleModal={handleToggleModal}
      toggleCheckBoxChange={toggleCheckBoxChange}
      handleSaveAccount={handleSaveAccount}
      handleUpdateAccount={handleUpdateAccount}
      validateKeys={validateKeys}
      isPModalVisible={isPModalVisible}
      isCreatingPackage={isCreatingPackage}
      PackageID={PackageID}
      Seats={Seats}
      handleToogleAccountActivate={handleToogleAccountActivate}
      handleTogglePackageModal={handleTogglePackageModal}
      handleSavePackage={handleSavePackage}
      handleUpdatePackage={handleUpdatePackage}

    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  companyContractors: getAccountsContractors(state),
  team: getAccountsTeam(state),
  accountID: getAccountID(state),
  companyInformation: getAccountCompanyInformation(state),
  seatsInformation: getSeatsDetails(state),
  errorMessages: getErrorMessages(state),
});

const mapDispatchToProps = {
  fetchAccounts: fetchAccountsAction.request,
  fetchTeamAction: fetchAccountUsersAction.request,
  fetchSeatsInfo: fetchSeatsInfoAction.request,
  fetchContractorsAction: fetchContractorsAction.request,
  createCompanyAction: createCompanyAction.request,
  updateCompanyAction: updateCompanyAccountAction.request,
  patchAccountActivateAction: patchAccountIsActivatedAction.request,
  createPackageAction: createPackageAction.request,
  updatePackageAction: updatePackageAction.request,
};

export const AccountsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Accounts);
