import { all, fork, takeLatest, put } from 'redux-saga/effects';

import { logoutAction } from './root.action';

import { changeThemeSaga } from '@themes/theme.saga';
import { sessionSaga } from '@session/session.saga';
import { adminSaga } from '@admin/admin.saga';
import { layoutSaga } from '@layout/layout.saga';
import { professionalSaga } from '@app/back-office/professional.saga';

import Cookies from 'js-cookie';

//TODO: add type
export function withAuthSaga(callback: (params: any) => any) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  if (tokenKey) {
    return;
  }

  callback(tokenKey);
}

function* logoutTrigger() {
  yield put(logoutAction.fulfill({}));
}

function* logoutSaga() {
  yield takeLatest(logoutAction.TRIGGER, logoutTrigger);
}

export function* rootSaga() {
  yield all([
    fork(changeThemeSaga),
    fork(sessionSaga),
    fork(adminSaga),
    fork(professionalSaga),
    fork(layoutSaga),
    fork(logoutSaga),
  ]);
}
