import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';

import { AxiosService } from '@services/axios/axios.service';
import Cookies from 'js-cookie';

let serviceSaga: AxiosService;

export function* cancelService(action: AnyAction) {
  yield serviceSaga.cancelRequest(action.payload.msg);
}

export function* fetchService(endpoint: string, queryParams?: any) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  serviceSaga = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield serviceSaga.get({
    headers: {
      Authorization: tokenKey,
    },
    queryParams
  });

  return data;
}

export function* addService(endpoint: string, body: any) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  serviceSaga = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield serviceSaga.post({
    headers: {
      Authorization: tokenKey,
    },
    body,
  });

  return data;
}

export function* updateService(endpoint: string, body: any) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  serviceSaga = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield serviceSaga.put({
    headers: {
      Authorization: tokenKey,
    },
    body,
  });

  return data;
}

export function* patchService(endpoint: string, body: any) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  serviceSaga = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield serviceSaga.patch({
    headers: {
      Authorization: tokenKey,
    },
    body,
  });

  return data;
}

export function* deleteService(endpoint: string, body?: any) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  serviceSaga = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield serviceSaga.delete({
    headers: {
      Authorization: tokenKey,
    },
    body,
  });

  return data;
}
