import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { SessionLayoutComponent } from '@layout/session-layout/session-layout.component';
import { SendRestoreEmailContainer } from './send-restore/state/send-restore.container';
import { RegistrationFormContainer } from './registration/state/registration-form.container';
import { AsideRegisterComponent } from '@layout/aside/register.component';

import cityBackground from '@assets/city-background-x2.png';

export const SessionRoutes = () => (
  <Switch>
    <Route
      path="/session/register"
      render={() => (
        <AsideRegisterComponent image={cityBackground}>
          <RegistrationFormContainer />
        </AsideRegisterComponent>
      )}
    />
    <Route
      path="/session/restore-email"
      render={() => (
        <SessionLayoutComponent>
          <SendRestoreEmailContainer />
        </SessionLayoutComponent>
      )}
    />
    <Route path="/session" render={() => <Redirect to="/login" />} />
  </Switch>
);
