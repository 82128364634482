import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

export const _getUserCorrectiveActions = (state: IAppState) =>
    state.professional.correctiveActions.items;

export const getUserCorrectiveActions = createSelector(
    [_getUserCorrectiveActions],
    (correctiveActions) => correctiveActions,
);

export const _getCATriggers = (state: IAppState) =>
    state.professional.correctiveActions.triggers;

export const getCATriggers = createSelector(
    [_getCATriggers],
    (triggers) => triggers,
);

export const _getCASeveritys = (state: IAppState) =>
    state.professional.correctiveActions.severitys;

export const getCASeveritys = createSelector(
    [_getCASeveritys],
    (severitys) => severitys,
);

export const _getCAStatus = (state: IAppState) =>
    state.professional.correctiveActions.status;

export const getCAStatus = createSelector(
    [_getCAStatus],
    (status) => status,
);

export const _getUserCAIsLoading = (state: IAppState) =>
    state.professional.correctiveActions.isLoading;

export const getCAIsLoading = createSelector(
    [_getUserCAIsLoading],
    (isLoading) => isLoading,
);