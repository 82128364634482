
import React, { FormEvent } from 'react'
import { WrappedFormUtils } from "antd/es/form/Form";
import { Modal, Input, Form, Button } from "antd"


interface ICertificateMailProps {
    form: WrappedFormUtils;
    visibleModal: boolean;
    setVisibleModal: any;
    handleMailSend: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
    setToMailCertificateIds: any;
}


const CertificateMail = (props: ICertificateMailProps) => {

    const { visibleModal, form, handleMailSend, setVisibleModal, setToMailCertificateIds } = props;

    const { getFieldDecorator, } = form

    return (
        <>
            <Modal
                title={`Mail Certificate`}
                className="mail-certificate"
                visible={visibleModal}
                destroyOnClose={true}
                onCancel={() => {
                    setVisibleModal(false);
                    setToMailCertificateIds([]);
                }}
                footer={null}
            >
                <Form
                    onSubmit={(event: FormEvent<HTMLFormElement>) => {
                        form.validateFields((err, values) => {
                            if (err) {
                                return;
                            }
                        })
                        handleMailSend(event, form)
                        setVisibleModal(false);
                    }} >

                    <Form.Item
                        label="Email Address"

                    >
                        {getFieldDecorator('emailID', {
                            rules:
                                [
                                    {
                                        type: 'email',
                                        required: true,
                                        message: "Please enter the email address",
                                    },
                                ],
                        })(
                            <Input
                                className="input--medium-height modal-input"
                                placeholder="Email Address"
                                required
                            />,
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Subject"

                    >
                        {getFieldDecorator('subject', {
                            rules:
                                [
                                    {
                                        required: true,
                                        message: "Enter the subject of mail",
                                    },
                                ],
                        })(
                            <Input
                                className="input--medium-height modal-input"
                                placeholder="Subject"
                                required
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        label="Message"

                    >
                        {getFieldDecorator('message', {
                            rules:
                                [
                                    {
                                        required: true,
                                        message: "Enter the message of mail",
                                    },
                                ],
                        })(
                            <Input.TextArea
                                className="textarea modal-input"
                                style={{ minHeight: '100px' }}
                                placeholder="Message"
                            />
                        )}
                    </Form.Item>

                    <div className='ant-modal-body__form-footer'>
                        <Button
                            htmlType="submit"
                            type='primary'
                        >
                            Send
                        </Button>

                        <Button type='default' onClick={() => {
                            setVisibleModal(false)
                            setToMailCertificateIds([]);
                        }}> Cancel </Button>
                    </div>

                </Form>
            </Modal>
        </>
    )
}

export const CertificateMailComponent: any = Form.create({ name: 'CerificateMail' })(CertificateMail);

