import React from 'react';

import { ThemeType } from '@shared-types/theme.type';

import { Button } from 'antd';

import injectSheet from 'react-jss';
import classNames from 'classnames';

import './button.styles.css';

//TODO: add types

const ButtonControl = ({ color, text, size, classes, isBold = false, ...rest }: any) => {
  const {
    backgroundGreen,
    backgroundGreenVoid,
    backgroundYellow,
    backgroundYellowVoid,
    backgroundBlack,
    backgroundBlackVoid,
    backgroundVoid,
    backgroundGray,
  } = classes;

  const selectSizeClass = (size: string) => {
    switch (size) {
      case 'extraSmall':
        return 'btn__extra-small';
      case 'small':
        return 'btn__small';
      case 'medium':
        return 'btn__medium';
      case 'large':
        return 'btn__large';
      case 'extraLarge':
        return 'btn__extra-large';
      default:
        return 'btn__small';
    }
  };

  const selectColorClass = (color: string) => {
    switch (color) {
      case 'yellow':
        return backgroundYellow;
      case 'yellowVoid':
        return backgroundYellowVoid;
      case 'green':
        return backgroundGreen;
      case 'greenVoid':
        return backgroundGreenVoid;
      case 'black':
        return backgroundBlack;
      case 'blackVoid':
        return backgroundBlackVoid;
      case 'void':
        return backgroundVoid;
      case 'gray':
        return backgroundGray;
      default:
        return backgroundGreen;
    }
  };

  return (
    <Button
      className={classNames(
        `btn__container ${isBold ? 'btn--bold' : ''}`,
        selectColorClass(color),
        selectSizeClass(size),
      )}
      {...rest}
    >
      {text}
    </Button>
  );
};

const styles = (theme: ThemeType) => ({
  backgroundGreen: {
    backgroundColor: theme.green.aside,
    color: '#fff',
    border: `1px solid ${theme.green.aside}`,
    '&:hover,&:focus': {
      color: '#fff',
      border: `1px solid ${theme.green.hover}`,
      backgroundColor: theme.green.hover,
    },
    '&[disabled],&[disabled]:hover': {
      backgroundColor: theme.green.hover,
      color: '#fff',
      opacity: '0.65',
    },
  },
  backgroundGreenVoid: {
    backgroundColor: 'none !important',
    color: theme.green.main,
    border: `1px solid ${theme.green.main}`,
    '&:hover,&:focus': {
      backgroundColor: theme.green.main,
      border: `1px solid ${theme.green.main}`,
      color: '#fff',
    },
  },
  backgroundYellow: {
    backgroundColor: theme.yellow.main,
    border: `1px solid ${theme.yellow.main}`,
    '&:hover,&:focus': {
      border: `1px solid ${theme.black.main}`,
      color: '#fff',
      backgroundColor: theme.black.main,
    },
  },
  backgroundYellowVoid: {
    backgroundColor: 'none !important',
    color: theme.black.main,
    border: `1px solid ${theme.yellow.main}`,
    '&:hover,&:focus': {
      backgroundColor: theme.yellow.main,
      border: `1px solid ${theme.yellow.main}`,
      color: theme.black.main,
    },
  },
  backgroundBlack: {
    backgroundColor: theme.black.main,
    border: `1px solid ${theme.black.main}`,
    color: '#fff',
    '&:hover,&:focus': {
      backgroundColor: theme.black.main,
      border: `1px solid ${theme.black.main}`,
      color: '#fff',
    },
  },
  backgroundBlackVoid: {
    backgroundColor: 'none !important',
    color: theme.black.main,
    border: `1px solid ${theme.black.main}`,
    '&:hover,&:focus': {
      backgroundColor: theme.black.main,
      border: `1px solid ${theme.black.main}`,
      color: '#fff',
    },
  },
  backgroundVoid: {
    background: 'none !important',
    border: 'none',
    color: theme.grey.fourth,
    '&:hover,&:focus': {
      color: theme.black.main,
    },
  },
  backgroundGray: {
    background: theme.grey.third,
    border: 'none',
    color: theme.white.main,
    '&:hover,&:focus': {
      background: theme.grey.fourth,
      color: theme.white.main,
    },
  },
});

export const ButtonControlComponent = injectSheet(styles)(ButtonControl);
