import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
    FETCH_ACCOUNT_SUBMISSIONS,
} from '@constants/endpoints';

export const FETCH_PROJECT_SUBMISSIONS = 'FETCH_PROJECT_SUBMISSIONS';

export const fetchAccountSubmissionsAction = createActionRoutine(
    FETCH_PROJECT_SUBMISSIONS,
    FETCH_ACCOUNT_SUBMISSIONS
);