        import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';
        import { FETCH_ACCOUNT_JOB_TITLES_API, FETCH_PROXY_USERS_API } from '@constants/endpoints';

        //FETCHES
        export const FETCH_STATES = 'FETCH_STATES';
        export const FETCH_PLANS = 'FETCH_PLANS';
        export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
        export const FETCH_TEAM = 'FETCH_TEAM';
        export const FETCH_WIZARD_PERMIT = 'FETCH_WIZARD_PERMIT';
        // UTILS
        export const CHANGE_FORM_VALUES = 'CHANGE_FORM_VALUES';
        export const CHANGE_FORM_SUBSTEP = 'CHANGE_FORM_SUBSTEP';
        export const CHANGE_MODAL_QUESTION = 'CHANGE_MODAL_QUESTION';
        // JOB TITLES
        export const FETCH_JOB_TITLES = 'FETCH_JOB_TITLES';
        export const FETCH_ACCOUNT_JOB_TITLES = 'FETCH_ACCOUNT_JOB_TITLES';
        // ERRORS
        export const REMOVE_BACKOFFICE_ERROR = 'REMOVE_BACKOFFICE_ERROR';

        // Actions
        export const fetchStatesAction = createActionRoutine(FETCH_STATES);
        export const fetchPlansAction = createActionRoutine(FETCH_PLANS);
        export const fetchQuestionsAction = createActionRoutine(FETCH_QUESTIONS);
        export const fetchWizardPermitAction = createActionRoutine(FETCH_WIZARD_PERMIT);

        export const changeFormValuesAction = createActionRoutine(CHANGE_FORM_VALUES);
        export const changeFormSubstepAction = createActionRoutine(CHANGE_FORM_SUBSTEP);
        export const changeModalQuestionAction = createActionRoutine(CHANGE_MODAL_QUESTION);

        export const fetchJobTitlesAction = createActionRoutine(FETCH_JOB_TITLES);
        export const fetchAccountJobTitlesAction = createActionRoutine(
          FETCH_ACCOUNT_JOB_TITLES,
          FETCH_ACCOUNT_JOB_TITLES_API,
        );

        export const removeBackofficeErrorAction = createActionRoutine(REMOVE_BACKOFFICE_ERROR);


