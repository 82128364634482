import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HazardComponent from '../view/hazard.component';
import { IAppState } from '@rdx/root.reducer';
import { getList } from '@shared/helpers/state-caster';
import { Hazard } from '@shared/types/backend.types';
import {
  addHazardRowAction,
  createHazardAction,
  deleteHazardAction,
  fetchHazardsAction,
  updateHazardAction,
} from './hazard.actions';

const HazardContainer = () => {
  const dispatch = useDispatch();
  const { items, isLoading: isHazardLoading } = useSelector((state: IAppState) => state.admin.hazards);

  // USE MEMOS
  const hazards: Hazard[] = useMemo(() => getList(items), [items]);

  // USE STATES
  const [addingHazards, setAddingHazards] = useState<{ [key: number]: Partial<Hazard> }>({});

  useEffect(() => {
    dispatch(fetchHazardsAction.request());
  }, []);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, hazard: Hazard) => {
    setAddingHazards({
      ...addingHazards,
      [hazard.id]: {
        ...addingHazards[hazard.id],
        [event.target.name]: event.target.value,
      },
    });
  };

  const onAddHazard = () => {
    const lastHazard = hazards.pop();
    const index = lastHazard ? `NEW-${lastHazard.id + 1}` : 'NEW-1';
    setAddingHazards({ ...addingHazards, [index]: { id: index } });
    dispatch(addHazardRowAction.fulfill(index));
  };

  const onSaveHazard = (hazardId: number, isExistingHazard: boolean) => {
    const hazard = addingHazards[hazardId];

    if (isExistingHazard)
      dispatch(
        updateHazardAction.request({
          params: { hazardId },
          body: hazard,
          cleanForm: () => onCloseHazardEdit(hazardId, false),
        }),
      );
    else
      dispatch(
        createHazardAction.request({
          body: hazard,
          cleanForm: () => onCloseHazardEdit(hazardId, true),
        }),
      );
  };



  const onDeleteHazard = (hazardId: number) =>
    dispatch(deleteHazardAction.request({ params: { hazardId } }));

  const onEditHazard = (hazard: Hazard) => setAddingHazards({ ...addingHazards, [hazard.id]: {} });

  const onCloseHazardEdit = (hazardId: number, isNewHazard: boolean) => {
    const newHazards = { ...addingHazards };
    delete newHazards[hazardId];
    setAddingHazards(newHazards);
    if (isNewHazard) dispatch(deleteHazardAction.success({ id: hazardId }));
  };

  return (
    <HazardComponent
      hazards={hazards}
      addingHazards={addingHazards}
      isHazardLoading={isHazardLoading}
      onAddHazard={onAddHazard}
      setAddingHazards={setAddingHazards}
      onSaveHazard={onSaveHazard}
      onDeleteHazard={onDeleteHazard}
      onEditHazard={onEditHazard}
      onCloseHazardEdit={onCloseHazardEdit}
      handleOnChange={handleOnChange}
    />
  );
};

export default HazardContainer;
