import { takeEvery, takeLatest, throttle, put, call, fork, all } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { SafetyPlanType } from '@shared/types/backend.types';

import {
  fetchStatesAction,
  fetchPlansAction,
  fetchQuestionsAction,
  changeFormSubstepAction,
  changeFormValuesAction,
  changeModalQuestionAction,
  fetchJobTitlesAction,
  fetchWizardPermitAction,
  fetchAccountJobTitlesAction,
  removeBackofficeErrorAction,
} from './professional.action';
import { createPlanStepsAction } from './programs/plans/state/plans.actions';

import { AxiosService } from '@services/axios/axios.service';

import { projectsSaga } from './projects/state/projects.saga';
import { teamSaga } from './team/state/team.saga';
import { programsSaga } from './programs/state/programs.saga';
import { safetyProgramsSaga } from './safety-programs/state/safety-programs.saga';
import { projectFormsSaga } from './submissions/state/submissions.saga';

import Cookies from 'js-cookie';
import { arrayToObject } from '@shared/helpers/state-caster';
import {
  API_URL,
  FETCH_SAFETY_PLANS_API,
  FETCH_ACCOUNT_IS_CONFIGURATE,
} from '@constants/endpoints';
import { STATUS } from '@constants/status';
import { helpFormSaga } from '@components/layout/navigation-menu/help-form/state/help-form.saga';
import { correctiveActionsSaga } from './corrective-actions/state/corrective-actions.saga';
import { preTaskPlanSaga } from '@shared/pre-task-plan/state/pre-task-plan.saga';

let professionalService: AxiosService;

function* cancelService(action: AnyAction) {
  yield professionalService.cancelRequest(action.payload.msg);
}

function* fetchService(endpoint: string) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  professionalService = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield professionalService.get({
    headers: {
      Authorization: tokenKey,
    },
  });

  return data;
}

function* fetchStatesRequest() {
  try {
    const data = yield call(fetchService, `${API_URL}/States`);

    yield put(fetchStatesAction.success({ states: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchStatesAction.failure({ title: 'There has been an error while retrieving states.' }),
      );
    }
  }
}

function* fetchPlansRequest(action: AnyAction) {
  try {
    const data = yield call(fetchService, FETCH_SAFETY_PLANS_API.replace(':vvID', action.payload));

    let steps = data.map((step: SafetyPlanType) => ({
      id: step.safetyPlanID,
      name: step.name,
      status: STATUS.wait,
      isActive: false,
    }));

    if (steps.length > 0) {
      steps[0].isActive = true;
      steps[0].status = STATUS.process;

      yield put(
        fetchPlansAction.success({
          plans: steps,
          currentSubstep: steps[0].id,
        }),
      );

      const mappedStepper = steps.map(({ id, status }: any) => ({ id, status }));

      yield put(createPlanStepsAction.fulfill(arrayToObject(mappedStepper, 'id')));
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchPlansAction.failure({
          title: 'There has been an error when retrieving safety plans.',
        }),
      );
    }
  }
}

function* fetchJobTitlesRequest(action: AnyAction) {
  try {
    const data = yield call(fetchService, `${API_URL}/JobTitles?isPrincipal=${!action.payload}`);

    yield put(fetchJobTitlesAction.success({ jobTitles: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchJobTitlesAction.failure({
          title: 'There has been an error while retrieving job titles.',
        }),
      );
    }
  }
}

function* fetchAccountJobTitlesRequest(action: AnyAction) {
  try {
    const endpoint = fetchAccountJobTitlesAction.getEndpoint({ accountID: action.payload });
    const data = yield call(fetchService, endpoint);

    yield put(fetchAccountJobTitlesAction.success({ jobTitles: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchAccountJobTitlesAction.failure({
          title: 'There has been an error while retrieving job titles.',
        }),
      );
    }
  }
}

function* fetchWizardPermitRequest(action: AnyAction) {
  try {
    const data = yield call(
      fetchService,
      FETCH_ACCOUNT_IS_CONFIGURATE.replace(':accountID', action.payload),
    );

    yield put(fetchWizardPermitAction.success({ account: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchWizardPermitAction.failure({
          title: "We've faced some issues when retrieving your user. Please try again.",
        }),
      );
    }
  }
}

function* changeFormSubstepTrigger(action: AnyAction) {
  yield put(changeFormSubstepAction.fulfill({ currentSubstep: action.payload }));
}

function* changeFormValuesTrigger(action: AnyAction) {
  yield put(changeFormValuesAction.fulfill(action.payload));
}

function* changeModalQuestionTrigger(action: AnyAction) {
  yield put(changeModalQuestionAction.fulfill({ currentQuestion: action.payload }));
}

function* removeBackOfficeErrorTrigger(action: AnyAction) {
  yield put(removeBackofficeErrorAction.fulfill(action.payload));
}

export function* professionalSaga() {
  yield takeEvery(fetchStatesAction.REQUEST, fetchStatesRequest);
  yield takeEvery(fetchStatesAction.FULFILL, cancelService);
  yield takeEvery(fetchPlansAction.REQUEST, fetchPlansRequest);
  yield takeEvery(fetchPlansAction.FULFILL, cancelService);
  yield takeEvery(fetchQuestionsAction.FULFILL, cancelService);
  yield takeLatest(fetchJobTitlesAction.REQUEST, fetchJobTitlesRequest);
  yield takeLatest(fetchJobTitlesAction.FULFILL, cancelService);
  yield takeLatest(fetchAccountJobTitlesAction.REQUEST, fetchAccountJobTitlesRequest);
  yield takeLatest(fetchWizardPermitAction.REQUEST, fetchWizardPermitRequest);
  yield takeLatest(fetchWizardPermitAction.FULFILL, cancelService);
  yield takeLatest(changeFormSubstepAction.TRIGGER, changeFormSubstepTrigger);
  yield throttle(200, changeFormValuesAction.TRIGGER, changeFormValuesTrigger);
  yield takeLatest(changeModalQuestionAction.TRIGGER, changeModalQuestionTrigger);
  yield takeLatest(removeBackofficeErrorAction.TRIGGER, removeBackOfficeErrorTrigger);
  yield all([
    fork(projectsSaga),
    fork(safetyProgramsSaga),
    fork(teamSaga),
    fork(programsSaga),
    fork(projectFormsSaga),
    fork(helpFormSaga),
    fork(correctiveActionsSaga),
    fork(preTaskPlanSaga),
  ]);
}
