import React, { useEffect, useState, SetStateAction } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { StepType } from '@shared/types/professional.types';
import { ErrorMessage } from '@shared/types/utils.types';
import { UserType, JobTitleType } from '@shared/types/backend.types';
import { History } from 'history';
import { IProfessionalProgramsState } from './programs.reducer';

import { withRouter, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrentStep, getStepItems } from '@layout/layout.selector';
import {
  getValidSteps,
  getPrograms,
  getWizardPermit,
  getPlans,
  getProfessionalIsLoading,
  getErrorMessages,
} from '../../professional.selector';
import { getSessionUser } from '@session/session.selector';
import { changeCurrentStepAction, validateCurrentStepAction } from '@layout/layout.action';
import { fetchWizardPermitAction, removeBackofficeErrorAction } from '../../professional.action';
import {
  submitSafetyTeamAction,
  resetSafetySetupAction,
  configAddFormsAction,
  configAddProjectsAction,
} from './programs.actions';

import Swal from 'sweetalert2';
import { ProfessionalProgramsComponent } from '../view/programs.component';
import { LoadingSpinnerComponent } from '@controls/loading/spinner.component';

import {
  validatePlans,
  validateProjects,
  minValidLength,
} from '../programs.validations';
import { STATUS } from '@constants/status';
import { baseTheme } from '@shared/themes/presets/base.theme';

import '@back-office/programs/view/programs.styles.scss';
import { openNotificationWithIcon } from '@components/controls/notification/notification.component';
import { getList } from '@shared/helpers/state-caster';
import { userRequestModel } from '@shared/utils/backend-models';

export interface IProgramsContainerProps {
  user: UserType;
  steps: StepType[];
  currentStep: number;
  totalSteps: number;
  totalPlans: number;
  validSteps: boolean[];
  programForms: IProfessionalProgramsState;
  jobTitles: JobTitleType[];
  history: History;
  isLoading: boolean;
  hasWizard: boolean;
  errorMessages: {
    [key: string]: ErrorMessage;
  };
  fetchWizardPermitAction: (accointID: number) => void;
  //TODO: add type
  changeCurrentStepAction: (payload?: any) => void;
  submitSafetyTeamAction: (team: any) => void;
  resetSafetySetupAction: () => void;
  configAddFormsAction: (payload: any) => void;
  configAddProjectsAction: (payload: any) => void;
  removeErrorAction: (code: string) => void;
  validateCurrentStepAction: (payload: any) => void;
}

const ProfessionalPrograms = (props: IProgramsContainerProps) => {
  const {
    user,
    currentStep,
    totalSteps,
    steps,
    validSteps,
    programForms,
    history,
    isLoading,
    hasWizard,
    errorMessages,
  } = props;
  const {
    fetchWizardPermitAction,
    changeCurrentStepAction,
    resetSafetySetupAction,
    removeErrorAction,
    configAddFormsAction,
    configAddProjectsAction,
    validateCurrentStepAction,
  } = props;

  const [hasShownWizard, setHasShownWizard] = useState<SetStateAction<Boolean | undefined>>(
    undefined,
  );
  const intlProvider = useIntl();

  const headings = [
    intlProvider.messages['wizard.titles.safetyPrograms'],
    intlProvider.messages['wizard.titles.project'],
    intlProvider.messages['wizard.titles.team'],
  ];

  const stepValidations: any = {
    0: () => validatePlans(programForms.forms, minValidLength),
    1: () => validateProjects(programForms.projects),
  };
  const handleSendRequest: any = {
    0: () =>
      configAddFormsAction({ forms: programForms.forms, accountID: user.user_metadata.accountID }),
    1: () =>
      configAddProjectsAction({
        projects: getList(programForms.projects),
        accountID: user.user_metadata.accountID,
      }),
  };

  useEffect(() => {
    fetchWizardPermitAction(user.accountID);

    return () => {
      resetSafetySetupAction();
    };
  }, [resetSafetySetupAction, fetchWizardPermitAction, user.accountID]);

  useEffect(() => {
    if (!isLoading) {
      if (programForms.meta.isSubmitSuccess !== undefined) {
        if (!programForms.meta.isSubmitSuccess && errorMessages['submitProgramsSetup']) {
          Swal.close();
          Swal.fire({
            title: 'Upps!',
            text: `There has been an error when submitting.`,
            type: 'error',
            html: undefined,
            confirmButtonColor: baseTheme.red.error,
          });
        } else {
          Swal.close();
          Swal.fire({
            title: intlProvider.messages['wizard.finish.sectionTitle'].toString(),
            text: intlProvider.messages['wizard.finish.message'].toString(),
            type: STATUS.success,
            html: intlProvider.messages['wizard.finish.message'].toString(),
            confirmButtonColor: baseTheme.green.aside,
            onBeforeOpen: () => {
              //@ts-ignore
              Swal.getContent().textContent = intlProvider.messages[
                'wizard.finish.message'
              ].toString();
            },
          }).then(() => {
            if (history && history.push) {
              window.location.reload();
            }
          });
        }
      } else if (errorMessages) {
        if (!programForms.meta.isSubmitSuccess && errorMessages['submitProgramsSetup']) {
          Swal.close();
          Swal.fire({
            title: 'Upps!',
            text: `There has been an error when submitting.`,
            type: 'error',
            html: undefined,
            confirmButtonColor: baseTheme.red.error,
          });
        }
      }
    }
  }, [isLoading, errorMessages, history, intlProvider.messages, programForms.meta.isSubmitSuccess]);

  // Error handler
  useEffect(() => {
    const errors = Object.values(errorMessages);

    errors.forEach((key: any) => {
      key && openNotificationWithIcon('error', key.title, key.message, removeErrorAction(key.code));
    });
  }, [errorMessages, removeErrorAction]);

  const handleUpdateCurrentPage = (current: number, updatedSteps: any) => {
    changeCurrentStepAction({
      title: headings[current],
      updatedSteps,
      currentStep: current,
    });
  };

  const handleNextPage = () => {
    if (stepValidations[currentStep]()) {
      const current = currentStep + 1;
      let updatedSteps = steps;
      //handle call action
      if (currentStep === 1 || 2) {
        handleSendRequest[currentStep]();
      }

      updatedSteps[currentStep].status = STATUS.finish;
      updatedSteps[current].status = STATUS.process;

      handleUpdateCurrentPage(current, updatedSteps);
    }
  };

  const handlePrevPage = () => {
    const current = currentStep - 1;
    let updatedSteps = steps;

    updatedSteps[currentStep].status = STATUS.wait;
    updatedSteps[current].status =
      updatedSteps[current].status !== STATUS.finish
        ? STATUS.process
        : updatedSteps[current].status;

    handleUpdateCurrentPage(current, updatedSteps);
  };

  const handleSubmit = () => {
    if (stepValidations[currentStep]() && !isLoading) {
      configAddProjectsAction({
        projects: getList(programForms.projects),
        accountID: user.user_metadata.accountID,
      })
    }
  };

  const showStartModal = () => {
    Swal.fire({
      text: intlProvider.messages['modal.setupCompany.message'].toString(),
      type: STATUS.info.toString() as any,
      confirmButtonColor: baseTheme.green.aside,
    });
  };

  if (hasWizard === undefined) {
    return <LoadingSpinnerComponent />;
  }

  if (hasWizard) {
    return <Redirect to="/back-office" />;
  } else if (hasShownWizard === undefined) {
    validateCurrentStepAction({ accountID: user.user_metadata.accountID });
    showStartModal();
    setHasShownWizard(true);
  }

  return (
    <ProfessionalProgramsComponent
      currentStep={currentStep}
      totalSteps={totalSteps}
      validSteps={validSteps}
      isLoading={isLoading}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      handleSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  steps: getStepItems(state),
  currentStep: getCurrentStep(state),
  totalSteps: getStepItems(state).length,
  totalPlans: getPlans(state).length,
  validSteps: getValidSteps(state),
  programForms: getPrograms(state),
  jobTitles: state.professional.programs.jobTitles,
  hasWizard: getWizardPermit(state),
  isLoading: getProfessionalIsLoading(state),
  errorMessages: getErrorMessages(state),
});

const mapDispatchToProps = {
  fetchWizardPermitAction: fetchWizardPermitAction.request,
  changeCurrentStepAction: changeCurrentStepAction.trigger,
  submitSafetyTeamAction: submitSafetyTeamAction.request,
  resetSafetySetupAction: resetSafetySetupAction.trigger,
  removeErrorAction: removeBackofficeErrorAction.trigger,
  configAddFormsAction: configAddFormsAction.request,
  configAddProjectsAction: configAddProjectsAction.request,
  validateCurrentStepAction: validateCurrentStepAction.request,
};

const professionalProgramsWithRouter : any = withRouter(ProfessionalPrograms as any);

export const ProfessionalProgramsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(professionalProgramsWithRouter);
