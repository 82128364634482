import { AnyAction } from 'redux';
import { IAdminState } from '@app/admin/admin.reducer';

import update from 'immutability-helper';
import { submitInviteTeamAction } from './invite-team.saga';

export interface IAdminInviteTeamState {
  isLoading: boolean;
  hasSubmitedTeam: boolean;
  errorMessages?: {};
}

export const INVITE_TEAM_INITIAL_STATE = {
  isLoading: false,
  hasSubmitedTeam: false,
  errorMessages: undefined,
};

export const adminInviteTeamReducer = {
  [submitInviteTeamAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      invite: {
        inviteTeam: {
          isLoading: { $set: true },
          hasSubmitedTeam: { $set: false },
        },
      },
    }),
  [submitInviteTeamAction.SUCCESS]: (state: IAdminState) =>
    update(state, {
      invite: {
        inviteTeam: {
          isLoading: { $set: false },
          hasSubmitedTeam: { $set: true },
        },
      },
    }),
  [submitInviteTeamAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      invite: {
        inviteTeam: {
          isLoading: { $set: false },
          errorMessages: {
            $set: payload.error,
          },
        }
      }
    }),
};
