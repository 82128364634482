import { IAdminState } from "@app/admin/admin.reducer";
import { addHazardRowAction, createHazardAction, deleteHazardAction, fetchHazardsAction, updateHazardAction } from "./hazard.actions";
import update from 'immutability-helper';
import { Hazard } from "@shared/types/backend.types";
import { ErrorMessage } from "@shared/types/utils.types";
import { AnyAction } from "redux";
import { combineActions } from "redux-actions";

export interface IHazardState {
    items: { [key: string]: Hazard; };
    isLoading: boolean;
    errorMessages: {
        [key: string]: ErrorMessage | undefined;
    };
}

export const HAZARD_INITIAL_STATE: IHazardState = {
    items: {},
    isLoading: false,
    errorMessages: {},
};

export const hazardsReducer = {
    [fetchHazardsAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
        return update(state, {
            hazards: {
                items: { $set: payload },
                isLoading: { $set: false }
            },
        })
    },

    [fetchHazardsAction.FULFILL]: (state: IAdminState) =>
        update(state, {
            hazards: {
                items: { $set: {} },
                isLoading: { $set: false }
            },
        }),

    [createHazardAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {

        return update(state, {
            hazards: {
                items: { $set: { ...state.hazards.items, [payload.id]: payload } },
                isLoading: { $set: false }
            },
        })
    },

    [updateHazardAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
        return update(state, {
            hazards: {
                items: { $set: { ...state.hazards.items, [payload.id]: payload } },
                isLoading: { $set: false }
            },
        })
    },

    [deleteHazardAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
        const hazards = { ...state.hazards.items };
        delete hazards[payload.id];

        return update(state, {
            hazards: {
                items: { $set: hazards },
                isLoading: { $set: false }
            },
        });
    },

    [addHazardRowAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) => {

        return update(state, {
            hazards: {
                items: { $set: { ...state.hazards.items, [payload]: { id: payload } } },
            },
        });

    },


    [combineActions(fetchHazardsAction.REQUEST, createHazardAction.REQUEST, updateHazardAction.REQUEST, deleteHazardAction.REQUEST) as any]: (
        state: IAdminState,
    ) => {
        return update(state, {
            hazards: {
                isLoading: { $set: true }
            },
        })
    },

    [combineActions
        (
            fetchHazardsAction.FAILURE,
            createHazardAction.FAILURE,
            updateHazardAction.FAILURE,
            deleteHazardAction.FAILURE,
        ) as any]: (
            state: IAdminState,
            { payload }: AnyAction,
        ) => {
            return update(state, {

                hazards: {
                    isLoading: { $set: false },
                    errorMessages: {
                        [payload.code]: { $set: payload.message },
                    }
                }
            })
        }
}
