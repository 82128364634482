import React, { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { IAppState } from '@rdx/root.reducer';

import { getAccountID } from '@app/admin/invite/state/invite.selector';

import { getProjects } from '@app/back-office/professional.selector';
import {
  getProjectSubmissionsLoading,
  getSubmissionsSelectedProjectID,
  getSelectedProjectSubmission,
  getIsLoadingSubmissionPDF,
  getSubmissionsSelectedPDF,
} from '@app/back-office/submissions/state/submissions.selector';
import { getProjectSubmissions } from '@app/admin/submissions/state/submissions.selector';

import { fetchAccountsAction } from '../../accounts/state/accounts.actions';
import {
  fetchSPProjectAction,
  getSelectedFormPDFAction,
  fetchFormPDFAction,
} from '../../../back-office/submissions/state/submissions.actions';
import { fetchAccountSubmissionsAction } from './submissions.actions';

import { ProjectType, Submission } from '@shared/types/backend.types';

import { SubmissionsPerAccountComponent } from '../view/submissions.component';

import _ from 'lodash';
import { invertedOrder } from '@constants/sort';

import moment from 'moment';
import { useRenderPDF } from '@shared/hooks/usepdf';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { FETCH_FORM_PDF_API } from '@constants/endpoints';
import axios from 'axios';

interface IFilters {
  formName?: string | undefined;
  user?: string | undefined;
  safetyPlanName?: string | undefined;
  projectName?: string | undefined;
  dateRange?: string[] | undefined;
  isCompleted?: boolean | number;
}

interface IAccountsFormsProps {
  selectedAccountID?: number;
  selectedProjectID?: number;
  projects: ProjectType[];
  submissions: Submission[];
  isFecthLoading: boolean;
  isLoadingPDF: boolean;
  formPDF?: string;
  selectedSubmission: Submission;
  fetchProjectsAction: (accountID: number) => void;
  fetchSubmissionsAction: (payload: any) => void;
  changeSelectedProjectAction: (projectID: string) => void;
  resetSubmissionsAction: () => void;
  fetchFormsAction: (payload: any) => void;
  fetchFormPDFAction: (payload: any) => void;
  getSelectedFormPDFAction: (formID: string) => void;
}

export const AccountsForms = (props: IAccountsFormsProps) => {
  const {
    submissions,
    projects,
    selectedAccountID,
    selectedProjectID,
    selectedSubmission,
    isFecthLoading,
    isLoadingPDF,
    formPDF,
  } = props;
  const {
    fetchProjectsAction,
    fetchSubmissionsAction,
    resetSubmissionsAction,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [sortSetup, setSortSetup] = useState({ key: 'user.name', order: 'asc' });
  const [filteredSubmisions, setFilteredSubmisions] = useState(submissions);
  const [orderedSubmisions, setorderedSubmisions] = useState(submissions);
  const [distinctNames, setDistinctNames] = useState([] as any);
  const [distinctPrograms, setDistinctPrograms] = useState([] as any);
  const [submittedReport, setsubmittedReport] = useState([] as any);
  const [filters, setFilters] = useState<IFilters>({});
  const [selectedName, setSelectedName] = useState('');
  const [selectedProgram, setSelectedProgram] = useState('');
  const [selectedReport, setSubmittedReport] = useState('');
  const ref = useRef<HTMLAnchorElement | undefined>(undefined);
  const { RenderPdf } = useRenderPDF(ref);

  useEffect(() => {
    if (submissions) {
      setDistinctNames(Array.from(new Set(submissions.map(x => x.user.name))));
      setDistinctPrograms(Array.from(new Set(submissions.map(x => x.safetyPlanName))));
      setsubmittedReport(Array.from(new Set(submissions.map(x => x.formName))));
      setFilteredSubmisions(submissions);
      setorderedSubmisions(submissions);
    }
  }, [submissions]);

  useEffect(() => {
    var data: Submission[] = submissions;
    _.forEach(filters, function (value: any, key: string) {
      if (value === 'All') {
        const TempObject = { ...filters };
        delete TempObject[key as keyof IFilters];
        setFilters({ ...TempObject });
      }

      if (key === 'dateRange')
        data = _.filter(data, d =>
          moment(d.submissionDateTime).isBetween(value[0], value[1], 'minutes'),
        );
      else if (key === 'isCompleted') {
        data = data.filter((submission: any) =>
          value === 2 ? data : submission.isCompleted === Boolean(value),
        );
      } else {
        if (key === 'user') key = 'user.name';
        if (key === 'projectName') key = 'project.name';

        data = _.filter(data, [key, value]);
      }
    });

    setFilteredSubmisions(data);

    if (!isFecthLoading) setIsLoading(false);
  }, [filters, submissions]);

  useEffect(() => {
    if (selectedAccountID !== undefined) {
      fetchProjectsAction(selectedAccountID);
    }
  }, [selectedAccountID, fetchProjectsAction]);

  useEffect(() => {
    if (selectedAccountID !== undefined) {
      fetchSubmissionsAction({ accountID: selectedAccountID });
      setIsLoading(true);
    }
  }, [selectedAccountID, selectedProjectID, fetchSubmissionsAction, resetSubmissionsAction]);

  const handleChangeSelectedProject = (projectName: string) => {
    setFilters({
      ...filters,
      projectName,
    });
  };

  const handleLocalChangeSelectedUser = (user: string) => {
    setSelectedName(user);
    setFilters({
      ...filters,
      user,
    });
  };

  const handleLocalChangeSelectedProgram = (safetyPlanName: string) => {
    setSelectedProgram(safetyPlanName);
    setFilters({
      ...filters,
      safetyPlanName,
    });
  };

  const handleLocalChangesubmittedReport = (formName: string) => {
    setSubmittedReport(formName);
    setFilters({
      ...filters,
      formName,
    });
  };

  const handleLocalChangeDateRange = (date: any, dateRange: string[]) => {
    setFilters({
      ...filters,
      dateRange,
    });
    if (dateRange[0] === '' || dateRange[1] === '') {
      const TempObject = { ...filters };
      delete TempObject.dateRange;
      setFilters({ ...TempObject });
    }
  };

  const handleSort = (key: any) => {
    if (sortSetup.key === key) {
      setSortSetup({ order: invertedOrder[sortSetup.order], key });
    } else {
      setSortSetup({ order: 'asc', key });
    }
  };



  const handleChangeReportStatus = (isCompleted: number) => {
    setFilters({
      ...filters,
      isCompleted,
    });
  };


  useEffect(() => {
    if (sortSetup.key != 'date') {
      setorderedSubmisions(
        _.orderBy(filteredSubmisions, [sortSetup.key], [sortSetup.order as any]),
      );
    }

    if (sortSetup.key === 'date') {
      if (sortSetup.order == 'asc')
        setorderedSubmisions(_.sortBy(filteredSubmisions, f => new Date(f.date)));
      if (sortSetup.order == 'desc')
        setorderedSubmisions(_.sortBy(filteredSubmisions, f => new Date(f.date)).reverse());
    }
  }, [filters, sortSetup, filteredSubmisions]);


  const fetchSubmissionPdf = async (submissionId: String) => {
    try {
      message.loading({ key: "submissionPdf", content: "Loading..." });
      const token = Cookies.get('sessionKey') || "";
      const endpoint = FETCH_FORM_PDF_API.replace(':submissionID', `${submissionId}`);
      const res = await axios.get(endpoint, { headers: { Authorization: `Bearer ${token}` } })
      if (res && res.data && res.data.pdfLocation && ref && ref.current) {
        ref.current.href = res.data.pdfLocation
        ref.current.click();
        message.success({ key: "submissionPdf", content: "Success" });
      } else throw new Error();

    } catch (error) {
      message.error({ key: "submissionPdf", content: "Error while fetching submission pdf!" })
    }
  }

  return (
    <SubmissionsPerAccountComponent
      submissions={orderedSubmisions}
      projects={projects}
      selectedProjectID={selectedProjectID}
      selectedProgram={selectedProgram}
      selectedReport={selectedReport}
      selectedName={selectedName}
      selectedSubmission={selectedSubmission}
      distinctNames={distinctNames}
      distinctPrograms={distinctPrograms}
      isLoading={isLoading}
      isLoadingPDF={isLoadingPDF}
      sortSetup={sortSetup}
      submittedReport={submittedReport}
      RenderPdf={RenderPdf}
      fetchSubmissionPdf={fetchSubmissionPdf}
      handleSort={handleSort}
      handleLocalChangeSelectedProgram={handleLocalChangeSelectedProgram}
      handleChangeReportStatus={handleChangeReportStatus}
      handleLocalChangesubmittedReport={handleLocalChangesubmittedReport}
      handleLocalChangeSelectedUser={handleLocalChangeSelectedUser}
      handleLocalChangeDateRange={handleLocalChangeDateRange}
      handleChangeSelectedProject={handleChangeSelectedProject}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  projects: getProjects(state),
  submissions: getProjectSubmissions(state),
  selectedAccountID: getAccountID(state),
  selectedProjectID: getSubmissionsSelectedProjectID(state),
  selectedSubmission: getSelectedProjectSubmission(state),
  isFecthLoading: getProjectSubmissionsLoading(state),
  isLoadingPDF: getIsLoadingSubmissionPDF(state),
  formPDF: getSubmissionsSelectedPDF(state),
});

const mapDispatchToProps = {
  fetchProjectsAction: fetchSPProjectAction.request,
  fetchSubmissionsAction: fetchAccountSubmissionsAction.request,
  fetchFormPDFAction: fetchFormPDFAction.request,
  getSelectedFormPDFAction: getSelectedFormPDFAction.trigger,
  resetAccountsAction: fetchAccountsAction.fulfill,
  resetSubmissionsAction: fetchAccountSubmissionsAction.fulfill,
};

export const AccountsFormsContainer = connect(mapStateToProps, mapDispatchToProps)(AccountsForms);
