import { submitSalesFormAction } from './sales-form.action';

import { IProfessionalState } from '@app/back-office/professional.reducer';

import { AnyAction } from 'redux';

import update from 'immutability-helper';

export const salesFormReducer = {
    [submitSalesFormAction.REQUEST]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            safetyPrograms: {
                isSalesFormSubmiting: { $set: true },
            }
        }),
    [submitSalesFormAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            safetyPrograms: {
                isSalesFormSubmiting: { $set: false },
            }
        }),
    [submitSalesFormAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
        safetyPrograms: {
            isSalesFormSubmiting: { $set: false },
            errorMessages: {
                [payload.error.code]: { $set: payload.error },
            },
        }
    }),
}