import { submitHelpFormAction } from './help-form.action';

import { ILayoutState } from '@layout/layout.reducer';

import { AnyAction } from 'redux';

import update from 'immutability-helper';

export const helpFormReducer = {
  [submitHelpFormAction.REQUEST]: (state: ILayoutState, { payload }: AnyAction) =>
    update(state, {
      isHelpFormSubmitting: { $set: true },
    }),
  [submitHelpFormAction.SUCCESS]: (state: ILayoutState, { payload }: AnyAction) =>
    update(state, {
      isHelpFormSubmitting: { $set: false },
    }),
  [submitHelpFormAction.FAILURE]: (state: ILayoutState, { payload }: AnyAction) =>
    update(state, {
      isHelpFormSubmitting: { $set: false },
      errorMessages: {
        [payload.error.code]: { $set: payload.error },
      },
    }),
};
