import React, { useState, FormEvent, useEffect } from 'react'
import { IAppState } from '@rdx/root.reducer';
import { connect } from 'react-redux';

import { fetchUserCertificatesAction, mailCertificateUserAction, updateAccountUserAction, updateUserSeatAction } from '@app/admin/accounts/team/state/team.actions';
import { updateUsersConfigAction } from '@app/admin/accounts/state/accounts.actions';

import { getUserCertificates, getCertificatesLoading, getMailCertificatesLoading } from '@app/admin/accounts/team/state/team.selector';
import { getAccountCompanyInformation, getSeatsDetails } from '@app/admin/accounts/state/accounts.selector';
import { getAccountID } from "@app/admin/invite/state/invite.selector";

import { UserType, UserCertificateType, AccountType, PackageSeatDetail } from "@shared/types/backend.types";
import { UpdateUserConfigPayload } from '../powerbi-key/userConfig';
import { WrappedFormUtils } from 'antd/es/form/Form';

import { UserProfileModal } from './user-certificates.component';
import { UpdateAccountUserPayload } from '@app/admin/accounts/accounts';


export interface IUserCertificatesContainerProps {
    team: UserType[]
    userCertificates?: UserCertificateType[];
    companyInformation?: AccountType;
    selectedAccountID: number | undefined;
    isLoading: boolean;
    isCertificateMailing: boolean;
    seatsInfo: PackageSeatDetail[];
    open?: boolean;
    setOpen?: any;
    usage: "tree" | 'team';
    site: 'admin' | 'backoffice';
    defaultTabOpen: string;
    targetUserID: string | undefined;
    fetchUserCertificates: (selectedUserID: string) => void;
    mailUserCertificates: (data: any) => void; // todo type
    updateUsersConfig: (payload: UpdateUserConfigPayload) => void;
    updateAccountUserAction: (payload: UpdateAccountUserPayload) => void;
    updateUserSeatAction: (payload: any) => void;
}

const UserCertificate = (props: IUserCertificatesContainerProps) => {

    const { team, userCertificates, defaultTabOpen, isLoading, isCertificateMailing, seatsInfo, companyInformation, open, usage, site, targetUserID } = props;
    const { setOpen, fetchUserCertificates, updateUsersConfig, mailUserCertificates, updateAccountUserAction, updateUserSeatAction } = props;
    const [editPowerBiKey, setEditPowerBiKey] = useState(false);
    const [user, setUser] = useState<undefined | UserType>(undefined);
    const [openCertificatesInfoModal, setCertificatesInfoModal] = useState(false);
    const [currentTab, setcurrentTab] = useState(defaultTabOpen);
    const [toMailCertificateIds, setToMailCertificateIds] = useState([] as any);
    const [openRoleChangeModal, toggleRoleChangeModal] = useState<boolean>(false);

    useEffect(() => {
        if (targetUserID) {
            setUser(team.filter((user: UserType) => user.user_id === targetUserID)[0]);
        }
    }, [targetUserID, team]);

    const handleFecthCertificate = (selectedUserId: string | undefined) => {
        if (selectedUserId !== undefined) {
            fetchUserCertificates(selectedUserId);
            setCertificatesInfoModal(true);
        }
    }

    const updatePowerBIKey = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils, userConfigID: number) => {
        event.preventDefault();

        if (user) {
            form.validateFieldsAndScroll((err: string, formValues: any) => {
                updateUsersConfig({ userConfigData: formValues, userConfigID: userConfigID, accoundID: user.user_metadata.accountID });
            });
        }
    }

    const handleOnCheckBoxChange = (value: boolean, certificateID: number) => {
        if (value) setToMailCertificateIds((prev: any) => [...prev, certificateID])
        else setToMailCertificateIds(toMailCertificateIds.filter((id: number) => id !== certificateID))
    }

    const handleMailSend = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => {

        event.preventDefault();

        form.validateFieldsAndScroll((err: string, formValues: UserType) => {
            formValues = { ...formValues, ...{ toMailCertificateIds } }
            mailUserCertificates(formValues);
            setToMailCertificateIds([]);
        })
    }

    const handleRoleChange = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => {
        event.preventDefault();
        form.validateFieldsAndScroll((err: string, formValues: any) => {
            if (user) {

                updateUserSeatAction(
                    {
                        accountId: user.user_metadata.accountID,
                        userID: user.user_id,
                        packageID: formValues.package
                    })

                updateAccountUserAction(
                    {
                        accountId: user.user_metadata.accountID,
                        toUpdateUserId: user.user_id,
                        user:
                        {
                            app_metadata: { role: { roleID: formValues.roleID, name: formValues.roleID === 2 ? 'Administrator' : 'User' } }
                        }
                    })
            }
            toggleRoleChangeModal(false);
            form.resetFields();
        })

    }

    return (
        <UserProfileModal
            user={user}
            userCertificates={userCertificates}
            isLoading={isLoading}
            toMailCertificateIds={toMailCertificateIds}
            openCertificatesModal={usage === "tree" && open !== undefined ? open : openCertificatesInfoModal}
            setCertificatesModal={usage === "tree" && setOpen !== undefined ? setOpen : setCertificatesInfoModal}
            editPowerBiKey={editPowerBiKey}
            companyInformation={companyInformation}
            updatePowerBIKey={updatePowerBIKey}
            seatsInfo={seatsInfo}
            setEditPowerBiKey={setEditPowerBiKey}
            setToMailCertificateIds={setToMailCertificateIds}
            openRoleChangeModal={openRoleChangeModal}
            toggleRoleChangeModal={toggleRoleChangeModal}
            handleRoleChange={handleRoleChange}
            usage={usage}
            site={site}
            defaultTabOpen={defaultTabOpen}
            currentTab={currentTab}
            setcurrentTab={setcurrentTab}
            handleFecthCertificate={handleFecthCertificate}
            handleOnCheckBoxChange={handleOnCheckBoxChange}
            handleMailSend={handleMailSend}
        />
    );
}

const mapStateToProps = (state: IAppState) => ({
    userCertificates: getUserCertificates(state),
    isLoading: getCertificatesLoading(state),
    seatsInfo: getSeatsDetails(state),
    selectedAccountID: getAccountID(state),
    isCertificateMailing: getMailCertificatesLoading(state),
    companyInformation: getAccountCompanyInformation(state),
});

const mapDispatchToProps = {
    fetchUserCertificates: fetchUserCertificatesAction.request,
    mailUserCertificates: mailCertificateUserAction.request,
    updateUsersConfig: updateUsersConfigAction.request,
    updateAccountUserAction: updateAccountUserAction.request,
    updateUserSeatAction: updateUserSeatAction.request
};

export const UserCertificateContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserCertificate);