import React from 'react';

import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import { SessionLayoutComponent } from '@layout/session-layout/session-layout.component';

import { ThemeType } from '@shared-types/theme.type';
import { Classes } from 'jss';

import './account-disable.styles.scss';
import injectSheet from 'react-jss';
export interface IAccountDisabledComponentProps {
    show: boolean;
    handleLogout: () => void;
    classes: Classes;
}

export const AccountDisabledModal = (props: IAccountDisabledComponentProps) => {
    const { show } = props;
    const { controlContainer } = props.classes;
    const { handleLogout } = props;

    return (
      <div className={show ? 'account-disabled-display' : 'hide'}>
        <SessionLayoutComponent>
          <div className={controlContainer}>
            <div className="account-disabled__container">
              <div className="account-disabled-body">
                <h2>
                  Your Safety Assist account has been <b>Disabled</b>. <br />
                  Please Contact our Sales Department for assistance.
                </h2>
                <h3>sales@velocityvue.com</h3>
                <ButtonControlComponent
                  text={'OK'}
                  color="green"
                  size="large"
                  onClick={() => handleLogout()}
                  style={{ marginTop: 25 }}
                />
              </div>
            </div>
          </div>
        </SessionLayoutComponent>
      </div>
    );
}

const styles = (theme: ThemeType) => ({
  titleStyles: {
    color: theme.grey.main,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 0,
  },
  controlContainer: {
    position: 'absolute',
  },
});

export const AccountDisabledComponent = injectSheet(styles)(AccountDisabledModal);