import {
  addProjectAction,
  updateProjectAction,
  removeProjectAction,
  fetchProjectsAction,
  addCachedProjectAction,
  removeProjectRowAction,
} from './projects.actions';
import { IProfessionalState } from '@app/back-office/professional.reducer';
import { getList } from '@shared/helpers/state-caster';
import { AnyAction } from 'redux';

import { combineActions } from 'redux-actions';

export const projectsReducer = {
  [fetchProjectsAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    projects: getList(payload.projects),
    programs: {
      ...state.programs,
      projects: payload.projects,
    },
  }),
  [fetchProjectsAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    programs: {
      ...state.programs,
      projects: {},
    },
  }),
  [removeProjectRowAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    const { key } = payload;
    let updatedProjects: any = {
      ...state.programs.projects,
    };
    delete updatedProjects[key];
    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProjects,
        projectCachedCounter: state.programs.projectCachedCounter - 1,
      },
    };
  },
  [addProjectAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    const { key, project } = payload;

    //TODO: add types
    let updatedProjects: any = {
      ...state.programs.projects,
      [project.projectID]: project,
    };

    delete updatedProjects[key];

    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProjects,
      },
    };
  },
  [addCachedProjectAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    const updatedProject: any = {
      ...state.programs.projects,
      [payload.project.key]: payload.project,
    };

    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProject,
        projectCachedCounter: state.programs.projectCachedCounter + 1,
      },
    };
  },
  [updateProjectAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    const updatedProject: any = {
      ...state.programs.projects,
      [payload.project.key]: payload.project,
    };

    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProject,
      },
    };
  },
  [removeProjectAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    let updatedProjects: any = { ...state.programs.projects };
    //delete updatedProjects[payload.projectID];
    window.location.reload();
    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProjects,
      },
    };
  },
  [combineActions(
    fetchProjectsAction.FAILURE,
    addProjectAction.FAILURE,
    updateProjectAction.FAILURE,
    removeProjectAction.FAILURE,
  ) as any]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    errorMessages: {
      ...state.errorMessages,
      [payload.error.code]: payload.error,
    },
  }),
};
