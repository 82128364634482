import { IProfessionalState } from '@app/back-office/professional.reducer';
import { fetchPtpSubmissionAction, updatePtpSubmissionAction, deletePtpSubmissionAction } from './ptp-submissions.actions'
import update from 'immutability-helper';
import { AnyAction } from 'redux';
import { combineActions } from 'redux-actions';
import { deleteHazardAction } from '@app/admin/hazard/state/hazard.actions';


export const ptpSubmissionsReducer = {
    [fetchPtpSubmissionAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            preTaskPlans: {
                ptpSubmissions: {
                    items: { $set: payload },
                    isLoading: { $set: false }
                },

            }
        }),
    [fetchPtpSubmissionAction.FULFILL]: (state: IProfessionalState) =>
        update(state, {
            preTaskPlans: {
                ptpSubmissions: {
                    items: { $set: {} },
                    isLoading: { $set: false }
                },
            }
        }),

    [updatePtpSubmissionAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            preTaskPlans: {
                ptpSubmissions: {
                    items: { $set: { ...state.preTaskPlans.ptpSubmissions.items, [payload.id]: payload } },
                    isLoading: { $set: false }
                },
            }
        }),

    [deleteHazardAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        const ptpSubmissions = { ...state.preTaskPlans.ptpSubmissions.items };
        delete ptpSubmissions[payload.id];

        return update(state, {
            preTaskPlans: {
                ptpSubmissions: {
                    items: { $set: ptpSubmissions },
                    isLoading: { $set: false }
                },
            }
        });
    },

    [combineActions(fetchPtpSubmissionAction.REQUEST, updatePtpSubmissionAction.REQUEST, deletePtpSubmissionAction.REQUEST) as any]: (
        state: IProfessionalState,
    ) => {
        return update(state, {
            preTaskPlans: {
                ptpSubmissions: {
                    isLoading: { $set: true }
                },
            }
        })
    },

}