import { takeLatest, call, put } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import {
  fetchService,
  cancelService,
  deleteService,
  updateService,
  addService,
  patchService,
} from '@rdx/services.saga';

import { message } from 'antd';

import { createTeamTearing } from '@shared/utils/add-team-tiering';

import {
  fetchAccountUsersAction,
  fetchUserCertificatesAction,
  removeAccountUserAction,
  toggleAccountsTeamSorterAction,
  updateAccountUserAction,
  resendUserInviteAction,
  mailCertificateUserAction,
  blockUsersAction,
  updateUserSeatAction,
  fetchUserAction,
  fetchProxyUsersAction,
  updateProxyUserAction,
  blockProxyUserAction,
} from './team.actions';

import { arrayToObject } from '@shared/helpers/state-caster';
import { UserCertificateType, UserType } from '@shared/types/backend.types';
import { fetchSeatsInfoAction } from '../../state/accounts.actions';

export function* fetchAccountsUsersRequest(action: AnyAction) {
  const endpoint = fetchAccountUsersAction.getEndpoint({ accountID: action.payload });

  try {
    let response = yield call(fetchService, endpoint);

    createTeamTearing(response, null, 1);

    // Casting object to dictionary

    const castedObject = arrayToObject(response, 'user_id');

    yield put(fetchAccountUsersAction.success({ team: castedObject }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fetchAccountUsersAction.failure({ title: 'Error while retrieving users.' }));
    }
  }
}

export function* fetchUserCertificatesRequest(action: AnyAction) {
  const endpoint = fetchUserCertificatesAction.getEndpoint({ id: action.payload });

  try {
    let response = yield call(fetchService, endpoint);

    response.map((res: UserCertificateType) => {
      res.certificateExpirationDate = new Date(
        res.certificateExpirationDate,
      ).toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: '2-digit' });
      res.certificateIssueDate = new Date(res.certificateIssueDate).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
    });

    yield put(fetchUserCertificatesAction.success({ certificates: response }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchUserCertificatesAction.failure({ title: 'errror while fecthing certificates.' }),
      );
    }
  }
}

function* resendUserInviteRequest(action: AnyAction) {
  const endpoint = resendUserInviteAction.getEndpoint({ userID: action.payload });

  try {
    yield call(fetchService, endpoint);
    message.success('Invite Resend Successfull');
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(resendUserInviteAction.failure('Invite Resend Failed'));
    }
  }
}

function* updateAccountUserRequest(action: AnyAction) {
  const { user, toUpdateUserId, accountId } = action.payload;

  try {
    const endpoint = updateAccountUserAction.getEndpoint({ userID: toUpdateUserId });

    message.loading({ content: 'Loading', key: 'updateUsers' });
    yield call(updateService, endpoint, user);

    if (accountId) {
      var usersEndPoint = fetchAccountUsersAction.getEndpoint({ accountID: accountId });
      let response = yield call(fetchService, usersEndPoint);

      createTeamTearing(response, null, 1);

      yield put(
        updateAccountUserAction.success({
          users: response,
        }),
      );
    }

    if (user.hasOwnProperty('password')) {
      message.success({ content: 'Password Updated', key: 'updateUsers' });
    } else if (
      user.hasOwnProperty('user_metadata') &&
      user.user_metadata.hasOwnProperty('powerBiKey')
    ) {
      message.success({ content: 'PowerBI Key Updated', key: 'updateUsers' });
    } else {
      message.success({ content: 'Associate Updated', key: 'updateUsers' });
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(updateAccountUserAction.failure("Job title couldn't be updated"));
      message.success({ content: "Associate can't be update", key: 'updateUsers' });
    }
  }
}

function* blockUsersRequest(action: AnyAction) {
  try {
    const endpoint = blockUsersAction.getEndpoint();
    var usersEndPoint = fetchAccountUsersAction.getEndpoint({
      accountID: action.payload.accountId,
    });

    message.loading({ content: 'Loading', key: 'blockUsers' });
    yield call(deleteService, endpoint, action.payload.toRemoveIDs);
    let response = yield call(fetchService, usersEndPoint);
    createTeamTearing(response, null, 1);
    yield put(
      updateAccountUserAction.success({
        users: response,
      }),
    );
    message.success({ content: 'Associates Removed', key: 'blockUsers' });
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(blockUsersAction.failure({ title: 'error when removing users' }));
      message.error({
        content: 'Error While Removing Associates!',
        key: 'blockUsers',
        duration: 2,
      });
    }
  }
}

function* removeAccountUserRequest(action: AnyAction) {
  const { userID } = action.payload;
  try {
    const endpoint = removeAccountUserAction.getEndpoint({ userID });

    yield call(deleteService, endpoint);
    yield put(removeAccountUserAction.success({ userID }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(removeAccountUserAction.failure({ title: 'error when removing user' }));
    }
  }
}

function* mailCertificateRequest(action: AnyAction) {
  try {
    const endpoint = mailCertificateUserAction.getEndpoint();

    message.loading({ content: 'Loading', key: 'mailCertificates' });
    yield call(addService, endpoint, action.payload);
    yield put(mailCertificateUserAction.success());
    message.loading({ content: 'Certificte Mailed', key: 'mailCertificates' });
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        mailCertificateUserAction.failure({ title: 'error while mailing the certificates' }),
      );
      message.error({ content: 'Error while mailing certificates', key: 'mailCertificates' });
    }
  }
}

function* updateUserSeatRequest(action: AnyAction) {
  try {
    const endpoint = updateUserSeatAction.getEndpoint({
      accountID: action.payload.accountId,
      userID: action.payload.userID,
    });
    const seatsendpoint = fetchSeatsInfoAction.getEndpoint();

    yield call(patchService, endpoint, { packageID: action.payload.packageID });
    const responseSeatsInfo: any = yield call(
      fetchService,
      seatsendpoint.replace(':accountID', action.payload.accountId),
    );

    if (responseSeatsInfo)
      yield put(
        fetchSeatsInfoAction.success({
          selectedAccountID: action.payload,
          seatsInfo: responseSeatsInfo.details,
        }),
      );
    else
      yield put(fetchSeatsInfoAction.success({ selectedAccountID: action.payload, seatsInfo: {} }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(updateUserSeatAction.failure({ title: 'role can not be updated. try again!' }));
    }
  }
}

function* fetchUserRequest({ payload }: AnyAction) {
  const { userID } = payload.params;

  try {
    const endpoint = fetchUserAction.getEndpoint({ userID });

    const user: UserType = yield call(fetchService, endpoint);

    yield put(fetchUserAction.success(user));
  } catch (error) {
    const {
      error: {
        response: { data },
        wasCancelled,
      },
    }: any = error;
    if (!wasCancelled) {
      yield put(fetchUserAction.failure({ title: data }));
    }
  }
}

function* fetchProxyUsersRequest({ payload }: AnyAction) {
  const { userID, status } = payload.params;

  try {
    const endpoint = `${fetchProxyUsersAction.getEndpoint({ userID })}?status=${status}`;

    const { data }: any = yield call(fetchService, endpoint);

    yield put(fetchProxyUsersAction.success(data));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchUserAction.failure({
          title: `can't get proxy users for requested user try again !!`,
        }),
      );
    }
  }
}

function* updateProxyUserRequest({ payload }: AnyAction): any {
  const { userID } = payload.params;

  try {
    const endpoint = updateProxyUserAction.getEndpoint({ userID });
    const user: UserType = yield call(updateService, endpoint, payload.body);
    yield put(updateProxyUserAction.success(user));
  } catch (error) {
    const {
      error: {
        wasCancelled,
        response: { data },
      },
    }: any = error;

    if (!wasCancelled) {
      yield put(fetchUserAction.failure({ title: data }));
    }
  }
}

function* blockProxyUserRequest({ payload }: AnyAction): any {
  const { userID, accountID } = payload.params;

  const usersEndpoint = fetchAccountUsersAction.getEndpoint({ accountID });

  try {
    const endpoint = blockProxyUserAction.getEndpoint({ userID });
    yield call(deleteService, endpoint, payload.body);
    yield put(blockProxyUserAction.success(userID));

    let response = yield call(fetchService, usersEndpoint);

    // Casting object to dictionary

    const castedObject = arrayToObject(response, 'user_id');

    yield put(fetchAccountUsersAction.success({ team: castedObject }));


  } catch (error) {
    const {
      error: {
        wasCancelled,
        response: { data },
      },
    }: any = error;

    if (!wasCancelled) {
      yield put(blockProxyUserAction.failure({ title: data }));
    }
  }
}

function* toggleAccountsTeamSorterTrigger(action: AnyAction) {
  yield put(toggleAccountsTeamSorterAction.fulfill(action.payload));
}

export function* accountsTeamSaga() {
  yield takeLatest(updateAccountUserAction.REQUEST, updateAccountUserRequest);
  yield takeLatest(fetchAccountUsersAction.REQUEST, fetchAccountsUsersRequest);
  yield takeLatest(fetchUserCertificatesAction.REQUEST, fetchUserCertificatesRequest);
  yield takeLatest(mailCertificateUserAction.REQUEST, mailCertificateRequest);
  yield takeLatest(removeAccountUserAction.REQUEST, removeAccountUserRequest);
  yield takeLatest(blockUsersAction.REQUEST, blockUsersRequest);
  yield takeLatest(updateUserSeatAction.REQUEST, updateUserSeatRequest);
  yield takeLatest(resendUserInviteAction.REQUEST, resendUserInviteRequest);
  yield takeLatest(fetchUserAction.REQUEST, fetchUserRequest);
  yield takeLatest(fetchProxyUsersAction.REQUEST, fetchProxyUsersRequest);
  yield takeLatest(updateProxyUserAction.REQUEST, updateProxyUserRequest);
  yield takeLatest(blockProxyUserAction.REQUEST, blockProxyUserRequest);
  yield takeLatest(removeAccountUserAction.FULFILL, cancelService);
  yield takeLatest(toggleAccountsTeamSorterAction.TRIGGER, toggleAccountsTeamSorterTrigger);
}
