import React, { useEffect, useState, SetStateAction, ChangeEvent } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { ErrorMessage } from '@shared/types/utils.types';
import { ContractorType, AccountType } from '@shared/types/backend.types';
import { ContractorRemovalPayload, ContractorUpdatePayload, ContractorSubmitPayload } from '../contractors';
import { AddContractorPayload, MappedContractor } from '@app/admin/accounts/contractors/contractors';

import { HeadingComponent } from '@components/layout/header/heading.component';

import { connect } from 'react-redux';

import { removeBackofficeErrorAction } from '@app/back-office/professional.action';
import {
  fetchContractorsAction,
  removeContractorAction,
  updateContractorAction,
  addContractorAction,
  addCachedContractorAction,
  removeContractorRowAction
} from '@app/admin/accounts/contractors/state/contractors.actions';

import { getUserCompany } from '@app/session/session.selector';

import {
  getContractorIsLoading,
  getAccountsContractors,
  getContractorCachedCounter,
  getErrorMessages,
} from '../state/contractors.selector';

import { getAccountID } from '@app/admin/invite/state/invite.selector';

import { ContractorsLockedComponent } from '../view/contractors.component';

import { invertedOrder } from '@constants/sort';
import { openNotificationWithIcon } from '@components/controls/notification/notification.component';
import '../view/contractors.style.scss';

interface IContractorsLockedProps {
  companyContractors: [];
  isLoading: boolean;
  contractorCachedCounter: number;
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
  selectedAccountID?: number;
  addContractorAction: (payload: ContractorSubmitPayload) => void;
  addCachedContractorAction: (payload: AddContractorPayload) => void;
  updateContractorAction: (payload: ContractorUpdatePayload) => void;
  removeContractorAction: (payload: ContractorRemovalPayload) => void;
  removeErrorAction: (code: any) => void;
  resetFetchContractorsAction: () => void;
  removeContractorRowAction: (payload: any) => void;
}

const ContractorsLocked = (props: IContractorsLockedProps) => {
  const { selectedAccountID, companyContractors, isLoading, contractorCachedCounter, errorMessages } = props;

  const {
    addContractorAction,
    addCachedContractorAction,
    updateContractorAction,
    removeContractorAction,
    removeErrorAction,
    removeContractorRowAction,
  } = props;

  //TODO: add types
  const [modifiedContractors, setModifiedContractors] = useState<SetStateAction<any>>({});
  const [sortSetup, setSortSetup] = useState<SetStateAction<any>>({ key: 'name', order: 'asc' });


  // Error handler
  useEffect(() => {
    const errors = Object.values(errorMessages);

    errors.forEach((key: any) => {
      key &&
        openNotificationWithIcon(
          'error',
          key.title,
          key.message,
          removeErrorAction({ code: key.code }),
        );
    });
  }, [errorMessages, removeErrorAction]);

  const handleUpdateContractor = (event: ChangeEvent<HTMLInputElement>, contractor: ContractorType) => {
    if (!contractor.contractorCompanyID) {
      setModifiedContractors({
        ...modifiedContractors,
        [contractor.key]: {
          ...modifiedContractors[contractor.key],
          key: `${contractor.key}`,
          [event.target.name]: event.target.value,
        },
      });
    } else {
      setModifiedContractors({
        ...modifiedContractors,
        [contractor.contractorCompanyID]: {
          ...contractor,
          [event.target.name]: event.target.value,
        },
      });
    }
  };


  const handleSaveContractor = (contractorCompanyID: number | string) => {
    const mappedContractor = {
      key: modifiedContractors[contractorCompanyID].key,
      contractorCompanyID: modifiedContractors[contractorCompanyID].contractorCompanyID,
      companyName: modifiedContractors[contractorCompanyID].companyName,
      companyAddress: modifiedContractors[contractorCompanyID].companyAddress,
    };

    if (selectedAccountID) {
      if (mappedContractor.contractorCompanyID) {
        updateContractorAction({ contractor: mappedContractor, accountID: selectedAccountID });
      } else {
        addContractorAction({
          contractor: mappedContractor as any,
          accountID: selectedAccountID,
        });
      }

      setModifiedContractors({ ...modifiedContractors, [contractorCompanyID]: undefined });
    }
  };

  const handleSort = (key: any) => {
    if (sortSetup.key === key) {
      setSortSetup({ order: invertedOrder[sortSetup.order], key });
    } else {
      setSortSetup({ order: 'asc', key });
    }
  };

  const handleRemoveContractor = (itemToRemove: ContractorType) => {
    if (selectedAccountID) {
      removeContractorAction({
        contractorCompanyID: itemToRemove.contractorCompanyID,
        accountID: selectedAccountID,
      });
    }
  };

  const handleRemoveTableItem = (key: string | number) => {
    removeContractorRowAction({ key });
  };

  var handleAddTableItem = () => {
    const newContractor: MappedContractor = {
      key: contractorCachedCounter,
      companyName: '',
      companyAddress: '',
    };
    addCachedContractorAction({ contractor: newContractor });
  };

  return (
    <div className="contractors-locked__container">
      <HeadingComponent title={`Contractor Company`} btnTitle={`Add Contractor Company`} onClkBtnFunction={handleAddTableItem} />
      <div className="sa__box-content">
        <div style={{ width: '100%', maxHeight: 700, overflowY: 'auto' }}>
          <ContractorsLockedComponent
            companyContractors={companyContractors}
            modifiedContractors={modifiedContractors}
            contractorCachedCounter={contractorCachedCounter}
            sorter={sortSetup}
            isLoading={isLoading}
            selectedAccountID={selectedAccountID}
            handleSort={handleSort}
            handleAddTableItem={handleAddTableItem}
            handleRemoveTableItem={handleRemoveTableItem}
            handleRemoveContractor={handleRemoveContractor}
            handleUpdateContractor={handleUpdateContractor}
            handleSaveContractor={handleSaveContractor}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  contractorCachedCounter: getContractorCachedCounter(state),
  isLoading: getContractorIsLoading(state),
  errorMessages: getErrorMessages(state),
});

const mapDispatchToProps = {
  addContractorAction: addContractorAction.request,
  addCachedContractorAction: addCachedContractorAction.trigger,
  updateContractorAction: updateContractorAction.request,
  removeContractorAction: removeContractorAction.request,
  removeErrorAction: removeBackofficeErrorAction.trigger,
  resetFetchContractorsAction: fetchContractorsAction.fulfill,
  removeContractorRowAction: removeContractorRowAction.trigger,
};



export const ContractorsLockedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractorsLocked);
