import React, { ReactNode, useState } from 'react';

import { UserType, AccountType } from '@shared/types/backend.types';

import MainHeaderComponent from '@components/layout/header/main-header/main-header.component';

import { Link } from 'react-router-dom';

import { Menu, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';

import { WrappedFormUtils } from 'antd/es/form/Form';

import { ROLES } from '@constants/roles';
import './navigation-menu.styles.scss';

const { SubMenu } = Menu;

interface INavigationMenuComponentProps {
  user: UserType;
  loggedUserAccesses: UserType[];
  userRole: string;
  accounts: AccountType[];
  selectedCompanyInformation: AccountType;
  isCreatingCompany?: boolean;
  isModalVisible?: boolean;
  //TODO: add types
  sidemenuPaths: any;
  hasWizard: boolean;
  location: Location;
  children?: ReactNode;
  handleLogout: () => void;
  handleShowHelpForm: () => void;
  validateKeys?: (event: any) => void;
  setAccountIDAction: (accountID: number) => void;
  handleToggleModal: (isUpdating: boolean, form?: WrappedFormUtils) => void;
  handleSaveAccount: (form: WrappedFormUtils) => void;
  handleUpdateAccount: (form: WrappedFormUtils) => void;
  setTeamAccessUserIdAction: (userID: string) => void;
}

const NavigationMenu = (props: INavigationMenuComponentProps) => {
  const {
    user,
    accounts,
    userRole,
    sidemenuPaths,
    location,
    selectedCompanyInformation,
    isCreatingCompany,
    isModalVisible,
    loggedUserAccesses,
    children,
  } = props;
  const {
    handleLogout,
    handleShowHelpForm,
    setAccountIDAction,
    validateKeys,
    handleToggleModal,
    handleSaveAccount,
    handleUpdateAccount,
    setTeamAccessUserIdAction,
  } = props;
  const [navigationMenu, toggleNavigationMenu] = useState(false);

  const getSelectDefaultSubMenu = () => {
    const result = [];

    for (const item of sidemenuPaths) {
      for (const child of item.children) {
        if (child.subMenu) {
          const matchedChild = child.subMenuChildren.find(
            (subChild: any) => subChild.path === location.pathname,
          );
          if (matchedChild) {
            result.push(matchedChild.localeID);
          }
        } else if (child.path === location.pathname) {
          result.push(child.localeID);
        }
      }
    }

    if (result.length === 0) {
      if (location.pathname === '/admin') {
        result.push('sidemenu.systemAdmin.account');
      } else {
        result.push('sidemenu.safetyProfessional.account');
      }
    }

    return result;
  };

  const renderSideList = () =>
    sidemenuPaths.map((item: any) => {
      return (
        <Menu.ItemGroup key={item.localeID}>
          {item.children &&
            item.children.map((item: any) => {
              if (item.subMenu) {
                return (
                  <SubMenu
                    key={item.localeID}
                    title={
                      <span>
                        {typeof item.icon === 'object' ? item.icon : <Icon type={item.icon} />}
                        <span>
                          <FormattedMessage id={item.localeID} />
                        </span>
                      </span>
                    }
                  >
                    {item.subMenuChildren.map((children: any) => (
                      <Menu.Item key={children.localeID}>
                        <Link to={children.path}>
                          {children.icon && <Icon type={children.icon} />}
                          <span>
                            <FormattedMessage id={children.localeID} />
                          </span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item key={item.localeID}>
                  <Link to={item.path}>
                    {item.icon && <Icon type={item.icon} />}
                    <span>
                      <FormattedMessage id={item.localeID} />
                    </span>
                  </Link>
                </Menu.Item>
              );
            })}
        </Menu.ItemGroup>
      );
    });

  return (
    <div className="main">
      <MainHeaderComponent
        user={user}
        accounts={accounts}
        selectedCompanyInformation={selectedCompanyInformation}
        isCreatingCompany={isCreatingCompany}
        isModalVisible={isModalVisible}
        loggedUserAccesses={loggedUserAccesses}
        navigationMenu={navigationMenu}
        handleLogout={handleLogout}
        setAccountIDAction={setAccountIDAction}
        validateKeys={validateKeys}
        toggleNavigationMenu={toggleNavigationMenu}
        handleToggleModal={handleToggleModal}
        handleUpdateAccount={handleUpdateAccount}
        handleSaveAccount={handleSaveAccount}
        setTeamAccessUserIdAction={setTeamAccessUserIdAction}
      />

      <div className="main__body">
        <Menu
          style={{ maxWidth: 256, minWidth: 240 }}
          defaultSelectedKeys={getSelectDefaultSubMenu()}
          mode="vertical"
          inlineCollapsed={navigationMenu}
        >
          {renderSideList()}

          {userRole === ROLES.safetyProfessional && (
            <div className="need-help">
              <span onClick={() => handleShowHelpForm()}>Need help</span>
              <i className="far fa-question-circle"></i>
            </div>
          )}
        </Menu>

        {children}
      </div>
    </div>
  );
};

export const NavigationMenuComponent = NavigationMenu;
