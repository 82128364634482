import { UserType } from "@shared/types/backend.types";

export const getUserManagers = (users: UserType[], user: UserType): UserType[] => {

    const managers: UserType[] = [];

    if (!user) return managers;

    if (user.user_metadata.reportsToUserID === '') {
        return managers;
    }

    while (true) {
        const manager = users.find((x: UserType) => x.user_id === user.user_metadata.reportsToUserID);

        if (!manager) break;

        managers.push(manager);
        user = manager;
    }

    return managers;
}

export const filterReportsToUsers = (accountUsers: UserType[], toFilterUsers: UserType) => {
    const subUsers: UserType[] = [];
    const userStack: UserType[] = [];

    subUsers.push(toFilterUsers)
    userStack.push(toFilterUsers);

    while (userStack.length > 0) {
        const currentUser = userStack.pop();

        if (currentUser) {
            const reportsToUsers = accountUsers.filter(user => user.user_metadata.reportsToUserID === currentUser.user_id);
            for (const user of reportsToUsers) {
                subUsers.push(user);
                userStack.push(user);
            }
        }
    }

    return subUsers;
}