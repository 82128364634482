import React, { useEffect, useState, Fragment, useRef } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { FetchSubmissionsPayload } from '../forms';
import { UserType, ProjectType, Submission, AccountType } from '@shared/types/backend.types';

import { connect } from 'react-redux';
import {
  fetchProjectFormsAction,
  fetchSPProjectAction,
  fetchFormPDFAction,
  changeSelectedProjectAction,
  getSelectedFormPDFAction,
} from './submissions.actions';
import { getSessionUser, getUserCompany } from '@app/session/session.selector';
import {
  getProjectSubmissionsLoading,
  getProjectSubmissions,
  getSubmissionsSelectedProjectID,
  getSelectedProjectSubmission,
  getIsLoadingSubmissionPDF,
  getSubmissionsSelectedPDF,
} from './submissions.selector';
import { getProjects } from '@app/back-office/professional.selector';

import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';
import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';

import { ProjectFormComponent } from '../view/submissions.component';

import _ from 'lodash';
import { invertedOrder } from '@constants/sort';
import moment from 'moment';
import { getTeamAccessUserID } from '@app/back-office/team/state/team.selector';
import { message } from 'antd';
import Cookies from 'js-cookie';
import axios from 'axios';
import { FETCH_FORM_PDF_API } from '@constants/endpoints';
import { useRenderPDF } from '@shared/hooks/usepdf';

interface IFilters {
  formName?: string | undefined;
  user?: string | undefined;
  safetyPlanName?: string | undefined;
  dateRange?: string[] | undefined;
  isCompleted?: boolean | number;
}

interface IProjectFormsProps {
  projects: ProjectType[];
  submissions: Submission[];
  isFecthLoading: boolean;
  isLoadingPDF: boolean;
  user?: UserType;
  team: UserType[];
  teamAccessUserID?: string;
  selectedProjectID?: number;
  selectedSubmission?: Submission;
  formPDF?: string;
  fetchProjectsAction: (accountID: number) => void;
  fetchFormsAction: (payload: FetchSubmissionsPayload) => void;
  fetchFormPDFAction: (payload: any) => void;
  changeSelectedProjectAction: (projectID: number | undefined) => void;
  getSelectedFormPDFAction: (formID: string) => void;
  resetFormsAction: () => void;
  fetchTeamAction: (accountID: number) => void;
}

export const ProjectForms = (props: IProjectFormsProps) => {
  const {
    user,
    team,
    projects,
    submissions,
    selectedProjectID,
    selectedSubmission,
    teamAccessUserID,
    isFecthLoading,
    isLoadingPDF,
    formPDF,
  } = props;
  const {
    fetchProjectsAction,
    fetchFormsAction,
    fetchFormPDFAction,
    resetFormsAction,
    changeSelectedProjectAction,
    getSelectedFormPDFAction,
    fetchTeamAction,
  } = props;

  const [sortSetup, setSortSetup] = useState({ key: 'user.name', order: 'asc' });
  const [filteredSubmisions, setFilteredSubmisions] = useState(submissions);
  const [isLoading, setIsLoading] = useState(true);
  const [orderedSubmisions, setorderedSubmisions] = useState(submissions);
  const [distinctNames, setDistinctNames] = useState({});
  const [distinctPrograms, setDistinctPrograms] = useState([] as any);
  const [submittedReport, setsubmittedReport] = useState([] as any);
  const [filters, setFilters] = useState<IFilters>({});
  const [underUser, setUnderUser] = useState<UserType[]>([]);
  const [underSubmissions, setunderSubmissions] = useState([] as any);

  const ref = useRef<HTMLAnchorElement | undefined>(undefined);

  const { RenderPdf } = useRenderPDF(ref);

  useEffect(() => {
    if (underSubmissions) {
      setDistinctNames(Array.from(new Set(underSubmissions.map((x: Submission) => x.user.name))));
      setDistinctPrograms(
        Array.from(new Set(underSubmissions.map((x: Submission) => x.safetyPlanName))),
      );
      setsubmittedReport(Array.from(new Set(underSubmissions.map((x: Submission) => x.formName))));
      setFilteredSubmisions(underSubmissions);
      setorderedSubmisions(underSubmissions);
    }
  }, [underSubmissions]);

  useEffect(() => {
    var data: Submission[] = underSubmissions;

    _.forEach(filters, function (value: any, key: string) {
      if (value === 'All') {
        const TempObject = { ...filters };
        delete TempObject[key as keyof IFilters];
        setFilters({ ...TempObject });
      }
      if (key === 'dateRange')
        data = _.filter(data, d =>
          moment(d.submissionDateTime).isBetween(value[0], value[1], 'minutes'),
        );
      else if (key === 'isCompleted') {
        data = data.filter(submission =>
          value === 2 ? data : submission.isCompleted === Boolean(value),
        );
      } else {
        if (key === 'user') key = 'user.name';
        data = _.filter(data, [key, value]);
      }
    });

    setFilteredSubmisions(data);

    if (!isFecthLoading) setIsLoading(false);
  }, [filters, underSubmissions]);

  // Handles initial project fetching
  useEffect(() => {
    if (user && user.user_metadata.accountID) {
      fetchProjectsAction(user.user_metadata.accountID);
    }
  }, [user, fetchProjectsAction]);

  useEffect(() => {
    if (user && user.user_metadata.accountID && team.length === 0) {
      fetchTeamAction(user.user_metadata.accountID);
    }
  }, [team, fetchTeamAction]);

  // Handles form fetching
  useEffect(() => {
    if (user) {
      fetchFormsAction({
        accountID: user.user_metadata.accountID,
        projectID: selectedProjectID,
      });
      setIsLoading(true);
    }

    return () => {
      resetFormsAction();
    };
  }, [selectedProjectID]);


  useEffect(() => {
    if (teamAccessUserID) {
      setUnderUser([]);
      getUnderUsers(team, teamAccessUserID);
    }
  }, [team, teamAccessUserID]);

  useEffect(() => {
    submissionsFilter(submissions);
  }, [submissions, underUser]);

  function getUnderUsers(data: UserType[], accessUserID: string): any {
    let children: UserType[] = [];
    if (accessUserID) {
      children = data.filter(
        (item: UserType) => item.user_metadata.reportsToUserID === accessUserID,
      );
    }

    if (children.length === 0) return null;

    children.map(item => {
      setUnderUser((prev: any) => [...prev, item]);
      return getUnderUsers(data, item.user_id);
    });
  }

  function submissionsFilter(submissions: Submission[]) {
    const temp: Submission[] = [];

    if (user && !user.user_metadata.isProxyUser && (!user.user_metadata.proxyUsers || !user.user_metadata.proxyUsers.length))
      temp.push(
        ...submissions.filter(
          (submission: Submission) => submission.user.user_id === (user as UserType).user_id,
        ),
      );


    underUser.map((underUser: UserType) => {
      temp.push(
        ...submissions.filter(
          (submission: Submission) => submission.user.user_id === underUser.user_id,
        ),
      );
    });

    setunderSubmissions(temp);
  }

  const handleChangeSelectedProject = (projectID: string) => {
    if (projectID === 'All' && user) {
      changeSelectedProjectAction(undefined);
    } else {
      changeSelectedProjectAction(+projectID);
      setDistinctNames(Array.from(new Set(filteredSubmisions.map(x => x.user.name))));
    }
  };

  const handleChangeSelectedUser = (user: string) => {
    setFilters({
      ...filters,
      user,
    });
  };

  const handleChangeSelectedProgram = (safetyPlanName: string) => {
    setFilters({
      ...filters,
      safetyPlanName,
    });
  };

  const handleChangesubmittedReport = (formName: string) => {
    setFilters({
      ...filters,
      formName,
    });
  };

  const handleChangeDateRange = (dateRange: string[]) => {
    setFilters({
      ...filters,
      dateRange,
    });
    if (dateRange[0] === '' || dateRange[1] === '') {
      const TempObject = { ...filters };
      delete TempObject.dateRange;
      setFilters({ ...TempObject });
    }
  };

  const handleChangeReportStatus = (isCompleted: number) => {
    setFilters({
      ...filters,
      isCompleted,
    });
  };

  const handleFetchPDF = (submissionID: string) => {
    if (user && selectedProjectID) {
      fetchFormPDFAction({ accountID: user.accountID, projectID: selectedProjectID, submissionID });
    }
  };

  const handleSelectForm = (submissionID: string) => {
    getSelectedFormPDFAction(submissionID);
    handleFetchPDF(submissionID);
  };

  const handleSort = (key: any) => {
    if (sortSetup.key === key) {
      setSortSetup({ order: invertedOrder[sortSetup.order], key });
    } else {
      setSortSetup({ order: 'asc', key });
    }
  };

  const fetchSubmissionPdf = async (submissionId: String) => {
    try {
      message.loading({ key: "submissionPdf", content: "Loading..." });
      const token = Cookies.get('sessionKey') || "";
      const endpoint = FETCH_FORM_PDF_API.replace(':submissionID', `${submissionId}`);
      const res = await axios.get(endpoint, { headers: { Authorization: `Bearer ${token}` } })
      if (res && res.data && res.data.pdfLocation && ref && ref.current) {
        ref.current.href = res.data.pdfLocation
        ref.current.click();
        message.success({ key: "submissionPdf", content: "Success" });
      } else throw new Error();

    } catch (error) {
      message.error({ key: "submissionPdf", content: "Error while fetching submission pdf!" })
    }
  }

  useEffect(() => {
    if (sortSetup.key != 'date') {
      setorderedSubmisions(
        _.orderBy(filteredSubmisions, [sortSetup.key], [sortSetup.order as any]),
      );
    }

    if (sortSetup.key === 'date') {
      if (sortSetup.order == 'asc')
        setorderedSubmisions(_.sortBy(filteredSubmisions, f => new Date(f.date)));
      if (sortSetup.order == 'desc')
        setorderedSubmisions(_.sortBy(filteredSubmisions, f => new Date(f.date)).reverse());
    }
  }, [filters, sortSetup, filteredSubmisions]);

  return (
    <ProjectFormComponent
      projects={projects}
      submissions={orderedSubmisions}
      selectedProjectID={selectedProjectID}
      selectedSubmission={selectedSubmission}
      isLoading={isLoading}
      isLoadingPDF={isLoadingPDF}
      sortSetup={sortSetup}
      fileBlob={formPDF || ''}
      handleSort={handleSort}
      RenderPdf={RenderPdf}
      fetchSubmissionPdf={fetchSubmissionPdf}
      handleChangeSelectedProject={handleChangeSelectedProject}
      handleChangeSelectedUser={handleChangeSelectedUser}
      handleChangeSelectedProgram={handleChangeSelectedProgram}
      handleChangesubmittedReport={handleChangesubmittedReport}
      handleChangeReportStatus={handleChangeReportStatus}
      distinctNames={distinctNames}
      distinctPrograms={distinctPrograms}
      submittedReport={submittedReport}
      handleChangeDateRange={handleChangeDateRange}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
  team: getAccountsTeam(state),
  projects: getProjects(state),
  submissions: getProjectSubmissions(state),
  isFecthLoading: getProjectSubmissionsLoading(state),
  isLoadingPDF: getIsLoadingSubmissionPDF(state),
  selectedProjectID: getSubmissionsSelectedProjectID(state),
  selectedSubmission: getSelectedProjectSubmission(state),
  formPDF: getSubmissionsSelectedPDF(state),
  teamAccessUserID: getTeamAccessUserID(state),
});

const mapDispatchToProps = {
  fetchProjectsAction: fetchSPProjectAction.request,
  fetchTeamAction: fetchAccountUsersAction.request,
  fetchFormsAction: fetchProjectFormsAction.request,
  changeSelectedProjectAction: changeSelectedProjectAction.trigger,
  getSelectedFormPDFAction: getSelectedFormPDFAction.trigger,
  fetchFormPDFAction: fetchFormPDFAction.request,
  resetFormsAction: fetchProjectFormsAction.fulfill,
};

export const ProjectFormsContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectForms);
