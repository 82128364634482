import React, { FormEvent } from 'react';

import { Classes } from 'jss';
import { ThemeType } from '@shared/types/theme.type';
import { PackageSeatDetail } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { FormComponentProps } from 'antd/es/form';

import { useIntl } from 'react-intl';

import { Modal, Button, Form, Input } from 'antd';

import injectSheet from 'react-jss';
import classnames from 'classnames';

import './add-package.styles.scss';

export interface IAddPackageProps {
  form: any;
  isPModalVisible: boolean;
  classes: Classes;
  isLoading?: boolean;
  imageValidation?: any;
  title?: string;
  PackageID?: string;
  Seats?: any;
  editMode?: boolean;
  validateKeys: (event: any) => void;
  handlePCloseModal: (isUpdating: boolean, form?: WrappedFormUtils) => void;
  handleSavePackage: (form: WrappedFormUtils) => void;
  user: any;
}

const AddPackage = (props: IAddPackageProps) => {
  const { form, isPModalVisible, isLoading, title, PackageID, Seats, editMode, user } = props;
  const { handlePCloseModal, handleSavePackage, validateKeys } = props;
  const { modalTitleStyles, formLabelStyles } = props.classes;
  const { getFieldDecorator } = form;
  const intlProvider = useIntl();

  return (
    <Modal
      title={
        title ? <span>{title}</span> : <span>Package Information</span>
      }
      visible={isPModalVisible}
      closeIcon={<i className="fas fa-times" onClick={() => handlePCloseModal(false, form)} />}
      footer={
        <div className="d-flex justify-center">
          <Button
            className="btn__supervisors btn--cancel"
            onClick={() => handlePCloseModal(false, form)}
          >
            {intlProvider.messages['buttons.cancel']}
          </Button>
          <Button
            className="btn__supervisors btn--white"
            onClick={() => handleSavePackage(form)}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        style={{ margin: '30px 15px' }}
        className="d-flex flex-wrap sm-w-100"
        layout="vertical"
        onSubmit={(event: FormEvent<HTMLFormElement>) => { }}
        hideRequiredMark
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <Form.Item key={PackageID} className={classnames('input--width-50 invite__form-label mb-0 pb-0', formLabelStyles)}>
            {getFieldDecorator('PackageID', {
              initialValue: PackageID ? PackageID : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.requiredLicenseType'],
                },
              ],
            })(
              <select
                className="input--width-100 input--medium-height input--transparent"
                placeholder="Select Licence Type"
                disabled={editMode}
                required
              >
                <option key={''} value={''}>Select Licence Type</option>
                <option key={'SA-EM-385-1-1'} value={'SA-EM-385-1-1'}>EM 385-1-1</option>
                <option key={'SA-OSHA'} value={'SA-OSHA'}>OSHA</option>
                <option key={'SA-POWER-BI-API'} value={'SA-POWER-BI-API'}>POWER-BI-API</option>
                <option key={'SA-TRADESMAN'} value={'SA-TRADESMAN'}>TRADESMAN</option>
                <option key={'SA-ADMINISTRATOR'} value={'SA-ADMINISTRATOR'}>ADMINISTRATOR</option>
                <option key={'SA-COMMANDER'} value={'SA-COMMANDER'}>COMMANDER</option>
                <option key={'SA-CRANES-SAFETY'} value={'SA-CRANES-SAFETY'}>CRANES SAFETY</option>
              </select>,
            )}
          </Form.Item>
          <Form.Item key={Seats} className={classnames('input--width-50 invite__form-label mb-0 pb-0', formLabelStyles)}>
            {getFieldDecorator('Seats', {
              initialValue: Seats ? Seats : '',
              rules: [
                {
                  required: true,
                  number: true,
                  message: intlProvider.messages['inputs.requiredNoLicences'],
                },
              ],
            })(
              <Input
                className="input--width-100 input--medium-height input--transparent"
                placeholder={'No. of Licences'}
                type="number"
                required
              />,
            )}
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

const styles = (theme: ThemeType) => ({
  modalTitleStyles: {
    color: theme.grey.fourth,
  },
  formLabelStyles: {
    '& .ant-form-item-label label': {
      ...theme.inputs.label,
      color: theme.grey.fourth,

    },
  },
});

export const AddPackageStyled: any = injectSheet(styles)(AddPackage);
export const AddPackageComponent: any = Form.create({ name: 'addPackage' })(AddPackageStyled);
