import React, { createRef, ChangeEvent } from 'react';

import { UserType } from '@shared/types/backend.types';

import { Popconfirm } from 'antd';
import { SorterComponent } from '@components/controls/table/sorter.component';

import { isProjectValid } from '../../programs.validations';
//TODO: Add sort icons with asc and desc statuses.
import { EmptyRowComponent } from '@components/controls/table/empty-row.component';

//TODO: add type
export interface IProjectsTableProps {
  projects: [];
  handleSort: (key: any) => void;
  handleUpdateForm: (targetKey: string, e: string, key: string) => void;
  handleAddTableItem: (item: any) => void;
  handleRemoveTableItem: (user: UserType) => void;
}

export const ProjectsTableComponent = (props: IProjectsTableProps) => {
  const { projects } = props;
  const { handleSort, handleUpdateForm, handleAddTableItem, handleRemoveTableItem } = props;
  const tableRef: any = createRef();

  const renderEditableRow = () => {
    if (projects.length === 0) {
      return <EmptyRowComponent colspan={6} text="No projects available" />;
    }

    return projects.map((item: any) => {
      if (item.number === '') {
        return (
          <tr key={item.key} className={isProjectValid(item) ? '' : 'is-invalid'}>
            <td>
              <input
                defaultValue={item.number || ''}
                placeholder={item.number || 'Add Project Number'}
                autoFocus
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('number', e.target.value, item.key)
                }
              />
            </td>
            <td>
              <input
                defaultValue={item.name || ''}
                placeholder={item.name || 'Add Project Name'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('name', e.target.value, item.key)
                }
              />
            </td>
            <td>
              <input
                defaultValue={item.address || ''}
                placeholder={item.address || 'Add Project Address'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('address', e.target.value, item.key)
                }
              />
            </td>
            <td>
              <Popconfirm
                placement="topRight"
                title="Are you sure you want to delete?"
                onConfirm={() => handleRemoveTableItem(item.key)}
                okText="Yes"
              >
                <i
                  className="far fa-trash-alt"
                  style={{ padding: '16px 16px !important', marginLeft: 15, cursor: 'pointer' }}
                ></i>
              </Popconfirm>
            </td>
          </tr>
        );
      }
      return (
        <tr key={item.key} className={isProjectValid(item) ? '' : 'is-invalid'}>
          <td>
            <input
              defaultValue={item.number || ''}
              placeholder={item.number || 'Add Project Number'}
              autoFocus
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('number', e.target.value, item.key)
              }
            />
          </td>
          <td>
            <input
              defaultValue={item.name || ''}
              placeholder={item.name || 'Add Project Name'}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('name', e.target.value, item.key)
              }
            />
          </td>
          <td>
            <input
              defaultValue={item.address || ''}
              placeholder={item.address || 'Add Project Address'}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('address', e.target.value, item.key)
              }
            />
          </td>
          <td>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete?"
              onConfirm={() => handleRemoveTableItem(item.key)}
              okText="Yes"
            >
              <i
                className="far fa-trash-alt"
                style={{ padding: '16px 16px !important', marginLeft: 15, cursor: 'pointer' }}
              ></i>
            </Popconfirm>
          </td>
        </tr>
      );
    });
  };

  return (
    <table
      ref={tableRef}
      className="table table__regular"
      style={{ width: '100%', textAlign: 'center' }}
    >
      <thead>
        <tr>
          <th onClick={() => handleSort('number')}>
            Project Number
            <SorterComponent status="asc" />
          </th>
          <th onClick={() => handleSort('name')}>
            Project Name
            <SorterComponent status="asc" />
          </th>
          <th onClick={() => handleSort('address')}>
            Project Address
            <SorterComponent status="asc" />
          </th>
          <th
            className="add-project-btn add-btn"
            style={{ cursor: 'pointer', padding: '10px 5px' }}
            onClick={() => {
              handleAddTableItem({});
            }}
          >
            <i className="far fa-plus-square " style={{ paddingRight: '5px' }}></i>
            Add Project
          </th>
        </tr>
      </thead>
      <tbody>{renderEditableRow()}</tbody>
    </table>
  );
};
