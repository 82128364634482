import React from 'react';
import { Button } from 'antd';

interface IHeadingComponentProps {
  title: string;
  btnTitle?: string;
  icon?: React.ReactNode;
  onClkBtnFunction?: () => void;
  onClkModalFunction?: (visible: boolean) => void;
}

export const HeadingComponent = ({
  title,
  btnTitle,
  icon,
  onClkBtnFunction,
}: IHeadingComponentProps) => {
  return (
    <div className="sa__box-heading">
      <h4 className="sa__box-heading-title">{title}</h4>
      {onClkBtnFunction ? (
        <Button type="primary" onClick={() => onClkBtnFunction()}>
          {icon && icon}
          {btnTitle}
        </Button>
      ) : null}
    </div>
  );
};
