import { IProfessionalState } from '@app/back-office/professional.reducer';
import { AnyAction } from 'redux';
import { ErrorMessage } from '@shared/types/utils.types';

import { fetchPlansInstanceAction, changeFormStatusAction } from './safety-programs.actions';

import update from 'immutability-helper';
import { createRoutineReducer } from '@shared/helpers/redux-saga-endpoints';

import { salesFormReducer} from '../contact-sales/state/sales-form.reducer';

export interface IBackOfficeProgramsState {
  items: any;
  isLoading: boolean;
  activeDropper?: number;
  isSalesFormSubmiting: boolean;
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
}

export const BACKOFFICE_PROGRAMS_INITIAL_STATE: IBackOfficeProgramsState = {
  ...createRoutineReducer(),
  items: [],
  isLoading: false,
  isSalesFormSubmiting: false,
  errorMessages: {},
};

export const safetyProgramsReducer = {
  ...salesFormReducer,
  [fetchPlansInstanceAction.REQUEST]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        isLoading: { $set: true },
      },
    }),
  [fetchPlansInstanceAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        items: { $set: payload.safetyPlans },
        isLoading: { $set: false },
      },
    }),
  [fetchPlansInstanceAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      safetyPrograms: {
        isLoading: { $set: false },
      },
    }),
  [changeFormStatusAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    const { isValid, id, safetyPlanID } = payload;

    const safetyPlansUpdated = [...state.safetyPrograms.items].map((safetyPlan: any) => {
      if (safetyPlan.safetyPlanID === safetyPlanID) {
        return update(safetyPlan, {
          forms: {
            [id]: { isSelected: { $set: isValid } },
          },
        });
      }
      return safetyPlan;
    });

    return update(state, {
      safetyPrograms: {
        items: { $set: safetyPlansUpdated },
      },
    });
  },
};
