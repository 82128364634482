import {
  changeSetupProjectAction,
  addSetupProjectAction,
  removeSetupProjectAction,
  validateProjectsStepAction,
} from './project.actions';

import { IProfessionalState } from '@app/back-office/professional.reducer';
import { AnyAction } from 'redux';

//TODO: add type
export const setupProjectsReducer = {
  [addSetupProjectAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    const updatedProject: any = {
      ...state.programs.projects,
      [payload.project.key]: payload.project,
    };

    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProject,
        projectCachedCounter: state.programs.projectCachedCounter + 1,
      },
    };
  },
  [changeSetupProjectAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    const updatedProject: any = {
      ...state.programs.projects,
      [payload.key]: payload.projectUpdate,
    };

    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProject,
      },
    };
  },
  [removeSetupProjectAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    let updatedProjects: any = { ...state.programs.projects };
    delete updatedProjects[payload.key];

    return {
      ...state,
      programs: {
        ...state.programs,
        projects: updatedProjects,
      },
    };
  },
  [validateProjectsStepAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    let updatedSteps: any = [...state.programs.meta.validSteps];
    updatedSteps[payload.step] = payload.isValid;

    return {
      ...state,
      programs: {
        ...state.programs,
        meta: {
          ...state.programs.meta,
          validSteps: updatedSteps,
        },
      },
    };
  },
};
