import React, { useEffect, useState, SetStateAction, FormEvent } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { History } from 'history';
import { SessionLoginForm, UseHasSession } from '../login.d';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { ErrorMessage } from '@shared/types/utils.types';
import { submitLoginAction } from '@session/session.action';
import { changePathRedirectionAction } from '@layout/layout.action';

import { getPathRedirection } from '@layout/layout.selector';
import {
  getIsSignedIn,
  getIsLoadingSession,
  getSessionErrors,
  getSessionUser,
} from '@session/session.selector';

import Swal from 'sweetalert2';
import { LoginFormComponent } from '../view/login.component';
import { LoadingSpinnerComponent } from '@controls/loading/spinner.component';

import Cookies from 'js-cookie';
import { REDIRECTION_PATHS } from '@constants/paths';
import { getUserSession, removeUserSession } from '@services/storage/session.service';

import '@session/session.styles.scss';

export interface ILoginContainerProps {
  userRole: string;
  isSignedIn: boolean;
  isFormSubmitting: boolean;
  mobile: boolean;
  //TODO: add types to errorMessages
  sessionErrorMessages: {
    [key: string]: any;
  };
  history: History;
  pathRedirection?: string;
  submitLoginAction: (form: SessionLoginForm) => void;
  changePathRedirectionAction: (value: string) => void;
}

const LoginForm = (props: ILoginContainerProps) => {
  const {
    isSignedIn,
    isFormSubmitting,
    sessionErrorMessages,
    history,
    pathRedirection,
    mobile,
  } = props;
  const { submitLoginAction, changePathRedirectionAction } = props;
  const [hasSession, setHasSession] = useState<SetStateAction<UseHasSession>>(undefined);
  const [willStoreSession, setWillStoreSession] = useState<SetStateAction<Boolean>>(false);

  useEffect(() => {
    const session = Cookies.get('sessionKey');

    let decodedJwt = null

    if (session) {
      decodedJwt = JSON.parse(atob(session.split('.')[1]));

      if (decodedJwt.exp * 1000 < Date.now()) {
        Cookies.remove('sessionKey');
        setHasSession(false);
        return history.push('/');
      }

      return setHasSession(true);
    }
    setHasSession(false);
  }, [hasSession, isSignedIn]);

  useEffect(() => {

    let err = sessionErrorMessages && sessionErrorMessages.error;
    err = err && err.response.data.error_description;
    
    if (err && err === 'user is blocked') {
      Swal.fire({
        title: 'Account Inactive',
        html: `<span>Your Safety Assist account is inactive. <br/> Contact <b>support@velocityvue.com</b> to reactivate your account</span>`,
        type: 'warning',
        confirmButtonColor: '#0B734F',
      }).then(() => history.push('/login'));
    }
  }, [sessionErrorMessages])


  useEffect(() => {
    if (mobile && sessionErrorMessages && !isFormSubmitting) {
      Swal.fire({
        title: 'Mobile user cannot access the Admin Portal',
        text: 'Please sign in on mobile app',
        type: 'warning',
        confirmButtonColor: '#0B734F',
      }).then(() => history.push('/login'));
    }


  }, [sessionErrorMessages, isFormSubmitting, history, mobile]);

  const __handleRedirection = () => {
    if (pathRedirection === '/login') {
      history.push('/');
      Cookies.remove('sessionKey');
      removeUserSession();
      changePathRedirectionAction('/admin/accounts')
    }
    else {
      const session = getUserSession();
      if (hasSession && session && (history && history.push)) {
        const nextRedirection = pathRedirection || REDIRECTION_PATHS[session.userRole as any];
        history.push(nextRedirection);
      }
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils): void => {
    event.preventDefault();
    form.validateFieldsAndScroll((err: string, formValues: SessionLoginForm) => {
      if (!err) {
        if (!willStoreSession) {
          removeUserSession();
        }
        submitLoginAction(formValues);
        setHasSession(isSignedIn);
      }
    });
  };

  const handleStorePassword = (e: CheckboxChangeEvent): void => {
    setWillStoreSession(e.target.checked);
  };

  __handleRedirection();

  if (hasSession === undefined) {
    return <LoadingSpinnerComponent />;
  }

  return (
    <LoginFormComponent
      isFormSubmitting={isFormSubmitting}
      sessionErrorMessages={sessionErrorMessages ? sessionErrorMessages.error : null}
      handleSubmit={handleSubmit}
      handleStorePassword={handleStorePassword}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  userRole: getSessionUser(state),
  isSignedIn: getIsSignedIn(state),
  isFormSubmitting: getIsLoadingSession(state),
  sessionErrorMessages: getSessionErrors(state),
  pathRedirection: getPathRedirection(state),
  mobile: state.session.mobile,
});

const mapDispatchToProps = {
  submitLoginAction: submitLoginAction.request,
  changePathRedirectionAction: changePathRedirectionAction.trigger,
};

const LoginFormWithRouter: any = withRouter(LoginForm as any);

export const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginFormWithRouter);
