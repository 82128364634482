import React from 'react';

import { PlanListComponent } from '../view/list.component';

export interface IPlanContainerProps {
  //TODO: add types
  plans: any;
  stepTracker: any;
  currentSubstep: any;
  handleChangeCurrentPlan: (nextStep: number, isFinished?: boolean) => void;
}

export const PlansListContainer = (props: IPlanContainerProps) => {
  const { plans, stepTracker, currentSubstep } = props;
  const { handleChangeCurrentPlan } = props;

  return (
    <PlanListComponent
      plans={plans}
      steps={stepTracker}
      currentStep={currentSubstep}
      handleChangeCurrentPlan={handleChangeCurrentPlan}
    />
  );
};
