import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';

import {
  submitRegisterAction,
  submitLoginAction,
  fetchUserAction,
  fetchUserProfileAction,
  submitPasswordRestoreAction,
  fetchUserRegisterAction,
  setAccountIdAction,
} from './session.action';

import { FETCH_USER_API, FETCH_USER_PROFILE_API } from '@constants/endpoints';

import {
  AUTH0_BASE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_CLIENT_SECRET,
  AUTH0_GRANT_TYPE,
  AUDIENCE,
  SCOPE,
} from '@services/axios/axios.config';
import { AxiosService } from '@services/axios/axios.service';
import Cookies from 'js-cookie';
import { ROLE, ROLES } from '@constants/roles';
import { setUserSession, removeUserSession } from '@services/storage/session.service';
import { updateService } from '@rdx/services.saga';
import { setAccountIDAction } from '@app/admin/admin.action';

let sessionService: AxiosService;

function* cancelService(action: AnyAction) {
  yield sessionService.cancelRequest(action.payload.msg);
}

function* submitRegisterRequest(action: AnyAction) {
  const { user, inviteID } = action.payload;

  try {
    const endpoint = submitRegisterAction.getEndpoint({ inviteID });
    yield call(updateService, endpoint, user);

    yield put(submitRegisterAction.success({}));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(submitRegisterAction.failure({ title: 'Error when submitting register' }));
    }
  }
}

function* submitPasswordRestoreService(endpoint: string, username: string) {
  const bodyForm = {
    client_id: AUTH0_CLIENT_ID,
    connection: 'Username-Password-Authentication',
    username,
  };

  sessionService = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield sessionService.post({
    body: bodyForm,
  });

  return data;
}

function* submitPasswordRestoreRequest(action: AnyAction) {
  try {
    const endpoint = submitPasswordRestoreAction.getEndpoint();
    yield call(submitPasswordRestoreService, endpoint, action.payload);

    yield put(submitPasswordRestoreAction.success());
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(submitPasswordRestoreAction.failure({ title: 'Error when restarting password.' }));
    }
  }
}

function* submitLoginService(email: string, password: string) {
  const loginRequestBody = {
    client_id: AUTH0_CLIENT_ID,
    client_secret: AUTH0_CLIENT_SECRET,
    audience: AUDIENCE,
    scope: SCOPE,
    grant_type: AUTH0_GRANT_TYPE,
    username: email,
    password,
  };

  sessionService = new AxiosService(`${AUTH0_BASE_URL}/oauth/token`);

  const { data }: AxiosResponse = yield sessionService.post({
    body: loginRequestBody,
  });

  return data;
}

function* sumbitLoginRequest(action: AnyAction) {
  const { email, password } = action.payload;

  try {
    const data = yield call(submitLoginService, email, password);

    Cookies.set('sessionKey', data.access_token);

    yield put(submitLoginAction.success({ token: data.access_token }));
    yield put(fetchUserProfileAction.request(email));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(submitLoginAction.failure(error));
    }
  }
}

function* fetchUserService(token: string, guid: string) {
  sessionService = new AxiosService(FETCH_USER_API.replace(':guid', guid));

  const { data }: AxiosResponse = yield sessionService.get({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

function* fetchUserRequest(action: AnyAction) {
  try {
    const urlParams = action.payload;

    const data = yield call(fetchUserService, urlParams.access_token, urlParams.guid);

    yield put(fetchUserAction.success({ user: data }));
  } catch (error) {
    if (!error.wasCancelled) {    
      yield put(fetchUserAction.failure({ title: 'Error while retrieving roles' }));
    }
  }
}

function* fetchService(endpoint: string) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  sessionService = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield sessionService.get({
    headers: {
      Authorization: tokenKey,
    },
  });

  return data;
}

function* fetchUserProfileRequest(action: AnyAction) {
  try {
    const user = yield call(fetchService, FETCH_USER_PROFILE_API);

    if (user.app_metadata.role.name !== 'User') {
      const userRole =
        user.app_metadata.role.roleID === 3 ? ROLES.systemAdmin : ROLES.safetyProfessional;

      setUserSession({ isSignedIn: true, email: action.payload, userRole });

      yield put(fetchUserProfileAction.success({ user, userRole }));

      if (userRole === ROLES.safetyProfessional) {
        yield put(setAccountIdAction.fulfill(user.user_metadata.accountID));
      }

    } else {
      yield put(
        fetchUserProfileAction.failure({
          title: 'Mobile user cannot access back-office',
          mobile: true,
        }),
      );
    }
  } catch (error) {
    if (!error.wasCancelled) {
      if (error.error.response.status === 401) {
        removeUserSession();
        Cookies.remove('sessionKey');
      }

      yield put(fetchUserProfileAction.failure({ title: 'Error while retrieving user' }));
    }
  }
}

function* fetchUserRegisterRequest(action: AnyAction) {
  const { inviteid } = action.payload;
  try {
    const endpoint = fetchUserRegisterAction.getEndpoint({ inviteID: inviteid });

    const response = yield call(fetchService, endpoint);

    yield put(
      fetchUserRegisterAction.success({
        user: response,
      }),
    );
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchUserRegisterAction.failure({
          title: error.error.response.data.error,
        }),
      );
    }
  }
}

export function* sessionSaga() {
  yield takeLatest(fetchUserRegisterAction.REQUEST, fetchUserRegisterRequest);
  yield takeEvery(submitLoginAction.REQUEST, sumbitLoginRequest);
  yield takeEvery(submitLoginAction.FULFILL, cancelService);
  yield takeEvery(submitRegisterAction.REQUEST, submitRegisterRequest);
  yield takeEvery(submitPasswordRestoreAction.REQUEST, submitPasswordRestoreRequest);
  yield takeEvery(submitPasswordRestoreAction.FULFILL, cancelService);
  yield takeEvery(fetchUserAction.REQUEST, fetchUserRequest);
  yield takeEvery(fetchUserProfileAction.REQUEST, fetchUserProfileRequest);
}
