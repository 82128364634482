import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';
import { AccountType } from '@shared/types/backend.types';

export const _getIsSignedIn = (state: IAppState) => state.session.isSignedIn;

export const getIsSignedIn = createSelector(
  [_getIsSignedIn],
  isSignedIn => isSignedIn,
);

export const _getIsLoadingSession = (state: IAppState) => state.session.isLoading;

export const getIsLoadingSession = createSelector(
  [_getIsLoadingSession],
  isLoading => isLoading,
);

export const _getSessionErrors = (state: IAppState) => state.session.errorMessages;

export const getSessionErrors = createSelector(
  [_getSessionErrors],
  errorMessages => errorMessages,
);

export const _getSessionUser = (state: IAppState) => state.session.user;

export const getSessionUser = createSelector(
  [_getSessionUser],
  user => user,
);

export const _getUserCompany = (state: IAppState) => state.professional.userCompany;

export const _getSeatsInfoCompany = (state: IAppState) => { 
  
 if (state.admin.accountID) {   
  return state.admin.accounts.seatsInfo;
}
};
 
export const getUserCompany = createSelector(
  [_getUserCompany],
  company => company,
);

export const getSeatsInfoCompany = createSelector(
  [_getSeatsInfoCompany],
  seatsInfo => seatsInfo,
);

export const _getUserRole = (state: IAppState) => state.session.userRole;

export const getUserRole = createSelector(
  [_getUserRole],
  role => role,
);

export const _getUserAccountId = (state: IAppState) => {
  if (state.session.user) {
    return state.session.user.user_metadata.accountID;
  }

  return undefined;
};

export const getUserAccountId = createSelector(
  [_getUserAccountId],
  accountID => accountID,
);
