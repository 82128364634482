import { combineActions, handleActions } from 'redux-actions';

import { AnyAction } from 'redux';
import { UserType } from '@shared/types/backend.types';

import {
  submitRegisterAction,
  submitLoginAction,
  fetchUserAction,
  fetchUserProfileAction,
  submitPasswordRestoreAction,
  fetchUserRegisterAction,
  setTeamAccessUserIdAction,
  setAccountIdAction,
} from './session.action';

import update from 'immutability-helper';

export interface ISessionState {
  userRole: string;
  isSignedIn: boolean;
  isLoading: boolean;
  mobile: boolean;
  accountId?: number;
  //TODO: errorMessages add type
  errorMessages?: any;
  user?: UserType;
  userInfo?: UserType;
  teamAccessUserID?: string;
}

export const SESSION_INITIAL_STATE: ISessionState = {
  userRole: '',
  isSignedIn: false,
  isLoading: false,
  mobile: false,
  accountId: undefined,
  errorMessages: undefined,
  userInfo: undefined,
  user: undefined,
  teamAccessUserID: undefined,
};

export const sessionReducer = handleActions(
  {
    [combineActions(
      submitRegisterAction.REQUEST,
      //submitLoginAction.REQUEST,
      submitPasswordRestoreAction.REQUEST,
      fetchUserAction.REQUEST,
      fetchUserProfileAction.REQUEST,
      fetchUserRegisterAction.REQUEST,
    ) as any]: (state: ISessionState) =>
        update(state, {
          isLoading: { $set: true },
          errorMessages: {
            $set: undefined,
          },
        }),
    [submitLoginAction.REQUEST]: (state: ISessionState, { payload }: AnyAction) => {
      return update(state, {
        isLoading: { $set: true },
        mobile: { $set: false },
        errorMessages: {
          $set: undefined,
        },
      });
    },
    [fetchUserRegisterAction.SUCCESS]: (state: ISessionState, { payload }: AnyAction) => {
      return update(state, {
        userInfo: { $set: payload.user },
        isLoading: { $set: false },
      });
    },
    [setAccountIdAction.FULFILL]: (state: ISessionState, { payload }: AnyAction) => {
      return update(state, {
        accountId: { $set: payload },
      });
    },

    [submitRegisterAction.SUCCESS]: (state: ISessionState) =>
      update(state, {
        isLoading: { $set: false },
      }),
    [submitLoginAction.SUCCESS]: (state: ISessionState) =>
      update(state, {
        isSignedIn: { $set: true },
        isLoading: { $set: false },
        errorMessages: { $set: {} },
      }),
    [submitPasswordRestoreAction.SUCCESS]: (state: ISessionState) =>
      update(state, {
        isLoading: { $set: false },
        errorMessages: { $set: {} },
      }),
    [combineActions(fetchUserAction.SUCCESS, fetchUserProfileAction.SUCCESS) as any]: (
      state: ISessionState,
      { payload }: AnyAction,
    ) =>
      update(state, {
        isLoading: { $set: false },
        user: { $set: payload.user },
        teamAccessUserID: { $set: payload.user.user_id },
        userRole: { $set: payload.userRole },
      }),
    [combineActions(
      submitRegisterAction.FAILURE,
      submitPasswordRestoreAction.FAILURE,
      fetchUserAction.FAILURE,
      fetchUserRegisterAction.FAILURE,
    ) as any]: (state: ISessionState, { payload }: AnyAction) =>
        update(state, {
          isLoading: { $set: false },
          errorMessages: { $set: payload },
        }),
    [submitLoginAction.FAILURE]: (state: ISessionState, { payload }: AnyAction) =>
      update(state, {
        mobile: { $set: false },
        isSignedIn: { $set: false },
        isLoading: { $set: false },
        errorMessages: { $set: payload },
      }),
    [fetchUserProfileAction.FAILURE]: (state: ISessionState, { payload }: AnyAction) => {
      const mobile = payload.mobile ? payload.mobile : false;
      return update(state, {
        mobile: { $set: mobile },
        isLoading: { $set: false },
        errorMessages: { $set: payload.title },
      });
    },
    [setTeamAccessUserIdAction.TRIGGER]: (state: ISessionState, { payload }: AnyAction) => {
      return update(state, {
        teamAccessUserID: { $set: payload },
      });
    },
  },
  SESSION_INITIAL_STATE,
);
