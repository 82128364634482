import { takeLatest, call, put } from 'redux-saga/effects';

import { ContractorType } from '@shared/types/backend.types';
import { AnyAction } from 'redux';

import { fetchService, addService, updateService, deleteService } from '@rdx/services.saga';

import {
  removeContractorAction,
  updateContractorAction,
  addContractorAction,
  fetchContractorsAction,
  addCachedContractorAction,
  removeContractorRowAction,
} from './contractors.actions';

import { arrayToObject } from '@shared/helpers/state-caster';
import { FETCH_ACCOUNTS_CONTRACTORS_API } from '@constants/endpoints';

function* fetchContractorsRequest(action: AnyAction) {
  try {

    const data = yield call(
      fetchService,
      FETCH_ACCOUNTS_CONTRACTORS_API.replace(':accountID', action.payload),
    );

    const mappedContractors = data.map(({ contractorCompanyID, ...rest }: ContractorType) => ({
      ...rest,
      key: contractorCompanyID,
      contractorCompanyID,
    }));

    const contractorsObject = arrayToObject(mappedContractors, 'contractorCompanyID');

    yield put(fetchContractorsAction.success({ contractors: contractorsObject }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchContractorsAction.failure({
          error: {
            code: 'adminFetchContractors',
            title: 'Failed to retrieve contractors',
            messages: 'There has been an error while retrieving contractors.',
          },
        }),
      );
    }
  }
}
function* addContractorRequest(action: AnyAction) {
  const { accountID, contractor } = action.payload;
  const endpoint = addContractorAction.getEndpoint({ accountID });

  let payloadreq = { ...contractor };
  delete payloadreq.key;

  try {
    const response = yield call(addService, endpoint, [payloadreq]);

    if (!response[0].succeeded) {
      yield put(
        addContractorAction.failure({
          error: {
            code: 'adminAddContractor',
            title: 'Failed to add contractor',
            message: response[0].result,
          },
        }),
      );
    } else {
      yield put(
        addContractorAction.success({
          contractor: { ...response[0].contractorCompany, key: response[0].contractorCompany.contractorCompanyID },
          key: contractor.key,
        }),
      );

    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        addContractorAction.failure({
          error: {
            code: 'adminAddContractor',
            title: 'Failed to add contractor',
            message: 'There has been an error while adding contractor.',
          },
        }),
      );
    }
  }
}

function* addCachedContractorTrigger(action: AnyAction) {
  yield put(addCachedContractorAction.fulfill({ contractor: action.payload.contractor }));
}

function* updateContractorRequest(action: AnyAction) {
  try {
    const endpoint = updateContractorAction.getEndpoint({ accountID: action.payload.accountID });

    yield call(updateService, endpoint, action.payload.contractor);

    yield put(updateContractorAction.success({ contractor: action.payload.contractor }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        updateContractorAction.failure({
          error: {
            code: 'adminUpdateContractor',
            title: 'Failed to update contractor',
            message: 'There has been an error while saving contractor.',
          },
        }),
      );
    }
  }
}

function* removeContractorRequest(action: AnyAction) {
  try {
    const { accountID, contractorCompanyID } = action.payload;
    const endpoint = removeContractorAction.getEndpoint({ accountID, contractorCompanyID });

    yield call(deleteService, endpoint);

    yield put(removeContractorAction.success({ contractorCompanyID }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        removeContractorAction.failure({
          error: {
            code: 'adminRemoveContractor',
            title: 'Failed to remove contractor',
            message: 'There has been an error while removing contractor.',
          },
        }),
      );
    }
  }
}

function* removeContractorRowTrigger(action: AnyAction) {
  yield put(removeContractorRowAction.fulfill({ key: action.payload.key }));
}

export function* contractorsSaga() {
  yield takeLatest(fetchContractorsAction.REQUEST, fetchContractorsRequest);
  yield takeLatest(addContractorAction.REQUEST, addContractorRequest);
  yield takeLatest(addCachedContractorAction.TRIGGER, addCachedContractorTrigger);
  yield takeLatest(updateContractorAction.REQUEST, updateContractorRequest);
  yield takeLatest(removeContractorAction.REQUEST, removeContractorRequest);
  yield takeLatest(removeContractorRowAction.TRIGGER, removeContractorRowTrigger);
}
