import { Switch } from "react-router-dom"

import { PrivateRouteHOC } from '@routes/private-route.hoc';


import React from 'react'
import { CorrectiveActionsContainer } from "./corrective-actions.container";
import { ROLE } from "@constants/roles";
import { ViewDetailsComponent } from "../view/view-details/view-details.component";
import { ViewHistoryComponent } from "../view/view-history/view-history.component";

const CorrectiveActionsRoutes = () => {
  return (
    <Switch>

      <PrivateRouteHOC
        path="/back-office/corrective-actions/:caID/history"
        component={ViewHistoryComponent}
        whitelistedRoles={[ROLE[2]]}
      />

      <PrivateRouteHOC
        path="/back-office/corrective-actions/:caID"
        component={ViewDetailsComponent}
        whitelistedRoles={[ROLE[2]]}
      />

      <PrivateRouteHOC
        path="/back-office/corrective-actions"
        component={CorrectiveActionsContainer}
        whitelistedRoles={[ROLE[2]]}
      />
    </Switch>
  )
}

export default CorrectiveActionsRoutes
