import React, { ChangeEvent, useRef } from 'react';

import { ProjectFormsTableComponent } from '@app/back-office/submissions/view/table.component';
import { DatePicker, Form } from 'antd';

import { HeadingComponent } from '@components/layout/header/heading.component';

import { ProjectType, Submission } from '@shared/types/backend.types';
import './submissions.styles.scss';
import { useRenderPDF } from '@shared/hooks/usepdf';

const { RangePicker } = DatePicker;

interface SubmissionsComponentProps {
  submissions?: any;
  isLoading: boolean;
  isLoadingPDF: boolean;
  selectedProjectID?: number;
  selectedName: string;
  selectedProgram: string;
  distinctPrograms: any;
  projects: ProjectType[];
  selectedSubmission: Submission;
  distinctNames: [];
  submittedReport: [];
  selectedReport: string;
  sortSetup: any;
  RenderPdf: () => JSX.Element;
  handleSort: (key: any) => void;
  fetchSubmissionPdf: (submissionId: string) => void;
  handleChangeSelectedProject: (projectName: string) => void;
  handleLocalChangeSelectedProgram: (safetyPlanName: string) => void;
  handleChangeReportStatus: (isCompleted: number) => void;
  handleLocalChangeSelectedUser: (user: string) => void;
  handleLocalChangesubmittedReport: (formName: string) => void;
  handleLocalChangeDateRange: (date: any, dateRange: string[]) => void;
}

export const SubmissionsPerAccountComponent = (props: SubmissionsComponentProps) => {
  const {
    submissions,
    isLoading,
    sortSetup,
    projects,
    selectedProgram,
    selectedReport,
    submittedReport,
    distinctNames,
    distinctPrograms,
    selectedName,
  } = props;
  const {
    RenderPdf,
    handleSort,
    fetchSubmissionPdf,
    handleChangeSelectedProject,
    handleLocalChangeSelectedUser,
    handleLocalChangeSelectedProgram,
    handleChangeReportStatus,
    handleLocalChangesubmittedReport,
    handleLocalChangeDateRange,
  } = props;

  return (
    <div className="project-forms sa__container">
      <div className="sa__box md-w-100">
        <HeadingComponent title="Submissions" />

        <div className="sa__box-content">
          <div className="project-forms__filters">
            <Form.Item className="project-forms__filters-form" label="Projects">
              <select
                style={{ marginLeft: 0 }}
                key="finteringproject"
                name="accountID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Select Account"
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChangeSelectedProject(e.target.value)
                }
                required
              >
                <option key="All" value="All">
                  All
                </option>

                {projects.map((project: ProjectType) => (
                  <option key={project.projectID} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="User">
              <select
                style={{ marginLeft: 0 }}
                key="filteringName"
                name="accountID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Select Account"
                value={selectedName}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleLocalChangeSelectedUser(e.target.value)
                }
              >
                <option key="All" value="All">
                  All
                </option>
                {distinctNames.map((distinctName: any) => (
                  <option key={distinctName} value={distinctName}>
                    {distinctName}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Safety Program">
              <select
                style={{ marginLeft: 0 }}
                key="filterinProgram"
                name="programID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Select P"
                value={selectedProgram}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleLocalChangeSelectedProgram(e.target.value)
                }
              >
                <option key="All" value="All">
                  All
                </option>
                {distinctPrograms.map((distinctProgram: any) => (
                  <option key={distinctProgram} value={distinctProgram}>
                    {distinctProgram}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Submitted Report">
              <select
                style={{ marginLeft: 0 }}
                key="submittedReportFilter"
                name="programID"
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Submitted Report"
                value={selectedReport}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleLocalChangesubmittedReport(e.target.value)
                }
              >
                <option key="All" value="All">
                  All
                </option>
                {submittedReport.map((report: any) => (
                  <option key={report} value={report}>
                    {report}
                  </option>
                ))}
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Date Range">
              <RangePicker
                onChange={handleLocalChangeDateRange}
                format={'MM/DD/YYYY'}
                style={{ width: 250 }}
              />
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Submission Status">
              <select
                style={{ marginLeft: 0 }}
                className="ant-select-selection ant-select-selection--single team__select--centered"
                placeholder="Submission Status"
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleChangeReportStatus(parseInt(e.target.value))
                }
              >
                <option key={2} value={2}>
                  All
                </option>

                <option key={1} value={1}>
                  Completed
                </option>

                <option key={0} value={0}>
                  Not Completed
                </option>
              </select>
            </Form.Item>

            <Form.Item className="project-forms__filters-form" label="Total Submissions">
              <h3 className="misc">{submissions.length}</h3>
            </Form.Item>
          </div>

          <RenderPdf />

          <ProjectFormsTableComponent
            submissions={submissions}
            isLoading={isLoading}
            handleSort={handleSort}
            sortSetup={sortSetup}
            handleShowViewer={fetchSubmissionPdf}
          />
        </div>
      </div>
    </div>
  );
};
