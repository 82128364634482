import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import { UPLOAD_COMPANY_IMAGE_API } from '@constants/endpoints';

export const UPLOAD_COMPANY_IMAGE = 'UPLOAD_COMPANY_IMAGE';
export const CHANGE_SETUP_IMAGE_STATUS = 'CHANGE_SETUP_IMAGE_STATUS';
export const CHANGE_SETUP_INDUSTRY = 'CHANGE_SETUP_INDUSTRY';
export const VALIDATE_CREATION = 'VALIDATE_CREATION';
export const DELETE_ACCOUNT_LOGO = 'DELETE_ACCOUNT_LOGO';

export const changeSetupIndustryAction = createActionRoutine(CHANGE_SETUP_INDUSTRY);

export const changeSetupImageStatusAction = createActionRoutine(CHANGE_SETUP_IMAGE_STATUS);
export const uploadImageCompanyAction = createActionRoutine(
  UPLOAD_COMPANY_IMAGE,
  UPLOAD_COMPANY_IMAGE_API,
);

export const validateCreationAction = createActionRoutine(VALIDATE_CREATION);
// DELETE COMPANY LOGO
export const deleteAccountLogoAction = createActionRoutine(
  DELETE_ACCOUNT_LOGO,
  UPLOAD_COMPANY_IMAGE_API
);