import { SearchFilter } from '@app/back-office/corrective-actions/view/search-filter/search.filter';
import { HeadingComponent } from '@components/layout/header/heading.component';
import { Hazard } from '@shared/types/backend.types';
import { Icon, Input, Modal, Popconfirm, Table } from 'antd';
import moment from 'moment';
import React, { ChangeEvent, useMemo } from 'react';

type props = {
  hazards: Hazard[];
  addingHazards: { [key: number]: Partial<Hazard> };
  isHazardLoading: boolean;
  onAddHazard: () => void;
  setAddingHazards: (value: React.SetStateAction<{ [key: number]: Partial<Hazard> }>) => void;
  onSaveHazard: (hazardId: number, isExistingHazard: boolean) => void;
  onDeleteHazard: (hazardId: number) => void;
  onEditHazard: (hazard: Hazard) => void;
  onCloseHazardEdit: (hazardId: number, isNewHazard: boolean) => void;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>, hazard: Hazard) => void;
};

const HazardComponent = (props: props) => {
  const {
    hazards,
    addingHazards,
    isHazardLoading,
    onAddHazard,
    onSaveHazard,
    onDeleteHazard,
    onEditHazard,
    onCloseHazardEdit,
    handleOnChange,
  } = props;


  const filteredHazards = useMemo(() => hazards.filter(h => h.status !== 'inactive'), [hazards]);

  const columns = [
    {
      title: <b>{`Total Hazards - ${hazards.length}`}</b>,
      children: [
        {
          title: <b>Hazard Type</b>,
          dataIndex: 'type',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <SearchFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="type"
            />
          ),
          filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          onFilter: (value: string, record: Hazard) =>
            record.type
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          render: (hazardtype: string, hazard: Hazard) =>
            addingHazards[hazard.id] ? (
              <Input
                name="type"
                placeholder="Type"
                value={addingHazards[hazard.id].type ? addingHazards[hazard.id].type : hazardtype}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, hazard)}
              />
            ) : (
              hazardtype
            ),
        },
        {
          title: <b>Hazard Description</b>,
          dataIndex: 'description',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <SearchFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex="description"
            />
          ),
          filterIcon: (filtered: any) => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          onFilter: (value: string, record: Hazard) =>
            record.description
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          render: (hazardDesc: string, hazard: Hazard) =>
            addingHazards[hazard.id] ? (
              <Input
                name="description"
                placeholder="Description"
                value={
                  addingHazards[hazard.id].description
                    ? addingHazards[hazard.id].description
                    : hazardDesc
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, hazard)}
              />
            ) : hazardDesc ? (
              hazardDesc
            ) : (
              <b>N/A</b>
            ),
        },
        {
          title: <b>Created On</b>,
          dataIndex: 'creationDateTime',
          width: '10%',
          render: (hazardDate?: string) => moment(hazardDate).format('MM-DD-YYYY'),
        },
        {
          title: '',
          width: '4%',
          key: 12,
          render: (hazard: Hazard) => (
            <div style={{ display: 'flex', gap: 10 }}>
              {addingHazards[hazard.id] ? (
                <>
                  <Popconfirm
                    title="Sure to remove the filled information ?"
                    okText="Yes"
                    onConfirm={() => onCloseHazardEdit(hazard.id, !!!hazard.creationDateTime)}
                  >
                    <Icon className="table-sa__deleteIcon" type="close" />
                  </Popconfirm>

                  <Icon
                    className="table-sa__actionIcon"
                    onClick={() => onSaveHazard(hazard.id, !!hazard.creationDateTime)}
                    type="save"
                    theme="filled"
                  />
                </>
              ) : (
                <>
                  <Icon
                    className="table-sa__actionIcon"
                    onClick={() => onEditHazard(hazard)}
                    type="edit"
                  />
                  <Popconfirm
                    title="Sure to delete this hazard ?"
                    okText="Yes"
                    onConfirm={() => onDeleteHazard(hazard.id)}
                  >
                    <Icon className="table-sa__deleteIcon" type="delete" />
                  </Popconfirm>
                </>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <div className="sa__container">
      <div className="sa__box">
        <HeadingComponent
          title="Hazards"
          btnTitle="Create Hazard"
          icon={<Icon type="plus-square" />}
          onClkBtnFunction={onAddHazard}
        />

        <div className="sa__box-content">
          <Table
            className="table-sa"
            columns={columns as any}
            rowKey={hazard => hazard.id.toString()}
            bordered={true}
            loading={isHazardLoading}
            dataSource={filteredHazards}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default HazardComponent;
