import { ButtonControlComponent } from '@components/controls/button/button-control.component';
import SADialog from '@components/ui/accordian/dialog';
import { FormType, SafetyPlanType } from '@shared/types/backend.types';
import { Checkbox, Form, Input, Select } from 'antd'
import { WrappedFormUtils } from 'antd/es/form/Form';
import React, { FormEvent } from 'react'
import { useDispatch } from 'react-redux';
import { addSafetyForm, fetchSafetyForms } from '../state/safety-programs.actions';
import Swal from 'sweetalert2';


type Props = {
    open: boolean;
    form: WrappedFormUtils;
    plans: SafetyPlanType[];
    forms: FormType[];
    reqState: { isLoading: boolean | undefined, type: string }
    setShow: (show: boolean) => void;
    onSubmitAddSafetyForm: (values: any) => void;
}

const AddForm = (props: Props) => {

    const dispatch = useDispatch();

    const { open, plans, forms, reqState: { isLoading, type }, form: { getFieldDecorator, validateFields, resetFields }, setShow, onSubmitAddSafetyForm } = props;

    const [showNewTitleTextBox, setshowNewTitleTextBox] = React.useState(true);

    React.useEffect(() => {
        if (!isLoading && type === addSafetyForm.SUCCESS) {
            Swal.fire({
                title: 'Done!',
                text: 'New form has been added',
                type: 'success',
                confirmButtonColor: '#0B734F',
            }).then(() => (resetFields(), setShow(false)));
        }
    }, [type]);


    return (
        <SADialog title="Add New Safety Form" open={open} setShow={setShow} >
            <Form
                layout="vertical"
                hideRequiredMark
                style={{ width: '100%' }}
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    validateFields((err, values) => {
                        if (!err) onSubmitAddSafetyForm(values)
                    })
                }}
            >
                <Form.Item label="Safety Programs">
                    {getFieldDecorator('planID', {
                        rules: [
                            {
                                required: true,
                                message: 'Please select a program',
                            },
                        ],
                    })(
                        <Select placeholder="Select safety program" className="input--medium-height" onChange={(planID: number) => dispatch(fetchSafetyForms.request({ params: { planID } }))} >
                            {
                                plans.map((plan: any) => (
                                    <Select.Option key={plan.safetyPlanID} value={plan.safetyPlanID}>
                                        {plan.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Tiles / Sections">
                    {getFieldDecorator('parentID', { initialValue: "newTile" })(
                        <Select placeholder="Select Tiles / Sections" className="input--medium-height" onChange={(value: string | undefined) => (setshowNewTitleTextBox(value === 'newTile'))}>
                            <Select.Option key={-1} value="newTile">
                                {"<<New Tile / New Section>>"}
                            </Select.Option>
                            {
                                forms.map((form: any) => (
                                    <Select.Option key={form.id} value={form.id}>
                                        {form.name}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    )}
                </Form.Item>

                {
                    showNewTitleTextBox && (
                        <>
                            <Form.Item className='sa-checkbox-right' style={{ float: 'right', marginBottom: 0 }} >
                                {getFieldDecorator('isMultiQuestionEnable', { valuePropName: 'checked', initialValue: false })
                                    (<Checkbox>Multi Questions Enabled</Checkbox>)}
                            </Form.Item>

                            <Form.Item label="New tile / Section">
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Insert new Tile / section',
                                        },
                                    ],
                                })(<Input placeholder="Add New Tile / Section" className="input--medium-height" required />)}
                            </Form.Item>
                        </>
                    )
                }
                {
                    !showNewTitleTextBox &&
                    <Form.Item label="Sub Tile / Sub Section">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: !showNewTitleTextBox,
                                    message: 'Insert Sub Tile / Sub Section',
                                },
                            ],
                        })(<Input placeholder="Add New Sub tile / Sub Section" className="input--medium-height" />)}
                    </Form.Item>
                }

                <div className='dialog__form-footer'>
                    <ButtonControlComponent
                        text="Cancel"
                        size="Large"
                        color="gray"
                        // loading={isFormSubmitting}
                        onClick={() => setShow(false)}
                    />
                    <ButtonControlComponent
                        text="Create"
                        size="Large"
                        color="grey"
                        // loading={isFormSubmitting}
                        htmlType="submit"
                    />
                </div>
            </Form>
        </SADialog>
    )
}

const AddFormDialog: any = Form.create({ name: 'addForms' })(AddForm);

export default AddFormDialog;
