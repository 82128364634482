import React, { ChangeEvent, useState } from 'react';

import { ContractorType, PackageSeatDetail, UserType } from '@shared/types/backend.types';

import { Switch, Popconfirm, Select, Form } from 'antd';
import { SorterComponent } from '@components/controls/table/sorter.component';

import { useIntl } from 'react-intl';

import { ModalContractorInviteContainer } from '@components/admin/add-contractor/add-contractor.container';

import classnames from 'classnames';
//TODO: Add sort icons with asc and desc statuses.

import { isEmployeeValid } from '@app/back-office/programs/programs.validations';

import './table.styles.scss'

const { Option, OptGroup } = Select;


//TODO: add types
export interface ITeamTableProps {
  user: UserType;
  viewContractorModal: boolean
  setContractorModal: any
  companyContractors: ContractorType[];
  users: UserType[];
  packages: PackageSeatDetail[];
  jobTitles?: string[];

  handleSort: (key: any) => void;
  handleUpdateForm: (targetKey: string, value: any, key: string | number) => void;
  handleAddTableItem: (item: any) => void;
  handleRemoveTableItem: (key: number) => void;
}

export const TeamTableComponent = (props: ITeamTableProps) => {
  const { user, users, packages, companyContractors, viewContractorModal } = props;
  const { handleSort, handleUpdateForm, handleAddTableItem, handleRemoveTableItem, setContractorModal } = props;
  const [roleValue, setRoleValue] = useState<string | undefined>(undefined)
  const intl = useIntl();

  const renderEditableRow = () => {
    return users.map((item: any) => {
      if (item.email === user.email) {
        return (
          <tr key={item.key} className={isEmployeeValid(item) ? '' : 'is-logged-user-invalid'}>
            <td>
              <input
                defaultValue={item.given_name || item.givenName || ''}
                placeholder={item.given_name || item.givenName || 'First Name'}
                type="text"
                disabled
                required
              />
            </td>
            <td>
              <input
                defaultValue={item.family_name || item.familyName || ''}
                placeholder={item.family_name || item.familyName || 'Last Name'}
                type="text"
                disabled
                required
              />
            </td>
            <td>
              <input
                defaultValue={item.email || ''}
                placeholder={item.email || 'Email'}
                required
                disabled
              />
            </td>

            <td>
              <input
                defaultValue={item.jobTitle || ''}
                placeholder={item.jobTitle || 'Job title'}
                className={classnames(!item.jobTitle && 'select__job-title')}
                disabled
                required
              />
            </td>

            {
              user.app_metadata.role.roleID === 2 ?
                <td>
                  <Select
                    placeholder="Role"
                    disabled
                  >
                  </Select>
                </td> : ''
            }

            <td>
              <Select
                placeholder="License Type"
                disabled
              >
              </Select>
            </td>

            {
              user.app_metadata.role.roleID === 2 ?
                <td>
                  <Select
                    placeholder="Company"
                    disabled
                  >
                  </Select>
                </td> : ''

            }
            <td>
              <Switch
                defaultChecked
                disabled
                onClick={() =>
                  handleUpdateForm('canCreateProject', !item.canCreateProject, item.key)
                }
              />
              <Popconfirm
                placement="topRight"
                disabled
                title="Are you sure you want to delete?"
                onConfirm={() => handleRemoveTableItem(item.key)}
                okText="Yes"
              >
                <i
                  className="far fa-trash-alt"
                  style={{ padding: '16px 16px !important', marginLeft: 15, cursor: 'pointer' }}
                ></i>
              </Popconfirm>
            </td>
          </tr >
        );
      }

      return (
        <tr key={item.key} className={isEmployeeValid(item) ? '' : 'is-invalid'}>
          <td>
            <input
              defaultValue={item.given_name || item.givenName || ''}
              placeholder={item.given_name || item.givenName || 'First Name'}
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('givenName', e.target.value, item.key)
              }
              autoFocus
              required
            />
          </td>
          <td>
            <input
              defaultValue={item.family_name || item.familyName || ''}
              placeholder={item.family_name || item.family_name || 'Last Name'}
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('familyName', e.target.value, item.key)
              }
              required
            />
          </td>
          <td>
            <input
              defaultValue={item.email || ''}
              placeholder={item.email || 'Email'}
              type="email"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('email', e.target.value, item.key)
              }
              required
            />
          </td>
          <td>
            <input
              defaultValue={item.jobTitle || ''}
              placeholder={item.jobTitle || 'Job title'}
              className={classnames(item.jobTitle === '' && 'select__job-title')}
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('jobTitle', e.target.value, item.key)
              }
              required
            />
          </td>

          {
            user.app_metadata.role.roleID === 2 ?
              <td>
                <Select
                  placeholder="Role"
                  defaultValue="User"
                  onChange={(value: string) => {
                    handleUpdateForm('roleID', value, item.key)
                    setRoleValue(value)
                  }
                  }
                >
                  <OptGroup key={0} label="Select Role">
                    <Option key={1} value="User" title="User">
                      Mobile User
                    </Option>
                    <Option key={2} value="Administrator" title="Administrator">
                      Administrator
                    </Option>
                  </OptGroup>
                </Select>
              </td> : ''
          }

          <td>
            <Select
              placeholder="License Type"
              defaultValue={item.package}
              onChange={(value: number) => { handleUpdateForm('package', value, item.key) }}
            >
              <OptGroup key={0} label="Select License Type">
                {roleValue === undefined ? (
                  packages.map((packagee: PackageSeatDetail, index) => (
                    <Option key={index} value={packagee.packageID}>{`${packagee.packageName} (${packagee.availableSeats})`}</Option>
                  ))
                )
                  : roleValue === 'User' ? (
                    packages.map((packagee: PackageSeatDetail, index) => (
                      packagee.packageID !== 'SA-ADMINISTRATOR' ? (
                        <Option key={index} value={packagee.packageID}>{`${packagee.packageName} (${packagee.availableSeats})`}</Option>
                      )
                        : null
                    ))
                  )

                    : roleValue === 'Administrator' ? (
                      packages.map((packagee: PackageSeatDetail, index) => (
                        packagee.packageID === 'SA-ADMINISTRATOR' ? (
                          <Option key={index} value={packagee.packageID}>{`${packagee.packageName} (${packagee.availableSeats})`}</Option>
                        )
                          : null
                      ))
                    )

                      : null
                }
              </OptGroup>
            </Select>
          </td>

          {
            user.app_metadata.role.roleID === 2 ?
              <td>
                <Select
                  placeholder="Company"
                  defaultValue={`${item.contractorCompanyID}`}
                  onChange={(value: string) => { handleUpdateForm('contractorCompanyID', value, item.key) }}
                >
                  <OptGroup key={0} label="Select Company">
                    <Option className='parent-company-option' value={`0`}>{user.user_metadata.companyName}</Option>
                    {companyContractors.map((contractor: ContractorType) => {
                      return <option key={contractor.contractorCompanyID} value={`${contractor.contractorCompanyID}`}>{contractor.companyName}</option>
                    })}
                    <option className='add-company-option' onClick={() => setContractorModal(true)} value={1} >Add Company</option>
                  </OptGroup>
                </Select>

                <ModalContractorInviteContainer
                  selectedAccountID={user.user_metadata.accountID}
                  setShowModal={setContractorModal}
                  showModal={viewContractorModal}
                />
              </td> : ''
          }
          <td>
            <Switch
              defaultChecked
              onClick={() => handleUpdateForm('canCreateProject', !item.canCreateProject, item.key)}
            />
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete?"
              onConfirm={() => handleRemoveTableItem(item.key)}
              okText="Yes"
            >
              <i
                className="far fa-trash-alt"
                style={{ padding: '16px 16px !important', marginLeft: 15, cursor: 'pointer' }}
              ></i>
            </Popconfirm>
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table table__regular sa-table" style={{ width: '100%', textAlign: 'center' }}>
      <thead>
        <tr>
          <th className="unselectable" onClick={() => handleSort('name')}>
            <span>{intl.messages['inputs.firstName'].toString()}</span> <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('lastName')}>
            {intl.messages['inputs.lastName'].toString()} <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('email')}>
            {intl.messages['inputs.email'].toString()}
            <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('jobTitleID')}>
            {intl.messages['inputs.jobTitle'].toString()}
            <SorterComponent status="asc" />
          </th>
          {user.app_metadata.role.roleID == 2 ?
            <th className="unselectable" onClick={() => handleSort('roleID')}>
              Role
              <SorterComponent status="asc" />
            </th> : ''}
          <th className="unselectable" onClick={() => handleSort('packageID')}>
            License Type
            <SorterComponent status="asc" />
          </th>
          {user.app_metadata.role.roleID == 2 ?
            <th className="unselectable" onClick={() => handleSort('contractorCompapny')}>
              Company Name
              <SorterComponent status="asc" />
            </th> : ''}
          <th>
            {intl.messages['inputs.createProject'].toString()}
            <SorterComponent status="asc" />
          </th>
        </tr>
      </thead>
      <tbody>
        {renderEditableRow()}
        <tr key={users.length + 1} onClick={() => handleAddTableItem({})} className="table--footer">
          <td>
            <input placeholder={intl.messages['inputs.firstName'].toString()} disabled />
          </td>
          <td>
            <input placeholder={intl.messages['inputs.lastName'].toString()} disabled />
          </td>
          <td>
            <input placeholder={intl.messages['inputs.email'].toString()} disabled />
          </td>
          <td>
            <input placeholder={intl.messages['inputs.jobTitle'].toString()} disabled />
          </td>
          {
            user.app_metadata.role.roleID === 2 ?
              <td>
                <input placeholder="Role" disabled />
              </td> : ''
          }
          <td>
            <input placeholder={intl.messages['inputs.package'].toString()} disabled />
          </td>
          {
            user.app_metadata.role.roleID === 2 ?
              <td>
                <input placeholder="Company Name" disabled />
              </td> : ''
          }
          <td>
            <Switch defaultChecked disabled />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
