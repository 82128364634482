import React from 'react';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { SessionRoutes } from '@session/session.routes';
import { AdminRoutes } from '@admin/admin.routes';

import { SessionLayoutComponent } from '@layout/session-layout/session-layout.component';
import { NotFoundComponent } from '../components/layout/codes/not-found/not-found.component';
import { EnvIndicator } from '@shared/components/environment-indicator/env-indicator.component';
import { LoginFormContainer } from '@app/session/login/state/login.container';
import { ProfessionalRoutes } from '@app/back-office/professional.routes';

export const AppRoutes = () => (
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <SessionLayoutComponent>
            <LoginFormContainer />
          </SessionLayoutComponent>
        )}
      />
      <Route
        path="/login"
        render={() => (
          <SessionLayoutComponent>
            <LoginFormContainer />
          </SessionLayoutComponent>
        )}
      />
      <Route path="/session" component={SessionRoutes} />
      <Route path="/admin" component={AdminRoutes} />
      <Route path="/back-office" component={ProfessionalRoutes} />
      <Route component={NotFoundComponent} />
    </Switch>
    <EnvIndicator />
  </Router>
);
