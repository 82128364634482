import { AnyAction } from 'redux';
import { IProfessionalState } from '../../professional.reducer';

import {
  fetchTeamAction,
  changeSetUserAction,
  toggleSPUserAction,
  updateUserInfoAction,
  removeAccountUserAction,
  fetchUserTeamAction,
} from './team.actions';
import { combineActions } from 'redux-actions';

import update from 'immutability-helper';

export const teamReducer = {
  [fetchTeamAction.REQUEST]: (state: IProfessionalState) => {
    return update(state, {
      team: {
        isLoading: { $set: true },
      },
    });
  },

  [fetchTeamAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    return update(state, {
      team: {
        items: { $set: payload.team },
        isLoading: { $set: false },
      },
    });
  },

  [fetchTeamAction.FULFILL]: (state: IProfessionalState) => {
    return update(state, {
      team: {
        items: { $set: {} },
        isLoading: { $set: false },
      },
    });
  },

  [fetchUserTeamAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    return update(state, {
      team: {
        userTeam: { $set: payload },
        isLoading: { $set: false },
      },
    });
  },

  [fetchUserTeamAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
    return update(state, {
      team: {
        userTeam: { $set: [] },
        isLoading: { $set: false },
      },
    });
  },

  [updateUserInfoAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    return update(state, {
      team: {
        userTeam: { $set: payload },
      },
    });
  },

  [removeAccountUserAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
    let updatedTeam = { ...state.team.items };
    delete updatedTeam[payload.userID];
    return update(state, {
      team: {
        items: { $set: updatedTeam },
      },
    });
  },

  [combineActions(
    fetchTeamAction.FAILURE,
    updateUserInfoAction.FAILURE,
    fetchUserTeamAction.FAILURE,
  ) as any]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      team: {
        isLoading: { $set: false },
      },
      errorMessages: {
        [payload.error.code]: { $set: payload.error },
      },
    }),
  [changeSetUserAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      team: {
        items: {
          [payload.key]: { $set: payload.userUpdate },
        },
      },
    }),
  [toggleSPUserAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      teamSorter: { $set: payload },
    }),
};
