import { handleActions } from 'redux-actions';

import { AnyAction } from 'redux';

import { changeLanguageAction } from '@languages/lang.action';

export interface ILangState {
  activeLanguage: string;
  isLoadingLanguage: boolean;
}

export const LANG_INITIAL_STATE: ILangState = {
  activeLanguage: 'en',
  isLoadingLanguage: false,
};

export const langReducer = handleActions(
  {
    [changeLanguageAction.TRIGGER]: (state: ILangState) => ({
      ...state,
      isLoadingLanguage: true,
    }),
    [changeLanguageAction.FULFILL]: (state: ILangState, { payload }: AnyAction) => ({
      activeLanguage: payload.theme,
      isLoadingLanguage: false,
    }),
  },
  LANG_INITIAL_STATE,
);
