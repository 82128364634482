import { takeEvery, put, call, fork, all } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { IFormInvite } from '@shared/types/forms.types';

import { submitInviteAction, fetchCompaniesAction, fetchPackagesAction } from './admin.action';
import { accountsSaga } from './accounts/state/accounts.saga';

import { AxiosService } from '@services/axios/axios.service';
import Cookies from 'js-cookie';
import SafetyProgramsSaga from './safety-programs/state/safety-programs.saga';

import { SUBMIT_INVITE_API, FETCH_ACCOUNTS_API, FETCH_PACKAGE_API } from '@constants/endpoints';
import { adminInviteTeamSaga } from './invite/invite-team/state/invite-team.saga';
import { submissionsSaga } from './submissions/state/submissions.saga';
import { adminCASaga } from './corrective-actions/state/corrective-actions.saga';
import { hazardsSaga } from './hazard/state/hazard.saga';

let adminService: AxiosService;

function* cancelService(action: AnyAction) {
  yield adminService.cancelRequest(action.payload.msg);
}

function* fetchService(endpoint: string) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;

  adminService = new AxiosService(endpoint);

  const { data }: AxiosResponse = yield adminService.get({
    headers: {
      Authorization: tokenKey,
    },
  });

  return data;
}

function* fetchCompaniesRequest() {
  try {
    const data = yield call(fetchService, FETCH_ACCOUNTS_API);

    yield put(fetchCompaniesAction.success({ companies: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fetchCompaniesAction.failure({ title: 'Error when getting companies' }));
    }
  }
}

function* fetchPackagesRequest(action: AnyAction) {
  try {
    const data = yield call(
      fetchService,
      FETCH_PACKAGE_API.replace(':accountID', action.payload),
    );

    yield put(fetchPackagesAction.success({ packages: data }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fetchPackagesAction.failure({ title: 'Error when getting packages' }));
    }
  }
}


function* submitInviteService(inviteForm: IFormInvite) {
  const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;
  const { accountID } = inviteForm;

  adminService = new AxiosService(SUBMIT_INVITE_API.replace(':accountID', `${accountID}`));

  const { data }: AxiosResponse = yield adminService.post({
    body: [inviteForm],
    headers: {
      Authorization: tokenKey,
    },
  });

  return data;
}

function* submitInviteRequest(action: AnyAction) {
  const { form } = action.payload

  delete action.payload.form

  const inviteForm: IFormInvite = { ...action.payload };

  try {
    const response = yield call(submitInviteService, inviteForm);

    if (!response[0].succeeded) {
      yield put(submitInviteAction.failure({ title: response[0] }));
    } else {
      yield put(submitInviteAction.success({}));
      form.resetFields();
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(submitInviteAction.failure(error.error.response.data));
    } else {
      yield put(submitInviteAction.failure({ title: 'Error when sending invitation' }));
    }
  }
}

export function* adminSaga() {
  yield takeEvery(submitInviteAction.REQUEST, submitInviteRequest);
  yield takeEvery(fetchCompaniesAction.REQUEST, fetchCompaniesRequest);
  yield takeEvery(fetchCompaniesAction.FULFILL, cancelService);
  yield takeEvery(fetchPackagesAction.REQUEST, fetchPackagesRequest);
  yield all([fork(accountsSaga), fork(SafetyProgramsSaga), fork(adminInviteTeamSaga), fork(submissionsSaga), fork(adminCASaga), fork(hazardsSaga)]);
}
