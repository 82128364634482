import React from 'react';

import { Submission } from '@shared/types/backend.types';

import { ProjectFormItemComponent } from './table-item.component';
import { Empty } from 'antd';
import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';
import { SorterComponent } from '@components/controls/table/sorter.component';


import '@utils/styles/width.css';
import '@utils/styles/margin.scss';
import '@utils/styles/padding.scss';

interface IProjectFormTableProps {
  submissions: Submission[];
  isLoading: boolean;
  sortSetup?: any;
  handleSort: (key: any) => void;
  handleShowViewer: (submissionID: string) => void;
}

const ProjectFormsTable = (props: IProjectFormTableProps) => {
  const { submissions, isLoading, sortSetup } = props;
  const { handleShowViewer, handleSort } = props;

  const renderRows = () => {
    if (isLoading) {
      return (
        <tr style={{ height: 400 }}>
          <td colSpan={6}>
            <TableLoadingSpinnerComponent />
          </td>
        </tr>
      );
    }

    if (submissions.length === 0) {
      return (
        <tr>
          <td colSpan={6}>
            <Empty className='submissions-empty' description={"No Submissions"} />
          </td>
        </tr>
      )
    }
    return submissions.map((submission: Submission) => (
      <ProjectFormItemComponent
        key={submission.id}
        submission={submission}
        handleShowViewer={() => handleShowViewer(submission.id)}
      />
    ));
  };
  return (
    <table className="sa-table">
      <thead >
        <tr>
          <th className="unselectable">Project</th>
          <th className="unselectable" onClick={() => handleSort('user.name')}>
            User Name
            <SorterComponent status={sortSetup.key === 'user.name' ? sortSetup.order : 'desc'} />
          </th>
          <th className="unselectable" onClick={() => handleSort('safetyPlanName')}>
            Safety Program
            <SorterComponent status={sortSetup.key === 'safetyPlanName' ? sortSetup.order : 'desc'} />
          </th>
          <th className="unselectable" onClick={() => handleSort('formName')}  >
            Submitted Report
            <SorterComponent status={sortSetup.key === 'formName' ? sortSetup.order : 'desc'} />
          </th>
          <th className="unselectable" onClick={() => handleSort('submissionDateTime')}>
            Date & Time
            <SorterComponent status={sortSetup.key === 'submissionDateTime' ? sortSetup.order : 'desc'} />
          </th>
          <th className="unselectable" ></th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
};

export const ProjectFormsTableComponent = ProjectFormsTable;
