import { HAZARD_API, MUTATE_HAZARD_API } from "@constants/endpoints";
import { createActionRoutine } from "@shared/helpers/redux-saga-endpoints";

export const fetchHazardsAction = createActionRoutine("FETCH_HAZARDS_ACTION", HAZARD_API);
export const createHazardAction = createActionRoutine("CREATE_HAZARD_ACTION", HAZARD_API);
export const updateHazardAction = createActionRoutine("UPDATE_HAZARD_ACTION", MUTATE_HAZARD_API);
export const deleteHazardAction = createActionRoutine("DELETE_HAZARD_ACTION", MUTATE_HAZARD_API);

export const addHazardRowAction = createActionRoutine("ADD_Hazard_ROW_ACTION");

