import React, { Fragment } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import { PrivateRouteHOC } from '@routes/private-route.hoc';

import { AdminInviteContainer } from './invite/state/invite.container';
import { AdminSubmissionsReportsContainer } from './reports/submissions/state/sub.reports.container';
import { NavigationMenuContainer } from '@layout/navigation-menu/navigation-menu.container';
import { AccountsContainer } from './accounts/state/accounts.container';
import SafetyProgramsContainer from './safety-programs/state/safety-programs.container';
import { AccountsFormsContainer } from './submissions/state/submissions.container';

import { ROLE } from '@constants/roles';
import { AdminInviteTeamContainer } from './invite/invite-team/state/invite-team.container';
import CorrectiveActionsRoutes from './corrective-actions/state/corrective-actions.routes';
import { AdminCAReportsContainer } from './reports/corrective-actions/state/ca.reports.container';
import ProxyUsersContainer from '@components/proxy-users/proxy-users.container';
import HazardContainer from './hazard/state/hazard.container';

export const AdminRoutes = () => (
  <Fragment>
    <NavigationMenuContainer>
      <Switch>
        <PrivateRouteHOC
          path="/admin/accounts"
          component={AccountsContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/user/:userID/proxy-users"
          component={ProxyUsersContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/invite"
          component={AdminInviteContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/invite-team"
          component={AdminInviteTeamContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/safety-programs"
          component={SafetyProgramsContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/reports/submissions"
          component={AdminSubmissionsReportsContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/reports/corrective-actions"
          component={AdminCAReportsContainer}
          whitelistedRoles={[ROLE[3]]}
        />
        <PrivateRouteHOC
          path="/admin/submissions"
          component={AccountsFormsContainer}
          whitelistedRoles={[ROLE[3]]}
        />

        <PrivateRouteHOC
          path="/admin/hazards"
          component={HazardContainer}
          whitelistedRoles={[ROLE[3]]}
        />

        {/* Corrective Actions Routes
         * Route - /back-office/corrective-actions */}
        <CorrectiveActionsRoutes />

        <Route path="/admin" render={() => <Redirect to="/admin/accounts" />} />
      </Switch>
    </NavigationMenuContainer>
  </Fragment>
);
