// HOOKS
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// LOCAL COMPONENTS
import ProxyUsersComponent from './proxy-users.component';
import { TeamChangePasswordContainer } from '@components/admin/change-password/change-password.container';

// ACTIONS
import {
  blockProxyUserAction,
  fetchUserAction,
  resendUserInviteAction,
  updateProxyUserAction,
} from '@app/admin/accounts/team/state/team.actions';
import { fetchProxyUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { clearErrorMessages } from '@app/back-office/team/state/team.actions';

// TYPES
import { IAppState } from '@rdx/root.reducer';
import { UserType } from '@shared/types/backend.types';
import { Dropdown, Icon, Menu, Popconfirm, Switch, Tag, Tree, message } from 'antd';
import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';
import { getList } from '@shared/helpers/state-caster';
import { ROLE } from '@constants/roles';

const ProxyUsersContainer = () => {
  // URL PARAMS
  const { userID }: { userID: string } = useParams();

  // HOOKS INSTANCES
  const dispatch = useDispatch();

  // LOCAL STATES
  const [openModal, toggleModal] = useState<boolean>(false);
  const [name, setName] = useState<string | undefined>(undefined);
  const [editingKey, setEditingKey] = useState<string | undefined>(undefined);

  // STORE DATA
  const {
    isLoading,
    actionSelectUser,
    proxyUsers,
    items: accountsTeam,
    errorMessages,
  } = useSelector((state: IAppState) => state.admin.accounts.team);

  const reportsToUsers = useSelector((state: IAppState) => state.professional.team.userTeam);

  const user = useSelector((state: IAppState) => state.session.user);

  const [treeNodes, setTreeNodes] = useState<any>();

  const accountsTeamArr = useMemo(() => {
    return getList(accountsTeam);
  }, [accountsTeam]);

  const team = useMemo(() => {
    if (user && user.app_metadata.role.roleID === 3) {
      return accountsTeamArr;
    } else {
      return reportsToUsers;
    }
  }, [user, accountsTeam, reportsToUsers]);

  useEffect(() => {
    if (accountsTeamArr.length) {
      setTreeNodes(buildTree(accountsTeamArr, null));
    }
  }, [accountsTeamArr]);

  useEffect(() => {
    dispatch(fetchUserAction.request({ params: { userID } }));
  }, [userID]);

  useEffect(() => {
    dispatch(fetchProxyUsersAction.request({ params: { userID, status: 'active' } }));
  }, []);

  useEffect(() => {
    if (errorMessages && errorMessages.title)
      message.error(errorMessages.title, 5, () => dispatch(clearErrorMessages.trigger()));
  }, [errorMessages]);

  const onEdit = ({ user_id, name }: UserType) => (setName(name), setEditingKey(user_id));

  const onCancel = () => (setEditingKey(undefined), setName(undefined));

  const onSave = ({ user_id: userID }: UserType) => {
    if (name) {
      const [given_name, family_name] = name.split(' ');
      dispatch(
        updateProxyUserAction.request({
          params: { userID },
          body: { given_name, family_name, name },
        }),
      );
      onCancel();
    }
  };

  const buildTree = (users: UserType[], user: UserType | null): any => {
    let children: UserType[] = [];

    if (user === null) {
      children = users.filter((item: UserType) => item.reportsTo === null);
    } else {
      if (user !== undefined) {
        children = users.filter(
          (item: UserType) => item.user_metadata.reportsToUserID === user.user_id,
        );
      }
    }

    if (children.length === 0) {
      return null;
    }

    return children.map((child: UserType) => {
      const proxyUsers = getList(accountsTeam).filter((item: UserType) =>
        item.user_metadata.proxyUsers && item.user_metadata.proxyUsers.length
          ? item.user_metadata.proxyUsers.includes(child.user_id)
          : undefined,
      );

      return (
        <Tree.TreeNode
          title={
            <span className={child.blocked ? 'inactive-user' : undefined}>
              {child.name}{' '}
              {child.contractorCompany !== null
                ? `(${child.contractorCompany.companyName})`
                : `(${child.user_metadata.companyName})`}{' '}
              - {child.app_metadata.role.name} (Tier {child.tier})
              {child.blocked ? 'Inactive' : null}{' '}
              {proxyUsers.length ? (
                <span style={{ backgroundColor: '#ff4d5036' }}>
                  {`( ${proxyUsers.map((user: UserType) => user.name).join(', ')} )`}
                </span>
              ) : null}
            </span>
          }
          key={child.user_id}
        >
          {buildTree(getList(accountsTeam), child)}
        </Tree.TreeNode>
      );
    });
  };

  const proxyUsersColumns = [
    {
      title: <b>Name</b>,
      dataIndex: 'name',
      render: (value: string, row: UserType) =>
        editingKey === row.user_id ? (
          <input name="name" value={name} onChange={e => setName(e.target.value)} />
        ) : (
          value
        ),
    },
    {
      title: <b>Email</b>,
      dataIndex: 'email',
    },
    {
      key: 'jobTitle',
      title: <b>Job Title</b>,
      dataIndex: 'user_metadata',
      render: (value: any) => (value.jobTitle ? value.jobTitle : <b>N/A</b>),
    },
    {
      key: 'package',
      title: <b>Package</b>,
      dataIndex: 'user_metadata',
      render: (value: any) => (value.package ? value.package : <b>N/A</b>),
    },
    {
      key: 'status',
      title: <b>Status</b>,
      dataIndex: 'blocked',
      render: (blocked: boolean) =>
        !blocked ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>,
    },
    {
      key: 'company',
      title: <b>Company Name</b>,
      dataIndex: 'user_metadata',
      render: (value: any, user: UserType) =>
        value.contractorCompanyID > 0 ? user.contractorCompany.companyName : value.companyName,
    },
    {
      key: 'reportsTo',
      title: <b>Proxy User Reports To</b>,
      dataIndex: 'user_metadata',
      render: (value: any, user: UserType) =>
        value.reportsToUserID && value.reportsToUserID !== '' ? (
          user.reportsTo.name
        ) : (
          <b>Owner (Tier - 1)</b>
        ),
    },
    {
      key: 'actions',
      render: (_: undefined, user: UserType) => {
        return (
          <div
            style={{
              gap: '0.5rem',
            }}
            className="d-flex justify-center align-items-center"
          >
            {user.user_id === editingKey ? (
              <>
                <Icon
                  className="edit-icon"
                  type="save"
                  theme="filled"
                  onClick={() => onSave(user)}
                />

                <Icon
                  style={{ fontSize: 14 }}
                  className="danger-icon"
                  type="close"
                  onClick={onCancel}
                />
              </>
            ) : (
              <>
                {user.user_metadata.isProxyUser && (
                  <Icon
                    style={{ fontSize: 14 }}
                    className="edit-icon"
                    type="edit"
                    onClick={() => onEdit(user)}
                  />
                )}
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <UserCertificateContainer
                          team={proxyUsers}
                          targetUserID={user.user_id}
                          usage="team"
                          site="admin"
                          defaultTabOpen="1"
                        />
                      </Menu.Item>

                      {!user.blocked ? (
                        <Menu.Item>
                          <a>
                            <Popconfirm
                              placement="topRight"
                              title="Are you sure you want to resend invite?"
                              onConfirm={() => {
                                dispatch(resendUserInviteAction.request(user.user_id));
                              }}
                              okText="Yes"
                            >
                              <div className="team-actions-dropdown-content">
                                <Icon type="mail" />
                                <b>Resend Invite &nbsp;</b>
                              </div>
                            </Popconfirm>
                          </a>
                        </Menu.Item>
                      ) : null}

                      <Menu.Item>
                        <TeamChangePasswordContainer user={user} />
                      </Menu.Item>

                      <Menu.Item>
                        <Popconfirm
                          placement="topRight"
                          title={
                            user.user_metadata.isProxyUser
                              ? 'Are you sure this proxy user will permanently removed from the account ?'
                              : 'Are you sure you want to unlink this proxy user ?'
                          }
                          onConfirm={() => {
                            dispatch(
                              blockProxyUserAction.request({
                                params: {
                                  userID: user.user_id,
                                  accountID: user.user_metadata.accountID,
                                },
                              }),
                            );
                          }}
                          okText="Yes"
                        >
                          <div className="team-actions-dropdown-content">
                            <Icon style={{ color: 'red' }} type="user-delete" />
                            <b>Proxy Status</b>
                            <Switch
                              defaultChecked={!user.blocked}
                              checked={!user.blocked}
                              size="small"
                            />
                          </div>
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Icon
                    className="hover-icon"
                    style={{ display: editingKey ? 'hidden' : 'block' }}
                    type="more"
                  />
                </Dropdown>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <ProxyUsersComponent
      user={actionSelectUser}
      accountsTeam={team}
      treeNodes={treeNodes}
      proxyUsersColumns={proxyUsersColumns}
      proxyUsers={proxyUsers}
      isLoading={isLoading}
      openModal={openModal}
      toggleModal={toggleModal}
    />
  );
};

export default ProxyUsersContainer;
