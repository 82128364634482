import React, { useCallback } from 'react';

import { AccountType, UserType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';

import { Select, Form, Empty, Icon } from 'antd';

import '@components/reports/reports.styles.scss';



export interface IAdminCAReportsComponentProps {
    team: UserType[];
    selectedAccount?: AccountType;
    selectedUser: UserType | undefined;
    form: WrappedFormUtils;
    setSelectedUser: (value: UserType | undefined) => void;
}

const { Option } = Select;


const AdminCAReports = (props: IAdminCAReportsComponentProps) => {

    const { team, selectedAccount, selectedUser, form, setSelectedUser } = props;
    const { getFieldDecorator } = form

    const reportKey = useCallback(
        () => {
            if (!selectedAccount)
                return '';

            if (selectedAccount.isPowerBIReportFree === false)
                return `reportEmbed?reportId=${selectedAccount.caPowerBIkey}&$filter=Submissions/tier${selectedUser ? selectedUser.tier : ''} eq '${selectedUser ? selectedUser.user_id : ''}'`

            else
                return `view?r=${selectedUser && selectedUser.userConfig.caPowerBIkey}`
        }, [selectedUser, selectedAccount]
    )

    return (
        <div className="reports__container">
            <div className='reports__choose-user'>
                <Form.Item
                >
                    {
                        getFieldDecorator('Choose User', { initialValue: selectedUser ? selectedUser.user_id : '' })
                            (
                                <Select
                                    placeholder="Choose User"
                                    onSelect={(value: any) => { setSelectedUser(team.filter((user: UserType) => user.user_id === value)[0]) }}
                                >

                                    {team.map((user: UserType) => (
                                        user.app_metadata.role.roleID === 2 ? <Option key={user.user_id} className={user.blocked ? 'inactive' : ``} value={user.user_id} >{user.name} {`(Tier - ${user.tier}) ${user.blocked ? '(Inactive)' : ''}`} </Option> : null
                                    ))}

                                </Select>
                            )
                    }
                </Form.Item>
            </div>

            {
                selectedAccount && !(selectedAccount.isPowerBIReportFree) ? (
                    selectedAccount.caPowerBIkey === '' ? (
                        <Empty image={<Icon type="info-circle" />} description="please setup associtate powerbi report key to access reports" />
                    ) : (
                        <iframe
                            title="Reports"
                            className="reports__power-bi"
                            src={`https://app.powerbi.com/${reportKey()}`}
                        >
                        </iframe>
                    )
                )
                    : selectedUser && selectedUser.userConfig.caPowerBIkey === '' ? (
                        <Empty image={<Icon type="info-circle" />} description="Please setup associtate powerbi report key to access reports" />
                    )
                        : (
                            <iframe
                                title="Reports"
                                className="reports__power-bi"
                                src={`https://app.powerbi.com/${reportKey()}`}
                            >
                            </iframe>
                        )
            }

        </div>
    );
};

export const AdminCAReportsComponent: any = Form.create({ name: 'AdminCAReportsComponent' })(AdminCAReports);
