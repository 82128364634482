import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';
import { MUTATE_PTP_API, MUTATE_PTP_TASK_API, PTP_API, PTP_TASK_API } from '@constants/endpoints';

// Actions
export const fetchPtpAction = createActionRoutine('FETCH_PTP', PTP_API);
export const createPtpAction = createActionRoutine('CREATE_PTP', PTP_API);
export const updatePtpAction = createActionRoutine('UPDATE_PTP', MUTATE_PTP_API);
export const deletePtpAction = createActionRoutine('DELETE_PTP', MUTATE_PTP_API);

export const fetchPtpTaskAction = createActionRoutine('FETCH_PTP_TASK', MUTATE_PTP_TASK_API);
export const createPtpTaskAction = createActionRoutine('CREATE_PTP_TASK', PTP_TASK_API);
export const updatePtpTaskAction = createActionRoutine('UPDATE_PTP_TASK', MUTATE_PTP_TASK_API);
export const deletePtpTaskAction = createActionRoutine('DELETE_PTP_TASK', MUTATE_PTP_TASK_API);
