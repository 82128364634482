import React, { ReactNode, useEffect, useState, SetStateAction, Fragment } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { UserType } from '@shared/types/backend.types';
import { History } from 'history';

import { withRouter } from 'react-router-dom';

import { AddAccountPayload, UpdateAccountPayload } from '@app/admin/accounts/accounts';

import { connect } from 'react-redux';
import { getActiveSidemenu } from '@layout/layout.selector';
import { logoutAction } from '@rdx/root.action';
import {
  fetchUserProfileAction,
  setAccountIdAction,
  setTeamAccessUserIdAction,
} from '@session/session.action';
import {
  createCompanyAction,
  fetchAccountsAction,
} from '@app/admin/accounts/state/accounts.actions';
import { getSessionUser, getUserRole } from '@session/session.selector';

import { getAccountID } from '@app/admin/invite/state/invite.selector';
import { setAccountIDAction } from '@app/admin/admin.action';

import { getAccounts } from '@app/admin/accounts/state/accounts.selector';

import { getWizardPermit } from '@app/back-office/professional.selector';

import { NavigationMenuComponent } from '@layout/navigation-menu/navigation-menu.component';
import { LoadingSpinnerComponent } from '@controls/loading/spinner.component';

import { WrappedFormUtils } from 'antd/es/form/Form';
import { REGEX } from '@constants/regex';

import Cookies from 'js-cookie';

import { fetchWizardPermitAction } from '@app/back-office/professional.action';
import { getUserSession, removeUserSession } from '@services/storage/session.service';
import { HelpFormContainer } from './help-form/state/help-form.container';
import { AccountDisabledComponent } from '@shared/components/layout/codes/account-disabled/view/account-disable.component';

import { getAccountCompanyInformation } from '@app/admin/accounts/state/accounts.selector';
import { AccountType } from '@shared/types/backend.types';
import { getTeam, getUserTeam } from '@app/back-office/team/state/team.selector';
import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';
import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { ROLE, ROLES } from '@constants/roles';

export interface INavigationMenuContainerProps {
  loggedUser: UserType;
  accountTeam: UserType[];
  accounts: AccountType[];
  selectedCompanyInformation: AccountType;
  accountID?: number;
  userRole: string;
  //TODO: add types
  sidemenuPaths: any;
  hasWizard: boolean;
  location: Location;
  history: History;
  match: any;
  children?: ReactNode;
  handleLogoutAction: () => void;
  fetchUserAction: (email: string) => void;
  fetchWizardPermitAction: (accountID: number) => void;
  fetchAccounts: () => void;
  fetchAccountTeam: (accountID: number) => void;
  setAccountIDAction: (accountID: number) => void;
  setSessionAccountIDAction: (accountID: number) => void;
  createCompanyAction: (payload: AddAccountPayload) => void;
  updateCompanyAction: (payload: UpdateAccountPayload) => void;
  setTeamAccessUserIdAction: (userID: string) => void;
}

const NavigationMenu = (props: INavigationMenuContainerProps) => {
  const {
    loggedUser,
    accountTeam,
    accounts,
    selectedCompanyInformation,
    accountID,
    userRole,
    sidemenuPaths,
    hasWizard,
    location,
    history,
    children,
  } = props;
  const {
    fetchUserAction,
    fetchAccountTeam,
    fetchWizardPermitAction,
    handleLogoutAction,
    fetchAccounts,
    setAccountIDAction,
    setSessionAccountIDAction,
    createCompanyAction,
    updateCompanyAction,
    setTeamAccessUserIdAction,
  } = props;
  const session = getUserSession();
  const [showHelpModal, setShowHelpModal] = useState<SetStateAction<any>>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>(undefined);
  const [isCreatingCompany, setIsCreatingCompany] = useState<boolean | undefined>(undefined);
  const [companyInfo, setCompanyInfo] = useState<SetStateAction<any>>({});
  const [loggedUserAccesses, setLoggedUserAccesses] = useState<UserType[]>([]);
  const [showAccountDisabledModal, setShowAccountDisabledModal] = useState<SetStateAction<any>>(
    false,
  );

  useEffect(() => {
    const session = getUserSession();
    session && session.email && fetchUserAction(session.email);
  }, [fetchUserAction]);

  useEffect(() => {
    if (accountTeam.length && loggedUser && loggedUser.user_metadata.proxyUsers) {
      setLoggedUserAccesses(
        accountTeam
          .filter(user => loggedUser.user_metadata.proxyUsers.includes(user.user_id))
          .sort((a, b) => a.tier - b.tier),
      );
    }
  }, [accountTeam]);

  useEffect(() => {
    if (loggedUserAccesses.length) setTeamAccessUserIdAction(loggedUserAccesses[0].user_id);
  }, [loggedUserAccesses]);

  useEffect(() => {
    // this code runs when a administrator (roleID === 2) login
    if (loggedUser && loggedUser.user_metadata.accountID) {
      fetchWizardPermitAction(loggedUser.user_metadata.accountID);
      if (loggedUser.user_metadata.isAccountActive === false) {
        setShowAccountDisabledModal(true);
      }
    }

    if (loggedUser) {
      if (loggedUser.app_metadata.role.roleID === 3 && accountID) {
        fetchAccountTeam(accountID);
      } else {
        fetchAccountTeam(loggedUser.user_metadata.accountID);
      }
    }
  }, [loggedUser]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    if (!accountID && accounts.length && loggedUser && loggedUser.app_metadata.role.roleID === 3) {
      setAccountIDAction(accounts[0].accountID);
      setSessionAccountIDAction(accounts[0].accountID);
    }
  }, [loggedUser && accounts]);

  const handleLogout = () => {
    handleLogoutAction();

    history.push('/');

    Cookies.remove('sessionKey');
    removeUserSession();
  };

  if (!session) {
    handleLogout();
  }

  if (!loggedUser || !userRole) {
    return <LoadingSpinnerComponent />;
  }

  const handleShowHelpForm = () => {
    setShowHelpModal(true);
  };

  const handleCloseHelpForm = () => {
    setShowHelpModal(false);
  };

  const handleSaveAccount = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err) {
        createCompanyAction({ formValues });
        setIsModalVisible(undefined);
        fetchAccounts();
        form.resetFields();
      }
    });
  };

  const validateKeys = (e: any) => {
    if (!REGEX.validateNumber.test(e.key) || e.target.value.length >= 10) {
      e.preventDefault();
    }
  };

  const handleToggleModal = (isUpdating: boolean, form?: WrappedFormUtils) => {
    if (isModalVisible && form) {
      setIsCreatingCompany(undefined);
      setIsModalVisible(undefined);
      form.resetFields();
    } else {
      setIsCreatingCompany(!isUpdating);
      setIsModalVisible(!isModalVisible);
    }
  };

  const handleUpdateAccount = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err && accountID) {
        const mergedCompanyInfo = { ...companyInfo, ...formValues };

        updateCompanyAction({ formValues: mergedCompanyInfo, accountID: accountID });
        setIsModalVisible(undefined);
        setCompanyInfo(mergedCompanyInfo);
        form.resetFields();
      }
    });
  };

  return (
    <Fragment>
      {showHelpModal && (
        <HelpFormContainer
          show={showHelpModal}
          setShowHelpModal={setShowHelpModal}
          handleCloseHelpForm={handleCloseHelpForm}
        />
      )}
      {showAccountDisabledModal && (
        <AccountDisabledComponent show={showAccountDisabledModal} handleLogout={handleLogout} />
      )}
      <NavigationMenuComponent
        user={loggedUser}
        accounts={accounts}
        userRole={userRole}
        hasWizard={hasWizard}
        sidemenuPaths={sidemenuPaths}
        location={location}
        loggedUserAccesses={loggedUserAccesses}
        handleLogout={handleLogout}
        handleShowHelpForm={handleShowHelpForm}
        setAccountIDAction={setAccountIDAction}
        validateKeys={validateKeys}
        isModalVisible={isModalVisible}
        isCreatingCompany={isCreatingCompany}
        selectedCompanyInformation={selectedCompanyInformation}
        handleToggleModal={handleToggleModal}
        handleUpdateAccount={handleUpdateAccount}
        handleSaveAccount={handleSaveAccount}
        setTeamAccessUserIdAction={setTeamAccessUserIdAction}
      >
        {children}
      </NavigationMenuComponent>
    </Fragment>
  );
};

const mapStateToProps = (state: IAppState) => ({
  loggedUser: getSessionUser(state),
  accountTeam: getAccountsTeam(state),
  accounts: getAccounts(state),
  accountID: getAccountID(state),
  selectedCompanyInformation: getAccountCompanyInformation(state),
  userRole: getUserRole(state),
  sidemenuPaths: getActiveSidemenu(state),
  hasWizard: getWizardPermit(state),
});

const mapDispatchToProps = {
  handleLogoutAction: logoutAction.trigger,
  setAccountIDAction: setAccountIDAction.trigger,
  setSessionAccountIDAction: setAccountIdAction.fulfill,
  createCompanyAction: createCompanyAction.request,
  fetchUserAction: fetchUserProfileAction.request,
  fetchWizardPermitAction: fetchWizardPermitAction.request,
  fetchAccounts: fetchAccountsAction.request,
  fetchAccountTeam: fetchAccountUsersAction.request,
  setTeamAccessUserIdAction: setTeamAccessUserIdAction.trigger,
};

const navMenuWithRouter = withRouter(NavigationMenu as any);
export const NavigationMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(navMenuWithRouter as any);
