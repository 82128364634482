import {
  IProfessionalState,
  PROFESSIONAL_INITIAL_STATE,
} from '@app/back-office/professional.reducer';
import { AnyAction } from 'redux';
import { ProjectType, UserType } from '@shared/types/backend.types';

import {
  resetSafetySetupAction,
  fetchIndustriesAction,
  configAddProjectsAction,
  updateAccountConfigurationAction
} from './programs.actions';

import { setupCreationReducer } from '../creation/state/creation.reducer';
import { setupProjectsReducer } from '../projects/state/projects.reducer';
import { setupPlansReducer } from '../plans/state/plans.reducer';

//TODO: add type
export type IProfessionalProgramsState = {
  accountID: number;
  userID: string;
  industryID: number;
  hasImage: boolean;
  forms: any;
  projects: {
    [key: string]: ProjectType;
  };
  projectCachedCounter: number;
  jobTitles: string[];
  team: {
    [key: string]: UserType;
  };
  teamCachedCounter: number;
  teamUpload: any[];
  stepTracker: {
    [key: string]: any;
  };
  meta: {
    isSubmitSuccess?: boolean;
    currentStep: number;
    currentSubstep: number;
    currentQuestion: any;
    validSteps: boolean[];
  };
};

export const PROGRAMS_INITIAL_STATE = {
  accountID: 0,
  userID: '',
  industryID: 0,
  hasImage: false,
  forms: {},
  projects: {},
  projectCachedCounter: 0,
  contractors: {},
  contractorCachedCounter: 0,
  jobTitles: [],
  team: {},
  teamCachedCounter: 0,
  teamUpload: [],
  stepTracker: {},
  meta: {
    isSubmitSuccess: undefined,
    currentStep: 0,
    currentSubstep: 1,
    currentQuestion: null,
    validSteps: [true, false, false, false],
  },
};

//TODO: implement immutability helper
export const programsReducer = {
  [resetSafetySetupAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    errorMessages: {},
    programs: {
      ...PROFESSIONAL_INITIAL_STATE.programs,
      meta: {
        isSubmitSuccess: false,
        currentStep: 0,
        currentSubstep: 1,
        currentQuestion: null,
        validSteps: [true, false, false],
      },
    },
  }),
  [configAddProjectsAction.SUCCESS as any]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    programs: {
      ...state.programs,
      meta: {
        ...state.programs.meta,
        isSubmitSuccess: true,
      },
    },
    errorMessages: {
      ...state.errorMessages,
      [payload.code]: undefined,
    },
  }),
  [fetchIndustriesAction.REQUEST]: (state: IProfessionalState) => ({
    ...state,
    isLoading: true,
  }),
  [fetchIndustriesAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    isLoading: false,
    errorMessages: {
      ...state.errorMessages,
      [payload.error.code]: payload.error,
    },
  }),
  [fetchIndustriesAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    industries: payload.industries,
    isLoading: false,
  }),
  [updateAccountConfigurationAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => ({
    ...state,
    programs: {
      ...state.programs,
      meta: {
        ...state.programs.meta,
        isSubmitSuccess: true,
      },
    },
    errorMessages: {
      ...state.errorMessages,
      [payload.code]: undefined,
    },
  }),
  ...setupCreationReducer,
  ...setupProjectsReducer,
  ...setupPlansReducer,
};
