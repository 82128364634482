import { takeLatest, put, call } from 'redux-saga/effects';

import { submitSalesFormAction } from './sales-form.action';
import { AnyAction } from 'redux';
import { addService } from '@rdx/services.saga';

function* submitSalesFormRequest(action: AnyAction) {
    const { item } = action.payload;
    const endpoint = submitSalesFormAction.getEndpoint({});
    try {
        const requestBody = {
            content: item.name
        }
        yield call (addService, endpoint, requestBody);
        yield put(submitSalesFormAction.success({}));
    } catch (error) {
        if(!error.wasCancelled) {
            yield put(
                submitSalesFormAction.failure({
                    error: {
                        code: 'salesFormSubmit',
                        title: 'Failed to submit sales request.',
                        message: 'There has been an error while sending sales request. Please try again.', 
                    }
                }),
            );
        }
    }
}

export function* salesFormSaga(){
    yield takeLatest(submitSalesFormAction.REQUEST, submitSalesFormRequest);
}