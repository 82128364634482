
import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { fetchUnderUserCA, fetchCATriggers, fetchCASeveritys, fetchCAStatus, fetchCATypes, fetchCATickets, UpdateUnderUserCA, fetchUserManagers, createCorrectiveAction, fetchCorrectiveAction } from "./corrective-actions.actions";
import { addService, fetchService } from '@rdx/services.saga';
import { updateService } from '@rdx/services.saga';
import { message } from 'antd';
import { arrayToObject } from '@shared/helpers/state-caster';
import { createTeamTearing } from '@shared/utils/add-team-tiering';

function* fetchCorrectiveActionsRequest(action: AnyAction) {

    try {
        const endpoint = fetchUnderUserCA.getEndpoint({ userID: action.payload });

        const response = yield call(fetchService, endpoint);

        yield put(fetchUnderUserCA.success(response));

    } catch (error) {
        yield put(fetchUnderUserCA.failure(error));
    }
}

function* fetchCorrectiveActionRequest(action: AnyAction) {
    try {
        const endpoint = fetchCorrectiveAction.getEndpoint({ accountID: action.payload.accountID, correctiveActionID: action.payload.caID });

        const response = yield call(fetchService, endpoint);

        yield put(fetchCorrectiveAction.success(response));

    } catch (error) {
        yield put(fetchCorrectiveAction.failure(error));
    }
}

function* createCorrectiveActionRequest(action: AnyAction) {

    try {
        message.loading({ content: 'Creating Corrective Action', key: 'ca-update', duration: 0 });
        const endpoint = createCorrectiveAction.getEndpoint();
        const response = yield call(addService, endpoint, action.payload);
        yield put(createCorrectiveAction.success(response));
        message.success({ content: 'Corrective Action Created', key: 'ca-update' });
    } catch (error) {
        yield put(createCorrectiveAction.failure(error));
        message.error({ content: 'Error! While Creating the action', key: 'ca-update' });
    }
}

function* fetchCATriggersRequest() {

    try {
        const endpoint = fetchCATriggers.getEndpoint();

        const response = yield call(fetchService, endpoint);

        yield put(fetchCATriggers.success(response));

    } catch (error) {
        yield put(fetchCATriggers.failure(error));
    }
}

function* fetchCASeveritysRequest() {

    try {
        const endpoint = fetchCASeveritys.getEndpoint();

        const response = yield call(fetchService, endpoint);

        yield put(fetchCASeveritys.success(response));

    } catch (error) {
        yield put(fetchCASeveritys.failure(error));
    }
}

function* fetchCAStatusRequest() {

    try {
        const endpoint = fetchCAStatus.getEndpoint();

        const response = yield call(fetchService, endpoint);

        yield put(fetchCAStatus.success(response));

    } catch (error) {
        yield put(fetchCAStatus.failure(error));
    }
}

function* fetchCATypesRequest() {

    try {
        const endpoint = fetchCATypes.getEndpoint();

        const response = yield call(fetchService, endpoint);

        yield put(fetchCATypes.success(response));

    } catch (error) {
        yield put(fetchCATypes.failure(error));
    }
}

function* fetchCATicketsRequest(action: AnyAction) {
    try {
        const endpoint = fetchCATickets.getEndpoint({ accountID: action.payload.accountID, correctiveActionID: action.payload.caID });

        const response = yield call(fetchService, endpoint);

        yield put(fetchCATickets.success(response));

    } catch (error) {
        yield put(fetchCATickets.failure(error));
    }
}

function* UpdateCorrectiveActionRequest(action: AnyAction) {
    try {
        message.loading({ content: 'Loading', key: 'ca-update' });
        const endpoint = UpdateUnderUserCA
            .getEndpoint
            ({ userID: action.payload.userID, correctiveActionID: action.payload.correctiveActionID });

        const response = yield call(updateService, endpoint, action.payload.formValues);
        yield put(UpdateUnderUserCA.success(response));
        message.success({ content: 'Corrective Action Updated', key: 'ca-update' });
    } catch (error) {
        message.error({ content: 'Error! While Updating the action', key: 'ca-update' });
        yield put(UpdateUnderUserCA.failure(error));
    }
}

function* fetchReportsToUsersRequest(action: AnyAction) {
    try {
        const endpoint = fetchUserManagers.getEndpoint({ userID: action.payload });
        const response = yield call(fetchService, endpoint);
        createTeamTearing(response, null, 1)
        yield put(fetchUserManagers.success(response));
    } catch (error) {
        yield put(fetchUserManagers.failure(error));
    }
}

export function* correctiveActionsSaga() {
    yield takeLatest(createCorrectiveAction.REQUEST, createCorrectiveActionRequest);
    yield takeLatest(fetchCorrectiveAction.REQUEST, fetchCorrectiveActionRequest);
    yield takeLatest(fetchUnderUserCA.REQUEST, fetchCorrectiveActionsRequest);
    yield takeLatest(UpdateUnderUserCA.REQUEST, UpdateCorrectiveActionRequest);
    yield takeLatest(fetchCATriggers.REQUEST, fetchCATriggersRequest);
    yield takeLatest(fetchCASeveritys.REQUEST, fetchCASeveritysRequest);
    yield takeLatest(fetchCAStatus.REQUEST, fetchCAStatusRequest);
    yield takeLatest(fetchCATypes.REQUEST, fetchCATypesRequest);
    yield takeLatest(fetchCATickets.REQUEST, fetchCATicketsRequest);
    yield takeLatest(fetchUserManagers.REQUEST, fetchReportsToUsersRequest);
}
