import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import { FETCH_CA_SEVERITYS_API, FETCH_CA_STATUS_API, FETCH_CA_TICKETS_API, CORRECTIVE_ACTIONS_API, FETCH_CA_TRIGGERS_API, FETCH_CA_TYPES_API, FETCH_UNDERUSER_CA_API, UPDATE_UNDERUSER_CA_API, FETCH_CORRECTIVE_ACTION_API, FETCH_USER_MANAGERS_API } from '@constants/endpoints';

const FETCH_UNDERUSER_CA = 'FETCH_UNDERUSER_CA';
const FETCH_CA_TRIGGERS = 'FETCH_CA_TRIGGERS';
const FETCH_CA_SEVERITYS = 'FETCH_CA_SEVERITYS';
const FETCH_CA_STATUS = 'FETCH_CA_STATUS';
const FETCH_CA_TYPES = 'FETCH_CA_TYPES';
const FETCH_CA_TICKETS = 'FETCH_CA_TICKETS';
const UPDATE_UNDERUSER_CA = 'UPDATE_UNDERUSER_CA'
const FETCH_REPORTSTO_USERS = 'FETCH_REPORTSTO_USERS'
const CREATE_CA = 'CREATE_CA'
const FETCH_CORRECTIVE_ACTION = 'FETCH_CORRECTIVE_ACTION'

export const fetchUnderUserCA = createActionRoutine(FETCH_UNDERUSER_CA, FETCH_UNDERUSER_CA_API);
export const fetchCorrectiveAction = createActionRoutine(FETCH_CORRECTIVE_ACTION, FETCH_CORRECTIVE_ACTION_API);
export const fetchCATriggers = createActionRoutine(FETCH_CA_TRIGGERS, FETCH_CA_TRIGGERS_API);
export const fetchCASeveritys = createActionRoutine(FETCH_CA_SEVERITYS, FETCH_CA_SEVERITYS_API);
export const fetchCAStatus = createActionRoutine(FETCH_CA_STATUS, FETCH_CA_STATUS_API);
export const fetchCATypes = createActionRoutine(FETCH_CA_TYPES, FETCH_CA_TYPES_API);
export const fetchCATickets = createActionRoutine(FETCH_CA_TICKETS, FETCH_CA_TICKETS_API);
export const UpdateUnderUserCA = createActionRoutine(UPDATE_UNDERUSER_CA, UPDATE_UNDERUSER_CA_API);
export const fetchUserManagers = createActionRoutine(FETCH_REPORTSTO_USERS, FETCH_USER_MANAGERS_API);
export const createCorrectiveAction = createActionRoutine(CREATE_CA, CORRECTIVE_ACTIONS_API);

