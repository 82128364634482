import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { Submission } from '@shared/types/backend.types';

import { fetchAccountSubmissionsAction } from './submissions.actions';

import { arrayToObject } from '@shared/helpers/state-caster';
import { fetchService } from '@rdx/services.saga';

import moment from 'moment';

function* fetchAccountSubmissionsRequest(action: AnyAction) {
  const { accountID } = action.payload;
  try {
    const endpoint = fetchAccountSubmissionsAction.getEndpoint({ accountID });

    const response: Submission[] = yield call(fetchService, endpoint);

    const submissions = arrayToObject(response, 'id');
    
    yield put(fetchAccountSubmissionsAction.success({ submissions }));

  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchAccountSubmissionsAction.failure({
          code: 'backOfficeProjectForms',
          title: 'Failed to retrieve forms',
          message: 'There has been an error while retrieving forms.',
        }),
      );
    }
  }
}

export function* submissionsSaga() {
  yield takeLatest(fetchAccountSubmissionsAction.REQUEST, fetchAccountSubmissionsRequest);
}
