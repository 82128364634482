import React from 'react';

import { Submission } from '@shared/types/backend.types';

import { Tooltip } from 'antd';
import moment from 'moment';

interface IProjectFormItemProps {
  submission: Submission;
  handleShowViewer: (submissionID: string) => void;
}

export const ProjectFormItemComponent = (props: IProjectFormItemProps) => {
  const { handleShowViewer } = props;
  const { submission } = props;
  const { safetyPlanName, formName, submissionDateTime, user, id, project } = submission;

  return (
    <tr>
      <td>{project.name}</td>
      <td>{user.name}</td>
      <td>{safetyPlanName}</td>
      <td className="align-left">{formName}</td>
      <td>{moment(submissionDateTime).format('MM-DD-YYYY')}</td>
      <td>
        <Tooltip title="View PDF">
          <i className="far fa-file-pdf btn__pdf" onClick={() => handleShowViewer(id)} />
        </Tooltip>
      </td>
    </tr>
  );
};
