import React from 'react';

import { IMappedStep } from '@shared/types/professional.types';
import { ThemeType } from '@shared-types/theme.type';
import { Classes } from 'jss';

import injectSheet from 'react-jss';

import { STATUS } from '@constants/status';

import '@components/back-office/safety-programs/list.styles.scss';
import { TabsComponent } from '@components/tabs/tabs.component';

export interface IPlanListProps {
  //TODO: add type
  plans: any;
  steps: IMappedStep[];
  currentStep: number;
  classes: Classes;
  handleChangeCurrentPlan: (nextStep: number, isFinished?: boolean) => void;
}

const PlanList = (props: IPlanListProps) => {
  const { plans, steps, currentStep } = props;
  const { handleChangeCurrentPlan } = props;

  const __renderListItem = (): JSX.Element[] | null => {
    if (Object.keys(steps).length === 0) {
      return null;
    }

    return plans.map((step: IMappedStep) => {
      const activeClass = currentStep === step.id ? STATUS.active : '';

      return (
        <TabsComponent
          key={step.id}
          planName={step.name}
          stepID={step.id}
          steps={steps}
          status={step.status}
          activeClass={activeClass}
          handleChangeCurrentPlan={handleChangeCurrentPlan}
        />
      );
    });
  };

  return <nav className="plans__list">{__renderListItem()}</nav>;
};

const styles = (theme: ThemeType) => ({});

export const PlanListComponent = injectSheet(styles)(PlanList);
