import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';
import { Submission } from '@shared/types/backend.types';

export const _getProjectSubmissions = (state: IAppState) => state.admin.projectSubmissions.items;

export const getProjectSubmissions = createSelector(
  [_getProjectSubmissions],
  (submissions): Submission[] => getList(submissions),
);

export const _getProjectSubmissionsLoading = (state: IAppState) =>
  state.admin.projectSubmissions.isLoading;

export const getProjectSubmissionsLoading = createSelector(
  [_getProjectSubmissionsLoading],
  isLoading => isLoading,
);