import { UserType } from "@shared/types/backend.types"

export const filterAdminUsers = (users: UserType[]) => {
    var filteredAdminUsers: UserType[] = [];

    users.map((user: UserType) => {
        if (user.app_metadata.role.roleID !== 1) {
            filteredAdminUsers.push(user)
        }
    })
    return filteredAdminUsers
}