import React, { Fragment } from 'react';

import { RegulationType, RegulationImageType } from '@shared/types/backend.types';

import { Modal } from 'antd';

interface IModalRegulationsProps {
  regulations: RegulationType[];
  isVisible: boolean;
  footer: React.ReactNode;
  onClick: () => any;
  onOk: () => any;
  onCancel: () => any;
}

export const ModalRegulationsComponent = (props: IModalRegulationsProps) => {
  const { regulations, isVisible, footer } = props;
  const { onClick, onOk, onCancel } = props;

  return (
    <Modal
      title={
        <div className="modal__questions-container">
          <i className="fas fa-arrow-left" onClick={onClick} />
          <h2 style={{ maxWidth: 720, fontSize: 28, textAlign: 'center', margin: 'auto' }}>
            <strong>Regulations</strong>
          </h2>
        </div>
      }
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      width={'60%'}
    >
      <ul>
        {regulations &&
          regulations.map(({ number, description, images }: RegulationType) => (
            <Fragment key={number}>
              <li style={{ fontSize: 18, listStyle: 'none' }}>
                <h4>{number}</h4>
                <p>{description}</p>
                <div style={{ textAlign: 'center' }}>
                  {images && images.map(({ uri }: RegulationImageType) => (
                    <img src={uri} alt="Regulation" style={{ width: '80%' }} />
                  ))}
                </div>
              </li>
              <br />
            </Fragment>
          ))}
      </ul>
    </Modal>
  );
};
