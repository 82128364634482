import React, { useEffect, useState } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { connect } from 'react-redux';

import SubmissionsReportsComponent from '../view/sub.reports.component';

import { UserType, UsersConfigType, AccountType } from '@shared/types/backend.types';

import { fecthUsersConfigAction } from '@app/admin/accounts/state/accounts.actions';
import { fetchTeamAction } from '@app/back-office/team/state/team.actions';

import { getUsersConfig } from '@app/admin/accounts/state/accounts.selector';
import { getTeam } from '@app/back-office/team/state/team.selector';
import { getUserCompany } from '@app/session/session.selector';
import { getSessionUser } from '@app/session/session.selector';

// Helper Functions
import { getUnderUsers } from '@shared/utils/filterSubUsers';
import _ from 'lodash';

//TODO: add types
interface IReportsContainerProps {
  reports: any;
  company?: AccountType;
  user?: UserType;
  team: UserType[]
  usersConfig: UsersConfigType[];
  fecthUsersConfig: (loggedUserID: number) => void;
  fetchTeamAction: (accountID: number) => void;
}

const SubmissionsReports = (props: IReportsContainerProps) => {
  const { team, user, company, usersConfig, fecthUsersConfig, fetchTeamAction } = props;

  const [userConfig, setUserConfig] = useState<UsersConfigType | undefined>(undefined)
  const [underUsers, setUnderUsers] = useState([] as any);
  const [selectedUser, setSelectedUser] = useState<UserType | undefined>(undefined)
  const [loggedUser, setLoggedUser] = useState<undefined | UserType>(undefined)

  useEffect(() => {
    if (user) {
      setLoggedUser(user);
    }
  }, [user])

  useEffect(() => {
    if (loggedUser) {
      fecthUsersConfig(loggedUser.user_metadata.accountID)
      fetchTeamAction(loggedUser.user_metadata.accountID)
    }
  }, [loggedUser])

  useEffect(() => {
    if (loggedUser)
      setUserConfig(usersConfig.filter((userConfig: UsersConfigType) => userConfig.user_id === loggedUser.user_id)[0])
  }, [usersConfig])

  useEffect(() => {
    if (loggedUser) {
      setUnderUsers(getUnderUsers(team, loggedUser.user_id));
    }
  }, [team]);

  useEffect(() => {
    if (loggedUser) {
      setSelectedUser(team.filter((user: UserType) => user.user_id === loggedUser.user_id)[0])
    }
  }, [team]);

  const sortedTeam = _.orderBy(underUsers, ['tier'], ['asc']);


  return (
    <SubmissionsReportsComponent
      loggedUserConfig={userConfig}
      user={loggedUser}
      company={company}
      underUsers={sortedTeam}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  company: getUserCompany(state),
  user: getSessionUser(state),
  usersConfig: getUsersConfig(state),
  team: getTeam(state),
});

const mapDispatchToProps = {
  fecthUsersConfig: fecthUsersConfigAction.request,
  fetchTeamAction: fetchTeamAction.request,
};

const SubmissionsReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmissionsReports);

export default SubmissionsReportsContainer;
