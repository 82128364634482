import React, { useState, FormEvent } from 'react';

import { UserCertificateType, UserType } from '@shared/types/backend.types';
import { Sorter } from '@shared/types/utils.types';

import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';
import { TeamItemComponent } from '../item/item.component';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { TeamLockedItemComponent } from '../item/locked-item.component';
import { SorterComponent } from '@components/controls/table/sorter.component';
import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';
import { EmptyRowComponent } from '@components/controls/table/empty-row.component';
import { Tree, Modal, Button, Select, Checkbox } from 'antd';

import '@utils/styles/cursor.scss';
import './team.styles.scss';
import '@shared/utils/styles/sa.tree.styles.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AntTreeNode } from 'antd/lib/tree';

interface IAccountsTeamProps {
  roles: []; //TODO: unused prop
  companyContractors: [];
  team: UserType[];
  orderedTeam: UserType[];
  isLoading: boolean;
  sorter: Sorter;
  loggedUser?: UserType;
  selectedAccountID?: number;
  selectedRolID?: number;
  modifiedUser: any;
  treeNodes: any;
  handleSaveUser: (user_id: string) => void;
  onFilterChange: (value: string) => void;
  resendUserInvite: (selectedUserId: string) => void;
  toggleCheckBoxChange: (value: boolean) => void;
  handleUpdateUser: (event: any, user: any) => void;
  setselectedRolID: (rolID?: number) => void;
  handleJobTitleChange: (value: any, user_id: string) => void;
  handleDeleteUser: (id: string, status: boolean) => void;
  handleSort: (key: string) => void;
  handleFecthCertificate: (selectedUserId: string | undefined) => void;
}

export const AccountsTeamComponent = (props: IAccountsTeamProps) => {
  const {
    team,
    orderedTeam,
    companyContractors,
    loggedUser,
    treeNodes,
    isLoading,
    selectedAccountID,
    selectedRolID,
    sorter,
    modifiedUser,
  } = props;

  const {
    handleSaveUser,
    handleDeleteUser,
    toggleCheckBoxChange,
    onFilterChange,
    handleSort,
    resendUserInvite,
    handleUpdateUser,
    handleJobTitleChange,
    setselectedRolID,
    handleFecthCertificate,
  } = props;

  const [showTreeModal, setTreeModal] = useState(false);
  const [openProfileModal, setProfileModal] = useState(false);
  const [targetUserID, setTargetUserID] = useState<string | undefined>(undefined);

  const renderUsers = () => {
    if (isLoading) {
      return (
        <td colSpan={9}>
          <div style={{ padding: 50 }}>
            <TableLoadingSpinnerComponent />
            <h4 className="table__accounts-team__loading-title">Loading Team</h4>
          </div>
        </td>
      );
    }

    if (orderedTeam.length === 0) {
      return <EmptyRowComponent colspan={9} text="The account's team is empty." />;
    }

    if (loggedUser) {
      return orderedTeam.map((user: UserType) =>
        user.user_id === loggedUser.user_id || user.app_metadata.role.roleID === 3 ? (
          <TeamLockedItemComponent key={user.user_id} user={user} />
        ) : (
          <TeamItemComponent
            team={team}
            companyContractors={companyContractors}
            key={user.user_id}
            user={user}
            modifiedUser={modifiedUser}
            handleUpdateUser={handleUpdateUser}
            selectedAccountID={selectedAccountID}
            selectedRolID={selectedRolID}
            setselectedRolID={setselectedRolID}
            resendUserInvite={resendUserInvite}
            handleJobTitleChange={handleJobTitleChange}
            handleDeleteUser={handleDeleteUser}
            handleSaveUser={handleSaveUser}
          />
        ),
      );
    }
  };

  return (
    <>
      <Modal
        title="Organization Tree"
        className="sa-tree"
        visible={showTreeModal}
        onCancel={() => setTreeModal(false)}
        destroyOnClose
        footer={
          <Button
            className="ant-modal-btn-close"
            type="primary"
            onClick={() => {
              setTreeModal(false);
            }}
          >
            Close
          </Button>
        }
      >
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Checkbox
              onChange={(value: CheckboxChangeEvent) => toggleCheckBoxChange(value.target.checked)}
            >
              <b>Include Inactive</b>
            </Checkbox>
          </div>
          {isLoading === true ? (
            <TableLoadingSpinnerComponent />
          ) : treeNodes && treeNodes.length ? (
            <div className="sa-modal-margin">
              <Tree
                defaultExpandAll
                showLine={true}
                selectable={false}
                onClick={(event: any, node: AntTreeNode) => {
                  setTargetUserID(node.props.eventKey);
                  handleFecthCertificate(node.props.eventKey);
                  setProfileModal(true);
                }}
                multiple
              >
                {treeNodes}
              </Tree>
            </div>
          ) : (
            <>No Associates</>
          )}
        </>
      </Modal>

      <UserCertificateContainer
        team={team}
        targetUserID={targetUserID}
        open={openProfileModal}
        setOpen={setProfileModal}
        usage="tree"
        site="admin"
        defaultTabOpen="1"
      />

      <div className="sa__box-heading">
        <h4 className="sa__box-heading-title">Associates</h4>
        <Button
          type="primary"
          onClick={() => {
            setTreeModal(true);
          }}
        >
          Organization Tree
        </Button>
      </div>

      <div className="account-team__table-container sa__box-content">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            style={{ width: 200 }}
            defaultValue="active"
            onChange={(value: string) => onFilterChange(value)}
          >
            <Select.Option value="all"> All</Select.Option>
            <Select.Option value="active"> Active</Select.Option>
            <Select.Option value="inactive"> Inactive</Select.Option>
          </Select>
        </div>

        <table className="sa-table">
          <thead>
            <tr>
              <th className="unselectable" onClick={() => handleSort('name')}>
                Name
                <SorterComponent status={sorter.key === 'name' ? sorter.order : 'desc'} />
              </th>
              <th className="unselectable" onClick={() => handleSort('email')}>
                Email
                <SorterComponent status={sorter.key === 'email' ? sorter.order : 'desc'} />
              </th>
              <th className="unselectable" onClick={() => handleSort('user_metadata.jobTitle')}>
                Job Title
                <SorterComponent
                  status={sorter.key === 'user_metadata.jobTitle' ? sorter.order : 'desc'}
                />
              </th>
              <th className="unselectable" onClick={() => handleSort('user_metadata.package')}>
                License Type
                <SorterComponent
                  status={sorter.key === 'user_metadata.package' ? sorter.order : 'desc'}
                />
              </th>
              <th className="unselectable" onClick={() => handleSort('tier')}>
                Tier
                <SorterComponent status={sorter.key === 'tier' ? sorter.order : 'desc'} />
              </th>
              <th className="unselectable">Role</th>
              <th className="unselectable">Company Name</th>
              <th className="unselectable">Reports To</th>
              <th className="unselectable"></th>
            </tr>
          </thead>
          <tbody>{renderUsers()}</tbody>
        </table>
      </div>
    </>
  );
};
