import React from 'react';

import { useIntl } from 'react-intl';

import { ProfessionalPlansContainer } from '../plans/state/plans.container';
import { ProgramStepsContainer } from '../steps/state/steps.container';
import { ProfessionalProjectsContainer } from '../projects/state/projects.container';
import { ButtonControlComponent } from '@controls/button/button-control.component';

import './programs.styles.scss';

export interface IProgramsComponentProps {
  totalSteps: number;
  currentStep: number;
  validSteps: boolean[];
  isLoading: boolean;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleSubmit: () => void;
}

export const ProfessionalProgramsComponent = (props: IProgramsComponentProps) => {
  const { currentStep, totalSteps, validSteps, isLoading } = props;
  const { handleNextPage, handleSubmit } = props;
  const intlProvider = useIntl();

  return (
    <div className="programs__container">
      <ProgramStepsContainer />
      <div className="steps__tabs">
        <div className={currentStep !== 0 ? 'd-none' : 'md-w-90'} style={{ margin: '0px auto' }}>
          <ProfessionalPlansContainer />
        </div>
        <div className={currentStep !== 1 ? 'd-none' : 'md-w-100'}>
          <ProfessionalProjectsContainer />
        </div>
      </div>
      <div className="programs__buttons md-w-100">
        {currentStep < totalSteps - 1 && (
          <ButtonControlComponent
            text={intlProvider.messages['buttons.continue']}
            color="green"
            size="large"
            style={{ float: 'right', marginTop: 40, fontSize: 20 }}
            onClick={() => handleNextPage()}
            disabled={!validSteps[currentStep]}
          />
        )}
        {currentStep === totalSteps - 1 && (
          <ButtonControlComponent
            text={intlProvider.messages['buttons.submit']}
            color="green"
            size="large"
            style={{ float: 'right', marginTop: 40, fontSize: 20 }}
            onClick={() => handleSubmit()}
            loading={isLoading}
            disabled={!validSteps[currentStep]}
          />
        )}
        {/* {currentStep > 0 && (
          <ButtonControlComponent
            text={intlProvider.messages['buttons.back']}
            color="void"
            size="large"
            style={{
              float: 'left',
              marginTop: 40,
              marginLeft: 40,
              fontSize: 20,
              boxShadow: 'none',
            }}
            onClick={() => handlePrevPage()}
          />
        )} */}
      </div>
    </div>
  );
};
