import React, { Dispatch } from 'react';

import { QuestionType, FormType } from '@shared/types/backend.types';
import { ThemeType } from '@shared/types/theme.type';
import { Classes } from 'jss';

import { FormattedMessage } from 'react-intl';
import { PlanFormComponent } from '@components/controls/checkbox/form/form.component';
import { ModalRegulationsComponent } from '@components/modal/regulations.component';
import { ModalQuestionsComponent } from '@components/modal/questions.component';
import { Checkbox, Row, Icon, Spin } from 'antd';

import injectSheet from 'react-jss';
import '@components/back-office/safety-programs/forms.styles.scss';
import '@utils/styles/width.css';

export interface IPlanFormsProps {
  isLoading: boolean;
  forms: [];
  checkedForms: number[];
  areAllChecked: boolean;
  modalQuestionsInfo: any;
  modalRegulationsInfo: any;
  classes: Classes;
  setModalQuestionsInfo: Dispatch<any>;
  setModalRegulationsInfo: Dispatch<any>;
  handleCheckedOptions: (checkedValues: any) => void;
  handleCheckAll: (handleCheckAll: boolean) => void;
  renderQuestionsModal: (title: string, id: string) => void;
  renderRegulationsModal: (question: QuestionType) => void;
}

const PlanForms = (props: IPlanFormsProps) => {
  const {
    forms,
    checkedForms,
    areAllChecked,
    isLoading,
    modalQuestionsInfo,
    modalRegulationsInfo,
  } = props;
  const {
    setModalQuestionsInfo,
    setModalRegulationsInfo,
    handleCheckedOptions,
    handleCheckAll,
    renderQuestionsModal,
    renderRegulationsModal,
  } = props;

  const { checkboxStyles } = props.classes;

  const __renderForms = (): JSX.Element[] => {
    return forms.map(({ name, id }: FormType) => (
      <PlanFormComponent key={id} id={id} name={name} hasCheckbox onClick={renderQuestionsModal} />
    ));
  };

  return (
    <div className="forms-div md-w-100">
      <ModalQuestionsComponent
        title={modalQuestionsInfo.title}
        questions={modalQuestionsInfo.questions}
        isVisible={modalQuestionsInfo.isVisible}
        renderCallback={renderRegulationsModal}
        onOk={() => setModalQuestionsInfo({ ...modalQuestionsInfo, isVisible: false })}
        onCancel={() => setModalQuestionsInfo({ ...modalQuestionsInfo, isVisible: false })}
        footer={null}
      />
      <ModalRegulationsComponent
        regulations={modalRegulationsInfo.regulations}
        isVisible={modalRegulationsInfo.isVisible}
        footer={null}
        onClick={() => {
          setModalRegulationsInfo({ ...modalRegulationsInfo, isVisible: false });
          setModalQuestionsInfo({ ...modalQuestionsInfo, isVisible: true });
        }}
        onOk={() => setModalRegulationsInfo({ ...modalRegulationsInfo, isVisible: false })}
        onCancel={() => setModalRegulationsInfo({ ...modalRegulationsInfo, isVisible: false })}
      />
      <div className="d-flex align-items-center justify-between">
        <h1 style={{ fontWeight: 'bold' }}>
          <FormattedMessage id="wizard.safetyProgram.forms" />
        </h1>
        {!isLoading && (
          <Checkbox
            className={`${checkboxStyles} form__item-title`}
            checked={areAllChecked}
            onClick={() => {
              handleCheckAll(!areAllChecked);
            }}
          >
            Check All
          </Checkbox>
        )}
      </div>
      <Checkbox.Group
        className="forms__checkgroup-container"
        onChange={handleCheckedOptions}
        value={checkedForms}
        style={{ maxHeight: 250, overflowY: 'auto', overflowX: 'hidden', width: '100%' }}
      >
        {isLoading ? (
          <div className="form__spinner">
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 44, color: '#006435' }} spin />}
            />
          </div>
        ) : (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 } as any}>{__renderForms()}</Row>
        )}
      </Checkbox.Group>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  checkboxStyles: {
    fontSize: 16,
    color: theme.grey.fourth,
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: theme.green.fourth,
    },
  },
});

export const PlanFormsComponent = injectSheet(styles)(PlanForms);
