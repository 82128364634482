import { IAdminState } from '@app/admin/admin.reducer';
import { UserType, UserCertificateType } from '@shared-types/backend.types';
import { AnyAction } from 'redux';
import { Sorter, ErrorMessage } from '@shared-types/utils.types';

import {
  fetchAccountUsersAction,
  fetchUserCertificatesAction,
  removeAccountUserAction,
  toggleAccountsTeamSorterAction,
  updateAccountUserAction,
  mailCertificateUserAction,
  fetchUserAction,
  fetchProxyUsersAction,
  createProxyUserAction,
  updateProxyUserAction,
  blockProxyUserAction,
} from './team.actions';

import { createRoutineReducer } from '@shared/helpers/redux-saga-endpoints';

import update from 'immutability-helper';
import { clearErrorMessages } from '@app/back-office/team/state/team.actions';

export interface IAccountTeamState {
  items: {
    [key: string]: UserType;
  };
  isLoading: boolean;

  actionSelectUser?: UserType;
  proxyUsers: UserType[];

  certificates: {
    items?: UserCertificateType[];
    isLoading: boolean;
    isCertificateMailing: boolean;
  };

  errorMessages?: {
    [key: string]: ErrorMessage;
  };

  sorter: Sorter;
}

export const ACCOUNTS_TEAM_INITIAL_STATE: IAccountTeamState = {
  ...createRoutineReducer(),

  certificates: {
    items: undefined,
    isLoading: false,
    isCertificateMailing: false,
  },
  actionSelectUser: undefined,
  proxyUsers: [],
  sorter: {
    key: 'name',
    order: 'asc',
  },
};

export const accountsTeamReducer = {
  [fetchAccountUsersAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        team: {
          isLoading: { $set: true },
        },
      },
    }),
  [fetchAccountUsersAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          items: { $set: payload.team },
          isLoading: { $set: false },
        },
      },
    });
  },

  [fetchUserCertificatesAction.REQUEST]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        team: {
          certificates: {
            isLoading: { $set: true },
          },
        },
      },
    });
  },

  [fetchUserCertificatesAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          certificates: {
            items: { $set: payload.certificates },
            isLoading: { $set: false },
          },
        },
      },
    });
  },

  [fetchUserCertificatesAction.FULFILL]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        team: {
          certificates: {
            items: { $set: {} },
            isLoading: { $set: false },
          },
        },
      },
    });
  },

  [fetchUserCertificatesAction.FAILURE]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        team: {
          certificates: {
            items: { $set: {} },
            isLoading: { $set: false },
          },
        },
      },
    });
  },

  [updateAccountUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const { users } = payload;

    return update(state, {
      accounts: {
        team: {
          items: { $set: users },
        },
      },
    });
  },

  [removeAccountUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    let updatedTeam = { ...state.accounts.team.items };
    delete updatedTeam[payload.userID];

    return update(state, {
      accounts: {
        team: {
          items: { $set: updatedTeam },
        },
      },
    });
  },
  [toggleAccountsTeamSorterAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        team: {
          sorter: { $set: payload },
        },
      },
    }),
  [mailCertificateUserAction.REQUEST]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        team: {
          certificates: {
            isCertificateMailing: { $set: true },
          },
        },
      },
    }),

  [mailCertificateUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        team: {
          certificates: {
            isCertificateMailing: { $set: false },
          },
        },
      },
    }),

  [fetchUserAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        team: {
          isLoading: { $set: true },
        },
      },
    }),

  [fetchUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          actionSelectUser: { $set: payload },
          isLoading: { $set: false },
        },
      },
    });
  },

  [fetchUserAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          errorMessages: { $set: payload },
          isLoading: { $set: false },
        },
      },
    });
  },

  [fetchProxyUsersAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        team: {
          isLoading: { $set: true },
        },
      },
    }),

  [fetchProxyUsersAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          proxyUsers: { $set: payload },
          isLoading: { $set: false },
        },
      },
    });
  },

  [fetchProxyUsersAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          isLoading: { $set: false },
          errorMessages: { $set: payload },
        },
      },
    });
  },

  [createProxyUserAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        team: {
          isLoading: { $set: true },
        },
      },
    }),

  [createProxyUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          proxyUsers: { $set: [...state.accounts.team.proxyUsers, payload] },
          isLoading: { $set: false },
        },
      },
    });
  },
  [createProxyUserAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          errorMessages: { $set: payload },
          isLoading: { $set: false },
        },
      },
    });
  },

  [updateProxyUserAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        team: {
          isLoading: { $set: true },
        },
      },
    }),

  [updateProxyUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    const proxyUsers = state.accounts.team.proxyUsers;
    const index = proxyUsers.findIndex(user => user.user_id === payload.user_id);
    proxyUsers[index] = payload;

    return update(state, {
      accounts: {
        team: {
          proxyUsers: { $set: proxyUsers },
          isLoading: { $set: false },
        },
      },
    });
  },
  [updateProxyUserAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          errorMessages: { $set: payload },
          isLoading: { $set: false },
        },
      },
    });
  },
  [blockProxyUserAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      accounts: {
        team: {
          isLoading: { $set: true },
        },
      },
    }),

  [blockProxyUserAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          proxyUsers: {
            $set: state.accounts.team.proxyUsers.filter(user => user.user_id !== payload),
          },
          isLoading: { $set: false },
        },
      },
    });
  },
  [blockProxyUserAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        team: {
          errorMessages: { $set: payload },
          isLoading: { $set: false },
        },
      },
    });
  },

  [clearErrorMessages.TRIGGER]: (state: IAdminState) => {
    return update(state, {
      accounts: {
        team: {
          errorMessages: { $set: undefined },
        },
      },
    });
  },
};
