import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';
import { SUBMIT_INVITE_API } from '@constants/endpoints';
import { AnyAction } from 'redux';
import { call, takeLatest, put } from 'redux-saga/effects';
import { addService } from '@rdx/services.saga';
import { getList } from '@shared/helpers/state-caster';

export const ADMIN_SUBMIT_TEAM = 'ADMIN_SUBMIT_TEAM';

export const submitInviteTeamAction = createActionRoutine(ADMIN_SUBMIT_TEAM, SUBMIT_INVITE_API);

function* submitInviteTeamRequest(action: AnyAction) {
  const { team, accountID } = action.payload;
 
  let teamList = getList(team);
 
  try {
    const endpoint = submitInviteTeamAction.getEndpoint({ accountID });
  
    const response = yield call(addService, endpoint, teamList);

    const succededUsers = response.filter((user: any) => user.succeeded);

    if (teamList.length === succededUsers.length) {
      yield put(submitInviteTeamAction.success());
    } else {
      yield put(
        submitInviteTeamAction.failure({
          error: {
            code: 'submitInviteTeam',
            title: 'Error submitting invite',
            message: 'It looks like some users have already been invited.'
          }
        }),
      );
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        submitInviteTeamAction.failure({
          title: 'there was an error while requesting add account team',
        }),
      );
    }
  }
}

export function* adminInviteTeamSaga() {
  yield takeLatest(submitInviteTeamAction.REQUEST, submitInviteTeamRequest);
}
