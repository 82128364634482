import { takeLatest, call, put, fork, all } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { fetchService, addService, updateService, patchService } from '@rdx/services.saga';

import { contractorsSaga } from '../contractors/state/contractors.saga';

import { message } from 'antd';

import {
  fetchAccountsAction,
  createCompanyAction,
  updateCompanyAccountAction,
  patchAccountIsActivatedAction,
  fetchSeatsInfoAction,
  createPackageAction,
  updatePackageAction,
} from './accounts.actions';

import { fetchAccountUsersAction } from '../team/state/team.actions';
import { createTeamTearing } from '@shared/utils/add-team-tiering';

import { fecthUsersConfigAction, updateUsersConfigAction } from './accounts.actions';

import { accountsTeamSaga } from '../team/state/team.saga';
import { accountsSafetyPlansSaga } from '../safety-plans/state/safety-plans.saga';

import { arrayToObject } from '@shared/helpers/state-caster';

export function* fetchAccountsRequest(action: AnyAction) {
  const endpoint = fetchAccountsAction.getEndpoint();
  const seatsendpoint = fetchSeatsInfoAction.getEndpoint();

  try {
    const response: any = yield call(fetchService, endpoint);

    if (response.length > 0) {
      // Casting object to dictionary
      const castedObject = arrayToObject(response, 'accountID');
      const accountID = response[0].accountID;

      yield put(fetchAccountsAction.success({ accounts: castedObject, accountID }));
    } else {
      yield put(fetchAccountsAction.success({ accounts: {}, accountID: undefined }));
    }


  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fetchAccountsAction.failure({ title: 'Error while retrieving accounts.' }));
    }
  }
}

export function* createCompanyRequest(action: AnyAction) {
  const { formValues } = action.payload;

  const endpoint = createCompanyAction.getEndpoint();

  try {
    const response = yield call(addService, endpoint, formValues);

    yield put(createCompanyAction.success({ account: response }));
  } catch (error) {
    yield put(createCompanyAction.failure({ title: 'Error when creating company' }));
  }
}

export function* updateCompanyAccountRequest(action: AnyAction) {
  const { formValues, accountID } = action.payload;

  const endpoint = updateCompanyAccountAction.getEndpoint({});

  try {
    const response = yield call(updateService, endpoint, { ...formValues, accountID });
    yield put(updateCompanyAccountAction.success({ updatedAccount: response }));
  } catch (error) {
    yield put(
      updateCompanyAccountAction.failure({ title: 'Error when updating company information' }),
    );
  }
}

export function* patchAccountIsActivatedRequest(action: AnyAction) {
  const { isActive, accountID, powerBIConfig } = action.payload;
  const endpoint = patchAccountIsActivatedAction.getEndpoint({ accountID });

  try {
    let body;

    if (action.payload.hasOwnProperty('isActive'))
      body = { isActive: isActive }
    else
      body = powerBIConfig

    const response = yield call(patchService, endpoint, body);
    yield put(patchAccountIsActivatedAction.success({ updatedAccount: response }));
  } catch (error) {
    yield put(
      patchAccountIsActivatedAction.failure({
        code: 'failure',
        title: 'Error on activate/deactivate account',
        message: 'There has been an error trying to update the account.',
      }),
    );
  }
}

export function* fetchAccountSeatsRequest(action: AnyAction) {

  const seatsendpoint = fetchSeatsInfoAction.getEndpoint();
  try {

    const responseSeatsInfo: any = yield call(
      fetchService,
      seatsendpoint.replace(':accountID', action.payload),
    );

    if (responseSeatsInfo)
      yield put(fetchSeatsInfoAction.success({ selectedAccountID: action.payload, seatsInfo: responseSeatsInfo.details }));
    else
      yield put(fetchSeatsInfoAction.success({ selectedAccountID: action.payload, seatsInfo: {} }));


  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fetchSeatsInfoAction.failure({ title: 'Error while retrieving seats summary.' }));
    }
  }
}

export function* createPackageRequest(action: AnyAction) {
  const { formValues, accountID } = action.payload;

  const endpoint = createPackageAction.getEndpoint();

  try {
    const response = yield call(addService, endpoint, { ...formValues, accountID });

    yield put(createPackageAction.success({ account: response }));
  } catch (error) {
    yield put(createPackageAction.failure({ title: 'Error when adding package' }));
  }
}

export function* updatePackageRequest(action: AnyAction) {
  const { formValues, accountID } = action.payload;

  const endpoint = updatePackageAction.getEndpoint({});

  try {
    const response = yield call(updateService, endpoint, { ...formValues, accountID });
    yield put(updatePackageAction.success({ updatedAccount: response }));
  } catch (error) {
    yield put(
      updatePackageAction.failure({ title: 'Error when updating package information' }),
    );
  }
}

export function* fetchUsersConfigRequest(action: AnyAction) {
  const endpoint = fecthUsersConfigAction.getEndpoint({ id: action.payload });

  try {
    let response = yield call(fetchService, endpoint);

    yield put(fecthUsersConfigAction.success(response));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(fecthUsersConfigAction.failure({ title: 'Error while retrieving users config.' }));
    }
  }
}

export function* updateUsersConfigRequest(action: AnyAction) {
  const { userConfigID, userConfigData, accoundID } = action.payload;

  const fecthTeamEndpoint = fetchAccountUsersAction.getEndpoint({ accountID: accoundID })
  const endpoint = updateUsersConfigAction.getEndpoint({ id: userConfigID });

  try {
    yield call(updateService, endpoint, userConfigData);
    message.success("PowerBI Key Updated");
    let response = yield call(fetchService, fecthTeamEndpoint);
    createTeamTearing(response, null, 1)
    const castedObject = arrayToObject(response, 'user_id');
    yield put(updateUsersConfigAction.success(castedObject));
  } catch (error) {
    if (!error.wasCancelled)
      yield put(updateUsersConfigAction.failure({ title: 'Error when Updateing PowerBI Key' }));
    message.error("Error while updading PowerBI Key");
  }
}

export function* accountsSaga() {
  yield takeLatest(fetchAccountsAction.REQUEST, fetchAccountsRequest);
  yield takeLatest(updateUsersConfigAction.REQUEST, updateUsersConfigRequest);
  yield takeLatest(fecthUsersConfigAction.REQUEST, fetchUsersConfigRequest);
  yield takeLatest(fetchSeatsInfoAction.REQUEST, fetchAccountSeatsRequest)
  yield takeLatest(createCompanyAction.REQUEST, createCompanyRequest);
  yield takeLatest(updateCompanyAccountAction.REQUEST, updateCompanyAccountRequest);
  yield takeLatest(patchAccountIsActivatedAction.REQUEST, patchAccountIsActivatedRequest);
  yield takeLatest(createPackageAction.REQUEST, createPackageRequest);
  yield takeLatest(updatePackageAction.REQUEST, updatePackageRequest);
  yield all([fork(accountsTeamSaga), fork(accountsSafetyPlansSaga), fork(contractorsSaga)]);
}
