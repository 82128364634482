import React, { FormEvent } from 'react'

import { Form, Button, Modal, Select } from 'antd';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { AccountType } from '@shared/types/backend.types';


const { Option, OptGroup } = Select;


export interface IReportsPlanComponentProps {
    companyInformation?: AccountType;
    form: WrappedFormUtils;
    modalState: boolean;
    setState: any;
    handleSaveReportConfig?: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
}

const ReportsPlanComponent = (props: IReportsPlanComponentProps) => {

    const { form, modalState, setState, companyInformation, handleSaveReportConfig } = props;
    const { getFieldDecorator } = form

    return (
        <Modal
            visible={modalState}
            title="Power BI Reports Plan"
            onCancel={() => setState(false)}
            footer={null}
        >

            <Form
                onSubmit={(event: FormEvent<HTMLFormElement>) => {
                    form.validateFields((err, values) => {
                        if (err) {
                            return;
                        }
                    })
                    handleSaveReportConfig && handleSaveReportConfig(event, form);
                    setState(false);
                }}
            >
                <Form.Item
                >
                    {getFieldDecorator('isPowerBIReportFree', { initialValue: companyInformation && companyInformation.isPowerBIReportFree ? 1 : 0 })
                        (
                            <Select
                                className={'input--medium-height'}
                            >
                                <OptGroup label="Select Plan Type">
                                    <Option value={1}>Free</Option>
                                    <Option value={0}>Paid</Option>
                                </OptGroup>
                            </Select>
                        )}
                </Form.Item>


                <div className='ant-modal-body__form-footer'>
                    <Button
                        htmlType="submit"
                        type='primary'
                    >
                        SAVE
                    </Button>

                    <Button type='default' onClick={() => {
                        setState(false)
                    }}> Cancel </Button>
                </div>
            </Form>

        </Modal>
    )
}

export const ReportsPlanModal: any = Form.create({ name: 'reportsplan' })(ReportsPlanComponent);
