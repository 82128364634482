import React, { useCallback } from 'react';

import { AccountType, UsersConfigType, UserType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';

import { Select, Form, Empty, Icon } from 'antd';

import './sub.reports.styles.scss';

export interface ISubmissionReportComponentProps {
  user?: UserType;
  company: AccountType;
  loggedUserConfig?: UsersConfigType;
  underUsers: UserType[];
  selectedUser: UserType | undefined;
  setSelectedUser: any;
  form: WrappedFormUtils;
}

const { Option } = Select;

const SubmissionsReports = (props: ISubmissionReportComponentProps) => {
  const { user, loggedUserConfig, underUsers, selectedUser, company, form, setSelectedUser } = props;
  const { getFieldDecorator } = form

  const reportKey = useCallback(
    () => {
      if (!loggedUserConfig || !company)
        return '';

      if (company.isPowerBIReportFree === false)
        return `reportEmbed?reportId=${company.powerBIKey}&$filter=Submissions/tier${selectedUser ? selectedUser.tier : ''} eq '${selectedUser ? selectedUser.user_id : ''}'`

      else
        return `view?r=${selectedUser && selectedUser.userConfig.powerBIKey}`
    }
    , [company, selectedUser]
  )

  return (

    <div className="reports__container">
      <div className='reports__choose-user'>
        <Form.Item
        >
          {
            getFieldDecorator('Choose User', { initialValue: user ? user.user_id : '' })
              (
                <Select
                  placeholder="Choose User"
                  onSelect={(value: any) => { setSelectedUser(underUsers.filter((user: UserType) => user.user_id === value)[0]) }}
                >
                  {underUsers.map((user: UserType) => (
                    user.app_metadata.role.roleID === 2 ? <Option key={user.user_id} className={user.blocked ? 'inactive' : ''} value={user.user_id} >{user.name} {`(Tier - ${user.tier}) ${user.blocked ? '(Inactive)' : ''}`}</Option> : null
                  ))}
                </Select>
              )
          }
        </Form.Item>
      </div>

      {
        company && company.isPowerBIReportFree ?
          (
            !selectedUser || selectedUser && selectedUser.userConfig.powerBIKey === '' ?
              <Empty image={<Icon type="info-circle" />} description="Please setup associtate powerbi report key to access reports" />
              : <iframe
                title="Reports"
                className="reports__power-bi"
                src={`https://app.powerbi.com/${reportKey()}`}
              >
              </iframe>
          ) : (
            !company || company && !company.isPowerBIReportFree && company.powerBIKey === '' ? (
              <Empty image={<Icon type="info-circle" />} description="please setup associtate powerbi report key to access reports" />
            )
              : (
                <iframe
                  title="Reports"
                  className="reports__power-bi"
                  src={`https://app.powerbi.com/${reportKey()}`}
                >
                </iframe>
              )
          )
      }

    </div>
  );
};


const SubmissionsReportsComponent: any = Form.create({ name: 'BackOfficeReportsComponent' })(SubmissionsReports);

export default SubmissionsReportsComponent;