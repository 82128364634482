import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormType, QuestionType, SafetyPlanType } from '@shared/types/backend.types';

import './safety-programs.styles.scss';
import '@utils/styles/margin.scss';
import '@utils/styles/utility.scss';
import '@utils/styles/padding.scss';
import '@utils/styles/width.css';

import { Button, Checkbox, Divider, Icon, Input, Popconfirm, Switch, Tooltip } from 'antd';

import { Collapse } from 'antd';
import {
  fetchFormQuestions,
  fetchMultiQuestions,
  fetchSafetyForms,
  fetchSafetySubforms,
} from '../state/safety-programs.actions';
import SACollapse from '@components/ui/accordian/accordian';
import SAEmpty from '@components/ui/accordian/empty';
import SASpin from '@components/ui/accordian/spin';
import { fetchQuestionsAction } from '@app/back-office/professional.action';
import { QUESTION_TYPE } from '@constants/question';
import SADialog from '@components/ui/accordian/dialog';
import { ModalComponent } from '@components/layout/modal-component/modal-component';
import AddPlanDialog from './add-plans.component';
import AddFormDialog from './add-forms.component';
import { AddQuestionIcon } from '@assets/icons/icons.svg';
import AddQuestionDialog from '../question/view/question.component';

/*
CODE SUMMARY - first we iterate each safety-program using map function then for each safety-program there are safety-forms onClick of each safety-programs we fetch its respective safety-forms from backend and display them. then after for each for there may or may not exist safety-sub-forms. onClick of each form we fetch its respective safety-sub-forms from backend and display them.

CODE ARCHETECTURE - suppose for safety-programs, first we check the isLoading and its respective is true or not if its true that means data is loading form backend. then isLoading is false it means data is fetched from backend not we need to check wheather the fetched safety-programs are empty or not if fetched data is empty the show empty component otherwise show data according to the needs 
 */

interface IAdminSafetyProgramsComponentProps {
  programs: SafetyPlanType[];
  forms: FormType[];
  subForms: FormType[];
  selectedForm: FormType | undefined;
  questions: QuestionType[];
  multiQuestions: any[];
  reqState: any;
  newFormName: string | undefined;
  toEditForm: FormType | undefined;
  selectedQuestion: QuestionType | undefined;
  fetchForms: (planID: number) => void;
  fetchSubForms: (formID: string) => void;
  handleSafetyProgramsDrag: (e: React.DragEvent<HTMLElement>, targetIndex: any) => void;
  handleSafetyFormsDrag: (
    e: React.DragEvent<HTMLElement>,
    planID: number,
    targetIndex: any,
  ) => void;
  handleSafetySubFormsDrag: (
    e: React.DragEvent<HTMLElement>,
    planID: number,
    targetIndex: any,
  ) => void;
  handleQuestionsDrag: (e: React.DragEvent<HTMLElement>, formID: string, targetIndex: any) => void;
  handleMultiQuestionsDrag: (e: React.DragEvent<HTMLElement>, targetIndex: any) => void;
  handleMQQuestionsDrag: (
    e: React.DragEvent<HTMLElement>,
    formID: string,
    targetIndex: any,
  ) => void;
  handleTogglePrograms: (ref: React.MutableRefObject<null>, toNotToggleItemIndex: number) => void;
  handleToggleForms: (
    ref: React.MutableRefObject<null>,
    programIndex: number,
    toNotToggleItemIndex: number,
  ) => void;
  setNewFormName: (value: React.SetStateAction<string | undefined>) => void;
  setToEditForm: (value: React.SetStateAction<FormType | undefined>) => void;
  setSelectedForm: (value: React.SetStateAction<FormType | undefined>) => void;
  setSelectedQuestion: (value: React.SetStateAction<QuestionType | undefined>) => void;
  onSubmitCreateSafetyPlan: (values: any) => void;
  onSubmitAddSafetyForm: (values: any) => void;
  handleUpdateSafetyForm: (parmas: any, values: any) => void;
  onSubmitAddQuestionForm: (values: any) => void;
  handleDeleteQuestion: (
    parentID: string,
    formID: string,
    questionID: any,
    isMultiQuestion?: boolean,
  ) => void;
  handleDeleteSafetyForm: (formID: string) => void;
  handleDeleteSafetyProgram: (planID: number) => void;
  setToAddFormID: (value: React.SetStateAction<string | undefined>) => void;
}

const SafetyProgramsComponent = (props: IAdminSafetyProgramsComponentProps) => {
  const {
    programs,
    forms,
    subForms,
    questions,
    multiQuestions,
    newFormName,
    toEditForm,
    selectedForm,
    reqState,
    selectedQuestion,
    fetchForms,
    fetchSubForms,
    handleSafetyProgramsDrag,
    handleSafetyFormsDrag,
    handleSafetySubFormsDrag,
    handleQuestionsDrag,
    handleMQQuestionsDrag,
    handleMultiQuestionsDrag,
    handleTogglePrograms,
    handleToggleForms,
    setNewFormName,
    setToEditForm,
    setSelectedForm,
    setSelectedQuestion,
    onSubmitCreateSafetyPlan,
    onSubmitAddSafetyForm,
    handleUpdateSafetyForm,
    onSubmitAddQuestionForm,
    handleDeleteQuestion,
    handleDeleteSafetyForm,
    handleDeleteSafetyProgram,
    setToAddFormID,
  } = props;

  const ref = useRef(null);
  const { isLoading, type } = reqState;

  const [openAddPlanDialog, setAddPlanDialog] = useState<boolean>(false);
  const [openAddFormDialog, setAddFormDialog] = useState<boolean>(false);
  const [openAddQuestionDialog, setAddQuestionDialog] = useState<boolean>(false);

  const handleProgramsClick = (
    ref: React.MutableRefObject<null>,
    programIndex: number,
    safetyPlanID: number,
  ) => {
    // @ts-ignore
    if (!ref.current.childNodes[programIndex].open) {
      handleTogglePrograms(ref, programIndex);
      fetchForms(safetyPlanID);
    }
  };

  const handleFormsClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    ref: React.MutableRefObject<null>,
    programIndex: number,
    formIndex: number,
    formID: string,
  ) => {
    e.stopPropagation();
    // @ts-ignore
    if (!ref.current.childNodes[programIndex].childNodes[1].childNodes[formIndex].open) {
      handleToggleForms(ref, programIndex, formIndex);
      fetchSubForms(formID);
    }
  };

  return (
    <>
      <div className="safety-programs">
        <div className="safety-programs__collapse-container">
          <div className="safety-programs__collapse-container_header d-flex justify-between align-items-center">
            <h4 style={{ marginBottom: 0, fontWeight: 600, fontSize: 20 }}>Safety Programs</h4>

            <Button.Group>
              <Tooltip title="Create safety program">
                <Button
                  type="primary"
                  size="default"
                  onClick={() => setAddPlanDialog(true)}
                  icon="check-square"
                />
              </Tooltip>

              <Tooltip title="Add safety form">
                <Button
                  type="primary"
                  size="default"
                  onClick={() => setAddFormDialog(true)}
                  icon="profile"
                />
              </Tooltip>
            </Button.Group>
          </div>

          <div ref={ref} className="safety-programs__collapse-container_content">
            {programs.length ? (
              programs.map((program: SafetyPlanType, programIndex: number) => (
                <SACollapse
                  name={program.name}
                  key={program.safetyPlanID}
                  extra={
                    <Popconfirm
                      title="Are you sure delete this plan?"
                      onConfirm={() => {
                        handleDeleteSafetyProgram(program.safetyPlanID);
                      }}
                      onCancel={e => e.stopPropagation()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon
                        onClick={e => e.stopPropagation()}
                        className="danger-icon"
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        type="delete"
                      />
                    </Popconfirm>
                  }
                  onClick={() => handleProgramsClick(ref, programIndex, program.safetyPlanID)}
                  draggable={true}
                  onDragStart={e =>
                    e.dataTransfer.setData('planID', program.safetyPlanID.toString())
                  }
                  onDragOver={e => e.preventDefault()}
                  onDrop={e => {
                    handleSafetyProgramsDrag(e, programIndex + 1);
                  }}
                >
                  {isLoading && !!(type === fetchSafetyForms.REQUEST) ? (
                    <SASpin />
                  ) : forms.length ? (
                    forms[0].safetyPlanID === program.safetyPlanID &&
                    forms.map((form: FormType, formIndex: number) =>
                      form.hasSubForms ? (
                        <SACollapse
                          name={
                            <>
                              {toEditForm && toEditForm.id === form.id ? (
                                <input
                                  onChange={({ target: { value } }) => setNewFormName(value)}
                                  defaultValue={form.name}
                                />
                              ) : (
                                form.name
                              )}
                            </>
                          }
                          style={{ borderLeft: 'none', borderRight: 'none' }}
                          key={form.id}
                          headerStyles={{ backgroundColor: '#fff' }}
                          onClick={e => {
                            setSelectedForm(form);
                            handleFormsClick(e, ref, programIndex, formIndex, form.id);
                          }}
                          extra={
                            <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                              {form.isMultiQuestionEnable && (
                                <Tooltip title="Multiquestion Form">
                                  <span style={{ color: '#22c55e', display: 'flex' }}>
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 448 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z"></path>
                                    </svg>
                                  </span>
                                </Tooltip>
                              )}

                              {toEditForm && toEditForm.id === form.id ? (
                                <Icon
                                  onClick={e => {
                                    e.preventDefault();
                                    handleUpdateSafetyForm(
                                      {
                                        planID: form.safetyPlanID,
                                        formID: form.id,
                                        isParent: true,
                                      },
                                      { name: newFormName },
                                    );
                                    setToEditForm(undefined);
                                  }}
                                  type="save"
                                  theme="filled"
                                />
                              ) : (
                                <Icon
                                  className="edit-icon"
                                  onClick={e => {
                                    e.preventDefault();
                                    setToEditForm(form);
                                  }}
                                  type="edit"
                                />
                              )}

                              <Popconfirm
                                title="Are you sure delete this safety form?"
                                onConfirm={() => {
                                  handleDeleteSafetyForm(form.id);
                                }}
                                onCancel={e => e.stopPropagation()}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Tooltip title="Delete">
                                  <Icon
                                    onClick={e => e.stopPropagation()}
                                    className="danger-icon"
                                    style={{ cursor: 'pointer' }}
                                    type="delete"
                                  />
                                </Tooltip>
                              </Popconfirm>
                            </div>
                          }
                          draggable={true}
                          onDragStart={e => e.dataTransfer.setData('formID', form.id.toString())}
                          onDragOver={e => e.preventDefault()}
                          onDrop={e => (
                            e.stopPropagation(),
                            handleSafetyFormsDrag(e, program.safetyPlanID, formIndex + 1)
                          )}
                        >
                          {isLoading && !!(type === fetchSafetySubforms.REQUEST) ? (
                            <SASpin />
                          ) : subForms.length && subForms[0].parentID === form.id ? (
                            subForms.map((subForm: FormType, subFormIndex: number) => (
                              <div
                                key={subForm.id}
                                className="form"
                                onClick={e => (e.stopPropagation(), setSelectedForm(subForm))}
                                draggable={true}
                                onDragStart={e =>
                                  e.dataTransfer.setData('subFormID', subForm.id.toString())
                                }
                                onDragOver={e => (e.preventDefault(), e.stopPropagation())}
                                onDrop={e => (
                                  e.stopPropagation(),
                                  handleSafetySubFormsDrag(
                                    e,
                                    program.safetyPlanID,
                                    subFormIndex + 1,
                                  )
                                )}
                              >
                                {
                                  <>
                                    {toEditForm && toEditForm.id === subForm.id ? (
                                      <input
                                        onChange={({ target: { value } }) => setNewFormName(value)}
                                        defaultValue={subForm.name}
                                      />
                                    ) : (
                                      <span>{subForm.name}</span>
                                    )}

                                    <div
                                      className="d-flex align-items-center"
                                      style={{ gap: '1rem' }}
                                    >
                                      {toEditForm && toEditForm.id === subForm.id ? (
                                        <Icon
                                          onClick={e => {
                                            e.preventDefault();
                                            handleUpdateSafetyForm(
                                              {
                                                planID: subForm.safetyPlanID,
                                                formID: subForm.id,
                                                isParent: false,
                                              },
                                              { name: newFormName },
                                            );
                                            setToEditForm(undefined);
                                          }}
                                          type="save"
                                          theme="filled"
                                        />
                                      ) : (
                                        <Icon
                                          className="edit-icon"
                                          onClick={e => {
                                            e.preventDefault();
                                            setToEditForm(subForm);
                                          }}
                                          type="edit"
                                        />
                                      )}

                                      <Popconfirm
                                        title="Are you sure delete this subform form?"
                                        onConfirm={() => {
                                          handleDeleteSafetyForm(subForm.id);
                                        }}
                                        onCancel={e => e.stopPropagation()}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Tooltip title="Delete">
                                          <Icon
                                            onClick={e => e.stopPropagation()}
                                            className="danger-icon"
                                            style={{ cursor: 'pointer' }}
                                            type="delete"
                                          />
                                        </Tooltip>
                                      </Popconfirm>
                                    </div>
                                  </>
                                }
                              </div>
                            ))
                          ) : (
                            <SAEmpty title="No subforms found for the form" />
                          )}
                        </SACollapse>
                      ) : (
                        <div
                          key={form.id}
                          className="form"
                          onClick={() => setSelectedForm(form)}
                          draggable={true}
                          onDragStart={e => e.dataTransfer.setData('formID', form.id.toString())}
                          onDragOver={e => e.preventDefault()}
                          onDrop={e => (
                            e.stopPropagation(),
                            handleSafetyFormsDrag(e, program.safetyPlanID, formIndex + 1)
                          )}
                        >
                          <>
                            {toEditForm && toEditForm.id === form.id ? (
                              <input
                                onChange={({ target: { value } }) => setNewFormName(value)}
                                defaultValue={form.name}
                              />
                            ) : (
                              <span>{form.name}</span>
                            )}
                            <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                              {form.isMultiQuestionEnable && (
                                <Tooltip title="Multiquestion Form">
                                  <span style={{ color: '#22c55e', display: 'flex' }}>
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 448 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z"></path>
                                    </svg>
                                  </span>
                                </Tooltip>
                              )}

                              {toEditForm && toEditForm.id === form.id ? (
                                <Icon
                                  onClick={e => {
                                    e.preventDefault();
                                    handleUpdateSafetyForm(
                                      {
                                        planID: form.safetyPlanID,
                                        formID: form.id,
                                        isParent: true,
                                      },
                                      { name: newFormName },
                                    );
                                    setToEditForm(undefined);
                                  }}
                                  type="save"
                                  theme="filled"
                                />
                              ) : (
                                <Icon
                                  className="edit-icon"
                                  onClick={e => {
                                    e.preventDefault();
                                    setToEditForm(form);
                                  }}
                                  type="edit"
                                />
                              )}

                              <Popconfirm
                                title="Are you sure delete this safety form?"
                                onConfirm={() => {
                                  handleDeleteSafetyForm(form.id);
                                }}
                                onCancel={e => e.stopPropagation()}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Icon
                                  onClick={e => e.stopPropagation()}
                                  className="danger-icon"
                                  style={{ cursor: 'pointer' }}
                                  type="delete"
                                />
                              </Popconfirm>
                            </div>
                          </>
                        </div>
                      ),
                    )
                  ) : (
                    <SAEmpty title="No forms found for the program" />
                  )}
                </SACollapse>
              ))
            ) : (
              <SAEmpty title="No safety programs found" />
            )}
          </div>
        </div>

        <div className="safety-programs__question-container">
          <div className="safety-programs__question-container_header">
            <div
              style={{
                float: 'right',
                margin: selectedForm && !selectedForm.parentID ? '4px 0px' : '1rem 0px',
              }}
            >
              {selectedForm && !selectedForm.parentID && (
                <div className="d-flex align-items-center " style={{ gap: '0.5rem' }}>
                  <div>
                    <b>Multi Questions</b>
                    <Popconfirm
                      title={
                        selectedForm.isMultiQuestionEnable
                          ? 'Are you sure you want to make this form normal form ?'
                          : 'Are you sure you want to make this form multiquestion form?'
                      }
                      onConfirm={() =>
                        handleUpdateSafetyForm(
                          {
                            planID: selectedForm.safetyPlanID,
                            formID: selectedForm.id,
                            isParent: true,
                          },
                          {
                            isMultiQuestionEnable: !selectedForm.isMultiQuestionEnable,
                          },
                        )
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Switch
                        style={{ marginLeft: '10px' }}
                        size="small"
                        checked={selectedForm.isMultiQuestionEnable}
                      />
                    </Popconfirm>
                  </div>

                  {selectedForm.isMultiQuestionEnable ? (
                    <>
                      <Divider type="vertical" />
                      <Tooltip title="Add new question">
                        <Button
                          size="small"
                          style={{
                            fontSize: 18,
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0px 10px',
                          }}
                          onClick={() => setAddQuestionDialog(true)}
                        >
                          <AddQuestionIcon />
                        </Button>
                      </Tooltip>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          <div className="safety-programs__question-container_body">
            <div className="safety-programs__question-container_body-scollable">
              {!!selectedForm ? (
                selectedForm.isMultiQuestionEnable ? (
                  isLoading && type === fetchMultiQuestions.REQUEST ? (
                    <SASpin />
                  ) : (
                    <div className="questions-container">
                      {multiQuestions.length ? (
                        multiQuestions
                          .sort((a: any, b: any) => a.mqSortOrder - b.mqSortOrder)
                          .map((mq: any, mqIndex: number) => {
                            if (mq.type === 'question') {
                              return (
                                <SACollapse
                                  name={mq.description.slice(0, 20)}
                                  draggable={true}
                                  onDragStart={e =>
                                    e.dataTransfer.setData(
                                      'mqDragData',
                                      JSON.stringify({
                                        id: mq.id,
                                        parentID: selectedForm.id,
                                        idType: 'quesID',
                                        sourceIndex: mqIndex + 1,
                                      }),
                                    )
                                  }
                                  onDragOver={e => e.preventDefault()}
                                  onDrop={e => (
                                    e.stopPropagation(), handleMultiQuestionsDrag(e, mqIndex + 1)
                                  )}
                                >
                                  <table
                                    className="sa-table"
                                    style={{
                                      marginTop: '0px',
                                      borderLeft: 'none',
                                      borderRight: 'none',
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th style={{ borderLeft: 'none' }}>Description</th>
                                        <th>Type</th>
                                        <th style={{ borderRight: 'none' }}></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr>
                                        <td style={{ borderLeft: 'none' }}>{mq.description}</td>
                                        <td>{QUESTION_TYPE[mq.questionTypeID]}</td>
                                        <td>
                                          <Tooltip title="Edit question">
                                            <Icon
                                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                                              className="edit-icon"
                                              onClick={() => (
                                                setSelectedQuestion(mq), setAddQuestionDialog(true)
                                              )}
                                              type="edit"
                                            />
                                          </Tooltip>

                                          <Tooltip title="Delete question">
                                            <Popconfirm
                                              title="Are you sure delete this question?"
                                              onConfirm={() =>
                                                handleDeleteQuestion(
                                                  selectedForm.id,
                                                  selectedForm.id,
                                                  mq.id,
                                                  true,
                                                )
                                              }
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                              <Icon
                                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                className="danger-icon"
                                                type="delete"
                                              />
                                            </Popconfirm>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </SACollapse>
                              );
                            } else {
                              return (
                                <SACollapse
                                  name={mq.name}
                                  draggable={true}
                                  onDragStart={e =>
                                    e.dataTransfer.setData(
                                      'mqDragData',
                                      JSON.stringify({
                                        id: mq.id,
                                        idType: 'SubFormID',
                                        parentID: mq.parentID,
                                        sourceIndex: mqIndex + 1,
                                      }),
                                    )
                                  }
                                  onDragOver={e => e.preventDefault()}
                                  onDrop={e => (
                                    e.stopPropagation(), handleMultiQuestionsDrag(e, mqIndex + 1)
                                  )}
                                >
                                  <table
                                    className="sa-table"
                                    style={{
                                      margin: '0px',
                                      borderLeft: 'none',
                                      borderRight: 'none',
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th style={{ borderLeft: 'none' }}>Description</th>
                                        <th style={{ borderRight: 'none' }}>Type</th>
                                        <th
                                          onClick={() => (
                                            setSelectedQuestion(undefined),
                                            setToAddFormID(mq.id),
                                            setAddQuestionDialog(true)
                                          )}
                                        >
                                          <span>Add</span>
                                          <Tooltip title="Add question">
                                            <Icon
                                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                                              type="plus-square"
                                            />
                                          </Tooltip>
                                        </th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {mq.questions.length ? (
                                        mq.questions.map(
                                          (ques: QuestionType, questionIndex: number) => (
                                            <tr
                                              key={ques.id}
                                              draggable={true}
                                              onDragStart={e =>
                                                e.dataTransfer.setData('quesID', ques.id.toString())
                                              }
                                              onDragOver={e => e.preventDefault()}
                                              onDrop={e => (
                                                e.stopPropagation(),
                                                handleMQQuestionsDrag(e, mq.id, questionIndex + 1)
                                              )}
                                            >
                                              <td style={{ borderLeft: 'none' }}>
                                                {ques.description}
                                              </td>
                                              <td style={{ borderRight: 'none' }}>
                                                {QUESTION_TYPE[ques.questionTypeID]}
                                              </td>
                                              <td>
                                                <Tooltip title="Edit question">
                                                  <Icon
                                                    style={{
                                                      marginLeft: '10px',
                                                      cursor: 'pointer',
                                                    }}
                                                    className="edit-icon"
                                                    onClick={() => (
                                                      setSelectedQuestion(ques),
                                                      setToAddFormID(mq.id),
                                                      setAddQuestionDialog(true)
                                                    )}
                                                    type="edit"
                                                  />
                                                </Tooltip>

                                                <Tooltip title="Delete question">
                                                  <Popconfirm
                                                    title="Are you sure delete this question?"
                                                    onConfirm={() =>
                                                      handleDeleteQuestion(
                                                        mq.parentID,
                                                        mq.id,
                                                        ques.id,
                                                        true,
                                                      )
                                                    }
                                                    okText="Yes"
                                                    cancelText="No"
                                                  >
                                                    <Icon
                                                      style={{
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                      }}
                                                      className="danger-icon"
                                                      type="delete"
                                                    />
                                                  </Popconfirm>
                                                </Tooltip>
                                              </td>
                                            </tr>
                                          ),
                                        )
                                      ) : (
                                        <SAEmpty title="No question found for the subform" />
                                      )}
                                    </tbody>
                                  </table>
                                </SACollapse>
                              );
                            }
                          })
                      ) : (
                        <td colSpan={3}>
                          <SAEmpty title="No questions found for the selected form" />
                        </td>
                      )}
                    </div>
                  )
                ) : isLoading && type === fetchFormQuestions.REQUEST ? (
                  <SASpin />
                ) : (
                  <div className="questions-container">
                    <table className="sa-table mt-0">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Type</th>
                          <th
                            onClick={() => (
                              setSelectedQuestion(undefined), setAddQuestionDialog(true)
                            )}
                          >
                            <span>Add</span>
                            <Tooltip title="Add question">
                              <Icon
                                style={{ marginLeft: '10px', cursor: 'pointer' }}
                                type="plus-square"
                              />
                            </Tooltip>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {questions.length ? (
                          questions.map((question: QuestionType, questionIndex: number) => (
                            <tr
                              key={question.id}
                              draggable={true}
                              onDragStart={e =>
                                e.dataTransfer.setData('quesID', question.id.toString())
                              }
                              onDragOver={e => e.preventDefault()}
                              onDrop={e => {
                                handleQuestionsDrag(e, selectedForm.id, questionIndex + 1);
                              }}
                            >
                              <td>{question.description}</td>
                              <td>{QUESTION_TYPE[question.questionTypeID]}</td>
                              <td>
                                <Tooltip title="Edit question">
                                  <Icon
                                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                                    className="edit-icon"
                                    onClick={() => (
                                      setSelectedQuestion(question), setAddQuestionDialog(true)
                                    )}
                                    type="edit"
                                  />
                                </Tooltip>

                                <Tooltip title="Delete question">
                                  <Popconfirm
                                    title="Are you sure delete this question?"
                                    onConfirm={() =>
                                      handleDeleteQuestion(
                                        selectedForm.id,
                                        selectedForm.id,
                                        question.id,
                                      )
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Icon
                                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                                      className="danger-icon"
                                      type="delete"
                                    />
                                  </Popconfirm>
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3}>
                              <SAEmpty title="No questions found for the selected form" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )
              ) : (
                <SAEmpty title="Currently No form has been selected" />
              )}
            </div>
          </div>
        </div>
      </div>

      <AddPlanDialog
        open={openAddPlanDialog}
        reqState={reqState}
        setShow={setAddPlanDialog}
        onSubmitCreateSafetyPlan={onSubmitCreateSafetyPlan}
      />

      <AddFormDialog
        open={openAddFormDialog}
        plans={programs}
        forms={forms}
        reqState={reqState}
        setShow={setAddFormDialog}
        onSubmitCreateSafetyPlan={onSubmitCreateSafetyPlan}
        onSubmitAddSafetyForm={onSubmitAddSafetyForm}
      />

      <AddQuestionDialog
        open={openAddQuestionDialog}
        reqState={reqState}
        question={selectedQuestion}
        setShow={setAddQuestionDialog}
        onSubmitAddQuestionForm={onSubmitAddQuestionForm}
      />
    </>
  );
};

export default SafetyProgramsComponent;
