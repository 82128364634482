import axios from "axios";
import Cookies from "js-cookie";

export const getBase64 = async (file: Blob): Promise<string | undefined> => {
  var reader = new FileReader();
  reader.readAsDataURL(file as Blob);
  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = (error) => reject(error);
  })
}

export const uploadImageServiceUtil = function (endpoint: any, file: any) {
  //clean base64 string .replace('data:image/png;base64,','')   
  const result = getBase64(file)
    .then(base64Image => {
      let base64Data = (base64Image as string).split(",")[1];

      let imageSubmitBody: any = {
        fileName: file.name,
        base64Data
      };

      const tokenKey: string = `Bearer ${Cookies.get('sessionKey')}`;
      const config = {
        Authorization: tokenKey,
      };
      return axios.post(endpoint, imageSubmitBody, { headers: config }).then();
    }).catch(error => console.error(error));

  return result;
}

