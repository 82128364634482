import React, { useEffect, useState, SetStateAction, FormEvent } from 'react';
import { IAppState } from '@rdx/root.reducer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSessionUser } from '@app/session/session.selector';

import { getAccountID } from '@app/admin/invite/state/invite.selector';

import SafetyProgramsComponent from '../view/safety-programs.component';
import {
  fetchFormQuestions,
  fetchMultiQuestions,
  fetchSafetyForms,
  fetchSafetyPrograms,
  fetchSafetySubforms,
  managePlansSortOrder,
  manageFormsSortOrder,
  addSafetyProgram,
  addSafetyForm,
  addFormQuestion,
  updateFormQuestion,
  removeFormQuestion,
  updateSafetyForm,
  manageQuestionsSortOrder,
  manageMultiQuestionsSortOrder,
  removeSafetyProgram,
  removeSafetyForm,
  manageMQQuestionsSortOrder,
} from './safety-programs.actions';

import Swal from 'sweetalert2';

import { FormType, QuestionType } from '@shared/types/backend.types';

const SafetyProgramsContainer = () => {
  const dispatch = useDispatch();

  const { programs, forms, subForms, questions, multiQuestions, reqState } = useSelector(
    (state: IAppState) => state.admin.safetyPrograms,
  );

  const [toEditForm, setToEditForm] = useState<FormType | undefined>(undefined);
  const [newFormName, setNewFormName] = useState<string | undefined>(undefined);
  const [selectedForm, setSelectedForm] = useState<FormType | undefined>(undefined);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionType | undefined>(undefined);
  const [toAddFormID, setToAddFormID] = useState<string | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchSafetyPrograms.request());
  }, []);

  useEffect(() => {
    if (selectedForm) {
      if (selectedForm.isMultiQuestionEnable)
        dispatch(fetchMultiQuestions.request({ params: { formID: selectedForm.id } }));
      else dispatch(fetchFormQuestions.request({ params: { formID: selectedForm.id } }));
    }
  }, [selectedForm]);

  useEffect(() => {
    if (!reqState.isLoading && reqState.type === removeFormQuestion.SUCCESS) {
      Swal.fire({
        title: 'Done!',
        text: 'The question has been removed from the selected form',
        type: 'success',
        confirmButtonColor: '#0B734F',
      });
    }

    if (reqState.type === updateSafetyForm.SUCCESS && selectedForm) {
      const updatedForm = forms.find((form: FormType) => form.id === selectedForm.id);
      setSelectedForm(updatedForm);
    }
  }, [reqState.type]);

  const fetchForms = (planID: number) => {
    dispatch(fetchSafetyForms.request({ params: { planID } }));
  };

  const fetchSubForms = (formID: string) => {
    dispatch(fetchSafetySubforms.request({ params: { formID } }));
  };

  const onSubmitCreateSafetyPlan = (values: any) => {
    dispatch(addSafetyProgram.request({ body: values }));
  };

  const onSubmitAddSafetyForm = (values: any) => {
    const { planID, parentID, name, isMultiQuestionEnable } = values;
    dispatch(
      addSafetyForm.request({
        params: { planID },
        body: { parentID: parentID === 'newTile' ? null : parentID, name, isMultiQuestionEnable },
      }),
    );
  };

  const handleUpdateSafetyForm = (params: any, values: any) => {
    if (selectedForm)
      dispatch(
        updateSafetyForm.request({
          params,
          body: {
            ...values,
          },
        }),
      );
  };

  const onSubmitAddQuestionForm = (values: any) => {
    if (selectedForm) {
      if (selectedQuestion) {
        dispatch(
          updateFormQuestion.request({
            params: {
              planID: selectedForm.safetyPlanID,
              formID: toAddFormID ? toAddFormID : selectedForm.id,
              questionID: selectedQuestion.id,
              parentID: selectedForm.id,
              isMultiQuestion: selectedForm.isMultiQuestionEnable,
            },
            body: { ...values },
          }),
        );
        setSelectedQuestion(undefined);
        setToAddFormID(undefined);
      } else {
        dispatch(
          addFormQuestion.request({
            params: {
              id: selectedForm.id,
              formID: toAddFormID ? toAddFormID : selectedForm.id,
              isMultiQuestion: selectedForm.isMultiQuestionEnable,
            },
            body: { ...values },
          }),
        );
        setToAddFormID(undefined);
      }
    }
  };

  const handleDeleteQuestion = (
    parentID: string,
    formID: string,
    questionID: any,
    isMultiQuestion?: boolean,
  ) => {
    if (selectedForm)
      dispatch(
        removeFormQuestion.request({
          params: {
            formID,
            questionID,
            parentID,
            isMultiQuestion,
          },
        }),
      );
  };

  const handleTogglePrograms = React.useCallback(
    (ref: React.MutableRefObject<null>, toNotToggleItemIndex: number) => {
      // @ts-ignore
      ref.current.childNodes.forEach((item: any, itemIndex: number) => {
        if (itemIndex !== toNotToggleItemIndex) item.open = false;
      });
    },
    [],
  );

  const handleToggleForms = React.useCallback(
    (ref: React.MutableRefObject<null>, programIndex: number, toNotToggleItemIndex: number) => {
      // @ts-ignore
      ref.current.childNodes[programIndex].childNodes[1].childNodes.forEach(
        (item: any, itemIndex: number) => {
          if (itemIndex !== toNotToggleItemIndex) item.open = false;
        },
      );
    },
    [],
  );

  const handleSafetyProgramsDrag = (e: React.DragEvent<HTMLElement>, targetIndex: any) => {
    const planID = e.dataTransfer.getData('planID');
    dispatch(managePlansSortOrder.request({ params: { planID }, body: { targetIndex } }));
  };

  const handleSafetyFormsDrag = (
    e: React.DragEvent<HTMLElement>,
    planID: number,
    targetIndex: any,
  ) => {
    const formID = e.dataTransfer.getData('formID');
    dispatch(manageFormsSortOrder.request({ params: { formID, planID }, body: { targetIndex } }));
  };

  const handleSafetySubFormsDrag = (
    e: React.DragEvent<HTMLElement>,
    planID: number,
    targetIndex: any,
  ) => {
    const formID = e.dataTransfer.getData('subFormID');
    if (selectedForm)
      dispatch(
        manageFormsSortOrder.request({
          params: { formID, planID, parentID: selectedForm.id },
          body: { targetIndex },
        }),
      );
  };

  const handleQuestionsDrag = (
    e: React.DragEvent<HTMLElement>,
    formID: string,
    targetIndex: any,
  ) => {
    const questionID = e.dataTransfer.getData('quesID');
    if (selectedForm)
      dispatch(
        manageQuestionsSortOrder.request({
          params: { formID },
          body: { questionID, targetIndex },
        }),
      );
  };

  const handleMQQuestionsDrag = (
    e: React.DragEvent<HTMLElement>,
    formID: string,
    targetIndex: any,
  ) => {
    const questionID = e.dataTransfer.getData('quesID');
    if (selectedForm)
      dispatch(
        manageMQQuestionsSortOrder.request({
          params: { formID, parentID: selectedForm.id },
          body: { questionID, targetIndex },
        }),
      );
  };

  const handleMultiQuestionsDrag = (e: React.DragEvent<HTMLElement>, targetIndex: any) => {
    const data: any = e.dataTransfer.getData('mqDragData');
    if (selectedForm)
      dispatch(
        manageMultiQuestionsSortOrder.request({
          params: { formID: selectedForm.id },
          body: { targetIndex, ...JSON.parse(data) },
        }),
      );
  };

  const handleDeleteSafetyProgram = (planID: number) => {
    dispatch(removeSafetyProgram.request({ params: { planID } }));
  };

  const handleDeleteSafetyForm = (formID: string) => {
    dispatch(removeSafetyForm.request({ params: { formID } }));
  };

  return (
    <SafetyProgramsComponent
      programs={programs}
      forms={forms}
      subForms={subForms}
      selectedForm={selectedForm}
      questions={questions}
      selectedQuestion={selectedQuestion}
      multiQuestions={multiQuestions}
      reqState={reqState}
      newFormName={newFormName}
      toEditForm={toEditForm}
      setNewFormName={setNewFormName}
      setToEditForm={setToEditForm}
      setSelectedQuestion={setSelectedQuestion}
      setSelectedForm={setSelectedForm}
      fetchForms={fetchForms}
      fetchSubForms={fetchSubForms}
      handleSafetyProgramsDrag={handleSafetyProgramsDrag}
      handleSafetyFormsDrag={handleSafetyFormsDrag}
      handleSafetySubFormsDrag={handleSafetySubFormsDrag}
      handleQuestionsDrag={handleQuestionsDrag}
      handleMQQuestionsDrag={handleMQQuestionsDrag}
      handleMultiQuestionsDrag={handleMultiQuestionsDrag}
      handleTogglePrograms={handleTogglePrograms}
      handleToggleForms={handleToggleForms}
      onSubmitCreateSafetyPlan={onSubmitCreateSafetyPlan}
      onSubmitAddSafetyForm={onSubmitAddSafetyForm}
      handleUpdateSafetyForm={handleUpdateSafetyForm}
      onSubmitAddQuestionForm={onSubmitAddQuestionForm}
      handleDeleteQuestion={handleDeleteQuestion}
      handleDeleteSafetyProgram={handleDeleteSafetyProgram}
      handleDeleteSafetyForm={handleDeleteSafetyForm}
      setToAddFormID={setToAddFormID}
    />
  );
};

export default SafetyProgramsContainer;
