import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  FETCH_PROJECT_FORMS_API,
  FETCH_ACCOUNTS_PROJECTS_API,
  FETCH_FORM_PDF_API,
} from '@constants/endpoints';

export const FETCH_SP_PROJECTS = 'FETCH_SP_PROJECTS';
export const FETCH_PROJECT_FORMS = 'FETCH_PROJECT_FORMS';
export const FETCH_FORM_PDF = 'FETCH_FORM_PDF';
export const GET_SELECTED_FORM_PDF = 'GET_SELECTED_FORM_PDF';
export const CHANGE_SELECTED_PROJECT = 'CHANGE_SELECTED_PROJECT';

export const fetchProjectFormsAction = createActionRoutine(
  FETCH_PROJECT_FORMS,
  FETCH_PROJECT_FORMS_API,
);

export const fetchSPProjectAction = createActionRoutine(
  FETCH_SP_PROJECTS,
  FETCH_ACCOUNTS_PROJECTS_API,
);

export const changeSelectedProjectAction = createActionRoutine(CHANGE_SELECTED_PROJECT);

export const getSelectedFormPDFAction = createActionRoutine(GET_SELECTED_FORM_PDF);

export const fetchFormPDFAction = createActionRoutine(FETCH_FORM_PDF, FETCH_FORM_PDF_API);
