import React, { useState, FormEvent } from 'react'

import { Form, Input, Button, Modal, Icon } from 'antd';

import { WrappedFormUtils } from 'antd/es/form/Form';

import { handleValidateToNextPassword } from '@shared/utils/password-validation';
import { handleCompareToFirstPassword } from '@shared/utils/password-validation';

import { useIntl } from 'react-intl';
import lockIcon from '@assets/icon/lock-icon.svg';

import './change-password.styles.scss';



export interface IChangePasswordModalProps {
    form: WrappedFormUtils;
    selectedUserId: string;
    changeUserPassword: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils, selectedUserId: string) => void;
}

export const ChangePasswordModal = (props: IChangePasswordModalProps) => {

    const { form, selectedUserId, changeUserPassword } = props;
    const { getFieldDecorator } = form
    const intlProvider = useIntl();
    const [visible, setVisible] = useState(false)


    return (
        <>
            <a className='team-actions-dropdown-content' onClick={() => setVisible(true)}>
                <Icon type="key" />
                <b>Change Password &nbsp;</b>
            </a>

            <Modal
                title="Change Password"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <div className='change-password-form'>
                    <Form
                        onSubmit={(event: FormEvent<HTMLFormElement>) => {
                            form.validateFields((err, values) => {
                                if (err) {
                                    return;
                                }
                            })
                            changeUserPassword(event, form, selectedUserId)
                            setVisible(false)
                        }} >
                        <Form.Item
                            label={intlProvider.messages['inputs.newPassword']}

                        >
                            {getFieldDecorator('password', {
                                rules:
                                    [
                                        {
                                            required: true,
                                            message: intlProvider.messages['inputs.requiredPassword'],
                                        },
                                        {
                                            validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                                                handleValidateToNextPassword(form, rule, value, callback, intlProvider.messages['inputs.invalidPassword'].toString()),
                                        },
                                    ],
                            })(
                                <Input.Password
                                    className="input--medium-height"
                                    placeholder="Password"
                                    required
                                    prefix={<img src={lockIcon} width="15" alt="Password" />}
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            label={intlProvider.messages['inputs.confirmPassword']}

                        >
                            {getFieldDecorator('confirmPassword', {
                                rules:
                                    [
                                        {
                                            required: true,
                                            message: intlProvider.messages['inputs.requiredPassword'],
                                        },
                                        {
                                            validator: (rule: any, value: string, callback: (message?: string) => {}) =>
                                                handleCompareToFirstPassword(form, rule, value, callback, intlProvider.messages['inputs.unmatchingPassword'].toString()),
                                        },
                                    ],
                            })(
                                <Input.Password
                                    className="input--medium-height"
                                    placeholder="Confirm Password"
                                    required
                                    prefix={<img src={lockIcon} width="15" alt="Password" />}
                                />
                            )}
                        </Form.Item>

                        <div className='ant-modal-body__form-footer'>
                            <Button htmlType="submit" type='primary'> Save</Button>
                            <Button type='default' onClick={() => setVisible(false)}> Cancel </Button>
                        </div>

                    </Form>
                </div>

            </Modal>
        </>
    )
}


export const TeamChangePasswordModal: any = Form.create({ name: 'changePasswordModal' })(ChangePasswordModal);
