export const CLIENT_ROLE = 'CLIENT';

export const ROLES = {
  systemAdmin: 'systemAdmin',
  safetyProfessional: 'safetyProfessional',
};

//API V2
export const ROLE: { [index: string]: any } = {
  1: 'User', //mobile user, this role can't log in into web app
  2: 'Administrator',
  3: 'SysAdmin',
};
