import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

export const ADD_SETUP_PROJECT = 'ADD_SETUP_PROJECT';
export const CHANGE_SETUP_PROJECT = 'CHANGE_SETUP_PROJECT';
export const REMOVE_SETUP_PROJECT = 'REMOVE_SETUP_PROJECT';
export const VALIDATE_PROJECTS_STEP = 'VALIDATE_PROJECTS_STEP';

export const addSetupProjectAction = createActionRoutine(ADD_SETUP_PROJECT);
export const changeSetupProjectAction = createActionRoutine(CHANGE_SETUP_PROJECT);
export const removeSetupProjectAction = createActionRoutine(REMOVE_SETUP_PROJECT);
export const validateProjectsStepAction = createActionRoutine(VALIDATE_PROJECTS_STEP);
