export const daysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const getYears = () => {
  const year = new Date().getFullYear() - 80;
  const years = Array.from(new Array(80), (val, index) => index + year);
  return years.reverse();
};

export const getMonths = () => {
  const months = Array.from(new Array(12), (val, index) => index);
  return months;
};
