import { AnyAction } from 'redux';
import { AccountType, Package } from '@shared/types/backend.types';
import { ErrorMessage } from '@shared/types/utils.types';
import { submitInviteAction, fetchCompaniesAction, fetchPackagesAction, setAccountIDAction } from '@app/admin/admin.action';
import { IAdminState } from '@app/admin/admin.reducer';

import update from 'immutability-helper';
import {
  IAdminInviteTeamState,
  INVITE_TEAM_INITIAL_STATE,
  adminInviteTeamReducer,
} from '../invite-team/state/invite-team.reducer';

export interface IAdminInviteState {
  companies: AccountType[];
  packages: Package[];
  isLoading: boolean;
  hasSentInvite?: boolean;
  isBlocked?: boolean;
  inviteTeam: IAdminInviteTeamState;
  errorMessages?: {
    [key: string]: ErrorMessage;
  };
}

export const INVITE_INITIAL_STATE: IAdminInviteState = {
  companies: [],
  packages: [],
  isLoading: false,
  hasSentInvite: undefined,
  isBlocked: undefined,
  errorMessages: undefined,
  inviteTeam: INVITE_TEAM_INITIAL_STATE,
};

export const adminInviteReducer = {
  ...adminInviteTeamReducer,
  [submitInviteAction.REQUEST]: (state: IAdminState) =>
    update(state, {
      invite: {
        isLoading: { $set: true },
        hasSentInvite: { $set: undefined },
        errorMessages: { $set: undefined },
      },
    }),
  [submitInviteAction.SUCCESS]: (state: IAdminState) =>
    update(state, {
      invite: {
        isLoading: { $set: false },
        hasSentInvite: { $set: true },
      },
    }),
  [submitInviteAction.FULFILL]: (state: IAdminState) =>
    update(state, {
      invite: {
        hasSentInvite: { $set: undefined },
      },
    }),
  [submitInviteAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    const { isBlocked } = payload.title
    return update(state, {
      invite: {
        isLoading: { $set: false },
        hasSentInvite: { $set: false },
        errorMessages: { $set: isBlocked ? 'The user already exists but is inactive ' : payload.title.result },
        isBlocked: { $set: isBlocked }
      },
    })
  },
  [fetchCompaniesAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      invite: {
        companies: { $set: payload.companies },
      },
    }),

  [setAccountIDAction.TRIGGER]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accountID: { $set: payload }
    })
  },

  [fetchPackagesAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      invite: {
        packages: { $set: payload.packages },
      },
    }),
};
