export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = process.env.AUTH0_CLIENT_SECRET;
export const AUDIENCE = process.env.AUDIENCE;
export const SCOPE = process.env.SCOPE;
export const AUTH0_GRANT_TYPE = process.env.AUTH0_GRANT_TYPE;
export const AUTH0_BASE_URL = process.env.AUTH0_BASE_URL;
export const BASE_URL = process.env.API_BASE_URL;
export const BEARER_KEY = process.env.SAF_APP_BEARER_KEY;
export const CURRENT_ENV = process.env.ENVIRONMENT_NAME;
export const MIGRATION_API = process.env.MIGRATION_API;

export const getAuthorizationHeader = (bearerKey: string): object => ({
  Authorization: `Bearer ${localStorage.getItem(bearerKey)}`,
});
