
import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { createHazardAction, deleteHazardAction, fetchHazardsAction, updateHazardAction } from "./hazard.actions";
import { addService, deleteService, fetchService, updateService } from '@rdx/services.saga';
import { BackendResponse, Hazard } from '@shared/types/backend.types';
import { arrayToObject } from '@shared/helpers/state-caster';

function* fetchHazardsRequest() {

    try {
        const endpoint = fetchHazardsAction.getEndpoint();
        const { data }: BackendResponse<Hazard[]> = yield call(fetchService, endpoint);

        if (data)
            yield put(fetchHazardsAction.success(arrayToObject(data, 'id')));

    } catch (error) {
        yield put(fetchHazardsAction.failure(error));
    }
}
function* createHazardRequest({ payload }: AnyAction) {

    try {

        const { cleanForm, body } = payload

        const endpoint = createHazardAction.getEndpoint();
        const { data }: BackendResponse<Hazard[]> = yield call(addService, endpoint, body);

        if (data) {
            yield put(createHazardAction.success(data));
            cleanForm()
        }

    } catch (error) {
        yield put(createHazardAction.failure(error));
    }
}
function* updateHazardRequest({ payload }: AnyAction) {

    try {
        const { body, cleanForm } = payload;
        const endpoint = updateHazardAction.getEndpoint({ hazardId: payload.params.hazardId });
        const { data }: BackendResponse<Hazard> = yield call(updateService, endpoint, body);

        if (data) {
            yield put(updateHazardAction.success(data));
            cleanForm()
        }


    } catch (error) {
        yield put(updateHazardAction.failure(error));
    }
}
function* deleteHazardRequest({ payload }: AnyAction) {

    try {
        const endpoint = deleteHazardAction.getEndpoint({ hazardId: payload.params.hazardId });
        yield call(deleteService, endpoint);
        yield put(deleteHazardAction.success({ id: payload.params.hazardId }));

    } catch (error) {
        yield put(deleteHazardAction.failure(error));
    }
}

export function* hazardsSaga() {
    yield takeLatest(fetchHazardsAction.REQUEST, fetchHazardsRequest);
    yield takeLatest(createHazardAction.REQUEST, createHazardRequest);
    yield takeLatest(updateHazardAction.REQUEST, updateHazardRequest);
    yield takeLatest(deleteHazardAction.REQUEST, deleteHazardRequest);
}
