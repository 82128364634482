import React from 'react';

import { IAppState } from '@rdx/root.reducer';

import { getSessionUser } from '@app/session/session.selector';

import { Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { changePathRedirectionAction } from '@layout/layout.action';

import Cookies from 'js-cookie';

//TODO: add type
const PrivateRoute = (props: any) => {
  const {
    changePathRedirectionAction,
    path,
    user,
    whitelistedRoles,
    blacklistedRoles,
    ...rest
  } = props;

  const sessionProps = Cookies.get('sessionKey');

  if (!sessionProps) {
    changePathRedirectionAction(path);

    return <Redirect to="/login" />;
  }

  if (
    (user && blacklistedRoles && blacklistedRoles.includes(user.app_metadata.role.name)) ||
    (user && whitelistedRoles && !whitelistedRoles.includes(user.app_metadata.role.name))
  ) {
    return <Redirect to="/" />;
  }

  if (sessionProps) {
    let decodedJwt = null

    try { decodedJwt = JSON.parse(atob(sessionProps.split('.')[1])) }
    catch (error) { decodedJwt = null }

    if (decodedJwt.exp * 1000 < Date.now()) {
      changePathRedirectionAction('/login');
      return <Redirect to="/" />;
    }
  }

  return <Route to={path} {...rest} />;
};

const mapStateToProps = (state: IAppState) => ({
  user: getSessionUser(state),
});

const mapDispatchToProps = {
  changePathRedirectionAction: changePathRedirectionAction.trigger,
};

export const PrivateRouteHOC = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);
