import { put, call, takeLatest, all, fork } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { fetchService, deleteService, addService } from '@rdx/services.saga';

import {
  fetchPlansInstanceAction,
  changeFormStatusAction,
  activateFormAction,
  deactivateFormAction,
} from './safety-programs.actions';

import { arrayToObject } from '@shared/helpers/state-caster';
import { findObjectInCollection } from '@shared/utils/lodash';

import { salesFormSaga } from '../contact-sales/state/sales-form.saga';

function* fetchPlansInstanceRequest(action: AnyAction) {
  try {
    const endpoint = fetchPlansInstanceAction.getEndpoint({ accountID: action.payload });
    let data = yield call(fetchService, endpoint);

    if (data.length > 0) {
      let safetyPlansTestWithisActive = data;

      // Add isSelected property to each safety program for enable/disable functionality
      let safetyPlansTest = safetyPlansTestWithisActive.map((safetyPlan: any) =>{
        let isActiveValue = findObjectInCollection(safetyPlan.forms, 'isSelected');
        let newSafetyPlan = {...safetyPlan, isSelected: isActiveValue };
        
        if (newSafetyPlan.safetyPlanID === 97) {
          let orderedForms = newSafetyPlan.forms.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
          newSafetyPlan.forms = arrayToObject(orderedForms, 'id');
        } else {
          newSafetyPlan.forms = arrayToObject(newSafetyPlan.forms, 'id');
        }
        return newSafetyPlan;
      });

      yield put(
        fetchPlansInstanceAction.success({
          safetyPlans: safetyPlansTest,
        }),
      );
    } else {
      yield put(
        fetchPlansInstanceAction.success({
          safetyPlans: {},
        }),
      );
    }
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchPlansInstanceAction.failure({
          code: 'backOfficeFetchPlansInstance',
          title: 'Failed to retrieve plans',
          message: 'There has been an error while retrieving safety plans.',
        }),
      );
    }
  }
}

function* changeFormStatusRequest(action: AnyAction) {
  try {
    const { isValid, form, safetyPlanID, accountID } = action.payload;
    const { id } = form;

    const addAccountFormEndpoint = activateFormAction.getEndpoint({ accountID });
    const deleteAccountFormEndpoint = deactivateFormAction.getEndpoint({
      formID: id,
      accountID,
      safetyPlanID,
    });

    if (!isValid) {
      yield call(deleteService, deleteAccountFormEndpoint);
      yield put(changeFormStatusAction.success({ id, safetyPlanID, isValid }));
    } else {
      const bodyData = [
        {
          formID: id,
          safetyPlanID,
        },
      ];
      yield call(addService, addAccountFormEndpoint, bodyData);
      yield put(changeFormStatusAction.success({ id, safetyPlanID, isValid }));
    }
  } catch (error) {}
}

export function* safetyProgramsSaga() {
  yield takeLatest(fetchPlansInstanceAction.REQUEST, fetchPlansInstanceRequest);
  yield takeLatest(changeFormStatusAction.REQUEST, changeFormStatusRequest);
  yield all([
    fork(salesFormSaga),
  ]);
}
