import { put, call, takeLatest, throttle } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { message } from 'antd';

import { updateService, fetchService, deleteService } from '@rdx/services.saga';
import {
  updateUserInfoAction,
  fetchTeamAction,
  changeSetUserAction,
  toggleSPUserAction,
  removeAccountUserAction,
  blockBackOfficeUserAction,
  fetchUserTeamAction,
} from './team.actions';

import { createTeamTearing } from '@shared/utils/add-team-tiering';
import { arrayToObject } from '@shared/helpers/state-caster';
import { FETCH_BACKOFFICE_ACCOUNT_TEAM_API } from '@constants/endpoints';
import { blockUsersAction } from '@app/admin/accounts/team/state/team.actions';

//TODO: remove endpoints, add to endpoints file
//TODO: create actions to call here
function* fetchTeamRequest(action: AnyAction): any {
  try {
    const data = yield call(
      fetchService,
      FETCH_BACKOFFICE_ACCOUNT_TEAM_API.replace(':accountID', action.payload),
    );

    createTeamTearing(data, null, 1);

    const updatedData = arrayToObject(data, 'user_id');

    yield put(fetchTeamAction.success({ team: updatedData }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchTeamAction.failure({
          error: {
            code: 'backOfficeFetchTeam',
            title: 'Failed to retrieve team.',
            message: 'There has been an error while fetching your team.',
          },
        }),
      );
    }
  }
}

function* fetchUserTeamRequest(action: AnyAction): any {
  try {

    const endpoint = fetchUserTeamAction.getEndpoint({ userID: action.payload });

    const data = yield call(fetchService, endpoint);

    yield put(fetchUserTeamAction.success(data));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchUserTeamAction.failure({
          error: {
            code: 'backOfficeFetchTeam',
            title: 'Failed to retrieve team.',
            message: 'There has been an error while fetching your team.',
          },
        }),
      );
    }
  }
}

function* updateUserInfoRequest(action: AnyAction): any {
  const { user_id, user, managerID } = action.payload;

  try {
    message.loading({ content: 'Loading', key: 'updateAssociates' });
    const endpoint = updateUserInfoAction.getEndpoint({ userID: user_id });
    yield call(updateService, endpoint, user);
    const data = yield call(fetchService, fetchUserTeamAction.getEndpoint({ userID: managerID }));
    yield put(updateUserInfoAction.success(data));
    message.success({ content: 'Associate Updated', key: 'updateAssociates' });
  } catch (error) {
    if (!error.wasCancelled) {
      message.error({
        content: 'There has been an error while saving associate',
        key: 'updateAssociates',
      });
      yield put(
        updateUserInfoAction.failure({
          error: {
            code: 'backOfficeUpdateUser',
            title: 'Failed to update user',
            message: 'There has been an error while saving user.',
          },
        }),
      );
    }
  }
}

function* blockUsersRequest(action: AnyAction) {
  try {
    message.loading({ content: 'Loading', key: 'blockUsers', duration: 0 });
    const endpoint = blockBackOfficeUserAction.getEndpoint();

    yield call(deleteService, endpoint, action.payload.toRemoveIDs);
    let data = yield call(
      fetchService,
      FETCH_BACKOFFICE_ACCOUNT_TEAM_API.replace(':accountID', action.payload.accountId),
    );

    createTeamTearing(data, null, 1);

    const updatedData = arrayToObject(data, 'user_id');

    yield put(
      updateUserInfoAction.success({
        users: updatedData,
      }),
    );
    message.success({ content: 'Associates Removed', key: 'blockUsers', duration: 2 });
  } catch (error) {
    if (!error.wasCancelled) {
      message.error({
        content: 'Error While Removing Associates!',
        key: 'blockUsers',
        duration: 2,
      });
      yield put(blockBackOfficeUserAction.failure({ title: 'error when removing users' }));
    }
  }
}

function* removeAccountUserRequest(action: AnyAction): any {
  const { userID } = action.payload;
  try {
    const endpoint = removeAccountUserAction.getEndpoint({ userID });
    yield call(deleteService, endpoint);
    yield put(removeAccountUserAction.success({ userID }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(removeAccountUserAction.failure({ title: 'Error when removing user' }));
    }
  }
}

function* changeSetupShowUserTrigger(action: AnyAction) {
  const { userKey, userUpdate } = action.payload;
  yield put(
    changeSetUserAction.fulfill({
      key: userKey,
      userUpdate,
    }),
  );
}

function* toggleTeamSorterTrigger(action: AnyAction) {
  yield put(toggleSPUserAction.fulfill(action.payload));
}

export function* teamSaga() {
  yield takeLatest(fetchTeamAction.REQUEST, fetchTeamRequest);
  yield takeLatest(fetchUserTeamAction.REQUEST, fetchUserTeamRequest);
  yield takeLatest(updateUserInfoAction.REQUEST, updateUserInfoRequest);
  yield takeLatest(removeAccountUserAction.REQUEST, removeAccountUserRequest);
  yield takeLatest(blockBackOfficeUserAction.REQUEST, blockUsersRequest);
  yield throttle(200, changeSetUserAction.TRIGGER, changeSetupShowUserTrigger);
  yield takeLatest(toggleSPUserAction.TRIGGER, toggleTeamSorterTrigger);
}
