import update from 'immutability-helper';

import { IProfessionalState } from '@app/back-office/professional.reducer';
import { AnyAction } from 'redux';

import { changePlanFormsAction, fetchFormQuestionsAction } from './forms.actions';

export const programPlanFormsReducer = {
  [changePlanFormsAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      programs: {
        forms: {
          [payload.id]: { $set: payload.checkedValues },
        },
      },
    }),
  [fetchFormQuestionsAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
    update(state, {
      question: { $set: payload.question },
      questions: { $set: payload.question.questions },
      isLoadingQuestion: { $set: false },
    }),
};
