import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';
import {
  FETCH_PLANS_INSTANCE_API,
  ADD_ACCOUNT_FORM_API,
  DELETE_ACCOUNT_FORM_API,
} from '@constants/endpoints';

export const FETCH_PLANS_INSTANCE = 'FETCH_PLANS_INSTANCE';

export const CHANGE_FORM_STATUS_REQUEST = 'CHANGE_FORM_STATUS_REQUEST';

export const ADD_ACCOUNT_FORM_INSTANCE = 'ADD_ACCOUNT_FORM_INSTANCE';

export const DELETE_ACCOUNT_FORM_INSTANCE = 'DELETE_ACCOUNT_FORM_INSTANCE';

export const fetchPlansInstanceAction = createActionRoutine(
  FETCH_PLANS_INSTANCE,
  FETCH_PLANS_INSTANCE_API,
);

export const changeFormStatusAction = createActionRoutine(CHANGE_FORM_STATUS_REQUEST);

export const activateFormAction = createActionRoutine(
  ADD_ACCOUNT_FORM_INSTANCE,
  ADD_ACCOUNT_FORM_API,
);

export const deactivateFormAction = createActionRoutine(
  DELETE_ACCOUNT_FORM_INSTANCE,
  DELETE_ACCOUNT_FORM_API,
);
