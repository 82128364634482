import { Empty } from 'antd'
import React from 'react'

type Props = {
    title?: string;
    className?: string
}

const SAEmpty = (props: Props) => {

    const { title, className } = props;

    return (
        <Empty
            description={title}
            className={className}
            style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 0, padding: "32px 0px" }}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    )
}

export default SAEmpty