import React from 'react';

import { ThemeType } from '@shared-types/theme.type';

import { Classes } from 'jss';

import { Spin, Icon } from 'antd';

import injectSheet from 'react-jss';

import './spinner.styles.scss';

const styles = (theme: ThemeType) => ({
  spinnerStyles: {
    fontSize: 44,
    color: theme.green.main,
  },
});

export interface ILoadingProps {
  classes: Classes;
}

const LoadingSpinner = ({ classes }: ILoadingProps) => (
  <div className="loading-spinner">
    <Spin indicator={<Icon type="loading" className={classes.spinnerStyles} spin />} />
  </div>
);

export const LoadingSpinnerComponent = injectSheet(styles)(LoadingSpinner);
