import React, { useEffect, useState, SetStateAction, ChangeEvent, useMemo } from 'react';

import { IAppState } from '@rdx/root.reducer';
import {
  JobTitleType,
  UserType,
  UserMetaData,
  RoleType,
  UserCertificateType,
} from '@shared/types/backend.types';
import { baseTheme } from '@shared/themes/presets/base.theme';

import { Sorter } from '@shared-types/utils.types';
import { RemoveUserPayload } from '../team.d';
import { UpdateAccountUserPayload } from '../../accounts';

import { Modal, Tree, Table, Divider, Tag } from 'antd';
import Swal from 'sweetalert2';

import { ROLE } from '@constants/roles';

import { connect } from 'react-redux';
import {
  fetchAccountUsersAction,
  fetchUserCertificatesAction,
  toggleAccountsTeamSorterAction,
  updateAccountUserAction,
  resendUserInviteAction,
  blockUsersAction,
} from './team.actions';
import { getAccountID } from '@app/admin/invite/state/invite.selector';
import {
  getAccountsTeam,
  getAccountsTeamIsLoading,
  getAccountsTeamSorter,
  getUserCertificates,
} from './team.selector';
import { getSessionUser } from '@app/session/session.selector';

import { AccountsTeamComponent } from '../view/team.component';

import { invertedOrder } from '@constants/sort';
import _ from 'lodash';
import { AntdSelectTarget } from '@app/back-office/team/team';
import { getUnderUsers } from '@shared/utils/filterSubUsers';
import { sleep } from '@shared/utils/sleep';

interface IAccountsTeamProps {
  roles: [];
  companyContractors: [];
  team: UserType[];
  isLoadingAccountsTeam: boolean;
  sorter: Sorter;
  loggedUser?: UserType;
  selectedAccountID?: number;
  fetchTeamAction: (selectedAccountID: number) => void;
  fetchUserCertificates: (selectedUserID: string) => void;
  removeAccountUserAction: (payload: any) => void;
  updateAccountUserAction: (payload: UpdateAccountUserPayload) => void;
  resendUserInviteAction: (selectedUserID: string) => void;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Job Title',
    dataIndex: 'jobTitle',
  },
];

const AccountsTeam = (props: IAccountsTeamProps) => {
  const {
    roles,
    loggedUser,
    team,
    selectedAccountID,
    isLoadingAccountsTeam,
    companyContractors,
  } = props;
  const {
    fetchTeamAction,
    fetchUserCertificates,
    removeAccountUserAction,
    updateAccountUserAction,
    resendUserInviteAction,
  } = props;

  const [sorter, setSorter] = useState<Sorter>({ key: 'tier', order: 'asc' });
  const [selectedRolID, setselectedRolID] = useState<number | undefined>(undefined);
  const [modifiedUser, setModifiedUser] = useState<SetStateAction<any>>([] as any);
  const [teamFilter, setTeamFilter] = useState<string>('active');
  const [showPopupModal, setPopupModal] = useState<boolean>(false);
  const [filteredTeam, setFilteredTeam] = useState<UserType[]>([]);
  const nonProxyUsers = useMemo(() => {
    return team.filter(user => !user.user_metadata.isProxyUser);
  }, [team]);
  const [treeNodes, setTreeNodes] = useState<any>();

  const { TreeNode } = Tree;

  useEffect(() => {
    if (team.length) {
      onFilterChange(teamFilter);
    } else {
      setFilteredTeam([]);
    }
  }, [team]);

  const onFilterChange = (value: string) => {
    setTeamFilter(value);
    if (value === 'active')
      setFilteredTeam(nonProxyUsers.filter((user: UserType) => !user.blocked));
    else if (value === 'inactive') setFilteredTeam(team.filter((user: UserType) => user.blocked));
    else setFilteredTeam(nonProxyUsers);
  };

  useEffect(() => {
    if (filteredTeam.length > 0) {
      setTreeNodes(buildTree(filteredTeam, null, true));
    } else {
      setTreeNodes([]);
    }
  }, [filteredTeam]);

  useEffect(() => {
    if (selectedAccountID) {
      fetchTeamAction(selectedAccountID);
    }
  }, [selectedAccountID, fetchTeamAction]);

  const toggleCheckBoxChange = (value: boolean) => {
    setTreeNodes(buildTree(nonProxyUsers, null, !value));
  };

  const buildTree = (data: UserType[], user: UserType | null, incActive: boolean): any => {
    let children: UserType[] = [];

    if (!user) {
      children = data.filter((item: UserType) => item.reportsTo === null);
    } else {
      children = data.filter(
        (item: UserType) => item.user_metadata.reportsToUserID === user.user_id,
      );
    }

    if (children.length === 0) {
      return null;
    }

    return children.map((child: UserType) => {
      if (incActive && child.blocked === true) return null;
      const proxyUsers = team.filter((item: UserType) =>
        item.user_metadata.proxyUsers && item.user_metadata.proxyUsers.length
          ? item.user_metadata.proxyUsers.includes(child.user_id)
          : undefined,
      );

      return (
        <TreeNode
          title={
            <span className={child.blocked ? 'inactive-user' : undefined}>
              {child.name}{' '}
              {child.contractorCompany !== null
                ? `(${child.contractorCompany.companyName})`
                : `(${child.user_metadata.companyName})`}{' '}
              - {child.app_metadata.role.name} (Tier {child.tier})
              {child.blocked ? 'Inactive' : null}{' '}
              {proxyUsers.length ? (
                <span style={{ backgroundColor: '#ff4d5036' }}>
                  {`( ${proxyUsers.map(user => user.name).join(', ')} )`}
                </span>
              ) : null}
            </span>
          }
          key={child.user_id}
        >
          {buildTree(data, child, incActive)}
        </TreeNode>
      );
    });
  };

  const resendUserInvite = (selectedUserId: string) => {
    resendUserInviteAction(selectedUserId);
  };

  const handleDeleteUser = (id: string, status: boolean) => {
    const underUsers: UserType[] = getUnderUsers(team, id);
    const underUsersLength = underUsers.length;
    const targetUser = underUsers[0];
    const underUsersIDs = underUsers.map((underUser: UserType) => underUser.user_id);
    let data: any = [];

    underUsers.map((underUser: UserType) => {
      if (!underUser.blocked)
        data.push({
          key: underUser.user_id,
          name: underUser.name,
          jobTitle: underUser.user_metadata.jobTitle,
        });
    });

    Modal.confirm({
      className: 'remove-associate',
      icon: null,
      title: null,
      visible: showPopupModal,
      onOk() {
        if (status) {
          updateAccountUserAction({
            user: { blocked: false },
            toUpdateUserId: id,
            accountId: selectedAccountID,
          });
          return setPopupModal(false);
        }
        removeAccountUserAction({ toRemoveIDs: underUsersIDs, accountId: selectedAccountID });
        return setPopupModal(false);
      },
      content: (
        <>
          <div
            className="swal2-icon swal2-warning swal2-animate-warning-icon"
            style={{ display: 'flex ' }}
          ></div>
          <b className="warn-text">
            Do you want to {!targetUser.blocked ? 'inactive' : 'active'} {targetUser.name}{' '}
            {underUsersLength > 1 && !targetUser.blocked ? 'and associates under him' : ''} ?
          </b>

          <br />

          {underUsersLength > 1 && !targetUser.blocked ? (
            <>
              <span className="note-text">
                <b>Note:&nbsp;</b>
                <span>
                  Associate under {targetUser.name} also be inactive. you can also change reporting
                  of associates under {targetUser.name}{' '}
                </span>
              </span>
              <Table
                bordered={true}
                columns={columns}
                dataSource={data}
                scroll={{ y: 200 }}
                pagination={false}
              />
            </>
          ) : null}
        </>
      ),
      okText: `${!targetUser.blocked ? 'Inactive' : 'Active'}`,
      cancelText: 'Cancel',
    });
  };

  const handleSaveUser = (user_id: string) => {
    const { name }: UserType = modifiedUser[user_id];
    const { canCreateProject }: UserType = modifiedUser[user_id];
    const { reportsToUserID }: UserMetaData = modifiedUser[user_id];
    const { jobTitle }: JobTitleType = modifiedUser[user_id];
    const { roleID }: RoleType = modifiedUser[user_id];
    const { contractorCompanyID }: UserMetaData = modifiedUser[user_id];

    const mappedUser: any = {};

    if (typeof name !== 'undefined') {
      var splitName = name.split(' ');
      mappedUser.name = name;
      mappedUser.firstName = splitName.shift();
      mappedUser.lastName = splitName.join(' ');
    }

    if (typeof roleID !== 'undefined') {
      mappedUser.app_metadata = {};
      mappedUser.app_metadata.role = {};
      mappedUser.app_metadata.role.roleID = roleID;
      mappedUser.app_metadata.role.name = ROLE[roleID];
    }
    if (typeof reportsToUserID !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.reportsToUserID = reportsToUserID;
      } else {
        mappedUser.user_metadata.reportsToUserID = reportsToUserID;
      }
    }

    if (typeof contractorCompanyID !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.contractorCompanyID = contractorCompanyID;
      } else {
        mappedUser.user_metadata.contractorCompanyID = contractorCompanyID;
      }
    }

    if (typeof jobTitle !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.jobTitle = jobTitle;
      } else {
        mappedUser.user_metadata.jobTitle = jobTitle;
      }
    }

    if (typeof canCreateProject !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.canCreateProject = canCreateProject;
      } else {
        mappedUser.user_metadata.canCreateProject = canCreateProject;
      }
    }

    updateAccountUserAction({
      user: mappedUser,
      toUpdateUserId: user_id,
      accountId: selectedAccountID,
    });

    const removeUserModified = { ...modifiedUser };
    delete removeUserModified[user_id];
    setModifiedUser(removeUserModified);
  };

  const handleUpdateUser = (event: any, user: any) => {
    if (event.target.name === 'reportsToUserID') {
      const result = reportsToValidation(event, user);
      if (result.status) {
        Swal.close();
        Swal.fire({
          title: 'Action Not Allowed',
          text: result.message,
          type: 'error',
          html: undefined,
          confirmButtonColor: baseTheme.red.error,
        });

        event.target.value = user.user_metadata.reportsToUserID;

        return;
      }
    }

    const userModified = {
      ...modifiedUser[user.user_id],
      [event.target.name]: event.target.value,
    };

    setModifiedUser({
      ...modifiedUser,
      [user.user_id]: {
        ...userModified,
        user_id: user.user_id,
      },
    });
  };

  const reportsToValidation = (
    event: ChangeEvent<HTMLSelectElement> | AntdSelectTarget,
    user: UserType,
  ) => {
    const result = { status: false, message: '' };

    if (
      user.user_metadata.reportsToUserID === '' &&
      team.filter((user: UserType) => user.user_metadata.reportsToUserID === '').length < 2
    ) {
      result.status = true;
      result.message = 'To change reporting of Owner there must be another owner exist in company';
      return result;
    }

    const toReportsToUser: UserType = team.filter(
      (user: UserType) => user.user_id === event.target.value,
    )[0];

    if (toReportsToUser && toReportsToUser.user_metadata.reportsToUserID === user.user_id) {
      result.status = true;
      result.message =
        'The user you are trying to assign reportsTo is already reporting to the same user';
      return result;
    }

    return result;
  };

  const handleFecthCertificate = (selectedUserId: string | undefined) => {
    if (selectedUserId !== undefined) {
      fetchUserCertificates(selectedUserId);
    }
  };

  const handleJobTitleChange = (value: any, user_id: string) => {};

  const handleSort = (key: string) => {
    if (sorter.key === key) {
      setSorter({ order: invertedOrder[sorter.order], key });
    } else {
      setSorter({ order: 'asc', key });
    }
  };

  const orderedTeam: UserType[] = _.orderBy(filteredTeam, [sorter.key], [sorter.order as any]);

  return (
    <AccountsTeamComponent
      sorter={sorter}
      companyContractors={companyContractors}
      roles={roles}
      orderedTeam={orderedTeam}
      team={team}
      treeNodes={treeNodes}
      onFilterChange={onFilterChange}
      resendUserInvite={resendUserInvite}
      loggedUser={loggedUser}
      handleFecthCertificate={handleFecthCertificate}
      isLoading={isLoadingAccountsTeam}
      handleDeleteUser={handleDeleteUser}
      handleSort={handleSort}
      toggleCheckBoxChange={toggleCheckBoxChange}
      handleJobTitleChange={handleJobTitleChange}
      selectedAccountID={selectedAccountID}
      selectedRolID={selectedRolID}
      setselectedRolID={setselectedRolID}
      handleUpdateUser={handleUpdateUser}
      modifiedUser={modifiedUser}
      handleSaveUser={handleSaveUser}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  loggedUser: getSessionUser(state),
  team: getAccountsTeam(state),
  sorter: getAccountsTeamSorter(state),
  isLoadingAccountsTeam: getAccountsTeamIsLoading(state),
  selectedAccountID: getAccountID(state),
});

const mapDispatchToProps = {
  fetchTeamAction: fetchAccountUsersAction.request,
  fetchUserCertificates: fetchUserCertificatesAction.request,
  removeAccountUserAction: blockUsersAction.request,
  toggleSorterAction: toggleAccountsTeamSorterAction.trigger,
  updateAccountUserAction: updateAccountUserAction.request,
  resendUserInviteAction: resendUserInviteAction.request,
};

export const AccountsTeamContainer = connect(mapStateToProps, mapDispatchToProps)(AccountsTeam);
