import { UserType } from '@shared/types/backend.types';
import { Button, Checkbox, Icon, Modal, Select, Table, Tree } from 'antd';
import React, { useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import CreateProxyUserModal from './create/proxy-user.modal';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type Props = {
  user?: UserType;
  accountsTeam: UserType[];
  proxyUsersColumns: ColumnProps<UserType>[] | undefined;
  proxyUsers: UserType[];
  treeNodes: any;
  isLoading: boolean;
  openModal: boolean;
  history: History;
  toggleModal: (value: React.SetStateAction<boolean>) => void;
  setUserFilter: (value: React.SetStateAction<string>) => void;
};

const ProxyUsers = (props: Props) => {
  const {
    user,
    accountsTeam,
    isLoading,
    openModal,
    proxyUsersColumns,
    proxyUsers,
    history,
    treeNodes,
    toggleModal,
  } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="sa__container">
        <div className="sa__box">
          <div className="sa__box-heading">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', cursor: 'pointer' }}>
              <Icon onClick={() => history.push('/')} style={{ fontSize: 18 }} type="arrow-left" />
              <div>
                <h4 className="sa__box-heading-title">{`Proxy Users ${
                  user ? `- ${user.name}` : ''
                }`}</h4>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button type="primary" onClick={() => setShowModal(true)}>
                Organization Tree
              </Button>

              <Button type="primary" onClick={() => toggleModal(true)}>
                <Icon type="plus-square" />
                Add New User
              </Button>
            </div>
          </div>

          <div className="sa__box-content">
            <Table
              dataSource={proxyUsers}
              loading={isLoading}
              columns={proxyUsersColumns}
              rowKey={user => user.user_id}
              bordered={true}
              pagination={false}
            />
          </div>
        </div>
      </div>

      {user ? (
        <CreateProxyUserModal
          user={user}
          accountsTeam={accountsTeam}
          openModal={openModal}
          isLoading={isLoading}
          toggleModal={toggleModal}
        />
      ) : null}

      <Modal
        title={'Organization Tree'}
        className="sa-tree"
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={
          <Button
            className="ant-modal-btn-close"
            type="primary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        }
      >
        <Tree defaultExpandAll showLine={true} selectable={false} multiple>
          {treeNodes}
        </Tree>
      </Modal>
    </>
  );
};

const ProxyUsersComponent: any = withRouter(ProxyUsers as any);

export default ProxyUsersComponent;
