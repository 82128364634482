import React from 'react';

import { ThemeType } from '@shared-types/theme.type';
import { FormComponentProps } from 'antd/es/form';
import { Classes } from 'jss';

import { Form, Input } from 'antd';
import { useIntl } from 'react-intl';

import { ButtonControlComponent } from '@controls/button/button-control.component';

import injectSheet from 'react-jss';
import classNames from 'classnames';

import emailIcon from '@assets/icon/email-icon.svg';

import '@session/session.styles.scss';
import '@controls/input.styles.css';
import './send-restore.styles.scss';

export interface ISendRestoreEmailProps {
  classes: Classes;
  form: any;
  isLoading: boolean;
  handleSubmit: (form: FormComponentProps) => void;
}

const SendRestoreEmail = (props: ISendRestoreEmailProps) => {
  const { isLoading, form } = props;
  const { handleSubmit } = props;
  const { formLabelStyles, titleStyles, buttonStyles } = props.classes;
  const { getFieldDecorator } = form;
  const intlProvider = useIntl();

  return (
    <div className="session__container">
      <div className="session__restore-form-box send__restore-box">
        <div className={classNames('send__restore-container', titleStyles)}>
          <h1>Password reset</h1>
          <span>Enter your email address and we'll send you a link to reset your password</span>
        </div>
        <Form
          className={classNames('input--large-width login__form-label', formLabelStyles)}
          layout="vertical"
          hideRequiredMark
        >
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: intlProvider.messages['inputs.requiredEmailAddress'],
                },
              ],
            })(
              <Input
                className="input--medium-height"
                placeholder="Email"
                prefix={<img src={emailIcon} width="18" alt="Email" />}
              />,
            )}
          </Form.Item>
          <Form.Item className={classNames(buttonStyles)}>
            <ButtonControlComponent
              text={'Send email'}
              size="extraLarge"
              color="green"
              htmlType="submit"
              loading={isLoading}
              style={{ marginTop: 10 }}
              onClick={() => handleSubmit(form)}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
const styles = (theme: ThemeType) => ({
  titleStyles: {
    '& h1': {
      fontSize: 30,
      fontWeight: 500,
    },
    '& span': {
      fontSize: 18,
      color: theme.grey.fourth,
    },
  },
  formLabelStyles: {
    color: theme.grey.main,
    fontSize: '18px',
  },
  buttonStyles: {
    '& span': {
      fontSize: 16,
      fontWeight: 'lighter',
    },
  },
});

const SendRestoreEmailStyled = injectSheet(styles)(SendRestoreEmail);
export const SendRestoreEmailFormComponent: any = Form.create({ name: 'sendRestoreEmail' })(
  SendRestoreEmailStyled,
);
