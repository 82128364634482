import { AnyAction } from "redux";
import { deletePtpSubmissionAction, fetchPtpSubmissionAction, updatePtpSubmissionAction } from "./ptp-submissions.actions";
import { deleteService, fetchService, patchService } from "@rdx/services.saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { BackendResponse } from "@shared/types/backend.types";
import { arrayToObject } from "@shared/helpers/state-caster";


function* fetchPtpSubmissionsRequest({ payload }: AnyAction) {

    try {

        const endpoint = fetchPtpSubmissionAction.getEndpoint();

        const response: BackendResponse<any[]> = yield call(fetchService, endpoint, payload.query);

        const { data } = response;

        if (data) yield put(fetchPtpSubmissionAction.success(arrayToObject(data, 'id')));

    } catch (error) {
        yield put(fetchPtpSubmissionAction.failure({}));
    }
}

function* updatePtpSubmissionRequest({ payload }: AnyAction) {

    const { clearForm } = payload;

    try {
        const endpoint = updatePtpSubmissionAction.getEndpoint({ submissionId: payload.params.submissionId });

        const response: BackendResponse<any> = yield call(patchService, endpoint, payload.body);

        const { data } = response;

        if (data) {
            yield put(updatePtpSubmissionAction.success(data));
            clearForm && clearForm(false)
        }

    } catch (error) {
        yield put(updatePtpSubmissionAction.failure({}));
    }
}


function* deletePtpSubmissionRequest({ payload }: AnyAction) {

    const { submissionId } = payload.params;

    try {
        const endpoint = deletePtpSubmissionAction.getEndpoint({ submissionId });

        const response: BackendResponse<any> = yield call(deleteService, endpoint);

        const { data } = response;

        if (data) yield put(deletePtpSubmissionAction.success(submissionId));

    } catch (error) {
        yield put(deletePtpSubmissionAction.failure({}));
    }
}


export function* ptpSubmissionSaga() {
    yield takeLatest(fetchPtpSubmissionAction.REQUEST, fetchPtpSubmissionsRequest);
    yield takeLatest(updatePtpSubmissionAction.REQUEST, updatePtpSubmissionRequest);
    yield takeLatest(deletePtpSubmissionAction.REQUEST, deletePtpSubmissionRequest);

}