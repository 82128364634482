import React, { useState, SetStateAction, useEffect } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { ValidateStepPayload } from '@back-office/programs/programs.d';
import { IProfessionalProgramsState } from '../../state/programs.reducer';

import { connect } from 'react-redux';
import { changeFormValuesAction } from '@app/back-office/professional.action';
import { getCurrentStep, getStepItems } from '@layout/layout.selector';

import { ProfessionalProjectsComponent } from '../view/projects.component';

import { validateProjects } from '../../programs.validations';
import {
  getPrograms,
  getProgramsProjects,
  getProjectCachedCounter,
} from '@app/back-office/professional.selector';
import { invertedOrder } from '@constants/sort';

import {
  RemoveProjectPayload,
  MappedProject,
  AddProjectPayload,
  ChangeProjectPayload,
} from '../project.d';
import {
  addSetupProjectAction,
  removeSetupProjectAction,
  changeSetupProjectAction,
  validateProjectsStepAction,
} from './project.actions';

export interface IProfessionalProjectsContainer {
  items: any;
  projects: [];
  projectCachedCounter: number;
  programForms: IProfessionalProgramsState;
  currentStep: number;
  addProjectAction: (payload: AddProjectPayload) => void;
  changeProjectAction: (payload: ChangeProjectPayload) => void;
  removeProjectAction: (payload: RemoveProjectPayload) => void;
  validateProjectsAction: (payload: ValidateStepPayload) => void;
}

const Projects = (props: IProfessionalProjectsContainer) => {
  const { projects, projectCachedCounter, programForms, currentStep } = props;
  const {
    addProjectAction,
    changeProjectAction,
    removeProjectAction,
    validateProjectsAction,
  } = props;
  const [sortSetup, setSortSetup] = useState<SetStateAction<any>>({ key: 'name', order: 'asc' });

  useEffect(() => {
    //const projects = items.find((item: any) => item.name === 'projects');
    //if steps are added this step number might change
    if (currentStep === 1) {
      const isValid = validateProjects(programForms.projects);
      validateProjectsAction({ currentStep, isValid });
    }
  }, [programForms.projects, currentStep, validateProjectsAction]);

  const handleUpdateForm = (targetKey: string, value: string, projectKey: string) => {
    changeProjectAction({
      projectKey: projectKey,
      projectUpdate: { ...programForms.projects[projectKey], [targetKey]: value },
    });
  };

  // TODO: Fix sorter
  const handleSort = (key: any) => {
    if (sortSetup.key === key) {
      setSortSetup({ order: invertedOrder[sortSetup.order], key });
    } else {
      setSortSetup({ order: 'asc', key });
    }
  };

  const handleRemoveTableItem = (itemToRemove: any) => {
    removeProjectAction({ projectKey: itemToRemove });
  };

  const handleAddTableItem = () => {
    const newProject: MappedProject = {
      key: projectCachedCounter,
      number: '',
      name: '',
      address: '',
    };

    addProjectAction({ project: newProject });
  };

  //const orderedProjects: any = _.orderBy(projects, [sortSetup.key], [sortSetup.order]);

  return (
    <div style={{ height: 350 }}>
      <ProfessionalProjectsComponent
        dataSource={projects}
        handleSort={handleSort}
        handleUpdateForm={handleUpdateForm}
        handleAddTableItem={handleAddTableItem}
        handleRemoveTableItem={handleRemoveTableItem}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  projects: getProgramsProjects(state),
  projectCachedCounter: getProjectCachedCounter(state),
  programForms: getPrograms(state),
  currentStep: getCurrentStep(state),
  items: getStepItems(state),
});

const mapDispatchToProps = {
  changeFormValuesAction: changeFormValuesAction.trigger,
  addProjectAction: addSetupProjectAction.trigger,
  changeProjectAction: changeSetupProjectAction.trigger,
  removeProjectAction: removeSetupProjectAction.trigger,
  validateProjectsAction: validateProjectsStepAction.trigger,
};

export const ProfessionalProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Projects);
