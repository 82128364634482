import { takeLatest, call, put, all, fork } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import {
  fetchFormsAction,
  changeCurrentPlanStepAction,
  validatePlansStepAction,
} from './plans.actions';
import { fetchService, cancelService } from '@rdx/services.saga';

import { arrayToObject } from '@shared/helpers/state-caster';
import { programPlanFormsSaga } from '../forms/state/forms.saga';

function* fetchFormsRequest(action: AnyAction) {
  try {
    const endpoint = fetchFormsAction.getEndpoint({ planID: action.payload });

    const data = yield call(fetchService, endpoint);

    const validForms = data.filter((form: any) => form.isActive === true);

    const formsCollection = arrayToObject(validForms, 'id');

    yield put(fetchFormsAction.success({ forms: formsCollection }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchFormsAction.failure({ title: 'There has been an error while retrieving forms.' }),
      );
    }
  }
}

function* changeCurrentPlanStepTrigger(action: AnyAction) {
  yield put(changeCurrentPlanStepAction.fulfill(action.payload));
}

function* validatePlanStepTrigger(action: AnyAction) {
  yield put(
    validatePlansStepAction.fulfill({
      step: action.payload.currentStep,
      isValid: action.payload.isValid,
    }),
  );
}

export function* setupPlansSaga() {
  yield takeLatest(fetchFormsAction.REQUEST, fetchFormsRequest);
  yield takeLatest(fetchFormsAction.FULFILL, cancelService);
  yield takeLatest(changeCurrentPlanStepAction.TRIGGER, changeCurrentPlanStepTrigger);
  yield takeLatest(validatePlansStepAction.TRIGGER, validatePlanStepTrigger);
  yield all([fork(programPlanFormsSaga)]);
}
