import { RoutineReducer } from './redux-saga-endpoints.d';

//TODO: add types
function replaceEndpointPlaceholders(endpoint: any, params?: any) {
  let renewedEndpoint = endpoint;

  if (params !== undefined && params !== null) {
    Object.entries(params).forEach(element => {
      const placeholder = `:${element[0]}`;

      renewedEndpoint = renewedEndpoint.replace(placeholder, element[1]);
    });
  }

  return renewedEndpoint;
}

function typeGenerator(type: string) {
  return {
    TRIGGER: `${type}/TRIGGER`,
    FULFILL: `${type}/FULFILL`,
    REQUEST: `${type}/REQUEST`,
    SUCCESS: `${type}/SUCCESS`,
    FAILURE: `${type}/FAILURE`,
  };
}

export function createActionRoutine(type: string, endpoint = '') {
  const types = typeGenerator(type);

  return {
    ...types,
    trigger: (payload: any = null) => ({ type: types.TRIGGER, payload }),
    fulfill: (payload: any = null) => ({ type: types.FULFILL, payload }),
    success: (payload: any = null) => ({ type: types.SUCCESS, payload }),
    failure: (payload: any = null) => ({ type: types.FAILURE, payload }),
    request: (payload: any = null) => ({ type: types.REQUEST, payload }),
    getEndpoint: (params?: any) => replaceEndpointPlaceholders(endpoint, params),
  };
}

export function createRoutineReducer(): RoutineReducer {
  return {
    items: {},
    isLoading: false,
    errorMessages: {},
  };
}
