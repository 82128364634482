import React, { ChangeEvent } from 'react';

import { ContractorType, UserType, AccountType, PackageSeatDetail } from '@shared/types/backend.types';

import { Switch, Popconfirm, Select } from 'antd';
import { SorterComponent } from '@components/controls/table/sorter.component';

import { useIntl } from 'react-intl';

//TODO: Add sort icons with asc and desc statuses.

import { isEmployeeValid } from '@app/back-office/programs/programs.validations';


const { Option, OptGroup } = Select;


//TODO: add types
export interface ITeamTableProps {
  user: UserType;
  companyContractors: ContractorType[]
  selectedCompanyInformation: AccountType;
  selectedAccountID?: number;
  accounts: AccountType[];
  users: UserType[];
  packages: PackageSeatDetail[];
  reportsToUsers: UserType[];
  jobTitles?: string[];
  handleSort: (key: any) => void;
  handleUpdateForm: (targetKey: string, value: any, key: string | number) => void;
  handleAddTableItem: (item: any) => void;
  handleRemoveTableItem: (key: number) => void;
}

export const TeamTableComponent = (props: ITeamTableProps) => {
  const { user, companyContractors, users, packages, reportsToUsers, selectedAccountID, selectedCompanyInformation, accounts } = props;
  const { handleSort, handleUpdateForm, handleAddTableItem, handleRemoveTableItem } = props;
  const intl = useIntl();

  const renderEditableRow = () => {
    return users.map((item: any) => {
      if (item.email === user.email) {
        return (
          <tr key={item.key} className={isEmployeeValid(item) ? '' : 'is-logged-user-invalid'}>
            <td>
              <input
                defaultValue={item.given_name || item.givenName || ''}
                placeholder={item.given_name || item.givenName || 'First Name'}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('givenName', e.target.value, item.key)
                }
                disabled
                required
              />
            </td>
            <td>
              <input
                defaultValue={item.family_name || item.familyName || ''}
                placeholder={item.family_name || item.familyName || 'Last Name'}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('familyName', e.target.value, item.key)
                }
                disabled
                required
              />
            </td>
            <td>
              <input
                defaultValue={item.email || ''}
                placeholder={item.email || 'Email'}
                type="email"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('email', e.target.value, item.key)
                }
                required
                disabled
              />
            </td>
            <td>
              <input
                defaultValue={item.jobTitle || ''}
                placeholder={item.jobTitle || 'Job title'}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('jobTitle', e.target.value, item.key)
                }
                disabled
                required
              />
            </td>
            <td>
              <input
                defaultValue={item.packageID || ''}
                placeholder={item.packageName || 'License Type'}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('packageName', e.target.value, item.key)
                }
                disabled
                required
              />
            </td>

            <td>
              <input
                defaultValue={item.reportsToUserID || ''}
                placeholder={item.reportsToUserName || 'ReportsTo'}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateForm('reportsToUserID', e.target.value, item.key)
                }
                disabled
                required
              />
            </td>

            <td>
              <Switch
                defaultChecked
                onClick={() =>
                  handleUpdateForm('canCreateProject', !item.canCreateProject, item.key)
                }
              />
              <Popconfirm
                placement="topRight"
                title="Are you sure you want to delete?"
                onConfirm={() => handleRemoveTableItem(item.key)}
                okText="Yes"
              >
                <i
                  className="far fa-trash-alt"
                  style={{ padding: '16px 16px !important', marginLeft: 15, cursor: 'pointer' }}
                ></i>
              </Popconfirm>
            </td>
          </tr>
        );
      }


      return (
        <tr key={item.key} className={isEmployeeValid(item) ? '' : 'is-invalid'}>
          <td>
            <input
              defaultValue={item.given_name || item.givenName || ''}
              placeholder={item.given_name || item.givenName || 'First Name'}
              className='select'
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('givenName', e.target.value, item.key)
              }
              autoFocus
              required
            />
          </td>
          <td>
            <input
              defaultValue={item.family_name || item.familyName || ''}
              placeholder={item.family_name || item.family_name || 'Last Name'}
              className='select'
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('familyName', e.target.value, item.key)
              }
              required
            />
          </td>
          <td>
            <input
              defaultValue={item.email || ''}
              placeholder={item.email || 'Email'}
              className='select'
              type="email"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('email', e.target.value, item.key)
              }
              required
            />
          </td>
          <td>
            <input
              defaultValue={item.jobTitle || ''}
              placeholder={item.jobTitle || 'Job title'}
              className='select'
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleUpdateForm('jobTitle', e.target.value, item.key)
              }
              required
            />
          </td>

          <td>
            <Select placeholder={item.package || 'License Type'} onChange={((value: any) => handleUpdateForm('package', value, item.key))}>
              <OptGroup label="Select License Type">
                {packages.map((license: PackageSeatDetail) => (
                  license.packageID !== 'SA-ADMINISTRATOR' ? (
                    <Option key={license.packageID} value={license.packageID}>{`${license.packageName} (${license.availableSeats})`}</Option>
                  )
                    : null
                ))}
              </OptGroup>
            </Select>
          </td>

          {user.app_metadata.role.roleID == 2 ?
            <td>
              <Select placeholder="Select Role" onChange={((value) => handleUpdateForm('roleID', value, item.key))}>
                <OptGroup label="Select License Type">
                  <Option key={1} value="1" title="User">
                    Mobile User
                  </Option>
                  <Option key={2} value="2" title="Administrator">
                    Administrator
                  </Option>
                </OptGroup>
              </Select>
            </td> : ''}

          <td>
            <Select placeholder="Select Company" defaultValue={"0"} onChange={((value: any) => handleUpdateForm('contractorCompanyID', value, item.key))}>
              <OptGroup label="Select Company">
                {selectedCompanyInformation !== undefined ? <Option value="0">{selectedCompanyInformation.name}</Option> : null}
                {companyContractors.map((contractor: ContractorType) => {
                  return <Option value={contractor.contractorCompanyID}>{contractor.companyName}</Option>
                })}
              </OptGroup>
            </Select>
          </td>

          <td>
            <Select placeholder="Select Reports To" defaultValue={item.reportsToUserID} onChange={((value: any) => handleUpdateForm('reportsToUserId', value, item.key))}>
              <OptGroup label="Select Reports To">
                <option value="">Owner (Tier 1)</option>
                {reportsToUsers.map((u: UserType) => (
                  u.app_metadata.role.roleID == 2 && !u.blocked
                    ? (<Option value={u.user_id}>{u.name}</Option>)
                    : ""
                ))}
              </OptGroup>
            </Select>
          </td>
          <td>
            <Switch
              defaultChecked
              onClick={() => handleUpdateForm('canCreateProject', !item.canCreateProject, item.key)}
            />
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete?"
              onConfirm={() => handleRemoveTableItem(item.key)}
              okText="Yes"
            >
              <i
                className="far fa-trash-alt"
                style={{ padding: '16px 16px !important', marginLeft: 15, cursor: 'pointer' }}
              ></i>
            </Popconfirm>
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="sa-table">
      <thead>
        <tr>
          <th className="unselectable" onClick={() => handleSort('firstName')}>
            <span>{intl.messages['inputs.firstName'].toString()}</span> <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('lastName')}>
            {intl.messages['inputs.lastName'].toString()} <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('email')}>
            {intl.messages['inputs.email'].toString()}
            <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('jobTitleID')}>
            {intl.messages['inputs.jobTitle'].toString()}
            <SorterComponent status="asc" />
          </th>
          <th className="unselectable" onClick={() => handleSort('packageID')}>
            {intl.messages['inputs.package'].toString()}
            <SorterComponent status="asc" />
          </th>

          <th className="unselectable" onClick={() => handleSort('contractor')}>
            {intl.messages['inputs.contractor'].toString()}
            <SorterComponent status="asc" />
          </th>

          <th className="unselectable" onClick={() => handleSort('reportsTo')}>
            {intl.messages['inputs.reportsTo'].toString()}
            <SorterComponent status="asc" />
          </th>

          <th>
            {intl.messages['inputs.createProject'].toString()}
          </th>
        </tr>
      </thead>
      <tbody>
        {renderEditableRow()}
        <tr onClick={() => handleAddTableItem({})}>
          <td>
            <input className='select' placeholder={intl.messages['inputs.firstName'].toString()} disabled />
          </td>
          <td>
            <input className='select' placeholder={intl.messages['inputs.lastName'].toString()} disabled />
          </td>
          <td>
            <input className='select' placeholder={intl.messages['inputs.email'].toString()} disabled />
          </td>
          <td>
            <input className='select' placeholder={intl.messages['inputs.jobTitle'].toString()} disabled />
          </td>
          <td>
            <input className='select' placeholder={intl.messages['inputs.package'].toString()} disabled />
          </td>
          <td>
            <input className='select' placeholder={intl.messages['inputs.contractor'].toString()} disabled />
          </td>
          <td>
            <input className='select' placeholder={intl.messages['inputs.reportsTo'].toString()} disabled />
          </td>
          <td>
            <Switch defaultChecked disabled />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
