import { takeEvery, takeLatest, put, call, fork, all } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import {
  fetchSafetyPrograms,
  addSafetyProgram,
  fetchSafetyForms,
  addSafetyForm,
  updateSafetyForm,
  fetchFormQuestions,
  managePlansSortOrder,
  addFormQuestion,
  updateFormQuestion,
  removeFormQuestion,
  manageQuestionsSortOrder,
  manageFormsSortOrder,
  fetchSafetySubforms,
  fetchMultiQuestions,
  manageMultiQuestionsSortOrder,
  removeSafetyForm,
  removeSafetyProgram,
  manageMQQuestionsSortOrder,
  updateSafetySubforms,
} from './safety-programs.actions';

import {
  fetchService,
  addService,
  updateService,
  deleteService,
  patchService,
} from '@rdx/services.saga';
import { arrayToObject } from '@shared/helpers/state-caster';
import { FormType, QuestionType, SafetyPlanType } from '@shared/types/backend.types';

function* fetchSafetyProgramsRequest() {
  try {
    const endpoint = fetchSafetyPrograms.getEndpoint({});
    const response: SafetyPlanType[] = yield call(fetchService, endpoint);
    yield put(fetchSafetyPrograms.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(fetchSafetyPrograms.failure('Unable to fetch Safety Programs'));
    }
  }
}

function* addSafetyProgramRequest({ payload }: AnyAction) {
  try {
    const endpoint = addSafetyProgram.getEndpoint({});
    const response: SafetyPlanType = yield call(addService, endpoint, payload.body);
    yield put(addSafetyProgram.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(addSafetyProgram.failure('Unable to add Safety Program'));
    }
  }
}

function* removeSafetyProgramRequest({ payload }: AnyAction) {
  const { planID } = payload.params;

  try {
    const endpoint = removeSafetyProgram.getEndpoint({ planID });
    const response: SafetyPlanType = yield call(deleteService, endpoint);
    yield put(removeSafetyProgram.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(removeSafetyProgram.failure('Unable to remove Safety Program'));
    }
  }
}

function* managePlanSortOrderRequest({ payload }: AnyAction) {
  try {
    const manageSortOrderEndPoint = managePlansSortOrder.getEndpoint({
      planID: payload.params.planID,
    });
    const planEndPoint = fetchSafetyPrograms.getEndpoint({});

    yield call(patchService, manageSortOrderEndPoint, payload.body);
    const response: SafetyPlanType[] = yield call(fetchService, planEndPoint);
    yield put(fetchSafetyPrograms.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(managePlansSortOrder.failure('Unable to change sort order of safety plans'));
    }
  }
}

function* fetchSafetyFormsRequest({ payload }: AnyAction) {
  const { planID } = payload.params;

  try {
    const endpoint = fetchSafetyForms.getEndpoint({ planID });
    const response: FormType[] = yield call(fetchService, endpoint);
    yield put(fetchSafetyForms.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(fetchSafetyForms.failure('Unable to fetch Safety Forms'));
    }
  }
}

function* addSafetyFormRequest({ payload }: AnyAction) {
  const { planID } = payload.params;

  try {
    const addFormEndPoint = addSafetyForm.getEndpoint({ planID });
    const fetchFormsEndPoint = fetchSafetyForms.getEndpoint({ planID });

    yield call(addService, addFormEndPoint, payload.body);

    const response: FormType[] = yield call(fetchService, fetchFormsEndPoint);
    yield put(addSafetyForm.success());
    yield put(fetchSafetyForms.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(addSafetyForm.failure('Unable to add Safety Forms'));
    }
  }
}

function* updateSafetyFormRequest({ payload }: AnyAction) {
  const { formID, isParent } = payload.params;

  try {
    const endpoint = updateSafetyForm.getEndpoint({ formID });
    const response: FormType = yield call(updateService, endpoint, payload.body);

    if (isParent) yield put(updateSafetyForm.success(response));
    else yield put(updateSafetyForm.success(response.subForms.find((form) => form.id === formID)));
    
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(updateSafetyForm.failure('Unable to update Safety Forms'));
    }
  }
}

function* removeSafetyFormRequest({ payload }: AnyAction) {
  const { formID } = payload.params;

  try {
    const deleteFormEndpoint = removeSafetyForm.getEndpoint({ formID });
    const { safetyPlanID, parentID }: FormType = yield call(deleteService, deleteFormEndpoint, payload.body);

    const fetchFormsEndPoint = fetchSafetyForms.getEndpoint({ planID: safetyPlanID });

    if (parentID) {
      const fetchSubformsEndpoint = fetchSafetySubforms.getEndpoint({ formID: parentID });
      const response: FormType[] = yield call(fetchService, fetchSubformsEndpoint);
      yield put(fetchSafetySubforms.success(response));
    } else {
      const response: FormType[] = yield call(fetchService, fetchFormsEndPoint);
      yield put(removeSafetyForm.success());
      yield put(fetchSafetyForms.success(response));
    }

  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(updateSafetyForm.failure('Unable to remove Safety Form'));
    }
  }
}

function* manageFormSortOrderRequest({ payload }: AnyAction) {
  const { parentID } = payload.params;

  try {
    const manageSortOrderEndpoint = manageFormsSortOrder.getEndpoint({
      formID: payload.params.formID,
    });
    const formsEndpoint = parentID
      ? fetchSafetySubforms.getEndpoint({ formID: parentID })
      : fetchSafetyForms.getEndpoint({ planID: payload.params.planID });

    yield call(patchService, manageSortOrderEndpoint, payload.body);

    const response: FormType[] = yield call(fetchService, formsEndpoint);

    if (parentID) yield put(fetchSafetySubforms.success(response));
    else yield put(fetchSafetyForms.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(manageFormsSortOrder.failure('Unable to change sort order of safety form'));
    }
  }
}

function* fetchSafetySubFormsRequest({ payload }: AnyAction) {
  const { formID } = payload.params;

  try {
    const endpoint = fetchSafetySubforms.getEndpoint({ formID });
    const response: FormType[] = yield call(fetchService, endpoint);
    yield put(fetchSafetySubforms.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(fetchSafetySubforms.failure('Unable to fetch Safety Forms'));
    }
  }
}

function* fetchQuestionsRequest({ payload }: AnyAction) {
  const { formID } = payload.params;
  try {
    const endpoint = fetchFormQuestions.getEndpoint({ formID });
    const response: QuestionType[] = yield call(fetchService, endpoint);
    yield put(fetchFormQuestions.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(fetchFormQuestions.failure('Unable to fetch form questions'));
    }
  }
}

function* addQuestionRequest({ payload }: AnyAction) {
  const { planID, formID, id, isMultiQuestion } = payload.params;

  try {
    const endpoint = addFormQuestion.getEndpoint({ planID, formID });
    const response: QuestionType = yield call(addService, endpoint, payload.body);
    yield put(addFormQuestion.success(response));

    if (isMultiQuestion) {
      const endpoint = fetchMultiQuestions.getEndpoint({ formID: id });
      const response: any[] = yield call(fetchService, endpoint);
      yield put(fetchMultiQuestions.success(response));
    }
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(addFormQuestion.failure('Unable to add question in the form'));
    }
  }
}

function* updateQuestionRequest({ payload }: AnyAction) {
  const { planID, formID, questionID, parentID, isMultiQuestion } = payload.params;

  try {
    const updateQuesEndpoint = updateFormQuestion.getEndpoint({ planID, formID, questionID });
    const response: QuestionType = yield call(updateService, updateQuesEndpoint, payload.body);
    yield put(updateFormQuestion.success(response));

    if (isMultiQuestion) {
      const mqEndpoint = fetchMultiQuestions.getEndpoint({ formID: parentID });
      const response: any[] = yield call(fetchService, mqEndpoint);
      yield put(fetchMultiQuestions.success(response));
    }
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(updateFormQuestion.failure('Unable to update question in the form'));
    }
  }
}

function* removeQuestionRequest({ payload }: AnyAction) {
  const { formID, questionID, parentID, isMultiQuestion } = payload.params;

  try {
    const deleteQuestionEndpoint = removeFormQuestion.getEndpoint({ formID, questionID });

    yield call(deleteService, deleteQuestionEndpoint);
    yield put(removeFormQuestion.success());

    if (isMultiQuestion) {
      const endpoint = fetchMultiQuestions.getEndpoint({ formID: parentID });
      const response: any[] = yield call(fetchService, endpoint);
      yield put(fetchMultiQuestions.success(response));
    } else {
      const fetchQuestionsEndpoint = fetchFormQuestions.getEndpoint({ formID });
      const response: FormType[] = yield call(fetchService, fetchQuestionsEndpoint);
      yield put(fetchFormQuestions.success(response));
    }
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(removeFormQuestion.failure('Unable to remove question in the form'));
    }
  }
}

function* manageQuestionsSortOrderRequest({ payload }: AnyAction) {
  const { formID } = payload.params;

  try {
    const questionSortOrderEndpoint = manageQuestionsSortOrder.getEndpoint({ formID });

    const response: QuestionType[] = yield call(
      patchService,
      questionSortOrderEndpoint,
      payload.body,
    );
    yield put(fetchFormQuestions.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(manageQuestionsSortOrder.failure('Unable to change question sort order'));
    }
  }
}

function* manageMQQuestionsSortOrderRequest({ payload }: AnyAction) {
  const { formID, parentID } = payload.params;

  try {
    const mqEndpoint = fetchMultiQuestions.getEndpoint({ formID: parentID });
    const mqQuestionSortOrderEndpoint = manageMQQuestionsSortOrder.getEndpoint({ formID });

    yield call(patchService, mqQuestionSortOrderEndpoint, payload.body);
    const response: any[] = yield call(fetchService, mqEndpoint);
    yield put(fetchMultiQuestions.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(manageMQQuestionsSortOrder.failure('Unable to change question sort order'));
    }
  }
}

function* fetchMultiQuestionsRequest({ payload }: AnyAction) {
  const { formID } = payload.params;

  try {
    const endpoint = fetchMultiQuestions.getEndpoint({ formID });
    const response: any[] = yield call(fetchService, endpoint);
    yield put(fetchMultiQuestions.success(response));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(fetchFormQuestions.failure('Unable to fetch multi questions'));
    }
  }
}

function* manageMultiQuestionsSortOrderRequest({ payload }: AnyAction) {
  const { formID } = payload.params;

  try {
    const manageSortOrderEndpoint = manageMultiQuestionsSortOrder.getEndpoint();
    const fetchMultiQuestionEndpoint = fetchMultiQuestions.getEndpoint({ formID });

    yield call(patchService, manageSortOrderEndpoint, payload.body);
    const response: any[] = yield call(fetchService, fetchMultiQuestionEndpoint);
    const sortedResponse = response.sort((a, b) => a.mqSortOrder - b.mqSortOrder);
    yield put(fetchMultiQuestions.success(sortedResponse));
  } catch (error) {
    // @ts-ignore
    if (!error.wasCancelled) {
      yield put(fetchFormQuestions.failure('Unable to sort multi questions'));
    }
  }
}

export default function* SafetyProgramsSaga() {
  yield takeLatest(fetchSafetyPrograms.REQUEST, fetchSafetyProgramsRequest);
  yield takeLatest(addSafetyProgram.REQUEST, addSafetyProgramRequest);
  yield takeLatest(removeSafetyProgram.REQUEST, removeSafetyProgramRequest);
  yield takeLatest(managePlansSortOrder.REQUEST, managePlanSortOrderRequest);
  yield takeLatest(fetchSafetyForms.REQUEST, fetchSafetyFormsRequest);
  yield takeLatest(addSafetyForm.REQUEST, addSafetyFormRequest);
  yield takeLatest(updateSafetyForm.REQUEST, updateSafetyFormRequest);
  yield takeLatest(removeSafetyForm.REQUEST, removeSafetyFormRequest);
  yield takeLatest(manageFormsSortOrder.REQUEST, manageFormSortOrderRequest);
  yield takeLatest(fetchSafetySubforms.REQUEST, fetchSafetySubFormsRequest);
  yield takeLatest(fetchFormQuestions.REQUEST, fetchQuestionsRequest);
  yield takeLatest(addFormQuestion.REQUEST, addQuestionRequest);
  yield takeLatest(updateFormQuestion.REQUEST, updateQuestionRequest);
  yield takeLatest(removeFormQuestion.REQUEST, removeQuestionRequest);
  yield takeLatest(manageQuestionsSortOrder.REQUEST, manageQuestionsSortOrderRequest);
  yield takeLatest(manageMQQuestionsSortOrder.REQUEST, manageMQQuestionsSortOrderRequest);
  yield takeLatest(manageMultiQuestionsSortOrder.REQUEST, manageMultiQuestionsSortOrderRequest);
  yield takeLatest(fetchMultiQuestions.REQUEST, fetchMultiQuestionsRequest);
}
