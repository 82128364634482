import React from 'react';

export interface IEmptyRowProps {
  colspan: number;
  text: string;
}

export const EmptyRowComponent = (props: IEmptyRowProps) => {
  const { colspan, text } = props;
  return (
    <tr>
      <td colSpan={colspan}>
        <h2 className='text-center' style={{ padding: '50px 0px' }}>{text}</h2>
      </td>
    </tr>
  );
};
