import React, { useState, SetStateAction, useEffect } from 'react';

import { IAppState } from '@rdx/root.reducer';
import { QuestionType, FormType } from '@shared/types/backend.types';
import { ChangePlanFormsPayload } from '../forms.d';

import { connect } from 'react-redux';
import {
  getPlanForms,
  getPrograms,
  getCurrentSubstep,
  getProfessionalIsLoading,
  getFormQuestion,
  getSelectedFormQuestion,
} from '@app/back-office/professional.selector';

import { PlanFormsComponent } from '../view/forms.component';

import { changePlanFormsAction, fetchFormQuestionsAction } from './forms.actions';

export interface IPlanFormContainerProps {
  isLoading: boolean;
  forms: [];
  programForm: any;
  questions: QuestionType[];
  currentSubstep: number;
  selectedQuestion: any; //TODO: add question type
  changePlanFormsAction: (payload: ChangePlanFormsPayload) => void;
  fetchFormQuestionsAction: (payload: any) => void;
  handleChangeCurrentPlan: (nextStep: number, isFinished?: boolean) => void;
  handleValidateStep: (step: number, alternativeStatus: string, values?: string[]) => void;
}

const PlanForms = (props: IPlanFormContainerProps) => {
  const { isLoading, forms, questions, currentSubstep, programForm, selectedQuestion } = props;
  const { changePlanFormsAction, fetchFormQuestionsAction, handleValidateStep } = props;
  const [modalQuestionsInfo, setModalQuestionsInfo] = useState<SetStateAction<any>>({
    title: '',
    questions: [],
    isVisible: false,
  });
  const [modalRegulationsInfo, setModalRegulationsInfo] = useState<SetStateAction<any>>({
    title: '',
    regulations: [],
    isVisible: false,
  });

  useEffect(() => {
    if (questions.length > 0) {
      const { name } = selectedQuestion;
      setModalQuestionsInfo({ title: name, isVisible: true, questions });
    }
  }, [questions]);

  //TODO: add type
  const handleCheckedOptions = (checkedValues: any) => {
    changePlanFormsAction({ id: currentSubstep, checkedValues: checkedValues });
    handleValidateStep(currentSubstep, 'process', checkedValues);
  };

  const handleCheckedAll = (isCheckingAll: boolean) => {
    const checkedValues = isCheckingAll ? forms.map((form: FormType) => form.id) : [];

    changePlanFormsAction({ id: currentSubstep, checkedValues: checkedValues });
    handleValidateStep(currentSubstep, 'process', checkedValues);
  };

  const renderRegulationsModal = (question: QuestionType) => {
    setModalQuestionsInfo({ ...modalQuestionsInfo, isVisible: false });
    setModalRegulationsInfo({ regulations: question.regulations, isVisible: true, title: '' });
  };

  const renderQuestionsModal = (title: string, id: string) => {
    fetchFormQuestionsAction({ safetyplanID: currentSubstep, formID: id });
  };

  const totalCheckedForms = programForm.forms[currentSubstep]
    ? programForm.forms[currentSubstep].length
    : 0;

  return (
    <PlanFormsComponent
      isLoading={isLoading}
      forms={forms}
      checkedForms={programForm.forms[currentSubstep] || []}
      areAllChecked={forms.length === totalCheckedForms}
      modalQuestionsInfo={modalQuestionsInfo}
      modalRegulationsInfo={modalRegulationsInfo}
      setModalQuestionsInfo={setModalQuestionsInfo}
      setModalRegulationsInfo={setModalRegulationsInfo}
      handleCheckedOptions={handleCheckedOptions}
      handleCheckAll={handleCheckedAll}
      renderQuestionsModal={renderQuestionsModal}
      renderRegulationsModal={renderRegulationsModal}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  isLoading: getProfessionalIsLoading(state),
  forms: getPlanForms(state),
  programForm: getPrograms(state),
  questions: getFormQuestion(state),
  currentSubstep: getCurrentSubstep(state),
  selectedQuestion: getSelectedFormQuestion(state),
});

const mapDispatchToProps = {
  changePlanFormsAction: changePlanFormsAction.trigger,
  fetchFormQuestionsAction: fetchFormQuestionsAction.request,
};

export const PlanFormsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanForms);
