import React, { FormEvent } from 'react';

import { WrappedFormUtils } from "antd/es/form/Form";


import { IAppState } from '@rdx/root.reducer';
import { AccountType, PackageSeatDetail, UserType } from '@shared/types/backend.types';

import { connect } from 'react-redux';

import { AccountsInfoComponent } from '../view/info.component';
import { patchAccountIsActivatedAction } from '../../state/accounts.actions';
import { fetchUserCertificatesAction } from '../../team/state/team.actions';
import { getSessionUser } from '@app/session/session.selector';
import { getAccountsTeam } from "@app/admin/accounts/team/state/team.selector";


interface IAccountsInfoProps {
  user: any;
  adminsTree?: any;
  team: UserType[];
  isTreeLoading?: boolean;
  companyInformation: AccountType;
  seatsDetails: PackageSeatDetail[];
  handleToggleModal: (isUpdating: boolean) => void;
  handleToogleAccountActivate?: (isActivated: boolean, company: AccountType) => void;
  patchAccountActivateAction: (payload: any) => void;
  fetchUserCertificates: (selectedUserID: string) => void;
  toggleCheckBoxChange: (value: boolean) => void
}

const AccountsInfo = (props: IAccountsInfoProps) => {
  const { adminsTree, isTreeLoading, companyInformation, seatsDetails, user, team, } = props;
  const { handleToggleModal, handleToogleAccountActivate, fetchUserCertificates, patchAccountActivateAction, toggleCheckBoxChange } = props;

  const handleOnClickTreeNode = (selectedUserId: string | undefined) => {
    if (selectedUserId !== undefined) {
      fetchUserCertificates(selectedUserId);
    }
  }

  const handleSaveReportConfig = (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => {
    event.preventDefault();

    form.validateFieldsAndScroll((err: string, formValues: any) => {
      patchAccountActivateAction({ powerBIConfig: formValues, accountID: companyInformation.accountID });
    })
  }


  return (
    <AccountsInfoComponent
      user={user}
      team={team}
      isTreeLoading={isTreeLoading}
      adminsTree={adminsTree}
      companyInformation={companyInformation}
      toggleCheckBoxChange={toggleCheckBoxChange}
      handleSaveReportConfig={handleSaveReportConfig}
      handleToggleModal={handleToggleModal}
      handleToogleAccountActivate={handleToogleAccountActivate}
      handleOnClickTreeNode={handleOnClickTreeNode}
      seatsDetails={seatsDetails}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  team: getAccountsTeam(state),
  user: getSessionUser(state),
});

const mapDispatchToProps = {
  fetchUserCertificates: fetchUserCertificatesAction.request,
  patchAccountActivateAction: patchAccountIsActivatedAction.request,
};

export const AccountsInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsInfo);
