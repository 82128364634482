import React, { FormEvent } from 'react';

import { WrappedFormUtils } from 'antd/es/form/Form';
import { ThemeType } from '@shared-types/theme.type';
import { ErrorMessage } from '@shared/types/utils.types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Classes } from 'jss';

import { Link } from 'react-router-dom';

import { Form, Input, Checkbox } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { ButtonControlComponent } from '@controls/button/button-control.component';

import injectSheet from 'react-jss';
import classnames from 'classnames';
import { getUserSession } from '@services/storage/session.service';
import emailIcon from '@assets/icon/email-icon.svg';
import lockIcon from '@assets/icon/lock-icon.svg';
import '@session/session.styles.scss';
import '@controls/input.styles.css';
import './login.styles.scss';

export interface ILoginFormProps {
  isFormSubmitting: boolean;
  sessionErrorMessages: {
    [key: string]: ErrorMessage;
  };
  classes: Classes;
  form: WrappedFormUtils;
  handleSubmit: (event: FormEvent<HTMLFormElement>, form: any) => void;
  handleStorePassword: (e: CheckboxChangeEvent) => void;
}

const LoginForm = (props: ILoginFormProps) => {
  const { sessionErrorMessages, isFormSubmitting, classes, form } = props;
  const { handleSubmit, handleStorePassword } = props;
  const {
    titleStyles,
    formLabelStyles,
    checkboxStyles,
    forgotPasswordStyles,
    sessionPStyles,
    signinButtonStyles,
  } = classes;
  const { getFieldDecorator } = form;
  const intlProvider = useIntl();
  const storedSession: any = getUserSession();

  return (
    <div className="session__container">
      <div className="session__form-box">
        <h1 className={classnames(titleStyles, 'login__title')}>
          {intlProvider.messages['signin.welcome']}
        </h1>
        <p className={classnames(sessionPStyles, 'session__p')}>
          {intlProvider.messages['signin.instruction']}
        </p>
        <Form
          layout="vertical"
          hideRequiredMark
          onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event, form)}
        >
          <Form.Item
            className={classnames('input--large-width login__form-label', formLabelStyles)}
          >
            {getFieldDecorator('email', {
              initialValue: storedSession ? storedSession.email : '',
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: intlProvider.messages['inputs.requiredEmailAddress'],
                },
              ],
              validateTrigger: 'onBlur',
            })(
              <Input
                className="input--medium-height"
                placeholder={intlProvider.messages['inputs.email'].toString()}
                type="email"
                prefix={<img src={emailIcon} width="18" alt="Email" />}
              />,
            )}
          </Form.Item>
          <Form.Item
            className={classnames('input--large-width login__form-label', formLabelStyles)}
            style={{ marginBottom: '0' }}
          >
            {getFieldDecorator('password', {
              initialValue: storedSession ? storedSession.password : '',
              rules: [
                {
                  required: true,
                  message: intlProvider.messages['inputs.requiredPassword'],
                },
              ],
            })(
              //@ts-ignore
              <Input.Password
                className="input--medium-height"
                placeholder={intlProvider.messages['inputs.password'].toString()}
                prefix={<img src={lockIcon} width="15" alt="Password" />}
              />,
            )}
          </Form.Item>
          <span
            className={
              sessionErrorMessages ? 'login__error-message' : 'login__error-message opacity-0'
            }
          >
            <FormattedMessage id="inputs.wrongPassword" />
          </span>
          <div className="login__session-options">
            <Checkbox
              className={classnames(checkboxStyles, 'login__remember')}
              onChange={handleStorePassword}
              indeterminate={true}
            >
              {intlProvider.messages['inputs.rememberPassword']}
            </Checkbox>
            <Link
              to="/session/restore-email"
              className={classnames(forgotPasswordStyles, 'login__forgot-password')}
            >
              <FormattedMessage id="inputs.forgotPassword" />
            </Link>
          </div>
          <Form.Item className={classnames(signinButtonStyles, 'login__email-link')}>
            <ButtonControlComponent
              text={intlProvider.messages['buttons.login'].toString()}
              size="extraLarge"
              color="green"
              htmlType="submit"
              loading={isFormSubmitting}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const styles = (theme: ThemeType) => ({
  titleStyles: {
    color: theme.black,
  },
  formLabelStyles: {
    color: theme.grey.main,
    fontSize: '18px',
  },
  checkboxStyles: {
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: theme.green.fourth,
      fontSize: '18px',
    },
    '& span': {
      fontSize: '18px',
      color: theme.grey.questionMark,
    },
  },
  forgotPasswordStyles: {
    color: theme.green.fourth,
  },
  sessionPStyles: {
    color: theme.grey.fourth,
  },
  signinButtonStyles: {
    '& span': {
      fontSize: '16px',
      fontWeight: 'lighter',
    },
  },
});

const LoginFormStyled = injectSheet(styles)(LoginForm);
export const LoginFormComponent: any = Form.create({ name: 'login' })(LoginFormStyled);
