import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  FETCH_ACCOUNTS_API,
  FETCH_SEATSINFO_API,
  ADD_COMPANY_ACCOUNT_API,
  UPDATE_COMPANY_ACCOUNT_API,
  PATCH_ACCOUNT_INFO,
  ADD_PACKAGE_API,
  UPDATE_PACKAGE_API,
  USER_CONFIG_API
} from '@constants/endpoints';

export const FETCH_ACCOUNTS = 'FETCH_ACCCOUNTS';
export const FETCH_SEATSINFO = 'FETCH_SEATSINFO';

export const CREATE_COMPANY_ACCOUNT = 'CREATE_COMPANY_ACCOUNT';
export const EDIT_COMPANY_ACCOUNT = 'EDIT_COMPANY_ACCOUNT';
export const FETCH_ACCOUNTS_ROLES = 'FETCH_ACCOUNTS_ROLES';
export const PATCH_ACCOUNT_IS_ACTIVATED = 'PATCH_ACCOUNT_IS_ACTIVATED';

export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const EDIT_PACKAGE = 'EDIT_PACKAGE';
export const SELECTED_COMPANY_IN_ACCOUNTS = 'SELECTED_COMPANY_IN_ACCOUNTS'

export const FECTH_USERS_CONFIG = 'FECTH_USERS_CONFIG';
export const UPDATE_USER_CONFIG = 'UPDATE_USER_CONFIG';

export const ADD_PROXY_USER = 'ADD_PROXY_USER';



export const fetchAccountsAction = createActionRoutine(FETCH_ACCOUNTS, FETCH_ACCOUNTS_API);
export const fetchSeatsInfoAction = createActionRoutine(FETCH_SEATSINFO, FETCH_SEATSINFO_API);
export const addProxyUserAction = createActionRoutine(FETCH_SEATSINFO, FETCH_SEATSINFO_API);
export const createCompanyAction = createActionRoutine(CREATE_COMPANY_ACCOUNT, ADD_COMPANY_ACCOUNT_API);
export const selectedCompanyInAccounts = createActionRoutine(SELECTED_COMPANY_IN_ACCOUNTS);
export const updateCompanyAccountAction = createActionRoutine(EDIT_COMPANY_ACCOUNT, UPDATE_COMPANY_ACCOUNT_API);
export const createPackageAction = createActionRoutine(CREATE_PACKAGE, ADD_PACKAGE_API);
export const updatePackageAction = createActionRoutine(EDIT_PACKAGE, UPDATE_PACKAGE_API);
export const patchAccountIsActivatedAction = createActionRoutine(PATCH_ACCOUNT_IS_ACTIVATED, PATCH_ACCOUNT_INFO)
export const fecthUsersConfigAction = createActionRoutine(FECTH_USERS_CONFIG, USER_CONFIG_API);
export const updateUsersConfigAction = createActionRoutine(UPDATE_USER_CONFIG, USER_CONFIG_API);