import React, { useState, FormEvent } from 'react';

import { WrappedFormUtils } from "antd/es/form/Form";

import { AccountType, PackageSeatDetail, UserType } from '@shared/types/backend.types';

import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';

import { Tooltip, Popconfirm, Switch, Modal, Button, Tree, message, Collapse, Icon, Divider, Spin, Empty } from 'antd';

import '@utils/styles/width.css';
import './info.styles.scss';

import { ReportsPlanModal } from './report-config.modal';
import { AntTreeNode } from 'antd/lib/tree';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import AdminGuideComponent from '@app/back-office/team/view/admin-guide/admin-guide.component';

const { Panel } = Collapse;

interface IAccountsInfoProps {
  user?: UserType;
  team: UserType[];
  adminsTree?: any
  isTreeLoading?: boolean;
  companyInformation: AccountType;
  seatsDetails: PackageSeatDetail[];
  handleToggleModal: (isUpdating: boolean) => void;
  handleToogleAccountActivate?: (isActivated: boolean, company: AccountType) => void;
  handleSaveReportConfig?: (event: FormEvent<HTMLFormElement>, form: WrappedFormUtils) => void;
  handleOnClickTreeNode?: (selectedUserId: string | undefined) => void;
  toggleCheckBoxChange: (value: boolean) => void
  handleDeleteCompanyLogo?: (accountID: number) => void;
}



export const AccountsInfoComponent = (props: IAccountsInfoProps) => {
  const { adminsTree, isTreeLoading, companyInformation, user, team } = props;
  const { handleToggleModal, handleToogleAccountActivate, handleDeleteCompanyLogo, handleOnClickTreeNode, handleSaveReportConfig, toggleCheckBoxChange } = props;

  const [companyKeysModal, setCompanyKeysModal] = useState(false);
  const [associateskeysModal, setAssociatesKeysModal] = useState(false);
  const [reportStatusModal, setReportStatusModal] = useState(false);
  const [targetUserID, setTargetUserID] = useState<string | undefined>(undefined);
  const [openProfileModal, setProfileModal] = useState(false);
  const [openAdminGuideModal, toggleAdminGuideModal] = useState(false);

  return (
    <div className="info md-w-100">
      <div className="info__heading sa__box-heading">
        <div style={{ display: 'flex' }}>
          <h4 className="sa__box-heading-title">Company Information</h4>
          {
            user && (user.user_metadata.reportsToUserID === '' || user.app_metadata.role.roleID === 3) ? (
              <div className='info__heading-icon'>
                <Tooltip title="Edit Account">
                  <i className="fa fa-edit" onClick={() => handleToggleModal(true)} />
                </Tooltip>
              </div>
            ) : null
          }
        </div>
        {
          user && user.app_metadata.role.roleID === 2 &&
          <>
            <a className='user-guide' onClick={() => toggleAdminGuideModal(true)}>User Guide {`>>`}</a>
            <AdminGuideComponent
              showModal={openAdminGuideModal}
              toggleModal={toggleAdminGuideModal}
            />
          </>
        }

      </div>


      <div className='info__content sa__box-content'>

        <div className='info__content-container'>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <Collapse
              bordered={false}
              className="sa-ant-collapse"
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <Icon type={isActive ? 'minus-square' : 'plus-square'} />}
            >
              <Panel header={<b>{`${user && user.app_metadata.role.roleID === 2 && !user.contractorCompany ? 'Your Company Information' : `Owner's Company Information`}`}</b>} key="1">

                <table className='sa-info-table'>
                  <tbody>
                    <tr>
                      <td>Company Name</td>
                      <td>
                        <span>{companyInformation ? companyInformation.name : <b>N/A</b>}</span>
                      </td>
                    </tr>

                    <tr>
                      <td>Company Address</td>
                      <td>
                        <span>{companyInformation ? companyInformation.address : <b>N/A</b>}</span>
                      </td>
                    </tr>

                    <tr>
                      <td>Contact Name</td>
                      <td>
                        <span>{companyInformation ? companyInformation.firstName + ' ' + companyInformation.lastName : <b>N/A</b>}</span>
                      </td>
                    </tr>

                    <tr>
                      <td>Contact Email</td>
                      <td>
                        <span>{companyInformation ? companyInformation.contactEmail : <b>N/A</b>}</span>
                      </td>
                    </tr>

                    <tr>
                      <td>Contact Phone</td>
                      <td>
                        <span>{companyInformation ? companyInformation.contactPhone : <b>N/A</b>}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </Panel>
            </Collapse>

            {
              user && user.app_metadata.role.roleID === 2 ?
                (
                  <>
                    {companyInformation !== undefined && companyInformation.logoUri && handleDeleteCompanyLogo && (
                      <div className="logo-container">
                        <img
                          src={companyInformation.logoUri}
                          className="company-logo"
                          alt={companyInformation.name}
                        />
                        <div className="overlay"></div>
                        <div
                          className="delete-icon"
                        >
                          <Popconfirm
                            placement="top"
                            title="Are you sure you want to delete the company logo?"
                            onConfirm={() => handleDeleteCompanyLogo(companyInformation.accountID)}
                            okText="Yes"
                          >
                            <i
                              className="far fa-trash-alt active-icon"
                              style={{
                                marginLeft: 5,
                              }}
                            ></i>
                          </Popconfirm>
                        </div>
                      </div>
                    )}
                  </>
                )
                : null
            }
          </div>

          {
            user && user.contractorCompany ? (
              <Divider className='sa-divider' />
            )
              : null
          }

          {user && user.contractorCompany ? (
            <div className='d-flex'>
              <Collapse
                bordered={false}
                className="sa-ant-collapse"
                expandIcon={({ isActive }) => <Icon type={isActive ? 'minus-square' : 'plus-square'} />}
              >
                <Panel header={<><b>Your Company Information</b></>} key="1">
                  <table className='sa-info-table'>
                    <tbody>
                      <tr>
                        <td>Company Name</td>
                        <td>
                          <span>{user.contractorCompany ? user.contractorCompany.companyName : <b>N/A</b>}</span>
                        </td>
                      </tr>

                      <tr>
                        <td>Company Address</td>
                        <td>
                          <span>{user.contractorCompany ? user.contractorCompany.companyAddress : <b>N/A</b>}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Panel>
              </Collapse>
            </div>
          )
            : null}

        </div>

        {user && user.app_metadata.role.roleID === 3 ?
          (
            <>
              <div className="info__content-container lg-w-50 sm-w-100">

                <div className='d-flex'>
                  <h3>Company Status</h3>
                  {companyInformation && handleToogleAccountActivate && (
                    <div style={{ marginLeft: 20, marginTop: 2 }}>
                      <Popconfirm
                        title={
                          companyInformation.isActive
                            ? 'Are you sure you want to deactivate this account?'
                            : 'Are you sure you want to activate this account?'
                        }
                        onConfirm={() => handleToogleAccountActivate(!companyInformation.isActive, companyInformation)}
                        okText="Yes"
                      >
                        <Switch
                          checked={companyInformation.isActive}
                          size="small"
                        />
                      </Popconfirm>
                    </div>
                  )}
                </div>

                <div className='d-flex'>
                  <h3>Power BI Reports Plan: {companyInformation && companyInformation.isPowerBIReportFree ? `Free` : `Paid`}</h3>
                  <div style={{ marginLeft: 10, marginTop: 2 }}>
                    <Tooltip title="Change PowerBI Reports Plan">
                      <i className="fa fa-edit" onClick={() => setReportStatusModal(true)} />
                    </Tooltip>
                  </div>

                  <ReportsPlanModal modalState={reportStatusModal} companyInformation={companyInformation} setState={setReportStatusModal} handleSaveReportConfig={handleSaveReportConfig} />

                </div>

                <div className='d-flex'>
                  <h3>Company API & PowerBI Key</h3>
                  <div style={{ marginLeft: 20, marginTop: 3 }}>
                    <Tooltip title="Show Company Key">
                      <i className="fa fa-key" onClick={() => setCompanyKeysModal(true)} />
                    </Tooltip>
                  </div>

                  <Modal
                    visible={companyKeysModal}
                    title="Company API & PowerBI Key"
                    className='companykeymodal'
                    onCancel={() => setCompanyKeysModal(false)}
                    footer={<Button className='ant-modal-btn-close' type='primary' onClick={() => setCompanyKeysModal(false)}>Close</Button>}
                  >
                    {companyInformation && (
                      <table className='sa-table-textLeft'>
                        <tbody>
                          <tr>
                            <td>API Key</td>
                            <td >
                              <div className='d-flex'>
                                <span >
                                  {companyInformation.apiKey}
                                </span>
                                <Tooltip title="Copy API Key">
                                  <i className="fa fa-clipboard apikeycopy" onClick={() => {
                                    navigator.clipboard.writeText(companyInformation.apiKey as string)
                                    message.success("Api Key Copied")
                                  }} />
                                </Tooltip>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Submission PowerBI Key</td>
                            {
                              companyInformation.powerBIKey ? (

                                <td >
                                  <div className='d-flex'>
                                    <span className='cuttext'>
                                      {companyInformation.powerBIKey}
                                    </span>

                                    <Tooltip title="Copy PowerBI Key">
                                      <i className="fa fa-clipboard apikeycopy" onClick={() => {
                                        navigator.clipboard.writeText(companyInformation.powerBIKey as string)
                                        message.success("PowerBI Key Copied")
                                      }} />
                                    </Tooltip>
                                  </div>
                                </td>
                              )
                                : <td><b>N/A</b></td>
                            }
                          </tr>

                          <tr>
                            <td>Corrective Action PowerBI Key</td>
                            {
                              companyInformation.caPowerBIkey ? (

                                <td >
                                  <div className='d-flex'>
                                    <span className='cuttext'>
                                      {companyInformation.caPowerBIkey}
                                    </span>

                                    <Tooltip title="Copy Corrective Action PowerBI Key">
                                      <i className="fa fa-clipboard apikeycopy" onClick={() => {
                                        navigator.clipboard.writeText(companyInformation.caPowerBIkey as string)
                                        message.success("Corrective Action PowerBI Key Copied")
                                      }} />
                                    </Tooltip>
                                  </div>
                                </td>
                              )
                                : <td><b>N/A</b></td>
                            }
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </Modal>
                </div>


                <div className='d-flex'>
                  <h3>Administrators API {companyInformation && companyInformation.isPowerBIReportFree ? '& PowerBI' : null}Key</h3>
                  <div style={{ marginLeft: 20, marginTop: 3 }}>
                    <Tooltip title="Show Associates Key">
                      <i className="fa fa-key" onClick={() => setAssociatesKeysModal(true)} />
                    </Tooltip>
                  </div>

                  <Modal
                    visible={associateskeysModal}
                    title={`Administrators API ${companyInformation && companyInformation.isPowerBIReportFree ? '& PowerBI' : ''}Key`}
                    className='sa-tree'
                    onCancel={() => setAssociatesKeysModal(false)}
                    footer={<Button className='ant-modal-btn-close' type='primary' onClick={() => setAssociatesKeysModal(false)}>Close</Button>}
                  >
                    <div className='sa-modal-margin'>

                      {
                        isTreeLoading ? (
                          <div className='flex justify-center' style={{ padding: '50px 0px' }}><Spin /></div>
                        ) :
                          adminsTree.length === 0 ? (
                            <Empty description={"No Users are added to the company"} />
                          )
                            : (
                              <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Checkbox onChange={(value: CheckboxChangeEvent) => toggleCheckBoxChange(value.target.checked)}>
                                    <b>Include Inactive</b>
                                  </Checkbox>
                                </div>
                                <Tree
                                  defaultExpandAll
                                  showLine={true}
                                  onClick={(event: any, node: AntTreeNode) => {
                                    setTargetUserID(node.props.eventKey)
                                    handleOnClickTreeNode && handleOnClickTreeNode(node.props.eventKey)
                                    setProfileModal(true);
                                  }}
                                  selectable={false}
                                  multiple
                                >
                                  {adminsTree}
                                </Tree>
                              </>
                            )
                      }
                    </div>
                  </Modal>

                  <UserCertificateContainer
                    team={team}
                    open={openProfileModal}
                    setOpen={setProfileModal}
                    targetUserID={targetUserID}
                    usage="tree"
                    site='admin'
                    defaultTabOpen='3'
                  />
                </div>
              </div>
            </>
          )
          : null}
      </div >
    </div >
  );
};
