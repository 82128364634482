import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  UPDATE_USER_API,
  DELETE_USER_API,
  BLOCK_USERS_API,
  FETCH_USER_TEAM_API,
} from '@constants/endpoints';

export const FETCH_TEAM = 'FETCH_TEAM';
export const FETCH_USER_TEAM = 'FETCH_USER_TEAM';
export const ADD_SUPERVISOR_INVITE = 'ADD_SUPERVISOR_INVITE';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const REMOVE_ACCOUNT_USER = 'REMOVE_ACCOUNT_USER';
export const CHANGE_SETUP_SHOW_USER = 'CHANGE_SETUP_SHOW_USER';
export const TOGGLE_SP_TEAM_SORTER = 'TOGGLE_SP_TEAM_SORTER';
const BLOCK_BACKOFFICE_USER = 'BLOCK_BACKOFFICE_USER';
const CLEAR_ERROR_MESSAGES = 'CLEAR_ERROR_MESSAGES';

export const fetchTeamAction = createActionRoutine(FETCH_TEAM);
export const fetchUserTeamAction = createActionRoutine(FETCH_USER_TEAM, FETCH_USER_TEAM_API);
export const blockBackOfficeUserAction = createActionRoutine(
  BLOCK_BACKOFFICE_USER,
  BLOCK_USERS_API,
);
export const updateUserInfoAction = createActionRoutine(UPDATE_USER_INFO, UPDATE_USER_API);
export const removeAccountUserAction = createActionRoutine(REMOVE_ACCOUNT_USER, DELETE_USER_API);
export const changeSetUserAction = createActionRoutine(CHANGE_SETUP_SHOW_USER);
export const clearErrorMessages = createActionRoutine(CLEAR_ERROR_MESSAGES);
export const toggleSPUserAction = createActionRoutine(TOGGLE_SP_TEAM_SORTER);
