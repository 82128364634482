import { REGEX } from "@constants/regex";


export const handleValidateToNextPassword = (
    form: any,
    rule: any,
    value: string,
    callback: (message?: string) => {},
    errormsg?: string,
) => {
    const passwordStrength = new RegExp(REGEX.passwordStrength);
    const isStrong = passwordStrength.test(value);

    if (isStrong && value.length >= 6) {
        if (value) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    } else if (!isStrong || value !== '') {
        callback(errormsg);
    } else {
        callback();
    }
}

export const handleCompareToFirstPassword = (
    form: any,
    rule: any,
    value: string,
    callback: (message?: string) => {},
    errormsg?: string,

) => {
    if (value && value !== form.getFieldValue('password')) {
        callback(errormsg);
    } else {
        callback();
    }
};