import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';

export const _getProjectSubmissions = (state: IAppState) =>
  state.professional.projectSubmissions.items;

export const getProjectSubmissions = createSelector(
  [_getProjectSubmissions],
  (submissions): any[] => getList(submissions),
);

export const _getSelectedProjectSubmission = (state: IAppState) =>
  state.professional.projectSubmissions.selectedFormID;

export const getSelectedProjectSubmission = createSelector(
  _getSelectedProjectSubmission,
  _getProjectSubmissions,
  (selectedSubmission, submissions) => submissions[selectedSubmission || ''],
);

export const _getProjectSubmissionsLoading = (state: IAppState) =>
  state.professional.projectSubmissions.isLoading;

export const getProjectSubmissionsLoading = createSelector(
  [_getProjectSubmissionsLoading],
  isLoading => isLoading,
);

export const _getSubmissionsSelectedProjectID = (state: IAppState) =>
  state.professional.projectSubmissions.selectedProjectID;

export const getSubmissionsSelectedProjectID = createSelector(
  [_getSubmissionsSelectedProjectID],
  selectedProjectID => selectedProjectID,
);

export const _getSubmissionsSelectedPDF = (state: IAppState) =>
  state.professional.projectSubmissions.formPDF;

export const getSubmissionsSelectedPDF = createSelector(
  [_getSubmissionsSelectedPDF],
  formPDF => formPDF,
);

export const _getIsLoadingSubmissionPDF = (state: IAppState) =>
  state.professional.projectSubmissions.isLoadingPDF;

export const getIsLoadingSubmissionPDF = createSelector(
  [_getIsLoadingSubmissionPDF],
  isLoading => isLoading,
);
