import React, { useState, useEffect, SetStateAction, ChangeEvent, useMemo } from 'react';

import { IAppState } from '@rdx/root.reducer';
import {
  JobTitleType,
  UserType,
  UserMetaData,
  AccountType,
  RoleType,
  PackageSeatDetail,
} from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { AntdSelectTarget, ChangeSetUserPayload } from '../team.d';
import { RemoveUserPayload } from '@app/admin/accounts/team/team';
import { Sorter, ErrorMessage } from '@shared/types/utils.types';
import { IFileWithMeta, IMeta, StatusValue } from 'react-dropzone-uploader';

import _ from 'lodash';

import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  updateUserInfoAction,
  toggleSPUserAction,
  blockBackOfficeUserAction,
  fetchUserTeamAction,
} from './team.actions';
import {
  fetchAccountJobTitlesAction,
  removeBackofficeErrorAction,
} from '@app/back-office/professional.action';
import { fetchTeamAction, changeSetUserAction } from './team.actions';

import {
  blockUsersAction,
  fetchUserCertificatesAction,
} from '@app/admin/accounts/team/state/team.actions';

import {
  uploadImageCompanyAction,
  deleteAccountLogoAction,
} from '../../programs/creation/state/creation.actions';
import {
  fetchSeatsInfoAction,
  updateCompanyAccountAction,
} from '../../../admin/accounts/state/accounts.actions';
import { fetchCompaniesAction } from '@admin/admin.action';
import { getSeatsInfoCompany, getSessionUser, getUserCompany } from '@app/session/session.selector';
import {
  getHasSentInvite,
  getCachedSupervisorID,
  getJobTitles,
  getProfessionalErrors,
} from '@app/back-office/professional.selector';
import {
  getTeam,
  getTeamDictionary,
  getTeamSorter,
  getTeamIsLoading,
  getTeamIsSendingInvite,
  getUserTeam,
  getTeamAccessUserID,
} from './team.selector';

import { resendUserInviteAction } from '@app/admin/accounts/team/state/team.actions';

import { ContractorsLockedContainer } from '@app/admin/accounts/contractors/state/contractors.container';

import { TeamTableLockedComponent } from '../view/team.component';
import { HeadingComponent } from '@components/layout/header/heading.component';
import { openNotificationWithIcon } from '@components/controls/notification/notification.component';
import { AccountsInfoComponent } from '../../../admin/accounts/info/view/info.component';
import { AddAccountComponent } from '@components/admin/add-account/add-account.component';
import { UserCertificateContainer } from '@components/admin/user-certificates/user-certificates.container';

import { removeAccountUserAction } from './team.actions';

import { invertedOrder } from '@constants/sort';
import { STATUS } from '@constants/status';
import { ROLE } from '@constants/roles';
import { IMAGE_COMPANY_RESTRICTIONS } from '@constants/upload';
import { UpdateAccountPayload } from '../../../admin/accounts/accounts';

import { fetchContractorsAction } from '../../../admin/accounts/contractors/state/contractors.actions';
import { getAccountsContractors } from '../../../admin/accounts/contractors/state/contractors.selector';

import { getUnderUsers } from '@shared/utils/filterSubUsers';

import '@shared/components/back-office/team/supervisors.styles.scss';
import '@shared/components/back-office/team/team.styles.scss';
import '../view/team.styles.scss';

import { REGEX } from '@constants/regex';

import { uploadImageServiceUtil } from '@shared/utils/imageConverterBase64';
import { Modal, Tree, Button, Checkbox, Table } from 'antd';
import { TableLoadingSpinnerComponent } from '@components/controls/loading/table-spinner.component';
import Swal from 'sweetalert2';
import { baseTheme } from '@shared/themes/presets/base.theme';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AntTreeNode } from 'antd/lib/tree';
import { setTeamAccessUserIdAction } from '@app/session/session.action';
import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';

const { TreeNode } = Tree;

interface ITeamLocked {
  loggedUser?: UserType;
  userTeam: UserType[];
  loggedUserProxyUsers: UserType[];
  accountTeam: UserType[];
  teamAccessUserID?: string;
  teamSorter: Sorter;
  companyContractors: [];
  jobTitles: [];
  errorMessages: {
    [key: string]: ErrorMessage | undefined;
  };
  isLoading: boolean;
  company?: AccountType;
  seatsInfo?: PackageSeatDetail[];
  fetchUserTeamAction: (userID: string) => void;
  fetchJobTitlesAction: (accountID: number) => void;
  updateUserInfoAction: (payload: any) => void;
  changeSetUserAction: (payload: ChangeSetUserPayload) => void;
  toggleSorterAction: (payload: Sorter) => void;
  removeErrorAction: (code: any) => void;
  resetTeamAction: () => void;
  resetJobTitlesAction: () => void;
  changeImageStatusAction: (status: boolean) => void;
  uploadImageAction: (file: any) => void;
  updateCompanyAction: (payload: UpdateAccountPayload) => void;
  fetchCompaniesAction: () => void;
  removeAccountUserAction: (payload: any) => void;
  deleteAccountLogoAction: (payload: any) => void;
  fetchSeatsInfo: (accountID: number) => void;
  resendUserInviteAction: (selectedUserID: string) => void;
  fetchContractorsAction: (AccountID: any) => void;
  fetchUserCertificates: (selectedUserID: string) => void;
  setTeamAccessUserIdAction: (userID: string) => void;
}

const TeamLocked = (props: ITeamLocked) => {
  const {
    loggedUser,
    userTeam,
    accountTeam,
    company,
    teamAccessUserID,
    companyContractors,
    teamSorter,
    jobTitles,
    isLoading,
    errorMessages,
    seatsInfo,
  } = props;

  const accountID = loggedUser ? loggedUser.user_metadata.accountID : undefined;

  const {
    fetchUserTeamAction,
    fetchJobTitlesAction,
    updateUserInfoAction,
    fetchContractorsAction,
    removeAccountUserAction,
    removeErrorAction,
    resetTeamAction,
    resetJobTitlesAction,
    updateCompanyAction,
    fetchCompaniesAction,
    deleteAccountLogoAction,
    fetchSeatsInfo,
    resendUserInviteAction,
    fetchUserCertificates,
    setTeamAccessUserIdAction,
  } = props;
  const intlProvider = useIntl();
  const [modifiedUsers, setModifiedUsers] = useState<SetStateAction<any>>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageValidation, setImageValidation] = useState<SetStateAction<any>>({
    isValid: null,
    message: null,
  });
  const [imageWithMeta, setImageWithMeta] = useState<SetStateAction<any>>({});
  const [companyInfo, setCompanyInfo] = useState<SetStateAction<any>>({});
  const [seatsDetails, getSeatsDetails] = useState<SetStateAction<any>>({});
  const [showModal, setShowModal] = useState(false);
  const [sorter, setSorter] = useState<Sorter>({ key: 'tier', order: 'asc' });
  const [targetUserID, setTargetUserID] = useState<string | undefined>(undefined);
  const [openProfileModal, setProfileModal] = useState(false);
  const [teamFilter, setTeamFilter] = useState<string>('active');
  const [filteredTeam, setFilteredTeam] = useState<UserType[]>([]);
  const [showPopupModal, setPopupModal] = useState<boolean>(false);
  const [treeNodes, setTreeNodes] = useState<any>();
  const nonProxyUsers = useMemo(() => {
    return accountTeam.filter(user => !user.user_metadata.isProxyUser);
  }, [accountTeam]);

  useEffect(() => {
    if (accountID) {
      fetchContractorsAction(accountID);
      fetchJobTitlesAction(accountID);
      fetchSeatsInfo(accountID);
    }

    return () => {
      resetJobTitlesAction();
    };
  }, [fetchTeamAction, accountID, fetchJobTitlesAction, resetJobTitlesAction, resetTeamAction]);

  useEffect(() => {
    if (teamAccessUserID) fetchUserTeamAction(teamAccessUserID);
  }, [teamAccessUserID]);

  useEffect(() => {
    if (loggedUser) {
      setTeamAccessUserIdAction(loggedUser.user_id);
    }
  }, [loggedUser]);

  useEffect(() => {
    if (accountTeam.length > 0) {
      const sortbyUser: UserType[] = _.orderBy(nonProxyUsers, ['app_metadata.role.name'], ['desc']);
      setTreeNodes(buildTree(sortbyUser, null, true));
    }
  }, [accountTeam]);

  useEffect(() => {
    if (userTeam.length) {
      onFilterChange(teamFilter);
    }
  }, [userTeam]);

  const onFilterChange = (value: string) => {
    setTeamFilter(value);

    if (value === 'active' && loggedUser)
      setFilteredTeam(
        userTeam.filter((user: UserType) => !user.blocked && user.user_id !== loggedUser.user_id),
      );
    else if (value === 'inactive' && loggedUser) {
      setFilteredTeam(userTeam.filter((user: UserType) => user.blocked));
    } else if (loggedUser)
      setFilteredTeam(userTeam.filter((user: UserType) => user.user_id !== loggedUser.user_id));
  };

  useEffect(() => {
    const errors = Object.values(errorMessages);

    errors.forEach((key: any) => {
      key &&
        openNotificationWithIcon(
          'error',
          key.title,
          key.message,
          removeErrorAction({ code: key.code }),
        );
    });
  }, [errorMessages, removeErrorAction]);

  // useEffect(() => {
  //   fetchCompaniesAction();
  // }, [fetchCompaniesAction]);

  useEffect(() => {
    setCompanyInfo(company);
    getSeatsDetails(seatsInfo);
  }, [company]);

  const handleFecthCertificate = (selectedUserId: string | undefined) => {
    if (selectedUserId) {
      fetchUserCertificates(selectedUserId);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
    },
  ];

  const handleUpdateUser = (
    event: ChangeEvent<HTMLSelectElement> | AntdSelectTarget,
    user: UserType,
  ) => {
    if (event.target.name === 'reportsToUserID') {
      if (reportsToValidation(event, user)) {
        Swal.close();
        Swal.fire({
          title: 'Action Not Allowed',
          text: `The user you are trying to assign reportsTo is already reporting to the same user`,
          type: 'error',
          html: undefined,
          confirmButtonColor: baseTheme.red.error,
        });

        event.target.value = user.user_metadata.reportsToUserID;

        return;
      }
    }

    setModifiedUsers({
      ...modifiedUsers,
      [user.user_id]: {
        ...modifiedUsers[user.user_id],
        [event.target.name]: event.target.value,
      },
    });
  };

  const toggleCheckBoxChange = (value: boolean) => {
    setTreeNodes(buildTree(nonProxyUsers, null, !value));
  };

  const reportsToValidation = (
    event: ChangeEvent<HTMLSelectElement> | AntdSelectTarget,
    user: UserType,
  ) => {
    const toReportsToUser: UserType = userTeam.filter(
      (user: UserType) => user.user_id === event.target.value,
    )[0];

    if (toReportsToUser && toReportsToUser.user_metadata.reportsToUserID === user.user_id)
      return true;

    return false;
  };

  const handleSaveUser = async (user_id: string) => {
    const { name }: UserType = modifiedUsers[user_id];
    const { canCreateProject }: UserType = modifiedUsers[user_id];
    const { reportsToUserID }: UserMetaData = modifiedUsers[user_id];
    const { jobTitle }: JobTitleType = modifiedUsers[user_id];
    const { roleID }: RoleType = modifiedUsers[user_id];
    const { contractorCompanyID }: UserMetaData = modifiedUsers[user_id];

    const mappedUser: any = {};

    if (typeof name !== 'undefined') {
      var splitName = name.split(' ');
      mappedUser.name = name;
      mappedUser.firstName = splitName.shift();
      mappedUser.lastName = splitName.join(' ');
    }
    if (typeof roleID !== 'undefined') {
      mappedUser.app_metadata = {};
      mappedUser.app_metadata.role = {};
      mappedUser.app_metadata.role.roleID = roleID;
      mappedUser.app_metadata.role.name = ROLE[roleID];
    }
    if (typeof reportsToUserID !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.reportsToUserID = reportsToUserID;
      } else {
        mappedUser.user_metadata.reportsToUserID = reportsToUserID;
      }
    }

    if (typeof contractorCompanyID !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.contractorCompanyID = contractorCompanyID;
      } else {
        mappedUser.user_metadata.contractorCompanyID = contractorCompanyID;
      }
    }

    if (typeof jobTitle !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.jobTitle = jobTitle;
      } else {
        mappedUser.user_metadata.jobTitle = jobTitle;
      }
    }

    if (typeof canCreateProject !== 'undefined') {
      if (typeof mappedUser.user_metadata === 'undefined') {
        mappedUser.user_metadata = {};
        mappedUser.user_metadata.canCreateProject = canCreateProject;
      } else {
        mappedUser.user_metadata.canCreateProject = canCreateProject;
      }
    }

    if (loggedUser)
      updateUserInfoAction({ user: mappedUser, user_id: user_id, managerID: loggedUser.user_id });

    setModifiedUsers({ ...modifiedUsers, [user_id]: undefined });
  };

  const handleToggleModal = (value: any) => {
    if (value === true) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
      if (imageWithMeta.remove) {
        imageWithMeta.remove();
      }
    }
  };

  const handleImageDrop = ({ file, meta, cancel, remove }: IFileWithMeta) => {
    const { size }: IMeta = meta;
    const { maxSize } = IMAGE_COMPANY_RESTRICTIONS;
    // set ImageWithMeta to cancel or remove preview when closing edit modal
    setImageWithMeta({ file, meta, cancel, remove });

    if (size <= maxSize) {
      const endpoint = uploadImageCompanyAction.getEndpoint({ accountID });

      // Service to convert image into base64 string to make a post request
      // and save Company logo
      uploadImageServiceUtil(endpoint, file)
        .then((result: any) => {
          const imageUrl = result.data;
          // add imageUrl to companyInfo object
          setCompanyInfo({ ...companyInfo, logoUri: imageUrl });
        })
        .catch(() => {
          // set an error if POST request fails
          setImageValidation({
            isValid: false,
            message: 'There has been an error trying to upload your image. Please, try again.',
          });
        });

      setImageValidation({
        isValid: true,
        message: 'Company logo has been successfully updated!',
      });
      cancel();
    }
  };

  const handleImageStatusChange = (status: StatusValue) => {
    if (status === STATUS.removed) {
      setImageValidation({
        isValid: false,
        message: '',
      });
    }
  };

  const handleUpdateAccount = (form: WrappedFormUtils) => {
    form.validateFieldsAndScroll((err: string, formValues: any) => {
      if (!err && accountID) {
        const mergedCompanyInfo = { ...companyInfo, ...formValues };

        updateCompanyAction({ formValues: mergedCompanyInfo, accountID: accountID });
        setIsModalVisible(false);
        setCompanyInfo(mergedCompanyInfo);
        form.resetFields();

        // remove image uploaded from dropzone
        imageWithMeta.remove();
      }
    });
  };

  const validateKeys = (e: any) => {
    if (!REGEX.validateNumber.test(e.key) || e.target.value.length >= 10) {
      e.preventDefault();
    }
  };

  const handleDeleteCompanyLogo = (accountID: number) => {
    deleteAccountLogoAction({ accountID: accountID });
    if (errorMessages['failed']) {
      Swal.close();
      Swal.fire({
        title: 'Upps!',
        text: `There has been an error when deleting company logo.`,
        type: 'error',
        html: undefined,
        confirmButtonColor: baseTheme.red.error,
      });
    } else {
      const updatedCompanyInfo = { ...companyInfo, logoUri: undefined };
      setCompanyInfo(updatedCompanyInfo);
    }
  };

  const buildTree = (data: UserType[], user: UserType | null, incActive: boolean): any => {
    let children: UserType[] = [];

    if (user === null) {
      children = data.filter((item: UserType) => item.reportsTo === null);
    } else {
      if (user !== undefined) {
        children = data.filter(
          (item: UserType) => item.user_metadata.reportsToUserID === user.user_id,
        );
      }
    }

    if (children.length === 0) {
      return null;
    }

    return children.map((child: UserType) => {
      if (incActive && child.blocked === true) return null;

      const proxyUsers = accountTeam.filter((item: UserType) =>
        item.user_metadata.proxyUsers && item.user_metadata.proxyUsers.length
          ? item.user_metadata.proxyUsers.includes(child.user_id)
          : undefined,
      );

      return (
        <TreeNode
          title={
            <span className={child.blocked ? 'inactive-user' : undefined}>
              {child.name}{' '}
              {child.contractorCompany !== null
                ? `(${child.contractorCompany.companyName})`
                : `(${child.user_metadata.companyName})`}{' '}
              - {child.app_metadata.role.name} (Tier {child.tier})
              {child.blocked ? 'Inactive' : null}{' '}
              {proxyUsers.length ? (
                <span style={{ backgroundColor: '#ff4d5036' }}>
                  {`( ${proxyUsers.map(user => user.name).join(', ')} )`}
                </span>
              ) : null}
            </span>
          }
          key={child.user_id}
        >
          {buildTree(data, child, incActive)}
        </TreeNode>
      );
    });
  };

  const handleDeleteUser = (id: string, status: boolean) => {
    const underUsers: UserType[] = getUnderUsers(accountTeam, id);
    const underUsersLength = underUsers.length;
    const targetUser = underUsers[0];
    const underUsersIDs = underUsers.map((underUser: UserType) => underUser.user_id);
    let data: any = [];

    underUsers.map((underUser: UserType) => {
      !underUser.blocked &&
        data.push({
          key: underUser.user_id,
          name: underUser.name,
          jobTitle: underUser.user_metadata.jobTitle,
        });
    });

    Modal.confirm({
      className: 'remove-associate',
      icon: null,
      title: null,
      visible: showPopupModal,
      onOk() {
        if (status) {
          updateUserInfoAction({ user: { blocked: false }, user_id: id, accountId: accountID });
          return setPopupModal(false);
        }
        removeAccountUserAction({ toRemoveIDs: underUsersIDs, accountId: accountID });
        return setPopupModal(false);
      },
      content: (
        <>
          <div
            className="swal2-icon swal2-warning swal2-animate-warning-icon"
            style={{ display: 'flex ' }}
          ></div>
          <b className="warn-text">
            Do you want to {!targetUser.blocked ? 'inactive' : 'active'} {targetUser.name}{' '}
            {underUsersLength > 1 && !targetUser.blocked ? 'and associates under him' : ''} ?
          </b>

          <br />

          {underUsersLength > 1 && !targetUser.blocked ? (
            <>
              <span className="note-text">
                <b>Note:&nbsp;</b>
                <span>
                  Associate under {targetUser.name} also be inactive. you can also change reporting
                  of associates under {targetUser.name}{' '}
                </span>
              </span>
              <Table
                bordered={true}
                columns={columns}
                dataSource={data}
                scroll={{ y: 200 }}
                pagination={false}
              />
            </>
          ) : null}
        </>
      ),
      okText: `${!targetUser.blocked ? 'Inactive' : 'Active'}`,
      cancelText: 'Cancel',
    });
  };

  const resendUserInvite = (selectedUserId: string) => {
    resendUserInviteAction(selectedUserId);
  };

  const handleSort = (key: string) => {
    if (sorter.key === key) {
      setSorter({ order: invertedOrder[sorter.order], key });
    } else {
      setSorter({ order: 'asc', key });
    }
  };

  const orderedTeam: any = _.orderBy(filteredTeam, [sorter.key], [sorter.order as any]);

  return (
    <div className="sa__container">
      <AddAccountComponent
        title={'Edit Company Information'}
        user={loggedUser}
        imageValidation={imageValidation}
        validateKeys={validateKeys}
        account={companyInfo}
        isModalVisible={isModalVisible}
        handleCloseModal={handleToggleModal}
        handleSaveAccount={handleUpdateAccount}
        handleImageDrop={handleImageDrop}
        handleImageStatusChange={handleImageStatusChange}
      />

      <div className="sa__box">
        <AccountsInfoComponent
          team={accountTeam}
          user={loggedUser}
          companyInformation={companyInfo}
          toggleCheckBoxChange={toggleCheckBoxChange}
          handleToggleModal={handleToggleModal}
          seatsDetails={seatsDetails}
          handleDeleteCompanyLogo={handleDeleteCompanyLogo}
        />
      </div>

      <div className="sa__box">
        <>
          <HeadingComponent
            title={'Associates'}
            btnTitle={`Organization Tree`}
            onClkBtnFunction={() => setShowModal(true)}
          />

          <Modal
            title={'Organization Tree'}
            className="sa-tree"
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            footer={
              <Button
                className="ant-modal-btn-close"
                type="primary"
                onClick={() => setShowModal(false)}
              >
                Close
              </Button>
            }
          >
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Checkbox
                  onChange={(value: CheckboxChangeEvent) =>
                    toggleCheckBoxChange(value.target.checked)
                  }
                >
                  <b>Include Inactive</b>
                </Checkbox>
              </div>
              {isLoading === true ? (
                <TableLoadingSpinnerComponent />
              ) : (
                <Tree
                  defaultExpandAll
                  showLine={true}
                  className="sa-selectable"
                  onClick={(event: any, node: AntTreeNode) => {
                    setTargetUserID(node.props.eventKey);
                    handleFecthCertificate(node.props.eventKey);
                    setProfileModal(true);
                  }}
                  selectable={false}
                  multiple
                >
                  {treeNodes}
                </Tree>
              )}
            </>
          </Modal>

          <UserCertificateContainer
            team={accountTeam}
            targetUserID={targetUserID}
            open={openProfileModal}
            setOpen={setProfileModal}
            usage="tree"
            site="backoffice"
            defaultTabOpen="1"
          />
        </>

        <TeamTableLockedComponent
          team={orderedTeam}
          AccountTeam={accountTeam}
          onFilterChange={onFilterChange}
          companyContractors={companyContractors}
          resendUserInvite={resendUserInvite}
          sorter={teamSorter}
          handleDeleteUser={handleDeleteUser}
          loggedUser={loggedUser}
          modifiedUsers={modifiedUsers}
          jobTitles={jobTitles}
          isLoading={isLoading}
          handleSort={handleSort}
          handleUpdateUser={handleUpdateUser}
          handleSaveUser={handleSaveUser}
        />
      </div>

      <div className="sa__box">
        <ContractorsLockedContainer
          companyContractors={companyContractors}
          selectedAccountID={accountID}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  loggedUser: getSessionUser(state),
  userTeam: getUserTeam(state),
  accountTeam: getAccountsTeam(state),
  companyContractors: getAccountsContractors(state),
  teamCollection: getTeamDictionary(state),
  teamSorter: getTeamSorter(state),
  jobTitles: getJobTitles(state),
  isLoading: getTeamIsLoading(state),
  isSendingInvite: getTeamIsSendingInvite(state),
  hasSentInvite: getHasSentInvite(state),
  cachedSupervisorID: getCachedSupervisorID(state),
  teamAccessUserID: getTeamAccessUserID(state),
  errorMessages: getProfessionalErrors(state),
  company: getUserCompany(state),
  seatsInfo: getSeatsInfoCompany(state),
});

const mapDispatchToProps = {
  fetchUserTeamAction: fetchUserTeamAction.request,
  fetchContractorsAction: fetchContractorsAction.request,
  fetchJobTitlesAction: fetchAccountJobTitlesAction.request,
  updateUserInfoAction: updateUserInfoAction.request,
  fetchUserCertificates: fetchUserCertificatesAction.request,
  changeSetUserAction: changeSetUserAction.trigger,
  toggleSorterAction: toggleSPUserAction.trigger,
  removeErrorAction: removeBackofficeErrorAction.trigger,
  removeAccountUserAction: blockBackOfficeUserAction.request,
  resetTeamAction: fetchTeamAction.fulfill,
  resetJobTitlesAction: fetchAccountJobTitlesAction.fulfill,
  uploadImageAction: uploadImageCompanyAction.request,
  updateCompanyAction: updateCompanyAccountAction.request,
  fetchCompaniesAction: fetchCompaniesAction.request,
  deleteAccountLogoAction: deleteAccountLogoAction.request,
  fetchSeatsInfo: fetchSeatsInfoAction.request,
  resendUserInviteAction: resendUserInviteAction.request,
  setTeamAccessUserIdAction: setTeamAccessUserIdAction.trigger,
};

export const TeamLockedContainer = connect(mapStateToProps, mapDispatchToProps)(TeamLocked);
