export const STATUS = {
  active: 'active',
  wait: 'wait',
  process: 'process',
  completed: 'completed',
  finish: 'finish',
  done: 'done',
  uploading: 'uploading',
  removed: 'removed',
  success: 'success' as any,
  error: 'error' as any,
  warning: 'warning',
  info: 'info',
  question: 'question',
};
//TODO: remove type
//TODO: FIX swal modal types
