import React, { useState, useEffect } from 'react';

import { IAppState } from '@rdx/root.reducer';

import { connect } from 'react-redux';

import _ from 'lodash';


import { AdminSubmissionsReportsComponent } from '../view/sub.reports.component';

import { getAccountsTeam } from '@app/admin/accounts/team/state/team.selector';
import { getAccountID } from '@app/admin/invite/state/invite.selector';
import { getAccountCompanyInformation } from '@app/admin/accounts/state/accounts.selector';
import { fetchAccountsAction } from '@app/admin/accounts/state/accounts.actions';

import { fetchAccountUsersAction } from '@app/admin/accounts/team/state/team.actions';
import { AccountType, UserType } from '@shared/types/backend.types';

export interface IAdminSubmissoinsReportsContainerProps {
  team: UserType[];
  selectedAccount?: AccountType;
  selectedAccountID: number | undefined;
  fetchTeamAction: (selectedAccountID: number) => void;
  fetchAccounts: () => void;
}

const AdminSubmissionsReports = (props: IAdminSubmissoinsReportsContainerProps) => {
  const { team, selectedAccountID, selectedAccount } = props;
  const { fetchTeamAction, fetchAccounts } = props;
  const [selectedUser, setSelectedUser] = useState<UserType | undefined>(undefined)

  useEffect(() => {
    if (selectedAccountID) {
      fetchTeamAction(selectedAccountID)
    }
  }, [selectedAccountID])

  useEffect(
    () => { fetchAccounts() }, []
  )

  useEffect(() => {
    if (team.length > 0) {
      setSelectedUser(team.filter((user: UserType) => user.user_metadata.reportsToUserID === '')[0]);
    }
  }, [team])


  const sortedTeam = _.orderBy(team, ['tier'], ['asc']);

  return (
    <AdminSubmissionsReportsComponent
      team={sortedTeam}
      selectedAccount={selectedAccount}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
    />
  )
};

const mapStateToProps = (state: IAppState) => ({
  team: getAccountsTeam(state),
  selectedAccountID: getAccountID(state),
  selectedAccount: getAccountCompanyInformation(state),
});

const mapDispatchToProps = {
  fetchTeamAction: fetchAccountUsersAction.request,
  fetchAccounts: fetchAccountsAction.request,
};

export const AdminSubmissionsReportsContainer = connect(mapStateToProps, mapDispatchToProps)(AdminSubmissionsReports);
