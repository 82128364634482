import { PTP, PTPSubmission, PTPTask } from "@shared/types/backend.types";
import update from 'immutability-helper';

import { createPtpAction, deletePtpAction, deletePtpTaskAction, fetchPtpAction, updatePtpAction, createPtpTaskAction, fetchPtpTaskAction, updatePtpTaskAction } from "./pre-task-plan.actions";
import { AnyAction } from "redux";
import { IProfessionalState } from "@app/back-office/professional.reducer";
import { combineActions } from "redux-actions";
import { ptpSubmissionsReducer } from "../reports/state/ptp-submissions.reducer";
import { flush } from "redux-saga/effects";


const initialState = {
    items: [],
    isLoading: false
}

export interface IPtpState {
    ptps: {
        items: PTP[];
        isLoading: boolean;
    };
    ptpSubmissions: {
        items: { [key: string]: PTPSubmission };
        isLoading: boolean;
    };
    ptptasks: {
        items: PTPTask[];
        isLoading: boolean;
    };
}

export const PTP_INITIAL_STATE: IPtpState = {
    ptps: initialState,
    ptpSubmissions: { items: {}, isLoading: false },
    ptptasks: initialState
};


export const ptpReducer = {

    ...ptpSubmissionsReducer,

    [fetchPtpAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            preTaskPlans: {
                ptps: {
                    items: { $set: payload },
                    isLoading: { $set: false }
                },

            }
        }),
    [fetchPtpAction.FULFILL]: (state: IProfessionalState) =>
        update(state, {
            preTaskPlans: {
                ptps: {
                    items: { $set: [] },
                    isLoading: { $set: false }
                },
            }
        }),

    [createPtpAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            preTaskPlans: {
                ptps: {
                    items: { $set: [...state.preTaskPlans.ptps.items, payload] },
                    isLoading: { $set: false }
                },
            }
        }),

    [updatePtpAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {

        const ptps = state.preTaskPlans.ptps.items;

        const itemIndex = ptps.findIndex(obj => obj.id === payload.id);

        if (itemIndex !== -1) {
            ptps[itemIndex] = payload;

            return update(state, {
                preTaskPlans: {
                    ptps: {
                        items: { $set: ptps },
                        isLoading: { $set: false }
                    },
                }
            });
        } else {
            return update(state, {});
        }
    },

    [deletePtpAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {

        const ptps = state.preTaskPlans.ptps.items.filter((ptp) => ptp.id !== payload);

        return update(state, {
            preTaskPlans: {
                ptps: {
                    items: { $set: ptps },
                    isLoading: { $set: false }
                },
            }
        });
    },

    [fetchPtpTaskAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            preTaskPlans: {
                ptptasks: {
                    items: { $set: payload },
                    isLoading: { $set: false }
                },

            }
        }),


    [fetchPtpTaskAction.FULFILL]: (state: IProfessionalState) =>
        update(state, {
            preTaskPlans: {
                ptptasks: {
                    items: { $set: [] },
                    isLoading: { $set: false }
                },
            }
        }),



    [createPtpTaskAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) =>
        update(state, {
            preTaskPlans: {
                ptptasks: {
                    items: { $set: [...state.preTaskPlans.ptptasks.items, payload] },
                    isLoading: { $set: false }
                },
            }
        }),

    [updatePtpTaskAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {

        const ptpTasks = state.preTaskPlans.ptptasks.items;

        const itemIndex = ptpTasks.findIndex(obj => obj.id === payload.id);

        if (itemIndex !== -1) {
            ptpTasks[itemIndex] = payload;

            return update(state, {
                preTaskPlans: {
                    ptptasks: {
                        items: { $set: ptpTasks },
                        isLoading: { $set: false }
                    },
                }
            });
        } else {
            return update(state, {});
        }
    },


    [deletePtpTaskAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {

        const ptpTasks = state.preTaskPlans.ptptasks.items.filter((ptpTasks) => ptpTasks.id !== payload);

        return update(state, {
            preTaskPlans: {
                ptptasks: {
                    items: { $set: ptpTasks },
                    isLoading: { $set: false }
                },
            }
        });
    },


    [combineActions(fetchPtpAction.REQUEST, createPtpAction.REQUEST, updatePtpAction.REQUEST, deletePtpAction.REQUEST) as any]: (
        state: IProfessionalState,
    ) => {
        return update(state, {
            preTaskPlans: {
                ptps: {
                    isLoading: { $set: true }
                },
            }
        })
    },

    [combineActions(fetchPtpTaskAction.REQUEST, createPtpTaskAction.REQUEST, updatePtpTaskAction.REQUEST, deletePtpTaskAction.REQUEST) as any]: (
        state: IProfessionalState,
    ) => {
        return update(state, {
            preTaskPlans: {
                ptptasks: {
                    isLoading: { $set: true }
                },
            }
        })
    },

    [combineActions
        (
            fetchPtpAction.FAILURE,
            createPtpAction.FAILURE,
            updatePtpAction.FAILURE,
            deletePtpAction.FAILURE,
        ) as any]: (
            state: IProfessionalState,
            { payload }: AnyAction,
        ) =>
            update(state, {
                errorMessages: {
                    [payload.code]: { $set: payload.message },
                },
                preTaskPlans: {
                    ptps: {
                        isLoading: { $set: false }
                    }
                }
            }),

    [combineActions
        (
            fetchPtpTaskAction.FAILURE,
            updatePtpTaskAction.FAILURE,
            createPtpTaskAction.FAILURE,
            deletePtpTaskAction.FAILURE
        ) as any]: (
            state: IProfessionalState,
            { payload }: AnyAction,
        ) =>
            update(state, {
                errorMessages: {
                    [payload.code]: { $set: payload.message },
                },
                preTaskPlans: {
                    ptptasks: {
                        isLoading: { $set: false }
                    }
                }
            }),



}

