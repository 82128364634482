import React, { Fragment } from 'react';

import { AccountType, PackageSeatDetail, UserType } from '@shared/types/backend.types';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { Tooltip } from 'antd';
import { AccountsSafetyPlansContainer } from '../safety-plans/state/safety-plans.container';
import { AccountsTeamContainer } from '../team/state/team.container';
import { ContractorsLockedContainer } from '../contractors/state/contractors.container';
import { AccountsInfoContainer } from '../info/state/info.container';
import { AddAccountComponent } from '@components/admin/add-account/add-account.component';
import { AddPackageComponent } from '@components/admin/add-package/add-package.component';


import '@utils/styles/width.css';
import './accounts.styles.scss';

interface IAccountsProps {
  companyContractors: [];
  team: UserType[];
  adminsTree: any // add type
  isTreeLoading: boolean;
  roles: [];
  role?: string;
  isModalVisible?: boolean;
  isCreatingCompany?: boolean;
  isPModalVisible?: boolean;
  isCreatingPackage?: boolean;
  companyInformation: AccountType;
  seatsDetails: any;
  PackageID?: string;
  selectedAccountID?: number;
  Seats?: any;
  validateKeys?: (event: any) => void;
  handleToggleModal: (isUpdating: boolean, form?: WrappedFormUtils) => void;
  handleSaveAccount: (form: WrappedFormUtils) => void;
  toggleCheckBoxChange: (value: boolean) => void
  handleUpdateAccount: (form: WrappedFormUtils) => void;
  handleToogleAccountActivate?: (isActivated: boolean, company: AccountType) => void;
  handleTogglePackageModal: (isUpdating: boolean, pId?: string, seats?: any, aSeats?: any, form?: WrappedFormUtils) => void;
  handleSavePackage: (form: WrappedFormUtils) => void;
  handleUpdatePackage: (form: WrappedFormUtils) => void;
}

export const AccountsComponent = (props: IAccountsProps) => {
  const {
    companyContractors,
    adminsTree,
    isTreeLoading,
    roles,
    companyInformation,
    seatsDetails,
    isModalVisible,
    isCreatingCompany,
    isPModalVisible,
    isCreatingPackage,
    PackageID,
    Seats,
    selectedAccountID
  } = props;
  const {
    validateKeys,
    handleToggleModal,
    handleSaveAccount,
    handleUpdateAccount,
    handleToogleAccountActivate,
    toggleCheckBoxChange,
    handleTogglePackageModal,
    handleSavePackage,
    handleUpdatePackage,
  } = props;


  return (
    <div className="sa__container">

      <AddAccountComponent
        title={"Edit Account Information"}
        validateKeys={validateKeys}
        account={isCreatingCompany === false ? companyInformation : null}
        isModalVisible={isModalVisible}
        handleCloseModal={handleToggleModal}
        handleSaveAccount={!!isCreatingCompany ? handleSaveAccount : handleUpdateAccount}
      />

      <div className="accounts__content sa__box mt-0" >
        <AccountsInfoContainer
          adminsTree={adminsTree}
          isTreeLoading={isTreeLoading}
          companyInformation={companyInformation}
          handleToggleModal={handleToggleModal}
          toggleCheckBoxChange={toggleCheckBoxChange}
          handleToogleAccountActivate={handleToogleAccountActivate}
          seatsDetails={seatsDetails}
        />
      </div>

      <div className="accounts__content sa__box">
        <div className="sm-w-100">
          {companyInformation && handleToogleAccountActivate && (
            <Fragment>
              <div style={{ justifyContent: 'flex-start', gap: '10px' }} className="sa__box-heading ">
                <h4 className="sa__box-heading-title">Package Details</h4>
                <Tooltip title="Edit Account">
                  <i className="far fa-plus-square" onClick={() => handleTogglePackageModal(false)} />
                </Tooltip>
              </div>
              <div className='sa__box-content'>
                <table className='sa-info-table'>
                  <tbody>
                    {
                      seatsDetails.length > 0 ? seatsDetails.map((seat: PackageSeatDetail, index: number) =>
                      (
                        <tr key={index}>
                          <td>{seat.packageName}</td>
                          <td>
                            <div>
                              {seat.assignedSeats} Assigned of {seat.seats} Available
                              <Tooltip title="Edit Package">
                                <i className="fa fa-edit" onClick={() => handleTogglePackageModal(true, seat.packageID, seat.seats, seat.assignedSeats)} />
                              </Tooltip>
                            </div>
                          </td>
                        </tr>

                      )) : <div>No Seats Available</div>
                    }
                  </tbody>
                </table>
              </div>

              <AddPackageComponent
                title={"Package Information"}
                adminsTree={adminsTree}
                PackageID={PackageID}
                Seats={Seats}
                validateKeys={validateKeys}
                isPModalVisible={isPModalVisible}
                handlePCloseModal={handleTogglePackageModal}
                editMode={!!isCreatingPackage ? false : true}
                handleSavePackage={!!isCreatingPackage ? handleSavePackage : handleUpdatePackage}
              />
            </Fragment>
          )}
        </div>
      </div>

      <div className="accounts__content sa__box">
        <AccountsTeamContainer companyContractors={companyContractors} roles={roles} />
      </div>
      <div className="accounts__content sa__box">
        <ContractorsLockedContainer companyContractors={companyContractors} selectedAccountID={selectedAccountID} />
      </div>
      <div className="accounts__content sa__box">
        <AccountsSafetyPlansContainer />
      </div>
    </div>
  );
};
