import React, { FormEvent } from 'react';

import Form from 'antd/es/form';
import { ButtonControlComponent } from '@components/controls/button/button-control.component';

import './sales-form.styles.scss';

export interface ISalesFormComponentProps {
  showSalesModal: boolean;
  item?: any;
  isSalesFormSubmitting: boolean;
  handleCloseSalesModal: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>, item: any) => void;
}

const SalesForm = (props: ISalesFormComponentProps) => {
  const { showSalesModal, item, isSalesFormSubmitting } = props;
  const { handleCloseSalesModal, handleSubmit } = props;

  return (
    <div className={showSalesModal ? 'salesmodal-display' : 'hide'}>
      <div className="salesmodal__container">
        <div className="salesmodal-body">
          <Form
            layout="vertical"
            hideRequiredMark
            onSubmit={(event: FormEvent<HTMLFormElement>) => handleSubmit(event, item)}
          >
            <p>Would you like to have access to this Safety Program?</p>
            {item !== undefined && <h2>{item.name}</h2>}
            <p>
              Someone from our Sales Department <br /> will be in touch with you.
            </p>
            <div className="salesmodal-footer">
              <ButtonControlComponent
                text="Yes, contact me"
                size="small" color="green"
                htmlType="submit"
                loading={isSalesFormSubmitting}
              />
              <ButtonControlComponent
                text="No, thanks"
                size="small"
                color="gray"
                onClick={handleCloseSalesModal}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export const SalesFormComponent: any = SalesForm;
