import classNames from 'classnames';
import React from 'react';

type props = {
  extra?: any;
  headerClassName?: string;
  headerStyles?: React.CSSProperties | undefined;
  name: React.ReactNode | string;
};

const SACollapse = (
  props: React.DetailedHTMLProps<React.DetailsHTMLAttributes<HTMLElement>, HTMLElement> & props,
) => {
  const { name, children, extra, headerClassName, headerStyles } = props;

  return (
    <details className="sa-collapse" {...props}>
      <summary style={headerStyles} className={classNames('sa-collapse__header', headerClassName)}>
        <div className="d-flex justify-between w-100">
          <span>{name}</span>
          <div>{extra}</div>
        </div>
      </summary>

      <div className="sa-collapse__body">{children}</div>
    </details>
  );
};

export default SACollapse;
