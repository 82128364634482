import React, { useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';

import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import placeholderUploader from '@assets/placeholders/uploader-placeholder.svg';
import { fileToObject } from 'antd/lib/upload/utils';

export interface IUploaderComponentProps {
  form?: any;
  handleOnChangeStatus: (status: any) => void;
  handleImageDrop: (file: any, form: any) => void;
}

const ImageUploader = (props: IUploaderComponentProps) => {
  const { form } = props;
  const { handleOnChangeStatus, handleImageDrop } = props;
  return (
    <Dropzone
      inputContent={
        <img className="image__uploader" src={placeholderUploader} alt="Drop your file here" />
      }
      onChangeStatus={(file: IFileWithMeta, status: StatusValue) => handleOnChangeStatus(status)}
      validate={(file: IFileWithMeta) => handleImageDrop(file, form)}
      maxFiles={1}
      accept="image/*"
      styles={{ dropzone: { maxWidth: 400, minHeight: 400, marginBottom: 15} }}
    ></Dropzone>
  );
};

export const ImageUploaderComponent = ImageUploader;
