import { IAdminState } from '@app/admin/admin.reducer';
import { UserType, SafetyPlanType, JobTitleType, PackageSeatDetail, ContractorType, UsersConfigType, AccountType } from '@shared-types/backend.types';
import { ErrorMessage } from '@shared/types/utils.types';
import { AnyAction } from 'redux';

import {
  accountsTeamReducer,
  IAccountTeamState,
  ACCOUNTS_TEAM_INITIAL_STATE,
} from '../team/state/team.reducer';

import {
  fetchAccountsAction,
  fetchSeatsInfoAction,
  createCompanyAction,
  updateCompanyAccountAction,
  patchAccountIsActivatedAction,
  createPackageAction,
  updatePackageAction,
  updateUsersConfigAction,
} from './accounts.actions';

import { fecthUsersConfigAction } from '../state/accounts.actions';

import { createRoutineReducer } from '@shared/helpers/redux-saga-endpoints';
import { accountsSafetyPlansReducer } from '../safety-plans/state/safety-plans.reducer';
import { contractorsReducer } from '../contractors/state/contractors.reducer';


import update from 'immutability-helper';

export interface IAccountState {
  accountList: {
    items: {
      [key: string]: AccountType;
    };
    isLoading: boolean;
    errorMessages?: {
      [key: string]: ErrorMessage;
    };
  };
  team: IAccountTeamState;

  safetyPlans: {
    items: SafetyPlanType[];
    isLoading: boolean;
    isUpdating: number;
    errorMessages?: {
      [key: string]: ErrorMessage;
    };
  };

  usersConfig: {
    items: UsersConfigType[];
    isLoading: boolean;
    errorMessages?: { [key: string]: ErrorMessage; };
  }

  contractors: {
    items: ContractorType[];
    isLoading: boolean;
    contractorCachedCounter: number;
    errorMessages: {
      [key: string]: ErrorMessage | undefined;
    };
  };

  roles: { [key: string]: JobTitleType };
  seatsInfo: PackageSeatDetail[];
}

export const ACCOUNTS_INITIAL_STATE: IAccountState = {
  accountList: createRoutineReducer(),
  team: ACCOUNTS_TEAM_INITIAL_STATE,

  safetyPlans: {
    items: [],
    isUpdating: 0,
    isLoading: false,
    errorMessages: undefined,
  },

  contractors: {
    items: [],
    isLoading: false,
    contractorCachedCounter: 0,
    errorMessages: {}
  },

  usersConfig: {
    items: [],
    isLoading: false,
    errorMessages: undefined,
  },

  roles: {},
  seatsInfo: [],
};

export const accountsReducer = {
  ...accountsTeamReducer,
  ...accountsSafetyPlansReducer,
  ...contractorsReducer,

  [fetchAccountsAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        accountList: { items: { $set: payload.accounts } },
      },
    })
  },
  [fetchAccountsAction.FULFILL]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        accountList: {
          items: { $set: {} },
          isLoading: { $set: false },
          errorMessages: { $set: {} },
        },
      },
    }),
  [fetchSeatsInfoAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {
      accounts: {
        seatsInfo: { $set: payload.seatsInfo },
      },
    }),
  [createCompanyAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        // selectedAccountID: { $set: payload.account.accountID },
        accountList: {
          items: {
            [payload.account.accountID]: { $set: payload.account },
          },
        },
      },
    })
  },
  [updateCompanyAccountAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        accountList: {
          items: {
            [payload.updatedAccount.accountID]: { $set: payload.updatedAccount },
          },
        },
      },
    });
  },
  [patchAccountIsActivatedAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        accountList: {
          items: {
            [payload.updatedAccount.accountID]: { $set: payload.updatedAccount },
          },
        },
      },
    });
  },
  [patchAccountIsActivatedAction.FAILURE]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {
      accounts: {
        accountList: {
          errorMessages: {
            [payload.code]: { $set: payload.message }
          },
        },
      },
    });
  },
  [createPackageAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) =>
    update(state, {

    }),
  [updatePackageAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, {

    });
  },

  [fecthUsersConfigAction.REQUEST]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, { accounts: { usersConfig: { isLoading: { $set: true } } }, })
  },

  [fecthUsersConfigAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, { accounts: { usersConfig: { items: { $set: payload }, isLoading: { $set: false } } }, })
  },

  [updateUsersConfigAction.SUCCESS]: (state: IAdminState, { payload }: AnyAction) => {
    return update(state, { accounts: { team: { items: { $set: payload } } }, })
  }
};
