import { takeLatest, put, call } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import { changePlanFormsAction, fetchFormQuestionsAction } from './forms.actions';
import { fetchService } from '@rdx/services.saga';

function* changePlanFormsTrigger(action: AnyAction) {
  yield put(changePlanFormsAction.fulfill(action.payload));
}

function* fetchFormQuestionsRequest(action: AnyAction) {
  const { safetyPlanID, formID } = action.payload;

  try {
    const endpoint = fetchFormQuestionsAction.getEndpoint({ safetyPlanID, formID });

    const questions = yield call(fetchService, endpoint);

    yield put(fetchFormQuestionsAction.success({ question: questions }));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        fetchFormQuestionsAction.failure({
          code: 'backOfficeProgramsFetchFormQuestions',
          title: 'Failed to retrieve questions',
          message: 'There has been an error while retrieving form questions.',
        }),
      );
    }
  }
}

export function* programPlanFormsSaga() {
  yield takeLatest(fetchFormQuestionsAction.REQUEST, fetchFormQuestionsRequest);
  yield takeLatest(changePlanFormsAction.TRIGGER, changePlanFormsTrigger);
}
