import React from 'react';

import { UserType } from '@shared/types/backend.types';

import './item.styles.scss';

interface ITeamLockedItemProps {
  user: UserType;
}

export const TeamLockedItemComponent = (props: ITeamLockedItemProps) => {
  const { user } = props;
  const { given_name, family_name, email, user_metadata, app_metadata } = user;
  const { jobTitle } = user_metadata;
  const { role } = app_metadata;

  return (
    <tr>
      <td>{given_name}</td>
      <td>{family_name}</td>
      <td>{email}</td>
      <td>{user.user_metadata.package}</td>
      <td>{jobTitle}</td>
      <td>{role.name}</td>
      <td>
        <div className="team-item__controls"></div>
      </td>
    </tr>
  );
};
