import { baseTheme } from './base.theme';

export const darkTheme = {
  ...baseTheme,
  menuBackground: baseTheme.green.main,
  menuText: baseTheme.white.main,
  menuItemHover: baseTheme.green.hover,
  menuItemActive: baseTheme.yellow.main,
  dashboardBackground: baseTheme.white.main,
};
