import { CATicketType, CorrectiveActionsType } from "@shared/types/backend.types";
import update from 'immutability-helper';
import { fetchUnderUserCA, fetchCATriggers, fetchCASeveritys, fetchCAStatus, fetchCATypes, fetchCATickets, UpdateUnderUserCA, fetchUserManagers, createCorrectiveAction, fetchCorrectiveAction } from "./corrective-actions.actions";
import { IProfessionalState } from "@app/back-office/professional.reducer";
import { combineActions } from 'redux-actions';

import { AnyAction } from "redux";

export interface ICorrectiveActionsState {
    items: CorrectiveActionsType[];
    tickets: CATicketType[];
    triggers: string[];
    severitys: string[];
    status: string[];
    types: string[];
    questions: any[],
    isLoading: boolean;
    errMsg?: string;
}

export const CORRECTIVE_ACTIONS_INITIAL_STATE = {
    items: [],
    tickets: [],
    triggers: [],
    severitys: [],
    status: [],
    types: [],
    questions: [],
    isLoading: true,
    errMsg: undefined
};

export const correctiveActionsReducer = {


    // COMBINE REQUEST REDUCERS
    [combineActions(
        fetchUnderUserCA.REQUEST,
        fetchCATriggers.REQUEST,
        fetchCASeveritys.REQUEST,
        fetchCAStatus.REQUEST,
        fetchCATypes.REQUEST,
        UpdateUnderUserCA.REQUEST,
        fetchCATickets.REQUEST,
        fetchUserManagers.REQUEST,
        fetchCorrectiveAction.REQUEST,
        createCorrectiveAction.REQUEST
    ) as any]:
        (state: IProfessionalState) => {
            return update(state, {
                correctiveActions: {
                    isLoading: { $set: true },
                }
            })
        },

    // SUCCESS REDUCERS
    [fetchUnderUserCA.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                items: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCorrectiveAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [payload] },
                isLoading: { $set: false },
            }
        })
    },

    [createCorrectiveAction.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {

        return update(state, {
            correctiveActions: {
                items: { $set: [...state.correctiveActions.items, payload] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCATickets.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                tickets: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCATriggers.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                triggers: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCASeveritys.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                severitys: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCAStatus.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                status: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCATypes.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                types: { $set: payload },
                isLoading: { $set: false },
            }
        })
    },

    [UpdateUnderUserCA.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                isLoading: { $set: false },
            }
        })
    },

    [fetchUserManagers.SUCCESS]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            managers: { $set: payload },
            team: {
                isLoading: { $set: false },
            }
        })
    },

    // FULLFILL REDUCERS
    [fetchUnderUserCA.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCorrectiveAction.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCATickets.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                tickets: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCATriggers.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                triggers: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCASeveritys.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                severitys: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCAStatus.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                status: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [fetchCATypes.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                types: { $set: [] },
                isLoading: { $set: false },
            }
        })
    },

    [UpdateUnderUserCA.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                isLoading: { $set: false },
            }
        })
    },

    [fetchUserManagers.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            team: {
                items: {},
                isLoading: { $set: false },
            }
        })
    },

    // FAILURE REDUCERS
    [fetchUnderUserCA.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Actions' },
            }
        })
    },

    [fetchCorrectiveAction.FAILURE]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Action' },
            }
        })
    },

    [createCorrectiveAction.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                items: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while creating the corrective action' },
            }
        })
    },

    [fetchCATickets.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                isLoading: { $set: false },
                tickets: { $set: [] },
                errMsg: { $set: 'Error, while fetching action tickets' },
            }
        })
    },

    [fetchCATriggers.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                triggers: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Actions Triggers' },
            }
        })
    },

    [fetchCASeveritys.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                severitys: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Actions Severitys' },
            }
        })
    },

    [fetchCAStatus.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                status: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Actions Status' },
            }
        })
    },

    [fetchCATypes.FAILURE]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                types: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while fetching the Actions Types' },
            }
        })
    },

    [UpdateUnderUserCA.FULFILL]: (state: IProfessionalState) => {
        return update(state, {
            correctiveActions: {
                types: { $set: [] },
                isLoading: { $set: false },
                errMsg: { $set: 'Error, while updating the Action' },
            }
        })
    },

    [fetchUserManagers.FULFILL]: (state: IProfessionalState, { payload }: AnyAction) => {
        return update(state, {
            team: {
                items: {},
                isLoading: { $set: false },
            },
            correctiveActions: {
                errMsg: { $set: 'Error, while updating the Action' },
            }

        })
    },
};