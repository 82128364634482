import React, { Fragment } from 'react';

import { QuestionType } from '@shared/types/backend.types';

import { Icon, Modal } from 'antd';

export interface IFormQuestionProps {
  question: QuestionType;
  renderRegulationsModal: (question: QuestionType) => void;
}

export const FormQuestionComponent = (props: IFormQuestionProps) => {
  const { question, renderRegulationsModal } = props;
  return (
    <Fragment>
      <li style={{ fontSize: 18 }}>
        <p>
          {question.description}
          &nbsp;{' '}
          {question.regulations && question.regulations.length > 0 && (
            <Icon
              onClick={() => renderRegulationsModal(question)}
              type="question-circle"
              theme="filled"
            />
          )}
        </p>
      </li>
      <br />
    </Fragment>
  );
};

interface IModalQuestionsProps {
  title: string;
  isVisible: boolean;
  questions: any;
  renderCallback: (question: QuestionType) => void;
  onOk?: () => any;
  onCancel?: () => any;
  footer?: React.ReactNode;
  isLoadingQuestion?: boolean;
}

export const ModalQuestionsComponent = (props: IModalQuestionsProps) => {
  const { title, isVisible, footer, questions, renderCallback } = props;
  const { onOk, onCancel } = props;

  return (
    <Modal
      title={
        <h2 style={{ maxWidth: 720, fontSize: 28, textAlign: 'center', margin: 'auto' }}>
          <strong>{title}</strong>
        </h2>
      }
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      width={'60%'}
    >
      {questions.map((question: QuestionType) => (
        <FormQuestionComponent
          key={question.id}
          question={question}
          renderRegulationsModal={renderCallback}
        />
      ))}
    </Modal>
  );
};
