import { takeLatest, put, call } from 'redux-saga/effects';

import { submitHelpFormAction } from './help-form.action';
import { AnyAction } from 'redux';
import { addService } from '@rdx/services.saga';

function* submitHelpFormRequest(action: AnyAction) {
  const { formValues } = action.payload;

  const endpoint = submitHelpFormAction.getEndpoint({});

  try {
    yield call(addService, endpoint, formValues);

    yield put(submitHelpFormAction.success({}));
  } catch (error) {
    if (!error.wasCancelled) {
      yield put(
        submitHelpFormAction.failure({
          error: {
            code: 'helpFormSubmit',
            title: 'Failed to submit help formulary request.',
            message: 'There has been an error while sending formulary. Please try again.',
          },
        }),
      );
    }
  }
}

export function* helpFormSaga() {
  yield takeLatest(submitHelpFormAction.REQUEST, submitHelpFormRequest);
}
