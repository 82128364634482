import { throttle, put } from 'redux-saga/effects';

import { AnyAction } from 'redux';

import {
  changeSetupProjectAction,
  addSetupProjectAction,
  removeSetupProjectAction,
  validateProjectsStepAction,
} from './project.actions';

function* addSetupProjectTrigger(action: AnyAction) {
  yield put(addSetupProjectAction.fulfill({ project: action.payload.project }));
}

function* changeSetupProjectTrigger(action: AnyAction) {
  yield put(
    changeSetupProjectAction.fulfill({
      key: action.payload.projectKey,
      projectUpdate: action.payload.projectUpdate,
    }),
  );
}

function* removeSetupProjectTrigger(action: AnyAction) {
  yield put(removeSetupProjectAction.fulfill({ key: action.payload.projectKey }));
}

function* validateProjectsStepTrigger(action: AnyAction) {
  yield put(
    validateProjectsStepAction.fulfill({
      step: action.payload.currentStep,
      isValid: action.payload.isValid,
    }),
  );
}

export function* setupProjectsSaga() {
  yield throttle(200, addSetupProjectAction.TRIGGER, addSetupProjectTrigger);
  yield throttle(200, changeSetupProjectAction.TRIGGER, changeSetupProjectTrigger);
  yield throttle(200, removeSetupProjectAction.TRIGGER, removeSetupProjectTrigger);
  yield throttle(200, validateProjectsStepAction.TRIGGER, validateProjectsStepTrigger);
}
