import { createSelector } from 'reselect';

import { IAppState } from '@rdx/root.reducer';

import { getList } from '@shared/helpers/state-caster';

export const _getAccounts = (state: IAppState) => state.admin.accounts.accountList.items;

export const getAccounts = createSelector(
  [_getAccounts],
  accounts => {
    return getList(accounts);
  },
);

export const _getAccountCompanyInformation = (state: IAppState) => {
  if (state.admin.accountID) {
    return state.admin.accounts.accountList.items[state.admin.accountID];
  }
};
export const _getSeatsDetails = (state: IAppState) => {
  if (state.admin.accountID) {
    return state.admin.accounts.seatsInfo;
  }
};

export const getAccountCompanyInformation = createSelector(
  [_getAccountCompanyInformation],
  company => company,
);

export const getSeatsDetails = createSelector(
  [_getSeatsDetails],
  seatsDetails => {
    return getList(seatsDetails);
  }
);

export const _getSafetyPlansItems = (state: IAppState) => state.admin.accounts.safetyPlans.items;

export const getSafetyPlansItems = createSelector(
  [_getSafetyPlansItems],
  safetyPlan => safetyPlan,
);

export const _getSafetyPlansIsLoading = (state: IAppState) =>
  state.admin.accounts.safetyPlans.isLoading;

export const getSafetyPlansIsLoading = createSelector(
  [_getSafetyPlansIsLoading],
  isLoading => isLoading,
);

export const _getSafetyPlansIsUpdating = (state: IAppState) =>
  state.admin.accounts.safetyPlans.isUpdating;

export const getSafetyPlansIsUpdating = createSelector(
  [_getSafetyPlansIsUpdating],
  isUpdating => isUpdating,
);

export const _getAccountsRoles = (state: IAppState) => state.admin.accounts.roles;

export const getAccountsRoles = createSelector(
  [_getAccountsRoles],
  roles => getList(roles),
);

export const _getErrorMessages = (state: IAppState) => state.admin.accounts.accountList.errorMessages;

export const getErrorMessages = createSelector(
  [_getErrorMessages],
  errMessage => errMessage,
);

const _getUserConfig = (state: IAppState) => state.admin.accounts.usersConfig.items;

export const getUsersConfig = createSelector(
  _getUserConfig,
  userConfig => userConfig
)