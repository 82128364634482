import React, { ChangeEvent, SetStateAction, useState } from 'react';
import { IAppState } from '@rdx/root.reducer'
import { connect } from 'react-redux';

import { ContractorSubmitPayload } from '@app/admin/accounts/contractors/contractors';
import { WrappedFormUtils } from 'antd/es/form/Form';

import { addCachedContractorAction } from '@app/admin/accounts/contractors/state/contractors.actions';
import { addContractorAction } from '@app/admin/accounts/contractors/state/contractors.actions';
import { getContractorCachedCounter } from '@app/admin/accounts/contractors/state/contractors.selector';


import AddContractorModalComponents from './add-contractor.component';

export interface ModalContainerProps {
    selectedAccountID: number
    showModal: boolean;
    setShowModal: any;
    addContractorAction: (payload: ContractorSubmitPayload) => void;
}


const ContractorInvite = (props: ModalContainerProps) => {

    const { selectedAccountID, showModal, setShowModal, addContractorAction } = props;

    const [modifiedContractors, setModifiedContractors] = useState<SetStateAction<any>>({});


    const handleOnChangeContractor = (event: ChangeEvent<HTMLInputElement>) => {
        setModifiedContractors({
            ...modifiedContractors,
            key: 0,
            [event.target.name]: event.target.value,
        });
    };

    const handleSaveContractor = (form: WrappedFormUtils) => {
        const mappedContractor = {
            key: modifiedContractors.key,
            contractorCompanyID: modifiedContractors.contractorCompanyID,
            companyName: modifiedContractors.companyName,
            companyAddress: modifiedContractors.companyAddress,
        };

        if (selectedAccountID && mappedContractor.companyName !== undefined) {
            addContractorAction({
                contractor: mappedContractor as any,
                accountID: selectedAccountID,
            });
            form.resetFields();
        }
    };

    return (
        <AddContractorModalComponents
            showModal={showModal}
            setShowModal={setShowModal}
            handleSaveContractor={handleSaveContractor}
            handleOnChangeContractor={handleOnChangeContractor} />
    )

}

const mapStateToProps = (state: IAppState) => ({
    contractorCachedCounter: getContractorCachedCounter(state),
});

const mapDispatchToProps = {
    addContractorAction: addContractorAction.request,
    addCachedContractorAction: addCachedContractorAction.trigger,
};

export const ModalContractorInviteContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContractorInvite);

