import { createActionRoutine } from '@shared/helpers/redux-saga-endpoints';

import {
  FETCH_PLANS_INSTANCE_API,
  FETCH_FORMS_INSTANCE_API,
  ADD_ACCOUNT_FORM_API,
  DELETE_ACCOUNT_FORM_API,
  ADD_ACCOUNT_SAFETY_PLAN_API,
  DELETE_ACCOUNT_SAFETY_PLAN_API,
} from '@constants/endpoints';

export const CHANGE_FORM_ACTIVE_STATUS = 'CHANGE_FORM_ACTIVE_STATUS';
export const CHANGE_SUB_FORM_ACTIVE_STATUS = 'CHANGE_SUB_FORM_ACTIVE_STATUS';
export const FETCH_ACCOUNTS_PLANS = 'FETCH_ACCOUNTS_PLANS';
export const FETCH_ACCOUNTS_PLAN = 'FETCH_ACCOUNTS_PLAN';
export const ADD_ACCOUNT_FORM = 'ADD_ACCOUNT_FORM';
export const DELETE_ACCOUNT_FORM = 'DELETE_ACCOUNT_FORM_API';
export const CHANGE_ACCOUNT_SAFETY_PLAN_ACTIVE_STATUS = 'CHANGE_ACCOUNT_SAFETY_PLAN_ACTIVE_STATUS';
export const ADD_ACCOUNT_SAFETY_PLAN = 'ADD_ACCOUNT_SAFETY_PLAN';
export const DELETE_ACCOUNT_SAFETY_PLAN = 'DELETE_ACCOUNT_SAFETY_PLAN';


export const changeFormActiveStatusAction = createActionRoutine(CHANGE_SUB_FORM_ACTIVE_STATUS);

export const changeSubFormActiveStatusAction = createActionRoutine(CHANGE_FORM_ACTIVE_STATUS);

export const fetchAccountsPlansAction = createActionRoutine(
  FETCH_ACCOUNTS_PLANS,
  FETCH_PLANS_INSTANCE_API,
);

export const fetchAccountsPlanAction = createActionRoutine(
  FETCH_ACCOUNTS_PLAN,
  FETCH_FORMS_INSTANCE_API,
);

export const addAccountFormAction = createActionRoutine(ADD_ACCOUNT_FORM, ADD_ACCOUNT_FORM_API);

export const deleteAccountFormAction = createActionRoutine(
  DELETE_ACCOUNT_FORM,
  DELETE_ACCOUNT_FORM_API,
);

export const changeAccountSafetyPlanActiveStatusAction = createActionRoutine(CHANGE_ACCOUNT_SAFETY_PLAN_ACTIVE_STATUS);

export const addAccountSafetyPlanAction = createActionRoutine(
  ADD_ACCOUNT_SAFETY_PLAN,
  ADD_ACCOUNT_SAFETY_PLAN_API
);

export const deleteAccountSafetyPlanAction = createActionRoutine(
  DELETE_ACCOUNT_SAFETY_PLAN,
  DELETE_ACCOUNT_SAFETY_PLAN_API
);


