import React, { useEffect, Fragment, useState } from 'react';

import { connect } from 'react-redux';

import { IAppState } from '@rdx/root.reducer';
import { UserType, AccountType } from '@shared/types/backend.types';
import {
  FormInstanceSelected,
  SafetyFormsList,
  ChangeFormStatusPayload,
} from '@app/admin/accounts/safety-plans/safety-plans';

import { fetchFormQuestions } from '@app/admin/safety-programs/state/safety-programs.actions';

import { getProgramPlans, getIsLoadingSafetyPrograms } from './safety-programs.selector';
import { getUserCompany, getUserAccountId } from '@app/session/session.selector';

import { fetchPlansInstanceAction, changeFormStatusAction } from './safety-programs.actions';

import { BackOfficePlansComponent } from '@back-office/safety-programs/view/safety-programs.component';

export interface IPlansLockedProps {
  user?: UserType;
  company: AccountType;
  questionsIsLoading?: boolean;
  questions?: any;
  plans: any;
  isLoading: boolean;
  accountID: number;
  fetchPlansAction: (accountID: number) => void;
  changeFormStatusAction: (payload: ChangeFormStatusPayload) => void;
  fetchSafetyPlansFormQuestionsAction: (payload: any) => void;
}

const PlansLocked = (props: IPlansLockedProps) => {
  const { plans, questions, questionsIsLoading, company, isLoading, accountID } = props;
  const { fetchPlansAction, changeFormStatusAction, fetchSafetyPlansFormQuestionsAction } = props;

  useEffect(() => {
    if (accountID) {
      fetchPlansAction(accountID);
    }
  }, [accountID, fetchPlansAction]);

  useEffect(() => {
    if (plans.length > 0) {
      fecthSelectedFormQuestion((Object.values(plans[0].forms) as any)[0].id)
    }
  }, [plans])



  const handleSwitchChecked = (
    isValid: boolean,
    form: FormInstanceSelected,
    safetyPlan: SafetyFormsList,
  ) => {
    changeFormStatusAction({
      //@ts-ignore
      safetyPlanID: safetyPlan.safetyPlanID,
      accountID,
      form,
      safetyPlan,
      isValid,
    });
  };

  const fecthSelectedFormQuestion = (formID: string) => {
    fetchSafetyPlansFormQuestionsAction({ params: { formID } });
  }



  return (
    <Fragment>
      <BackOfficePlansComponent
        plans={plans}
        isLoading={isLoading}
        questions={questions}
        questionsIsLoading={questionsIsLoading}
        handleSwitchChecked={handleSwitchChecked}
        fecthSelectedFormQuestion={fecthSelectedFormQuestion}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: IAppState) => ({
  company: getUserCompany(state),
  plans: getProgramPlans(state),
  accountID: getUserAccountId(state),
  questions: state.admin.safetyPrograms.questions,
  questionsIsLoading: state.admin.safetyPrograms.reqState.isLoading,
  isLoading: getIsLoadingSafetyPrograms(state),
});

const mapDispatchToProps = {
  fetchPlansAction: fetchPlansInstanceAction.request,
  changeFormStatusAction: changeFormStatusAction.request,
  fetchSafetyPlansFormQuestionsAction: fetchFormQuestions.request,
};

export const PlansLockedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlansLocked as any);
